import { tasksConstants } from "../constants/tasks.constants";

export function tasks(state = {}, action) {
  switch (action.type) {
    case tasksConstants.GET_TASKS_DATA_REQUEST:
      return { loading: true };
    case tasksConstants.GET_TASKS_DATA_SUCCESS:
      return { 
        tasksList : action.result.callTasks,
        activeStageList : action.result.activeStageList,
       };
    case tasksConstants.GET_TASKS_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

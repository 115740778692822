import { notificationConstants } from "../constants"

export function addNotification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.ADD_NOTIFICATION_REQUEST:
      return { loading: true }
    case notificationConstants.ADD_NOTIFICATION_SUCCESS:
      return { data: action.result }
    case notificationConstants.ADD_NOTIFICATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function getNotifications(state = {}, action) {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_REQUEST:
      return { loading: true }
    case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
      return { data: action.result }
    case notificationConstants.GET_NOTIFICATIONS_FAILURE:
      return {}
    default:
      return state
  }
}

export function editNotification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.EDIT_NOTIFICATION_REQUEST:
      return { loading: true }
    case notificationConstants.EDIT_NOTIFICATION_SUCCESS:
      return { data: action.result }
    case notificationConstants.EDIT_NOTIFICATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteNotification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.DELETE_NOTIFICATION_REQUEST:
      return { loading: true }
    case notificationConstants.DELETE_NOTIFICATION_SUCCESS:
      return { data: action.result }
    case notificationConstants.DELETE_NOTIFICATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function publishNotifications(state = {}, action) {
  switch (action.type) {
    case notificationConstants.PUBLISH_NOTIFICATION_REQUEST:
      return { loading: true }
    case notificationConstants.PUBLISH_NOTIFICATION_SUCCESS:
      return { data: action.result }
    case notificationConstants.PUBLISH_NOTIFICATION_FAILURE:
      return {}
    default:
      return state
  }
}

import {  axiosAjaxCall } from './common.service'
import { showLoader } from '../../helpers';

export const userManagementService = {
    addUser,
    getUsers,
    deleteUser,
    changeUserStatus,

    addUserType,
    getUserTypes,
    deleteUserType,
    updateUserTypes,
    
    getDepartments,
    saveDepartments,
    
    getJobTitles,
    saveJobTitles,

    saveCommissionSlab,
    getCommissionSlab,
    getCommissionAmounts,
}

function addUser(data) {
    return axiosAjaxCall("users", data);
}

function changeUserStatus(data) {
    return axiosAjaxCall("change_user_status", data);
}

function deleteUser(data) {
    return axiosAjaxCall("delete_user", data);
}

function getUsers(data) {
    return axiosAjaxCall("get_users", data);
}

function addUserType(data) {
    return axiosAjaxCall("add_user_type", data);
}

function deleteUserType(data) {
    return axiosAjaxCall("delete_user_type", data);
}

function getUserTypes() {
    return axiosAjaxCall("get_user_type");
}

function updateUserTypes() {
    return axiosAjaxCall("update_user_type");
}

function saveCommissionSlab(data) {
    showLoader();
    return axiosAjaxCall("save_commission_slab", data);
}

function getCommissionSlab(data) {
    return axiosAjaxCall("get_commission_slab", data);
}

function getDepartments(data) {
    showLoader();
    return axiosAjaxCall("get_user_departments", data);
}

function saveDepartments(data) {
    return axiosAjaxCall("save_user_departments", data);
}

function getJobTitles(data) {
    showLoader();
return axiosAjaxCall("get_job_titles", data);
}

function saveJobTitles(data) {
    return axiosAjaxCall("save_job_titles", data);
}

function getCommissionAmounts(data) {
    return axiosAjaxCall("get_commission_amounts", data);
}

import React, { Component } from "react";
import { TimelineStructure } from "../../Sales/Forms/CommonComponents";
import moment from "moment";
import EachStageDataRender from "./EachStageDataRender";
import { VerticalTimeline } from "react-vertical-timeline-component";
import { Col, Row, Card, CardBody, CardHeader } from "reactstrap";

export default class ProcessStageTimeLine extends Component {
  renderEachStage() {
    const { processStages } = this.props;

    return processStages.map((each_stage) => {
      let iconStyle = {
        background: "#0194C8",
        color: "#fff",
      };
      return (
        <TimelineStructure
          title={false}
          iconStyle={iconStyle}
          icon={<i className="fas fa-star"></i>}
        >
            <Card>
            
          <CardHeader>
          <Row>
            <Col md="4">
              <b>Process Name</b> <br />
              {each_stage.process_name}
            </Col>
            <Col md="4">
              <b>Stage Name</b> <br />
              {each_stage.stage_name}
            </Col>
            <Col md="4" sm="6">
              <b>Communicated with Person </b> <br />
              {each_stage.individual_name}
            </Col>
            <Col md="4">
              <b>Updated By </b> <br />
              {each_stage.assign_to ? each_stage.assign_to.name : "-"}
            </Col>
            <Col md="4">
              <b>Created on </b> <br />
              {moment(each_stage.created_at).format("D-M-Y hh:mm A")}
            </Col>

            <Col md="4">
              <b>Completed on </b> <br />
              {each_stage.is_active
                ? "-"
                : moment(each_stage.updated_at).format("D-M-Y hh:mm A")}
            </Col>

            <Col md="4">
              <b>Status</b> <br />
              {each_stage.is_repeat ? (
                <span className="badge badge-primary">Repeat</span>
              ) : (
                ""
              )}
              {each_stage.is_active ? (
                <span className="badge badge-primary">Active</span>
              ) : (
                <span className="badge badge-success">Complete</span>
              )}
            </Col>
          </Row>
          </CardHeader>
          <CardBody>
          <EachStageDataRender processStage={each_stage} />
          </CardBody>
          </Card>
        </TimelineStructure>
      );
    });
  }

  render() {
    return (
      <div>
        <VerticalTimeline layout={"one-column"}>
          {this.renderEachStage()}
        </VerticalTimeline>
      </div>
    );
  }
}

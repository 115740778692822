

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import  { WhatsappSendSA} from './WhatsappSendLogsTabs/WhatsappSendLogSuperAdmin'
import  { WhatsappSendRI} from './WhatsappSendLogsTabs/WhatsappSendLogsReputeInfo'


const TABS = {
	Reputeinfo_WA_Send: 'Reputeinfo_WA_Send',
	Reputeinfo_SA_WA_Send: 'Reputeinfo_SA_WA_Send',
};


class WhatsappSend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: {
				perPage: 20,
				totalItemsCount: 0
			},
			activeTab: 'Reputeinfo_WA_Send'
		};
	}

	toggle(tab) {
		this.setState({ ...this.state, activeTab: tab });
	}


	render() {
		return (
			<Card>
				<CardHeader>
					<i className="fas fa-boxes" />
					<strong>Whatsapp Send  Logs</strong>
				</CardHeader>
				<CardBody>
					<Nav tabs>
						<NavItem>
							<NavLink
								className={classnames({
									active: this.state.activeTab === TABS.Reputeinfo_WA_Send,
								})}
								onClick={() => {
									this.toggle(TABS.Reputeinfo_WA_Send);
								}}
							>
								<span style={{ fontWeight: "bold" }}>Reputeinfo Whatsapp Send</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: this.state.activeTab === TABS.Reputeinfo_SA_WA_Send,
								})}
								onClick={() => {
									this.toggle(TABS.Reputeinfo_SA_WA_Send);
								}}
							>
								<span style={{ fontWeight: "bold" }}>Super Admin  Whatsapp Send</span>
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId={TABS.Reputeinfo_WA_Send}>
							<WhatsappSendRI/>
						</TabPane>
						<TabPane tabId={TABS.Reputeinfo_SA_WA_Send}>
							<WhatsappSendSA/>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		);
	}
}

export {  WhatsappSend };


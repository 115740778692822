import { notify } from 'reapop'
import { dailyMisConstants } from '../constants'
import { dailyMisService } from '../services'

export const dailyMisAction = {
    getDailyMis,
}


function getDailyMis(data) {
    return dispatch => {
        dispatch(request())

        dailyMisService. getDailyMis(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dailyMisConstants.GET_DAILY_MIS_REQUEST } }
    function success(result) { return { type: dailyMisConstants.GET_DAILY_MIS_SUCCESS, result } }
    function failure(error) { return { type: dailyMisConstants.GET_DAILY_MIS_FAIL, error } }
}

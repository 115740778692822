import {  axiosAjaxCall } from './common.service'
import {  showLoader } from "../../helpers";

export const emailLogService = {
    getEmailLogs,
}

function getEmailLogs(data) {
    showLoader();    
    return axiosAjaxCall("get_email_logs", data);
}

import { axiosAjaxCall } from './common.service';

export const LegalService = {
    getLegal,
    saveLegal,
    publishLegal
}

function getLegal(data) {
    // for edit
    if(data) {
        return axiosAjaxCall("get_legal_content", data);
    }
    
    // for list data
    return axiosAjaxCall("get_legal_data", data);
}

function publishLegal(data) {   
    return axiosAjaxCall("publish_legal_data", data);
}

function saveLegal(data) {
    return axiosAjaxCall("save_legal_content", data);
}

import { promoCodeUsagesConstants } from "../constants"


export function getPromoCodeUsages(state = {}, action) {
    switch (action.type) {
        case promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_REQUEST:
            return { loading: true }
        case promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_SUCCESS:
            return  { data: action.result }
        case promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_FAIL:
            return {}
        default:
            return state
    }
}
import { communicationMastersTemplatesConstants } from "../constants/communicationMastersTemplates.constants";

export function communicationMastersTemplates(state = {}, action) {
  switch (action.type) {
    case communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_REQUEST:
      return { loading: true };
    case communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_SUCCESS:
      return { templates : action.result };
    case communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

export const documentStatus = {
  PENDING: "PENDING",
  INREVIEW: "INREVIEW",
  IN_REVIEW: "IN_REVIEW",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const documentStatusName = {
  1: "Pending",
  2: "In Review",
  3: "Verified",
  4: "Rejected",

  "PENDING" :'Pending',
  "INREVIEW" :'In Review',
  "IN_REVIEW" :'In Review',
  "ACCEPTED" :'Accepted',
  "REJECTED" :'Rejected',
};


export const COMPANY_TYPE = {
  ALL: 1,
  DEBTOR: 2,
  CREDITOR: 3,
};

export const COMPANY_TYPE_TEXT = {
  ALL: "All",
  DEBTOR: "Debtor (Buyer)",
  CREDITOR: "Creditor (Seller)",
};

export const INVITATION_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
};

export const INVITATION_STATUS_TEXT = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
};

export const WEB_SOCKET_EVENTS = {
  USER_CONNECTED: "USER_CONNECTED",
  NOTIFICATIONS: "NOTIFICATIONS",
  USER_LOGOUT: "USER_LOGOUT",
};

export const YES = 1;
export const NO = 0;
export const TRUE = 1;
export const FALSE = 0;

export const COMPANY_ROLES = {
  OWNER: 1,
  AUTHORIZED_PERSON: 2,
};

export const DEFAULT_USER_TYPE = {
  SUPER_ADMIN: "Super admin",
  ADMIN: "Admin",
};

export const PERMISSIONS = {
  ALL: "ALL",
  DASHBOARD: "DASHBOARD",
  EXPENSES: 'EXPENSES',
  DASHBOARD_REPORTS: "DASHBOARD_REPORTS",
  BADDEBT_SEARCH_LOGS: "BADDEBT_SEARCH_LOGS",
  RECENT_REGISTRATIONS: "RECENT_REGISTRATIONS",
  INDIVIDUAL: "INDIVIDUAL",
  INDIVIDUAL_VERIFIED: "INDIVIDUAL_VERIFIED",
  INDIVIDUAL_NOT_VERIFIED: "INDIVIDUAL_NOT_VERIFIED",
  INDIVIDUAL_ALL: "INDIVIDUAL_ALL",
  INDIVIDUAL_JUNK: 'INDIVIDUAL_JUNK',
  COMPANY: "COMPANY",
  COMPANY_VERIFIED: "COMPANY_VERIFIED",
  COMPANY_NOT_VERIFIED: "COMPANY_NOT_VERIFIED",
  COMPANY_ALL: "COMPANY_ALL",
  SALES: "SALES",
  CUSTOMER_SALES_STATUS: "CUSTOMER_SALES_STATUS",
  SUPPORT: "SUPPORT",
  ACCOUNTING: "ACCOUNTING",
  LEGAL: "LEGAL",
  ADD_USER: "ADD_USER",
  LIST_USER: "LIST_USER",
  USER_TYPES: "USER_TYPES",
  SMS: "SMS",
  COMMUNICATION_TEMPLATE: "COMMUNICATION_TEMPLATE",
  EMAIL: "EMAIL",
  EDIT_EMAIL: "EDIT_EMAIL",
  FAQ: "FAQ",
  ADD_FAQ: "ADD_FAQ",
  EDIT_FAQ: "EDIT_FAQ",
  LIST_FAQ: "LIST_FAQ",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  LIST_NOTIFICATION: "LIST_NOTIFICATION",
  //"REQUESTS": "REQUESTS",
  REPUTEINFO: "REPUTEINFO",
  CUSTOMERDETAIL: "CUSTOMERDETAIL",
  NEW_CUSTOMER: "NEW_CUSTOMER",
  EDIT_CUSTOMER_DETAIL: "EDIT_CUSTOMER_DETAIL",
  SUBSCRIPTION_DETAILS: "SUBSCRIPTION_DETAILS",
  COMPANY_SECTOR_LIST: "COMPANY_SECTOR_LIST",
  ADD_COMPANY_SECTOR: "ADD_COMPANY_SECTOR",
  EDIT_COMPANY_SECTOR: "EDIT_COMPANY_SECTOR",
  CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
  SMS_PACKAGE: "SMS_PACKAGE",
  TICKETS: "TICKETS",
  MANAGE_COMMISSION_SLAB: "MANAGE_COMMISSION_SLAB",
  ACCOUNT_LIST: "ACCOUNT_LIST",
  COMPANY_LIST: "COMPANY_LIST",
  CHEQUE_DETAIL: "CHEQUE_DETAIL",
  USERS_SALARY: "USERS_SALARY",
  LOGS: "LOGS",
  EXCEPTION_LOGS: "EXCEPTION_LOGS",
  LOGIN_LOGS: "LOGIN_LOGS",
  CRON_LOGS: "CRON_LOGS",
  SEARCH_LOGS: "SEARCH_LOGS",
  SMS_LOGS: "SMS_LOGS",

  ADMIN_CONTENT_CONFIG: "ADMIN_CONTENT_CONFIG",
  UPDATE_ADMIN_CONTENT_CONFIG: "UPDATE_ADMIN_CONTENT_CONFIG",

  BUSINESS_ACCOUNT: "BUSINESS_ACCOUNT",
  SUPER_ADMIN_SETTING: "SUPER_ADMIN_SETTING",
  AUTO_REMINDER_CRON_LOGS: "AUTO_REMINDER_CRON_LOGS",
  SUBSCRIPTION_LOGS: "SUBSCRIPTION_LOGS",
  CONTACT_US_DETAIL: "CONTACT_US_DETAIL",
  MILESTONE_LOGS: "MILESTONE_LOGS",
  EMAIL_LOGS: "EMAIL_LOGS",
  PRODUCT_TAGS: "PRODUCT_TAGS",
  REQUIREMENTS: "REQUIREMENTS",

  UNREGISTERED_SELLER: "UNREGISTERED_SELLER",
  REPUTEINFO_BALANCE_PACKAGE: "REPUTEINFO_BALANCE_PACKAGE",
  RI_COMMUNICATION: "RI_COMMUNICATION",
  PROFILE: "PROFILE",
  GROUP: "GROUP",
  GROUP_TYPE: "GROUP_TYPE",
  ASSOCIATION_INDIVIUAL_TYPE: "ASSOCIATION_INDIVIUAL_TYPE",
  ExpenceStatement: "ExpenceStatement",
  INFORMATION: "INFORMATION",
  SETTINGS: "SETTINGS",

  WHATSAPP: 'WHATSAPP',
  WHATSAPP_SEND_LOG: 'WHATSAPP_SEND_LOG',
  WHATSAPP_RECEIVE_LOG: 'WHATSAPP_RECEIVE_LOG',
  WHATSAPP_SCHEDULE_LOG: 'WHATSAPP_SCHEDULE_LOG',
  USER_ACTIVITY: 'USER_ACTIVITY',
  FINANCE_STATUS: 'FINANCE_STATUS',
  VOICE_LOGS: 'VOICE_LOGS',
  FEEDBACK: 'FEEDBACK',
  VIDEOS: 'VIDEOS',
  LANDING_PAGE: 'LANDING_PAGE',
  DAILY_MIS: 'DAILY_MIS' ,
  SEND_BILL_TRANSACTION: 'SEND_BILL_TRANSACTION' ,
  PROMOCODE: 'PROMOCODE'

};

export const LAST_AMOUNT = 1000000000;

export const PERMISSIONS_FOR_FORM = [
  {
    value: PERMISSIONS.DASHBOARD,
    label: "Dashboard",
  },
  {
    value: PERMISSIONS.DAILY_MIS,
    label: "Daily MIS",
  },
  {
    value: PERMISSIONS.EXPENSES,
    label: "Expenses",
  },
  {
    value: PERMISSIONS.LANDING_PAGE,
    label: "Landing Page",
  },
  {
    value: PERMISSIONS.SEND_BILL_TRANSACTION,
    label: "Send Bill Transaction",
  },
  {
    value: PERMISSIONS.PROMOCODE,
    label: "Promo Code",
  },
  {
    value: PERMISSIONS.DASHBOARD_REPORTS,
    label: "Dashboard Reports",
  },
  {
    value: "VERIFICATION",
    label: "Verification",
    children: [
      {
        value: PERMISSIONS.INDIVIDUAL,
        label: "Individual (sidemenu)",
      },
      {
        value: PERMISSIONS.INDIVIDUAL_NOT_VERIFIED,
        label: "Individual > UnVerified",
      },
      {
        value: PERMISSIONS.INDIVIDUAL_VERIFIED,
        label: "Individual > Verified",
      },
      {
        value: PERMISSIONS.INDIVIDUAL_ALL,
        label: "Individual > ALL",
      },
      {
        value: PERMISSIONS.COMPANY,
        label: "Company (sidemenu)",
      },
      {
        value: PERMISSIONS.COMPANY_NOT_VERIFIED,
        label: "Company > UnVerified",
      },
      {
        value: PERMISSIONS.COMPANY_VERIFIED,
        label: "Company > Verified",
      },
      {
        value: PERMISSIONS.COMPANY_ALL,
        label: "Company > ALL",
      },
    ],
  },
  {
    value: PERMISSIONS.RECENT_REGISTRATIONS,
    label: "Recent Registrations",
  },
  {
    value: PERMISSIONS.ACCOUNTING,
    label: "Accounting",
    children: [
      {
        value: PERMISSIONS.ACCOUNT_LIST,
        label: "Account List",
      },
      {
        value: PERMISSIONS.USERS_SALARY,
        label: "User Salary",
      },
      {
        value: PERMISSIONS.CHEQUE_DETAIL,
        label: "Cheque Detail",
      },
      {
        value: PERMISSIONS.COMPANY_LIST,
        label: "Company List",
      },
    ],
  },
  {
    value: "PRODUCTS",
    label: "Reqs / Quotes",
    children: [
      {
        value: PERMISSIONS.REQUIREMENTS,
        label: "Requirements",
      },
      {
        value: PERMISSIONS.PRODUCT_TAGS,
        label: "Product Tags",
      },
      {
        value: PERMISSIONS.UNREGISTERED_SELLER,
        label: "Unregistered Seller",
      },
    ],
  },
  {
    value: "USER_MANAGEMENT",
    label: "User Management",
    children: [
      {
        value: PERMISSIONS.ADD_USER,
        label: "Add User",
      },
      {
        value: PERMISSIONS.LIST_USER,
        label: "Users",
      },
      {
        value: PERMISSIONS.USER_TYPES,
        label: "User Types",
      },
      {
        value: PERMISSIONS.USER_TYPES,
        label: "Departments",
      },
      {
        value: PERMISSIONS.USER_TYPES,
        label: "Job Titles",
      },
    ],
  },
  {
    value: "RI_COMMUNICATION",
    label: "RI Communications",
    children: [
      {
        value: PERMISSIONS.EMAIL,
        label: "Email",
      },
      {
        value: PERMISSIONS.SMS,
        label: "SMS",
      },
      {
        value: PERMISSIONS.LIST_NOTIFICATION,
        label: "Notifications",
      },
      {
        value: PERMISSIONS.COMMUNICATION_TEMPLATE,
        label: "Communication template",
      },
    ],
  },
  {
    value: PERMISSIONS.BUSINESS_ACCOUNT,
    label: "Business Account",
  },
  {
    value: PERMISSIONS.BUSINESS_ACCOUNT,
    label: "Tasks",
  },
  {
    value: PERMISSIONS.SEND_BILL_TRANSACTIONS,
    label: "Send Bill Transaction",
  },
  {
    value: PERMISSIONS.SUPER_ADMIN_SETTING,
    label: "RI Super Admin",
    children: [
      {
        value: PERMISSIONS.SUPER_ADMIN_SETTING,
        label: "Templates",
        children: [
          {
            value: PERMISSIONS.SUPER_ADMIN_SETTING,
            label: "Business Account",
          },
          {
            value: PERMISSIONS.SUPER_ADMIN_SETTING,
            label: "Communication",
          },
          {
            value: PERMISSIONS.SUPER_ADMIN_SETTING,
            label: "Support",
          },
        ],
      },

    ],
  },
  {
    value: PERMISSIONS.REPUTEINFO,
    label: "ReputeInfo",
    children: [
      {
        value: PERMISSIONS.SUBSCRIPTION_DETAILS,
        label: "Subscription Details",
      },
      {
        value: PERMISSIONS.SMS_PACKAGE,
        label: "SMS Package",
      },
      {
        value: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE,
        label: "Reputeinfo Balance",
      },
      {
        value: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE,
        label: "Balance Setting",
      },
      {
        value: PERMISSIONS.LEGAL,
        label: "Legal",
      },
      {
        value: "COMPANY_SECTORS",
        label: "Company Sectors",
        children: [
          {
            value: PERMISSIONS.ADD_COMPANY_SECTOR,
            label: "Add Company Sector",
          },
          {
            value: PERMISSIONS.EDIT_COMPANY_SECTOR,
            label: "Edit & Delete Company Sector",
          },
          {
            value: PERMISSIONS.COMPANY_SECTOR_LIST,
            label: "Company Sectors List",
          },
          {
            value: PERMISSIONS.SMS_PACKAGE,
            label: "SMS Package",
          },
        ],
      },
      {
        value: PERMISSIONS.FAQ,
        label: "FAQ",
        children: [
          {
            value: PERMISSIONS.LIST_FAQ,
            label: "FAQ List",
          },
          {
            value: PERMISSIONS.ADD_FAQ,
            label: "Add FAQ",
          },
          {
            value: PERMISSIONS.EDIT_FAQ,
            label: "Edit & Delete FAQ",
          },
        ],
      }
    ],
  },
  {
    value: PERMISSIONS.LOGS,
    label: "Logs",
    children: [
      {
        value: PERMISSIONS.EXCEPTION_LOGS,
        label: "Exception Logs",
      },
      {
        value: PERMISSIONS.LOGIN_LOGS,
        label: "Login Details",
      },
      {
        value: PERMISSIONS.CRON_LOGS,
        label: "Cron Logs",
      },
      {
        value: PERMISSIONS.SMS_LOG,
        label: "SMS Logs",
      },
      {
        value: PERMISSIONS.VOICE_LOGS,
        label: "Voice Reminder Logs",
      },
      {
        value: PERMISSIONS.FEEDBACK,
        label: "Feedback Logs",
      },
      {
        value: PERMISSIONS.VIDEOS,
        label: "Videos Logs",
      },
      {
        value: PERMISSIONS.SEARCH_LOG,
        label: "Search Logs",
      },
      {
        value: PERMISSIONS.AUTO_REMINDER_CRON_LOGS,
        label: "Auto Reminder Cron Logs",
      },
      {
        value: PERMISSIONS.SUBSCRIPTION_LOGS,
        label: "Subscription Logs",
      },
      {
        value: PERMISSIONS.CONTACT_US_DETAIL,
        label: "Contactus Detail",
      },
      {
        value: PERMISSIONS.MILESTONE_LOGS,
        label: "Milestone Logs",
      },
      {
        value: PERMISSIONS.EMAIL_LOGS,
        label: "Email Logs",
      },
      {
        value: PERMISSIONS.WHATSAPP,
        label: "Whatsapp Log",
        children: [
          {
            value: PERMISSIONS.WHATSAPP_RECEIVE_LOG,
            label: "Received Logs",
          },
          {
            value: PERMISSIONS.WHATSAPP_SEND_LOG,
            label: "Send Logs",
          },
          {
            value: PERMISSIONS.WHATSAPP_SCHEDULE_LOG,
            label: "Schedule Logs",
          },
        ],

      },
    ],
  },
  {
    value: "DEPRECATED",
    label: "Deprecated",
    children: [
      {
        value: "CUSTOMERS",
        label: "Customers",
        children: [
          {
            value: PERMISSIONS.NEW_CUSTOMER,
            label: "New Customer",
          },
          {
            value: PERMISSIONS.CUSTOMERDETAIL,
            label: "Customers",
          },
          {
            value: PERMISSIONS.EDIT_CUSTOMER_DETAIL,
            label: "Edit & Delete Customers Details",
          },
        ],
      },
      {
        value: PERMISSIONS.TICKETS,
        label: "Tickets",
      },
      {
        value: PERMISSIONS.SALES,
        label: "Sales",
        children: [
          {
            value: PERMISSIONS.CUSTOMER_SALES_STATUS,
            label: "Customer Sales Status",
          },
        ],
      },
    ]
  },



  // {
  // value: PERMISSIONS.SUPPORT,
  // label: "Support",
  // children: [
  //   {
  // value: PERMISSIONS.TICKETS,
  // label: "Tickets",
  //   },
  // ],
  //},

  // {
  //   value: PERMISSIONS.LEGAL,
  //   label: "Legal",
  // },

  // {
  //   value: PERMISSIONS.EMAIL,
  //   label: "Email",
  // },
  // {
  //   value: PERMISSIONS.SMS,
  //   label: "SMS",
  // },

  // {
  //   value: PERMISSIONS.FAQ,
  //   label: "FAQ",
  //   children: [
  //     {
  //       value: PERMISSIONS.LIST_FAQ,
  //       label: "FAQ List",
  //     },
  //     {
  //       value: PERMISSIONS.ADD_FAQ,
  //       label: "Add FAQ",
  //     },
  //     {
  //       value: PERMISSIONS.EDIT_FAQ,
  //       label: "Edit & Delete FAQ",
  //     },
  //   ],
  // },
  // {
  //   value: PERMISSIONS.LIST_NOTIFICATION,
  //   label: "Notifications",
  // },

];

export const INDIVIDUAL_PERMISSIONS = {
  VIEW_ALL_COMPANY: "VIEW_ALL_COMPANY",
  ADD_NEW_COMPANY: "ADD_NEW_COMPANY",
  DOCUMENTS: "DOCUMENTS",
  PROFILES: "PROFILES",
  NOTIFICATIONS: "NOTIFICATIONS",
  BILLING: "BILLING",
  CHAT: "CHAT",
  VIEW_ASSOCIATION: "VIEW_ASSOCIATION",
};

export const INDIVIDUAL_PERMISSIONS_FOR_FORM = [
  {
    value: INDIVIDUAL_PERMISSIONS.VIEW_ALL_COMPANY,
    label: "Companies",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.ADD_NEW_COMPANY,
    label: "Add new company",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.DOCUMENTS,
    label: "Documents",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.PROFILES,
    label: "Profile",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.NOTIFICATIONS,
    label: "Notifications",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.BILLING,
    label: "Billing",
  },
  {
    value: INDIVIDUAL_PERMISSIONS.VIEW_ASSOCIATION,
    label: "Association",
  },
];

export const COMPANY_PERMISSIONS = {
  DASHBOARD: "DASHBOARD",
  COMPANY_SEARCH: "COMPANY_SEARCH",
  INDIVIDUAL_SEARCH: "INDIVIDUAL_SEARCH",
  DEBTORS: "DEBTORS",
  CREDITORS: "CREDITORS",
  QUOTES: "QUOTES",
  REQUIREMENTS: "REQUIREMENTS",
  AUTHORIZED_PERSONS: "AUTHORIZED_PERSONS",
  RECEIVE_PAYMENT: "RECEIVE_PAYMENT",
  GOODS_RETURN: "GOODS_RETURN",
  CREDIT_NOTE: "CREDIT_NOTE",
  SEND_PAYMENT: "SEND_PAYMENT",
  SEND_BILL_TRANSACTION: "SEND_BILL_TRANSACTION",
  DEBIT_SELL: "DEBIT_SELL",
  DEBIT_SELL_ALL: "DEBIT_SELL_ALL",
  DEBIT_SELL_PENDING: "DEBIT_SELL_PENDING",
  DEBIT_SELL_CURRENT: "DEBIT_SELL_CURRENT",
  DEBIT_SELL_OVERDUE: "DEBIT_SELL_OVERDUE",
  DEBIT_SELL_BEDDEBT: "DEBIT_SELL_BEDDEBT",
  DEBIT_SELL_ISSUES: "DEBIT_SELL_ISSUES",
  DEBIT_SELL_INCOURT: "DEBIT_SELL_INCOURT",
  DEBIT_SELL_COMPLETE: "DEBIT_SELL_COMPLETE",
  DEBIT_SELL_BIN: "DEBIT_SELL_BIN",
  CREDIT_BUY: "CREDIT_BUY",
  CREDIT_BUY_ALL: "CREDIT_BUY_ALL",
  CREDIT_BUY_PENDING: "CREDIT_BUY_PENDING",
  CREDIT_BUY_CURRENT: "CREDIT_BUY_CURRENT",
  CREDIT_BUY_OVERDUE: "CREDIT_BUY_OVERDUE",
  CREDIT_BUY_BEDDEBT: "CREDIT_BUY_BEDDEBT",
  CREDIT_BUY_ISSUES: "CREDIT_BUY_ISSUES",
  CREDIT_BUY_INCOURT: "CREDIT_BUY_INCOURT",
  CREDIT_BUY_COMPLETE: "CREDIT_BUY_COMPLETE",
  CREDIT_BUY_BIN: "CREDIT_BUY_BIN",
  DEPARTMENTS: "DEPARTMENTS",
  DOCUMENTS: "DOCUMENTS",
  PROFILES: "PROFILES",
  BILLING: "BILLING",
  SMS: "SMS",
  REPORTS: "REPORTS",
  REPORTS_SALES: "REPORTS_SALES",
  REPORTS_PURCHASES: "REPORTS_PURCHASES",
  REPORTS_COMBINED: "REPORTS_COMBINED",
  RECEIVE: "RECEIVE",
  BEDDEBTACCOUNT: "BEDDEBTACCOUNT",
  BALANCELOGS: "BALANCELOGS"
};

export const COMPANY_PERMISSIONS_FOR_FORM = [
  {
    value: COMPANY_PERMISSIONS.DASHBOARD,
    label: "Dashboard",
  },
  {
    value: COMPANY_PERMISSIONS.COMPANY_SEARCH,
    label: "Company Search",
  },
  {
    value: COMPANY_PERMISSIONS.DEBTORS,
    label: "Debtors",
  },
  {
    value: COMPANY_PERMISSIONS.CREDITORS,
    label: "Creditors",
  },
  {
    value: COMPANY_PERMISSIONS.DEBTORS,
    label: "Debtors",
  },
  {
    value: COMPANY_PERMISSIONS.QUOTES,
    label: "Quotes",
  },
  {
    value: COMPANY_PERMISSIONS.REQUIREMENTS,
    label: "Requirements",
  },

  {
    value: COMPANY_PERMISSIONS.BEDDEBTACCOUNT,
    label: "Bad Debts",
  },

  {
    value: COMPANY_PERMISSIONS.SEND_BILL_TRANSACTION,
    label: "Send Bill Transection",
  },
  {
    value: COMPANY_PERMISSIONS.RECEIVE_PAYMENT,
    label: "Receive Payment",
  },
  {
    value: COMPANY_PERMISSIONS.CREDIT_NOTE,
    label: "Credit Note",
  },
  {
    value: COMPANY_PERMISSIONS.GOODS_RETURN,
    label: "Goods Return",
  },
  {
    value: COMPANY_PERMISSIONS.SEND_PAYMENT,
    label: "Send Payment",
  },
  {
    value: COMPANY_PERMISSIONS.DEBIT_SELL,
    label: "Debit (Sell)",
    children: [
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_ALL,
        label: "All",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_PENDING,
        label: "Pending",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_CURRENT,
        label: "Current",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_OVERDUE,
        label: "Overdue",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_BEDDEBT,
        label: "Bad Debt",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_ISSUES,
        label: "Issues",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_INCOURT,
        label: "In Court",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_COMPLETE,
        label: "Complete",
      },
      {
        value: COMPANY_PERMISSIONS.DEBIT_SELL_BIN,
        label: "Bin",
      },
    ],
  },
  {
    value: COMPANY_PERMISSIONS.CREDIT_BUY,
    label: "Credit (Buy)",
    children: [
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_ALL,
        label: "All",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_PENDING,
        label: "Pending",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_CURRENT,
        label: "Current",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_OVERDUE,
        label: "Overdue",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_BEDDEBT,
        label: "Bad Credit",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_ISSUES,
        label: "Issues",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_INCOURT,
        label: "In Court",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_COMPLETE,
        label: "Complete",
      },
      {
        value: COMPANY_PERMISSIONS.CREDIT_BUY_BIN,
        label: "Bin",
      },
    ],
  },
  {
    value: COMPANY_PERMISSIONS.REPORTS,
    label: "Reports",
    children: [
      {
        value: COMPANY_PERMISSIONS.REPORTS_SALES,
        label: "Sales Reports",
      },
      {
        value: COMPANY_PERMISSIONS.REPORTS_PURCHASES,
        label: "Purchases Reports",
      },
      {
        value: COMPANY_PERMISSIONS.REPORTS_COMBINED,
        label: "Combined Reports",
      },
    ]
  },
  {
    value: COMPANY_PERMISSIONS.RECEIVE,
    label: "Received Statement",
  },
  {
    value: COMPANY_PERMISSIONS.INDIVIDUAL_SEARCH,
    label: "Individual Search",
  },
  {
    value: COMPANY_PERMISSIONS.AUTHORIZED_PERSONS,
    label: "Authorized Persons",
  },
  {
    value: COMPANY_PERMISSIONS.MORE,
    label: "More",
    children: [
      {
        value: COMPANY_PERMISSIONS.PROFILES,
        label: "Profile",
      },
      {
        value: COMPANY_PERMISSIONS.DOCUMENTS,
        label: "Documents",
      },
      {
        value: COMPANY_PERMISSIONS.DEPARTMENTS,
        label: "Departmants",
      },
      {
        value: COMPANY_PERMISSIONS.BILLING,
        label: "Billing",
      },
      {
        value: COMPANY_PERMISSIONS.SMS,
        label: "SMS",
      },
      {
        value: COMPANY_PERMISSIONS.BALANCELOGS,
        label: "BalanceLogs",
      },
    ],
  },
];

export const SALES_STATUS = {
  SCHEDULE_CUSTOMER_CALL: "SCHEDULE_CUSTOMER_CALL",
  IS_CUSTOMER_CALL: "IS_CUSTOMER_CALL",
  CUSTOMER_CONTACT_RESPONSE: "CUSTOMER_CONTACT_RESPONSE",
  CUSTOMER_RESPONSE_INTERESTED_STATUS: "CUSTOMER_RESPONSE_INTERESTED_STATUS",
  APPOINTMENT: "APPOINTMENT",
  MEETING: "MEETING",
};

export const TICKET_STATUS = {
  NEW: 1,
  RE_OPEN: 2,
  IN_PROGRESS: 3,
  RESOLVED: 4,
  CLOSE: 5,
};

export const DATA_TO_REPLACE_FOR_LIVE_PREVIEW = {
  "{sender_company_name}": "ABC Company",
  "{sender_company_repute_id}": "RC-0000-0000-00",
  "{receiver_company_name}": "XYZ Company",
  "{receiver_company_repute_id}": "RC-1111-1111-11",
  "{sender_individual_name}": "Mr. John Doe",
  "{sender_individual_repute_id}": "RI-0000-0000-00",
  "{receiver_individual_name}": "Mr. Jack",
  "{receiver_individual_repute_id}": "RI-1111-1111-11",
  "{document_name}": "PAN Card",
  "{role}": "Owner",
  "{transaction_id}": "0000-0000-0000000000",
  "{permission_type}": "debtor",
  "{otp}": "111111",
  "{old_credit_limit}": "30 Days",
  "{new_credit_limit}": "60 Days",
  "{INR_symbol}": "Rs.",
  "{payable_principle}": "2,22,513.00",
  "{outstanding_amount}": "2,22,513.00",
  "{payable_interest}": "1,513.00",
  "{receivable_principle}": "2,22,513.00",
  "{receivable_interest}": "1,513.00",
  "{current_date}": "11-12-2019",
  "{days}": "5",
  "{interest_amount}": "1,513.00",
  "{bill_date}": "01-01-2021",
  "{bill_number}": "BN-0001",
  "{view_transaction_link}": "https://www.reputeinfo.com/r/abcdxyz",
  "{credit_amount}": "1,00,000.00"
};

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Tooltip } from "react-tippy";

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
    };
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan }, () => {});
  }

  render() {
    const { modalOpan } = this.state;
    const { data, index } = this.props;

    let _this = this;
    return (
      <div>
        <Tooltip
          className="pull-left m-0 p-0 ml-2"
          interactive
          arrow={true}
          title="Product Leads Details"
        >
          <Button color="primary" class="pull-left" onClick={_this.showModal}>
            View Details
          </Button>
        </Tooltip>
        <Modal className="modal-primary" size="lg" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>
            Product Leads Details {index + 1}
            <br />
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <div className="table-responsive-sm  animated fadeIn">
                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                  <thead class="thead-light">
                    <tr>
                      <th>Created date</th>
                      <th>Customer name</th>
                      <th>Phone number</th>
                      <th>Name of product company</th>
                      <th>Name of product lead company</th>
                      <th>Email address</th>
                      <th>source</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.created_at || "-"}</td>
                      <td>{data.name || "-"}</td>
                      <td>{data.phone || "-"}</td>
                      <td>{data.name_of_product_company || "-"}</td>
                      <td>{data.company_name || "-"}</td>
                      <td>{data.email || "-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
              <div className="table-responsive-sm  animated fadeIn">
                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                  <thead class="thead-light">
                    <tr>
                      <th colSpan={3}>Product Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Product name</td>
                      <td>Product Price</td>
                      <td>Quantity</td>
                    </tr>
                    {data.lead_inquiry_products &&
                    data.lead_inquiry_products.length ? (
                      data.lead_inquiry_products.map((product, index) => (
                        <tr key={index} className="bg-[#FFFFFF] ">
                          <td className="border">
                            {" "}
                            {product.product && product.product.product_name
                              ? `${product.product.product_name}`
                              : "-"}
                          </td>
                          <td className="border">
                            {" "}
                            {product.product && product.product.product_name
                              ? `${product.product.product_price}`
                              : "-"}
                          </td>
                          <td className="border">
                              {product.quantity}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-[#FFFFFF] ">
                        <td
                          className="border font-semibold text-center"
                          colSpan={3}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Detail;

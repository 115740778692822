import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from "react-localize-redux"
import filterFactory from "react-bootstrap-table2-filter"
import { Row, Col, CardBody, Card, CardHeader, Button } from 'reactstrap'
import { DataTable } from '../../../components/Tables/DataTable'
import Dropdown from './comman'
import swal from 'sweetalert'
import { userManagementActions } from '../../actions/userManagement.action';
import { TableLoader } from '../../../components/Loaders';
import { DEFAULT_USER_TYPE } from '../../constants';

class UserTypes extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.renderIsActive = this.renderIsActive.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.afterAction = this.afterAction.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        const { dispatch } = this.props;
        dispatch(userManagementActions.getUserTypes());
    }

    renderIsActive(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <Dropdown user={row}  {...this.props} afterAction={this.afterAction} />
        </React.Fragment>
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        const { dispatch } = this.props
        const confirmDelete = () => {
            swal({
                title: "Are you sure?",
                // text: "Once deleted, you will not be able to recover this user!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    dispatch(userManagementActions.deleteUserType({ id: row.id }, this));
                }
            });
        }

        if (row.name === DEFAULT_USER_TYPE.ADMIN || row.name === DEFAULT_USER_TYPE.SUPER_ADMIN) {
            return "-";
        }

        return (<React.Fragment>
            <Button color="danger" onClick={confirmDelete} size="sm">Delete</Button>
        </React.Fragment>
        )
    }

    afterAction() {
        // swal("User has been deleted!", {
        //     icon: "success",
        // });
        this.getData();
    }

    render() {
        const { data, loading } = this.props
        const columns = [{
            text: <Translate id='Name' />,
            dataField: 'name',
        }
        // , {
        //     text: <Translate id='Action' />,
        //     dataField: 'action',
        //     formatter: this.renderAction
        // }
    ]

        return (
            <div className="">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <i className="icon-list"></i><strong> User Types</strong>
                            </CardHeader>
                            <CardBody>
                                {/* <AddUserType /> */}
                                {loading ? <TableLoader /> :
                                    <React.Fragment>
                                        <DataTable keyField="id"
                                            data={data || []} columns={columns}
                                            filter={filterFactory()}
                                            noDataIndication={"No data found"} />
                                    </React.Fragment>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getUserTypes
    return {
        loading,
        data
    }
}

const connectedUserTypes = connect(mapStateToProps)(UserTypes)
export { connectedUserTypes as UserTypes }

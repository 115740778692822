import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import individualReducer from '../individual/reducers'

const loggerMiddleware = createLogger()

const combinedReducer = combineReducers(Object.assign({},
    individualReducer
))

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
    combinedReducer,
    composeEnhancer(applyMiddleware(thunk, loggerMiddleware)),
)
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

function mapStateToProps(state) {
    return {

    };
}

class FailRetry extends Component {
    render() {
        const { handleRetry } = this.props;
        return (
            <div className="no-data-warning text-center">
                <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ fontSize: "60px", color: "#f26522" }}>
                </i>
                <h3>Can't connect.</h3>
                <Button color="link" onClick={handleRetry}> <i className="fa fa-repeat"></i> Retry </Button>
            </div>
        );
    }
}

const connectedFailRetry = connect(mapStateToProps)(FailRetry);
export { connectedFailRetry as FailRetry }

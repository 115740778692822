import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import swal from 'sweetalert'
import { userManagementActions } from '../../actions';
import { DEFAULT_USER_TYPE } from '../../constants';

const ACTIVE = 1;
const INACTIVE = 0;

export default class Dropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            isActive: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(nextProps, prevState) {
        const { user } = this.props;
        if (user) {
            this.setState({
                id: user.id,
                isActive: parseInt(user.is_active, 10),
            })
        }
    }


    handleChange(e) {
        e.preventDefault()
        const { dispatch, user } = this.props
        const { id, value } = e.target
        swal({
            title: "Are you sure you want to change status?",
            // text: "Once deleted, you will not be able to recover this user!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(userManagementActions.changeUserStatus({ status: value, id: user.id }, this));
                this.setState({ id: id, isActive: value })
            }
        })
    }


    render() {
        const { id, isActive } = this.state;
        const {  user } = this.props
        return (
            <FormGroup style={{ maxWidth:"100px" , marginBottom: '0px' }}>
                <Input
                    type="select"
                    name="isActive"
                    onChange={this.handleChange}
                    size="sm"
                    id={id}
                    value={isActive}
                    className={parseInt(isActive, 10) === ACTIVE ? "bg-success" : "bg-danger"}
                    style={{ color: 'white' }}>
                     disabled={user.user_type ? (user.user_type.name === DEFAULT_USER_TYPE.ADMIN || user.user_type.name === DEFAULT_USER_TYPE.SUPER_ADMIN): false}
                    <option value={ACTIVE}>Active</option>
                    <option value={INACTIVE}>Inactive</option>
                </Input>
            </FormGroup>
        )
    }
} 
import React, { Component } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { show } from 'redux-modal';
import swal from 'sweetalert';
import { DataTable } from '../../../components/Tables/DataTable';
import { notificationActions } from '../../actions';
class Requests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: ''
    }
    this.renderAction = this.renderAction.bind(this)
    this.getNotifications = this.getNotifications.bind(this)
  }
  publishNotification(data) {
    const { dispatch } = this.props;
    dispatch(notificationActions.publishNotification(data, this));
  }
  getNotifications() {
    const { getNotifications } = this.props;
    if(typeof getNotifications === "function") {
      getNotifications();
    }
  }
  renderAction(cell, row, rowIndex, formateExtraData) {
    const { dispatch, publishing } = this.props;
    const confirmPublish = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to publish from draft ? It will visible on reputeinfo.com",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let dataToSent = {
            notification_type: row.notification_type
          };
          this.publishNotification(dataToSent)

        }
      });
    }
    
    // const testNotification = () => {
    //   dispatch(notify({ title: "Notification", position: "tc", message: row.notification_test_text, status: "info" }));
    // }
    const testNotification = () => {
      dispatch(show("bootstrap", {
        ModelSize: "md",
        ModelHeaderText:"Notification",
        ModelBodyText:row.notification_test_text,
        isFooter: false
      }));
  
    }
    return <React.Fragment>
      {parseInt(row.sub_version, 10) === 0 ?
        <Link className="btn btn-success btn-sm" to={"update_notification/" + row.id}> New draft </Link> :
        <Link className="btn btn-primary btn-sm" to={"update_notification/" + row.id}> Edit </Link>} {" "}
      {parseInt(row.sub_version, 10) === 0 ? "" :
        <Button className="btn btn-danger btn-sm" onClick={(e) => confirmPublish()} to={"update_notification/" + row.id}> {publishing ? "Please Wait..." : "Publish to Reputeinfo"} </Button>} &nbsp;
      <Button className="btn btn-warning btn-sm" onClick={(e) => testNotification()} > Test </Button>
    </React.Fragment>
  }

  renderVersion(cell, row, rowIndex, formateExtraData) {
    return row.version + "." + row.sub_version;
  }

  renderNotificationText(cell, row, rowIndex, formateExtraData) {
    row.short_description = row.notification_text;
    row.short_description = row.short_description.length > 80 ? row.short_description.slice(0, 80) + '.....' : row.short_description
    return row.short_description
  }

  componentWillReceiveProps(props) {
    // const { notificationdata } = props
    // if (notificationdata && notificationdata[0]) {
    //   this.setState({
    //     activeTab: notificationdata[0].notification_category
    //   })
    // }
  }

  render() {
    const data = this.props.notificationdata

    const columns = [
      {
        text: 'Notification Text',
        formatter: this.renderNotificationText,
        sort: true
      }, 
      {
        text: 'Description',
        dataField: 'help_text',
        sort: true,
        filter: textFilter({
					placeholder: 'Enter Description'
				}),
      },
      {
        text: 'Usage',
        dataField: 'usage',
        sort: true,
        filter: textFilter({
          placeholder: 'Usage'
        }),
      }, {
        text: 'Last Published',
        dataField: 'last_published',
        sort: true
      }, {
        text: 'Last Saved on',
        dataField: 'updated_at',
      }, {
        text: 'Version',
        formatter: this.renderVersion,
      },
      {
        text: 'Action',
        formatter: this.renderAction

      }]

    return (
      <DataTable keyField="id"
        data={data || []} columns={columns}
        filter={filterFactory()}
        noDataIndication={"No data found"} />
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getNotifications
  const { user } = state.authentication
  return {
    loading,
    data,
    authUser: user
  }

}

const connectedRequests = connect(mapStateToProps)(Requests)
export { connectedRequests as Requests };
//export default Requests
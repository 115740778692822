import React, { Component } from 'react'
import { connect } from 'react-redux'
import { subscriptionActions } from "../../actions";
import {  CardBody, Card, CardHeader, Button } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import moment from 'moment';


class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getSubscription = this.getSubscription.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
        this.getSubscription()
    }

    getSubscription() {
        const { dispatch } = this.props;
        let dataToSend = {
            type: this.state.activeTab
        }
        dispatch(subscriptionActions.getSubscription(dataToSend));
    }
    editData(d) {
        this.props.history.push('/subscriptions/edit/' + d.id)
    }
    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <Button size="sm" color="primary" onClick={() => this.editData(row)} > Edit </Button>  {" "}
        </React.Fragment>
    }
    
    renderLastUpdate(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
             {moment(row.updated_at).format('DD-MM-YYYY hh:mm A')} 
        </React.Fragment>
    }

    render() {
        const { data, loading } = this.props
        const columns = [
            {
                text: 'Plan Title',
                dataField: 'plan_title',
                sort: true
            },
            {
                text: 'Last Update',
                dataField: 'plan_title',
                formatter: this.renderLastUpdate 
            },
            {
                text: 'Action',
                dataField:'action',
                formatter: this.renderAction

            }]
        return (
            <Card>
                <CardHeader>
                    <i className="fas fa-boxes"></i><strong>Subscriptions Details</strong>
                </CardHeader>
                <CardBody>
                    {loading ? <TableLoader /> :
                        <div className="">
                            <DataTable keyField="id"
                                data={data || []} columns={columns}
                                filter={filterFactory()}
                                noDataIndication={"No data found"} />
                        </div>
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getSubscription
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user

    }
}

const connectedSubscriptions = connect(mapStateToProps)(Subscriptions)
export { connectedSubscriptions as Subscriptions }


export function validPassword(value) {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/
    return reg.test(value)
}

export function validAadhaar(value) {
    let reg = /^\d{4}\s\d{4}\s\d{4}$/
    return reg.test(value)
}

export function validPANIndividual(value) {
    let reg = /^[\w]{3}(p|P)[\w][\d]{4}[\w]$/
    return reg.test(value)
}

export function validPANCorporate(value) {
    let reg = /^[\w]{3}(p|P|c|C|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G|k|K)[\w][\d]{4}[\w]$/
    return reg.test(value)
}

export function validGSTIN(value){
    let reg =   /\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}/
    return reg.test(value)
}

export function validPhone(value) {
    let reg =  /^[5-9]\d{9}$/
    return reg.test(value)
}

export function validEmail(value) {
    let reg = new RegExp(/^(([^<>()\]\\.,:\s@"]+(\.[^<>()\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return reg.test(String(value).toLowerCase())
}

export function validAddress(value) {
    let reg  = new RegExp(/^[#.0-9a-zA-Z\s,-]+$/)
    return reg.test(value)
}

export function validReputeIdCorporate(value) {
    let reg = new RegExp(/^RC-\d\d\d\d-\d\d\d\d-\d\d$/)
    return reg.test(value)
}

export function validReputeIdIndividual(value) {
    let reg = new RegExp(/^RI-\d\d\d\d-\d\d\d\d-\d\d$/)
    return reg.test(value)
}

export function validReputeId(value) {
    let reg = new RegExp(/^R(C|I)-\d\d\d\d-\d\d\d\d-\d\d$/)
    return reg.test(value);
}

export function documentValidation(file) {
    
    const fileMinSize = 0.01 * 1000 * 1000 // 10 KB
    const fileMaxSize = 10 * 1000 * 1000 // 10MB
    let Message = false

    // validations
    if (!file) {
        Message = 'this field is required'   
    } else if (!file.name.endsWith('.pdf')) {
        Message = 'Document file must be an .pdf file'
    } else if (file.size < fileMinSize) {
        Message = 'Document file must be atleast 10 kb'
    } else if (file.size > fileMaxSize) {
        Message = 'Document file cannot exceed 10MB size'
    }

    return Message
}


export  function showLoader() {
    document.getElementsByClassName("ajax_loading")[0].style.display = "block";
}

export  function hideLoader() {
    document.getElementsByClassName("ajax_loading")[0].style.display = "none";
}
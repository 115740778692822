import { expenceStatementConstants } from "../constants"

export function addExpenceStatement(state = {}, action) {
  switch (action.type) {
    case expenceStatementConstants.ADD_COMMISSION_CALCULATION_REQUEST:
      return { loading: true }
    case expenceStatementConstants.ADD_COMMISSION_CALCULATION_SUCCESS:
      return { data: action.result }
    case expenceStatementConstants.ADD_COMMISSION_CALCULATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function getExpenceStatement(state = {}, action) {
    switch (action.type) {
      case expenceStatementConstants.GET_COMMISSION_CALCULATION_REQUEST:
        return { loading: true }
      case expenceStatementConstants.GET_COMMISSION_CALCULATION_SUCCESS:
        return { 
          data: action.result.commission_data ,
          last_calculation_date : action.result.last_calculation_date,
          last_calculate_till_date : action.result.last_calculate_till_date
        }
      case expenceStatementConstants.GET_COMMISSION_CALCULATION_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getPayoutDetails(state = {}, action) {
    switch (action.type) {
      case expenceStatementConstants.GET_PAYOUT_DETAILS_REQUEST:
        return { loading: true }
      case expenceStatementConstants.GET_PAYOUT_DETAILS_SUCCESS:
        return {  data: action.result }
      case expenceStatementConstants.GET_PAYOUT_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updatePayoutDetails(state = {}, action) {
    switch (action.type) {
      case expenceStatementConstants.UPDATE_PAYOUT_DETAILS_REQUEST:
        return { loading: true }
      case expenceStatementConstants.UPDATE_PAYOUT_DETAILS_SUCCESS:
        return {  data: action.result }
      case expenceStatementConstants.UPDATE_PAYOUT_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getAllPayoutDetails(state = {}, action) {
    switch (action.type) {
      case expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_REQUEST:
        return { loading: true }
      case expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_SUCCESS:
        return {  data: action.result }
      case expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }
import { FeedbackConstants } from "../constants"

export function getFeedbackData(state = {}, action) {
    switch (action.type) {
        case FeedbackConstants.GET_FEEDBACK_REQUEST:
        return { loading: true }
        case FeedbackConstants.GET_FEEDBACK_SUCCESS:
        return { 
            data: action.result
            }
        case FeedbackConstants.GET_FEEDBACK_FAILURE:
        return {}
        default:
        return state
    }
}

import { processConstants } from "../constants/process.constants";

export function process(state = {}, action) {
  switch (action.type) {
    case processConstants.GET_PROCESS_DATA_REQUEST:
      return { loading: true };
    case processConstants.GET_PROCESS_DATA_SUCCESS:
      return { processList : action.result };
    case processConstants.GET_PROCESS_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

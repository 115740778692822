import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class ViewInvoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
        this.handleHide = this.handleHide.bind(this)
    }

    handleHide() {
        const { show } = this.state;
        this.setState({ show: !show });
    }

    render() {

        
        const iframStyle = {
            border: 0,
            width: "100%",
            height: "calc(100vh - 225px)"
          }

        const { show } = this.state
        const { pdf_url, invoice_number } = this.props
        return <React.Fragment>
            <Button size="sm" color="primary" onClick={this.handleHide} >View Invoice</Button>


            <Modal isOpen={show} size={"lg"} className="modal-primary" toggle={this.handleHide}  >
                <ModalHeader toggle={this.handleHide}>
                    {"Invoice " + invoice_number}
                </ModalHeader>
                <ModalBody>
                    <iframe src={pdf_url}
                        style={iframStyle}
                        title={"Document Preview"} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleHide}>Close</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    }
}
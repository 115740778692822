import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { TableLoader } from '../../../components/Loaders';
import { Collapse } from 'reactstrap';
import Pagination from "react-js-pagination";
import { MilestoneLogsActions } from '../../actions';
import { connect } from 'react-redux'
import moment from 'moment'

const TABS = {
    NumberWise: "NumberWise",
    DateWise: "DateWise",
}
class TabContentData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tooltipOpen: false,
            collapse: [],
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },
            activeTab: ""
        }

        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getMilestoneLogs = this.getMilestoneLogs.bind(this);
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }
    getMilestoneLogs() {
        const { dispatch } = this.props;
        const { page } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            log_type: this.state.activeTab
        }
        dispatch(MilestoneLogsActions.getMilestoneLogs(dataToSend));
    }
    toggle(index) {
        this.setState({
            collapse: {
                [index]: !this.state.collapse[index]
            }
        });
    }
    componentWillReceiveProps(props) {
        let { page, activeTab } = this.state
        page.totalItemsCount = 0;
        if (props.logs) {
            page.totalItemsCount = props.logs.total_records
        }
        if (props.activeTab) {
            activeTab = props.activeTab
        }
        this.setState({
            page,
            activeTab
        })
    }
    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getMilestoneLogs());

    }

    renderDetails(data) {
        let _data = false;
        switch (data.type) {
            case "individuals_registered":
                _data = data.individual_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline" >
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Regisetered Individual</b></label> <br />
                                    {data.individual_details.individual_name} ( {data.individual_details.individual_repute_id})
                                </td>

                                <td> -
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td> -
                                </td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                break;

            case "companies_registered":
                _data = data.individual_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Regisetered Company</b></label> <br />
                                    {data.company_details.company_name} ( {data.company_details.company_repute_id})
                                </td>
                                <td></td>
                                <td></td>

                            </tr>
                        </table>
                    </div>
                </div>
                break;
            case "buyer_requirement_added":
                _data = data.buyer_requirement_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Created by Inividual</b></label> <br />
                                    {data.buyer_requirement_details.individua_name} ( {data.buyer_requirement_details.individual_repute_id})
                                </td>
                                <td>
                                    <label><b>Created by Company</b></label> <br />
                                    {data.buyer_requirement_details.company_name} ( {data.buyer_requirement_details.company_repute_id})
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td>-</td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            break;
            case "quotes_added":
                _data = data.quotesForRequirement_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Created by Inividual</b></label> <br />
                                    {data.quotesForRequirement_details.individua_name} ( {data.quotesForRequirement_details.individual_repute_id})
                                </td>
                                <td>
                                    <label><b>Created by Company</b></label> <br />
                                    {data.quotesForRequirement_details.company_name} ( {data.quotesForRequirement_details.company_repute_id})
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td>-</td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            break;
            case "product_added":
                _data = data.productTag_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Created by </b></label> <br />
                                    {data.productTag_details.created_by}
                                </td>
                                <td>
                                    <label><b>Created Prouct</b></label> <br />
                                    {data.productTag_details.created_prouct_tag}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td>-</td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            break;
            case "association_added":
                _data = data.association_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Created by </b></label> <br />
                                    {data.association_details.created_by}
                                </td>
                                <td>
                                    <label><b>Created Association</b></label> <br />
                                    {data.association_details.created_association_name}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td>-</td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            break;
            case "member_added":
                _data = data.associationCompany_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                            <tr>
                                <td>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>
                                <td>
                                    <label><b>Owner</b></label> <br />
                                    {data.associationCompany_details.individua_name} ( {data.associationCompany_details.individual_repute_id})
                                </td>
                                <td>
                                    <label><b>Member</b></label> <br />
                                    {data.associationCompany_details.company_name} ( {data.associationCompany_details.company_repute_id})
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>
                                <td>-</td>
                                <td >
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            break;
            case "individual_searched":
            case "companies_searched":
                _data = data.search_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline">
                            <tr>
                                <td style={{ width: "33%" }}>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>

                                <td style={{ width: "33%" }}>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>

                                <td style={{ width: "33%" }}>
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                            <tr>

                                <td>
                                    <label><b>Searched by Company</b></label> <br />
                                    {data.search_details.company_name} ( {data.search_details.company_repute_id})
                                </td>
                                <td>
                                    <label><b>Searched by Individual</b></label> <br />
                                    {data.search_details.individual_name} ( {data.search_details.individual_repute_id})
                                </td>
                                <td></td>
                            </tr>

                        </table>
                    </div>
                </div>
                break;

            case "transaction_completed":
            case "transaction_accepted":
            case "paid_credit":
            case "CREDIT_GIVEN":
                _data = data.transaction_details && <div className="row">
                    <div className="col-md-12">
                        <table className="table table-sm table-condensed  table-bordered  table-outline">
                            <tr>
                                <td style={{ width: "33%" }}>
                                    <label><b>Mile Stone Type </b></label> <br />
                                    {data.log_type}
                                </td>

                                <td style={{ width: "33%" }}>
                                    <label><b>Count Crossed</b></label> <br />
                                    {data.count}
                                </td>

                                <td style={{ width: "33%" }}>
                                    <label><b>Created At</b></label> <br />
                                    {data.created_at ? moment(data.created_at).format('DD/MMM/YYYY hh:mm:ss A') : '-'}
                                </td>

                            </tr>
                            <tr>

                                <td>
                                    <label><b>Creditor Company</b></label> <br />
                                    {data.transaction_details.creditor_company_name} ( {data.transaction_details.creditor_company_repute_id})
                                </td>

                                <td>
                                    <label><b>Creditor Individual</b></label> <br />
                                    {data.transaction_details.creditor_individual_name} ( {data.transaction_details.creditor_individual_repute_id})
                                </td>

                                <td rowspan="2">
                                    <label><b>Transactions Details</b></label> <br />
                                    <label><b>Transaction ID :</b></label> {data.transaction_details.transaction_id}  <br />
                                    <label><b>Work Name :</b></label> {data.transaction_details.work_name} <br />
                                    <label><b>Credit Amount :</b></label> {data.transaction_details.credit_amount}  <br />
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    <label><b>Debtor Company</b></label> <br />
                                    {data.transaction_details.debtor_company_name} ( {data.transaction_details.debtor_company_repute_id})
                                </td>
                                <td>
                                    <label><b>Debtor Individual</b></label> <br />
                                    {data.transaction_details.debtor_individual_name} ( {data.transaction_details.debtor_individual_repute_id})
                                </td>
                            </tr>
                        </table>
                    </div></div>

        }
        return _data
    }
    render() {
        const { logs, loading, activeTab } = this.props
        const { collapse, page } = this.state
        let _this = this;

        return (loading ? <TableLoader /> :
            <React.Fragment>
                {/* <div className="table-responsive-sm  animated fadeIn"> */}

                {
                    logs && logs.logs_list && logs.logs_list.length > 0 ?
                        logs.logs_list.map(function (eachData, index) {
                            return <React.Fragment>
                                <div className="milestone-data">

                                {activeTab === TABS.NumberWise ? _this.renderDetails(eachData) : false}
                                </div>

                            </React.Fragment>
                        })
                        : <tr><td colSpan="6" className="text-center">No Data Found</td></tr>
                }

                {/* </div> */}
                {page.totalItemsCount <= page.perPage ? "" :
                    <Pagination
                        activePage={page.activePage}
                        itemsCountPerPage={page.perPage}
                        totalItemsCount={page.totalItemsCount}
                        pageRangeDisplayed={5}
                        linkClass="page-link"
                        itemClass="page-item"
                        onChange={this.handlePageChange}
                    // hideNavigation={false}
                    // hideFirstLastPages={true}
                    />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getMilestoneLogs;
    return {
        loading,
        data
    }
}
const connectedTabContentData = connect(mapStateToProps)(TabContentData)
export { connectedTabContentData as TabContentData }

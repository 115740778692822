import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader, Button } from 'reactstrap';
import { Link } from "react-router-dom"
import { CompanySectorAction } from '../../actions/companySector.action'
import { TableLoader } from '../../../components/Loaders';
import swal from 'sweetalert'
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import { userHasPermission } from '../../../helpers';
import { PERMISSIONS } from '../../constants';

class CompanySector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false
        }
        this.dropdowntoggle = this.dropdowntoggle.bind(this)
        this.getCompanySectorData = this.getCompanySectorData.bind(this)
        this.deleteData = this.deleteData.bind(this)
        this.renderAction = this.renderAction.bind(this)
    }

    dropdowntoggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }
    componentDidMount() {
        this.getCompanySectorData()
    }
    getCompanySectorData() {
        const { dispatch } = this.props;
        dispatch(CompanySectorAction.getCompanySectorData());
    }
    editData(d) {
        this.props.history.push('/company-sector/edit/' + d.id)
    }
    deleteData(d) {
        const { dispatch } = this.props;
        var dataToSend = {
            "id": d.id
        }
        let _this = this
        swal({
            title: "Are you sure you want to delete ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // Here delete email id and call action here
                dispatch(CompanySectorAction.deleteCompanySectorData(dataToSend, _this));

            }
        });
    }

    afterAction() {
        this.getCompanySectorData()
    }
    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            {userHasPermission(PERMISSIONS.EDIT_COMPANY_SECTOR) && row.name.toLowerCase() !== "others" ? <React.Fragment>
                <Button size="sm" color="primary" onClick={() => this.editData(row)} > Edit </Button>  {" "}
                <Button size="sm" color="danger" onClick={() => this.deleteData(row)} > Delete </Button>
            </React.Fragment> : "-"}
        </React.Fragment>
    }
    renderActive(cell, row, rowIndex, formateExtraData) {
        return parseInt(row.is_active, 6) === 1 ? 'Yes' : 'No'
    }
    render() {
        const { loading, data } = this.props
        const columns = [
            {
                text: 'Sector Name',
                dataField: 'name',
                sort: true
            },
            {
                text: 'Is Active',
                dataField: 'is_active',
                formatter: this.renderActive,
                sort: true
            },
            {
                text: 'Action',
                dataField: 'action',
                formatter: this.renderAction

            }]
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="icon-directions"></i> <b>Company Sectors</b></CardHeader>
                            <CardBody>
                                {
                                    loading ? <TableLoader /> :
                                        <div>
                                            {userHasPermission(PERMISSIONS.ADD_COMPANY_SECTOR) ? <React.Fragment>
                                                <Link className="btn btn-primary btn-sm pull-right" to={"/company-sector/add"}> Add New </Link><br /><br />
                                            </React.Fragment> : ""}
                                            <DataTable keyField="id"
                                                data={data || []} columns={columns}
                                                filter={filterFactory()}
                                                noDataIndication={"No data found"} />
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getCompanySectorDetail
    return {
        loading,
        data
    }
}

const connectedCompanySector = connect(mapStateToProps)(CompanySector)
export { connectedCompanySector as CompanySector }

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class ChangePasswordModal extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    
        this.onSave = this.onSave.bind(this)
        this.toggle = this.toggle.bind(this)
      }
    
      toggle() {
          const { onClose } = this.props
          onClose()
      }

      onSave() {
        const { onSave } = this.props
        onSave()
      }

    render() {
        const {
            header,
            content
        } = this.props
        return (
            <Modal isOpen={true} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>{header}</ModalHeader>
                <ModalBody>
                   {content}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onSave}>Save</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ChangePasswordModal
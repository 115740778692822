import { sendBillTransactionConstants } from "../constants"


export function getSendBillTransaction(state = {}, action) {
    switch (action.type) {
        case sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_REQUEST:
            return { loading: true }
        case sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_SUCCESS:
            return { data: action.result }
        case sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_FAIL:
            return {}
        default:
            return state
    }
}
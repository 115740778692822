import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, FormFeedback, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router';
import { customerDetailAction } from '../../../actions';
import swal from 'sweetalert';
import { show, hide } from 'redux-modal';

import { CustomerPrioritiesOptions } from '../../CustomerDetail';
class UpdateCustomerPriority extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: {
				id: '',
				priority: ''
			},
			errors: {}
		};
		this.handleChange = this.handleChange.bind(this);
		this.handelUpdateCustomerPriority = this.handelUpdateCustomerPriority.bind(this);
		this.afterAction = this.afterAction.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handelCansel = this.handelCansel.bind(this);
	}
	toggle() {
		const { onClose } = this.props;
		onClose();
	}

	handleChange(e) {
		e.preventDefault();
		const { formData } = this.state;
		const { name, value } = e.target;

		formData[name] = value;

		this.setState({ formData }, () => this.handelUpdateCustomerPriority());
	}

	handelCansel() {
		const { dispatch } = this.props;
		dispatch(hide('bootstrap'));
	}
	afterAction() {
		const { dispatch, getCustomerList } = this.props;
		dispatch(hide('bootstrap'));
		getCustomerList();
	}

	handelUpdateCustomerPriority(e) {
		if (e) {
			e.preventDefault();
		}
		const { formData } = this.state;
		const { dispatch } = this.props;

		swal({
			title: 'Are you sure ?',
			text: 'Do you want to update customer priority?',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((confirm) => {
			if (confirm) {
				formData.type = 'priority';
				dispatch(customerDetailAction.updateCustomerDetilEmail(formData, this));
			}
		});
	}
	componentDidMount() {
		const { customerDetail } = this.props;
		let { formData } = this.state;
		if (customerDetail) {
			formData.id = customerDetail.id;
			formData.priority = customerDetail.priority ? customerDetail.priority : '';
			this.setState({ formData });
		}
	}
	render() {
		const { formData } = this.state;
		return (
			<React.Fragment>
				<CustomerPrioritiesOptions handleChange={this.handleChange} priority={formData.priority} />
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data, customerDetail } = state.getCustomerStatusData;
	return {
		loading,
		data,
		customerDetail
	};
}

const connectedUpdateCustomerPriority = withRouter(connect(mapStateToProps)(UpdateCustomerPriority));
export { connectedUpdateCustomerPriority as UpdateCustomerPriority };

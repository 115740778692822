import moment from 'moment'
import React, { Component } from 'react'
import { DateRangePicker } from 'react-bootstrap-daterangepicker'
import { connect } from 'react-redux'
import { Button, Col, FormGroup, Input, Row } from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import { validEmail } from '../../../helpers'
import { businessAccountsActions, communicationMastersTemplatesActions } from '../../actions'
import { MarketingActions } from '../../actions/marketing.action'
import { InputDropDown } from '../Settings/StageSetting/Components/FormElements'
import ScheduleEnableDisable from './ScheduleEnableDisable'
import ScheduleForm from './ScheduleForm'
import ScheduleStop from './ScheduleStop'

const defaultFormData = {
    communication_template_master_id: '',
    state_id: '',
    city_id: '',
    sector_id: '',
    keyword: '',
    category: '',
    target: '',
    dateRange: '',
}

const ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}

const SearchResultCount = ({ title, count, action }) => <div className='col-md-4'>
    <div className='card card-success'>
        <div className='card-body text-center'>
            <h5>{title}</h5>
            <h2>{count}</h2>
            {action}
        </div>
    </div>
</div>
const ResultBadge = ({ title, value, color }) => value ?
    <label className={"badge badge-success mr-2 " + color}>
        {title} : {value}
    </label> : ''

class Marketing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formdata: { ...defaultFormData },
            errors: {}
        }
        this.getData = this.getData.bind(this);
        this.reset = this.reset.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.isValidate = this.isValidate.bind(this)
        this.fetchResults = this.fetchResults.bind(this)
        this.handleEvent = this.handleEvent.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        const { dispatch } = this.props;

        dispatch(communicationMastersTemplatesActions.getCommunicationMastersTemplates())
        dispatch(businessAccountsActions.getSectors())
        dispatch(businessAccountsActions.getStates())

    }

    validate() {
        const { formdata, errors } = this.state
        if (formdata.communication_template_master_id == '') {
            errors['communication_template_master_id'] = 'Please select template';
        }
        this.setState({
            errors
        })

        return Object.keys(errors).length === 0 ? false : true;
    }

    fetchResults() {

        const { dispatch } = this.props;
        const { formdata } = this.state;

        let dataToSend = {
            ...formdata
        }

        dispatch(MarketingActions.fetchResult(dataToSend))
    }


    handleChange(e) {

        const { formdata, errors } = this.state
        formdata[e.target.name] = e.target.value
        errors[e.target.name] = ""
        this.isValidate();
        this.setState({ formdata })
    }

    handleChangeDate(data) {

        const { formdata, errors } = this.state
        formdata[data.name] = data.value
        errors[data.name] = ""
        this.setState({ formdata })
    }

    isValidate() {
        let errors = {};
        if (!validEmail(this.state.formdata.recipient_email)) {
            errors["recipient_email"] = "Please enter valid email address"
            this.setState({ errors });
            return false;
        }
        return true
    }

    reset() {
        this.setState({
            formdata: { ...defaultFormData },
            startDate: moment(),
            endDate: moment(),
            errors: {}
        })
        const { dispatch } = this.props;

        dispatch({ type: "FETCH_BUSINESSES_RESULT_FAILURE" })

    }

    handleEvent(event, picker) {
        var start = picker.startDate.format("DD MMM Y");
        var end = picker.endDate.format("DD MMM Y");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }

        const { formdata } = this.state;
        formdata.dateRange = label;
        formdata.startDate = picker.startDate.format('YYYY-MM-DD')
        formdata.endDate = picker.endDate.format('YYYY-MM-DD')
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formdata,
            errors: {}
        })
    }


    render() {
        const { templates, sectors, states, count, data } = this.props
        const { formdata, errors } = this.state

        let templateList = [{ title: "Select template", value: false }]
     
        if (templates) {
            
            templates.map((each) => {
                let title = `${each.name} - ${each.schedule_whatsapp_template.title} - ${each.is_hook ? "[HOOK]" : ''} ${each.schedule_whatsapp_template.status !== "active" ? " ["+each.schedule_whatsapp_template.status + "] "  : ''}`;
                // console.log(each);
                templateList.push({ title , value: each.id });
            });
        }

        let sectorOptions = [{ title: "Select Sector", value: false }]
        if (sectors) {
            sectors.map((item) => {
                if (item.id != 0) {
                    sectorOptions.push({ title: item.name, value: item.id })
                }
            });
        }

        let statesList = [{ title: "Select State", value: false }]
        let cityList = [{ title: "Select District", value: false }]
        if (states) {
            states.map((state) => {
                statesList.push({ title: state.state_name, value: state.id })
                if (parseInt(formdata.state_id, 10) === parseInt(state.id, 10)) {
                    state.cities.map((ct) => {
                        cityList.push({ title: ct.city_name, value: ct.id })
                    });
                }
            });
        }

        let template = data && data.template && data.template.schedule_whatsapp_template ?  data.template.schedule_whatsapp_template : {};


        console.log(template.media_url);
        return (
            <div className="">
                <Row>
                    <Col xs="12">

                        <ScheduleEnableDisable data={data} />

                        <div className='row'>

                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold"> Template </label>
                                    <InputDropDown
                                        value={formdata.communication_template_master_id}
                                        name={"communication_template_master_id"}
                                        onChange={this.handleChange}
                                        options={templateList}
                                    />

                                    <FormFeedback>
                                        {errors[`communication_template_master_id`] && errors[`communication_template_master_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold"> Keyword </label>
                                    <Input
                                        value={formdata.keyword}
                                        name={"keyword"}
                                        placeholder={"Keyword"}
                                        onChange={this.handleChange}
                                    />

                                    <FormFeedback>
                                        {errors[`keyword`] && errors[`keyword`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold">State</label>

                                    <InputDropDown
                                        value={formdata.state_id}
                                        name={"state_id"}
                                        onChange={this.handleChange}
                                        options={statesList}
                                    />

                                    <FormFeedback>
                                        {errors[`state_id`] && errors[`state_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold">District</label>

                                    <InputDropDown
                                        value={formdata.city_id}
                                        name={"city_id"}
                                        onChange={this.handleChange}
                                        options={cityList}
                                    />

                                    <FormFeedback>
                                        {errors[`city_id`] && errors[`city_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold">Sector </label>
                                    <InputDropDown
                                        value={formdata.sector_id}
                                        name={"sector_id"}
                                        onChange={this.handleChange}
                                        options={sectorOptions}
                                    />

                                    <FormFeedback>
                                        {errors[`sector_id`] && errors[`sector_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold">Category</label>
                                    <InputDropDown
                                        value={formdata.category}
                                        name={"category"}
                                        onChange={this.handleChange}
                                        options={[
                                            { title: "Select Category", value: '' },
                                            { title: 'Leads', value: 'lead' },
                                            { title: 'Registered', value: 'registered' },
                                        ]}
                                    />

                                    <FormFeedback>
                                        {errors[`sector_id`] && errors[`sector_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup>
                                    <label className="font-weight-bold">Target</label>
                                    <InputDropDown
                                        value={formdata.target}
                                        name={"target"}
                                        onChange={this.handleChange}
                                        options={[
                                            { title: "All", value: '' },
                                            { title: 'New [ Not sent till now ]', value: 'new_target' },
                                            { title: 'Old [ Previously sent ]', value: 'old_target' },
                                        ]}
                                    />

                                    <FormFeedback>
                                        {errors[`sector_id`] && errors[`sector_id`]}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <label className="font-weight-bold">Select Date Range</label>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    //onApply={this.handleApply}
                                    onEvent={this.handleEvent}
                                    ranges={ranges}
                                >
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Select Date Range" value={formdata.dateRange} id="date_picker" style={{ 'width': '220px' }} />
                                        <span className="input-group-btn">
                                            <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                                <i className="fa fa-calendar" />
                                            </Button>
                                        </span>
                                    </div>
                                </DateRangePicker>
                            </div>
                            <div className='col-md-3'>
                                <label> &nbsp; </label><br />

                                <Button className="mr-3" color="primary" onClick={() => this.fetchResults()}>
                                    Fetch Result
                                </Button>

                                <Button color="secondary" onClick={() => this.reset()}>
                                    Reset
                                </Button>
                            </div>
                        </div>

                        <hr />



                        {data &&
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5> Result : <br />

                                        <ResultBadge color="badge-danger" title="Template" value={data.parameters.template_name} />
                                        <ResultBadge title="Keyword" value={data.parameters.keyword} />
                                        <ResultBadge title="State" value={data.parameters.state_name} />
                                        <ResultBadge title="District" value={data.parameters.city_name} />
                                        <ResultBadge title="Sector" value={data.parameters.sector_name} />
                                        <ResultBadge title="Category" value={data.parameters.category} />




                                    </h5>

                                    <h4>{data.total_business_accounts} Businesses Found</h4>

                                    <div className='row'>
                                        <div className='col-md-8'>

                                            <table className='table table-sm table-bordered' style={{ fontSize: "10px" }}>
                                                <thead>
                                                    <tr>
                                                        <th>SR.NO.</th>
                                                        <th>Business name</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Sector</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.data && data.data.map(function (each, idx) {
                                                        return <tr key={idx}>
                                                            <th>{idx + 1}</th>
                                                            <th>{each.business_name}</th>
                                                            <th>{each.city}</th>
                                                            <th>{each.state}</th>
                                                            <th>{each.sector}</th>
                                                        </tr>
                                                    })}


                                                </tbody>

                                            </table>

                                        </div>

                                        <div className='col-md-4'>
                                            { data && data.template && data.template.schedule_whatsapp_template ? 
                                                <div className="chat" style={{height:"300px", 'borderRadius': "10px", 'overflow':'hidden'}}>
                                                    <div className="chat-container" >
                                                        <div className="conversation">
                                                            <div className="conversation-container" style={{height:"100%"}}>

                                                                <React.Fragment>
                                                                    <div className="message received">
                                                                        <div className="mb-3">
                                                                            <b className={"m-2"}>
                                                                                ~ Sample Preview
                                                                            </b>
                                                                            <i className="pull-right text-muted">
                                                                                +91 95 1023 1023
                                                                            </i>
                                                                        </div>

                                                                        {/* <td>{eachData.content_type}{eachData.sender}</td> */}
                                                                        <div className="text-center">
                                                                            <div className="text-center">
                                                                                {template.media_type === "video" && (
                                                                                    <video
                                                                                    style={{ maxHeight: "180px" }}
                                                                                        controls={true}
                                                                                        src={template.media_url}
                                                                                    ></video>
                                                                                )}
                                                                                {data.template.media_type === "image" && (
                                                                                    <img
                                                                                        style={{ maxHeight: "180px" }}
                                                                                        controls={true}
                                                                                        src={template.media_url}
                                                                                    />
                                                                                )}

                                                                                {data.template.media_type === "document" && (
                                                                                    <a
                                                                                        className="btn btn-primary btn-block"
                                                                                        href={template.media_url}
                                                                                        target="_blank"
                                                                                    >
                                                                                        Open file
                                                                                    </a>
                                                                                )}
                                                                            </div>

                                                                            {/* {eachData.caption} */}
                                                                            {template.template_text}
                                                                        </div>

                                                                        <span className="metadata">
                                                                            <span className="time">
                                                                                <b>
                                                                                    <i className="fa fa-clock"></i>{" "}
                                                                                    {moment().format(
                                                                                        "DD-MM-YYYY hh:mm:ss a"
                                                                                    )}
                                                                                </b>
                                                                            </span>
                                                                        </span>
                                                                    </div>

                                                                    {/* <tr> */}
                                                                    {/* <td>{index}</td> */}
                                                                    {/* <td>{eachData.sender}</td> */}
                                                                    {/* <td>{eachData.content_type}</td> */}
                                                                    {/* <td className='text-center'>
																
															</td> */}
                                                                    {/* <td>{eachData.received_at}</td> */}
                                                                    {/* </tr> */}
                                                                </React.Fragment>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : false}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <SearchResultCount title={"WhatsApp Not Scheduled/Sent"} count={data.whatsapp_not_scheduled}
                                            action={data.whatsapp_not_scheduled ? <React.Fragment>
                                                <ScheduleForm data={data} fetchResults={() => this.fetchResults()} />
                                            </React.Fragment> : false}
                                        />
                                        <SearchResultCount title={"WhatsApp Scheduled"} count={data.whatsapp_scheduled} action={<React.Fragment>
                                            <ScheduleStop data={data} actionsFetch={MarketingActions.fetchResult(data.parameters)} />
                                        </React.Fragment>} />
                                        <SearchResultCount title={"WhatsApp Sent"} count={data.whatsapp_sent} />
                                    </div>



                                </div>
                            </div>}


                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const { sectors } = state.sectors
    const { states } = state.states
    const { templates } = state.communicationMastersTemplates
    const { data } = state.fetchBusinessResults
    return {
        templates,
        sectors,
        states,
        data

    }
}

const connectedMarketing = connect(mapStateToProps)(Marketing)
export { connectedMarketing as Marketing }


import { notify } from 'reapop'
import { productsConstants } from '../constants'
import { productsService } from '../services'

export const productsAction = {
    getProducts,
    getProduct,
}


function getProducts(data) {
    return dispatch => {
        dispatch(request())

        productsService.getProducts(data)
            .then(
                verified =>{
                    console.log(`verified`);
                    console.log(verified);
                     dispatch(success(verified))
                    },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type:  productsConstants.GET_PRODUCTS_REQUEST } }
    function success(result) { return { type:  productsConstants.GET_PRODUCTS_SUCCESS, result } }
    function failure(error) { return { type:  productsConstants.GET_PRODUCTS_FAIL, error } }
}

function getProduct(data) {
    return dispatch => {
        dispatch(request())

        productsService.getProduct(data)
            .then(
                verified =>{
                    console.log(`verified`);
                    console.log(verified);
                     dispatch(success(verified))
                    },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type:  productsConstants.GET_PRODUCT_REQUEST } }
    function success(result) { return { type:  productsConstants.GET_PRODUCT_SUCCESS, result } }
    function failure(error) { return { type:  productsConstants.GET_PRODUCT_FAIL, error } }
}

import {  reputeinfoConstants } from "../constants"


export function recentRegistrations(state = {}, action) {
    switch (action.type) {
        case reputeinfoConstants.GET_RECENT_REGISTRATIONS_REQUEST:
            return { loading: true }
        case reputeinfoConstants.GET_RECENT_REGISTRATIONS_SUCCESS:
            return { data: action.result }
        case reputeinfoConstants.GET_RECENT_REGISTRATIONS_FAILURE:
            return {}
        default:
            return state
    }
}

export function recentRegisternAttempt(state = {}, action) {
    switch (action.type) {
        case reputeinfoConstants.GET_RECENT_ATTEMPT_REQUEST:
            return { loading: true }
        case reputeinfoConstants.GET_RECENT_ATTEMPT_SUCCESS:
            return { data: action.result }
        case reputeinfoConstants.GET_RECENT_ATTEMPT_FAILURE:
            return {}
        default:
            return state
    }
}

export function getSetting(state = {}, action) {
    switch (action.type) {
        case reputeinfoConstants.GET_SETTINGS_REQUEST:
            return { loading: true }
        case reputeinfoConstants.GET_SETTINGS_SUCCESS:
            return { settings: action.result }
        case reputeinfoConstants.GET_SETTINGS_FAILURE:
            return {}
        default:
            return state
    }
}

export function getProfileWeightageSetting(state = {}, action) {
    switch (action.type) {
        case reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_REQUEST:
            return { loading: true }
        case reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_SUCCESS:
            return { settings: action.result }
        case reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_FAILURE:
            return {}
        default:
            return state
    }
}
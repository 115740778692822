import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import {authHeader} from "../../helpers";

export const subscriptionService = {
    getSubscription,
    updateSubscription
}

function getSubscription(dataToSend) {
    var form_data = dataToSend
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/subscribsionlist`,form_data, setting).then(handleResponse)
}

function updateSubscription(dataToSend) {
    var form_data = dataToSend
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/subscribsionupdate`,form_data, setting).then(handleResponse)
}

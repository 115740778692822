import { subscriptionLogConstants } from "../constants"


export function getSubscriptionLogs(state = {}, action) {
    switch (action.type) {
        case subscriptionLogConstants.GET_SUBSCRIPTION_LOG_REQUEST:
            return { loading: true }
        case subscriptionLogConstants.GET_SUBSCRIPTION_LOG_SUCCESS:
            return { data: action.result }
        case subscriptionLogConstants.GET_SUBSCRIPTION_LOG_FAILURE:
            return {}
        default:
            return state
    }
}
import React from 'react'
import { connect } from 'react-redux';
import {  VideoLogsAction } from '../../actions';
import { CardBody, Card, CardHeader, Input, Button } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import moment from 'moment'
import { useState } from 'react';
import { useEffect } from 'react';


const Videos = (props) => {
    const [page, setPage] = useState({
        activePage: 1,
        perPage: 20,
        totalItemsCount: 0,
        keywords: ''
    })

    useEffect(() => {
        getData()
    }, [page]);

    // const handlePageChange = (pageNumber) => {

    //     setPage({
    //         ...page,
    //         activePage: pageNumber
    //     })
    // }

    const getData = () => {
        const { dispatch } = props;
        const { activePage, perPage, keywords } = page;
        let dataToSend = {
            active_page: activePage,
            per_page: perPage,
            keywords: keywords
        };
        dispatch(VideoLogsAction.getVideoLogData(dataToSend));
    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target

    //     setPage({
    //         ...page, activePage: 1, [name]: value
    //     })
    // }

    // const reset = () => {
    //     setPage({
    //         activePage: 1,
    //         perPage: 20,
    //         totalItemsCount: 0,
    //         keywords: ''
    //     })
    // }

    const { data, loading } = props;
    // const { activePage, perPage, keywords } = page;
    return (
        <Card>
            <CardHeader>
                <i className="fas fa-envelope" />
                <strong>Video Logs</strong>
            </CardHeader>
            <CardBody>
                {/* <div className="d-flex mb-2 " >
                    <Input name='keywords' className='w-25 mr-2' value={keywords} onChange={handleChange} placeholder='Search...' />
                    <Button color='' className='bg-secondary text-light' onClick={reset}>
                        Reset
                    </Button>
                </div> */}
                {loading ? (
                    <TableLoader />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive-sm  animated fadeIn">
                            <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                <thead className="thead-light">
                                    <tr>
                                        <th>No.</th>
                                        <th style={{width:'200px'}} >Title</th>
                                        <th>Video </th>
                                        <th>Cover Image</th>
                                        <th>View Count</th>
                                        <th>Is Featured</th>
                                        <th>Date and Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.videolist && data.videolist.length ? (
                                        data.videolist.map(function (eachData, index) {
                                            return (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>{eachData.video_id}</td>
                                                        <td>{eachData.title}</td>
                                                        <td>
                                                            <video
                                                                style={{ maxHeight: "150px" }}
                                                                controls={true}
                                                                src={eachData.url}
                                                            />
                                                        </td>
                                                        <td>
                                                            <img
                                                                style={{ maxHeight: "150px" }}
                                                                controls={true}
                                                                src={eachData.image}
                                                            />
                                                        </td>
                                                        <td>{eachData.view_counts}</td>
                                                        <td>{eachData.is_featured}</td>
                                                        <td>{eachData.created_at ? moment(eachData.created_at).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/* {data && data.videolist && data.videolist.length && <div>
                            {data.total_records <= perPage ? (
                                ''
                            ) : (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={data.total_records}
                                    pageRangeDisplayed={5}
                                    linkClass="page-link"
                                    itemClass="page-item"
                                    onChange={handlePageChange}

                                />
                            )}
                        </div>} */}
                    </React.Fragment>
                )}
            </CardBody>
        </Card>
    )
}




function mapStateToProps(state) {
    const { loading, data } = state.getVideoData;
    return {
        loading, data
    };
}



const connectedVideos = connect(mapStateToProps)(Videos);
export { connectedVideos as Videos };






import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";
import { UserActivity } from "../../actions";
import ChartView from "./ChartView";

const CardItem = ({ title, value, icon }) => {
  return <div className="col-sm-6 col-md-4 col-lg-3  p-3"
  >
    <div
      className="content  rounded d-flex justify-content-between p-3 align-items-center text-center"
      style={{ backgroundColor: "#F7FAFB" }}
    >
      <div
        className="left rounded-circle"
        style={{  
          height:60,
          width:60,
          lineHeight:"60px",
          verticalAlign:'middle',
          justifyContent:'center',
           alignItems:'center', 
           backgroundColor: "#D3EAFF", 
           fontSize: 20 
          }}
      >
          <b>
            {icon}
          </b>
      </div>
      <div className="right text-right">
        <b style={{ fontSize: 18 }}>
          {" "}
          <span className="content2">
            {!value ? (
              <i className="fa fa-spin fa-spinner" />
            ) : (
              value
            )}
          </span>{" "}
        </b>
        <span className="content2" x></span>
        <div className="contentheading">
          <h6 className="content2">{title}</h6>
        </div>
      </div>
    </div>
  </div>
}


function MainTab(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [activeTimeDuration, setActiveTimeDuration] = useState("1M");
  const [activeTab, setActiveTab] = useState("No-of-Register-Company");
  const [activeType, setActiveType] = useState(1);
  const [activeCustom, seteActiveCustom] = useState(false);
  const [dateData, setDateData] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
    serachField: {
      dateRange:
        moment().subtract(30, "days").format("DD MMM Y") +
        "-" +
        moment().format("DD MMM Y"),
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
  });
  const handleTabClick = (tabId, type) => {
    setActiveTab(tabId);
    setActiveType(type);
    seteActiveCustom(false);
    fetchData(startDate, endDate, type);
  };
  const { dailyMisData, total } = props;
  console.log("dailyMisDataconsole", dailyMisData);
  function fetchData(startDate, endDate, newtype) {
    const { dispatch } = props;
    let dataToSend = {
      end_date: startDate.format("YYYY-MM-DD"),
      start_date: endDate.format("YYYY-MM-DD"),
      type: newtype,
    };
    dispatch(UserActivity.getUserActivityDashboard(dataToSend));
  }

  const handleApply = () => {
    const { startDate, endDate } = dateData;
    if (!validation()) {
      fetchData(startDate, endDate, activeType);
    }
  };

  useEffect(() => {
    handleTimeDuration("1M");
    seteActiveCustom(false);
  }, []);

  const handleReset = () => {
    handleTimeDuration("1M");
  };

  const validation = () => {
    const { startDate, endDate } = dateData;
    let error = false;
    let errors = {};
    if (startDate === "" || endDate === "") {
      errors.startDate = "Please select Date";
      error = true;
    }
    setErrors({ errors });
    return error;
  };

  const handleEvent = (event, picker) => {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + " to " + end;
    if (start === end) {
      label = start;
    }
    setDateData({
      ...dateData,
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label,
      },
    });
    seteActiveCustom(true);
  };

  useEffect(() => {
    if (activeCustom) {
      handleApply();
    }
  }, [dateData.serachField.dateRange]);

  useEffect(() => {
    setData(dailyMisData);
    console.log("dailyMisDataaa", dailyMisData);
  }, [dailyMisData]);
  const myStyles = {
    borderColor: "#e5e6e7",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#c8ced330",
  };
  const handleButtonClick = () => {
    setActiveTimeDuration("custom");
  };
  const handleTimeDuration = (duration) => {
    seteActiveCustom(false);
    setActiveTimeDuration(duration);
    const today = moment();
    let startDate, endDate;

    switch (duration) {
      case "1M":
        startDate = today.clone().subtract(1, "months");
        break;
      case "6M":
        startDate = today.clone().subtract(6, "months");
        break;
      case "1Y":
        startDate = today.clone().subtract(1, "years");
        break;
      case "YTD":
        startDate = today.clone().startOf("year");
        break;
      case "ALL":
        startDate = moment("2000-01-01");
        break;
      default:
        break;
    }

    endDate = today;

    setDateData({
      startDate: startDate,
      endDate: endDate,
      serachField: {
        dateRange:
          startDate.format("DD MMM Y") + "-" + endDate.format("DD MMM Y"),
      },
    });

    setData([]);
    setErrors({});
    fetchData(startDate, endDate, activeType);
  };

  const tabData = [
    {
      id: "No-of-Register-Company",
      type: 1,
      label: "Register Company",
      filterKey: "registered_company_filter",
    },
    {
      id: "No-of-Register-Individual",
      type: 2,
      label: "Register Individual",
      filterKey: "registered_individual_filter",
    },
    {
      id: "No-of-BadDebt",
      type: 3,
      label: "Register BadDebt",
      filterKey: "bad_debt_transactions_filter",
    },
    {
      id: "searches",
      type: 4,
      label: "Searches",
      filterKey: "searches_filter",
    },
    {
      id: "No-of-Unregister-Company",
      type: 5,
      label: "Unregister Company",
      filterKey: "unregistered_company_filter",
    },
    {
      id: "No-of-Unregister-BadDebt",
      type: 6,
      label: "Unregister BadDebt",
      filterKey: "bad_debt_accounts_filter",
    },
    {
      id: "No-of-Send-Bill-Transactions",
      type: 7,
      label: "Send Bill Transactions",
      filterKey: "send_bill_transactions_filter",
    },
    {
      id: "No-of-Receive-Bill-Transactions",
      type: 8,
      label: "Receive Bill Transactions",
      filterKey: "receive_bill_transactions_filter",
    },
  ];
  const { startDate, endDate, ranges, serachField } = dateData;
  return (
    <Card style={{ border: "none", marginBottom: "unset" }}>
      <React.Fragment>
        <div className="containar">
          <div className="row mx-1">
            <CardItem
              title={"Total Activity"}
              icon={<i className="fa fa-list" aria-hidden="true"></i>}
              value={!dailyMisData ? false : dailyMisData.total_activity}
            />
            <CardItem
              title={"Total Revenue"}
              icon={<i className="fa fa-user-o" aria-hidden="true"></i>}
              value={!dailyMisData ? false : dailyMisData.total_revenue}
            />
            
            <CardItem
              title={"Customer's Total Balance"}
              icon={<i className="fa fa-money" aria-hidden="true"></i>}
              value={!dailyMisData ? false : dailyMisData.total_current_balance}
            />
          </div>
        </div>

        <div className="row p-0 m-0">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="list-group" id="list-tab" role="tablist">
              <table className="mt-2 table table-sm table-condensed  table-striped">
                {tabData.map((tab) => (
                  <tr>
                    <td className="border-0">
                      {" "}
                      <a
                        style={{ cursor: "pointer", fontSize: 15 }}
                        key={tab.id}
                        className={`list-group-item border border-primary${activeTab === tab.id
                            ? "active bg-primary text-white"
                            : ""
                          }`}
                        id={tab.id}
                        onClick={() => handleTabClick(tab.id, tab.type)}
                      >
                        {tab.label}
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-9 p-3">
            <div className="tab-content" id="nav-tabContent">
              <div className="mt-3 d-flex justify-content-center flex-wrap">
                <Button
                  color={`border rounded ${
                    activeTimeDuration === "1M" ? "primary" : ""
                  }`}
                  className={`border rounded ${
                    activeTimeDuration === "1M"
                      ? "default border-bottom  bg-primary  text-white"
                      : "border-bottom"
                  } px-4 py-1 m-2`}
                  onClick={() => handleTimeDuration("1M")}
                >
                  1M
                </Button>
                <Button
                  color={`border rounded ${
                    activeTimeDuration === "6M" ? "primary" : ""
                  }`}
                  className={`border rounded ${
                    activeTimeDuration === "6M"
                      ? "default border-bottom  bg-primary  text-white"
                      : "border-bottom"
                  } px-4 py-1 m-2`}
                  onClick={() => handleTimeDuration("6M")}
                >
                  6M
                </Button>
                <Button
                  color={`border rounded ${
                    activeTimeDuration === "YTD" ? "primary" : ""
                  }`}
                  className={`border rounded ${
                    activeTimeDuration === "YTD"
                      ? "default border-bottom  bg-primary  text-white"
                      : "border-bottom"
                  } px-4 py-1 m-2`}
                  onClick={() => handleTimeDuration("YTD")}
                >
                  YTD
                </Button>
                <Button
                  color={`border rounded ${
                    activeTimeDuration === "ALL" ? "primary" : ""
                  }`}
                  className={`border rounded ${
                    activeTimeDuration === "ALL"
                      ? "default border-bottom  bg-primary  text-white"
                      : "border-bottom"
                  } px-4 py-1 m-2`}
                  onClick={() => handleTimeDuration("ALL")}
                >
                  ALL
                </Button>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onEvent={handleEvent}
                  ranges={ranges}
                >
                  <div className="input-group">
                    <Button
                      color={`border rounded ${
                        activeTimeDuration === "custom" ? "primary" : ""
                      }`}
                      className={`border rounded ${
                        activeTimeDuration === "custom"
                          ? "default border-bottom  bg-primary  text-white"
                          : "border-bottom"
                      } px-4 py-1 m-2`}
                      onClick={handleButtonClick}
                    >
                      Custom
                    </Button>
                    <input
                      type="text"
                      className={` ${
                        activeTimeDuration === "custom"
                          ? "form-control mb-2 mt-2"
                          : "d-none"
                      } `}
                      value={serachField.dateRange}
                      placeholder="Select Date Range"
                      id="date_picker"
                      style={{ width: "220px" }}
                    />
                    <span className="input-group-btn  mb-2 mt-2">
                      <Button
                        className={` ${
                          activeTimeDuration === "custom"
                            ? "default date-range-toggle"
                            : "d-none"
                        } `}
                        onClick={handleButtonClick}
                      >
                        <i className="fa fa-calendar" />
                      </Button>
                    </span>
                  </div>
                </DateRangePicker>
              </div>

              {tabData.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                    }`}
                  id={tab.id}
                  role="tabpanel"
                >
                  {dailyMisData &&
                    dailyMisData[tab.filterKey] &&
                    dailyMisData[tab.filterKey].length ? (
                    <ChartView
                      data={dailyMisData[tab.filterKey]}
                      count={dailyMisData[`${tab.filterKey}_count`]}
                      label={tab.label}
                      activeTab={activeTab}
                    />
                  ) : (
                    <CardHeader style={{ borderBottom: "none" }}>
                      <div className="text-center fw-bold">No Data Found</div>
                    </CardHeader>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    </Card>
  );
}

function mapStateToProps(state) {
  const { data, total, loading } = state.getUserActivity;
  console.log("dailyMisDataaa", data);
  return {
    loading,
    dailyMisData: data,
    total,
  };
}

const connectedUserActivityMainTab = connect(mapStateToProps)(MainTab);
export { connectedUserActivityMainTab as MainTab };


import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const SmsPackageService = {
    getSMSPackage,
    updateSMSPackage
}

function getSMSPackage(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_sms_package`, form_data, setting).then(handleResponse)
}

function updateSMSPackage(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update_sms_package`, form_data, setting).then(handleResponse)
}
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tippy';
import { Button, Input } from 'reactstrap';
import { SearchAndSortInput } from '../../../../components/Forms/Inputs';
import { TableLoader } from '../../../../components/Loaders';
import { verificationActions } from '../../../actions';
import { renderVerificationStatus } from './Comman/TableListHelper';

class CompaniesTabContentData extends Component {
	constructor(props) {
		super(props)

		this.state = {
			tooltipOpen: false,
			page: {
				activePage: (localStorage.getItem("company_Verification_page_no")) ? Number(localStorage.getItem("company_Verification_page_no")) : 1,
				perPage: 15,
				totalItemsCount: 0
			},
			searchForm: {
				keywords: '',
				order_by: 'created_at',
				gst_filter: 'all'

			},
			GoToPage: ''
		};
		this.toggleTooltip = this.toggleTooltip.bind(this);
		this.getData = this.getData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.GoToPageFunc = this.GoToPageFunc.bind(this);
		this.handleChangeDropDown = this.handleChangeDropDown.bind(this);

	}
	componentWillReceiveProps(props) {
		let { page } = this.state;
		page.totalItemsCount = 0;
		if (props.total_companies) {
			page.totalItemsCount = props.total_companies;
		}
		this.setState({
			page
		});
	}

	toggleTooltip() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		})
	}

	renderAction(row) {
		return <React.Fragment>
			{/* <Dropdown {...this.props} data={row}/> */}
			{/* <Link className={!row.is_cheque_verified ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm"} to={row.is_cheque_verified ? "/verification/company/" + row.formated_repute_id : '/verification/company/'}> View </Link> */}
			<Link className="btn btn-primary btn-sm disabled-link" disabled={true} to={'/verification/company/' + row.formated_repute_id}>{' '}
					View{' '}
				</Link>
		</React.Fragment>
	}



	getData() {
		console.log(page)
		const { dispatch, activeTab } = this.props;
		const { page, searchForm } = this.state;
		let dataToSend = {
			active_page: (this.props.activeTab === '3' || '2' || '1' && localStorage.getItem("company_Verification_page_no")) ? Number(localStorage.getItem("company_Verification_page_no")) : page.activePage,
			per_page: page.perPage,
			type: localStorage.getItem('tab_company') || activeTab,
			...searchForm,

		};
		console.log(dataToSend.active_page);
		dispatch(verificationActions.getCompanies(dataToSend));
	}

	// search
	handleChange(event) {
		const { name, value } = event.target;
		const { searchForm } = this.state;
		searchForm[name] = value;
		this.setState(searchForm);
	}
	handleChangeDropDown(name, value) {
		const { searchForm } = this.state;
		searchForm[name] = value
		this.setState({ searchForm })
	}

	handleSubmit(e) {
		e.preventDefault();
		localStorage.setItem("company_Verification_page_no", 1)
		this.getData();
	}

	handleReset() {
		const { searchForm } = this.state;
		searchForm.keywords = '';
		searchForm.order_by = '';
		searchForm.gst_filter = '';
		this.setState(searchForm, () => this.getData());
	}
	//
	handlePageChange(pageNumber) {

		if (this.props.activeTab === '3' || '2' || '1') {
			localStorage.setItem("company_Verification_page_no", pageNumber)
		}
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => {
			this.getData()
		});
	}

	GoToPageFunc(e) {
		// GoToPage
		e.preventDefault()
		if (this.props.activeTab === '3' || '2' || '1') {
			localStorage.setItem("company_Verification_page_no", this.state.GoToPage)
		}

		const { page } = this.state;
		console.log(this.state);
		page.activePage = Number(this.state.GoToPage);
		this.setState({ page }, () => {
			this.getData()
		});
	}




	render() {

		const { companies, loading, activeTab } = this.props;
		const { page, searchForm } = this.state;
		let _this = this;
		console.log(loading);
		return (
			<React.Fragment>
				<div className="">
					<SearchAndSortInput
						searchForm={searchForm}
						handleChange={this.handleChange}
						handleSubmit={this.handleSubmit}
						handleReset={this.handleReset}
						handleChangeDropDown={this.handleChangeDropDown}
						is_Show_checkbox={true}
						handleClick={this.handleClick}
						is_show_gstField={true}
					/>
				</div>
				{loading ? (
					<TableLoader />
				) : (
					<React.Fragment>
						<div className="table-responsive-sm  animated fadeIn">
							<table className="table table-sm table-condensed table-striped table-bordered table-outline ">
								<thead className="thead-light">
									<tr>
										<th>
											<Translate id="ReputeID" />
										</th>
										<th>
											<Translate id="Company Name" />
										</th>
										<th>
											<Translate id="PAN" />
										</th>
										<th>
											<Translate id="GSTIN" />
										</th>

										<th>
											Registered On
										</th>
										<th>
											<Translate id="Verification Status" />
										</th>
										{activeTab !== "2" ? <th></th> : ''}
										{activeTab !== "3" ? <th>Verified At</th> : ''}
										<th>
											<Translate id="Actions" />
										</th>
									</tr>
								</thead>
								<tbody>
									{companies && companies.length > 0 ? (
										companies.map(function (eachData, index) {
											return (
												<React.Fragment>
													<tr >
														<td>{eachData.formated_repute_id}</td>
														<td>{eachData.name}</td>
														<td>{eachData.pan_no}</td>
														<td>{eachData.gst_no}</td>
														<td>{eachData.registered_on}</td>
														<td>{renderVerificationStatus(eachData)}</td>

														{activeTab !== "3" ? <td>{eachData.verified_at}</td> : ''}
														<td>{_this.renderAction(eachData)}</td>
													</tr>
												</React.Fragment>
											);
										})
									) : (
										<tr>
											<td colSpan="6" className="text-center">
												No Data Found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className='d-flex'>
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								console.log(page.activePage),
								<Pagination
									activePage={localStorage.getItem("company_Verification_page_no") ? Number(localStorage.getItem("company_Verification_page_no")) : page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}
								// hideNavigation={false}
								// hideFirstLastPages={true}
								/>
							)}
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Input
									className='mx-4'
									style={{ width: '120px' }}
									placeholder={`Page / ${Math.ceil(page.totalItemsCount / page.perPage)}`}
									onChange={e => this.setState({
										GoToPage: e.target.value
									})}
								/>
							)}
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Button style={{ height: 'max-content' }} onClick={this.GoToPageFunc} >
									Go To
								</Button>
							)}
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}


function mapStateToProps(state) {
	const { companies, total_companies, loading } = state.companiesData;
	return {
		companies,
		loading,
		total_companies
	}
}
const connectedCompanies = connect(mapStateToProps)(CompaniesTabContentData);
export { connectedCompanies as CompaniesTabContentData };


import { showLoader } from "../../helpers";
import { axiosAjaxCall } from './common.service';

export const userActivityService = {
    getUserActivity,
    getUserActivityDashboard
}

function getUserActivity(data) {
    showLoader();
    return axiosAjaxCall("user_activity_trending", data);
}
function getUserActivityDashboard(data) {
    showLoader();
    return axiosAjaxCall("user_activity_dashboard", data);
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { accountAction, productTagActions } from "../../actions";
import DropdownList from 'react-widgets/lib/DropdownList';
import { Button, FormGroup, InputGroup, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import InlineError from '../../../components/Messages/InlineError';
import swal from 'sweetalert'
import { Tooltip } from 'react-tippy';

class CompanyProductTags extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                tag_name: '',
            },
            selectedTags: [],
            isEdit: false,
            errors: {}
        }
        this.getCompanyProductTags = this.getCompanyProductTags.bind(this)
        this.handelToggle = this.handelToggle.bind(this)
        this.getProductTags = this.getProductTags.bind(this);
        this.handleChangeTagName = this.handleChangeTagName.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this)
        this.deleteProductTag = this.deleteProductTag.bind(this);
        // this.proceedToDeleteproduct = this.proceedToDeleteproduct.bind(this);
    }
    handelToggle() {
        const { isEdit } = this.state
        this.setState({
            isEdit: !isEdit
        }, () => {
            if (!isEdit == true) {
                this.afterSave()
            }
        })
    }
    // for change filter dropdown value
    handleChangeTagName(value) {
        const { formData, errors } = this.state
        formData.id = value.id
        formData.tag_name = value.tag_name
        errors.tag_name = ''
        this.setState({ formData, errors })
    }
    handelSubmit(data) {
        // const { formData } = this.state
        const { dispatch, element } = this.props
        // if (!this.isValid()) {
        let dataToSend = {
            product_tag_id: data.id,
            company_reputeid : element.repute_id
        }
        dispatch(accountAction.saveCompanyProductTags(dataToSend, {
            success: () => this.afterSave()
        }))
        // }
    }
    afterSave() {
        const { formData } = this.state
        formData.id = ''
        formData.tag_name = ''
        this.getCompanyProductTags()
        this.getProductTags()

    }
    isValid() {
        const { formData, errors } = this.state
        let error = false

        if (!formData.id) {
            error = true
            errors.tag_name = "this field is requied"
        }
        this.setState({ errors })
        return error
    }
    // deleteProductTag(data) {
    //     const { dispatch } = this.props;
    //     dispatch(
    //         show("bootstrap", {
    //             ModelSize: "md",
    //             ModelHeaderText: "Are you sure do you want to delete product tag \"" + data.product_name + "\" ?",
    //             ActionFunction: (e) => this.proceedToDeleteproduct(data),
    //             ActionLoading: false,
    //         })
    //     );
    // }
    deleteProductTag(data) {
        const { dispatch,element } = this.props;
        let dataToSend = {
            id: data.id,
            company_reputeid : element.repute_id
        }
        swal({
            title: "Are you sure you want to delete ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(accountAction.deleteCompanyProductTags(dataToSend, {
                    success: () => {
                        this.afterSave()
                    }
                }))
        
            }
        });

    }
    getCompanyProductTags() {
        const { dispatch, element } = this.props
        let dataToSend = {
            company_reputeid : element.repute_id
        }

        dispatch(accountAction.getCompanyProductTags(dataToSend));
    }
    getProductTags() {
        const { dispatch, element } = this.props
        let dataToSend = {
            for_add : true,
            company_reputeid : element.repute_id
        }
        dispatch(accountAction.getProductTagsAlphabatically(dataToSend))
    }
    // componentDidMount() {
    //     this.getCompanyProductTags();
    // }

    render() {
        const { loading, products, tagList } = this.props
        const { isEdit, formData, errors, selectedTags } = this.state
        // let _tagList = tagList;
        // products && products.map((prd) => {

        //     tagList && tagList.map(function (tg, idx) {
        //         if (tg.id === prd.product_tag_id) {
        //             tagList.splice(idx, 1)
        //         }
        //     })

        // })
        let _this = this;
        let _tag_list = [];
        if (tagList) {
            for (let eachTag in tagList) {
                _tag_list.push({ 
                    key: eachTag, 
                    selected_list: tagList[eachTag].hasOwnProperty('selected_tag_list') ? tagList[eachTag].selected_tag_list : [],
                    not_selected_list: tagList[eachTag].hasOwnProperty('tag_list') ? tagList[eachTag].tag_list : [],
                });
                
            }
        }

        return (<div>
            

            <Tooltip
            className="btn m-0 p-0"  arrow={true}
             title="Products Sell By Company">

            <button className="btn btn-warning" onClick={this.handelToggle}> <i className="fa fa-product-hunt" aria-hidden="true"></i>
</button>
            </Tooltip>

            <br />

            <Modal isOpen={isEdit} size={"lg"} className="modal-primary" toggle={this.handelToggle}    >
                <ModalHeader toggle={this.handelToggle} >
                    {"Products Sell By Company"}
                </ModalHeader>
                <ModalBody style={{ minHeight: '500px' }}>
                    <div className="row">

                        <div className="col-12">

                            <table className="table table-sm table-condensed table-outline">
                                <thead>
                                    <tr>
                                        <th colSpan="2" >
                                            Products to Add
                                        </th>
                                        <th >
                                            Selected Products
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {_tag_list && _tag_list.length ? (
                                        _tag_list.map((eachData) => {
                                            return (
                                                <React.Fragment>

                                                    <tr className="bg-light">
                                                        <td width="25px"> <strong>{eachData.key} </strong>
                                                        </td>
                                                        <td width="50%">
                                                            {/* {eachData.list.tag_list && eachData.list.tag_list.length ? eachData.list.tag_list.map((eachList) => { */}
                                                            { eachData.not_selected_list &&  eachData.not_selected_list.length ?  eachData.not_selected_list.map((eachList) => {
                                                            
                                                                return (
                                                                    <label  key={eachList.id} style={{ fontSize: 12, fontWeight: "500" }} className="m-1 badge badge-primary ml-2">
                                                                        {eachList.tag_name}
                                                                        &nbsp; <i className="fa fa-plus cursor-pointer" onClick={(e) => _this.handelSubmit(eachList)}></i>
                                                                    </label>

                                                                );
                                                            }): "-"}
                                                        </td>

                                                        <td width="50%">
                                                            { eachData.selected_list && eachData.selected_list.length ? eachData.selected_list.map((eachList) => {
                                                                return ( <label key={eachList.id} style={{ fontSize: 12, fontWeight: "500" }} className="m-1 badge badge-primary ml-2">
                                                                        {eachList.tag_name}
                                                                        &nbsp; <i className="fa fa-times cursor-pointer text-danger" onClick={(e) => this.deleteProductTag(eachList)}></i>
                                                                    </label>


                                                                );
                                                            }): "-"}
                                                        </td>
                                                    </tr>

                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </ModalBody>
            </Modal>
            


        </div>
        )
    }
}

function mapStateToProps(state) {
     const { loading, products } = state.getCompanyProductTags
     const { selected_tag_list, tag_list } = state.getProductTagsAlphabatically;
    return {
        loading,
        products,
         tagList: tag_list,
         selectedTagList: selected_tag_list
    }
}

const CompanyProductTagsconnected = connect(mapStateToProps)(CompanyProductTags);
export { CompanyProductTagsconnected as CompanyProductTags }
import { notify } from 'reapop'
import { whatsappLogConstants } from '../constants'
import { whatsappLogService } from '../services'

export const whatsAppLogs = {
    getWhatsappReceiveLogs,
    getWhatsappScheduleLogs,
    getWhatsappSendLogs, 
    getWhatsappSendLogsRi,
    
}

function getWhatsappReceiveLogs(data) {
    return dispatch => {
        dispatch(request())

        whatsappLogService.getWhatsappReceivedLogs(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_REQUEST } }
    function success(result) { return { type: whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_SUCCESS, result } }
    function failure(error) { return { type: whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_FAILURE, error } }
}

function getWhatsappSendLogsRi(data) {
    return dispatch => {
        dispatch(request())

        whatsappLogService.getWhatsappSendLogsRi(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_REQUEST } }
    function success(result) { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_SUCCESS, result } }
    function failure(error) { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_FAILURE, error } }
}



function getWhatsappSendLogs(data) {
    return dispatch => {
        dispatch(request())

        whatsappLogService.getWhatsappSendLogs(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_REQUEST } }
    function success(result) { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_SUCCESS, result } }
    function failure(error) { return { type: whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_FAILURE, error } }
}


function getWhatsappScheduleLogs(data) {
    return dispatch => {
        dispatch(request())

        whatsappLogService.getWhatsappScheduleLogs(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_REQUEST } }
    function success(result) { return { type: whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_SUCCESS, result } }
    function failure(error) { return { type: whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_FAILURE, error } }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { handleValidation } from './Validation';
import { CompanySectorAction } from '../../actions';
import { Link } from "react-router-dom";

class CompanySectorForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                sector: '',
                is_active: 1
            },
            errors: {},
        }

        this.handelSubmit = this.handelSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.afterAction = this.afterAction.bind(this)
    }
    handelSubmit(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;
        formData[name] = value
        this.setState({ formData })
        const result = handleValidation(formData)
        this.setState({ errors: result.errors })
        if (result.formIsValid) {
            const { dispatch } = this.props;
            if (this.state.formData.id === '') {
                dispatch(CompanySectorAction.addCompanySectorData(this.state.formData, this));
            }
            else {
                dispatch(CompanySectorAction.updateCompanySectorData(this.state.formData, this));
            }
        }
    }

    

    afterAction() {
        this.props.history.push('/company-sector')
    }

    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        let regex_allowed2 = /^[a-zA-Z0-9 \-/]+$/
        if (value.length && regex_allowed2.test(value) === false) {
          return false;
        }
        
        formData[name] = value;
        this.setState({ formData });
        const result = handleValidation(formData)
        this.setState({ errors: result.errors })
    }

    setStatus(event) {

        const { formData } = this.state
        formData["is_active"] = parseInt(event.target.value, 10)
        this.setState({ formData })
    }


    componentDidMount() {
        if (this.props.detail) {
            this.setState({
                formData: this.props.detail
            })
        }
    }

    render() {
        const { errors } = this.state
        const { loading } = this.props

        return (
            <div>
                <Form  autocomplete="off" className="col-md-6 offset-md-3" onSubmit={this.handelSubmit}>

                    <Row>
                        <Col md="12">
                            <FormGroup >
                                <label><b>Sector Name</b></label>
                    
                                <Input placeholder="Sector Name" onChange={this.handleChange} value={this.state.formData.sector} name="sector" />
                                <FormFeedback>
                                    {errors[`sector`] && errors[`sector`]}
                                </FormFeedback>
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            <FormGroup >
                                <label><b> Is Active </b></label>
                                <div onChange={this.setStatus.bind(this)}>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio1" name="is_active" value="1" class="custom-control-input" checked={this.state.formData.is_active === 1} />
                                        <label class="custom-control-label" for="customRadio1">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio2" name="is_active" value="0" class="custom-control-input" checked={this.state.formData.is_active === 0} />
                                        <label class="custom-control-label" for="customRadio2">No</label>
                                    </div>
                                </div>
                                <FormFeedback>
                                    {errors[`is_active`] && errors[`is_active`]}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <button className="btn btn-primary" disabled={loading}> { loading ? "Please wait..."  : "Save" }</button> {" "}
                    <Link className="btn btn-danger" to="/company-sector">Cancel</Link>

                </Form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {loading} = state.updateCompanySectorDetail
    return {
        loading
    }
}

const connectedCompanySectorForm = withRouter(connect(mapStateToProps)(CompanySectorForm))
export { connectedCompanySectorForm as CompanySectorForm }

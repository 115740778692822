import React from 'react';
import { Translate } from "react-localize-redux";
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { documentStatus, documentStatusName } from '../../../../constants';

export function renderVerificationStatus(row) {

    // let id = Math.random().toString(36).substr(2, 9);
    let data = {
        pan : {
            text: <Translate id='PAN' />, 
            status: 1
        },
        gstin : {
            text: <Translate id='GSTIN' />, 
            status: 1
        },
        name : {
            text: <Translate id='Name' />, 
            status: 1
        },
        address : {
            text: <Translate id='Address' />, 
            status: 1
        },
        gst_registered_date : {
            text: <Translate id='GST Registered Date' />, 
            status: 1
        },
        aadhar : {
            text: 'Aadhar', 
            status: 1
        }
    }


    if ( row.documents) {
        
        if(row.documents.hasOwnProperty('PAN_CARD')) {
            data.pan.status = row.documents.PAN_CARD.document_status;
        }

        if(row.documents.hasOwnProperty('GSTIN')) {
            data.gstin.status = row.documents.GSTIN.document_status;
        }

        
        if(row.documents.hasOwnProperty('AADHAR_CARD')) {
            data.aadhar.status = row.documents.AADHAR_CARD.document_status;
        }

        if(row.documents.hasOwnProperty('Name')) {
            data.name.status = row.documents.Name.document_status;
        }
        
        if(row.documents.hasOwnProperty('Address')) {
            data.address.status = row.documents.Address.document_status;
        }
        if(row.documents.hasOwnProperty('GST_Registered_Date')) {
            data.gst_registered_date.status = row.documents.GST_Registered_Date.document_status;
        }
    }
    return <React.Fragment>
        <ButtonGroup size="sm">
            {renderButton(data.name)}
            {renderButton(data.pan)}
            {renderButton(data.gstin)}
            {renderButton(data.address)}
            {renderButton(data.aadhar)}
            {/* {renderButton(data.gst_registered_date)} */}
        </ButtonGroup>
    </React.Fragment>
}

function renderButton(data) {
    let id = Math.random().toString(36).substr(2, 9);
    return <React.Fragment>
        <Button size="sm" className={ generateBtnClass(data.status) } id={"tooltip-" + id}> { data.text }</Button>

        <UncontrolledTooltip placement="top" target={"tooltip-" + id} >
            <b> { documentStatusName[data.status] } </b>
        </UncontrolledTooltip>
    </React.Fragment>
}


function generateBtnClass(status) {
    let className = "btn-danger";
    switch(status) {
        case documentStatus.ACCEPTED: className = "btn-success"; break;
        case documentStatus.INREVIEW: className = "btn-warning"; break;
        case documentStatus.PENDING: className = "btn-danger"; break;
        case documentStatus.REJECTED: className = "btn-danger"; break;
        default:  className = "btn-danger"; break;
    }
    return className;
}

import { findFirst } from "obj-traverse/lib/obj-traverse";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Button,
} from "reactstrap";
import { dashboardReportsActions } from "../../actions";
import { InputDropDown } from "../Settings/StageSetting/Components/FormElements";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { uniqueID } from "../../../helpers";
import TreeDropdown from "./TreeDropdown";

import moment from 'moment';
import { TableLoader } from "../../../components/Loaders";

class BusinessAccountStageStatus extends Component {
  constructor(props) {
    super(props);

    let label = ""
    let startDt =  moment().subtract(1,'months').startOf('month');
    let endDt =  moment().subtract(1,'months').endOf('month');

    
		var start = startDt.format('DD-MMM-Y');
		var end = endDt.format('DD-MMM-Y');
		label = start + ' to ' + end;

    this.state = {
      data: {
        process_name: "",
        startDate :startDt,
        endDate : endDt,
        individual:"",
        individual_name:""
      },
      serachFieldLabal: label,
      errors: {},
    };

    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.setIndividual = this.setIndividual.bind(this)
  }

  getData() {
    const {data} = this.state;
    const { dispatch } = this.props;

    dispatch(dashboardReportsActions.getDashboardReportsData({
        process_name : data.process_name,
        individual : data.individual,
        individual_name : data.individual_name,
        start_date : data.startDate ? data.startDate.format('YYYY-MM-DD') : "",
        end_date : data.startDate ?  data.endDate.format('YYYY-MM-DD') : "",
    }));
  }

  componentDidMount() {
    this.getData();
  }

   
  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState(data);
  }

  
	handleEvent(event, picker) {
		var start = picker.startDate.format('DD-MMM-Y');
		var end = picker.endDate.format('DD-MMM-Y');
		let label = start + ' to ' + end;
		if (start === end) {
			label = start;
        }

    const { data } = this.state;

        data.startDate = picker.startDate;
        data.endDate = picker.endDate;
        
		this.setState({
            data,
            serachFieldLabal: label,
		});
	}

    setIndividual(individual){
        const {data} = this.state;
        let _data = JSON.parse(individual);

        data.individual = _data.id;
        data.individual_name = _data.name;
        this.setState({data})
    }

    componentWillReceiveProps(props){
        const { processes, users_tree } = this.props
        const { data } = this.state

        if(users_tree && !data.individual_name ) {
          data.individual = users_tree.id;
          data.individual_name = users_tree.name;

        }
        
        if(processes && processes.length === 1){
            data.process_name = processes[0].process_name;
            this.setState({data})
        }
          this.setState({data})
    }
   

  render() {
    const { dashboardReports, processes, users_tree, loading, params } = this.props;
    const { data, errors, serachFieldLabal } = this.state;

   

    let processOptions = [{ title: "Select Process", value: "" }];

    if (processes) {
        processes.map((process) => {
            processOptions.push({ value: process.process_name, title: process.process_name });
        });
    }


    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="12">Stages Completed Report</Col>
                  
                </Row>
              </CardHeader>
              
              <CardHeader>
                <Row>
                    {processOptions.length > 2 && 
                  <Col md="4">
                    <InputDropDown
                      noMargin={true}
                      title={false}
                      name={"process_name"}
                      error={errors.process_name}
                      value={data.process_name}
                      onChange={this.handleChange}
                      options={processOptions}
                      maxLength="250"
                    />
                  </Col> }
                  
                  <Col md="4">
                        <DateRangePicker
                        individualstartDate={data.startDate}
                         endDate={data.endDate}
							//onApply={this.handleApply}
							          onEvent={this.handleEvent}
                            ranges={this.state.ranges}
                            containerStyles={{display:"block"}}
                        >
							<div className="input-group">
								<input
									type="text"
									className="form-control"
									placeholder="Select Date Range"
									value={serachFieldLabal}
									id="date_picker"
									style={{}}
									autoComplete={'date_picker' + uniqueID()}
								/>

								<span className="input-group-btn">
									<Button className="default date-range-toggle">
										<i className="fa fa-calendar" />
									</Button>
								</span>
							</div>
						</DateRangePicker>
					
                  </Col>
                  
                  <Col md="4"> <Button color="primary"  onClick={()=>this.getData()}>Get Data</Button></Col>

                </Row>
              </CardHeader>
              {users_tree 
                && users_tree.hasOwnProperty('all_report_by_persons') 
                && users_tree.all_report_by_persons.length > 0 && 
              <CardHeader>
                <TreeDropdown users={users_tree.all_report_by_persons || []} parentUserID={users_tree} setIndividual={this.setIndividual}/>
                &nbsp; &nbsp; <Button color="primary"  onClick={()=>this.getData()}>Get Data</Button>

              </CardHeader>}
              {dashboardReports && dashboardReports.stage_wise_report && <CardBody>
                  {loading ? <TableLoader/> : <React.Fragment>
                    Result For 
                     Process : <b>{params.process_name} </b>
                     Date : From <b>{params.start_date} </b> To  <b>{params.end_date} </b>
                     { params.individual && <React.Fragment>
                       Person : <b>{params.individual_name} </b>
                       </React.Fragment>}
                <Row>
                  <Col md="6">
                <ListGroup>
                <ListGroupItem>

                <b>Stage Name</b>
                <div className="pull-right">

                <b>Total Completed</b>
                </div>
                </ListGroupItem>    
                { dashboardReports.stage_wise_report.length  ?
                    dashboardReports.stage_wise_report.map((each_stage) => {
                      return (
                        <dov>
                          <ListGroupItem>
                            {each_stage.stage_name}
                            <div className="pull-right">
                              <span className="badge badge-danger">
                                {each_stage.total}
                              </span>
                            </div>
                          </ListGroupItem>
                         
                        </dov>
                      );
                    }) : 
                    <ListGroupItem>
                        No data found
                        </ListGroupItem>
                    }
                </ListGroup>
                    </Col>
                    </Row>
                    </React.Fragment>}
              </CardBody> }
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dashboardReports} = state;
  return {
    dashboardReports,
    params : dashboardReports.hasOwnProperty("params") ? dashboardReports.params : {},
    loading : dashboardReports.hasOwnProperty("loading") ? dashboardReports.loading : false,
    individuals : dashboardReports.hasOwnProperty("reportingUsers") ? dashboardReports.reportingUsers : [],
    processes : dashboardReports.hasOwnProperty("processes") ? dashboardReports.processes : [],
    users_tree : dashboardReports.hasOwnProperty("users_tree") ? dashboardReports.users_tree : []
  };
};

const connected = connect(mapStateToProps)(BusinessAccountStageStatus);
export { connected as BusinessAccountStageStatus };

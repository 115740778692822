export const tasksConstants = {

    GET_TASKS_DATA_REQUEST: 'GET_TASKS_DATA_REQUEST',
    GET_TASKS_DATA_SUCCESS: 'GET_TASKS_DATA_SUCCESS',
    GET_TASKS_DATA_FAILURE: 'GET_TASKS_DATA_FAILURE',
    
    SAVE_TASKS_DATA_REQUEST: 'SAVE_TASKS_DATA_REQUEST',
    SAVE_TASKS_DATA_SUCCESS: 'SAVE_TASKS_DATA_SUCCESS',
    SAVE_TASKS_DATA_FAILURE: 'SAVE_TASKS_DATA_FAILURE',

}       
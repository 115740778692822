import { axiosAjaxCall, axiosAppApiAjaxCall } from "./common.service";

export const promocodeService = {
    getPromocode ,
    savePromocode , 
    deletePromocode ,
    editPromoCode , 
    getBanner ,
    saveBanner ,
    deleteBanner

}



function getPromocode() {    
    return axiosAjaxCall("get_promo_code");
}

function getBanner() {    
    return axiosAjaxCall("get_promo_code_banner");
}

function saveBanner(data) {

    return axiosAppApiAjaxCall('save_promo_code_banner', data, {
        "Content-Type": "multipart/form-data"
    });
    
    // return Axios.post(`${config.get('apiUrl')}/save_promo_code_banner`, data, {
    //     headers: { 
    //         ...authHeader(),
    //         "Content-Type": "multipart/form-data"
    //     }
    // }).then(handleResponse)
}

function deleteBanner(data) {
    return axiosAjaxCall("delete_promo_code_banner" , data );
}

function savePromocode(data) {    
    return axiosAjaxCall("save_promo_code", data);
}

function deletePromocode(data) {    
    return axiosAjaxCall("delete_promo_code", data);
}

function editPromoCode(data) {    
    return axiosAjaxCall("update_promo_code", data);
}


import { notify } from 'reapop'
import { CompanySector } from '../constants'
import { companySectorService } from '../services'

export const CompanySectorAction = {
    getCompanySectorData,
    addCompanySectorData,
    deleteCompanySectorData,
    updateCompanySectorData
}

function getCompanySectorData(data) {
    return dispatch => {
        dispatch(request({ data }));

        companySectorService.getCompanySectorData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: CompanySector.GET_COMNAPY_SECTOR_DETAILS_REQUEST } }
    function success(result) { return { type: CompanySector.GET_COMPANY_SECTOR_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: CompanySector.GET_COMPANY_SECTOR_DETAILS_FAILURE, error } }
}

function addCompanySectorData(data,ref) {
    return dispatch => {
        dispatch(request({ data }));

        companySectorService.addCompanySectorData(data)
            .then(
                response => {
                    dispatch(success(response));
                    ref.afterAction();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Added Succesfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: CompanySector.UPDATE_COMNAPY_SECTOR_DETAILS_REQUEST } }
    function success(result) { return { type: CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_FAILURE, error } }
}

function deleteCompanySectorData(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        companySectorService.deleteCompanySectorData(data)
            .then(
                response => {
                    dispatch(success(response));
                    ref.afterAction();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Deleted Succesfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: CompanySector.DELETE_COMNAPY_SECTOR_DETAILS_REQUEST } }
    function success(result) { return { type: CompanySector.DELETE_COMPANY_SECTOR_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: CompanySector.DELETE_COMPANY_SECTOR_DETAILS_FAILURE, error } }
}

function updateCompanySectorData(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        companySectorService.updateCompanySectorData(data)
            .then(
                response => {
                    ref.afterAction();
                    dispatch(success(response));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Succesfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: CompanySector.UPDATE_COMNAPY_SECTOR_DETAILS_REQUEST } }
    function success(result) { return { type: CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_FAILURE, error } }
}
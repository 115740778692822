import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, Nav, NavItem, TabPane, TabContent, NavLink } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { currencyFormat } from '../../../helpers';
import { profileActions } from '../../actions';
import { UserSalaryCommisionDetail } from '../Account/UserSalaryCommisionDetail';
import {PayoutDetails} from './PayoutDetail'
const TAB = {
    COMMISSIONS: "commissions",
    DEPOSITS: "DEPOSITS",
    PAYOUTS : "PAYOUTS"
}

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: TAB.COMMISSIONS,
        }
        this.toggle = this.toggle.bind(this);

    }

    toggle(tab) {

        this.setState({ activeTab: tab });
    }

    totalAmount(data) {
        let total = 0;
        data.map(function (each) {
            total = total + parseInt(each.credit_amount, 10);
        })
        return total;
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(profileActions.getDepositPayments());
        dispatch(profileActions.getCommissionPayments());
    }

    render() {
        const { loading, comission_payments, deposit_payments, total,user } = this.props;
        console.log('user',user)
        return (
            <Card>
                <CardHeader>
                    <i className="fa fa-user" />
                    <strong>Payments</strong>
                </CardHeader>
                <CardBody>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TAB.COMMISSIONS })}
                                onClick={() => {
                                    this.toggle(TAB.COMMISSIONS);
                                }}
                            >
                                <i className="fa fa-money text-success" /> <b> Commissions </b>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TAB.DEPOSITS })}
                                onClick={() => {
                                    this.toggle(TAB.DEPOSITS);
                                }}
                            >
                                <i className="fa fa-file text-success" /> <b> Deposits</b> {' '}

                            </NavLink>
                        </NavItem>
                        {
                            user && user.user_type !== '1' ?
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === TAB.PAYOUTS })}
                                    onClick={() => {
                                        this.toggle(TAB.PAYOUTS);
                                    }}
                                >
                                    <i className="fa fa-file text-success" /> <b> Payouts</b> {' '}

                                </NavLink>
                            </NavItem>

                            : ''
                        }

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} className="mb-4">
                        <TabPane tabId={TAB.COMMISSIONS}>
                            {loading ? (
                                <TableLoader />
                            ) : comission_payments ? (
                                <div className="table-responsive">
                                    {/* <hr /> */}
                                    {/* <DataTable keyField="id"
                                                data={data || []} columns={columns}
                                                filter={filterFactory()}
												noDataIndication={"No data found"} /> */}

                                    <div className="table-responsive-sm  animated fadeIn">
                                        <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th colSpan="1"></th>
                                                    <th colSpan="5" className="text-center">Commission Amounts</th>
                                                    <th colSpan="1"></th>
                                                </tr>

                                                <tr>
                                                    {/* <th>Name</th> */}
                                                    <th>Month</th>
                                                    <th className="text-center">First Recharge</th>
                                                    <th className="text-center">Trail Recharge</th>
                                                    <th className="text-center">Service Recharge</th>
                                                    <th className="text-center">Flat Recharge</th>
                                                    <th className="text-center">Total Amount</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {comission_payments.length ? (
                                                    comission_payments.map(function (element, index) {
                                                        return (
                                                            <tr>
                                                                {/* <td>{element.name}</td> */}
                                                                <td>{element.month_year}</td>
                                                                <td className="text-right">₹ {currencyFormat(element.commission_amount_first_recharge)}</td>
                                                                <td className="text-right">₹ {currencyFormat(element.commission_amount_trail_recharge)}</td>
                                                                <td className="text-right">₹ {currencyFormat(element.commission_amount_service_recharge)}</td>
                                                                <td className="text-right">₹ {currencyFormat(element.commission_amount_flat_recharge)}</td>
                                                                <td className="text-right">₹ {currencyFormat(element.total_salary_amount)}</td>
                                                                <td>
                                                                    <UserSalaryCommisionDetail startDate={moment(element.month_year,'MM/YYYY').format('YYYY-MM-DD')}  element={element}  />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <th colSpan="8" className="text-center">
                                                            No Data Found
                                                        </th>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colSpan="1" className="text-right">
                                                        Total Amount
                                                    </th>
                                                    <th className="text-right"> ₹ {currencyFormat(total.commission_amount_first_recharge)}</th>
                                                    <th className="text-right"> ₹ {currencyFormat(total.commission_amount_trail_recharge)}</th>
                                                    <th className="text-right"> ₹ {currencyFormat(total.commission_amount_service_recharge)}</th>
                                                    <th className="text-right"> ₹ {currencyFormat(total.commission_amount_flat_recharge)}</th>
                                                    <th className="text-right"> ₹ {currencyFormat(total.total_salary_amount)}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                'No Data Found'
                            )}
                        </TabPane>
                        <TabPane tabId={TAB.DEPOSITS}>
                            {
                                deposit_payments && deposit_payments.all_data && deposit_payments.all_data.length ? (
                                    <div className="table-responsive">
                                        <div className="table-responsive-sm  animated fadeIn">
                                            <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                                <thead class="thead-light">
                                                    <tr>
                                                        {/* <th>ID</th> */}
                                                        <th>Receipt No</th>
                                                        {/* <th>User ID</th>
                                                        <th>User Name</th> */}
                                                        <th>User Type</th>
                                                        {/* <th>Type</th> */}
                                                        <th>Reference</th>
                                                        <th>Amount</th>
                                                        {/* <th>Debit</th> */}
                                                        <th>Date Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        deposit_payments.all_data.map((d) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr>

                                                                        {/* <td >{d.id}</td> */}
                                                                        <td >{d.receipt_number ? d.receipt_number : '-'}</td>
                                                                        {/* <td >{d.user_id}</td> */}
                                                                        {/* <td >{data.name}</td> */}
                                                                        <td >{d.user_type.name}</td>
                                                                        {/* <td >{d.type}</td> */}
                                                                        <td >{d.deposit_type}</td>
                                                                        <td >{d.credit_amount ? "₹ " + currencyFormat(d.credit_amount) : "-"}</td>
                                                                        {/* <td >{d.debit_amount ? "₹ " + currencyFormat(d.debit_amount) : "-"}</td> */}
                                                                        <td>{d.created_at}</td>
                                                                    </tr>

                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="3" className='text-right'> <b>Total</b></td>
                                                        <td>  <b>₹  {currencyFormat(this.totalAmount(deposit_payments.all_data))}</b> </td>

                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    'No Payments Available'
                                )}
                        </TabPane>
                        <TabPane tabId={TAB.PAYOUTS}>
                            <PayoutDetails />
                        </TabPane>
                    </TabContent>



                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading,deposit_payments } = state.getDepositPayments
    const {  comission_payments, total } = state.getCommissionPayments
    const { user } = state.authentication
    return { deposit_payments, loading, comission_payments, total ,user}
}


const connectedProfile = connect(mapStateToProps)(Payments);
export { connectedProfile as Payments }

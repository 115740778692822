import { axiosAjaxCall } from "./common.service";
import { showLoader } from "../../helpers";

export const tasksService = {
    saveTasks,
    getTasks,
}

function saveTasks(data) {   
    showLoader()
    return axiosAjaxCall("update_call_task", data);
}

function getTasks(data) {    
    // showLoader()

    return axiosAjaxCall("get_call_tasks", data);
}


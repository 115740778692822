import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback
} from "reactstrap";
import { Tooltip } from 'react-tippy';
import { accountAction } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
import { connect } from 'react-redux';
import { currencyFormat } from '../../../helpers';
import moment from "moment";
class DepositReceiptsDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpan: false,
    };
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan }, () => {
      if (modalOpan == false) {
        // this.getCommissionData();
      }
    });
  }
  

  componentDidMount() {
    //	this.getCommissionData();
  }

  getDebitTotal(data) {
    let total = 0;
    data.map((d) => {
      total = total + parseInt(d.give_amount, 10);
    })
    return total;
  }
  getCreditTotal(data) {
    let total = 0;
    data.map((d) => {
      total = total + parseInt(d.take_amount, 10);
    })
    return total;
  }
  getCommissionTotal(data) {

    return this.getCreditTotal(data) - this.getDebitTotal(data);
  }

  renerCommissionReference(type, percentage) {
    if (type === "FLAT_INTRO_AMOUNT") {
      return "-"
    }
    else {
      return " @ " + percentage + "%"
    }
  }

  renderRowSpan(data) {
    let span = 0;

    if (data.commission_type === "TRAIL_RECHARGE") {
      if (data.user_commission_log_id_child) {
        span = 4
      } else {
        span = 2
      }

    } else {
      if (data.user_commission_log_id_child) {
        span = 2
      } else {
        span = 1
      }

    }
    return span;
  }
  render() {
    const { modalOpan } = this.state;
    const { loading, data, element } = this.props;

    console.log(element);
    let _this = this;
    return (
      <div>
        <Tooltip className="pull-left m-0 p-0 ml-2" interactive arrow={true} title="Deposit Receipts Detail">

          <Button color="success"  class="pull-left" onClick={_this.showModal}>
            Deposits
          </Button>
        </Tooltip>
        <Modal className="modal-success" size="lg" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Deposit Receipts Detail<br />
            <small>{element ? element.name : ''}</small>
          </ModalHeader>
          <ModalBody>
            {
              element && element.all_data ? (
                <div className="table-responsive">
                  <div className="table-responsive-sm  animated fadeIn">
                    <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                      <thead class="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Receipt No</th>
                          <th>User ID</th>
                          <th>User Name</th>
                          <th>User Type</th>
                          <th>Type</th>
                          <th>Reference</th>
                          <th>Credit</th>
                          <th>Debit</th>
                          <th>Date Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          element.all_data.map((d) => {
                            return (
                              <React.Fragment>
                                <tr>
                                 
                                  <td >{d.id}</td>
                                  <td >{d.receipt_number ? d.receipt_number  : '-' }</td>
                                  <td >{d.user_id}</td>
                                  <td >{element.user.name}</td>
                                  <td >{d.user_type.name}</td>
                                  <td >{d.type}</td>
                                  <td >{d.deposit_type}</td>
                                  <td >{d.credit_amount ? "₹ " + currencyFormat(d.credit_amount) : "-"}</td>
                                  <td >{d.debit_amount ? "₹ " + currencyFormat( d.debit_amount) : "-"}</td>
                                  <td>{d.created_at}</td>
                                </tr>
                                
                              </React.Fragment>
                            )
                          })
                        }
                      </tbody>
                      <tfoot>
                        {/* <tr>
                          <th colSpan="6" className="text-right">Total</th>
                          <th>₹ {currencyFormat(this.getCreditTotal(data))}</th>
                          <th>₹ {currencyFormat(this.getDebitTotal(data))}</th>
                          <th></th>
                          <th></th>
                        </tr> */}
                        {/* <tr>
                          <th colSpan="7" className="text-right">Total Commission</th>
                          <th>₹ {currencyFormat(this.getCommissionTotal(data))}</th>
                          <th></th>
                          <th></th>
                        </tr> */}
                      </tfoot>
                    </table>
                  </div>
                </div>
              ) : (
                ''
              )}

          </ModalBody>
          {/* <ModalFooter>
                <Button onClick={_this.showModal}>Close</Button>
              </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getCommissionData;

  return {
    loading,
    data
  };
}

const connectedDepositReceiptsDetail = connect(mapStateToProps)(DepositReceiptsDetail);
export { connectedDepositReceiptsDetail as DepositReceiptsDetail };
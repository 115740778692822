import { emailConstants } from "../constants"

export function addEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.ADD_EMAIL_REQUEST:
      return { loading: true }
    case emailConstants.ADD_EMAIL_SUCCESS:
      return { data: action.result }
    case emailConstants.ADD_EMAIL_FAILURE:
      return {}
    default:
      return state
  }
}

export function getEmails(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_EMAILS_REQUEST:
      return { loading: true }
    case emailConstants.GET_EMAILS_SUCCESS:
      return { data: action.result }
    case emailConstants.GET_EMAILS_FAILURE:
      return {}
    default:
      return state
  }
}

export function editEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.EDIT_EMAIL_REQUEST:
      return { loading: true }
    case emailConstants.EDIT_EMAIL_SUCCESS:
      return { data: action.result }
    case emailConstants.EDIT_EMAIL_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.DELETE_EMAIL_REQUEST:
      return { loading: true }
    case emailConstants.DELETE_EMAIL_SUCCESS:
      return { data: action.result }
    case emailConstants.DELETE_EMAIL_FAILURE:
      return {}
    default:
      return state
  }
}

export function publishEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.PUBLISH_EMAIL_REQUEST:
      return { loading: true }
    case emailConstants.PUBLISH_EMAIL_SUCCESS:
      return { data: action.result }
    case emailConstants.PUBLISH_EMAIL_FAILURE:
      return {}
    default:
      return state
  }
}

export function sendEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.SEND_EMAIL_REQUEST:
      return { loading: true }
    case emailConstants.SEND_EMAIL_SUCCESS:
      return { data: action.result }
    case emailConstants.SEND_EMAIL_FAILURE:
      return {}
    default:
      return state
  }
}
import { CompanySector } from "../constants"

export function getCompanySectorDetail(state = {}, action) {
  switch (action.type) {
    case CompanySector.GET_COMNAPY_SECTOR_DETAILS_REQUEST:
      return { loading: true }
    case CompanySector.GET_COMPANY_SECTOR_DETAILS_SUCCESS:
      return { data: action.result }
    case CompanySector.GET_COMPANY_SECTOR_DETAILS_FAILURE:
      return {}
    default:
      return state
  }
}

export function addCompanySectorDetail(state = {}, action) {
  switch (action.type) {
    case CompanySector.ADD_COMNAPY_SECTOR_DETAILS_REQUEST:
      return { loading: true }
    case CompanySector.ADD_COMPANY_SECTOR_DETAILS_SUCCESS:
      return { data: action.result }
    case CompanySector.ADD_COMPANY_SECTOR_DETAILS_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteCompanySectorDetail(state = {}, action) {
  switch (action.type) {
    case CompanySector.DELETE_COMNAPY_SECTOR_DETAILS_REQUEST:
      return { loading: true }
    case CompanySector.DELETE_COMPANY_SECTOR_DETAILS_SUCCESS:
      return { data: action.result }
    case CompanySector.DELETE_COMPANY_SECTOR_DETAILS_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateCompanySectorDetail(state = {}, action) {
  switch (action.type) {
    case CompanySector.UPDATE_COMNAPY_SECTOR_DETAILS_REQUEST:
      return { loading: true }
    case CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_SUCCESS:
      return { data: action.result }
    case CompanySector.UPDATE_COMPANY_SECTOR_DETAILS_FAILURE:
      return {}
    default:
      return state
  }
}
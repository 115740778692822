import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { subscriptionActions } from "../../actions";
import { TableLoader } from '../../../components/Loaders';
import { SubscriptionForm } from './SubscriptionForm';
import { handleValidation } from './Validation';

class UpdateSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [],
            editorHtml: '',
            theme: 'snow',
            formData: {
                plan_title: '',
                features_list: '',
                permissions_list: [],
                subscription_type: '',
                chareslength: ''
            },
            errors: []
        }
        this.isLoad = false;
        this.getSubscription = this.getSubscription.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handelSubmit = this.handelSubmit.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangeHTML = this.handleChangeHTML.bind(this)
    }

    componentDidMount() {
        this.getSubscription();
    }

    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        formData[name] = value
        this.setState({ formData })
    }
    handelSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const { formData } = this.state;
        const result = handleValidation(formData);
        this.setState({ errors: result.errors });

        if (result.formIsValid) {
            let dataToSend = this.state.formData;
            dispatch(subscriptionActions.updateSubscription(dataToSend));
        }
    }
    handleChecked(checked) {
        const { formData } = this.state;
        formData.permissions_list = checked
        this.setState({ formData })
    }
    handleChangeHTML(html) {
        const { formData } = this.state;
        formData.features_list = html
        this.setState({ formData })
    }

    getSubscription() {
        const { dispatch } = this.props;
        const values = this.props.match.params.id
        let dataToSend = {
            id: values
        }
        dispatch(subscriptionActions.getSubscription(dataToSend));
    }

    componentWillReceiveProps(props) {
        if (props.data) {
            this.setState({ formData: props.data })
        }
    }

    render() {
        
        const { loading } = this.props

        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h3 className="text-center mb-4 mt-4"> Subscription Detail </h3>
                                {loading ? <TableLoader /> :
                                    <SubscriptionForm/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { loading, data } = state.getSubscription
    return {
        loading,
        data
    }
}
const connectedSubscriptions = connect(mapStateToProps)(UpdateSubscription)
export { connectedSubscriptions as UpdateSubscription }
import { communicationTemplateConstants } from "../constants"

export function addCommunicationTemplate(state = {}, action) {
  switch (action.type) {
    case communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_REQUEST:
      return { loading: true }
    case communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_SUCCESS:
      return { data: action.result }
    case communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_FAILURE:
      return {}
    default:
      return state
  }
}

export function getCommunicationTemplate(state = {}, action) {
  switch (action.type) {
    case communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_REQUEST:
      return { loading: true }
    case communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_SUCCESS:
      return { data: action.result }
    case communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_FAILURE:
      return {}
    default:
      return state
  }
}

export function editCommunicationTemplate(state = {}, action) {
  switch (action.type) {
    case communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_REQUEST:
      return { loading: true }
    case communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_SUCCESS:
      return { data: action.result }
    case communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_FAILURE:
      return {}
    default:
      return state
  }
}


import React, { Component } from "react";
import { connect } from "react-redux";
import { businessAccountsActions } from "../../../actions";
import { InputDropDown } from "../../Settings/StageSetting/Components/FormElements";
import swal from "sweetalert";
import { Button } from "reactstrap";

 class AssignToDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignToId: 0,
    };

    this.assignToUser = this.assignToUser.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const { value } = event.target;

    this.setState({assignToId : value})

  }

  assignToUser() {
    const { dispatch, getData, item } = this.props;
    const { assignToId } = this.state;


    let dataToSend = {
      userId: assignToId,
      business_details_id: item.id,
    };

    if(!assignToId){
return false;
    }

    swal({
        title: "Are you sure ?",
        text: "Do you want to assign  business account ?",
        icon: "warning",
        buttons: true,
        // dangerMode: true,
        confirmButtonText: 'Yes, I am sure!',
    }).then((confirm) => {
        if (confirm) {
            dispatch(businessAccountsActions.assignUser(dataToSend,{
              success : () => {
                getData()
              }
            }));
        }
    })

  }
  
  componentWillMount(){
      const {item} = this.props;

      if(item) {
          this.setState({ assignToId : item.assign_user_id})
      }
  }

  render() {
    const { assignToId } = this.state;
    const { item, reportingUsers } = this.props;

    let usersOptions = [
      { title: "Select user", value: "" },
      { title: "Not Assigned", value: 0 }
    ];

    if (reportingUsers) {
      reportingUsers.map((each) => {
        let name = each.name;
        if(each.job_title){
          name = name + " - "+ each.job_title.name;
        }
        usersOptions.push({ title: name, value: each.id });
      });
    }

    return (
      <div>
        
      <div className={"pull-left"}  >
        <InputDropDown
        size="sm" style={{margin:0}}
         className={"pull-left"}
          style={{maxWidth : "160px"}}
          title={false}
          name={"assign_to"}
          value={assignToId}
          onChange={(e) => this.handleChange(e)}
          options={usersOptions}
        /> 
        </div>
        {assignToId !== "" && 
        <Button size="sm" className={"pull-left"}   color="danger" onClick={() => this.assignToUser()}> Assign</Button>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { businessAccounts } = state;
  return {
    reportingUsers: businessAccounts.reportingUsers,
  };
};

const connected = connect(mapStateToProps)(AssignToDropDown);
export { connected as AssignToDropDown };

import { axiosAjaxCall } from "./common.service";

export const productsService = {
    getProducts,
    getProduct,
}


function  getProducts(data) {    
    return axiosAjaxCall("get-products", data);
}


function  getProduct(data) {    
    return axiosAjaxCall("get-product", data);
}
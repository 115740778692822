import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardHeader, Table, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import moment from 'moment'
import { TableLoader } from '../../../components/Loaders'
import { useState } from 'react';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { connect } from 'react-redux';
import { UserActivity } from '../../actions';
import Pagination from "react-js-pagination";

function ListTab(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [data, setData] = useState({})
    const [modalData, setModalData] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setpage] = useState({
        activePage: 1,
        perPage: 50,
        totalRecord: 0
    });



    let handlePageChange = (pageNumber) => {
        let dataToSend = {
            active_page: pageNumber,
            per_page: page.perPage,
        }
        getData(dataToSend)
        setpage({ ...page, activePage: pageNumber });
    }
    let getData = (data) => {
        const { dispatch } = props
        dispatch(UserActivity.getUserActivity(setData, data))
    }
    useEffect(() => {
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
        }
        getData(dataToSend)
    }, []);
    useEffect(() => {
        setpage({ ...page, totalRecord: data.total_records })
        if (data && data.companies) {
            setLoading(false)
        }
    }, [data]);

    let ShowActivity = (index) => {
        toggle()
        setModalData(data.companies[index])
    }
    let ShowRevenue = () => {
        toggle()
    }
    let toggle = () => {
        setModalOpen(!modalOpen)
    }
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <i className="fas fa-user" />
                    <strong>User Activty on Trending</strong>
                </CardHeader>
                <CardBody>
                    {loading ? (
                        <TableLoader />
                    ) : (
                        <React.Fragment>
                            <div className="table-responsive-sm  animated fadeIn">
                                <table className="table table-sm table-condensed table-striped table-bordered table-outline  table-hover " style={{ border: '2px solid #909090' }}>
                                    <thead className="thead-light">
                                        <tr className=''>
                                            <th className=''>No.</th>
                                            <th className=''>Company Name</th>
                                            <th className=''>Revenue</th>
                                            <th className=''>Activity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.companies && data.companies.map((item, index) =>
                                            <tr className='' key={index}>
                                                <td className=''>{index + 1}</td>
                                                <td className=''>{item.name}</td>
                                                <td className=''>123456 <Button onClick={ShowRevenue} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                                <td className=''>{item.activity_count}<Button id={index} onClick={() => ShowActivity(index)} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            {modalData && <Modal isOpen={modalOpen} toggle={toggle} size={"lg"}>
                                <ModalHeader toggle={toggle}>{'header'}</ModalHeader>
                                <ModalBody>
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline d-flex table-hover " style={{ border: '2px solid #909090' }}>
                                        <thead className="thead-light  col-4">
                                            <tr>
                                                <th className='col-2' >Company Name </th>
                                                <td className='col-2' >{modalData.name}</td>
                                            </tr>

                                            <tr>
                                                <th>GST Number</th>
                                                <td> {modalData.gst_no}</td>
                                            </tr>
                                            <tr>
                                                <th>PAN</th>
                                                <td> {modalData.pan_no}</td>
                                            </tr>
                                            <tr>
                                                <th>Mobile</th>
                                                <td> {modalData.mobile}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{modalData.email} </td>
                                            </tr>
                                            <tr>
                                                <th>Company RI </th>
                                                <td> RI {modalData.company_reputeid}</td>
                                            </tr>
                                        </thead>
                                        <thead className="thead-light col-5">

                                            <tr>
                                                <th className=''></th>
                                                <th>Today</th>
                                                <th>Week</th>
                                                <th>Month</th>
                                                <th>Year</th>
                                                <th>Lifetime</th>
                                            </tr>
                                            {/* Register User */}
                                            <tr>
                                                <th>No. of Register User </th>
                                                <td>{modalData.activity_data.baddebt.today}</td>
                                                <td>{modalData.activity_data.baddebt.week}</td>
                                                <td>{modalData.activity_data.baddebt.month}</td>
                                                <td>{modalData.activity_data.baddebt.year}</td>
                                                <td>{modalData.activity_data.baddebt.lifetime}</td>
                                            </tr>
                                            <tr>
                                                <th>No. of BadDebt </th>
                                                <td>{modalData.activity_data.baddebt.today}</td>
                                                <td>{modalData.activity_data.baddebt.week}</td>
                                                <td>{modalData.activity_data.baddebt.month}</td>
                                                <td>{modalData.activity_data.baddebt.year}</td>
                                                <td>{modalData.activity_data.baddebt.lifetime}</td>
                                            </tr>
                                            <tr>
                                                <th>Searches</th>
                                                <td>{modalData.activity_data.searches.today}</td>
                                                <td>{modalData.activity_data.searches.week}</td>
                                                <td>{modalData.activity_data.searches.month}</td>
                                                <td>{modalData.activity_data.searches.year}</td>
                                                <td>{modalData.activity_data.searches.lifetime}</td>
                                            </tr>
                                            {/* Unregister User */}
                                            <tr>
                                                <th>No. of Unregister User</th>
                                                <td>{modalData.activity_data.baddebt.today}</td>
                                                <td>{modalData.activity_data.baddebt.week}</td>
                                                <td>{modalData.activity_data.baddebt.month}</td>
                                                <td>{modalData.activity_data.baddebt.year}</td>
                                                <td>{modalData.activity_data.baddebt.lifetime}</td>
                                            </tr>
                                            <tr>
                                                <th>No. of Unregister BadDebt</th>
                                                <td>{modalData.activity_data.unregister_baddebt.today}</td>
                                                <td>{modalData.activity_data.unregister_baddebt.week}</td>
                                                <td>{modalData.activity_data.unregister_baddebt.month}</td>
                                                <td>{modalData.activity_data.unregister_baddebt.year}</td>
                                                <td>{modalData.activity_data.unregister_baddebt.lifetime}</td>
                                            </tr>
                                            <tr>
                                                <th>No. of Send Bill Transactions</th>
                                                <td>{modalData.activity_data.send_bill_transactions.today}</td>
                                                <td>{modalData.activity_data.send_bill_transactions.week}</td>
                                                <td>{modalData.activity_data.send_bill_transactions.month}</td>
                                                <td>{modalData.activity_data.send_bill_transactions.year}</td>
                                                <td>{modalData.activity_data.send_bill_transactions.lifetime}</td>
                                            </tr>
                                            <tr>
                                                <th>No. of Receive Bill Transactions</th>
                                                <td>{modalData.activity_data.receive_bill_transactions.today}</td>
                                                <td>{modalData.activity_data.receive_bill_transactions.week}</td>
                                                <td>{modalData.activity_data.receive_bill_transactions.month}</td>
                                                <td>{modalData.activity_data.receive_bill_transactions.year}</td>
                                                <td>{modalData.activity_data.receive_bill_transactions.lifetime}</td>
                                            </tr>
                                        </thead>
                                        <thead className="thead-light col-3">
                                            <tr>
                                                <th className=''>Total Activity</th>
                                                <td className='bg-success'>{modalData.activity_data.total_activity}</td>
                                            </tr>
                                            <tr>
                                                <th className=''>Total Revenue</th>
                                                <td className='bg-success'>{modalData.activity_data.total_revenue}</td>
                                            </tr>
                                            <tr>
                                                <th className=''>Current Balance</th>
                                                <td className='bg-success'>{modalData.activity_data.total_current_balance}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={toggle} >OK</Button>
                                </ModalFooter>
                            </Modal>}
                            {page.totalRecord <= page.perPage ? (
                                ''
                            ) : (
                                <Pagination
                                    activePage={page.activePage}
                                    itemsCountPerPage={page.perPage}
                                    totalItemsCount={page.totalRecord}
                                    pageRangeDisplayed={5}
                                    linkClass="page-link"
                                    itemClass="page-item"
                                    onChange={handlePageChange}
                                />
                            )}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    const { loading, data } = state.getProfile
    return { loading, data }
}

const connectedListTab = connect(mapStateToProps)(ListTab);
export { connectedListTab as ListTab }



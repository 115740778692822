import React, { Component } from 'react'
import { ListGroupItem, ListGroup } from 'reactstrap'
import { InputTypes } from '../../../../constants/stages.constants'
import { AddNewItem } from './AddNewItem'


export const DisplayListItems =  (props) => {
    const { Items, removeItem } = props;
    
    return <ListGroup>
    {Array.isArray(Items) && Items.map((each, idx)=>{
        return <ListGroupItem key={idx} >
            
            
                <i className={"fa fa-caret-right"}></i> &nbsp;
            
                {each.title} <label className={"badge badge-success"}> {each.inputType} </label>
            
            &nbsp;
            &nbsp;

            <AddNewItem isEdit={true} ItemData={each}  {...props}/>
            
            { each.inputType === InputTypes.SubItem &&
                <React.Fragment>
                        &nbsp;&nbsp;
                    <AddNewItem isSubItem={true} ItemData={each }  {...props}/>
                </React.Fragment>
            }

            &nbsp;&nbsp;
            <button title={"Re"} className="btn btn-danger btn-sm" onClick={() => removeItem(each.id)}>
                <i className="fa fa-trash"></i>
            </button>

            {/* </div> */}
           

           { each.inputType === InputTypes.SubItem && each.hasOwnProperty("children") &&
               <DisplayListItems isSubItem={true} {...props} Items={each.children} />
           }
            
        </ListGroupItem>
    })}
    </ListGroup>
}

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, FormGroup, Form, Button } from 'reactstrap'
import { subscriptionActions } from "../../actions";
import { SubscriptionCharges } from './SubscriptionCharges';
import ReactQuill from 'react-quill'
import { INDIVIDUAL_PERMISSIONS_FOR_FORM, COMPANY_PERMISSIONS_FOR_FORM } from '../../constants';
import { Link } from "react-router-dom";
import CheckboxTree from 'react-checkbox-tree';
import { editorConfig } from '../../../components/Editor';
import { handleValidation } from './Validation';
import InlineError from '../../../components/Messages/InlineError';
import { withRouter } from 'react-router-dom';

class SubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [],
            editorHtml: '',
            theme: 'snow',
            formData: {
                plan_title: '',
                features_list: '',
                permissions_list: [],
                subscription_type: '',
                chareslength: ''
            },
            errors: []
        }
        this.isLoad = false;
        this.handleChange = this.handleChange.bind(this)
        this.handelSubmit = this.handelSubmit.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangeHTML = this.handleChangeHTML.bind(this)
        this.afterAction = this.afterAction.bind(this)
        this.addPlanOption = this.addPlanOption.bind(this)
        this.removePlanOption = this.removePlanOption.bind(this)
    }


    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        formData[name] = value
        this.setState({ formData })
    }
    handelSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const { formData } = this.state;
        const result = handleValidation(formData);
        this.setState({ errors: result.errors });

        if (result.formIsValid) {
            let dataToSend = this.state.formData;
            dispatch(subscriptionActions.updateSubscription(dataToSend, this));
        }
    }

    afterAction() {
        this.props.history.push('/subscriptions')
    }
    handleChecked(checked) {
        const { formData } = this.state;
        formData.permissions_list = checked
        this.setState({ formData })
    }

    handleChangeHTML(html) {
        const { formData } = this.state;
        formData.features_list = html
        this.setState({ formData })
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ formData: this.props.data })
        }
    }

    removePlanOption(index) {
        let { formData } = this.state

        if (formData.subscription_charges.length <= 1) {
            alert("Atlest one is option required");
            return false;
        }
        formData.subscription_charges.splice(index, 1);
        console.log(formData.subscription_charges);
        this.setState({ formData })
    }

    addPlanOption() {

        let { formData } = this.state
        formData.subscription_charges.push({
            total_amount: "",
            total_sms: "",
            validity_days: ""
        })
        
        this.setState({ formData })

    }

    render() {
        const { loading } = this.props
        const { formData, errors } = this.state

        let permissions_list = INDIVIDUAL_PERMISSIONS_FOR_FORM;

        // if (formData.subscription_type === "debtor") {
        //     permissions_list = COMPANY_PERMISSIONS_FOR_FORM.filter((arr) => {
        //         return arr.value != COMPANY_PERMISSIONS.DEBIT_SELL && arr.value != COMPANY_PERMISSIONS.DEBTORS && 
        //         arr.value != COMPANY_PERMISSIONS.RECEIVE_PAYMENT && arr.value != COMPANY_PERMISSIONS.SEND_BILL_TRANSACTION &&
        //          arr.value != COMPANY_PERMISSIONS.GOODS_RETURN && arr.value != COMPANY_PERMISSIONS.REPORTS && arr.value != COMPANY_PERMISSIONS.RECEIVE;
        //     })
        // }

        if (formData.subscription_type === "creditor" || formData.subscription_type === "debtor") {
            permissions_list = COMPANY_PERMISSIONS_FOR_FORM;
        }

        let _this = this;

        return (
            <Form  autocomplete="off" onSubmit={this.handelSubmit} className="col-md-8 offset-md-2">
                <Row>
                    <Col xs="12">
                        <div className="form-group">
                            <label className="font-weight-bold" >Plan Title</label>
                            <input type="text" className="form-control" name="plan_title" onChange={this.handleChange} value={formData.plan_title} />
                            {errors.plan_title && <InlineError message={errors.plan_title} />}

                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold">Features</label>
                            <ReactQuill
                                theme={this.state.theme}
                                onChange={this.handleChangeHTML}
                                value={formData.features_list}
                                modules={editorConfig.modules}
                                formats={editorConfig.formats}
                                name="features_list"
                            />
                            {errors.features_list && <InlineError message={errors.features_list} />}

                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold">Permissions</label>
                            <FormGroup >
                                <CheckboxTree
                                    nodes={permissions_list}
                                    checked={formData.permissions_list}
                                    expanded={this.state.expanded}
                                    onCheck={this.handleChecked}
                                    onExpand={expanded => this.setState({ expanded })}
                                />
                            </FormGroup>
                        </div>

                        <label className="font-weight-bold">Plan Options</label>
                        <section>
                            
									<div className="table-responsive-sm  animated fadeIn">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Duration Days {this.state.isChargesShow}</th>
                                        {(formData.subscription_type === "debtor" || formData.subscription_type === "creditor") &&
                                            <th>Total SMS</th>}
                                        <th>Amount <br /><small> (Excluding GST) </small></th>
                                        <th>IGST (18%)</th>
                                        <th>CGST (9%)</th>
                                        <th>UTGST (9%)</th>
                                        <th>Amount <br /><small> (Including GST) </small></th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData && formData.subscription_charges ?
                                            formData.subscription_charges.map(function (element, index) {
                                                return (
                                                    <SubscriptionCharges subscription_type={formData.subscription_type} detail={element} key={element.id} currentIndex={index} removePaln={(index) => _this.removePlanOption(index)}></SubscriptionCharges>
                                                )
                                            })
                                            : ''
                                    }
                                    <Button color="primary" onClick={this.addPlanOption}> Add Plan Option </Button>
                                </tbody>
                            </table>
                            </div>
                        </section>
                        <br /><br />
                        <button type="submit" className="btn btn-primary" disabled={loading}> {loading ? "Please wait..." : "Save"} </button> &nbsp;
                                                <Link className="btn btn-secondary" to={"/subscriptions"}> Cancel </Link>

                    </Col>
                </Row>
            </Form>


        )
    }
}


function mapStateToProps(state) {
    const { data } = state.getSubscription

    const { loading } = state.updateSubscription
    return {
        loading,
        data
    }
}
const connectedSubscriptions = withRouter(connect(mapStateToProps)(SubscriptionForm))
export { connectedSubscriptions as SubscriptionForm }
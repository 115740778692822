import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Row, Col, Button, Form, FormFeedback, Input } from 'reactstrap'
import { handleValidation } from './Validation'
import { FaqActions } from '../../actions';
import { Link } from "react-router-dom";
import { editorConfig } from '../../../components/Editor';
import { TableLoader } from '../../../components/Loaders';

class AddFAQ extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEdit: false,
      id: '',
      editorHtml: '',
      theme: 'snow',
      formdata: {
        question: '',
        answer: ''
      },
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeHTML = this.handleChangeHTML.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onSubmitFAQ = this.onSubmitFAQ.bind(this)
    this.afterSave = this.afterSave.bind(this)
  }

  handleChangeHTML(html) {
    this.setState({
      formdata: {
        ...this.state.formdata,
        answer: html
      }
    })
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }

  handleChange(e) {

    e.preventDefault()
    const { formdata } = this.state
    formdata[e.target.name] = e.target.value
    this.setState({ formdata })
  }

  onSubmitFAQ() {
    const { formdata, isEdit } = this.state
    const { dispatch, match: { params } } = this.props
    const result = handleValidation(formdata)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {

      let dataToSend = {
        question: formdata.question,
        answer: formdata.answer,
      };

      if (isEdit) {
        dataToSend.id = params.faqId
      }

      dispatch(FaqActions.saveFAQ(dataToSend, this));
    }
  }

  afterSave() {
    this.props.history.push("/FAQ");
  }

  componentDidMount() {
    const { dispatch, match: { params } } = this.props

    if (params.faqId) {
      this.setState({
        isEdit: true
      })
      dispatch(FaqActions.getFAQ({ faqId: params.faqId }));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { FAQdata } = nextProps
    const { formdata } = this.state
    if (FAQdata && formdata.question === "") {

      this.setState({
        formdata: {
          question: FAQdata.question,
          answer: FAQdata.description
        }
      });
    }
  }

  closeModal() {
    const { closeModal } = this.props
    closeModal()
  }

  render() {

    const { errors, formdata, isEdit } = this.state;
    const { saving, loading } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="text-center mb-4 mt-4"> {isEdit ? "Edit" : "Add"} FAQ </h3>

          <Row>
            <div className="col-md-6 offset-md-3" >
              {loading ? <TableLoader /> :
                <Form>
                  <Row>
                    <Col xs="12">
                      {/* <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <b>Q.</b>
                    </InputGroupText>
                  </InputGroupAddon> */}
                      <label><b>Question</b></label>

                      <Input placeholder="What is your Question ?" name="question" value={formdata.question} onChange={this.handleChange} />
                      {/* </InputGroup> */}
                      <FormFeedback>
                        {errors[`question`] && errors[`question`]}
                      </FormFeedback>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs="12">
                      <label><b>Answer</b></label>
                      <ReactQuill
                        theme={this.state.theme}
                        onChange={this.handleChangeHTML}
                        value={formdata.answer}
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        bounds={'.app'}
                        placeholder={"Answer"} />

                      <FormFeedback> {errors[`answer`] && errors[`answer`]} </FormFeedback>

                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs="12">
                      <Button color="primary" onClick={this.onSubmitFAQ} disabled={saving}> {saving ? "Please wait..." : "Save"} </Button> &nbsp;
                <Link className="btn btn-danger" to="/FAQ">Cancel</Link>
                    </Col>
                  </Row>
                </Form>
              }
            </div>
          </Row>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { saving } = state.saveFAQ
  const { data, loading } = state.getFAQ
  return {
    loading,
    saving,
    FAQdata: data
  }

}

const connectedAddFAQ = connect(mapStateToProps)(AddFAQ)
export { connectedAddFAQ as AddFAQ }

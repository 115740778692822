import React, { Component } from 'react';
import FormHelpText from '../../../../../components/Messages/FormHelpText';
import InlineError from '../../../../../components/Messages/InlineError';
const { FormGroup, Input, Label } = require("reactstrap")

export const InputText = (props) => {
    
    return <FormGroup >
        { props.title ? <label> {props.title} { props.isRequired &&  <span className='text-danger' >*</span>} </label> : false }
        <Input type={"text"} {...props} />
        { props.helptext ? <FormHelpText message={props.helptext} /> : false }
        { props.error && <InlineError message={props.error} /> }
    </FormGroup>
} 


export const InputDropDown = (props) => {
    return <FormGroup style={props.noMargin ? {margin:0 } : {}}>
        {props.title ? <label> {props.title} </label> : false }
            <Input type="select"  {...props}>
            {props.options.map((each, idx) => {
                return 	<option key={idx} value={each.value}>{each.title}</option>
            })}
		</Input>
        { props.error && <InlineError message={props.error} /> }
    </FormGroup>
} 


export const InputCheckBox = (props) => {
    return <FormGroup check>
        <Label check>
            <Input type="checkbox"  {...props}/>
            &nbsp; {props.title}               
        </Label>
        { props.error && <InlineError message={props.error} /> }
    </FormGroup>
} 
export const InputRadio = (props) => {
    return <FormGroup>
        <label>
            <input type="radio"  {...props}/>
            &nbsp; {props.title}               
        </label>
        { props.error && <InlineError message={props.error} /> }
    </FormGroup>
} 

  
import { UserLoginLogsConstants } from "../constants"

export function getUserLoginLogs(state = {}, action) {
  switch (action.type) {
    case UserLoginLogsConstants.GET_USERLOGINLOGS_REQUEST:
      return { loading: true }
    case UserLoginLogsConstants.GET_USERLOGINLOGS_SUCCESS:
      return { data: action.result }
    case UserLoginLogsConstants.GET_USERLOGINLOGS_FAILURE:
      return {}
    default:
      return state
  }
}
import { userManagementConstants } from "../constants"

export function addUser(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.ADD_USER_REQUEST:
      return { loading: true }
    case userManagementConstants.ADD_USER_SUCCESS:
      return { data: action.result }
    case userManagementConstants.ADD_USER_FAILURE:
      return {}
    default:
      return state
  }
}

export function getUsers(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_USERS_REQUEST:
      return { loading: true }
    case userManagementConstants.GET_USERS_SUCCESS:
      return { data: action.result }
    case userManagementConstants.GET_USERS_FAILURE:
      return {}
    default:
      return state
  }
}


export function addUserType(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.ADD_USER_TYPE_REQUEST:
      return { loading: true }
    case userManagementConstants.ADD_USER_TYPE_SUCCESS:
      return { data: action.result }
    case userManagementConstants.ADD_USER_TYPE_FAILURE:
      return {}
    default:
      return state
  }
}

export function getUserTypes(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_USER_TYPES_REQUEST:
      return { loading: true }
    case userManagementConstants.GET_USER_TYPES_SUCCESS:
      return { data: action.result }
    case userManagementConstants.GET_USER_TYPES_FAILURE:
      return {}
    default:
      return state
  }
}

export function getCommissionSlab(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_COMISSION_SLAB_REQUEST:
      return { fetching: true }
    case userManagementConstants.GET_COMISSION_SLAB_SUCCESS:
      return { data: action.result }
    case userManagementConstants.GET_COMISSION_SLAB_FAILURE:
      return {}
    default:
      return state
  }
}

export function Departments(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_DEPARTMENTS_REQUEST:
      return { fetching: true }
    case userManagementConstants.GET_DEPARTMENTS_SUCCESS:
      return { data: action.result }
    case userManagementConstants.GET_DEPARTMENTS_FAILURE:
      return {}
    default:
      return state
  }
}

export function JobTitles(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_JOB_TITLES_REQUEST:
      return { fetching: true }
    case userManagementConstants.GET_JOB_TITLES_SUCCESS:
      return { data: action.result }
    case userManagementConstants.GET_JOB_TITLES_FAILURE:
      return {}
    default:
      return state
  }
}

export function getCommissionAmounts(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.GET_COMISSION_AMOUNT_REQUEST:
      return { fetching: true }
    case userManagementConstants.GET_COMISSION_AMOUNT_SUCCESS:
      return { commission_data: action.result }
    case userManagementConstants.GET_COMISSION_AMOUNT_FAILURE:
      return {}
    default:
      return state
  }
}

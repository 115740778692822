import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import Interweave from 'interweave';


class DepositeAccountDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: {
                'account': true,
                'upi': false,
                'neft': false,
            }
        }
        this.OnToggle = this.OnToggle.bind(this);
    }


    OnToggle(id) {
        const { toggle } = this.state;

        let tgl = !toggle[id];
        for (const key in toggle) {
            toggle[key] = false;
        }

        toggle[id] = tgl;

        this.setState({
            toggle
        })
    }


    render() {
        const _this = this;
        const { toggle } = this.state;
        return (
            <React.Fragment>
                <h5 className='text-center mb-4'> You can pay your deposit through the below listed options </h5>
                <Card >
                    <CardHeader className={" pl-5 pr-5 cursor-pointer " + (toggle.account ? "bg-primary" : "bg-white")} onClick={(e) => this.OnToggle('account')}  >
                        <b> Deposit Cheque in HDFC bank </b>
                    </CardHeader>
                    <Collapse isOpen={toggle['account']} >
                        <CardBody style={{ "borderBottom": "1px solid #0194C7" }}>
                            <b>Account Number : </b> 50200023794568 <br />
                            <b>Beneficiary Name :</b>  BSOSPL REPUTEINFO  <br />
                            <b>IFSC Code : </b> HDFC0002847 <br />
                            <b>Account Type : </b> Current <br />
                            <b>Branch Name :</b>  Moti Daman
                        </CardBody>
                    </Collapse>
                </Card>

                <Card>
                    <CardHeader className={" pl-5 pr-5 cursor-pointer " + (toggle.neft ? "bg-primary" : "bg-white")} onClick={(e) => this.OnToggle('neft')}  >
                        <b> Netbanking / NEFT / IMPS </b>
                    </CardHeader>
                    <Collapse isOpen={toggle['neft']} >
                        <CardBody style={{ "borderBottom": "1px solid #0194C7" }}>
                            <b>Account Number : </b> 50200023794568 <br />
                            <b>Beneficiary Name :</b>  BSOSPL REPUTEINFO  <br />
                            <b>IFSC Code : </b> HDFC0002847 <br />
                            <b>Account Type : </b> Current
                        </CardBody>
                    </Collapse>
                </Card>


                <Card >
                    <CardHeader className={" pl-5 pr-5 cursor-pointer " + (toggle['upi'] ? "bg-primary" : "bg-white")} onClick={(e) => this.OnToggle('upi')}  >
                        <b> UPI </b>
                    </CardHeader>
                    <Collapse isOpen={toggle['upi']} >
                        <CardBody style={{ "borderBottom": "1px solid #0194C7" }}>

                            coming soon...

                            {/* <img src={QRCode} width={400} />
                                            <h5> reputeinfo@upi </h5> */}
                        </CardBody>
                    </Collapse>
                </Card>

                <h6 className='mb-2 mt-2'>Please WhatsApp on +91 95 1023 1023 </h6>

                1. Your receipt copy <br/>
                2. Mobile Number<br/>
                3. Email

                <h6 className='mb-2 mt-2'> On receipt of the deposit in our bank account your account will be activated promptly</h6>

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connected = withRouter(connect(mapStateToProps)(DepositeAccountDetails));
export { connected as DepositeAccountDetails }
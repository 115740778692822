import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userManagementActions } from '../../actions/userManagement.action';
import { UserForm } from './UserForm';
import { withRouter } from 'react-router';
import { TableLoader } from '../../../components/Loaders';

class EditUser extends Component {
    constructor(props) {
        super(props)
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { dispatch, match: { params } } = this.props;

        dispatch(userManagementActions.getUsers({ user_id: params.userId }));
    }


    render() {
        const { data, loading } = this.props;
        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mb-4 mt-4"> Edit User </h3>
                    {loading ? <div  style={{ width: '50%', margin: '0 auto' }}> <TableLoader /> </div>:
                        <UserForm isEdit="true" userData={data} />}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getUsers
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user
    }
}

const connectedEditUser = withRouter(connect(mapStateToProps)(EditUser));
export { connectedEditUser as EditUser }
import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTemplate } from "./StageSetting/DataTemplate";

class Setting extends Component {
  render() {
    return (
      <React.Fragment>
        <h5 className="mb-4 mt-4 ">
          <i className="fas fa-cog" /> &nbsp; Settings
        </h5>
        <DataTemplate/>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {}

const connectedSetting = connect(mapStateToProps)(Setting);
export { connectedSetting as Setting };


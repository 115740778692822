import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, FormFeedback, Button } from 'reactstrap';
import { expenceStatementActions } from '../../actions';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
class AddDepositDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                paymentType: 'NEFT',
                reference_number: '',
                deposited_date: '',
                remark: '',
            },
            selectedTags: [],
            modalOpan: false,
            errors: {}
        }
        this.handelToggle = this.handelToggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.isValid = this.isValid.bind(this)
    }
    handelToggle() {
        const { modalOpan } = this.state
        this.setState({
            modalOpan: !modalOpan
        }, () => {
            if (!modalOpan == true) {
                console.log(modalOpan)
                //  this.getPayoutDetails()
            }
        })
    }
    handleChangeDate(date) {
        let { formData, errors } = this.state;
        formData.deposited_date = date
        errors.deposited_date = ''
        this.setState({
            formData
        });
    }
    handleChange(event) {
        const { name, value, type } = event.target;

        let { formData, errors } = this.state;
        let val = value;

        if (name === "paymentType") {
            val = Number(value);
        }
        formData[name] = val;
        errors[name] = ''
        this.setState({ formData });
    }

    setPaymentType(event) {
        const { formData } = this.state;
        formData["paymentType"] = event.target.value;
        this.setState({ formData });
    }
    isValid() {
        const { formData, errors } = this.state;
        let error = false;
        if (!formData.deposited_date) {
            error = true
            errors.deposited_date = "this field is requred"
        }
        this.setState({ errors })
        return error
    }
    handleSubmit() {
        if (!this.isValid()) {
            const { formData, errors } = this.state;
            const { PayoutDetails, dispatch, getData } = this.props;
            let dataToSend = {
                payment_type: formData.paymentType,
                reference_number: formData.reference_number,
                deposited_date: moment(formData.deposited_date).format('YYYY-MM-DD'),
                remark: formData.remark,
                id: PayoutDetails.id
            }

            dispatch(expenceStatementActions.updatePayoutDetails(dataToSend, {
                success: () => {
                    this.handelToggle()
                    getData()
                }
            }));
        }
    }
    render() {

        const { modalOpan, formData, errors } = this.state
        const { is_disable, bank_account_details, PayoutDetails } = this.props
        return (<div>

            {is_disable ? <React.Fragment>
                -
            </React.Fragment> : <React.Fragment>

                <button className="btn btn-success btn-sm" disabled={is_disable} onClick={this.handelToggle}> Add Deposit Details</button>
                <br />

                <Modal isOpen={modalOpan} size={"lg"} className="modal-success" toggle={this.handelToggle}    >
                    <ModalHeader toggle={this.handelToggle} >
                        {"Deposit Details"}
                    </ModalHeader>
                    <ModalBody >
                        <div className="row">
                            <div className="col-md-6">
                                <div className={" form-group mb-3"}>
                                    <label>Payment Type </label>
                                    <div onChange={this.setPaymentType.bind(this)}>
                                        {/* <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="customRadio1"
                                                name="paymentType"
                                                value="CASH"
                                                class="custom-control-input"
                                                checked={formData.paymentType === 'CASH'}
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadio1"
                                            >
                                                CASH
                                            </label>
                                        </div> */}
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="customRadio1"
                                                name="paymentType"
                                                value="NEFT"
                                                class="custom-control-input"
                                                checked={formData.paymentType === 'NEFT'}
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadio1"
                                            >
                                                NEFT
                                            </label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="customRadio2"
                                                name="paymentType"
                                                value="UPI"
                                                class="custom-control-input"
                                                checked={formData.paymentType === 'UPI'}
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadio2"
                                            >
                                                UPI
                                            </label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="customRadio3"
                                                name="paymentType"
                                                value="Cheque"
                                                class="custom-control-input"
                                                checked={formData.paymentType === 'Cheque'}
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadio3"
                                            >
                                                Cheque
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className={" form-group mb-3"}>
                                    <label>Reference Number </label>
                                    <Input
                                        name="reference_number"
                                        value={formData.reference_number}
                                        maxLength="50"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        type="text"
                                        placeholder="Reference Number"
                                    />
                                </div>
                                <div className={" form-group mb-3"}>
                                    <label>Deposited Date  </label><br />
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Deposited Date"
                                        selected={formData.deposited_date}
                                        name="deposited_date"
                                        onChange={(date) => this.handleChangeDate(date)}
                                        //     dateFormat="MM/yyyy"
                                        maxDate={moment().toDate()}
                                        minDate={moment(PayoutDetails.created_at).toDate()}
                                    //    showMonthYearPicker
                                    />
                                    {errors.deposited_date && (
                                        <FormFeedback>
                                            {errors.deposited_date}
                                        </FormFeedback>
                                    )}
                                </div>
                                <div className={" form-group mb-3"}>
                                    <label>Remarks  </label>
                                    <Input
                                        name="remark"
                                        value={formData.remark}
                                        maxLength="50"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        type="textarea"
                                        placeholder="Remarks"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                {
                                        bank_account_details  ?
                                            <React.Fragment>
                                                <h6 className='mt-2'>Account Details.</h6>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Bank name </b></div>
                                                    <div className="col-md-8">{bank_account_details ? bank_account_details.bank_name : ''}</div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account holder name </b></div>
                                                    <div className="col-md-8">{bank_account_details ? bank_account_details.account_holder_name : ''}</div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account number	 </b></div>
                                                    <div className="col-md-8">{bank_account_details ? bank_account_details.account_number : ''}</div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-4"><b>IFSC code </b></div>
                                                    <div className="col-md-8">{bank_account_details ? bank_account_details.ifsc_code : ''}
                                                    </div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account type </b></div>
                                                    <div className="col-md-8">{bank_account_details ? ( parseInt(bank_account_details.account_type, 10) == 1 ? 'Saving' : 'Current') : ''}</div>
                                                </div>
                                                
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Bank UPI </b></div>
                                                    <div className="col-md-8">{bank_account_details ? bank_account_details.upi  : ''}</div>
                                                </div>

                                            </React.Fragment>
                                            : ''
                                    }
                            </div>
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.handleSubmit}>
                            Proceed
                        </Button>
                        <Button onClick={this.handelToggle}>Close</Button>
                    </ModalFooter>
                </Modal>


            </React.Fragment>}
        </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getPayoutDetails
    return {
        loading,
        data
    }
}

const AddDepositDetailconnected = connect(mapStateToProps)(AddDepositDetail);
export { AddDepositDetailconnected as AddDepositDetail }
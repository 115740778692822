import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { TableLoader } from '../../../components/Loaders';
import { Collapse } from 'reactstrap';
import Pagination from "react-js-pagination";
import { MilestoneLogsActions } from '../../actions';
import { connect } from 'react-redux'
const TABS = {
    NumberWise: "NumberWise",
    DateWise: "DateWise",
}
class DateWiteContentData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tooltipOpen: false,
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },
            activeTab: ""
        }

        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getMilestoneLogs = this.getMilestoneLogs.bind(this);
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }
    getMilestoneLogs() {
        const { dispatch } = this.props;
        const { page } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            log_type: this.state.activeTab
        }
        dispatch(MilestoneLogsActions.getMilestoneLogs(dataToSend));
    }
    
   

    renderDetails(data) {
        let _data = false;
        
        return _data
    }
    render() {
        const { logs, loading, activeTab } = this.props
        const { collapse, page } = this.state
        let _this = this;

        return (loading ? <TableLoader /> :
            <React.Fragment>
                {/* <div className="table-responsive-sm  animated fadeIn"> */}

                {
                    logs && logs.logs_list && logs.logs_list.length > 0 ?
                        logs.logs_list.map(function (eachData, index) {
                            return <React.Fragment>
                                <div className="milestone-data">
                                { _this.renderDetails(eachData)}
                                </div>

                            </React.Fragment>
                        })
                        : <tr><td colSpan="6" className="text-center">No Data Found</td></tr>
                }

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getMilestoneLogs;
    return {
        loading,
        data
    }
}
const connectedTabContentData = connect(mapStateToProps)(DateWiteContentData)
export { connectedTabContentData as DateWiteContentData }

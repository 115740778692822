export const dashboardConstants = {

    GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
    GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
    GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',

    GET_SALSE_STATUS_OVERVIEW_REQUEST: 'GET_SALSE_STATUS_OVERVIEW_REQUEST',
    GET_SALSE_STATUS_OVERVIEW_SUCCESS: 'GET_SALSE_STATUS_OVERVIEW_SUCCESS',
    GET_SALSE_STATUS_OVERVIEW_FAILURE: 'GET_SALSE_STATUS_OVERVIEW_FAILURE',

    GET_DASHBOARD_DATA_LEAD_VS_REGISTER_REQUEST: 'GET_DASHBOARD_DATA_LEAD_VS_REGISTER_REQUEST',
    GET_DASHBOARD_DATA_LEAD_VS_REGISTER_SUCCESS: 'GET_DASHBOARD_DATA_LEAD_VS_REGISTER_SUCCESS',
    GET_DASHBOARD_DATA_LEAD_VS_REGISTER_FAILURE: 'GET_DASHBOARD_DATA_LEAD_VS_REGISTER_FAILURE',

}
export const communicationTemplateConstants = {  
    ADD_COMMUNICATION_TEMPLATE_REQUEST: 'ADD_COMMUNICATION_TEMPLATE_REQUEST',
    ADD_COMMUNICATION_TEMPLATE_SUCCESS: 'ADD_COMMUNICATION_TEMPLATE_SUCCESS',
    ADD_COMMUNICATION_TEMPLATE_FAILURE: 'ADD_COMMUNICATION_TEMPLATE_FAILURE',


    GET_COMMUNICATION_TEMPLATE_REQUEST: 'GET_COMMUNICATION_TEMPLATE_REQUEST',
    GET_COMMUNICATION_TEMPLATE_SUCCESS: 'GET_COMMUNICATION_TEMPLATE_SUCCESS',
    GET_COMMUNICATION_TEMPLATE_FAILURE: 'GET_COMMUNICATION_TEMPLATE_FAILURE',

    EDIT_COMMUNICATION_TEMPLATE_REQUEST: 'EDIT_COMMUNICATION_TEMPLATE_REQUEST',
    EDIT_COMMUNICATION_TEMPLATE_SUCCESS: 'EDIT_COMMUNICATION_TEMPLATE_SUCCESS',
    EDIT_COMMUNICATION_TEMPLATE_FAILURE: 'EDIT_COMMUNICATION_TEMPLATE_FAILURE',

    PUBLISH_COMMUNICATION_TEMPLATE_REQUEST: 'PUBLISH_COMMUNICATION_TEMPLATE_REQUEST',
    PUBLISH_COMMUNICATION_TEMPLATE_SUCCESS: 'PUBLISH_COMMUNICATION_TEMPLATE_SUCCESS',
    PUBLISH_COMMUNICATION_TEMPLATE_FAILURE: 'PUBLISH_COMMUNICATION_TEMPLATE_FAILURE',
}
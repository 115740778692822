import { notify } from 'reapop'
import { ExpensesConstants } from '../constants'
import { ExpensesService } from '../services'

export const ExpensesAction = {
    getExpenses,
}


function getExpenses(data) {
    return dispatch => {
        dispatch(request())

        ExpensesService.getExpenses(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: ExpensesConstants.GET_EXPENSES_REQUEST } }
    function success(result) { return { type: ExpensesConstants.GET_EXPENSES_SUCCESS, result } }
    function failure(error) { return { type: ExpensesConstants.GET_EXPENSES_FAIL, error } }
}

export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`ContentData`]) {
        formIsValid = false
        errors[`ContentData`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Button, FormGroup, FormFeedback, Label, Card, CardBody, CardHeader, Input } from 'reactstrap';
class BalancePackages extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { isLocal, packages, packageSelected, handleChange } = this.props
        return (
            <React.Fragment>
                <Card className={"border-success"}>
                    <CardHeader className={"text-center border-success bg-success"}> <b>Recharge</b></CardHeader>
                    <CardBody >
                        {
                            packages && packages.balance_charges && packages.balance_charges.length &&
                            <Input type="select" name="packageSelected" value={packageSelected} onChange={handleChange} disabled={packages.balance_charges.length <= 1}  >
                                {packages.balance_charges.map(eachPkg =>
                                    <option key={eachPkg.package_id} value={eachPkg.package_id}> ₹ {eachPkg.amount} for {eachPkg.balance} balance  </option>
                                )};
                            </Input>
                        }
                        <div className="table-responsive-sm">
                            <table className="table table-hover table-striped text-center text-uppercase">
                                <thead>
                                    <tr >
                                        <th>Balance</th>
                                        <th>Amount<br /><small> (Excluding GST) </small> </th>
                                        <th>GST(18%) <br /><small> (display only) </small></th>
                                        <th>Amount<br /><small> (Including GST) </small> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        packages && packages.balance_charges && packages.balance_charges.length &&
                                        packages.balance_charges.map((p, index) => {
                                            if (parseInt(p.package_id, 10) === parseInt(packageSelected, 10)) {

                                                return (
                                                    <tr>
                                                        <td>{p.balance}</td>
                                                        <td>{p.amount}</td>
                                                        <td>{p.igst}</td>
                                                        <td>{p.total_amount}</td>
                                                    </tr>
                                                )
                                            }
                                            return false;
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>

                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedBalancePackages = connect(mapStateToProps)(BalancePackages);
export { connectedBalancePackages as BalancePackages }
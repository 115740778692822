


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AddComments } from './AddComments';
import Interweave from 'interweave';
import moment from 'moment';
import TimeAgo from 'react-timeago'

class TicketsComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }



    render() {

        const { ticketsDetails } = this.props
        return (
            <React.Fragment>

                <label><b>Comments</b></label>

                {ticketsDetails.messages.length ? <React.Fragment>
                    {ticketsDetails.messages.map(function (eachMessage) {
                        return <React.Fragment>
                            <div className="my-2 rounded tickets-messages">
                                <div className="toast fade show" data-autohide="false" style={{ "maxWidth": "100%" }}>
                                    <div className="toast-header">
                                        {eachMessage.user_name} &nbsp; <small >   {moment(eachMessage.created_at).format('DD-MM-YYYY hh:mm A')} ( <TimeAgo date={eachMessage.created_at} />  )  </small>
                                    </div>
                                    <div className="toast-body p-0">
                                        <div className=" ql-snow">
                                            <div className="ql-editor">
                                                <Interweave content={eachMessage.message} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>
                    })}
                </React.Fragment> : <React.Fragment>
                        <div className="my-2 rounded tickets-messages">
                            <div className="toast fade show " style={{ "maxWidth": "100%" }}>
                                <div className="toast-body p-3">
                                    No comments found.
                                    </div>
                            </div>
                        </div>
                    </React.Fragment>}

                <AddComments {...this.props} />


            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    const { ticketsDetails, loading } = state.getTicketDetails;

    return {
        loading,
        ticketsDetails
    };

}

const connectedTicketsComments = connect(mapStateToProps)(TicketsComments);
export { connectedTicketsComments as TicketsComments }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { dashboardActions } from '../../actions';
import {
	CreditStatus, CronStatus,Searches, DashboardCard1, DashboardCard1CurrencyFormat, DashboardCardCol2, LeadVsRegister, MilestoneData, ReputeinfoStatisticsStatus, ReputeinfoUserAnalysis
} from './DashboardCards';

import { Tooltip } from 'react-tippy';
class DashBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getDashboardData = this.getDashboardData.bind(this)
		this.handelRefresh = this.handelRefresh.bind(this)
		this.sumObjectsByKey = this.sumObjectsByKey.bind(this)
	}

	getDashboardData() {
		const { dispatch } = this.props;
		dispatch(dashboardActions.getDashboardData());
		dispatch(dashboardActions.getDashboardDataLeadVsRegister());
	}
	componentDidMount() {
		this.getDashboardData()
	}

	handelRefresh(e) {
		e.preventDefault()
		this.getDashboardData()
	}

	sumObjectsByKey(...objs) {
		return objs.reduce((a, b) => {
			for (let k in b) {
				if (a.hasOwnProperty(k)) {
					a[k] = Number(a[k]) + Number(b[k])
				} else {
					a[k] = b[k];
				}

			}
			return a;
		}, {});
	}



	render() {

		const { dashboard, dashboardLeadVSRegister, dashboardBadDebt, dashboardUserAnalysis } = this.props
		const {
			loading,
			debtor_creditor_registration,
			individual_registration,
			debtor_registration,
			search_data,
			credit_status,
			reputeinfo_statistics_status,
			sales_status_overview,
			milestone_data_day_wise,
			ticket_status,
			cron_status,
			sms_data,
			email_data,
			notification_data,
			whatsapp_schedules,
			whatsapp_sents,
			gridline_search_data,
			earning,
			critcal_due ,
			critcal_due_data,
			user_analysis

		} = dashboard;
		// console.log(dashboardBadDebt);
		// console.log(dashboardUserAnalysis);
		// console.log(critcal_due,"this is data of dashbord");
		return (
			<div className="">
				<h5 className="mb-4 mt-4 ">
					<i className="icon-speedometer"></i> &nbsp; Dashboard
					<div className=" pull-right" >
						<Tooltip arrow={true} title="Refresh">
							<button className="btn btn-sm btn-primary" onClick={this.handelRefresh}>
								<i className="fa fa-refresh"></i>
							</button>
						</Tooltip>
					</div>
				</h5>
				<Row>
					<Col xs="12" md="6" lg="6" xl="5">
						<DashboardCard1CurrencyFormat
							loading={loading}
							title={'Earnings'}
							// title1="Order"
							title1="Registration"
							title2="Recharge"
							title3="Total"
							data1={earning && earning.orders ? earning.orders : false}
							data2={earning && earning.registration ? earning.registration : false}
							data3={earning && earning.recharge ? earning.recharge : false}
							registration_count={earning && earning.registration_counts ? earning.registration_counts : false}
							recharge_count={earning && earning.recharge_counts ? earning.recharge_counts : false}
							icon={<b>₹</b>}
						/>
					</Col>
					<Col xs="12" md="6" lg="6" xl="3">
						<LeadVsRegister
							loading={loading}
							title={'Lead Vs Register'}
							data={dashboardLeadVSRegister}
							icon={<i className="fa fa-exchange" />}
						/>
					</Col>
					<Col xs="12" md="6" lg="6" xl="4">
						<DashboardCardCol2
							loading={loading}
							title={'Registrations'}
							title1="Individual"
							title2="Company"
							data1={individual_registration}
							data2={debtor_creditor_registration}
							icon={<i className="fas fa-user-plus" />}
						/>
					</Col>
					{/* <Col xs="12" sm="6" lg="6" xl="4">
						<LeadVsRegister
							loading={loading}
							title={'Lead Vs Register'}
							data={credit_status}
							icon={<i className="fa fa-exchange" />}
						/>

						<DashboardCard1
							loading={loading}
							title={'GRID LINE DATA'}
							title1="Pan"
							title2="Gst"
							title3="Aadhaar"
							data1={gridline_search_data ? gridline_search_data.pan : false}
							data2={gridline_search_data ? gridline_search_data.gst : false}
							data3={gridline_search_data ? gridline_search_data.aadhaar : false}
							icon={<i className="fa fa-database" />}
						/>
					</Col> */}

					{/* <Col xs="12" md="6" lg="6" xl="4">
						<DashboardCard1
							loading={loading}
							title={'Whatsapp'}
							title1="Received"
							title2="Send"
							title3="Schedule"
							data1={true ? { today: '-', week: '-', month: "-", year: '-', lifetime: '-' } : false}
							data2={whatsapp_sents ? whatsapp_sents : false}
							data3={whatsapp_schedules ? whatsapp_schedules : false}
							icon={<i className="fa fa-whatsapp" />}
						/>
					</Col> */}


					{/* <Col xs="12" md="6" lg="6" xl="3">
						<ReputeinfoStatisticsStatus
							loading={loading}
							title={'Reputeinfo Statistics'}
							data={reputeinfo_statistics_status}
							icon={<i className="fa fa-line-chart" />}
						/>
					</Col> */}

					{/* <Col xs="12" md="6" lg="6" xl="3">
						<CreditStatus
							loading={loading}
							title={'Transaction Status'}
							data={credit_status}
							icon={<i className="fa fa-exchange" />}
						/>
					</Col> */}


					{/* <Col xs="12" sm="6" lg="6" xl="2">
						<TicketStatus
							loading={loading}
							title={'Ticket Status'}
							data={ticket_status}
							icon={<i className="fa fa-ticket" />}
						/>
					</Col> */}

					{/* <Col xs="12" md="12" lg="6">
					
					</Col> */}



					{/* 					
					<Col xs="12" md="12"  lg="6">
						<SalesStatusOverview
							loading={loading}
							title={'Sales Status Overview'}
							data={sales_status_overview}
							icon={<i className="fas fa-history" />}
						/>
					</Col>  */}




					{/* <Col xs="12" md="12"  lg="4">
						<MilestoneData loading={loading}
							title={'Number wise milestone'}
							title1="currnent"
							title2="previous"
							title3="older"
							data1={individual_registration}
							data2={debtor_registration}
							data3={debtor_creditor_registration}
							icon={<i className="fa fa-ticket" />}
						/> 
					</Col> */}

					{/* <Col xs="12" md="12" lg="8"> */}
					{/* <SalesStatus /> */}

					{/* <CronStatus
							loading={loading}
							title={'Cron Status'}
							data={cron_status}
							icon={<i className="fas fa-history" />}
						/>
					</Col> */}

					<Col xs="12" md="6" lg="6" xl="4">

						<Searches
							loading={loading}
							title={'Searches'}							
							data={search_data}
							icon={<i className="fa fa-search" />}
						/>

					</Col>

					<Col xs="12" md="12" lg="8">
						<CronStatus
							loading={loading}
							title={'Cron Status'}
							data={cron_status}
							icon={<i className="fas fa-history" />}
						/>
					</Col>

					<Col xs="12" md="6" lg="6" xl="4">
						<DashboardCard1
							loading={loading}
							title={'GRID LINE DATA'}
							title1="Pan"
							title2="Gst"
							title3="Aadhaar"
							data1={gridline_search_data ? gridline_search_data.pan : false}
							data2={gridline_search_data ? gridline_search_data.gst : false}
							data3={gridline_search_data ? gridline_search_data.aadhaar : false}
							icon={<i className="fa fa-database" />}
						/>
					</Col>
					<Col xs="12" md="6" lg="6" xl="4">
						<DashboardCard1
							loading={loading}
							title={'Critical Due '}
							title1="Direct"
							title2="Send Bill"
							title3="Total"
							data1={critcal_due ? critcal_due.direct_critcal_due : false}
							data2={critcal_due ? critcal_due.transaction_critical_due : false}
							data3={critcal_due ? this.sumObjectsByKey(critcal_due.direct_critcal_due,
								critcal_due.transaction_critical_due) : false}
								icon={<i className="fa fa-rupee text-danger"></i>}
								/>
					</Col>
								{console.log(critcal_due_data ? critcal_due_data : 'asihdasdhkahsd')}
					<Col xs="12" md="6" lg="6" xl="4">
						<DashboardCard1
							loading={loading}
							title={'Total Debtors (Buyer)'}
							title1="Register"
							title2="Unregister"
							title3="Total"
							data1={critcal_due_data ? critcal_due_data.critcal_due_register : false}
							data2={critcal_due_data ? critcal_due_data.critcal_due_unregister  : false}
							data3={critcal_due_data ? this.sumObjectsByKey(critcal_due_data.critcal_due_register ,
								critcal_due_data.critcal_due_unregister ) : false}
							icon={<i className="fa fa-rupee text-danger"></i>}
						/>
					</Col>
					<Col xs="12" md="6" lg="6" xl="4">
					<ReputeinfoUserAnalysis
							loading={loading}
							title={'User Analysis Data'}
							data={user_analysis}
							icon={<i className="fa fa-line-chart" />}
						/>
						<DashboardCard1
							loading={loading}
							title={'Whatsapp'}
							title1="Received"
							title2="Send"
							title3="Schedule"
							data1={true ? { today: '-', week: '-', month: "-", year: '-', lifetime: '-' } : false}
							data2={whatsapp_sents ? whatsapp_sents : false}
							data3={whatsapp_schedules ? whatsapp_schedules : false}
							icon={<i className="fa fa-whatsapp" />}
						/>

						<DashboardCard1
							loading={loading}
							title={'Communications'}
							title1="SMS"
							title2="Email"
							title3="Notification"
							data1={sms_data ? sms_data : false}
							data2={email_data ? email_data : false}
							data3={notification_data ? notification_data : false}
							icon={<i className="fa fa-envelope" />}
						/>

						<ReputeinfoStatisticsStatus
							loading={loading}
							title={'Reputeinfo Statistics'}
							data={reputeinfo_statistics_status}
							icon={<i className="fa fa-line-chart" />}
						/>
						<CreditStatus
							loading={loading}
							title={'Transaction Status'}
							data={credit_status}
							icon={<i className="fa fa-exchange" />}
						/>



					</Col>
					<Col xs="12" md="12" lg="8">
						<MilestoneData loading={loading}
							title={'Date wise milestone'}
							data={milestone_data_day_wise}
							icon={<i className="fa fa-ticket" />}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { dashboard, dashboardLeadVSRegister, dashboardBadDebt, dashboardUserAnalysis } = state
	return {
		dashboard,
		dashboardLeadVSRegister,
		dashboardBadDebt,
		dashboardUserAnalysis,
	};
}

const connectedDashBoard = connect(mapStateToProps)(DashBoard);
export { connectedDashBoard as DashBoard };


import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormFeedback, Input } from "reactstrap";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import Pagination from "react-js-pagination";
import { TableLoader } from "../../../components/Loaders";
import { sendBillTransactionAction } from "../../actions";
import { Chart } from "react-google-charts";
import {
  CardBody,
  Col,
  Row,
  CardHeader,
  ButtonGroup,
  InputGroup,
} from "reactstrap";
class SendBillTrasanctionTab extends Component {
  constructor(props) {
    super(props);

    let days = new Date().getDate();
    this.state = {
      // startDate: moment().subtract(days, 'days'),
      startDate: moment(),
      endDate: moment(),
      serachField: {
        dateRange: "",
      },
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      SearchKey: "",
      errors: {},
      columns: "",
      csv_data: [],
      modalOpan: false,
      is_disable: true,
      isDateChanged: false,
      add_type: "table",
      page: {
        activePage: 1,
        perPage: 50,
        totalItemsCount: 0,
      },
    };
    this.componentRef = "";
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  componentDidMount() {
    const { page } = this.state;
    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
    };

    this.getData(dataToSend);
  }

  handleEvent(event, picker) {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + " to " + end;
    if (start === end) {
      label = start;
    }
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label,
      },
      errors: {},
      isDateChanged: true,
    });
  }

  getData() {
    const { dispatch } = this.props;
    const { page, keywords, startDate, endDate, isDateChanged } = this.state;

    let dataToSend = {
      type: this.props.activeTab,
      active_page: page.activePage,
      per_page: page.perPage,
      keywords: keywords,
    };
    if (isDateChanged) {
      dataToSend[`start_date`] = startDate.format("YYYY-MM-DD");
      dataToSend[`end_date`] = endDate.format("YYYY-MM-DD");
    }
    dispatch(sendBillTransactionAction.getSendBillTransaction(dataToSend));
  }

  handleFetch() {
    this.setState(
      {
        page: {
          activePage: 1,
          perPage: this.state.page.perPage,
          totalItemsCount: this.state.page.totalItemsCount,
        },
      },
      () => this.getData()
    );
  }

  handlePageChange(pageNumber) {
    const { page } = this.state;
    page.activePage = pageNumber;
    this.setState({ page }, () => this.getData());
  }
  componentWillReceiveProps(props) {
    let { page, activeTab } = this.state;
    page.totalItemsCount = 0;
    if (props.data) {
      page.totalItemsCount = props.data.total;
    }
    if (props.activeTab) {
      activeTab = props.activeTab;
    }
    this.setState({
      page,
      activeTab,
    });
  }

  handleReset() {
    let days = new Date().getDate() - 1;

    this.setState(
      {
        ...this.state,
        startDate: moment().subtract(days, "days"),
        endDate: moment(),
        serachField: {
          dateRange: "",
        },
        keywords: "",
        isDateChanged: false,
      },
      () => this.getData()
    );
  }

  handleToggleButton = (type) => {
    this.setState({ add_type: type });
  };
  render() {
    let { loading, data } = this.props;
    const { page, serachField, errors, add_type } = this.state;

    return (
      <div>
        <Row>
          <Col xs="12">
            <CardBody>
              <div>
                <Form autocomplete="off">
                  <div className="form-group">
                    <div className="d-flex flex-wrap mb-2 justify-content-between align-items-center">
                      <div className="mb-2">
                        <DateRangePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onEvent={this.handleEvent}
                          ranges={this.state.ranges}
                        >
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select Date Range"
                              value={serachField.dateRange}
                              id="date_picker"
                              style={{ maxWidth: "220px" }} // Adjusted width for responsiveness
                            />
                            <span className="input-group-btn">
                              <Button
                                className="default date-range-toggle"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fa fa-calendar" />
                              </Button>
                            </span>
                          </div>
                        </DateRangePicker>

                        <Button
                          color="primary"
                          onClick={this.handleFetch}
                          className="mr-2"
                        >
                          Fetch
                        </Button>
                        <Button color="secondary" onClick={this.handleReset}>
                          Reset
                        </Button>
                      </div>

                      <div className="mb-2 order-3 mr-3 order-md-2 ml-lg-auto d-lg-flex">
                        <span style={{ fontSize: 20 }}>
                          <b>
                            Total Send Bill Transaction:{" "}
                            <span className="bg-success mx-2 px-2 rounded">
                              {data && data.total}
                            </span>
                          </b>
                        </span>
                      </div>
                      <div className="mb-2 order-2 order-md-3 ">
                        <InputGroup className="w-auto">
                          <ButtonGroup>
                            <Button
                              className={`${
                                add_type === "table"
                                  ? "bg-primary"
                                  : "bg-light border-primary text-primary"
                              }`}
                              onClick={() => this.handleToggleButton("table")}
                            >
                              <i className="fas fa-table"></i> Table
                            </Button>
                            <Button
                              className={`${
                                add_type === "chart"
                                  ? "bg-primary"
                                  : "bg-light border-primary text-primary"
                              }`}
                              onClick={() => this.handleToggleButton("chart")}
                            >
                              <i className="fa fa-chart-pie"></i> Chart
                            </Button>
                          </ButtonGroup>
                        </InputGroup>
                      </div>
                    </div>

                    <FormFeedback>
                      {errors[`dateRange`] && errors[`dateRange`]}
                    </FormFeedback>
                  </div>
                </Form>

                {add_type === "table" ? (
                  <div>
                    {loading ? (
                      <TableLoader />
                    ) : (
                      <div
                        ref={(el) => (this.componentRef = el)}
                        className="table-responsive-sm  animated fadeIn"
                      >
                        <table className="table table-sm table-condensed  table-bordered  table-outline ">
                          <thead className="thead-light">
                            <tr>
                              <th>SR.NO.</th>
                              <th>Date</th>
                              <th>Sender Individual Name</th>
                              <th>Sender Company Name</th>
                              <th>Sender Mobile</th>
                              <th>Receiver Individual Name</th>
                              <th>Receiver Company Name </th>
                              <th>Receiver Mobile</th>
                              <th>Current status of transaction</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                            data.send_bill_transaction &&
                            data.send_bill_transaction.length > 0 ? (
                              data.send_bill_transaction.map((d, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {(page.activePage - 1) * page.perPage +
                                        (i + 1)}
                                    </td>
                                    <td>{d.created_at}</td>
                                    <td>{d.sender_individual_name}</td>
                                    <td>{d.sender_company_name}</td>
                                    <td>{d.sender_individual_mobile}</td>
                                    <td>{d.receiver_individual_name}</td>
                                    <td>{d.receiver_company_name}</td>
                                    <td>{d.receiver_individual_mobile}</td>
                                    <td
                                      title="Status"
                                      className="text-center align-middle"
                                    >
                                      <span
                                        style={{ fontSize: 12 }}
                                        className={`badge badge-${
                                          d.status === "overdue"
                                            ? "warning"
                                            : d.status === "completed"
                                            ? "success"
                                            : d.status === "pending"
                                            ? "info"
                                            : d.status === "accepted"
                                            ? "success"
                                            : "danger"
                                        }`}
                                      >
                                        {d.status}{" "}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan="9"
                                  className="text-center"
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                  }}
                                >
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {page.totalItemsCount <= page.perPage ? (
                          ""
                        ) : (
                          <Pagination
                            activePage={page.activePage}
                            itemsCountPerPage={page.perPage}
                            totalItemsCount={page.totalItemsCount}
                            pageRangeDisplayed={5}
                            linkClass="page-link"
                            itemClass="page-item"
                            onChange={this.handlePageChange}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {" "}
                    {data &&
                    data.send_bill_transaction &&
                    data.send_bill_transaction.length > 0 ? (
                      <div className="row border border-light mx-0 mb-2 p-3">
                        <div className="col-md-6">
                          <table className="table  table-sm">
                            <thead className="thead-light">
                              <tr>
                                <th></th>
                                <th>Status</th>
                                <th className="text-center">%</th>
                                <th className="text-center">Total</th>
                              </tr>
                            </thead>
                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#137EFF",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">Pending</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#137EFF",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.pending_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#137EFF",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.pending) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#20c997",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">Accepted</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#20c997",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.accepted_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#20c997",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.accepted) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#fa5838",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">Rejected</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#fa5838",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.rejected_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#fa5838",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.rejected) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#FFA500",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">Overdue</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#FFA500",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.overdue_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#FFA500",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.overdue) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#c3371c",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">Issue</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#c3371c",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.issue_percentage) || 0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#c3371c",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.issue) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#6f42c1",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">In-court</span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#6f42c1",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.incourt_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#6f42c1",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.incourt) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#03b983",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">
                                  Completed
                                </span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#03b983",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.completed_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#03b983",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.completed) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: "#e53614",
                                  }}
                                ></i>{" "}
                              </td>
                              <td>
                                <span className="cursor-pointer">
                                  Critical Due
                                </span>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#e53614",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.bad_debt_percentage) ||
                                      0}%{" "}
                                  </span>
                                </b>
                              </td>
                              <td className="text-center">
                                <b>
                                  <span
                                    class=" mx-2 px-4 py-1 rounded"
                                    style={{
                                      color: "#e53614",
                                    }}
                                  >
                                    {" "}
                                    {(data && data.bad_debt) || 0}{" "}
                                  </span>
                                </b>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div className="col-md-6">
                          <Chart
                            width={"100%"}
                            height={"300px"}
                            chartType="PieChart"
                            loader={<TableLoader />}
                            data={[
                              ["Current Status", "Amount"],
                              ["Pending", data && data.pending],
                              ["Accepted", data && data.accepted],
                              ["Rejected", data && data.rejected],
                              ["Overdue", data && data.overdue],
                              ["Issue", data && data.issue],
                              ["In-incourt", data && data.incourt],
                              ["Completed", data && data.completed],
                              ["Critical Due", data && data.bad_debt],
                            ]}
                            options={{
                              title: "",
                              pieHole: 0.4,
                              is3D: false,
                              slices: {
                                0: { color: "#137EFF" },
                                1: { color: "#20c997" },
                                2: { color: "#fa5838" },
                                3: { color: "#FFA500" },
                                4: { color: "#c3371c" },
                                5: { color: "#6f42c1" },
                                6: { color: "#03b983" },
                                7: { color: "#e53614" },
                              },
                            }}
                            rootProps={{ "data-testid": "4" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <CardHeader style={{ borderBottom: "none" }}>
                        <div className="text-center fw-bold">No Data Found</div>
                      </CardHeader>
                    )}
                  </div>
                )}
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getSendBillTransaction;

  console.log("loading", loading);
  return {
    loading,
    data,
  };
}
const connected = connect(mapStateToProps)(SendBillTrasanctionTab);
export { connected as SendBillTrasanctionTab };

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { expenceStatementActions } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
import { currencyFormat } from '../../../helpers';
import DatePicker from 'react-datepicker';
import { Button, ButtonGroup, FormFeedback, InputGroup } from 'reactstrap'
import moment from 'moment';
import { PayoutDetails } from './PayoutDetails'
class TabContentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            till_date: '',
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.addExpenceStatement = this.addExpenceStatement.bind(this);
        this.getExpenceStatement = this.getExpenceStatement.bind(this);
    }
    handleChange(date) {
        this.setState({
            till_date: date,
            errors: {}
        });
    }
    addExpenceStatement() {
        const { dispatch } = this.props
        const { till_date } = this.state
        if (!till_date) {
            this.setState({ errors: { till_date: 'this field is required' } })
            return false;
        }
        let dataToSend = {
            till_date: moment(this.state.till_date).format('YYYY-MM-DD')
        }
        dispatch(expenceStatementActions.addExpenceStatement(dataToSend, {
            success: () => { this.getExpenceStatement() }
        }));
    }
    getExpenceStatement() {
        const { dispatch } = this.props
        dispatch(expenceStatementActions.getExpenceStatement());
    }
    componentDidMount() {
        const { activeTab } = this.props
        this.getExpenceStatement();
    }

    getTotalCommission() {
        const { loading, data } = this.props
        let total_commission_amount = 0;
        data && data.map((eachData) => {
            total_commission_amount = total_commission_amount + parseFloat(eachData.total_commission, 10);
        })
        return currencyFormat(total_commission_amount)
    }

    getTotalPaid() {
        const { loading, data } = this.props
        let total_commission_amount = 0;
        data && data.map((eachData) => {
            total_commission_amount = total_commission_amount + parseFloat(eachData.paid_commission, 10);
        })
        return currencyFormat(total_commission_amount)
    }

    getTotalPending() {
        const { loading, data } = this.props
        let total_commission_amount = 0;
        data && data.map((eachData) => {
            total_commission_amount = total_commission_amount + parseFloat(eachData.pending_commission, 10);
        })
        return currencyFormat(total_commission_amount)
    }
    render() {
        const { loading, data, last_calculate_till_date, last_calculation_date } = this.props
        const { till_date, errors } = this.state
        let _this = this;
        return (loading ? <TableLoader /> :
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 ">
                        <InputGroup>
                            <DatePicker
                                className="form-control"
                                placeholderText="Select Till Date"
                                selected={till_date}
                                minDate={last_calculate_till_date ? moment(last_calculate_till_date).add(1, 'day').toDate() : false}
                                maxDate={moment().subtract(1, 'day').toDate()}
                                onChange={(date) => this.handleChange(date)}
                                dateFormat="yyyy-MM-dd"
                            //    showMonthYearPicker
                            />
                            <div class="input-group-append">
                                <Button color="primary" onClick={() => { this.addExpenceStatement() }}>Calculate Commission Payout  </Button>
                            </div>
                        </InputGroup>
                        {errors && errors.till_date && (<FormFeedback> {errors.till_date} </FormFeedback>)}
                           
                    </div>
                    <div className="col-md-3 pt-2"><b>Last Calculation Date : </b> {last_calculation_date}</div>
                    <div className="col-md-3  pt-2"><b>Last Calculate Till Date : </b>{last_calculate_till_date}</div>
                </div>
                <br />
                <div className="table-responsive-sm  animated fadeIn">
                    <table className="table table-sm table-condensed  table-bordered  table-outline ">
                        <thead className="thead-light">
                            <tr>
                                <th>Calculation Date</th>
                                <th>Calculate From Date</th>
                                <th>Calculate Till Date</th>
                                <th>Total Commission</th>
                                <th>Paid Commission</th>
                                <th>Pending Commission</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 0 ?
                                    data.map(function (eachData, index) {
                                        return <React.Fragment>
                                            <tr>
                                                <td>{eachData.calculation_date}</td>
                                                <td>{eachData.calculate_from_date}</td>
                                                <td>{eachData.calculate_till_date}</td>
                                                <td>₹{currencyFormat(eachData.total_commission)}</td>
                                                <td>₹{currencyFormat(eachData.paid_commission)}</td>
                                                <td>₹{currencyFormat(eachData.pending_commission)}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        
                                                    <PayoutDetails commissionData={eachData} getExpenceStatement={_this.getExpenceStatement} />
                                                    <Button disabled={true}className="btn btn-secondary btn-outline btn-sm">Add Bulk Payouts</Button>
                                                    
                                                    </ButtonGroup>
                                                    </td>
                                            </tr>
                                        </React.Fragment>
                                    })
                                    : <tr><td colSpan="7" className="text-center">No Data Found</td></tr>
                            }

                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Total</th>
                                <th>₹  {_this.getTotalCommission()}</th>
                                <th>₹  {_this.getTotalPaid()}</th>
                                <th>₹  {_this.getTotalPending()}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data, last_calculate_till_date, last_calculation_date } = state.getExpenceStatement
    return { loading, data, last_calculate_till_date, last_calculation_date }
}
const TabContentDataConnected = connect(mapStateToProps)(TabContentData);
export { TabContentDataConnected as TabContentData }
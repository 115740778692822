export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`key`]) {
        formIsValid = false
        errors[`key`] = 'Can Not be empty'
    }
    if (!fields[`value`]) {
        formIsValid = false
        errors[`value`] = 'Can Not be empty'
    }
    return { errors, formIsValid }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { validPhone } from '../../../helpers';
import { businessAccountsActions } from '../../actions';
import { businessAccountsService } from '../../services';
import { InputDropDown } from '../Settings/StageSetting/Components/FormElements';

const defaultFormData = {
    individual_name: '',
    business_name: '',
    mobile: '',
    state_id: '',
    city_id: '',
    sector_id: '',
    is_new_sector: false,
};

class QuickAddBusiness extends Component {
    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            formdata: { ...defaultFormData },
            errors: {},
        }
        this.handleChange = this.handleChange.bind(this)
        this.checkMobile = this.checkMobile.bind(this)
        this.toggleSector = this.toggleSector.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(e) {
        e.preventDefault()
        const { formdata,errors } = this.state
        const { name, value  } = e.target
        formdata[name] = value
        errors[name] = "";
        if (name === "state_id") {
            formdata['city_id'] = ""
        }
        this.setState({ formdata }, () => {
            if (name === 'mobile' && value.length === 10) {
                this.checkMobile();
            }
        })
    }

    checkMobile() {
        const { formdata,errors } = this.state

        formdata.is_mobile_valid = false;
        this.setState({ formdata })
        businessAccountsService.checkMobile({ mobile: formdata.mobile }).then((response) => {
            console.log(response)
            formdata.is_mobile_valid = true;
            this.setState({ formdata })

        }).catch((error) => {
            errors.mobile = error;
            this.setState({ errors })
        });
    }

    handleValidation = (data) => {
        const fields = data
        const errors = {}
        // var business_name = fields[`business_name`]
        // var individual_name = fields[`individual_name`]
        var mobile = fields[`mobile`]

        if (!mobile) {
            errors['mobile'] = "Mobile number Required";
        } else if (!validPhone(mobile)) {
            errors['mobile'] = "Enter valid mobile number";
        }

        var sector_name = fields[`sector_name`];

        if (sector_name && sector_name.length <= 3) {
            errors['sector_id'] = "Please enter atleast 3 charaters to create new sector";
        }

        let formIsValid = Object.keys(errors).length == 0;

        return { errors, formIsValid }
    }

    toggleSector() {
        const { formdata } = this.state
        const { dispatch } = this.props
        formdata.is_new_sector = !formdata.is_new_sector;
        if (formdata.is_new_sector === false) {
            formdata.sector_name = ""
        }
        this.setState({
            formdata
        }, () => {
            dispatch(businessAccountsActions.getSectors());
        })
    }

    onSubmit(e) {
        e.preventDefault();
        const { formdata } = this.state
        const result = this.handleValidation(formdata)
        this.setState({ errors: result.errors })
        if (result.formIsValid) {
            const { dispatch } = this.props;
            this.setState({ saving: true })
            dispatch(businessAccountsActions.addQuickBusinessAccount(formdata, {
                success: () => {
                    this.reset();
                },
                failure: () => {
                    this.setState({
                        saving: false,
                    });
                },

            }));
        }
    }
    reset() {
        const { formdata } = this.state;
        formdata.individual_name = ""
        formdata.business_name = ""
        formdata.mobile = ""

        this.setState({
            formdata,
            saving: false
        });
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(businessAccountsActions.getStates());
        dispatch(businessAccountsActions.getSectors());
    }


    render() {
        const { errors, formdata, saving, isChange } = this.state
        const { loading, sectors, states } = this.props;

        let sectorOptions = [{ title: "Select Sector", value: false }]
        if (sectors) {
            sectors.map((item) => {
                sectorOptions.push({ title: item.name, value: item.id })
            });
        }

        let statesList = [{ title: "Select State", value: false }]
        let cityList = [{ title: "Select District", value: false }]
        if (states) {
            states.map((state) => {
                statesList.push({ title: state.state_name, value: state.id })
                if (parseInt(formdata.state_id, 10) === parseInt(state.id, 10)) {
                    state.cities.map((ct) => {
                        cityList.push({ title: ct.city_name, value: ct.id })
                    });
                }
            });
        }


        return (
            <div className="">
                <div className="card">
                    <div className="card-body">

                        {loading ? <TableLoader /> :
                            <Form onSubmit={this.onSubmit} autocomplete="off" style={{ width: '50%', margin: '0 auto' }}>

                                <FormGroup>
                                    <label className="font-weight-bold">Owner Mobile</label>
                                    <Input placeholder="Owner Mobile" maxLength="10" name="mobile" value={formdata.mobile} onChange={this.handleChange} />
                                    <FormFeedback>
                                        {errors[`mobile`] && errors[`mobile`]}
                                    </FormFeedback>
                                </FormGroup>


                                <FormGroup>
                                    <label className="font-weight-bold">Business Name</label>
                                    <Input placeholder="Business Name" maxLength="100" name="business_name" value={formdata.business_name} onChange={this.handleChange} />
                                    <FormFeedback>
                                        {errors[`business_name`] && errors[`business_name`]}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <label className="font-weight-bold">Owner Name</label>
                                    <Input placeholder="Owner Name" maxLength="100" name="individual_name" value={formdata.individual_name} onChange={this.handleChange} />
                                    <FormFeedback>
                                        {errors[`individual_name`] && errors[`individual_name`]}
                                    </FormFeedback>
                                </FormGroup>


                                <FormGroup>
                                    <label className="font-weight-bold">State</label>

                                    <InputDropDown
                                        value={formdata.state_id}
                                        name={"state_id"}
                                        onChange={this.handleChange}
                                        options={statesList}
                                    />

                                    <FormFeedback>
                                        {errors[`state_id`] && errors[`state_id`]}
                                    </FormFeedback>

                                </FormGroup>

                                <FormGroup>
                                    <label className="font-weight-bold">District</label>

                                    <InputDropDown
                                        value={formdata.city_id}
                                        name={"city_id"}
                                        onChange={this.handleChange}
                                        options={cityList}
                                    />
                                    <FormFeedback>
                                        {errors[`city_id`] && errors[`city_id`]}
                                    </FormFeedback>
                                </FormGroup>



                                <FormGroup>
                                    <label className="font-weight-bold">Secter </label>
                                    {formdata.is_new_sector ?
                                        <button className='btn btn-link' onClick={this.toggleSector}>Select From Existing</button>
                                        : <button className='btn btn-link' onClick={this.toggleSector}>Enter New Sector</button>
                                    }

                                    {formdata.is_new_sector ?
                                        <Input placeholder="Enter Sector Name" maxLength="50" name="sector_name" value={formdata.sector_name} onChange={this.handleChange} />
                                        :
                                        <InputDropDown
                                            value={formdata.sector_id}
                                            name={"sector_id"}
                                            onChange={this.handleChange}
                                            options={sectorOptions}
                                        />
                                    }
                                    {errors.sector_id && (<FormFeedback> {errors.sector_id} </FormFeedback>)}
                                </FormGroup>





                                <FormGroup>
                                    <Button color="primary" type="submit" disabled={saving}> {saving ? "Saving..." : "Save Business"}</Button>&nbsp;&nbsp;&nbsp;

                                </FormGroup>




                            </Form>}
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { sectors } = state.sectors
    const { loading, states } = state.states
    const { user } = state.authentication
    return {
        loading,
        sectors,
        states,
        authUser: user
    }

}

const connectedQuickAddBusiness = connect(mapStateToProps)(QuickAddBusiness)
export { connectedQuickAddBusiness as QuickAddBusiness };


import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

class NoDocumentsAvailable extends Component {
    render() {
        return (
            <div className="no-data-warning text-center">
              <i className="fa fa-exclamation-triangle" aria-hidden="true" style={ { fontSize: "60px" , color: "#f26522"}}>
              </i><h3>No Document Available</h3>
            </div> 
        );
    }
}

const connectedNoDocumentsAvailable = connect(mapStateToProps)(NoDocumentsAvailable);
export { connectedNoDocumentsAvailable as NoDocumentsAvailable }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productTagActions } from '../../actions';
import { CardBody, Card, CardHeader, ModalBody, ModalHeader, ModalFooter, Button, Modal, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
class ProductTags extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			isEdit: false,
			data: {
				tag_name: ''
			},
			errors: {},
			page: {
				perPage: 20,
				totalItemsCount: 0
			}
		};
		this.toggle = this.toggle.bind(this)
		this.getProductTagsData = this.getProductTagsData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.isValid = this.isValid.bind(this);
		this.save = this.save.bind(this);
		this.reset = this.reset.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.editData = this.editData.bind(this);
		this.cancel = this.cancel.bind(this)
		//		this.renderAction = this.renderAction.bind(this);
	}

	cancel(value) {
		const { data } = this.state
		data.tag_name = ''
		this.toggle(value);
		this.setState({
			isEdit: false,
			data
		})
	}
	toggle(value) {
		this.setState({ show: value }, () => {
			if (value == false) {
				this.reset();
			}

		});
	}
	isValid() {
		const { data, errors } = this.state;
		let error = false;
		if (!data.tag_name) {
			error = true
			errors.tag_name = 'this field is requird'
		}

		this.setState({ errors });

		return error;
	}
	save() {
		const { data, isEdit } = this.state;

		const { dispatch } = this.props;
		if (!this.isValid()) {

			let dataToSend = {
				tag_name: data.tag_name
			};

			if (isEdit) {
				dataToSend.id = data.id
			}
			dispatch(productTagActions.addEditProductTags(dataToSend, {
				success: () => {
					this.getProductTagsData();
					this.reset();
					this.toggle(false);
				}
			}))
		}
	}

	reset() {
		const { data } = this.state;
		data.tag_name = '';
		this.setState({
			data,
			errors: {}
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		const { data } = this.state;
		data[name] = value;

		this.setState({ data, errors: {} }, () => {
			value.length >= 3 && this.getProductTagsData()
		});
	}
	componentDidMount() {
		this.getProductTagsData();
	}

	getProductTagsData() {
		const { dispatch } = this.props;
		const { page, data } = this.state;
		let dataToSend = {
			// active_page: page.activePage,
			// per_page: page.perPage
		};

		if (data && data.tag_name.length >= 3) {
			dataToSend.tag_name = data.tag_name
		}

		dispatch(productTagActions.getProductTags(dataToSend));
		// this.setState({ show: false, isEdit: false })
		// this.reset();
	}

	handlePageChange(pageNumber) {
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => this.getProductTagsData());
	}

	componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}

	addData() {
		const { data } = this.state
		data.tag_name = ""
		data.id = ""

		this.toggle(true);
		this.setState({ data, isEdit: false });
	}

	editData(row) {
		const { data } = this.state
		data.tag_name = row.tag_name
		data.id = row.id

		this.toggle(true);
		this.setState({ data, isEdit: true });
	}
	deleteData(row) {
		const { dispatch } = this.props;
		const { page } = this.state;
		let dataToSend = {
			id: row.id
		};

		swal({
			title: 'Are you sure you want to delete ?',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(productTagActions.deleteProductTags(dataToSend, {
					success: () => {
						this.getProductTagsData();
					}
				}));
			}
		});
	}
	renderAction(row) {
		return (
			<React.Fragment>
				{' '}
				<i className="fas fa-edit cursor-pointer " onClick={() => this.editData(row)}>
					
				</i>
				{' '}
				{/* <Button size="sm" color="danger" onClick={() => this.deleteData(row)}>
					{' '}
					Delete{' '}
				</Button> */}
			</React.Fragment>
		);
	}
	render() {
		const { logs, loading, exists_data } = this.props;
		const { page, data, show, isEdit, errors } = this.state;
		let _this = this;
		let _tag_list = [];
		if (logs && logs.tag_list) {
			for (let eachTag in logs.tag_list) {
				_tag_list.push({ key: eachTag, list: logs.tag_list[eachTag] });
			}
		}
		return (
			<Card>
				<CardHeader>
					<i className="fas fa-boxes" />
					<strong>Product Tags</strong>
					<Button color="primary" className="pull-right" onClick={() => {
						this.addData(true)
					}}
					>Add Product Tag </Button>
				</CardHeader>
				<CardBody>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-outline">
									
									<tbody>
										{_tag_list && _tag_list.length ? (
											_tag_list.map((eachData) => {
												return (
													<React.Fragment>

														<tr className="bg-light">
															<td width="2%"> <strong>{eachData.key} </strong>
															</td>
															<td>   
														{eachData.list.map((eachList) => {

															return (
																		<label style={{fontSize:12, fontWeight:"500"}}  className="mb-0 badge badge-success ml-2">
																		{eachList.tag_name} &nbsp;
																		{_this.renderAction(eachList)}
																		</label>
																	
															);
														})}	</td>
														</tr>

													</React.Fragment>
												);
											})
										) : (
											<tr>
												<td colSpan="3" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{/* {page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}

								/>
							)} */}
						</React.Fragment>
					)}
					<Modal
						isOpen={show}
						toggle={() => this.toggle(false)}>
						<ModalHeader>
							{isEdit ? "Edit" : "Add"} Product Tag
						</ModalHeader>
						<ModalBody>
							<Form autoComplete="off" >
								<FormGroup >
									<label><b>Product Tag Name</b></label>

									<Input placeholder="Enter product tag name" onChange={this.handleChange} value={data.tag_name} name="tag_name" />

									<FormFeedback>
										{errors && errors.tag_name && errors.tag_name}
									</FormFeedback>

									{exists_data && exists_data.length > 0 && data.tag_name.length >= 3 ?
									
										<div className="mt-3">
											<strong>Similar product tag exists</strong>
											<ul className="list-group list-group-sm">
												{exists_data.map((each, idx) => {
													return <li key={idx} className="list-group-item">{each.tag_name}</li>
												})}
											</ul>
										</div>
										: false}

								</FormGroup>
							</Form>
						</ModalBody>

						<ModalFooter>
							<Button color="primary" onClick={() => this.save()}>
								Save
							</Button>{" "}
							<Button color="warning" onClick={() => this.reset()}>
								Reset
							</Button>{" "}
							<Button color="secondary" onClick={() => this.cancel(false)}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data, exists_data } = state.getProductTags;
	return {
		loading,
		logs: data,
		exists_data: data && data.exists_data ? data.exists_data : [],
	};
}

const connectedProductTags = connect(mapStateToProps)(ProductTags);
export { connectedProductTags as ProductTags };

export const communicationMastersTemplatesConstants = {

    GET_COMM_MASTERS_TEMP_DATA_REQUEST: 'GET_COMM_MASTERS_TEMP_DATA_REQUEST',
    GET_COMM_MASTERS_TEMP_DATA_SUCCESS: 'GET_COMM_MASTERS_TEMP_DATA_SUCCESS',
    GET_COMM_MASTERS_TEMP_DATA_FAILURE: 'GET_COMM_MASTERS_TEMP_DATA_FAILURE',
    
    SAVE_COMM_MASTERS_TEMP_DATA_REQUEST: 'SAVE_COMM_MASTERS_TEMP_DATA_REQUEST',
    SAVE_COMM_MASTERS_TEMP_DATA_SUCCESS: 'SAVE_COMM_MASTERS_TEMP_DATA_SUCCESS',
    SAVE_COMM_MASTERS_TEMP_DATA_FAILURE: 'SAVE_COMM_MASTERS_TEMP_DATA_FAILURE',

}
import {  axiosAjaxCall } from './common.service'

export const VoiceLogsService = {
    getVoiceLogsData,
 
}

function getVoiceLogsData(data) {
    return axiosAjaxCall("get_voice_logs_details",data);
}

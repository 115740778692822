import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { store } from '../helpers';
import { documentStatus, documentStatusName, PERMISSIONS, PERMISSIONS_FOR_FORM } from "../individual/constants";


export function uniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

function randomEl(list) {
    var i = Math.floor(Math.random() * list.length)
    return list[i]
}

export function arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export function getRendomUserImage() {
    let users = [
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/1.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/2.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/3.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/4.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/5.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/6.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/7.jpg",
        "https://wrappixel.com/demos/admin-templates/pixeladmin/plugins/images/users/8.jpg",

    ]

    return randomEl(users)
}

export function isLoggedIn() {
    try {
        let user = localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : false

        if (user) {
            return true
        }
        return false
    }
    catch (e) {
        localStorage.removeItem('user')
        localStorage.removeItem('company')
        return false
    }
}

export function updateToken(newToken) {
    try {
        let user = localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : false
        user.individual.access_token = newToken
        localStorage.setItem('user', btoa(JSON.stringify(user)))
        return user
    }
    catch (e) {
        return false
    }
}

export function searchCaption(param) {
    switch (param) {
        case "gst":
            return <span className="pull-right badge badge-primary"> GST </span>
        case "pan":
            return <span className="pull-right badge badge-danger"> PAN </span>
        case "aadhar_no":
            return <span className="pull-right badge badge-success"> AADHAAR </span>
        case "mobile":
            return <span className="pull-right badge badge-success"> Mobile </span>
        default:
            return <span className="pull-right badge badge-success"> Repute ID </span>
    }
}

export function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale)
    } else {
        var arr = ("" + num).split("e")
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+"
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale)
    }
}


export function getStatusIcon(status,reason='') {
    let id = Math.random().toString(36).substr(2, 9)
    let statusClassName = "";
    switch (status) {
        case documentStatus.PENDING:
            statusClassName = "fa fa-times-circle text-danger"; break;

        case documentStatus.INREVIEW:
            statusClassName = "fa fa-exclamation-circle text-warning"; break;

        case documentStatus.ACCEPTED:
            statusClassName = "fa fa-check-circle text-success"; break;

        case documentStatus.REJECTED:
            statusClassName = "fa fa-times-circle text-danger"; break;

        default:
            statusClassName = "fa fa-times-circle text-danger"; break;
    }

    let stausText = documentStatusName[status] || documentStatusName[documentStatus.PENDING];

    return <React.Fragment>
        <span className={statusClassName} id={'tooltip_' + id}></span>
        <UncontrolledTooltip placement="top" target={'tooltip_' + id}>
            {stausText}
        </UncontrolledTooltip>{' '}
        {
            (status === documentStatus.REJECTED) ? 
                <React.Fragment>
                    <i className="fa fa-info-circle text-info " id={'tooltip-toc-' + id}></i>
                    <UncontrolledTooltip placement="top" target={'tooltip-toc-' + id}>
                        {reason}
                    </UncontrolledTooltip>
                </React.Fragment>
            : ''
        }
        
    </React.Fragment>;
}

export function currencyFormat(x, rupeeSymbol = false) {
    x = parseFloat(Number(x).toFixed(2));
    x = x.toString();
    var afterPoint = ".00";
    if (x.indexOf(".") > 0) {
      afterPoint = x.substring(x.indexOf("."), x.length);
    }
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    
    return rupeeSymbol ? "₹ "+res  : res;
  }

export function userHasPermission(permissionName) {
    var newState = store.getState();
    const { user } = newState.authentication;
    let permissions = user ? (user.permission ? user.permission.split(',') : []) : [];

    if (permissions.indexOf(PERMISSIONS.ALL) !== -1) return true;

    if (permissions.indexOf(permissionName) !== -1) {
        return true;
    }
    return false;
}

export function generateTextForPermission(permissions) {
    let permission_txt = [];
    if(permissions) {
        let permission_array =  permissions.split(',');
        if (permission_array.indexOf(PERMISSIONS.ALL) !== -1) {
            return  <span> All permissions allowed </span>;
        }
        permission_txt = innerPermissionCheck(permission_array, PERMISSIONS_FOR_FORM, permission_txt)
        
        return permission_txt.map(function(each) {
            return <React.Fragment>
                <span>{each}</span> <br/> 
                </React.Fragment>
        });
    }
   
}

function innerPermissionCheck(permission_array, PERMISSIONS, permission_txt) {
    
    PERMISSIONS.map((eachPermission,index)=>{
        if (eachPermission.children) {
            if (eachPermission.children.length) {
                permission_txt.concat(innerPermissionCheck(permission_array, eachPermission.children, permission_txt));
            }
        } else {
            if (permission_array.indexOf(eachPermission.value) !== -1) {
                permission_txt.push(eachPermission.label);
            }
        }
    });

    return  permission_txt
}

export function renderCronStatus(status) {
	let CronStatus = {
		PENDING: 0,
		COMPLETE: 1,
		FAILED: 2
	};

	switch (parseInt(status, 10)) {
		case CronStatus.PENDING:
			return <span className="badge badge-warning"> Pending </span>;
			break;
		case CronStatus.COMPLETE:
			return <span className="badge badge-success"> Complete </span>;
			break;
		case CronStatus.FAILED:
			return <span className="badge badge-danger"> Failed </span>;
			break;
	}
}

export function clean_text(tg) {

    let _tg = tg.trim();
    if(_tg.indexOf("#") !== -1) {
      _tg = _tg.substr(0,_tg.indexOf("#"));
    } 
    _tg = _tg.replace(/_/gi," ");
    _tg = _tg[0].toUpperCase() + _tg.slice(1);
    return _tg
}

export function clean_tag(tg) {

    let _tg = tg.trim();
    if(tg.indexOf("#") !== -1) {
      _tg = tg.substr(0,tg.indexOf("#"));
    } 

    return _tg
}
 


export function validateItem(item, errorsList){
    let options = {
      type : "text",
    }

    if(item.hasOwnProperty('children')){

      item.children.map(( eachItem1) =>{
        validateItem(eachItem1, errorsList)
      })

    } else {
      if(item.hasOwnProperty('attribute') && item.attribute.hasOwnProperty('validate')) {
        
        let _option = item.attribute.validate.split("|");   
        _option.map((each) =>{
          let splt = each.split(":");
          if(splt[1] === undefined) {
            options[splt[0].trim()] = true;
          } else {
            options[splt[0].trim()] = splt[1].trim();
          }
        })

        if(options.hasOwnProperty("required")) {
          if(!item.value.trim()) {
            let errtxt = item.title + " field is required.";
             errorsList.push(errtxt);
          }

        } 
        
        if(options.hasOwnProperty("min")) {
          
          if(item.value.length && item.value.length <  parseInt(options.min, 10 ) ) {
            let errtxt = item.title + " should contains atleast " + options.min + " characters.";
             errorsList.push(errtxt);
          }
        } 
        
        if(options.hasOwnProperty("max")) {
          
          if( item.value.length >  parseInt(options.max, 10 ) ) {
            let errtxt = "sorry! " + item.title + " is exeeding " + options.max + " characters limit.";
             errorsList.push(errtxt);
          }
        }

        if(options.hasOwnProperty('type') && options.type === "numbers" && item.value.length) {
          if(/[0-9]/.test(item.value) === false) {
            let errtxt =   item.title + " field is allow only numbers.";
             errorsList.push(errtxt);
          }
        }

        
        if(options.hasOwnProperty('minNum') && item.value.length) {
          if(parseInt(item.value) < parseInt(options.minNum, 10)   ) {
            let errtxt =   item.title + " field value should be greater then " +options.minNum +".";
             errorsList.push(errtxt);
          }
        }

        if(options.hasOwnProperty('maxNum') && item.value.length) {
          if( parseInt(item.value) > parseInt(options.maxNum, 10)   ) {
            let errtxt =   item.title + " field value should be less then " +options.maxNum +".";
             errorsList.push(errtxt);
          }
        }

        return errorsList
      }
    }
  }

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { businessAccountsActions } from "../../../actions";
import { InputTypes } from "../../../constants/stages.constants";
import { AssignToDropDown } from "./AssignToDropDown";
import ViewAccountDetailsData from "./ViewAccountDetailsData";

class ViewAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      business_details: [],
      individual_details: [],
      data: { title: "", value: "  " },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  getData() {
    const { dispatch, business_details_id } = this.props;

    if (business_details_id) {
      this.setState({
        business_details_id,
        isEdit: true,
      });
      dispatch(
        businessAccountsActions.getBusinessAccounts({ business_details_id })
      );
    }
  }

  toggle(e) {
    if (e) {
      e.stopPropagation();
    }
    const { isEdit, current_data } = this.props;
    let { data } = this.state;

    data = { ...data, ...current_data };
    console.log(current_data);
    let isOpen = !this.state.isOpen;
    if (isOpen === true) {
      this.getData();
    }
    this.setState({ isOpen, data });
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    if (data.inputType === InputTypes.Checkbox) {
      data[name] = event.target.checked;
    } else {
      data[name] = value;
    }
    this.setState(data);
  }

  save() {
    const { data } = this.state;
    const { save, index } = this.props;

    this.toggle();
  }

  validation() {
    let { data } = this.state;
    let error = {};
    let isError = false;

    if (data.stage_name.trim() === "") {
      error.stage_name = "Enter stage code";
      isError = true;
    }

    if (data.heading.trim() === "") {
      error.heading = "Enter stage name";
      isError = true;
    }

    this.setState({ error });
    return isError;
  }

  componentWillReceiveProps(props) {
    let { isEdit, individual_details } = this.state;
    let {
      businessAccountTemplate,
      businessDetails,
      individualDetails,
      currentStageJson,
    } = props;
    console.log("businessDetails ", businessDetails);
    console.log("individualDetails ", individualDetails);

    if (isEdit) {
      if (businessDetails) {
        let businessDetails_parse = businessDetails
          ? JSON.parse(businessDetails)
          : [];
        let individual_details_parse = individualDetails
          ? JSON.parse(individualDetails)
          : [];
        individual_details = individual_details_parse;
        let current_stage = currentStageJson
          ? JSON.parse(currentStageJson.json)
          : [];

        this.setState({
          template: businessAccountTemplate,
          business_details: businessDetails_parse,
          individual_details,
          current_stage,
          // stages:businessAccountTemplate.stages.Items
        });
      }
    } else {
      if (businessAccountTemplate) {
        // let template_data = JSON.parse(businessAccountTemplate);
        if (
          typeof businessAccountTemplate === "object" &&
          businessAccountTemplate.hasOwnProperty("individual_details")
        ) {
          this.setState({
            template: businessAccountTemplate,
            business_details: businessAccountTemplate.business_details.children,
          });
        }
      }
    }
  }

  render() {
    const { data, error } = this.state;
    const {
      business_details,
      individual_details,

      business_details_id,
    } = this.state;
    const { getData, item } = this.props;

    // let Options = [{ title : "Not Specified", value : false}]
    // if(template) {
    //     template.stages.Items.map((item)=>{
    //         Options.push({ title : item.heading, value : item.stage_name})
    //     });
    // }
    return (
      <React.Fragment>
        {/* <span onClick={(e) => this.toggle(e)}>
            <i className="fas fa-edit  text-primary cursor-pointer" />{" "}
            </span> */}
        <Button onClick={this.toggle} className="btn-info">
          View
        </Button>{" "}
        &nbsp;&nbsp;
        <Modal size="lg" className="modal-primary" isOpen={this.state.isOpen}>
          <ModalHeader toggle={this.toggle}>
            Business Account Details
            <div style={{ position: "absolute", top: 15, right: 50 }}>
              <AssignToDropDown item={item} getData={getData} />
            </div>
          </ModalHeader>
          <ModalBody>
            <ViewAccountDetailsData
              business_details={business_details}
              individual_details={individual_details}
              business_details_id={business_details_id}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { businessAccountTemplate, businessAccounts } = state;
  return {
    businessAccountTemplate,
    businessDetails: businessAccounts.businessDetails,
    individualDetails: businessAccounts.individualDetails,
    currentStageJson: businessAccounts.currentStageJson,
  };
}

const connected = connect(mapStateToProps)(ViewAccountDetails);
export { connected as ViewAccountDetails };

import React from 'react';

import { Card, CardBody, CardHeader} from 'reactstrap';
export function DocumentPreview(data) {
    
    const iframStyle = {
        border: 0,
        width: "100%",
        height: "300px"
    }

    return  <div className="col-md-4">
    <Card>
        <CardHeader> <b> { data.title } </b> </CardHeader>
        <CardBody>
            <iframe src={ data.url }
                style={iframStyle}
                title={ data.title  } />
        </CardBody>
        {/* <CardFooter>
            <Button> Action </Button>    
            <Button> Action 2 </Button>    
        </CardFooter> */}
    </Card></div>
}
import classnames from "classnames";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { businessAccountsActions } from "../../actions";
import { DEFAULT_USER_TYPE } from "../../constants";
import { QuickAddBusiness } from "./QuickAddBusiness";
import { TabContentData } from "./TabContentData";
import { BlockBusinessAccount } from "./BlockBusinessAccount";
const TABS = {
  Assigned: "assigned",
  Linked: "linked",
  NotLinked: "notLinked",
  All: "all",
  QuickAdd: "QuickAdd",
  BlockAdd: "BlockAdd",
};

class BusinessAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TABS.NotLinked,
      active_page: 1,
      per_page: 20,
    };

    this.editData = this.editData.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  editData(d) {
    this.props.history.push("/customer-detail/edit/" + d.id);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { dispatch } = this.props;
    const { activeTab, active_page, per_page } = this.state;
    let dataTosend = {
      activeTab: activeTab,
      active_page: active_page,
      per_page: per_page,
      //is_introduce: activeTab === TABS.Introduce ? true : false
    };
    dispatch(businessAccountsActions.getBusinessAccounts(dataTosend, this));
  }
  handleChangeTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          active_page: 1,
        },
        function () {
          this.getData();
        }
      );
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ active_page: pageNumber });
    this.setState({ active_page: pageNumber }, () => this.getData());
  }

  render() {
    const { data, loading, total_records } = this.props;

    let _data = typeof data === "object" ? data : [];
    let _this = this;
    return (
      <div>
        <Card>
          <CardHeader>
            Business Accounts
            <Link
              className="btn btn-primary btn-sm pull-right"
              to={"/business-account/add"}
            >
              Add Business Account
            </Link>
          </CardHeader>
          <CardBody>
            <Nav tabs>
              {this.props.authUser.user_type_name ==
              DEFAULT_USER_TYPE.SUPER_ADMIN ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.Assigned,
                    })}
                    onClick={() => {
                      this.handleChangeTab(TABS.Assigned);
                    }}
                  >
                    <b>Assigned to me</b>
                  </NavLink>
                </NavItem>
              ) : (
                false
              )}

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.NotLinked,
                  })}
                  onClick={() => {
                    this.handleChangeTab(TABS.NotLinked);
                  }}
                >
                  <b>Not Linked</b>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.Linked,
                  })}
                  onClick={() => {
                    this.handleChangeTab(TABS.Linked);
                  }}
                >
                  <b>Linked</b>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.All,
                  })}
                  onClick={() => {
                    this.handleChangeTab(TABS.All);
                  }}
                >
                  <b>All</b>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.QuickAdd,
                  })}
                  onClick={() => {
                    this.handleChangeTab(TABS.QuickAdd);
                  }}
                >
                  <b>Quick Add Business Account</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.BlockAdd,
                  })}
                  onClick={() => {
                    this.handleChangeTab(TABS.BlockAdd);
                  }}
                >
                  <b>Block Business Account</b>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={TABS.Assigned}>
                <TabContentData
                  loading={loading}
                  data={_data}
                  activeTab={this.state.activeTab}
                  getData={_this.getData}
                />
              </TabPane>
              <TabPane tabId={TABS.Linked}>
                <TabContentData
                  loading={loading}
                  data={_data}
                  activeTab={this.state.activeTab}
                  getData={_this.getData}
                />
              </TabPane>
              <TabPane tabId={TABS.NotLinked}>
                <TabContentData
                  loading={loading}
                  data={_data}
                  activeTab={this.state.activeTab}
                  getData={_this.getData}
                />
              </TabPane>
              <TabPane tabId={TABS.All}>
                <TabContentData
                  loading={loading}
                  data={_data}
                  activeTab={this.state.activeTab}
                  getData={_this.getData}
                />
              </TabPane>
              <TabPane tabId={TABS.QuickAdd}>
                <QuickAddBusiness />
              </TabPane>
              <TabPane tabId={TABS.BlockAdd}>
                <BlockBusinessAccount />
              </TabPane>
            </TabContent>
          </CardBody>

          {total_records && this.state.activeTab !== TABS.QuickAdd  && this.state.activeTab !== TABS.BlockAdd ? (
            <React.Fragment>
              {total_records <= this.state.per_page ? (
                ""
              ) : (
                <Pagination
                  activePage={this.state.active_page}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={total_records}
                  pageRangeDisplayed={5}
                  linkClass="page-link"
                  itemClass="page-item"
                  onChange={this.handlePageChange}
                />
              )}
            </React.Fragment>
          ) : (
            false
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { businessAccounts } = state;
  const { user } = state.authentication;
  return {
    authUser: user,
    data: businessAccounts.businessAccounts,
    total_records: businessAccounts.total_records,
    loading: businessAccounts.loading,
  };
};

const connected = connect(mapStateToProps)(BusinessAccounts);
export { connected as BusinessAccounts };

import { ExceptionLogsConstants } from "../constants"

export function getExceptionLogs(state = {}, action) {
  switch (action.type) {
    case ExceptionLogsConstants.GET_ExceptionLogs_REQUEST:
      return { loading: true }
    case ExceptionLogsConstants.GET_ExceptionLogs_SUCCESS:
      return { data: action.result }
    case ExceptionLogsConstants.GET_ExceptionLogs_FAILURE:
      return {}
    default:
      return state
  }
}
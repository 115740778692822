import { notify } from 'reapop'
import { SMSConstants } from '../constants'
import { SMSService } from '../services'

export const SMSActions = {
    addSMS,
    getSMS,
    editSMS,
    deleteSMS,
    publishSMS
}

function addSMS(data) {
    return dispatch => {
        dispatch(request({ data }));

        SMSService.addSMS(data)
            .then(
                notVerified => dispatch(success(notVerified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SMSConstants.ADD_SMS_REQUEST } }
    function success(result) { return { type: SMSConstants.ADD_SMS_SUCCESS, result } }
    function failure(error) { return { type: SMSConstants.ADD_SMS_FAILURE, error } }
}

function getSMS(data) {
    return dispatch => {
        dispatch(request({ data }));
        SMSService.getSMS(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SMSConstants.GET_SMS_REQUEST } }
    function success(result) { return { type: SMSConstants.GET_SMS_SUCCESS, result } }
    function failure(error) { return { type: SMSConstants.GET_SMS_FAILURE, error } }
}

function editSMS(data,ref) {
    return dispatch => {
        dispatch(request())
        SMSService.editSMS(data)
            .then(
                result => { 
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Successfully', status: "success" }))
                    ref.afterSave()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SMSConstants.EDIT_SMS_REQUEST } }
    function success(result) { return { type: SMSConstants.EDIT_SMS_SUCCESS, result } }
    function failure(error) { return { type: SMSConstants.EDIT_SMS_FAILURE, error } }
}

function deleteSMS() {
    return dispatch => {
        dispatch(request())

        SMSService.deleteSMS()
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SMSConstants.DELETE_SMS_REQUEST } }
    function success(result) { return { type: SMSConstants.DELETE_SMS_SUCCESS, result } }
    function failure(error) { return { type: SMSConstants.DELETE_SMS_FAILURE, error } }
}

function publishSMS(data,ref) {
    return dispatch => {
        dispatch(request())
        SMSService.publishSMS(data)
            .then(
                verified => { 
                    dispatch(success(verified))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Published Successfully', status: "success" }))
                    ref.getSMS()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SMSConstants.PUBLISH_SMS_REQUEST } }
    function success(result) { return { type: SMSConstants.PUBLISH_SMS_SUCCESS, result } }
    function failure(error) { return { type: SMSConstants.PUBLISH_SMS_FAILURE, error } }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Link } from "react-router-dom"
import swal from 'sweetalert'

import { salesAction } from '../../../actions/sales.action';
import { SALES_STATUS } from '../../../constants';
import moment from 'moment';

import { TimelineStructure, CommonDatePicker } from './CommonComponents';

class ScheduleCustomerCallForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                action_status: 1,
                action_note: "",
                schedule_status: 1,
                schedule_date: new Date(),
            },
            errors: []
        }

        this.afterAction = this.afterAction.bind(this)
        this.setStatus = this.setStatus.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleChange(event) {
        const { name, value } = event.target;
        const { formData } = this.state;
        formData[name] = value
        this.setState({ formData });
    }


    afterAction() {
        const { dispatch, customer_id} = this.props;
        dispatch(salesAction.getCustomerSalesStatusData({ customer_id: customer_id }));
    }

    handleSubmit(e) {
        e.preventDefault();
        const { formData, cs_id } = this.state;
        const { dispatch, customer_id, recordCounts } = this.props;
        let dataToSend = {
            action_data: JSON.stringify({
                schedule_status: formData.schedule_status,
                schedule_date: moment(formData.schedule_date).format("YYYY-MM-DD HH:mm:ss"),
            }),
            action_status: 1,
            action_type: SALES_STATUS.SCHEDULE_CUSTOMER_CALL,
            action_note: formData.action_note,
            customer_id: customer_id,
            recordCounts: recordCounts
        };
        if (cs_id) {
            dataToSend.cs_id = cs_id
        }

        let _this = this
        swal({
            title: "Are you sure ?",
            text: "Do you want to update status?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(salesAction.updateCustomerSalesStatus(dataToSend, _this));
            }
        })
    }

    renderCustomerName(cell, row, rowIndex, formateExtraData) {
        return row.first_name + " " + row.last_name
    }

    setStatus(event) {
        const { formData } = this.state;
        formData[event.target.name] = parseInt(event.target.value, 10)
        this.setState({ formData })
    }

    onChange = date => {
        const { formData } = this.state;
        formData["schedule_date"] = date;
        this.setState({ formData })
    }

    componentDidMount() {
        const { formData } = this.props;

        if (formData) {
            let action_data = formData.action_data ? JSON.parse(formData.action_data) : {};
            this.setState({
                formData: {
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : 1,
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                },
                oldFormData: {
                    ...formData,
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : 1,
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                },
                cs_id: formData.id
            })
        }

    }
    render() {
        const { loading } = this.props
        const { formData, oldFormData } = this.state

        return (
            <React.Fragment>
                <TimelineStructure
                    title={"Call to set demo appointment"}
                    iconStyle={{
                        background: '#0194C8', color: '#fff'
                    }}
                    icon={<i className="fas fa-phone"></i>}
                    oldFormData={oldFormData}
                >
                    <Form  autocomplete="off" >
                        {oldFormData && parseInt(oldFormData.action_status, 10) === 1 ?
                            <React.Fragment>
                                Customer call scheduled on {moment(oldFormData.schedule_date).format("YYYY-MM-DD hh:mm A")}.
                                </React.Fragment>
                            :
                            <React.Fragment>
                                <CommonDatePicker selectedDate={formData.schedule_date} onChange={this.onChange} />
                                <FormGroup>
                                    <Button color="primary" onClick={this.handleSubmit} disabled={loading || parseInt(formData.action_status, 10) === -1}>{loading ? "Please wait..." : "Update"}</Button> {" "}
                                    <Link className="btn btn-secondary" to={"/customer-sales-status"}> Cancel </Link>
                                </FormGroup>
                            </React.Fragment>}

                    </Form>
                </TimelineStructure>

            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.updateCustomerSalesStatus
    return {
        loading,
        data
    }
}

const connectedScheduleCustomerCallForm = connect(mapStateToProps)(ScheduleCustomerCallForm)
export { connectedScheduleCustomerCallForm as ScheduleCustomerCallForm }
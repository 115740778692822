import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reputeinfoActions, reputeinfoBalancePackageActions } from "../../actions";
import { CardBody, Card, CardHeader, Button, ButtonGroup } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import Alert from 'reactstrap/lib/Alert';
import swal from 'sweetalert';


class ReputeinfoBalancePackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getBalancePackage = this.getBalancePackage.bind(this);
        this.getSetting = this.getSetting.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
        this.getBalancePackage()
        this.getSetting()
    }

    getBalancePackage() {
        const { dispatch } = this.props;
        dispatch(reputeinfoBalancePackageActions.getBalancePackage());
    }
    editData(d) {
        this.props.history.push('/reputeinfo-balance/edit/' + d.id)
    }
    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <Button size="sm" color="primary" onClick={() => this.editData(row)} > Edit </Button>  {" "}
        </React.Fragment>
    }

    getSetting() {
        const { dispatch } = this.props;

        let dataToSend = {
            key: 'REGISTRATION_PAYMENT'
        }
        dispatch(reputeinfoActions.getSettings(dataToSend));
    }

    registrationPayment(isEnable) {
        const { dispatch } = this.props
        swal({
            title: "Are you sure ?",
            text: "Do you want to " + (isEnable ? "Enable" : "Disable") + " Online Payment at Registration ?",
            isHtml: true,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {
                let dataTosend = {
                    key: 'REGISTRATION_PAYMENT',
                    value: isEnable ? 'enable' : 'disable',
                }

                dispatch(
                    reputeinfoActions.updateSettings(dataTosend, {
                        success: () => {
                            this.getSetting()
                        }
                    })
                );

            }
        });
    }

    render() {
        const { data, loading, settings } = this.props
        const columns = [
            {
                text: 'Package Title',
                dataField: 'title',
                sort: true
            },
            {
                text: 'Action',
                dataField: 'action',
                formatter: this.renderAction

            }]
        return (
            <Card>
                <CardHeader>
                    <i className="fas fa-boxes"></i><strong>Reputeinfo Balance packages</strong>
                </CardHeader>
                <CardBody>
                    {settings && settings.hasOwnProperty('value') &&
                        <Alert  color={settings.value == 'disable' ? 'danger' : 'success'} className="h5 ">
                            Online Payment at Registration  <span className={"font-weight-bolder " + (settings.value == 'disable' ? 'tex-danger' : 'text-success') } >
                            {settings.value == 'disable' ? 'Disabled' : 'Enabled'}
                            
                            </span>
                        
                            <ButtonGroup className="mx-4" >
                                {settings.value == 'disable' ?
                                    <Button color={'success'} onClick={() => this.registrationPayment(true)}>Enable</Button>
                                    : <Button color={'danger'} onClick={() => this.registrationPayment(false)}>Disable</Button>}
                            </ButtonGroup>
                        </Alert>}
                    {loading ? <TableLoader /> :
                        <div className="">
                            <DataTable keyField="id"
                                data={data || []} columns={columns}
                                filter={filterFactory()}
                                noDataIndication={"No data found"} />
                        </div>
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getBalancePackage
    const { settings } = state.getSetting
    const { user } = state.authentication

    console.log('settings ----- ', settings);
    return {
        loading,
        data,
        settings,
        authUser: user

    }
}

const connectedReputeinfoBalancePackage = connect(mapStateToProps)(ReputeinfoBalancePackage)
export { connectedReputeinfoBalancePackage as ReputeinfoBalancePackage }

import { customerDetail } from "../constants"

export function pinCodeDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.PINCODE_REQUEST:
        return { loading: true }
      case customerDetail.PINCODE_SUCCESS:
        return action.result;
      case customerDetail.PINCODE_FAILURE:
        return {}
      default:
        return state
    }
  }
  
  export function addCustomerDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.ADD_CUSTOMER_DETAIL_REQUEST:
        return { loading: true }
      case customerDetail.ADD_CUSTOMER_DETAIL_SUCCESS:
        return action.result;
      case customerDetail.ADD_CUSTOMER_DETAIL_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getCustomerDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.GET_CUSTOMER_DETAILS_REQUEST:
        return { loading: true }
      case customerDetail.GET_CUSTOMER_DETAILS_SUCCESS:
          let result = action.result;
          if(result.customerData) {
            return { 
              customerData: result.customerData ? result.customerData : [],
              userData: result.users ? result.users : [],
              total_records: result.total_records ? result.total_records : [],
            }  
          }
          else {
            return {data:result}      
          }
      case customerDetail.GET_CUSTOMER_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updateCustomerDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.UPDATE_CUSTOMER_DETAILS_REQUEST:
        return { loading: true }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_SUCCESS:
        return { }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updateCustomerDetailLanguage(state = {}, action) {
    switch (action.type) {
      case customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_REQUEST:
        return { loading: true }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_SUCCESS:
        return { }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function deleteCustomerDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.DELETE_CUSTOMER_DETAILS_REQUEST:
        return { loading: true }
      case customerDetail.DELETE_CUSTOMER_DETAILS_SUCCESS:
        return { data: action.result }
      case customerDetail.DELETE_CUSTOMER_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }
  
  export function assignCustomerDetail(state = {}, action) {
    switch (action.type) {
      case customerDetail.ASSIGN_CUSTOMER_DETAILS_REQUEST:
        return { loading: true }
      case customerDetail.ASSIGN_CUSTOMER_DETAILS_SUCCESS:
        return { data: action.result }
      case customerDetail.ASSIGN_CUSTOMER_DETAILS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function uploadCsvData(state = {}, action) {
    switch (action.type) {
      case customerDetail.UPLOAD_CSV_REQUEST:
        return { loading: true }
      case customerDetail.UPLOAD_CSV_SUCCESS:
        return { data: action.result }
      case customerDetail.UPLOAD_CSV_FAILURE:
        return {}
      default:
        return state
    }
  }
  
  export function updateCustomerDetilEmail(state = {}, action) {
    switch (action.type) {
      case customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_REQUEST:
        return { loading: true }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_SUCCESS:
        return { }
      case customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_FAILURE:
        return {}
      default:
        return state
    }
  }

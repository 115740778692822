import { axiosAjaxCall } from "./common.service";

export const promoCodeUsagesService = {
    getPromoCodeUsages,
}


function  getPromoCodeUsages(data) {    
    return axiosAjaxCall("get-promo-code-usage", data);
}


export const expenceStatementConstants = {
    ADD_COMMISSION_CALCULATION_REQUEST: 'ADD_COMMISSION_CALCULATION_REQUEST',
    ADD_COMMISSION_CALCULATION_SUCCESS: 'ADD_COMMISSION_CALCULATION_SUCCESS',
    ADD_COMMISSION_CALCULATION_FAILURE: 'ADD_COMMISSION_CALCULATION_FAILURE',

    GET_COMMISSION_CALCULATION_REQUEST: 'GET_COMMISSION_CALCULATION_REQUEST',
    GET_COMMISSION_CALCULATION_SUCCESS: 'GET_COMMISSION_CALCULATION_SUCCESS',
    GET_COMMISSION_CALCULATION_FAILURE: 'GET_COMMISSION_CALCULATION_FAILURE',

    GET_PAYOUT_DETAILS_REQUEST: 'GET_PAYOUT_DETAILS_REQUEST',
    GET_PAYOUT_DETAILS_SUCCESS: 'GET_PAYOUT_DETAILS_SUCCESS',
    GET_PAYOUT_DETAILS_FAILURE: 'GET_PAYOUT_DETAILS_FAILURE',

    UPDATE_PAYOUT_DETAILS_REQUEST: 'UPDATE_PAYOUT_DETAILS_REQUEST',
    UPDATE_PAYOUT_DETAILS_SUCCESS: 'UPDATE_PAYOUT_DETAILS_SUCCESS',
    UPDATE_PAYOUT_DETAILS_FAILURE: 'UPDATE_PAYOUT_DETAILS_FAILURE',

    GET_ALL_PAYOUT_DETAILS_REQUEST: 'GET_ALL_PAYOUT_DETAILS_REQUEST',
    GET_ALL_PAYOUT_DETAILS_SUCCESS: 'GET_ALL_PAYOUT_DETAILS_SUCCESS',
    GET_ALL_PAYOUT_DETAILS_FAILURE: 'GET_ALL_PAYOUT_DETAILS_FAILURE',
}
import React from 'react'
import { Button, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { GstNoInput } from '../../../../../components/Forms/Inputs'

const UpdateGst = ({ toggle, is_gst_update_modal_open, handleChange, gst_no, onSaveGstUpdate , errors_gst }) => {
    
    return (
        <React.Fragment>
            <Button color="primary" size="sm" className='btn-sm m-1' onClick={toggle}>Update GST</Button>
            <Modal isOpen={is_gst_update_modal_open} >
                <ModalHeader toggle={toggle}>Update GST Number</ModalHeader>
                <ModalBody>
                    <GstNoInput name={'gst_no'} value={gst_no} handleChange={handleChange} disabled={false} />
                    <FormFeedback>{errors_gst}</FormFeedback>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onSaveGstUpdate}>Update</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateGst
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reputeinfoBalancePackageActions } from "../../actions";
import {  CardBody, Card, CardHeader, Button } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import { UpdateBalanceSetting } from './UpdateBalanceSetting'

class ReputeinfoBalanceSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getBalanceSetting = this.getBalanceSetting.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
       this.getBalanceSetting()
    }

    getBalanceSetting() {
        const { dispatch } = this.props;
        dispatch(reputeinfoBalancePackageActions.getBalanceSetting());
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <UpdateBalanceSetting editData={row} getBalanceSetting={this.getBalanceSetting} />
        </React.Fragment>
    }
    render() {
        const { data, loading } = this.props
        const columns = [
            {
                text: 'Type',
                dataField: 'type',
                sort: true
            },
            {
                text: 'Value',
                dataField: 'value',
                sort: true
            },
            {
                text: 'Action',
                dataField:'action',
                formatter: this.renderAction

            }]
        return (
            <Card>
                <CardHeader>
                    <i className="fa fa-cog"></i><strong>Reputeinfo Balance Settings</strong>
                </CardHeader>
                <CardBody>
                    {loading ? <TableLoader /> :
                        <div className="">
                            <DataTable keyField="id"
                                data={data || []} columns={columns}
                                filter={filterFactory()}
                                noDataIndication={"No data found"} />

                        </div>
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getBalanceSetting
    const { user } = state.authentication

    return {
        loading,
        data ,
        authUser: user

    }
}

const connectedReputeinfoBalanceSetting = connect(mapStateToProps)(ReputeinfoBalanceSetting)
export { connectedReputeinfoBalanceSetting as ReputeinfoBalanceSetting }

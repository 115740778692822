export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true
    var dateRange =  fields[`dateRange`]
    if (!fields[`dateRange`] || dateRange === '') {
        formIsValid = false
        errors[`dateRange`] = 'Select date rage'
    }

    return { errors, formIsValid }
}
import { notify } from 'reapop'
import { searchLogsConstants } from '../constants'
import { searchLogService } from '../services'

export const searchLogsAction = {
    getSearchLogData
}

function getSearchLogData(data) {
    return dispatch => {
        dispatch(request())

        searchLogService.getSearchLogData(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: searchLogsConstants.GET_SEARCH_LOG_DATA_REQUEST } }
    function success(result) { return { type: searchLogsConstants.GET_SEARCH_LOG_DATA_SUCCESS, result } }
    function failure(error) { return { type: searchLogsConstants.GET_SEARCH_LOG_DATA_FAILURE, error } }
}

import { SupportConstants } from '../constants'

export function customerSearchData(state = {}, action) {
  switch (action.type) {
    case SupportConstants.CUSTOMER_SUPPORT_SEARCH_REQUEST:
      return { searching: true }
    case SupportConstants.CUSTOMER_SUPPORT_SEARCH_SUCCESS:
      return { data: action.result }
    case SupportConstants.CUSTOMER_SUPPORT_SEARCH_FAILURE:
      return {}
    default:
      return state
  }
}

export function verifySupportPIN(state = {}, action) {
  switch (action.type) {
    case SupportConstants.VERIFY_SUPPORT_PIN_REQUEST:
      return { ...state, verifying: true }
    case SupportConstants.VERIFY_SUPPORT_PIN_SUCCESS:
      let data = action.result;
      let res = {
        companyList: data.companies,
        supportProcessTemplate: data.support_process_template,
      }
      if (action.params) {
        res.inputParams = action.params
      }
      return res;
    case SupportConstants.VERIFY_SUPPORT_PIN_FAILURE:
      return {}
    default:
      return state
  }
}

export function getTicketDetails(state = {}, action) {
  switch (action.type) {
    case SupportConstants.GET_TICKET_DETAILS_REQUEST:
      return { ...state, loading: true }
    case SupportConstants.GET_TICKET_DETAILS_SUCCESS:
      let data = action.result;
      return { ticketsDetails: data }
    case SupportConstants.GET_TICKET_DETAILS_FAILURE:
      return {}
    default:
      return state
  }
}

export function saveTicketMessage(state = {}, action) {
  switch (action.type) {
    case SupportConstants.SAVE_TICKET_MESSAGE_REQUEST:
      return { loading: true }
    case SupportConstants.SAVE_TICKET_MESSAGE_SUCCESS:
      // let data =  action.result;
      return {}
    case SupportConstants.SAVE_TICKET_MESSAGE_FAILURE:
      return {}
    default:
      return state
  }
}

export function changeTicketStatus(state = {}, action) {
  switch (action.type) {
    case SupportConstants.CHANGE_TICKET_STATUS_REQUEST:
      return { loading: true }
    case SupportConstants.CHANGE_TICKET_STATUS_SUCCESS:
      // let data =  action.result;
      return {}
    case SupportConstants.CHANGE_TICKET_STATUS_FAILURE:
      return {}
    default:
      return state
  }
}

export function createTicket(state = {}, action) {
  switch (action.type) {
    case SupportConstants.CREATE_SUPPORT_TICKET_REQUEST:
      return { loading: true }
    case SupportConstants.CREATE_SUPPORT_TICKET_SUCCESS:
      // let data =  action.result;
      return {}
    case SupportConstants.CREATE_SUPPORT_TICKET_FAILURE:
      return {}
    default:
      return state
  }
}
export function getTicketList(state = {}, action) {
  switch (action.type) {
    case SupportConstants.TICKETS_LIST_REQUEST:
      return { loading: true }
    case SupportConstants.TICKETS_LIST_SUCCESS:
      return { data: action.result }
    case SupportConstants.TICKETS_LIST_FAILURE:
      return {}
    default:
      return state
  }
}

export const requirementLogsConstants = {
    GET_REQUIREMENT_LOG_REQUEST: 'GET_REQUIREMENT_LOG_REQUEST',
    GET_REQUIREMENT_LOG_SUCCESS: 'GET_REQUIREMENT_LOG_SUCCESS',
    GET_REQUIREMENT_LOG_FAILURE: 'GET_REQUIREMENT_LOG_FAILURE',

    GET_SELLER_DETAIL_REQUEST: 'GET_SELLER_DETAIL_REQUEST',
    GET_SELLER_DETAIL_SUCCESS: 'GET_SELLER_DETAIL_SUCCESS',
    GET_SELLER_DETAIL_FAILURE: 'GET_SELLER_DETAIL_FAILURE',

    ADD_BUYER_REQUIREMENT_INVITE_REQUEST: 'ADD_BUYER_REQUIREMENT_INVITE_REQUEST',
    ADD_BUYER_REQUIREMENT_INVITE_SUCCESS: 'ADD_BUYER_REQUIREMENT_INVITE_SUCCESS',
    ADD_BUYER_REQUIREMENT_INVITE_FAILURE: 'ADD_BUYER_REQUIREMENT_INVITE_FAILURE',

    GET_BUYER_REQUIREMENT_INVITE_REQUEST: 'GET_BUYER_REQUIREMENT_INVITE_REQUEST',
    GET_BUYER_REQUIREMENT_INVITE_SUCCESS: 'GET_BUYER_REQUIREMENT_INVITE_SUCCESS',
    GET_BUYER_REQUIREMENT_INVITE_FAILURE: 'GET_BUYER_REQUIREMENT_INVITE_FAILURE',

    GET_UNREGISTERED_SELLER_DETAIL_REQUEST: 'GET_UNREGISTERED_SELLER_DETAIL_REQUEST',
    GET_UNREGISTERED_SELLER_DETAIL_SUCCESS: 'GET_UNREGISTERED_SELLER_DETAIL_SUCCESS',
    GET_UNREGISTERED_SELLER_DETAIL_FAILURE: 'GET_UNREGISTERED_SELLER_DETAIL_FAILURE',

    UPDATE_REQUIREMMENT_LOCATION_REQUEST : 'UPDATE_REQUIREMMENT_LOCATION_REQUEST',
    UPDATE_REQUIREMMENT_LOCATION_SUCCESS : 'UPDATE_REQUIREMMENT_LOCATION_SUCCESS',
    UPDATE_REQUIREMMENT_LOCATION_FAILURE : 'UPDATE_REQUIREMMENT_LOCATION_FAILURE'
}
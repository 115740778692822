import { AppHeaderDropdown, AppSidebarToggler } from '@coreui/react'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Link, NavLink } from "react-router-dom"
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Nav, NavItem } from 'reactstrap'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { authActions, profileActions } from '../individual/actions'

import LanguageDropdownMain from '../language'

import logo_small from '../assets/img/brand/logo-small.jpg'
import logo from '../assets/img/brand/logo1.png'
// import sygnet from '../../assets/img/brand/sygnet.svg'

import { WebSocketManager } from './../views/WebSocketManager'

import { Tooltip } from 'react-tippy'
import { FormGroup, Input, Label } from 'reactstrap'
import FormHelpText from '../components/Messages/FormHelpText'
import { currencyFormat, validPassword } from '../helpers'
import ChangePasswordModal from './Models/ChangePasswordModal'


class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      changePasswordData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      errorMessage: {
        oldPasswordError: '',
        newPassowrdError: '',
        confirmPasswordError: '',
      }
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.toggle = this.toggle.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.isValidData = this.isValidData.bind(this)
    this.goToProfile = this.goToProfile.bind(this)
  }

  logout(e) {

    e.preventDefault()

    const { dispatch } = this.props
    dispatch(authActions.logout())
    this.props.history.push('/login')

  }

  toggle() {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  onChangeHandler(e) {
    const { changePasswordData } = this.state
    changePasswordData[e.target.name] = e.target.value
    this.setState({ changePasswordData })
  }

  onKeyPress(e) {
    const { errorMessage } = this.state
    if (e.target.name === 'oldPassword') {
      errorMessage['oldPasswordError'] = ''
    }
    if (e.target.name === 'newPassword') {
      errorMessage['newPassowrdError'] = ''
    }
    if (e.target.name === 'confirmPassword') {
      errorMessage['confirmPasswordError'] = ''
    }
    this.setState({ errorMessage })
  }

  getHeaderData() {
    const { dispatch } = this.props;

    dispatch(profileActions.getHeaderDataCount())
  }

  isValidData() {
    const { changePasswordData, errorMessage } = this.state
    let error = false;
    if (!changePasswordData.oldPassword) {
      errorMessage['oldPasswordError'] = 'Enter the current password'
      error = true
    }
    if (!changePasswordData.newPassword) {
      errorMessage['newPassowrdError'] = 'Enter the new password'
      error = true
    }
    else if (!validPassword(changePasswordData.newPassword)) {
      errorMessage['newPassowrdError'] = 'Password does not match specified format.'
      error = true
    }
    if (!changePasswordData.confirmPassword) {
      errorMessage['confirmPasswordError'] = 'Enter the confirm password'
      error = true
    }
    if (changePasswordData.newPassword !== changePasswordData.confirmPassword) {
      errorMessage['confirmPasswordError'] = 'Confirm password not match'
      error = true
    }
    this.setState({ errorMessage })
    return !error
  }
  componentDidMount() {

    this.getHeaderData()
    setInterval(() => {
      this.getHeaderData()
    }, 30000);
  }
  onSave() {
    const { dispatch } = this.props
    const { changePasswordData } = this.state
    if (this.isValidData()) {
      let dataToSend = {
        old_password: changePasswordData.oldPassword,
        password: changePasswordData.newPassword,
        password_confirmation: changePasswordData.confirmPassword
      }
      dispatch(authActions.changePassword(dataToSend, this))
    }
  }

  afterSave() {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  goToProfile() {
    this.props.history.push('/profile')
  }
  render() {

    // eslint-disable-next-line
    const { auth, children, linked_businesses, monthly_target, paid_commission, pending_commission, business_target, loading, ...attributes } = this.props
    const { showModal, errorMessage } = this.state

    const userLinks = (
      <React.Fragment>
        {
          showModal && <ChangePasswordModal
            header={<div>Change Password</div>}
            content={
              <div>
                <FormGroup>
                  <Label>Current Password</Label>
                  <Input type="password" name="oldPassword" placeholder="Enter Current Password" onChange={this.onChangeHandler} onKeyPress={this.onKeyPress} />
                  <FormFeedback>{errorMessage.oldPasswordError}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>New Password</Label>
                  <Input type="password" name="newPassword" placeholder="Enter New Password" onChange={this.onChangeHandler} onKeyPress={this.onKeyPress} />
                  <FormFeedback>{errorMessage.newPassowrdError}</FormFeedback>
                  <FormHelpText message={"New Password must include : 8-20 Characters, at least one capital latter,  one number, one special character, no spaces."} />
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input type="password" name="confirmPassword" placeholder="Enter Confirm Password" onChange={this.onChangeHandler} onKeyPress={this.onKeyPress} />
                  <FormFeedback>{errorMessage.confirmPasswordError}</FormFeedback>
                </FormGroup>
              </div>
            }
            onClose={this.toggle}
            onSave={this.onSave} />
        }

        <Nav className="ml-auto" navbar>
          {/* <NavItem>
            <b>500 / 100</b>
          </NavItem>
          <NavItem>
            <Notification />
          </NavItem> */}

          <NavItem className=" d-none d-md-block">
            <NavLink
              to="/business-account"
              className="nav-link "
            >
              <div
                className="d-none d-lg-block ml-3 mr-1  text-dark  border border-success"
                style={{ borderRadius: "50px", padding: "5px 10px " }}
              >
                <Tooltip className="btn m-0 p-0" interactive arrow={true} title={'Linked Business'}>
                  <b style={{ fontSize: 12 }}><i className="fas fa-link"></i> <i className="fas fa-bold"></i></b>
                  &nbsp;
                   <React.Fragment>
                    <Badge className="badge-text mr-0" style={{ fontSize: 10 }} color={"success"}>
                      {linked_businesses ? linked_businesses : 0}
                    </Badge>
                  </React.Fragment>
                </Tooltip>
              </div>
            </NavLink>
          </NavItem>



          <NavItem  className=" d-none d-md-block">
            <NavLink
              to="/business-account"
              className="nav-link "
            >
              <div
                className="d-none d-lg-block ml-3 mr-1  text-dark  border border-danger"
                style={{ borderRadius: "50px", padding: "5px 10px " }}
              >
                <Tooltip className="btn m-0 p-0" interactive arrow={true} title={'Monthly Business Target'}>
                  <b style={{ fontSize: 12 }}><i className="fas fa-bullseye"></i> <i className="fas fa-bold"></i></b>
                  &nbsp;

 <React.Fragment>
                    <Badge className="badge-text mr-0" style={{ fontSize: 10 }} color={"danger"}>
                      { business_target ? business_target : 0}

                    </Badge>

                  </React.Fragment>
                </Tooltip>
              </div>
            </NavLink>
          </NavItem>

          <NavItem  className=" d-none d-md-block">
            <NavLink
              to="/payments"
              className="nav-link  d-none d-md-block"
            >
              <div
                className="d-none d-lg-block ml-3 mr-1  text-dark  border border-success"
                style={{ borderRadius: "50px", padding: "5px 10px " }}
              >
                <Tooltip className="btn m-0 p-0" interactive arrow={true} title={'Paid Commission'}>
                  <b style={{ fontSize: 12 }}><i className="fas fa-play"></i> <i className="fas fa-inr"></i></b>
                  &nbsp;

<React.Fragment>
                    <Badge className="badge-text mr-0" style={{ fontSize: 10 }} color={"success"}>
                      {currencyFormat(paid_commission ? paid_commission : 0)}

                    </Badge>
                  </React.Fragment>
                </Tooltip>
              </div>
            </NavLink>
          </NavItem>


          <NavItem  className=" d-none d-md-block">
            <NavLink
              to="/payments"
              className="nav-link  d-none d-md-block"
            >
              <div
                className="d-none d-lg-block ml-3 mr-1  text-dark  border border-info"
                style={{ borderRadius: "50px", padding: "5px 10px " }}
              >
                <Tooltip className="btn m-0 p-0" interactive arrow={true} title={'Pending Commission'}>
                  <b style={{ fontSize: 12 }}><i className="fas fa-pause"></i> <i className="fas fa-inr"></i></b>
                  &nbsp;

 <React.Fragment>
                    <Badge className="badge-text mr-0" style={{ fontSize: 10 }} color={"info"}>
                    {currencyFormat(pending_commission ? pending_commission : 0)}

                    </Badge>
                  </React.Fragment>
                </Tooltip>
              </div>
            </NavLink>
          </NavItem>

          

          <NavItem  className=" d-none d-md-block">
            <NavLink
              to="/payments"
              className="nav-link  d-none d-md-block"
            >
              <div
                className="d-none d-lg-block ml-3 mr-1  text-dark  border border-danger"
                style={{ borderRadius: "50px", padding: "5px 10px " }}
              >
                <Tooltip className="btn m-0 p-0" interactive arrow={true} title={'Monthly Commission Target'}>
                  <b style={{ fontSize: 12 }}><i className="fas fa-bullseye"></i> <i className="fas fa-inr"></i></b>
                  &nbsp;
                  <React.Fragment>
                    <Badge className="badge-text mr-0" style={{ fontSize: 10 }} color={"danger"}>
                      {currencyFormat( monthly_target ? monthly_target : 0)}

                    </Badge>

                  </React.Fragment>
                </Tooltip>
              </div>
            </NavLink>
          </NavItem>


          &nbsp;
          &nbsp;

          {auth && auth.loggedIn ?
            <React.Fragment>
              {/* <NavItem> <b>Demo Access Code:</b> {auth.user.unique_id } </NavItem>&nbsp;&nbsp; */}
              <NavItem onClick={this.goToProfile} style={{ cursor: 'pointer' }}> <b> {auth.user.name} </b></NavItem>
            </React.Fragment> : ''}

          <LanguageDropdownMain />



          <AppHeaderDropdown className="" direction="down">
            <DropdownToggle color="white" size="sm" >
              <div>
                <i className=" fa fa-user"></i>
                &nbsp;
                &nbsp;
                <i className="fa fa-bars"></i>
              </div>
            </DropdownToggle>

            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem onClick={this.goToProfile}> <i className="fa fa-user"></i> Profile </DropdownItem>
              <DropdownItem onClick={this.toggle}> <i className="fa fa-key"></i> Change Password </DropdownItem>
              <DropdownItem onClick={this.logout.bind(this)}> <i className="fa fa-lock"></i> Logout </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>

          &nbsp;  &nbsp;

        </Nav>
      </React.Fragment>
    )

    const guestLinks = (
      <Nav className="ml-auto" navbar>
        <NavItem className="px-4 d-none d-sm-block">
          <NavLink to="/login" className="nav-link  text-primary"><i className="icon-login"></i> &nbsp;Login </NavLink>
        </NavItem>

        <NavItem className="px-4 d-none d-sm-block">
          {/* <NavLink to="/individual/register" className="nav-link  text-primary"> <i className="icon-user-follow"></i>&nbsp  Register </NavLink> */}
        </NavItem>
        <LanguageDropdownMain />
      </Nav>
    )

    return (
      <React.Fragment>
        {/* <div className='container d-flex align-items-center '> */}

        <WebSocketManager />

        <AppSidebarToggler className="d-lg-none" display="sm" mobile />

        <Link to="/" className="navbar-brand">
          <img src={logo} height="40" alt="ReputeInfo" className=" d-none d-md-block mr-auto"></img>
          <img src={logo_small} height="40" alt="ReputeInfo" className="navbar-brand-full d-block d-md-none mr-auto"></img>
          <img src={logo_small} height="40" alt="ReputeInfo" className="navbar-brand-minimized"></img>
        </Link>

        {auth.loggedIn ? userLinks : guestLinks}
        {/* </div> */}
      </React.Fragment >
    )
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  // logout : PropTypes.func.isRequired
}


function mapStateToProps(state) {
  const { linked_businesses, monthly_target, paid_commission, pending_commission,business_target, loading } = state.getHeaderData;

  return {
    auth: state.authentication,
    linked_businesses, monthly_target, paid_commission, pending_commission, business_target, loading,
    socket: state.websocket
  }
}

const connectedHeader = withRouter(connect(mapStateToProps)(Header))
export { connectedHeader as Header }


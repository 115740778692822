export const reputeinfoBalancePackageConstants = {

    GET_REPUTEINFOBALANCE_REQUEST: 'GET_REPUTEINFOBALANCE_REQUEST',
    GET_REPUTEINFOBALANCE_SUCCESS: 'GET_REPUTEINFOBALANCE_SUCCESS',
    GET_REPUTEINFOBALANCE_FAILURE: 'GET_REPUTEINFOBALANCE_FAILURE',

    EDIT_REPUTEINFOBALANCE_REQUEST: 'EDIT_REPUTEINFOBALANCE_REQUEST',
    EDIT_REPUTEINFOBALANCE_SUCCESS: 'EDIT_REPUTEINFOBALANCE_SUCCESS',
    EDIT_REPUTEINFOBALANCE_FAILURE: 'EDIT_REPUTEINFOBALANCE_FAILURE',

    GET_REPUTEINFOBALANCE_SETTING_REQUEST: 'GET_REPUTEINFOBALANCE_SETTING_REQUEST',
    GET_REPUTEINFOBALANCE_SETTING_SUCCESS: 'GET_REPUTEINFOBALANCE_SETTING_SUCCESS',
    GET_REPUTEINFOBALANCE_SETTING_FAILURE: 'GET_REPUTEINFOBALANCE_SETTING_FAILURE',

    EDIT_REPUTEINFOBALANCE_SETTING_REQUEST: 'EDIT_REPUTEINFOBALANCE_SETTING_REQUEST',
    EDIT_REPUTEINFOBALANCE_SETTING_SUCCESS: 'EDIT_REPUTEINFOBALANCE_SETTING_SUCCESS',
    EDIT_REPUTEINFOBALANCE_SETTING_FAILURE: 'EDIT_REPUTEINFOBALANCE_SETTING_FAILURE',
}

import { notify } from 'reapop'
import { VideosConstants } from '../constants'
import { VideoService } from '../services'

export const VideoLogsAction = {
    getVideoLogData
}

function getVideoLogData(data) {
    return dispatch => {
        dispatch(request())

        VideoService.getVideoLogsData(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: VideosConstants.GET_VIDEOS_REQUEST } }
    function success(result) { return { type: VideosConstants.GET_VIDEOS_SUCCESS, result } }
    function failure(error) { return { type: VideosConstants.GET_VIDEOS_FAILURE, error } }
}

import { notify } from 'reapop'
import { UserLoginLogsConstants } from '../constants'
import { UserLoginLogService } from '../services'

export const UserLoginLogsActions = {
    getUserLoginLogs
}

function getUserLoginLogs(data) {
    return dispatch => {
        dispatch(request())

        UserLoginLogService.getUserLoginLogs(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: UserLoginLogsConstants.GET_USERLOGINLOGS_REQUEST } }
    function success(result) { return { type: UserLoginLogsConstants.GET_USERLOGINLOGS_SUCCESS, result } }
    function failure(error) { return { type: UserLoginLogsConstants.GET_USERLOGINLOGS_FAILURE, error } }
}


import { dailyMisConstants } from "../constants"


export function getDailyMis(state = {}, action) {
    switch (action.type) {
        case dailyMisConstants.GET_DAILY_MIS_REQUEST:
            return { loading: true }
        case dailyMisConstants.GET_DAILY_MIS_SUCCESS:
            return action.result
        case dailyMisConstants.GET_DAILY_MIS_FAIL:
            return {}
        default:
            return state
    }
}
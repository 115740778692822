import React, { Component } from "react";
import swal from "sweetalert";
import { accountAction } from "../../actions/account.action";

import { Tooltip } from 'react-tippy';

import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { BalancePackages } from './BalancePackages';
export default class ChequePayment extends Component {
  constructor(props) {
    super(props);
    let days = new Date().getDate() - 1;
    this.state = {
      modalOpan: false,
      data: {
        sms: "",
        days: "",
        account_holder_name: "",
        bank_name: "",
        cheque_number: "",
        account_number: "",
        cheque_amount: "",
        gst_percentage: '',
        amount: 0,
        igst: 0,
        cgst: 0,
        utgst: 0,
        amount: 0,
        balance : 0
      },
      errors: {},
      packageSelected: 0,
      isLocal: false
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.proceed = this.proceed.bind(this);
    this.isValid = this.isValid.bind(this);
    this.reset = this.reset.bind(this);
    this.calculateGST = this.calculateGST.bind(this)
    this.handleChangePackage = this.handleChangePackage.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan },() => {
      if( modalOpan === false) {
        this.reset()
        this.calculateGST()
      }
    });
  }
  reset() {
    const { data, errors } = this.state
    data.sms = ""
    data.days = ""
    data.account_holder_name = ""
    data.bank_name = ""
    data.cheque_number = ""
    data.account_number = ""
    data.cheque_amount = ""
    data.gst_percentage = 0
    data.igst = 0
    data.cgst = 0
    data.utgst = 0
    data.amount = 0

    this.setState({ data, errors: {} })
  }
  handleChangePackage(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    },() => { this.calculateGST() });

  }
  handleChange(event) {
    const { name, value } = event.target;
    let { data } = this.state;
    let val = value;

    let regex_allowed = /^[0-9]+$/;
    if (name === "sms" || name === "days") {
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    if (name === "bank_name" || name === "company_name" || name === "account_holder_name") {
      let regex_allowed = /^(?![\s-])[a-zA-Z ]*$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    if (name === "account_number" || name === "cheque_number") {
      let regex_allowed = /^[0-9]+$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    // Cheque Amount
    if (name === "cheque_amount") {
      let regex_allowed = /^\d+(\.\d{0,2})?$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    data[name] = value;
    this.setState({ data }, () => this.calculateGST())
  }

  calculateGST() {
    let { data , packageSelected} = this.state;
    const { balancePackages } = this.props
    balancePackages && balancePackages.balance_charges && balancePackages.balance_charges.length &&
    balancePackages.balance_charges.map((each) => {
      if(each.package_id == packageSelected) {
        data.cheque_amount = each.total_amount
        data.igst = each.igst
        data.cgst = each.cgst
        data.utgst= each.utgst
        data.amount = each.amount
        data.balance = each.balance
        this.setState({ data })
      }  
      return false;
    })
    // if(data.cheque_amount !="" ) {
    //     let igst = parseFloat(data.cheque_amount) * 0.18   ;
    //     let cgst = igst / 2;
    //     let utgst = cgst;
    //     let amount =  parseFloat(data.cheque_amount) -  parseFloat(igst) 
    //     // data.total_amount = currencyFormat(data.total_amount);
    //     data.igst = igst;
    //     data.cgst = cgst;
    //     data.utgst =  utgst ;
    //     data.amount = amount
    // } 
    


  }

  isValid() {
    const { data, errors } = this.state;
    let error = false;

    // if (!data.sms) {
    //   error = true
    //   errors.sms = "this field is required"
    // }
    // if (!data.days) {
    //   error = true
    //   errors.days = "this field is required"
    // }
    if (!data.account_holder_name) {
      error = true
      errors.account_holder_name = "this field is required"
    }
    if (!data.bank_name) {
      error = true
      errors.bank_name = "this field is required"
    }
    if (!data.cheque_number) {
      error = true
      errors.cheque_number = "this field is required"
    }
    if (!data.account_number) {
      error = true
      errors.account_number = "this field is required"
    }
    if (!data.cheque_amount) {
      error = true
      errors.cheque_amount = "this field is required"
    }

    this.setState({ errors })
    return error
  }
  proceed() {
    const { data } = this.state;
    const { dispatch, element, getData } = this.props;
    if (!this.isValid()) {
      swal({
        title: "Are you sure ?",
        text:  'Do you want to generate invoice ?',
        icon: "warning",
        buttons: true,
        dangerMode: true,
        buttons: ["Cancel", "Yes, I am sure!"],
      }).then((confirm) => {
        if (confirm) {
          let dataTosend = {
            repute_id: element.repute_id,
            //sms: data.sms,
            //days: data.days,
            //type: element.current_plan === "Premium" ? 'renew' : 'upgrade',
            account_holder_name : data.account_holder_name,
            bank_name : data.bank_name,
            cheque_number : data.cheque_number,
            account_number : data.account_number,
            cheque_amount : data.cheque_amount,
            igst : data.igst,
            cgst : data.cgst,
            utgst : data.utgst,
            amount : data.amount,
            balance : data.balance
          }
          console.log('dataTosend',dataTosend)
          dispatch(
            accountAction.proceedChequePayment(dataTosend, {
              success: () => {
                this.showModal()
                getData()
              }
            })
          );

        }
      });
    }
  }

  componentDidMount() {
    let { isLocal , packageSelected, data} = this.state
    const {  element ,balancePackages} = this.props;
    packageSelected =  balancePackages && balancePackages.balance_charges[0].package_id
    
    this.setState({
        isLocal : element.is_local,
        packageSelected
      },() => { this.calculateGST() })
}
  render() {
    const { modalOpan, data, errors } = this.state;
    const { element } = this.props;
    let _this = this;
    return (
      <div>
        <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Cheque Payment">

          <Button onClick={_this.showModal} color="danger">
            <i className="fas fa-money-check"></i>
          </Button>
        </Tooltip>
        <Modal size="lg" className="modal-danger" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Cheque Payment<br />{" "}
            <small> Recharge Balance With Chaque Payment</small></ModalHeader>
          <ModalBody>
            <Row>
              <Col md="5">
                <Row>
                  <Col md="5" className=" mb-2">
                    Name of company :
                </Col>
                  <Col md="7" className="mb-1">{element.company_name}</Col>

                  <Col md="5" className="mb-4">
                    Repute ID :
                </Col>
                  <Col md="7" className="mb-1">{element.formated_repute_id}</Col>

                  {/* <Col md="5" className="text-right  mb-1">
                    Subscription Plan :
                </Col>
                  <Col md="7" className="mb-1">{element.current_plan}</Col>
                  <Col md="5" className="text-right  mb-4">
                  Current Expiry Date :
                </Col>
                  <Col md="7" className="mb-1">{element.formated_expiry_date}</Col> */}
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  {/* <Col md="6" className="text-right mb-1">
                    Plan Start Date :
                </Col>
                  <Col md="6">{element.formated_plan_start_date}</Col>


                  <Col md="6" className="text-right mb-1 pt-2">
                    Add Days :
                </Col>
                  <Col md="6">
                    <Input
                      maxlength={3}
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Days"
                      name={"days"}
                      value={data.days}
                    />
                    {errors.days && (
                      <FormFeedback>
                        {errors.days}
                      </FormFeedback>
                    )}

                  </Col>


                  <Col md="6" className="text-right  mb-4">
                    New Expiry Date :
                </Col>
                  <Col md="6" className="mb-1">{moment(element.expiry_date).add(data.days, 'days').format('DD MMM YYYY hh:mm A')}</Col> */}

                </Row>

              </Col>
              <Col md="4">
                <Row>
                  {/* <Col md="7" className="text-right mb-1">
                    SMS Qty Remaining :
                </Col>
                  <Col md="5" className="mb-1">{element.total_sms}</Col>

                  <Col md="7" className="text-right mb-1 pt-2">
                    Add SMS :
                </Col>
                  <Col md="5" className="mb-1"><Input
                    maxlength={4}
                    type="text"
                    onChange={this.handleChange}
                    name={"sms"}
                    value={data.sms}
                    placeholder="SMS"
                  />
                    {errors.sms && (
                      <FormFeedback>
                        {errors.sms}
                      </FormFeedback>
                    )}
                  </Col>

                  <Col md="7" className="text-right">
                    Total SMS  :
                </Col>
                  <Col md="5" >{data.sms ? parseInt(element.total_sms) + parseInt(data.sms) : element.total_sms}</Col> */}
                </Row>
              </Col>
            </Row>
            <Label><strong>Cheque Details</strong></Label>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Account Holder Name </Label>
                  <Input
                    name="account_holder_name"
                    maxLength="30"
                    value={data.account_holder_name}
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    placeholder="Account Holder Name"
                  />
                  {errors.account_holder_name && (
                    <FormFeedback>
                      {errors.account_holder_name}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Bank Name</Label>
                  <Input
                    name="bank_name"
                    maxLength="30"
                    value={data.bank_name}
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    placeholder="Bank Name"
                  />
                  {errors.bank_name && (
                    <FormFeedback>
                      {errors.bank_name}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Cheque Number</Label>
                  <Input
                    name="cheque_number"
                    maxLength="6"
                    value={data.cheque_number}
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    placeholder="Cheque Number"
                  />
                  {errors.cheque_number && (
                    <FormFeedback>
                      {errors.cheque_number}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Account Number</Label>
                  <Input
                    name="account_number"
                    value={data.account_number}
                    maxLength="20"
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    placeholder="Account Number"
                  />
                  {errors.account_number && (
                    <FormFeedback>
                      {errors.account_number}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            
            {/* <table className="table">
              <thead>
                <tr>
                  <th>Amount<br /><small> (Excluding GST) </small> </th>
                  <th 
                   style={{
                    display: element.is_local ? "none" : "",
                  }}
                  >IGST (18%)</th>
                  <th 
                  style={{
                    display: element.is_local ? "" : "none",
                  }}
                  > CGST (9%)</th>
                  <th 
                  style={{
                    display: element.is_local ? "" : "none",
                  }}
                  >UTGST (9%)</th>
                  <th>Cheque amount<br /><small> (Including GST) </small> </th>
                </tr>
                <tr>
                  <td>
                  <Input
                    name="amount"
                    value={currencyFormat(data.amount)}
                    maxLength="17"
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    disabled
                  />
                  </td>
                  <td
                  style={{
                    display: element.is_local ? "none" : "",
                  }}
                  >
                    <Input
                      name="igst"
                      value={currencyFormat(data.igst)}
                      maxLength="17"
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </td>
                  <td
                  style={{
                    display: element.is_local ? "" : "none",
                  }}
                  >
                    <Input
                      name="cgst"
                      value={currencyFormat(data.cgst)}
                      maxLength="17"
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </td>
                  <td
                  style={{
                    display: element.is_local ? "" : "none",
                  }}
                  >
                    <Input
                      name="utgst"
                      value={currencyFormat(data.utgst)}
                      maxLength="17"
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                  <Input
                    name="cheque_amount"
                    value={data.cheque_amount}
                    maxLength="17"
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    placeholder="Cheque Amount"
                  />
                  {errors.cheque_amount && (
                    <FormFeedback>
                      {errors.cheque_amount}
                    </FormFeedback>
                  )}
                  </td>
                </tr>
              </thead>
            </table> */}
            <BalancePackages packages={this.props.balancePackages} packageSelected={this.state.packageSelected} handleChange={this.handleChangePackage}  />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
            Generate Invoice
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import { businessAccountTemplateConstants } from "../constants/businessAccountTemplate.constants";

export function businessAccountTemplate(state = {}, action) {
  switch (action.type) {
    case businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_REQUEST:
      return { loading: true };
    case businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_SUCCESS:
        let data = {}
        if(action.result && action.result.hasOwnProperty('business_account_template_json') ){
            data = JSON.parse(action.result.business_account_template_json);
        
        } 
      return data;
    case businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

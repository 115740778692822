export const adminContentConfigConstants = {
    GET_ADMIN_CONTENT_CONFIG_REQUEST: 'GET_ADMIN_CONTENT_CONFIG_REQUEST',
    GET_ADMIN_CONTENT_CONFIG_SUCCESS: 'GET_ADMIN_CONTENT_CONFIG_SUCCESS',
    GET_ADMIN_CONTENT_CONFIG_FAILURE: 'GET_ADMIN_CONTENT_CONFIG_FAILURE',

    UPDATE_ADMIN_CONTENT_CONFIG_REQUEST: 'UPDATE_ADMIN_CONTENT_CONFIG_REQUEST',
    UPDATE_ADMIN_CONTENT_CONFIG_SUCCESS: 'UPDATE_ADMIN_CONTENT_CONFIG_SUCCESS',
    UPDATE_ADMIN_CONTENT_CONFIG_FAILURE: 'UPDATE_ADMIN_CONTENT_CONFIG_FAILURE',

    SETTING_ONE : 'SETTING_ONE',
    SETTING_TWO : 'SETTING_TWO',
    SETTING_THREE : 'SETTING_THREE'
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { TableLoader } from "../../../components/Loaders";
import moment from "moment";
import { currencyFormat } from '../../../helpers';
class ViewQuotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false
        }
        this.handleToggle = this.handleToggle.bind(this);
    }
    handleToggle() {
        let { thisModel } = this.state;
        thisModel = !thisModel
        this.setState({
            thisModel: thisModel,
        })
    }
    render() {
        const { loading, requirements, activeTab } = this.props
        const { thisModel } = this.state

        return (
            <React.Fragment>
                <Button size="sm" color="primary" onClick={this.handleToggle}>View Quotes</Button>
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="lg" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> Quotes Detail  </ModalHeader>
                    <ModalBody style={{ minHeight: '500px' }}>
                        {loading ? <TableLoader /> :
                            (
                                <div className="table-responsive-sm animated fadeIn">
                                    {
                                        requirements ?
                                            //requirements.map(element => {

                                            //     return (
                                            <Card outline color="primary">
                                                <CardHeader>
                                                    <div >
                                                        <Row>
                                                            <Col md="12" className="h6"><strong>Requirement ID :</strong> {requirements.id}</Col>
                                                            <Col md="3"><strong>Start Date :</strong> {moment(requirements.start_date).format('DD-MM-YYYY')}</Col>
                                                            <Col md="3"><strong>End Date :</strong> {moment(requirements.end_date).format('DD-MM-YYYY')}</Col>
                                                            <Col md="3"><strong>Location :</strong> {requirements.delivery_location}</Col>
                                                            <Col md="3"><strong>Delivered by seller :</strong> {requirements.is_delivered_seller ? 'Yes' : 'No'}</Col>
                                                        </Row>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="p-2">
                                                    {
                                                        requirements.quote_for_requirement && requirements.quote_for_requirement.length ?
                                                            <table className="table mb-3 table-sm table-condensed table-striped table-bordered table-outline">
                                                                <thead className='thead-light'>
                                                                    <tr>
                                                                        <th>Quote ID</th>
                                                                        <th>Company Name</th>
                                                                        <th>Owner Name</th>
                                                                        <th>Contact Number</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        requirements.quote_for_requirement && requirements.quote_for_requirement.length ?
                                                                            requirements.quote_for_requirement.map((eachRequirement) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>C{eachRequirement.id}</td>
                                                                                        <td>{eachRequirement.company_name}</td>
                                                                                        <td>{eachRequirement.individual_name}</td>
                                                                                        <td>{eachRequirement.contact_number}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : false
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            : false
                                                    }

                                                    {
                                                        requirements.buyer_requiryment_product_tag.map((eachTag) => {

                                                            {
                                                                eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                    let total, rate, gst, rate_total = 0;

                                                                    rate = parseFloat(eachitem.unit_rate);
                                                                    gst = parseFloat(eachitem.gst_percentage);
                                                                    let gstAmt = rate * gst / 100;
                                                                    rate_total = rate + gstAmt;
                                                                    total = (rate_total * parseFloat(eachTag.quantity));
                                                                    eachTag.sort_quote_for_requirement_product_wise[index].total_unit_rate = rate_total;
                                                                    eachTag.sort_quote_for_requirement_product_wise[index].gst_amount = gstAmt;
                                                                    eachTag.sort_quote_for_requirement_product_wise[index].total = total;
                                                                })
                                                            }

                                                            return (
                                                                <React.Fragment>

                                                                    <table className='table p-0  m-0 table-sm table-condensed table-striped table-bordered table-outline'>
                                                                        <thead className='thead-light'>
                                                                            <tr>
                                                                                <td className='br1'> <strong> Product Name : </strong> {eachTag.product_name} </td>
                                                                                <th> </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <th className="text-center" > L{eachitem.lower_price_ranking} </th> }
                                                                                })}
                                                                                {eachTag.sort_quote_for_requirement_product_wise.length == 0 &&
                                                                                    <th className="text-center"></th>
                                                                                }
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="20%" className='br1' > <strong>Quantity : </strong> {parseFloat(eachTag.quantity).toFixed()}</td>

                                                                                <th width="10%" > Quote ID  </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <td className="text-center" > C{eachitem.quote_for_requirement_id} </td> }
                                                                                })}

                                                                                {eachTag.sort_quote_for_requirement_product_wise.length == 0 &&
                                                                                    <td rowSpan="5" className="text-center">No quotes available</td>
                                                                                }
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='br1'> <strong>Unit : </strong>{eachTag.unit}</td>
                                                                                <th > Rate </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <td className="text-center" > {parseFloat(eachitem.unit_rate)} </td> }
                                                                                })}
                                                                            </tr>
                                                                            <tr>
                                                                                <td rowSpan="4" style={{ verticalAlign: "top" }} className='br1' >
                                                                                    <strong>Description : </strong> {eachTag.description} </td>
                                                                                <th > GST % </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <td className="text-center" > {parseFloat(eachitem.gst_percentage)} </td> }
                                                                                })}
                                                                            </tr>

                                                                            <tr>
                                                                                <th > Unit rate </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <td className="text-center" > {currencyFormat(eachitem.total_unit_rate)} </td> }
                                                                                })}
                                                                            </tr>
                                                                            <tr>
                                                                                <th > Total </th>
                                                                                {eachTag.sort_quote_for_requirement_product_wise.map((eachitem, index) => {
                                                                                    if (index < 5) { return <td className="text-center" >  {currencyFormat(eachitem.total)} </td> }
                                                                                })}
                                                                            </tr>

                                                                            {/* <tr>
                                                                            <th > Actions </th>
                                                                            {eachTag.sort_quote_for_requirement_product_wise.map((eachitem) => {
                                                                                return <td className="text-center" > -- Action -- </td>
                                                                            })}
                                                                        </tr> */}
                                                                        </thead>
                                                                        <tbody >


                                                                        </tbody>
                                                                    </table>

                                                                    {/* </CardBody>
                                                                    </Collapse>
                                                                </Card> */}
                                                                    {/* 
                                                                    </Col>
                                                                </Row> */}
                                                                </React.Fragment>
                                                            )
                                                        })}



                                                </CardBody>
                                            </Card>
                                            //     )
                                            // })
                                            : 'No Requirements Available'
                                    }
                                </div>
                            )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleToggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const ViewQuotesConnected = connect(mapStateToProps)(ViewQuotes);
export { ViewQuotesConnected as ViewQuotes }
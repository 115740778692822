import { notify } from 'reapop'
import { UserActivityConstants } from '../constants'
import { userActivityService } from '../services'

export const UserActivity = {
    getUserActivity,
    getUserActivityDashboard
}

function getUserActivity(state, data ) {
    return dispatch => {
        dispatch(request())

        userActivityService.getUserActivity(data)
            .then(
                result => {
                    dispatch(success(result)) 
                     state(result)
                    },
               
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: UserActivityConstants.GET_USER_ACTIVITY_REQUEST } }
    function success(result) { return { type: UserActivityConstants.GET_USER_ACTIVITY_SUCCESS, result } }
    function failure(error) { return { type: UserActivityConstants.GET_USER_ACTIVITY_FAILURE, error } }
}

function getUserActivityDashboard(data) {
    return dispatch => {
        dispatch(request())

        userActivityService.getUserActivityDashboard(data)
        .then(
            verified => dispatch(success(verified)),
            error => {
                dispatch(failure(error.toString()));
                dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
            }
        )
    }

    function request() { return { type: UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_REQUEST } }
    function success(result) { return { type: UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_SUCCESS, result } }
    function failure(error) { return { type: UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_FAILURE, error } }
}




import { contactusDetailConstants } from "../constants"


export function getContactusDetail(state = {}, action) {
    switch (action.type) {
        case contactusDetailConstants.GET_CONTACTUS_DETAIL_REQUEST:
            return { loading: true }
        case contactusDetailConstants.GET_CONTACTUS_DETAIL_SUCCESS:
            return { data: action.result }
        case contactusDetailConstants.GET_CONTACTUS_DETAIL_FAILURE:
            return {}
        default:
            return state
    }
}
import { validPhone, validReputeIdIndividual, validEmail } from "../../../../helpers";
export const handleCustomerNameValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`first_name`]) {
        formIsValid = false
        errors[`first_name`] = 'Please enter first name.'
    }
    return { errors, formIsValid }
}
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "./global.json";
import LanguageDropdown from './LanguageDropdown.jsx';

class LanguageDropdownMain extends React.Component {
  constructor(props) {
    super(props);
    const missingTranslationMsg = 'Whoops! Missing translation!';
    this.props.initialize({
      languages: [
        { name: "ENG", code: "en", icon:"flag-icon flag-icon-us" },
        { name: "HIN", code: "hi", icon:"flag-icon flag-icon-in" },
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup, missingTranslationMsg }
    });
  }

  render() {
    // render Main layout component
    return <LanguageDropdown/>;
  }
}

export default withLocalize(LanguageDropdownMain);
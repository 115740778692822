import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Collapse,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import { TableLoader } from "../../../components/Loaders";
import { communicationTemplateActions } from "../../actions";
import { DATA_TO_REPLACE_FOR_LIVE_PREVIEW } from "../../constants";
import { handleValidation } from "./Validation";

class AddCommunicationTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      addCommunicationTemplate: {
        id: "",
        title: "",
        email_subject: "",
        sms_text: "",
        type: "",
        sms_flow_id: "",
        notification_title: "",
        notification_text: "",
      },
      errors: {},
      isChange: false,
    };
    this.getCommunicationTemplate = this.getCommunicationTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmitSMS = this.onSubmitSMS.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
  }

  componentDidMount() {
    this.getCommunicationTemplate();
  }

  getCommunicationTemplate() {
    const { dispatch } = this.props;
    const values = this.props.match.params.id;
    let dataToSend = {
      id: values,
    };
    dispatch(communicationTemplateActions.getCommunicationTemplate(dataToSend));
  }
  componentWillReceiveProps(props) {
    const { data } = props;

    if (data.length) {
      this.setState({ addCommunicationTemplate: data[0] });
    }
  }
  handleChange(e) {
    e.preventDefault();
    const { addCommunicationTemplate } = this.state;
    addCommunicationTemplate[e.target.name] = e.target.value;
    this.setState({ addCommunicationTemplate });
  }

  onSubmitSMS() {
    console.log("onSubmitSMS");
    const { addCommunicationTemplate } = this.state;

    console.log("addCommunicationTemplate ", addCommunicationTemplate);
    const result = handleValidation(addCommunicationTemplate);
    this.setState({ errors: result.errors });
    if (result.formIsValid) {
      const { dispatch } = this.props;
      this.setState({ saving: true });
      dispatch(
        communicationTemplateActions.editCommunicationTemplate(
          addCommunicationTemplate,
          this
        )
      );
    }
  }
  afterSave() {
    this.props.history.goBack();
  }
  toggleChange(e) {
    e.preventDefault();
    this.setState({
      isChange: true,
    });
  }

  renderLivePreview(text) {
    for (let key in DATA_TO_REPLACE_FOR_LIVE_PREVIEW) {
      text = text.replace(key, DATA_TO_REPLACE_FOR_LIVE_PREVIEW[key]);
    }
    return text;
  }

  render() {
    const { errors, addCommunicationTemplate, saving, isChange } = this.state;
    const { loading } = this.props;
    // console.log(data)
    return (
      <div className="">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mb-4 mt-4"> Update Template </h3>

            {loading ? (
              <TableLoader />
            ) : (
              <Form
                autocomplete="off"
              >
                   <FormText style={isChange ? { display: "none" } : {}}>
                            {" "}
                            <i>
                              {" "}
                              <h5>
                              <b className="text-info">
                                {" "}
                                {addCommunicationTemplate.type}{" "}
                              </b>{" "}
                              </h5>
                            </i>{" "}
                            <a
                              className="text-danger  font-weight-bold cursor-pointer"
                              onClick={this.toggleChange}
                            >
                              {" "}
                              Change{" "}
                            </a>
                          </FormText>

                          <Collapse isOpen={isChange}>
                          <FormGroup>
                            <label className="font-weight-bold">Type</label>
                            <Input
                              placeholder="Type"
                              type="text"
                              rows="5"
                              name="type"
                              value={addCommunicationTemplate.type}
                              onChange={this.handleChange}
                            />
                            <FormFeedback>
                              {errors[`type`] && errors[`type`]}
                            </FormFeedback>
                          </FormGroup>
                        </Collapse>
                <div className="row  ">
                  <div className="col-md-6 ">
                    <div className="card ">
                    <div className="card-header ">Email</div>
                      <div className="card-body ">
                        <FormGroup>
                          <label className="font-weight-bold">Title</label>
                          <Input
                            placeholder="Title"
                            maxLength="500"
                            name="title"
                            value={addCommunicationTemplate.title}
                            onChange={this.handleChange}
                          />

                          <FormFeedback>
                            {errors[`title`] && errors[`title`]}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <label className="font-weight-bold">Email subject</label>
                          <Input
                            placeholder="Email subject"
                            maxLength="500"
                            name="email_subject"
                            value={addCommunicationTemplate.email_subject}
                            onChange={this.handleChange}
                          />

                          <FormFeedback>
                            {errors[`email_subject`] && errors[`email_subject`]}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <label className="font-weight-bold">email Text</label>
                          <span className="text-muted pull-right">
                            {addCommunicationTemplate.email_text
                              ? `${parseInt(
                                addCommunicationTemplate.email_text.length,
                                10
                              )}`
                              : ""}{" "}
                            characters{" "}
                          </span>
                          <Input
                            placeholder="email_text Text"
                            type="textarea"
                            rows="5"
                            name="email_text"
                            value={
                              this.state.addCommunicationTemplate.email_text
                            }
                            onChange={this.handleChange}
                          />

                   

                          <FormFeedback>
                            {errors[`notification_text`] && errors[`notification_text`]}
                          </FormFeedback>
                        </FormGroup>


                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 ">

                    <div className="card ">
                    <div className="card-header ">SMS</div>
                      
                      <div className="card-body ">

                        <FormGroup>
                          <label className="font-weight-bold">SMS Text</label>
                          <span className="text-muted pull-right">
                            {parseInt(addCommunicationTemplate.sms_text.length, 10)}{" "}
                            characters{" "}
                          </span>
                          <Input
                            placeholder="SMS Text"
                            type="textarea"
                            rows="5"
                            maxLength="500"
                            name="sms_text"
                            value={addCommunicationTemplate.sms_text}
                            onChange={this.handleChange}
                          />
                

                          <FormFeedback>
                            {errors[`sms_text`] && errors[`sms_text`]}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <label className="font-weight-bold">Flow ID</label>
                          <Input
                            placeholder="Flow ID"
                            maxLength="500"
                            name="sms_flow_id"
                            value={addCommunicationTemplate.sms_flow_id}
                            onChange={this.handleChange}
                          />

                          <FormFeedback>
                            {errors[`sms_flow_id`] && errors[`sms_flow_id`]}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <label className="font-weight-bold">
                            Live Sms Text Preview{" "}
                          </label>
                          {addCommunicationTemplate.sms_text ? (
                            <>
                              <span className="text-muted pull-right">
                                {parseInt(
                                  this.renderLivePreview(
                                    addCommunicationTemplate.sms_text
                                  ).length,
                                  10
                                )}{" "}
                                characters{" "}
                              </span>
                              <br />
                              <span className="text-muted">
                                {this.renderLivePreview(
                                  addCommunicationTemplate.sms_text
                                )}{" "}
                              </span>
                            </>
                          ) : (
                            <span className="text-muted">SMS text is null</span>
                          )}
                        </FormGroup>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="card ">
                    <div className="card-header ">Notification</div>
                      <div className="card-body ">
                        <FormGroup>
                          <label className="font-weight-bold">Notification title</label>
                          <Input
                            placeholder="Notification title"
                            maxLength="500"
                            name="notification_title"
                            value={addCommunicationTemplate.notification_title}
                            onChange={this.handleChange}
                          />

                          <FormFeedback>
                            {errors[`notification_title`] &&
                              errors[`notification_title`]}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <label className="font-weight-bold">Notification Text</label>
                          <span className="text-muted pull-right">
                            {addCommunicationTemplate.notification_text
                              ? `${parseInt(
                                addCommunicationTemplate.notification_text.length,
                                10
                              )}`
                              : ""}{" "}
                            characters{" "}
                          </span>
                          <Input
                            placeholder="Notification Text"
                            type="textarea"
                            rows="5"
                            name="notification_text"
                            value={
                              this.state.addCommunicationTemplate.notification_text
                            }
                            onChange={this.handleChange}
                          />

             

                          <FormFeedback>
                            {errors[`notification_text`] && errors[`notification_text`]}
                          </FormFeedback>
                        </FormGroup>


                        <FormGroup>
                          <label className="font-weight-bold">
                            notification Text Preview{" "}
                          </label>
                          {addCommunicationTemplate.notification_text ? (
                            <>
                              <span className="text-muted pull-right">
                                {parseInt(
                                  this.renderLivePreview(
                                    addCommunicationTemplate.notification_text
                                  ).length,
                                  10
                                )}{" "}
                                characters{" "}
                              </span>
                              <br />
                              <span className="text-muted">
                                {this.renderLivePreview(
                                  addCommunicationTemplate.notification_text
                                )}{" "}
                              </span>
                            </>
                          ) : (
                            <span className="text-muted">notification text is null</span>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="card ">
                    <div className="card-header ">Voice </div>
                      <div className="card-body ">
                        <FormGroup>
                          <label className="font-weight-bold">Voice Text</label>
                          <span className="text-muted pull-right">
                            {addCommunicationTemplate.voice_text
                              ? `${parseInt(
                                addCommunicationTemplate.voice_text.length,
                                10
                              )}`
                              : ""}{" "}
                            characters{" "}
                          </span>
                          <Input
                            placeholder="Voice Text"
                            type="textarea"
                            rows="5"
                            name="voice_text"
                            value={
                              this.state.addCommunicationTemplate.voice_text
                            }
                            onChange={this.handleChange}
                          />

                       

                          <FormFeedback>
                            {errors[`notification_text`] && errors[`notification_text`]}
                          </FormFeedback>
                        </FormGroup>

                        
                    
                      </div>
                    </div>
                  </div>


                </div>



                <FormGroup>
                  <Button
                    color="primary"
                    onClick={this.onSubmitSMS}
                    disabled={saving}
                  >
                    {" "}
                    {saving ? "Saving..." : "Save Draft"}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    color="secondary"
                  onClick={() => this.props.history.goBack()}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </FormGroup>
              </Form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getCommunicationTemplate;
  const { user } = state.authentication;
  return {
    loading,
    data: data ? data.data : [],
    authUser: user,
  };
}

const connectedAddCommunicationTemplate = connect(mapStateToProps)(
  AddCommunicationTemplate
);
export { connectedAddCommunicationTemplate as AddCommunicationTemplate };

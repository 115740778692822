import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import moment from 'moment';
import classnames from 'classnames';
import { reputeinfoActions } from "../../actions";
import { reputeinfoConstants } from '../../constants';
import { RecentRegistered } from './RecentRegistered';
import { handleValidation } from './Validation';
import { RegistrationAttemptLog } from './RegistrationAttemptLog,';
import { RegistrationAnalysis } from './RegistrationAnalysis';
import { MarketingAnalysis } from './MarketingAnalysis';

const TABS = {
    Registered: "Registered",
    RegisterationAttempt: "RegisterationAttempt",
    Analysis: 'Analysis',


};

class RecentRegistrations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.RegisterationAttempt,
            data: ''
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
    }
    stateFunc(item) {
        this.setState({
            data: item
        })
    }

    componentDidMount() {
        var url = window.location.href;
        var subPosition = url.substring(url.indexOf("?") + 1);
        if (subPosition === `registration-analysis`) {
            this.setState({ activeTab: TABS.Analysis });
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="fas fa-user"></i> <b> Recent Registrations</b></CardHeader>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === TABS.Registered,
                                            })}
                                            onClick={() => {
                                                this.toggle(TABS.Registered);
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Registered</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === TABS.RegisterationAttempt,
                                            })}
                                            onClick={() => {
                                                this.toggle(TABS.RegisterationAttempt);
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Registration Tracker</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === TABS.Analysis,
                                            })}
                                            onClick={() => {
                                                this.toggle(TABS.Analysis);
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold" }}>Registration Analysis</span>
                                        </NavLink>
                                    </NavItem>
                                    <span>

                                    </span>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId={TABS.Registered}>

                                        <RecentRegistered dataState={this.stateFunc} />
                                    </TabPane>

                                    <TabPane tabId={TABS.RegisterationAttempt}>

                                        <RegistrationAttemptLog />
                                    </TabPane>
                                    <TabPane tabId={TABS.Analysis}>

                                        <MarketingAnalysis />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}
const connected = connect(mapStateToProps)(RecentRegistrations)
export { connected as RecentRegistrations };


import React, { Component } from 'react'
import { AddNewItem } from '../Components/AddNewItem';
import { DisplayListItems } from '../Components/DisplayListItems';
import { findAndDeleteFirst } from 'obj-traverse/lib/obj-traverse'

export default class IndividualDetailsSection extends Component {

    constructor(props){
        super(props)
        this.SaveItem = this.SaveItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    SaveItem(Items){
        const {template, updateMainJSON} = this.props
        
        template.individual_details.individual_template.children = [...Items];
        
        updateMainJSON(template);
        
    }

    removeItem(item_id) {
        const {template} = this.props
        console.log(item_id);

        let Items =  findAndDeleteFirst(template.individual_details.individual_template, "children", {id : item_id})

        if(Items) {
            this.SaveItem(Items.children)
        }
      }
      

    render() {
        let { template } = this.props

        return ( <div>
            <h3>Individuals Details</h3>  
               
            <AddNewItem RootItems={template.individual_details.individual_template.children} Items={template.individual_details.individual_template.children} SaveItem={this.SaveItem} />  

            <DisplayListItems RootItems={template.individual_details.individual_template.children} Items={template.individual_details.individual_template.children} SaveItem={this.SaveItem} removeItem={this.removeItem} />

            </div>
        )
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productTagActions } from '../../actions';
import { Row,Col, CardBody, Card, CardHeader, ModalBody, ModalHeader, ModalFooter, Button, Modal, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import { Tooltip } from 'react-tippy';
import { validPhone } from '../../../helpers';
import LocationSearchInput from '../../../components/Forms/LocationSearchInput';
import  GoogleApiWrapper  from '../../../components/GoogleMap'


class UnregisteredSeller extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			isEdit: false,
			formData: {
                company_name: '',
                owner_name: '',
                mobile: '',
                selected_product :'',
				lat : '',
				long : '',
				radius : '',
				location_data : '',
				radius_list : [
					{label : '5 KM', value : '5'},
					{label : '10 KM', value : '10'},
					{label : '15 KM', value : '15'},
					{label : '20 KM', value : '20'},
					{label : '25 KM', value : '25'},
					{label : '30 KM', value : '30'},
				],
                seller_product_list : [],
				isMobileVerified : false
            },
			errors: {},
			page: {
				perPage: 20,
				totalItemsCount: 0
			}
		};
		this.toggle = this.toggle.bind(this)
		this.getData = this.getData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.isValid = this.isValid.bind(this);
		this.save = this.save.bind(this);
		this.reset = this.reset.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.editData = this.editData.bind(this);
		this.cancel = this.cancel.bind(this)
        this.addSellerProduct = this.addSellerProduct.bind(this)
        this.removeSellerProduct = this.removeSellerProduct.bind(this)
		this.checkDuplicateUnregisteredSeller = this.checkDuplicateUnregisteredSeller.bind(this)
		//		this.renderAction = this.renderAction.bind(this);
		this.updateData = this.updateData.bind(this)
	}

	cancel(value) {
		this.toggle(value);
		
	}
	toggle(value) {
		this.setState({ show: value }, ()=>{
			if(value == false){
				this.reset();
			}

		});
	}
	isValid() {
		const { formData, errors } = this.state;
		let error = false;
		if (!formData.company_name) {
			error = true
			errors.company_name = 'this field is requird'
		}
		if (!formData.owner_name) {
			error = true
			errors.owner_name = 'this field is requird'
		}
        if (!formData.mobile) {
			error = true
			errors.mobile = 'this field is requird'
		}
        if (formData.mobile && !validPhone(formData.mobile)) {
            error = true
            errors.mobile = "invalid mobile"
        }

        if (formData.seller_product_list.length == 0) {
			error = true
			errors.selected_product = 'this field is requird'
		}

		if (!formData.lat) {
			error = true
			errors.lat = 'this field is requird'
		}
		if (!formData.radius) {
			error = true
			errors.radius = 'this field is requird'
		}
		this.setState({ errors });

		return error;
	}
	save() {
		const { formData, isEdit } = this.state;

		const { dispatch } = this.props;
		if (!this.isValid() && formData.isMobileVerified) {

			let dataToSend = {
				company_name: formData.company_name,
				owner_name: formData.owner_name,
				mobile: formData.mobile,
				lat : formData.lat,
				long : formData.long,
				location_data : formData.location_data,
				radius : formData.radius,
				seller_product_list: formData.seller_product_list,
			};

			if (isEdit) {
				dataToSend.id = formData.id
				dataToSend.location_detail_id = formData.location_detail_id
			}
			dispatch(productTagActions.addUnregisteredSeller(dataToSend, {
				success: () => {
					this.getData();
					this.reset();
					this.toggle(false);
				}
			}))
		}
	}

	reset() {
		const { formData } = this.state;
		formData.company_name = '';
		formData.id = '';
		formData.owner_name = '';
		formData.mobile = '';
		formData.seller_product_list = [];
		formData.selected_product = ''
		formData.lat =  ''
		formData.long = ''
		formData.radius = ''
		formData.location_data = ''
		this.setState({
			formData,
			isEdit : false,
			errors: {}
		});
	}

	updateData(name,value) {
		const { formData,errors } = this.state;
		formData[name] = value
		errors['lat'] = ''
		this.setState({ formData })
	}
	handleChange(event) {
		const { name, value } = event.target;
		const { formData,errors } = this.state;
		if (name === "company_name" || name === "owner_name") {
            let regex_allowed = /^[a-zA-Z ]+$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }

		if (name === "mobile" || name === "radius") {
            let regex_allowed = /^[0-9]+$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
			
			if(name === "radius" && value > 4000) {
				return false
			}
        }

		
		formData[name] = value;
        errors[name] = ''
		this.setState({ formData, errors },() => {
			
			if(name == 'mobile' && formData.mobile && formData.mobile.length === 10) {
				this.checkDuplicateUnregisteredSeller()
			}
		});
	}

	checkDuplicateUnregisteredSeller() {
		const { formData, errors } = this.state
		const { dispatch } = this.props;
		if(formData.mobile && formData.mobile.length == 10) {
			let dataToSend = {
				mobile : formData.mobile
			}
			dispatch(productTagActions.checkDuplicateUnregisteredSeller(dataToSend,{
				success : () => {
						errors.mobile = ''
						formData.isMobileVerified = true
						this.setState({ errors, formData })
				},
				failure : (error) => {
					if(error) {
						errors.mobile = error.toString()
						formData.isMobileVerified = false
						this.setState({ errors, formData })
					}
				}
			}));

		}
	}
	componentDidMount() {
		
		this.getData();
	}

	getData() {
		const { dispatch } = this.props;
		const { page, data } = this.state;
		let dataToSend = {
			active_page: page.activePage,
			per_page: page.perPage
		};		
		dispatch(productTagActions.getUnregisteredSeller(dataToSend));
	}

    addSellerProduct() {
        const { formData,errors } = this.state
        const { product_list } = this.props
		
		if(!formData.selected_product) {
			errors.selected_product = 'this field is requird'
			this.setState({ errors })
			return false
		}
        let selected_product = product_list.find((x) => x.id == formData.selected_product)
        formData.seller_product_list.push(selected_product)
		formData.selected_product = "";
        this.setState({ formData })
    }
    removeSellerProduct(index) {
        let { formData } = this.state
        formData.seller_product_list.splice(index, 1);
        this.setState({ formData })
    }
	handlePageChange(pageNumber) {
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => this.getData());
	}

	componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}

	editData(row) {
		const { formData } = this.state
		formData.company_name = row.company_name
		formData.id = row.id
		formData.owner_name = row.owner_name
		formData.mobile = row.mobile
		formData.isMobileVerified = true
		formData.seller_product_list = []
		row.unregistered_seller_product_tag && row.unregistered_seller_product_tag.length &&
		row.unregistered_seller_product_tag.map((each) => {
			formData.seller_product_list.push({
				id :  each.product_tag_id,
				tag_name : each.product_name
			})
		})
		if(row.location_detail) {
			formData.lat  = row.location_detail.lat
			formData.long = row.location_detail.long
			formData.location_data = row.location_detail.location_data
			formData.radius = row.location_detail.radius			
			formData.location_detail_id = row.location_detail.id
		}
		this.toggle(true);
		this.setState({ formData, isEdit: true });

	}
	deleteData(row) {
		const { dispatch } = this.props;
		const { page } = this.state;
		let dataToSend = {
			id: row.id
		};

		swal({
			title: 'Are you sure you want to delete ?',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(productTagActions.deleteProductTags(dataToSend, {
					success: () => {
						this.getData();
					}
				}));
			}
		});
	}
	renderAction(row) {
		return (
			<React.Fragment>
				<Button size="sm" color="primary" onClick={() => this.editData(row)}>
					{' '}
					Edit{' '}
				</Button>{' '}
				{' '}
				{/* <Button size="sm" color="danger" onClick={() => this.deleteData(row)}>
					{' '}
					Delete{' '}
				</Button> */}
			</React.Fragment>
		);
	}
	render() {
		const { logs, loading, seller_list,product_list } = this.props;
		const { page, formData, show, isEdit, errors, seller_product_list } = this.state;
		let _this = this;
		return ( 
			<Card>
				<CardHeader>
					<i className="fas fa-boxes" />
					<strong>Unregistered Sellers</strong>
					<Button color="primary" className="pull-right" onClick={() => {
						this.reset(true)
						this.toggle(true)
					}}
					>Add New Seller </Button>
				</CardHeader>
				<CardBody>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
                            <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Owner Name</th>
                                                <th>Mobile</th>
												<th>Products</th>
												<th>Selling Location</th>
												<th>Radius</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                                seller_list && seller_list.length ?
                                                    seller_list.map((element, idx) => {
                                                        return (
                                                            <tr key={idx}>

                                                                <td>{element.company_name}</td>
                                                                <td>{element.owner_name}</td>
                                                                <td>{element.mobile}</td>
																<td>
																	{
																		element.unregistered_seller_product_tag && element.unregistered_seller_product_tag.length ?
																		element.unregistered_seller_product_tag.map((item, index) => {
																				return <label  key={index}  className="badge badge-success mr-2 product-name-badge" >{item.product_name}</label>
																			})
																			: ''
																	}
																</td>
																<td>
																	
																{ element.location_detail ?
																		<React.Fragment>
																			{element.location_detail.location_data}
																			<div className="float-right">
																			<Tooltip
																					animateFill={false}
																					interactive
																					arrow={true}
																					position="left"
																					theme="light"
																					animation="fade"
																					arrowSize="big"
																					interactiveBorder="2"
																					html={
																						<div style={{ width:"400px", height:"300px",  }}>
																							<GoogleApiWrapper height={270} width={360} selectedPlace={element.location_detail}  />	
																							
																							<div style={{position:"fixed", bottom:0,  overflow : "hidden", width:"95%"}}>
																							<label> {element.location_detail.location_data} | { element.location_detail ? element.location_detail.radius + " KM" : '' }</label>
																							</div>
																						</div>
																						
																					}
																				>
																					<i className="fa fa-map-marker text-danger cursor-pointer" style={{fontSize:"20px"}}  aria-hidden="true"></i>
																				</Tooltip> 
																				</div>
																		</React.Fragment>
																	: '' }

																</td>
																<td>
																	{ element.location_detail ? element.location_detail.radius : '' }
																</td>
                                                                <td>
																	<Button color="primary" size="sm" onClick={() => { this.editData(element) }}>Edit</Button>
																</td>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="5" className="text-center"> No Sellres </td></tr>
                                            }
                                           

                                        </tbody>
                                    </table>
							</div>
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}

								/>
							)}
						</React.Fragment>
					)}
					<Modal
						isOpen={show}
						size={'lg'}
						toggle={() => this.toggle(false)}>
						<ModalHeader>
							{isEdit ? "Edit" : "Add"} Unregistered Seller
						</ModalHeader>
						<ModalBody style={{ minHeight: '500px' }}>
							<Form autoComplete="off" >
								<Row>
									<Col>
										<FormGroup >
											<label><b>Company Name</b></label>
											<Input type="text" value={formData.company_name} name="company_name" placeholder="company name" onChange={this.handleChange} />
											{errors && errors.company_name && (<FormFeedback> {errors.company_name} </FormFeedback>)}
										</FormGroup>
										<FormGroup >
											<label><b>Owner Name</b></label>
											<Input type="text" value={formData.owner_name} name="owner_name" placeholder="owner name" onChange={this.handleChange} />
											{errors && errors.owner_name && (<FormFeedback> {errors.owner_name} </FormFeedback>)}
										</FormGroup>
										<FormGroup >
											<label><b>Mobile Number</b></label>
											<Input type="text" value={formData.mobile} name="mobile" placeholder="mobile" maxLength="10" onChange={this.handleChange} />
											{errors && errors.mobile && (<FormFeedback> {errors.mobile} </FormFeedback>)}
										</FormGroup>
										<FormGroup>
											<Row>
												<Col md={10}>
													<label><b>Select Products</b></label>
													<select className="form-control" name="selected_product" value={formData.selected_product} onChange={this.handleChange}>
														<option>Select</option>
														{
															product_list && product_list.length &&
															product_list.map((product, index) => {
																return (
																	
																	<option  key={index}  value={product.id}>{product.tag_name}</option>
																)
															})
														}
													</select>                                        
												</Col>
												
												<Col md={2} style={{paddingTop: '2em'}}>
													<Button onClick={this.addSellerProduct}>Add</Button>
												</Col>
											</Row>
											{errors && errors.selected_product && (<FormFeedback> {errors.selected_product} </FormFeedback>)}
										</FormGroup>
										{
										formData.seller_product_list && formData.seller_product_list.length ?
										formData.seller_product_list.map((item, index) => {
												return <label key={index} className="badge badge-success mr-2 product-name-badge" >{item.tag_name} &nbsp;<i className="fa fa-times cursor-pointer text-danger" onClick={(e) => this.removeSellerProduct(index)}></i> </label>
											})
											: ''
										}									
									</Col>
									<Col>
									<FormGroup >
										<label><b>Selling Location</b></label>
										<LocationSearchInput updateData={this.updateData} address={formData.location_data} />
										{errors && errors.lat && (<FormFeedback> {errors.lat} </FormFeedback>)}
									</FormGroup>
									<FormGroup>
										<label><b>Radius</b></label>
										<Input type="text" placeholder="Enter radius in KM" name="radius" value={formData.radius} onChange={this.handleChange} />
										{errors && errors.radius && (<FormFeedback> {errors.radius} </FormFeedback>)}
									</FormGroup>
									
									<FormGroup>
									{
										formData.lat && formData.long ?
										<GoogleApiWrapper selectedPlace={formData} updateData={this.updateData} />	
										: ''
									}</FormGroup>
									</Col>	
								</Row>

								
                                
							</Form>
						</ModalBody>

						<ModalFooter>
							<Button color="primary" onClick={() => this.save()}>
								Save
							</Button>{" "}
							<Button color="warning" onClick={() => this.reset()}>
								Reset
							</Button>{" "}
							<Button color="secondary" onClick={() => this.cancel(false)}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getUnregisteredSeller;
	return {
		loading,
		seller_list: data ? data.seller_list : [],
        product_list:data ? data.product_list : [],
	};
}

const connectedUnregisteredSeller = connect(mapStateToProps)(UnregisteredSeller);
export { connectedUnregisteredSeller as UnregisteredSeller };

import { SMSConstants } from "../constants"

export function addSMS(state = {}, action) {
  switch (action.type) {
    case SMSConstants.ADD_SMS_REQUEST:
      return { loading: true }
    case SMSConstants.ADD_SMS_SUCCESS:
      return { data: action.result }
    case SMSConstants.ADD_SMS_FAILURE:
      return {}
    default:
      return state
  }
}

export function getSMS(state = {}, action) {
  switch (action.type) {
    case SMSConstants.GET_SMS_REQUEST:
      return { loading: true }
    case SMSConstants.GET_SMS_SUCCESS:
      return { data: action.result }
    case SMSConstants.GET_SMS_FAILURE:
      return {}
    default:
      return state
  }
}

export function editSMS(state = {}, action) {
  switch (action.type) {
    case SMSConstants.EDIT_SMS_REQUEST:
      return { loading: true }
    case SMSConstants.EDIT_SMS_SUCCESS:
      return { data: action.result }
    case SMSConstants.EDIT_SMS_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteSMS(state = {}, action) {
  switch (action.type) {
    case SMSConstants.DELETE_SMS_REQUEST:
      return { loading: true }
    case SMSConstants.DELETE_SMS_SUCCESS:
      return { data: action.result }
    case SMSConstants.DELETE_SMS_FAILURE:
      return {}
    default:
      return state
  }
}
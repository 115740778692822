import React from 'react';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export const TimelineStructure = ({ iconStyle, icon, title, oldFormData, ...props }) => (
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={false}
        iconStyle={iconStyle}
        icon={icon}
    >
        <b> {title} </b>
        {oldFormData && oldFormData.updated_at &&
            <span className="pull-right badge badge-pill  badge-info" > <i class="fa fa-clock-o"></i> {moment(oldFormData.updated_at).format("YYYY-MM-DD hh:mm A")}</span>}
        <div className="mt-2">
            {props.children}
        </div>
    </VerticalTimelineElement> 
);


export const NoteText = ({ action_note }) => (
    action_note &&
    <React.Fragment>
        <p><b>Notes</b></p>
        <p>{action_note}</p>
    </React.Fragment>
);

export const CommonDatePicker = ({ selectedDate, onChange }) => (
    <FormGroup>
        <DatePicker
            selected={selectedDate}
            onChange={onChange}
            className={"form-control"}
            showTimeSelect
            timeFormat="h:mm aa"
            minDate={new Date()}
            timeIntervals={15}
            dateFormat="d-M-Y h:mm aa"
            timeCaption="time"
        />
    </FormGroup>
);


export const NotesInput = ({ action_note, handleChange, errors }) => (
    <FormGroup>
        <label className="font-weight-bold">Notes</label>
        <Input placeholder="Notes" type="textarea" rows="5" name="action_note" value={action_note} onChange={handleChange} />
        <FormFeedback>
            {errors[`action_note`] && errors[`action_note`]}
        </FormFeedback>
    </FormGroup>
)

import config from 'react-global-configuration'
import { axiosAjaxCall, handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const reputeinfoBalancePackageService = {
    getBalancePackage,
    updateBalancePackage,

    getProfileWeightageSetting,
    updateProfileWeightageSetting,

    getBalanceSetting,
    updateBalanceSetting
}

function getBalancePackage(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_balance_package`, form_data, setting).then(handleResponse)
}

function updateBalancePackage(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update_balance_package`, form_data, setting).then(handleResponse)
}

function getBalanceSetting(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_balance_setting`, form_data, setting).then(handleResponse)
}

function updateBalanceSetting(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update_balance_setting`, form_data, setting).then(handleResponse)
}



function getProfileWeightageSetting(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/getProfileWeightageSetting`, form_data, setting).then(handleResponse)
}

function updateProfileWeightageSetting(data) {
    return axiosAjaxCall("updateProfileWeightageSetting", data);
}

import React, { Component } from 'react'
import { Map, google, InfoWindow, Marker, GoogleApiWrapper, Circle } from 'google-maps-react';
import { data } from 'jquery';
import { construct } from 'core-js/fn/reflect';
import { GOOGLE_MAP_API_KEY } from '../api-config'

function getZoomLevel(radius) {
  let zoomLevel = 11;
  if (radius != null) {
    let scale = radius / 250;
    zoomLevel = (16 - Math.log(scale) / Math.log(2));
  }
  return zoomLevel ;
}

export class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }

  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  moveMarker = (coord, index) => {
    const { updateData } = this.props
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    if (updateData) {
      updateData('lat', lat)
      updateData('long', lng)
    }
  };

  render() {
    const coords = {
      lat: parseFloat(this.props.selectedPlace.lat),
      lng: parseFloat(this.props.selectedPlace.long)
    };
    const radius = parseInt(this.props.selectedPlace.radius)
    const zoom = getZoomLevel(radius* 1000);

    return (
      <Map
        initialCenter={coords}
        google={this.props.google}
        style={{ width: this.props.width ? this.props.width : 500, height: this.props.height ? this.props.height : 300, position: 'relative' }}
        zoom={zoom}
        mapCenter={coords}
      >
        <Marker
          position={coords}
          //  animation={this.props.google.maps.Animation.BOUNCE}
          onClick={this.onMarkerClick}
          // title={'The marker`s title will appear as a tooltip.'}
          // name={'Current location'}
          draggable={this.props.updateData ? true : false}
          onDragend={(t, map, coord) => this.moveMarker(coord, 0)}
        />
        <InfoWindow onClose={this.onInfoWindowClose} visible={this.state.showingInfoWindow} marker={this.state.activeMarker} >
          <div>
            <h1>{this.props.selectedPlace.location_data}</h1>
          </div>
        </InfoWindow>
        {
          radius ?
            <Circle
              radius={radius * 1000}
              center={coords}
              fillColor='#007bff'
              strokeColor='#007bff'
              strokeOpacity='0.8'
              strokeWeight='1'
              fillOpacity='0.35'
            />
            : ''
        }

      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (GOOGLE_MAP_API_KEY + `&libraries`)
})(MapContainer)
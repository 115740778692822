import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import { currencyFormat } from '../../../helpers';
import moment from "moment";

export default class BuyerDetails extends Component {
    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                {/* <h6>Buyer and Requirement Details</h6> */}
            <div className="row">
                <div className="col-md-2">
                    <strong>Buyer Company Name</strong> <br />
                    <label>{data.company_name}</label>
                </div>

                <div className="col-md-2">
                    <strong>Buyer Owner Name</strong><br />
                    <label>{data.individual_name}</label>
                </div>


                <div className="col-md-2">
                    <strong>Product(s) Requirement</strong><br />
                    {data.buyer_requiryment_product_tag.map((each) => {
                        return <React.Fragment>
                            <Tooltip
                                // className="btn m-0 p-0"
                                interactive
                                arrow={true}
                                html={
                                    <React.Fragment>
                                        <div className="text-left">

                                            <b>Description: </b>{each.description} <br />
                                            <b>Quantity : </b> {currencyFormat(each.quantity)} <br />
                                            <b>Unit: </b>{each.unit} <br />
                                        </div>
                                    </React.Fragment>
                                }
                            >
                                <label className="badge badge-success mr-1">{each.product_name} - {' '}
                                    {each.sort_quote_for_requirement_product_wise.length}</label>
                            </Tooltip>  </React.Fragment>
                    })}
                </div>

                <div className="col-md-2">
                    <strong>Start and End Date </strong> <br />
                    <label>{moment(data.start_date).format('DD-MM-YYYY')} to {moment(data.end_date).format('DD-MM-YYYY')}</label> 
                    
                </div>
               
                <div className="col-md-2">
                    <strong>Location</strong><br />
                    <label>{data.delivery_location}</label>
                </div>
                <div className="col-md-2">
                    <strong>Delivered by seller</strong><br />
                    <label>{data.is_delivered_seller ? 'Yes' : 'No'}</label>
                </div>

            </div>
            </React.Fragment>
        );
    }
}

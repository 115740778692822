import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Button, Row, Col } from "reactstrap";
import { UpdateCallTaskModal } from "./UpdateCallTaskModal";
import { tasksActions } from "../../actions/tasks.action";
import { Link } from "react-router-dom";
import DropdownList from 'react-widgets/lib/DropdownList';
import { Support } from "./Support";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

class Tasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { selected_stage_id: "" },
      display_activeStageList: [],
      all_activeStageList: [],
    };
    this.getData = this.getData.bind(this);
    this.filterData = this.filterData.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { dispatch } = this.props;

    dispatch(tasksActions.getTasks());
  }

  filterData(selected) {
    const {  all_activeStageList } = this.state;
    let display_activeStageList = [];
    if(all_activeStageList){
      
      if(selected.value == 0) {
        display_activeStageList = all_activeStageList
      } else {
        display_activeStageList = all_activeStageList.filter(each => selected.value ==  each.process_stage.process_name + " > " + each.process_stage.stage_name);
      }
    }

    // data["selected_stage_id"] = stage_id;

    this.setState({
      display_activeStageList,
    });
  }

  componentWillReceiveProps(props) {
    const { activeStageList } = props;
    if (activeStageList) {
      this.setState({
        display_activeStageList: activeStageList,
        all_activeStageList: activeStageList,
      });
    }
  }

  render() {
    const { tasksList, activeStageList } = this.props;
    const { display_activeStageList, all_activeStageList } = this.state;
    let _this = this;

    let filter_options = [{ label: "All", value: 0 }];

    var counts = all_activeStageList.reduce((p, c) => {
      // var name = c.fields.status.name;
      var name = "Not Avalilable";
      if(c.hasOwnProperty("process_stage")){
        name = c.process_stage.process_name + " > " + c.process_stage.stage_name;
      }

      if (!p.hasOwnProperty(name)) {
        p[name] = {
          label: name,
          value: name,
          count: 0,
        };
      }
      p[name].count++;
      return p;
    }, {});

    Object.keys(counts).map((k) => {
      return filter_options.push(counts[k]);
    });

    return (
      <div>
        <h5 className="mb-4 mt-4">
          <i className="fa fa-ticket"></i> &nbsp; Tasks
        </h5>

        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <Row>

                <Col>
                <i className="fa fa-th"></i> &nbsp;
                Active Stages
                </Col>
                <Col md="6">

                <DropdownList
									filter //={customerOptions.length > 1}
									className="form-control-select"
									placeholder={"Select Status"}
									valueField="label"
									textField="label"
									// value={company_repute_id}
									onChange={this.filterData }
									data={filter_options}
                  name={'company_repute_id'}
                  itemComponent={ ({ item }) => (
                    <span>
                      <strong>{item.label}</strong>
                      <span className="badge badge-success pull-right">{item.count}</span>
                    </span>
                  )}
                  />
                  </Col>
                  </Row>
                
              </CardHeader>
              <CardBody>
                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                  <thead class="thead-light">
                    <tr>
                      <td>Business Name</td>
                      <td>Stage</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  {display_activeStageList && display_activeStageList.length ? (
                    display_activeStageList.map((eachtask) => {
                      return (
                        <tr>
                          <td>{eachtask.business_name}</td>
                          <td>
                            {eachtask.process_stage
                              ? eachtask.process_stage.process_name
                              : "Not Available"}{" "}
                            &nbsp; <i className="fa fa-caret-right"></i> &nbsp;
                            {eachtask.process_stage
                              ? eachtask.process_stage.stage_name
                              : "Not Available"}
                          </td>

                          <td>
                            <Link
                              className="btn btn-primary btn-sm"
                              to={"/business-account/edit/" + eachtask.id}
                            >
                              Update
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        {" "}
                        No Tasks Available{" "}
                      </td>{" "}
                    </tr>
                  )}
                </table>
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Card>
              <CardHeader>
                
              <i className="fa fa-phone"></i> &nbsp;
                Scheduled Call</CardHeader>
              <CardBody>
                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                  <thead class="thead-light">
                    <tr>
                      <td>Business Name</td>
                      <td>Scheduled Date Time</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  {tasksList && tasksList.length ? (
                    tasksList.map((eachtask) => {
                      return (
                        <tr>
                          <td>{eachtask.business_name}</td>
                          <td>{eachtask.when_to_execute}</td>

                          <td>
                            <UpdateCallTaskModal
                              getData={_this.getData}
                              taskData={eachtask}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        {" "}
                        No Scheduled Calls{" "}
                      </td>{" "}
                    </tr>
                  )}
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Support/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { tasksList, activeStageList, loading } = state.tasks;
  return {
    tasksList,
    activeStageList,
    loading,
  };
};

let connected = connect(mapStateToProps)(Tasks);

export { connected as Tasks };

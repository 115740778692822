import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requirementLogsAction } from '../../actions'
import { Tooltip } from 'react-tippy';
import { TableLoader } from "../../../components/Loaders";
import { currencyFormat } from '../../../helpers';
import moment from "moment";
import { CardBody, Card, CardHeader, Button, Form, FormGroup, Input, FormFeedback, Row, Col, } from 'reactstrap';
import Pagination from "react-js-pagination";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { notify } from 'reapop'
import { InviteBuyerRequirement } from './InviteBuyerRequirement'
import { ViewQuotes } from './ViewQuotes'
import { Selleres } from './Selleres'
import { UpdateLocation } from './UpdateLocation'
import {CopyToClipboard} from 'react-copy-to-clipboard';
class RequirementLogs extends Component {
    constructor(props) {
        super(props)
        let days = new Date().getDate() - 1;
        this.state = {
            copied:{},
            startDate: moment(), //moment().subtract(days, 'days'),
            endDate: moment(),
            serachField: {
                startDateRange: '',
                endDateRange: '',
                product_id: '',
                buyer_requirement_id: '',
                section: 'active',
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            errors: {},
            page: {
                perPage: 20,
                totalItemsCount: 0
            }
        }
        this.getData = this.getData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }
    getData() {
        const { dispatch } = this.props
        const { page, sstart_date, send_date, estart_date, eend_date, serachField } = this.state;

        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            sstart_date: sstart_date,
            send_date: send_date,
            estart_date: estart_date,
            eend_date: eend_date,
            product_tag_id: serachField.product_id,
            section: serachField.section,
            buyer_requirement_id: serachField.buyer_requirement_id
        };
        dispatch(requirementLogsAction.getRequirementLogs(dataToSend))
    }

    handleChange(e) {
        const { serachField } = this.state
        const { name, value } = e.target;
        serachField[name] = value;

        this.setState({ serachField })
    }

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getData());
    }

    handleEvent(event, picker, name) {
        const { serachField } = this.state
        var start = picker.startDate.format("DD-MM-YY");
        var end = picker.endDate.format("DD-MM-YY");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }
        if (name == 'sdate') {
            serachField.startDateRange = label
            this.setState({
                sstart_date: picker.startDate,
                send_date: picker.endDate,
                serachField,
                errors: {}
            })
        }
        else {
            serachField.endDateRange = label
            this.setState({
                estart_date: picker.startDate,
                eend_date: picker.endDate,
                serachField,
                errors: {}
            })
        }
    }

    handleApply(e) {
        e.preventDefault();
        const { page, start_date, end_date, serachField } = this.state;
        const { dispatch } = this.props
        // if(!start_date && !end_date && serachField.product_id === '') {
        //     dispatch(notify({ position: "tc", dismissible: false, message: 'Select at least one filter', status: "error" }))
        // }
        this.getData();
    }
    handleReset() {
        this.setState({
            sstart_date: '',
            send_date: '',
            estart_date: '',
            eend_date: '',
            serachField: {
                startDateRange: '',
                endDateRange: '',
                product_id: '',
                section: 'active',
                buyer_requirement_id: '',
            }
        }, () => this.getData());
    }
    componentDidMount() {
        this.getData();
    }

    componentWillReceiveProps(props) {
        let { page, activeTab } = this.state;
        page.totalItemsCount = 0;
        if (props.data) {
            page.totalItemsCount = props.data.total_records;
        }
        if (props.activeTab) {
            activeTab = props.data.activeTab;
        }
        this.setState({
            page,
            activeTab
        });
    }
    
    renderCopyToClipboardText(products, element){
        let text = `For More Details Contact\nReputeInfo Support +919510231023\n`;
        text+= "====================\n";
        text+= `Location : ${element.delivery_location}\n`;
        text+= `Seller Delivered : ${element.is_delivered_seller ? "YES" : "NO"} \n`;
        text+= "====================\n";
        products.map((each, idx) => {
                text+= each.product_name + "\n";
                text+= "Description:" + each.description + "\n";
                text+= "Quantity:" + currencyFormat(each.quantity) + "\n";
                text+= "Unit:" + each.unit + "\n";
                
                if(idx !== (products.length - 1) )   {
                    text+= "--------------------" + "\n";
                }
            })

            return text
        
    }
        
    renderAllTooltip(products, id, element) {
        let _this = this
        let {copied} = this.state;
        return (
            <React.Fragment>


                <Tooltip
                    // className="btn m-0 p-0"
                    interactive
                    arrow={true}
                    html={
                        <React.Fragment>
                            {
                                products.map((each, idx) => {
                                    return <React.Fragment>
                                        <div className="text-left">
                                            <b className='text-success' >{each.product_name}</b> <br />

                                            <b>Description: </b>{each.description} <br />
                                            <b>Quantity : </b> {currencyFormat(each.quantity)} <br />
                                            <b>Unit: </b>{each.unit} <br />

                                            {idx !== (products.length - 1) && <React.Fragment>

                                                ----------------------------- <br />
                                            </React.Fragment>}

                                        </div>
                                    </React.Fragment>
                                })
                            }


                        </React.Fragment>
                    }
                >
                    <label className="badge badge-danger mr-1">  <CopyToClipboard text={this.renderCopyToClipboardText(products, element)}
                        onCopy={() =>  {
                            copied[id] = true
                            _this.setState({ copied })
                            setTimeout(function(){
                                copied[id] = false
                                _this.setState({ copied })
                            }, 2000)
                        }}>
                        <span style={{cursor:"pointer"}}> {copied.hasOwnProperty(id) && copied[id] ? "Copied" : "Copy"}</span>
                    </CopyToClipboard></label>

                </Tooltip>


            </React.Fragment>
        )
    }
    renderProducts(products, id, element) {
        
            return (
                <React.Fragment>
                    {
                        products.map((each) => {
                            return <React.Fragment>
                                <Tooltip
                                    // className="btn m-0 p-0"
                                    interactive
                                    arrow={true}
                                    html={
                                        <React.Fragment>
                                            <div className="text-left">

                                                <b>Description: </b>{each.description} <br />
                                                <b>Quantity : </b> {currencyFormat(each.quantity)} <br />
                                                <b>Unit: </b>{each.unit} <br />
                                            </div>
                                        </React.Fragment>
                                    }
                                >
                                    <label className="badge badge-success mr-1">{each.product_name} - {' '}
                                        {each.sort_quote_for_requirement_product_wise.length}</label>
                                </Tooltip>
                            </React.Fragment>
                        })
                    }

                    {this.renderAllTooltip(products, id, element)}
                </React.Fragment>
            )
      
    }
    render() {
        const { page, serachField, errors, } = this.state;
        const { loading, requirements, productTag } = this.props

        return (
            <Card>
                <CardHeader>
                    <i className="fas fa-boxes" />
                    <strong>Requirements</strong>
                </CardHeader>
                <CardBody>
                    {loading ? <TableLoader /> :
                        (
                            <React.Fragment>
                                <div className="table-responsive-sm animated fadeIn">
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        <thead className="thead-light">
                                            <tr>
                                                <th width="5%">
                                                    <Input type="text" name="buyer_requirement_id" placeholder="#" onChange={this.handleChange} />
                                                </th>
                                                <th width="15%">
                                                    <DateRangePicker
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        //onApply={this.handleApply}
                                                        onEvent={(e, picker) => this.handleEvent(e, picker, 'sdate')}
                                                    //                                            ranges={this.state.ranges}
                                                    >
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="Select Date Range" value={serachField.startDateRange} id="date_picker" />
                                                            {/* <span className="input-group-btn">
                                                        <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                                            <i className="fa fa-calendar" />
                                                        </Button>
                                                    </span> */}
                                                        </div>
                                                    </DateRangePicker>
                                                </th>
                                                <th width="15%">
                                                    <DateRangePicker
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        //onApply={this.handleApply}
                                                        onEvent={(e, picker) => this.handleEvent(e, picker, 'edate')}
                                                    //                                            ranges={this.state.ranges}
                                                    >
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="Select Date Range" value={serachField.endDateRange} id="date_picker2" />
                                                            {/* <span className="input-group-btn">
                                                        <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                                            <i className="fa fa-calendar" />
                                                        </Button>
                                                    </span> */}
                                                        </div>
                                                    </DateRangePicker>
                                                </th>
                                                <th>
                                                    <select className="form-control" name="product_id" value={serachField.product_id} onChange={this.handleChange}>
                                                        <option>--- Select Product ---</option>
                                                        {
                                                            productTag && productTag.length &&
                                                            productTag.map((p) => {
                                                                return <option value={p.id}> {p.tag_name} </option>
                                                            })
                                                        }
                                                    </select>
                                                </th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th >
                                                    <select className="form-control" name="section" value={serachField.section} onChange={this.handleChange}>
                                                        <option value="active">Active</option>
                                                        <option value="all">All</option>
                                                        <option value="ended">Ended</option>
                                                    </select>
                                                </th>
                                                <th width="14%">
                                                    <Button color="primary" onClick={(e) => this.handleApply(e)} style={{ 'margin-left': '10px' }}>Fetch</Button>
                                                    <Button color="secondary" onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>RQ.ID</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Product(s)</th>
                                                <th>Location</th>
                                                <th>Delivered by seller</th>
                                                <th>Created At</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                requirements && requirements.length ?
                                                    requirements.map(element => {
                                                        return (
                                                            <tr>
                                                                <td>{element.id}</td>
                                                                <td>{moment(element.start_date).format('DD-MM-YYYY ')}</td>
                                                                <td>{moment(element.end_date).format('DD-MM-YYYY hh:mm A')}</td>
                                                                {/* <td>{element.company_name + ' (' + element.formatted_company_repute_id + ')'}</td> */}
                                                                {/* <td style={{width:'18%'}}>{element.buyer_requiryment_product_tag.map((each) => {
                                                            return <React.Fragment>
                                                                <Tooltip
                                                                    // className="btn m-0 p-0"
                                                                    interactive
                                                                    arrow={true}
                                                                    html={
                                                                        <React.Fragment>
                                                                            <div className="text-left">

                                                                                <b>Description: </b>{each.description} <br />
                                                                                <b>Quantity : </b> {currencyFormat(each.quantity)} <br />
                                                                                <b>Unit: </b>{each.unit} <br />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <label className="badge badge-success mr-1">{each.product_name} - {' '}
                                                                   {each.sort_quote_for_requirement_product_wise.length}</label>
                                                                </Tooltip>  </React.Fragment>
                                                        })}</td> */}
                                                                <td>{this.renderProducts(element.buyer_requiryment_product_tag, element.id, element)}</td>
                                                                <td>{element.delivery_location}<UpdateLocation getData={this.getData} requirement_details={element} /></td>
                                                                <td>{element.is_delivered_seller ? 'Yes' : 'No'}</td>
                                                                <td>{moment(element.created_at).format('DD-MM-YYYY')}</td>
                                                                <td>{moment(element.end_date) > moment() ? 'Active' : 'Ended'}</td>
                                                                <td>
                                                                    <InviteBuyerRequirement buyer_requirement_id={element.id} requirement_details={element} invite_count={element.buyer_requirement_invite.length} getData={this.getData} /> &nbsp;
                                                                    {/* <ViewQuotes requirements={element} /> */}
                                                                    <Selleres buyer_requirement_id={element.id} seller_count={element.seller_count} unregistered_seller_count={element.unregistered_seller_count} requirement_details={element} />
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="9" className="text-center">No requirements available</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {page.totalItemsCount <= page.perPage ? (
                                    ''
                                ) : (
                                    <Pagination
                                        activePage={page.activePage}
                                        itemsCountPerPage={page.perPage}
                                        totalItemsCount={page.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        linkClass="page-link"
                                        itemClass="page-item"
                                        onChange={this.handlePageChange}

                                    />
                                )}
                            </React.Fragment >
                        )}
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    const { loading, data } = state.getRequirementLogs

    return {
        requirements: data ? data.buyerRequirement : [],
        productTag: data ? data.productTag : [],
        data,
        loading
    }
}

const RequirementLogsConnected = connect(mapStateToProps)(RequirementLogs);
export { RequirementLogsConnected as RequirementLogs }
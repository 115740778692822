import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const productTagService = {
    getProductTags,
    addEditProductTags,
    deleteProductTags,
    addSuperadminInteraction,
    getUnregisteredSeller,
    addUnregisteredSeller,
    checkDuplicateUnregisteredSeller
}

function getProductTags(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_product_tags`, form_data, setting).then(handleResponse)
}

function addEditProductTags(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/add_edit_product_tags`, form_data, setting).then(handleResponse)
}

function deleteProductTags(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/delete_product_tags`, form_data, setting).then(handleResponse)
}

function addSuperadminInteraction(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/add_superadmin_interaction`, form_data, setting).then(handleResponse)
}

function getUnregisteredSeller(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_unregistered_seller`, form_data, setting).then(handleResponse)
}

function addUnregisteredSeller(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/add_unregistered_seller`, form_data, setting).then(handleResponse)
}

function checkDuplicateUnregisteredSeller(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/check_duplicate_unregistered_seller`, form_data, setting).then(handleResponse)
}
export const processConstants = {

    GET_PROCESS_DATA_REQUEST: 'GET_PROCESS_DATA_REQUEST',
    GET_PROCESS_DATA_SUCCESS: 'GET_PROCESS_DATA_SUCCESS',
    GET_PROCESS_DATA_FAILURE: 'GET_PROCESS_DATA_FAILURE',
    
    SAVE_PROCESS_DATA_REQUEST: 'SAVE_PROCESS_DATA_REQUEST',
    SAVE_PROCESS_DATA_SUCCESS: 'SAVE_PROCESS_DATA_SUCCESS',
    SAVE_PROCESS_DATA_FAILURE: 'SAVE_PROCESS_DATA_FAILURE',

}       
import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';


export const DataTable = ({data, columns, noDataIndication, keyField, className, rowClasses}) => {
  
    return <BootstrapTable
        keyField={keyField || 'id'}
        data={data}
        columns={columns}
        filter={filterFactory()}
        noDataIndication={noDataIndication}
        striped
        // hover
        // condensed
        responsive
        // width={window.innerWidth < 768 ? '0' : '20%'}
        bordered={false}
        bootstrap4={true}
        classes={"table-outline table-striped table-bordered table-sm "  + className}
        headerClasses={"thead-light"}
        rowClasses={rowClasses}
        wrapperClasses={'table-responsive-sm  animated fadeIn'}
        pagination={paginationFactory({
            paginationSize: 4,
            hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            sizePerPage:20,
            // firstPageText: <i class="fa  fa-angle-double-left"></i>,
            // prePageText: <i class="fa fa-angle-left"></i>,
            // nextPageText: <i class="fa fa-angle-right"></i>,
            // lastPageText:  <i class="fa fa-angle-double-right"></i>,
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: false,
        })} />
}

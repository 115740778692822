import { notify } from 'reapop'
import { voiceLogsConstants } from '../constants'
import { VoiceLogsService } from '../services'

export const VoiceLogsAction = {
    getVoiceLogData
}

function getVoiceLogData(data) {
    return dispatch => {
        dispatch(request())

        VoiceLogsService.getVoiceLogsData(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: voiceLogsConstants.GET_VOICE_LOG_DATA_REQUEST } }
    function success(result) { return { type: voiceLogsConstants.GET_VOICE_LOG_DATA_SUCCESS, result } }
    function failure(error) { return { type: voiceLogsConstants.GET_VOICE_LOG_DATA_FAILURE, error } }
}

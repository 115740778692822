import React from 'react';
import { Badge, Button } from 'reactstrap';

const CallDone = ({ status, _id, handleCalledButton }) => {
    return (
        status
            ?
            <Badge className='bg-success mx-1'><span className='fa fa-check-circle text-light'></span>{ } Call Done</Badge>
            :
            <Button color="primary" className=' btn-sm mx-1' onClick={() => handleCalledButton(_id)}>Call Done</Button>
    )
}

export default CallDone
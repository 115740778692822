import { notify } from 'reapop'
import { emailLogConstants } from '../constants'
import { emailLogService } from '../services'

export const emailLogsActions = {
    getEmailLogs
}

function getEmailLogs(data) {
    return dispatch => {
        dispatch(request())

        emailLogService.getEmailLogs(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailLogConstants.GET_EMAIL_LOGS_REQUEST } }
    function success(result) { return { type: emailLogConstants.GET_EMAIL_LOGS_SUCCESS, result } }
    function failure(error) { return { type: emailLogConstants.GET_EMAIL_LOGS_FAILURE, error } }
}
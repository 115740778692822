import {  axiosAjaxCall } from './common.service'
import {  showLoader } from '../../helpers';

export const companySectorService = {
    getCompanySectorData,
    addCompanySectorData,
    deleteCompanySectorData,
    updateCompanySectorData
}

function getCompanySectorData(data) {
    return axiosAjaxCall("get_company_sector_list", data);
}

function addCompanySectorData(data) {
    return axiosAjaxCall("add_company_sector", data);
}

function deleteCompanySectorData(data) {    
    showLoader();    
    return axiosAjaxCall("delete_company_sector", data);
}

function updateCompanySectorData(data) {
    return axiosAjaxCall("update_company_sector", data);
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {   Nav } from 'reactstrap';
import { NavLink } from "react-router-dom";
import PrivacyPolicy from "./Models/PrivacyPolicy.jsx";
import TermsOfuse from "./Models/TermsOfuse.jsx";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Footer extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
        <NavLink to="/" > Reputeinfo </NavLink>
         &copy; {(new Date().getFullYear())}  BinarySphere Online Services Pvt Ltd.</span>
        <Nav className="ml-auto" >
          <TermsOfuse/>
          <PrivacyPolicy/>
        </Nav>
      </React.Fragment>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import { show } from 'redux-modal';
import { Button } from 'reactstrap';
import { ViewTicketDetails } from './Tickets/ViewTicketDetails';
import { supportActions } from '../../actions';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { IconStatus } from './TicketsOld/components';

class TicketList extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.openModal = this.openModal.bind(this);
        this.renderSubject = this.renderSubject.bind(this);
        this.renderLastUpdate = this.renderLastUpdate.bind(this);
    }
    openModal(data) {
        const { dispatch } = this.props;
        dispatch(show("bootstrap", {
            ModelSize: "lg",
            ModelHeaderText: "Ticket Details : # " + data.id,
            ModelBodyText: <ViewTicketDetails {...this.props} ticket_id={data.id} />,
            isFooter:false
        }));

        dispatch(supportActions.getTicketDetails({ id: data.id }));
    }
    renderSubject(cell, row, rowIndex, formateExtraData) {
        return <Button color="link" size="link" onClick={() => this.openModal(row)}> {cell} </Button>
    }

    renderLastUpdate(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
             {moment(row.updated_at).format('DD-MM-YYYY hh:mm A')} (<TimeAgo date={row.updated_at} />)
        </React.Fragment>
    }
    renderID(cell, row, rowIndex, formateExtraData) {
        return '#' + row.id
    }
    renderStatus(cell, row, rowIndex, formateExtraData) {
        return  <IconStatus ticket_status_id={row.ticket_status_id}  status_name={row.ticket_status} />
    }
    render() {
        const { ticketList } = this.props
        const columns = [
            {
                text: '#',
                dataField: 'id',
                // formatter: this.renderID,
                sort: true
            },
            {
                text: 'Company ReputeID',
                dataField: 'company_formated_repute_id',
                sort: true
            },
            {
                text: 'Title',
                dataField: 'title',
                formatter: this.renderSubject,
                sort: true
            },
            {
                text: 'Status',
                dataField: 'ticket_status',
                sort: true,
                formatter: this.renderStatus,
                
            },
            {
                text: 'Last Update',
                dataField: 'updated_at',
                formatter: this.renderLastUpdate,
                sort: true

            }
        ]
        return (
            <div>
                <div className="">
                    <DataTable keyField="id"
                        data={ticketList || []} columns={columns}
                        filter={filterFactory()}
                        noDataIndication={"No data found"} />
                </div>
            </div>
        )
    }

}


function mapStateToProps(state) {
}

const connectedTicketList = connect(mapStateToProps)(TicketList)
export { connectedTicketList as TicketList }

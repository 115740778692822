import React, { Component, useState } from "react";
import { Card, CardHeader, Collapse, CardBody } from "reactstrap";
import ViewAccountDetailsData from "../../BusinessAccounts/Components/ViewAccountDetailsData";

export const DisplayBusinessDetails = (props) => {
  const [showPanel, togglePanel] = useState(false);

  return (
    <Card>
      <CardHeader onClick={() => togglePanel(!showPanel)}>
        <h5 style={{ marginBottom: 0 }}>
           Business Account and Individuals Details
          <i
            className={
              "pull-right " +
              (showPanel ? "fa fa-caret-down" : "fa fa-caret-right")
            }
          ></i>
        </h5>
      </CardHeader>
      <Collapse isOpen={showPanel}>
          <CardBody>
            <ViewAccountDetailsData {...props} />
          </CardBody>
      </Collapse>
    </Card>
  );
};

import { notify } from 'reapop'
import { emailConstants } from '../constants'
import { emailService } from '../services'

export const emailActions = {
    addEmail,
    getEmails,
    editEmail,
    deleteEmail,
    publishEmail,
    sendEmail
}

function addEmail(data) {
    return dispatch => {
        dispatch(request({ data }));

        emailService.addEmail(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Added Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.ADD_EMAIL_REQUEST } }
    function success(result) { return { type: emailConstants.ADD_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.ADD_EMAIL_FAILURE, error } }
}

function getEmails(data) {
    return dispatch => {
        dispatch(request())

        emailService.getEmails(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.GET_EMAILS_REQUEST } }
    function success(result) { return { type: emailConstants.GET_EMAILS_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.GET_EMAILS_FAILURE, error } }
}

function editEmail(data,ref) {
    return dispatch => {
        dispatch(request())

        emailService.editEmail(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Successfully', status: "success" }))
                    ref.afterSave()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.EDIT_EMAIL_REQUEST } }
    function success(result) { return { type: emailConstants.EDIT_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.EDIT_EMAIL_FAILURE, error } }
}

function deleteEmail(data) {
    return dispatch => {
        dispatch(request())

        emailService.deleteEmail(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Deleted Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.DELETE_EMAIL_REQUEST } }
    function success(result) { return { type: emailConstants.DELETE_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.DELETE_EMAIL_FAILURE, error } }
}

function publishEmail(data,ref) {
    return dispatch => {
        dispatch(request())

        emailService.publishEmail(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Published Successfully', status: "success" }))
                    ref.getEmails()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.PUBLISH_EMAIL_REQUEST } }
    function success(result) { return { type: emailConstants.PUBLISH_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.PUBLISH_EMAIL_FAILURE, error } }
}

function sendEmail(data,ref) {
    return dispatch => {
        dispatch(request())

        emailService.sendEmail(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Send Email Successfully', status: "success" }))
                    ref.handelHideModel()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: emailConstants.SEND_EMAIL_REQUEST } }
    function success(result) { return { type: emailConstants.SEND_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: emailConstants.SEND_EMAIL_FAILURE, error } }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Form,
	Input,
	InputGroup,
	FormFeedback,
	FormGroup
} from 'reactstrap';
import { salesAction } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
import { NotAllowAccess } from '../../../components/ErrorPages';
import {
	CustomerCallForm,
	CustomerResponseForm,
	CustomerResponseInterestedForm,
	ScheduleCustomerCallForm,
	CustomerAppointmentForm,
	CustomerMeetingForm
} from './Forms';
import { withRouter } from 'react-router';
import { SALES_STATUS } from '../../constants';
import { Link } from 'react-router-dom';
import { handleValidation } from './Validation';
// import { VerticalTimeline } from 'react-vertical-timeline-component';
import { RenderText, RenderSalesStatus } from './CommonComponents';
import { customerDetailAction } from '../../actions';
import swal from 'sweetalert';
import { show, hide } from 'redux-modal';
import { UpdateEmail } from './UpdateEmail';
import { UpdateMobile } from './UpdateMobileNumber/UpdateMobile';
import { UpdateCompanyName } from './UpdateCompanyName/UpdateCompanyName';
import { UpdateSector } from './UpdateSector';
import { UpdateCustomerName } from './UpdateCustomerName/UpdateCustomerName';
import { UpdateAddress } from './UpdateAddress/UpdateAddress';
import { UpdateCustomerPriority } from './UpdateCustomerPriority/UpdateCustomerPriority';
import { UpdateNote } from './UpdateNote';
import Dropdown from '../CustomerDetail/comman';

class UpdateSalesStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			customerData: [],
			userData: [],
			formData: {
				id: '',
				preferred_language: '',
				other_language: '',
				email: []
			},
			is_lan_btn_disable: true,
			errors: {}
		};

		this.getCustomerList = this.getCustomerList.bind(this);
		this.editData = this.editData.bind(this);
		this.renderAction = this.renderAction.bind(this);
		this.afterAction = this.afterAction.bind(this);
		this.afterActionAssign = this.afterActionAssign.bind(this);
		this.renderForms = this.renderForms.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handelSubmit = this.handelSubmit.bind(this);
		this.startDemo = this.startDemo.bind(this);
	}

	componentDidMount() {
		this.getCustomerList();
	}

	getCustomerList() {
		const { dispatch, match: { params } } = this.props;
		dispatch(salesAction.getCustomerSalesStatusData({ customer_id: params.customerId }));
	}

	editData(d) {
		this.props.history.push('/customer-detail/edit/' + d.id);
	}

	startDemo() {
		const { dispatch } = this.props;
		dispatch(salesAction.addDemoAppData());
	}

	afterAction() {
		let { is_lan_btn_disable } = this.state;
		is_lan_btn_disable = true;
		this.setState({ is_lan_btn_disable });
		this.getCustomerList();
	}

	afterActionAssign() {
		this.getCustomerList();
	}

	renderAction(cell, row, rowIndex, formateExtraData) {
		return (
			<React.Fragment>
				<Button size="sm" color="primary" onClick={() => this.editData(row)}>
					{' '}
					Edit{' '}
				</Button>{' '}
				{' '}
				<Button size="sm" color="danger" onClick={() => this.deleteData(row)}>
					{' '}
					Delete{' '}
				</Button>
			</React.Fragment>
		);
	}

	renderCustomerName(cell, row, rowIndex, formateExtraData) {
		return row.first_name + ' ' + row.last_name;
	}

	componentWillReceiveProps(props) {
		if (props.customerDetail) {
			let { formData } = this.state;
			formData.id = props.customerDetail.id;
			formData.preferred_language = props.customerDetail.preferred_language;
			formData.other_language = props.customerDetail.other_language;
			formData.email = props.customerDetail.email;
			this.setState({ formData });
		}
	}

	handleChange(e) {
		e.preventDefault();
		let { formData, is_lan_btn_disable } = this.state;
		const { name, value } = e.target;

		formData[name] = value;
		is_lan_btn_disable = false;
		if (name === 'preferred_language' && value !== 'Other') {
			formData['other_language'] = '';
		}
		this.setState({ formData, is_lan_btn_disable });

		if (name === 'preferred_language' && value !== 'Other') {
			this.handelSubmit();
		}
	}

	handelSubmit() {
		const { formData } = this.state;

		const result = handleValidation(formData);

		this.setState({ errors: result.errors });
		if (result.formIsValid) {
			const { dispatch } = this.props;
			swal({
				title: 'Are you sure ?',
				text: 'Do you want to change language?',
				icon: 'warning',
				buttons: true,
				dangerMode: true
			}).then((confirm) => {
				if (confirm) {
					dispatch(customerDetailAction.updateCustomerDetailLanguage(formData, this));
				}
			});
		}
	}

	renderForms() {
		const { data, match: { params } } = this.props;

		if (data && data.length) {
			return data.map(function(eachData, index) {
				let action_data = eachData.action_data ? JSON.parse(eachData.action_data) : {};

				switch (eachData.action_type) {
					case SALES_STATUS.SCHEDULE_CUSTOMER_CALL:
						return (
							<ScheduleCustomerCallForm
								customer_id={params.customerId}
								recordCounts={data.length}
								formData={eachData}
							/>
						);

					case SALES_STATUS.IS_CUSTOMER_CALL:
						if (parseInt(eachData.action_status, 10) === 1) {
							return (
								<React.Fragment>
									<CustomerCallForm
										customer_id={params.customerId}
										formData={eachData}
										recordCounts={data.length}
									/>
									{((data[index + 1] &&
										data[index + 1].action_type !== SALES_STATUS.CUSTOMER_CONTACT_RESPONSE) ||
										data[index + 1] === undefined) && (
										<CustomerResponseForm
											customer_id={params.customerId}
											recordCounts={data.length}
										/>
									)}
								</React.Fragment>
							);
						}
						return (
							<CustomerCallForm
								customer_id={params.customerId}
								formData={eachData}
								recordCounts={data.length}
							/>
						);

					case SALES_STATUS.CUSTOMER_CONTACT_RESPONSE:
						if (
							parseInt(eachData.action_status, 10) === 1 &&
							action_data.hasOwnProperty('schedule_status') &&
							parseInt(action_data.schedule_status, 10) === 0
						) {
							return (
								<React.Fragment>
									<CustomerResponseForm
										customer_id={params.customerId}
										formData={eachData}
										recordCounts={data.length}
									/>
									{((data[index + 1] &&
										data[index + 1].action_type !==
											SALES_STATUS.CUSTOMER_RESPONSE_INTERESTED_STATUS) ||
										data[index + 1] === undefined) && (
										<CustomerResponseInterestedForm
											customer_id={params.customerId}
											recordCounts={data.length}
										/>
									)}
								</React.Fragment>
							);
						}
						return (
							<CustomerResponseForm
								customer_id={params.customerId}
								formData={eachData}
								recordCounts={data.length}
							/>
						);

					case SALES_STATUS.CUSTOMER_RESPONSE_INTERESTED_STATUS:
						if (parseInt(eachData.action_status, 10) === 1) {
							return (
								<React.Fragment>
									<CustomerResponseInterestedForm
										customer_id={params.customerId}
										formData={eachData}
										recordCounts={data.length}
									/>
									{((data[index + 1] && data[index + 1].action_type !== SALES_STATUS.APPOINTMENT) ||
										data[index + 1] === undefined) && (
										<CustomerAppointmentForm
											customer_id={params.customerId}
											recordCounts={data.length}
										/>
									)}
								</React.Fragment>
							);
						}
						return (
							<CustomerResponseInterestedForm
								customer_id={params.customerId}
								formData={eachData}
								recordCounts={data.length}
							/>
						);

					case SALES_STATUS.APPOINTMENT:
						if (parseInt(eachData.action_status, 10) === 1 && eachData.action_status) {
							return (
								<React.Fragment>
									<CustomerAppointmentForm
										customer_id={params.customerId}
										formData={eachData}
										recordCounts={data.length}
									/>
									{((data[index + 1] && data[index + 1].action_type !== SALES_STATUS.MEETING) ||
										data[index + 1] === undefined) && (
										<CustomerMeetingForm
											customer_id={params.customerId}
											recordCounts={data.length}
										/>
									)}
								</React.Fragment>
							);
						}
						return (
							<CustomerAppointmentForm
								customer_id={params.customerId}
								formData={eachData}
								recordCounts={data.length}
							/>
						);

					case SALES_STATUS.MEETING:
						if (parseInt(eachData.action_status, 10) === 2) {
							return (
								<React.Fragment>
									<CustomerMeetingForm
										customer_id={params.customerId}
										formData={eachData}
										recordCounts={data.length}
									/>
									{((data[index + 1] && data[index + 1].action_type !== SALES_STATUS.MEETING) ||
										data[index + 1] === undefined) && (
										<CustomerMeetingForm
											customer_id={params.customerId}
											recordCounts={data.length}
										/>
									)}
								</React.Fragment>
							);
						}
						return (
							<CustomerMeetingForm
								customer_id={params.customerId}
								formData={eachData}
								recordCounts={data.length}
							/>
						);
					default:
						return false;
				}
			});
		}

		return <ScheduleCustomerCallForm customer_id={params.customerId} recordCounts={data ? data.length : 0} />;
	}

	updateEmails() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Emails',
				ModelBodyText: <UpdateEmail customerDetail={customerDetail} getCustomerList={this.getCustomerList} />,
				isFooter: false
			})
		);
	}

	updateMobile() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Mobiles',
				ModelBodyText: <UpdateMobile customerDetail={customerDetail} getCustomerList={this.getCustomerList} />,
				isFooter: false
			})
		);
	}

	updateCompanyName() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Company Name',
				ModelBodyText: (
					<UpdateCompanyName customerDetail={customerDetail} getCustomerList={this.getCustomerList} />
				),
				isFooter: false
			})
		);
	}

	updateSector() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Sector',
				ModelBodyText: <UpdateSector customerDetail={customerDetail} getCustomerList={this.getCustomerList} />,
				isFooter: false
			})
		);
	}

	updateCustomerName() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Customer Name',
				ModelBodyText: (
					<UpdateCustomerName customerDetail={customerDetail} getCustomerList={this.getCustomerList} />
				),
				isFooter: false
			})
		);
	}

	UpdateAddress() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Address',
				ModelBodyText: <UpdateAddress customerDetail={customerDetail} getCustomerList={this.getCustomerList} />,
				isFooter: false
			})
		);
	}

	UpdateNote() {
		const { dispatch, customerDetail } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Update Note',
				ModelBodyText: <UpdateNote customerDetail={customerDetail} getCustomerList={this.getCustomerList} />,
				isFooter: false
			})
		);
	}

	render() {
		const { loading, customerDetail } = this.props;
		const { match: { params } } = this.props;
		const { formData, errors, is_lan_btn_disable } = this.state;

		return (
			<React.Fragment>
				<Card>
					<CardHeader>
						<i className="fa fa-clock-o" />
						<strong> Customer Sales Status </strong>
						{/* <Button color="primary" size="xs" className="pull-right" onClick={this.goBack} > Back </Button> */}
						<Link className="btn btn-primary btn-xs pull-right" to={'/customer-sales-status'}>
							{' '}
							Back{' '}
						</Link>
					</CardHeader>
					{params.customerId ? (
						<React.Fragment>
							<CardBody className="bg-light">
								{loading ? (
									<TableLoader />
								) : customerDetail ? (
									<React.Fragment>
										<h6>Customer Detail</h6>
										<Row>
											<RenderText
												title={'Customer Name'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.hasOwnProperty('first_name') && (
														<React.Fragment>
															{customerDetail.first_name + ' ' + customerDetail.last_name}
															<span onClick={(e) => this.updateCustomerName()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
											<RenderText
												title={'Company Name'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.hasOwnProperty('company_name') && (
														<React.Fragment>
															{customerDetail.company_name}
															<span onClick={(e) => this.updateCompanyName()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
											<RenderText
												title={'Mobile Number'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.hasOwnProperty('mobile') && (
														<React.Fragment>
															{customerDetail.mobile.split(',').map(function(m) {
																return (
																	<span className="" key={m}>
																		{' '}
																		{m} &nbsp;
																	</span>
																);
															})}
															<span onClick={(e) => this.updateMobile()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
											<React.Fragment>
												<RenderText
													title={'Email ID'}
													l={loading}
													text={
														customerDetail &&
														customerDetail.hasOwnProperty('email') && (
															<React.Fragment>
																{customerDetail.email.split(',').map(function(m) {
																	return (
																		<span className="" key={m}>
																			{' '}
																			{m} &nbsp;
																		</span>
																	);
																})}{' '}
																<span onClick={(e) => this.updateEmails()}>
																	{' '}
																	<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
																</span>
															</React.Fragment>
														)
													}
												/>
											</React.Fragment>
											<RenderText
												title={'Address'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.address && (
														<React.Fragment>
															{customerDetail.address.line1 ? (
																customerDetail.address.line1 + ', '
															) : (
																''
															)}
															{customerDetail.address.line2 ? (
																customerDetail.address.line2 + ', '
															) : (
																''
															)}
															{customerDetail.address.city ? (
																customerDetail.address.city + ', '
															) : (
																''
															)}
															{customerDetail.address.taluka ? (
																customerDetail.address.taluka + ', '
															) : (
																''
															)}
															{customerDetail.address.pincode ? (
																customerDetail.address.pincode + ', '
															) : (
																''
															)}
															{customerDetail.address.district ? (
																customerDetail.address.district + ', '
															) : (
																''
															)}
															{customerDetail.address.state ? (
																customerDetail.address.state
															) : (
																''
															)}
															<span onClick={(e) => this.UpdateAddress()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
											<Col md="4" lg="3" sm="6">
												<strong>Preferred Language</strong> <br />
												<div className="row">
													<div className="col-md-5 pr-0">
														<select
															className="form-control"
															name="preferred_language"
															name="preferred_language"
															onChange={this.handleChange}
															value={formData.preferred_language}
														>
															<option value="0">--- Select Language ---</option>
															<option> English </option>
															<option> हि ंदी </option>
															<option> ગુજરતી </option>
															<option> Other </option>
														</select>
													</div>
													<div className="col-md-7 pl-0">
														{formData.preferred_language === 'Other' ? (
															<React.Fragment>
																<InputGroup>
																	<Input
																		placeholder="Preferred Language"
																		name="other_language"
																		onChange={this.handleChange}
																		value={formData.other_language}
																		maxLength="50"
																	/>&nbsp;&nbsp;
																	<button
																		className="btn btn-primary"
																		size="sm"
																		disabled={is_lan_btn_disable}
																		onClick={() => this.handelSubmit()}
																	>
																		{' '}
																		{loading ? 'Please wait...' : 'Save'}{' '}
																	</button>
																</InputGroup>
																{errors[`other_language`] && (
																	<FormFeedback>
																		{' '}
																		{errors[`other_language`]}
																	</FormFeedback>
																)}
															</React.Fragment>
														) : (
															''
														)}
													</div>
												</div>
											</Col>

											<RenderText
												title={'Sector'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.hasOwnProperty('sector') && (
														<React.Fragment>
															{customerDetail.sector}
															<span onClick={(e) => this.updateSector()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
											<RenderText
												title={'Note'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.hasOwnProperty('notes') && (
														<React.Fragment>
															{customerDetail.notes}
															<span onClick={(e) => this.UpdateNote()}>
																{' '}
																<i className="fas fa-edit  text-primary cursor-pointer" />{' '}
															</span>
														</React.Fragment>
													)
												}
											/>
										</Row>
										<h6 className="mt-1">Referrer Detail</h6>
										<Row>
											<RenderText
												title={'Referrer Person Name'}
												l={loading}
												text={customerDetail && customerDetail.referrer_name}
											/>
											<RenderText
												title={'Referrer Company Name'}
												l={loading}
												text={customerDetail && customerDetail.referrer_company_name}
											/>
											<RenderText
												title={'Referrer Mobile Number'}
												l={loading}
												text={customerDetail && customerDetail.referrer_mobile_number}
											/>
											<RenderText
												title={'Referrer  Reputeid'}
												l={loading}
												text={customerDetail && customerDetail.referrer_repute_id_and_name}
											/>
										</Row>
										<h6 className="mt-1">Sales Status</h6>
										<Row>
											<RenderText
												title={'Assign To'}
												l={loading}
												text={
													customerDetail &&
													customerDetail.assign_to_user && (
														<Dropdown
															user={this.props.userData}
															current={customerDetail}
															{...this.props}
															afterAction={this.afterActionAssign}
														/>
													)
												}
											/>
											<RenderText
												size="4"
												title={'Current Status'}
												l={loading}
												text={
													customerDetail && (
														<RenderSalesStatus status={customerDetail.status} />
													)
												}
											/>
											<RenderText
												size="4"
												title={'Priority'}
												l={loading}
												text={
													customerDetail && (
														<React.Fragment>
															<UpdateCustomerPriority
																customerDetail={customerDetail}
																getCustomerList={this.getCustomerList}
															/>
														</React.Fragment>
													)
												}
											/>
											<RenderText
												size="4"
												title={'Action'}
												l={loading}
												text={
													<React.Fragment>
														<Button size="sm" color="primary" onClick={this.startDemo}>
															{' '}
															Reset & Start Demo{' '}
														</Button>
													</React.Fragment>
												}
											/>
										</Row>
									</React.Fragment>
								) : (
									<NotAllowAccess />
								)}
							</CardBody>
						</React.Fragment>
					) : (
						''
					)}
				</Card>

				{params.customerId && customerDetail ? (
					<React.Fragment>
						{loading ? (
							<TableLoader />
						) : (
							// <VerticalTimeline layout={'one-column'}>{this.renderForms()}</VerticalTimeline>
							false
						)}
					</React.Fragment>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data, customerDetail, userData } = state.getCustomerStatusData;
	return {
		loading,
		data,
		customerDetail,
		userData
	};
}

const connectedUpdateSalesStatus = withRouter(connect(mapStateToProps)(UpdateSalesStatus));
export { connectedUpdateSalesStatus as UpdateSalesStatus };

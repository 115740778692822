export const FAQConstants = {
    ADD_FAQ_REQUEST: 'ADD_FAQ_REQUEST',
    ADD_FAQ_SUCCESS: 'ADD_FAQ_SUCCESS',
    ADD_FAQ_FAILURE: 'ADD_FAQ_FAILURE',

    GET_FAQ_REQUEST: 'GET_FAQ_REQUEST',
    GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
    GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',

    EDIT_FAQ_REQUEST: 'EDIT_FAQ_REQUEST',
    EDIT_FAQ_SUCCESS: 'EDIT_FAQ_SUCCESS',
    EDIT_FAQ_FAILURE: 'EDIT_FAQ_FAILURE',

    DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
    DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
    DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',
}
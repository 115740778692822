import { axiosAjaxCall } from "./common.service";
import { showLoader } from "../../helpers";

export const communicationMastersService = {
    saveCommunicationMasters,
    getCommunicationMasters,
}

function saveCommunicationMasters(data) {  
    showLoader()  
    return axiosAjaxCall("save_communication_master", data);
}

function getCommunicationMasters(data) {    
    return axiosAjaxCall("get_communication_master", data);
}


import React from "react";
import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { DetailList} from "./DetailList";
import { Inquiries } from "./Inquiries";

function Leads() {
  const [ActiveTab, SetActiveTab] = useState("Leads");

  const TABS = {
    Leads: "Leads",
    Inquiries: "Inquiries",
  };

  let toggle = (tab) => {
    SetActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              {" "}
              <i className="fa fa-comment"></i> <b>Leads</b>
            </CardHeader>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: ActiveTab === TABS.Leads,
                    })}
                    onClick={() => toggle(TABS.Leads)}
                  >
                    <span style={{ fontWeight: "bold" }}>Leads</span>
                  </NavLink>
                </NavItem>

                <NavItem className="">
                  <NavLink
                    className={classnames({
                      active: ActiveTab === TABS.Inquiries,
                    })}
                    onClick={() => {
                      toggle(TABS.Inquiries);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Inquiries</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={ActiveTab}>
                <TabPane tabId={TABS.Leads}>
                  <DetailList />
                </TabPane>
                <TabPane tabId={TABS.Inquiries}>
                  <Inquiries />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export { Leads };

import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Row, Col, Button, Form, FormFeedback, Input, Card, CardBody, CardHeader, InputGroup, InputGroupAddon } from 'reactstrap'
import { handleValidation } from '../FAQ/Validation'
import { landingPageActions } from '../../actions';
import { Link } from "react-router-dom";
import { editorConfig } from '../../../components/Editor';
import { TableLoader } from '../../../components/Loaders';
import { handleResponse } from '../../services'
import { authHeader, showLoader } from '../../../helpers'
import config from 'react-global-configuration';
import axios from 'axios';

function LandingPageAdd(props) {


    const [formdata, setFormData] = useState({
        title: '',
        description: '',
        landing_page_video: '',
        suggestion_video_1: '',
        suggestion_video_2: '',
        suggestion_video_3: '',
        id: '',
        landing_page_video_title: '',
        suggestion_video_title_1: '',
        suggestion_video_title_2: '',
        suggestion_video_title_3: ''
    })
    const [banner_image_1, setBanner_image_1] = useState('')
    const [banner_image_2, setBanner_image_2] = useState('')
    const [banner_image_3, setBanner_image_3] = useState('')
    const [mobile_banner_image_1, setMobile_banner_image_1] = useState('')
    const [mobile_banner_image_2, setMobile_banner_image_2] = useState('')
    const [mobile_banner_image_3, setMobile_banner_image_3] = useState('')

    const [errors, setErrors] = useState({})

    const loading = false

    const handleChange = (e) => {
        const { name, value } = e.target
        formdata[name] = value

        setFormData({
            ...formdata
        })
    }

    const handleChangeHTML = (html) => {
        setFormData({
            ...formdata, description: html
        })
    }

    const onSubmit = () => {
        const { dispatch } = props

        let dataToSend = {
            ...formdata,
            banner_image_1: banner_image_1,
            banner_image_2: banner_image_2,
            banner_image_3: banner_image_3,
            mobile_banner_image_1: mobile_banner_image_1,
            mobile_banner_image_2: mobile_banner_image_2,
            mobile_banner_image_3: mobile_banner_image_3,
        }

        dispatch(landingPageActions.sendLandingPageData(dataToSend, goBack))

        // showLoader();    

        // var form_data = new FormData()
        // let setting = {
        //     headers: authHeader()
        // }

        // for (var key in dataToSend) {
        //     form_data.append(key, dataToSend[key]);
        // }
        // return axios.post(`${config.get('apiUrl')}/${`save_landing_page_template`}`, form_data, setting).then(handleResponse)


    }

    const goBack = () => {
        props.history.goBack();
    }

    useEffect(() => {
        getEditData()
    }, []);

    const getEditData = () => {
        const { match: { params: { action } }, dispatch } = props

        if (action !== 'add') {
            const dataToSend = {
                id: action
            }
            dispatch(landingPageActions.getLandingPageData(dataToSend))
        }
    }

    const handleEditData = (data) => {
        const { banner_image_1, banner_image_2, banner_image_3,mobile_banner_image_1,mobile_banner_image_2,mobile_banner_image_3, created_at, created_by_user_id, description, id, landing_page_video, status, suggestion_video_1, suggestion_video_2, suggestion_video_3, title, landing_page_video_title, suggestion_video_title_1, suggestion_video_title_2, suggestion_video_title_3 } = data

        setFormData({
            ...formdata,
            banner_image_1: (banner_image_1 ? banner_image_1 : ''),
            banner_image_2: (banner_image_2 ? banner_image_2 : ""),
            banner_image_3: (banner_image_3 ? banner_image_3 : ""),
            mobile_banner_image_1: (mobile_banner_image_1 ? mobile_banner_image_1 : ""),
            mobile_banner_image_2: (mobile_banner_image_2 ? mobile_banner_image_2 : ""),
            mobile_banner_image_3: (mobile_banner_image_3 ? mobile_banner_image_3 : ""),
            landing_page_video: (landing_page_video ? landing_page_video : ""),
            suggestion_video_1: (suggestion_video_1 ? suggestion_video_1 : ""),
            suggestion_video_2: (suggestion_video_2 ? suggestion_video_2 : ""),
            suggestion_video_3: (suggestion_video_3 ? suggestion_video_3 : ""),
            landing_page_video_title: (landing_page_video_title ? landing_page_video_title : ""),
            suggestion_video_title_1: (suggestion_video_title_1 ? suggestion_video_title_1 : ""),
            suggestion_video_title_2: (suggestion_video_title_2 ? suggestion_video_title_2 : ""),
            suggestion_video_title_3: (suggestion_video_title_3 ? suggestion_video_title_3 : ""),
            description: (description ? description : ""),
            title: (title ? title : ""),
            id: id
        })
    }

    useEffect(() => {
        const { match: { params: { action } }, data } = props
        const { id } = formdata
        if (data && action !== 'add' && action == data.id && data.id !== id && action !== id) {
            handleEditData(data)
        }
    }, [props]);


    const handleFileChange = (event) => {

        const file = event.target.files[0];
        const name = event.target.name
        switch (name) {
            case 'banner_image_1':
                setBanner_image_1(file)
                break;
            case 'banner_image_2':
                setBanner_image_2(file)
                break;
            case 'banner_image_3':
                setBanner_image_3(file)
                break;
            case 'Mobile banner_image_1':
                setMobile_banner_image_1(file)
                break;
            case 'Mobile banner_image_2':
                setMobile_banner_image_2(file)
                break;
            case 'Mobile banner_image_3':
                setMobile_banner_image_3(file)
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <i className="fas fa-ad	"></i><strong>Landing Page</strong>
                            <Button color="primary" className='pull-right btn-sm' onClick={goBack} >Back</Button>
                        </CardHeader>
                        <CardBody>
                            {loading ? <TableLoader /> :
                                <Form>
                                      <Row>
                                        <Col xs="12">
                                            <label><b>Banner Image 1 (Note : Size 1920*640)</b></label>
                                            <Input placeholder="Banner Images 1" type='file' name={`banner_image_1`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_1`] && errors[`banner_image_1`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Banner Image 2  (Note : Size 1920*640)</b></label>
                                            <Input placeholder="Banner Images 2" type='file' name={`banner_image_2`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_2`] && errors[`banner_image_2`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Banner Image 3  (Note : Size 1920*640)</b></label>
                                            <Input placeholder="Banner Images 3" type='file' name={`banner_image_3`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_3`] && errors[`banner_image_3`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    {/*  */}
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Mobile Banner Image 1 (Note : Size 375*400) </b></label>
                                            <Input placeholder="Mobile Banner Images 1" type='file' name={`Mobile banner_image_1`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_1`] && errors[`banner_image_1`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Mobile Banner Image 2 (Note : Size 375*400)</b></label>
                                            <Input placeholder="Mobile Banner Images 2" type='file' name={`Mobile banner_image_2`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_2`] && errors[`banner_image_2`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Mobile Banner Image 3 (Note : Size 375*400)</b></label>
                                            <Input placeholder="Mobile Banner Images 3" type='file' name={`Mobile banner_image_3`} onChange={handleFileChange} />
                                            <FormFeedback>
                                                {errors[`banner_image_3`] && errors[`banner_image_3`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    {/*  */}
                                    <Row>
                                        <Col xs="6">
                                            <label><b>Feature Video</b></label>
                                            <Input placeholder="Feature video You tube url..." name="landing_page_video" value={formdata.landing_page_video} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`landing_page_video`] && errors[`landing_page_video`]}
                                            </FormFeedback>
                                        </Col>
                                        <Col xs="6">
                                            <label><b>Title</b></label>
                                            <Input placeholder="Title" name="landing_page_video_title" value={formdata.landing_page_video_title} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`landing_page_video_title`] && errors[`landing_page_video_title`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="6">
                                            <label><b>Suggestion Video 1</b></label>
                                            <Input placeholder="Suggestion video 1 You tube url..." name="suggestion_video_1" value={formdata.suggestion_video_1} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_1`] && errors[`suggestion_video_1`]}
                                            </FormFeedback>
                                        </Col>
                                        <Col xs="6">
                                            <label><b>Title</b></label>
                                            <Input placeholder="Title" name="suggestion_video_title_1" value={formdata.suggestion_video_title_1} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_title_1`] && errors[`suggestion_video_title_1`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="6">
                                            <label><b>Suggestion Video 2</b></label>
                                            <Input placeholder="Suggestion video 2 You tube url..." name="suggestion_video_2" value={formdata.suggestion_video_2} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_2`] && errors[`suggestion_video_2`]}
                                            </FormFeedback>
                                        </Col>
                                        <Col xs="6">
                                            <label><b>Title</b></label>
                                            <Input placeholder="Title" name="suggestion_video_title_2" value={formdata.suggestion_video_title_2} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_title_2`] && errors[`suggestion_video_title_2`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="6">
                                            <label><b>Suggestion Video 3</b></label>
                                            <Input placeholder="Suggestion video 3 You tube url..." name="suggestion_video_3" value={formdata.suggestion_video_3} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_3`] && errors[`suggestion_video_3`]}
                                            </FormFeedback>
                                        </Col>
                                        <Col xs="6">
                                            <label><b>Title</b></label>
                                            <Input placeholder="Title" name="suggestion_video_title_3" value={formdata.suggestion_video_title_3} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`suggestion_video_title_3`] && errors[`suggestion_video_title_3`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <label><b>Title</b></label>
                                            <Input placeholder="Title" name="title" value={formdata.title} onChange={handleChange} />
                                            <FormFeedback>
                                                {errors[`title`] && errors[`title`]}
                                            </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12" style={{ display: "flex", flexDirection: 'column' }} >
                                            <label><b>Description</b></label>
                                            {/* <ReactQuill
                                                theme={theme}
                                                onChange={handleChangeHTML}
                                                value={formdata.description}
                                                modules={editorConfig.modules}
                                                formats={editorConfig.formats}
                                                bounds={'.app'}
                                                placeholder={"Description"} /> */}
                                            <Input type='textarea' name="description" onChange={handleChange} placeholder={"Description....."} id="description" value={formdata.description} cols="40" rows="10" />
                                            <FormFeedback> {errors[`description`] && errors[`description`]} </FormFeedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="12">
                                            <Button color="primary" onClick={onSubmit}> Save </Button> &nbsp;
                                            <Button onClick={goBack}> Cancel </Button> &nbsp;
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

function mapStateToProps(state) {

    const { loading, data } = state.getLandingPageData
    // editLandingPageData
    return {
        loading,
        data
    }
}

const connectedLandingPageAdd = connect(mapStateToProps)(LandingPageAdd)
export { connectedLandingPageAdd as LandingPageAdd }


import React, { Component } from "react";
import { profileActions } from "./../../actions";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback,InputGroup, InputGroupAddon, InputGroupText 
} from "reactstrap";
import FormHelpText from '../../../components/Messages/FormHelpText';

export default class UpdateBankDetail extends Component {
    constructor(props) {
        super(props);
        let days = new Date().getDate() - 1;
        this.state = {
            modalOpan: false,
            data: {
                bank_name: "",
                account_holder_name: "",
                ifsc_code: "",
                account_number: "",
                account_type: "",
            },
            errors: {}
        };
        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.proceed = this.proceed.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.setEditData = this.setEditData.bind(this);
    }

    showModal() {
        const { modalOpan } = this.state;
        this.setState({ modalOpan: !modalOpan });
        this.reset();
        this.setEditData();
        if(modalOpan == true) {
        }
    }

    reset() {
        this.setState({ data: {
            bank_name: "",
            account_holder_name: "",
            ifsc_code: "",
            account_number: "",
            account_type: "",
        },})
    }

    setPaymentType(event) {
        const { data } = this.state;
        data["paymentType"] = parseInt(event.target.value, 10);
        this.setState({ data });
    }

    handleChange(event) {
        const { name, value, type } = event.target;

        let { data } = this.state;
        let val = value;

        if (name === "paymentType" ) {
            val = Number(value);
        }
        if ( name === "bank_name" || name === "account_holder_name" ) {
            let regex_allowed = /^(?![\s-])[a-zA-Z ]*$/;
            if (value.length && regex_allowed.test(value) === false) {
              return false;
            }
        }
        if (name === "account_number" ) {
            let regex_allowed = /^[0-9]+$/;
            if (value.length && regex_allowed.test(value) === false) {
              return false;
            }
        }
        data[name] = val;
        this.setState({ data });
    }

    isValidData() {
        const { data, errors } = this.state
        let error = false
            if (data.account_holder_name === "") {
              errors.account_holder_name = "this field is required";
              error = true;
            } else if (data.account_holder_name.length > 30) {
              errors.account_holder_name =
                "bank name max length should be 30 characters";
                error = true;
            }
      
            if (data.bank_name === "") {
              errors.bank_name = "this field is required";
              error = true;
            } else if (data.bank_name.length > 30) {
              errors.bank_name = "bank name max length should be 30 characters";
              error = true;
            }
      
            
            if (data.account_number === "") {
              errors.account_number = "this field is required";
              error = true;
            } else if (
              data.account_number.length < 9 ||
              data.account_number.length > 20
            ) {
              errors.account_number =
                "account number length should be 9 to 20 digits";
                error = true;
            }

            if (data.ifsc_code === "") {
                errors.ifsc_code = "this field is required";
                error = true;
              }
              if (data.account_type === "") {
                errors.account_type = "this field is required";
                error = true;
              }
          
        this.setState({ errors })
        return error
    }
    proceed() {
        const { data } = this.state;
        const { dispatch, element, getData } = this.props;

        if (!this.isValidData()) {
            swal({
                title: "Are you sure ?",
                text: "Do you want to add bank details ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    let dataTosend = {
                        user_id : element.id,
                        bank_name : data.bank_name,
                        account_holder_name	 : data.account_holder_name,
                        account_number : data.account_number,
                        account_type : data.account_type,
                        ifsc_code : data.ifsc_code,
                    }

                    console.log('dataTosend',dataTosend)
                    dispatch(
                        profileActions.updateBankDetail(dataTosend, {
                            success: () => {
                                this.showModal()
                                getData()
                            }
                        })
                    );

                }
            });
        }
    }
    setEditData() {
        const { data } = this.state
        const { element } = this.props
        if(element && element.bank_account_details) {
            data.bank_name = element.bank_account_details.bank_name
            data.account_holder_name = element.bank_account_details.account_holder_name
            data.account_number = element.bank_account_details.account_number
            data.account_type = element.bank_account_details.account_type
            data.ifsc_code = element.bank_account_details.ifsc_code
            this.setState({ data })
        }
    }
    componentDidMount() {
            this.setEditData()

    }
    render() {
        const { modalOpan, data, errors } = this.state;
        const { element } = this.props;
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
          };
        let _this = this;
        return (
            <div>


                    <Button color="success" onClick={_this.showModal} >
                        { element && element.bank_account_details ? 'Update Bank Details' : 'Add Bank Details' } 
                    </Button>
                <Modal className="modal-success" isOpen={modalOpan}>
                    <ModalHeader toggle={_this.showModal}>Bank Details<br />{" "}
                        {/* <small>Add remaining negotiated offer</small> */}
                    </ModalHeader>
                    <ModalBody>
                        
                        
                              <React.Fragment>
                                
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                        <i className="fa fa-user"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      name="account_holder_name"
                                      maxLength="30"
                                      value={data.account_holder_name}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      type="text"
                                      placeholder="Account Holder Name"
                                    />
                                  </InputGroup>
                                  {errors.account_holder_name && (
                                    <FormFeedback>
                                      {errors.account_holder_name}
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                        <i className="fa fa-bank"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      name="bank_name"
                                      maxLength="30"
                                      value={data.bank_name}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      type="text"
                                      placeholder="Bank Name"
                                    />
                                  </InputGroup>
                                  {errors.bank_name && (
                                    <FormFeedback>
                                      {errors.bank_name}
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                        <i className="fas fa-money-check"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      name="ifsc_code"
                                     // maxLength="6"
                                      value={data.ifsc_code}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      type="text"
                                      placeholder="IFSC Code"
                                    />
                                  </InputGroup>
                                  {errors.ifsc_code && (
                                    <FormFeedback>
                                      {errors.ifsc_code}
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                        <i className="fa fa-building-o"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      name="account_number"
                                      value={data.account_number}
                                      maxLength="20"
                                      onChange={this.handleChange}
                                      className="form-control"
                                      type="text"
                                      placeholder="Account Number"
                                    />
                                  </InputGroup>
                                  {errors.account_number && (
                                    <FormFeedback>
                                      {errors.account_number}
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className={" form-group mb-3"}>
                                    <select className="form-control" name="account_type" onChange={this.handleChange} value={data.account_type} >
                                        <option value="0">--- Select Type ---</option>
                                        <option value="1"> Saving </option>
                                        <option value="2"> Current </option>
                                    </select>
                                    {errors.account_type && (
                                    <FormFeedback>
                                      {errors.account_type}
                                    </FormFeedback>
                                  )}
                                </div>
                              </React.Fragment>
                        
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.proceed}>
                            Proceed
                        </Button>
                        <Button onClick={_this.showModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

import React, { Component } from "react";
import { profileActions } from "../../actions";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback,InputGroup, InputGroupAddon, InputGroupText 
} from "reactstrap";
import FormHelpText from '../../../components/Messages/FormHelpText';

export default class UpdateBankUPI extends Component {
    constructor(props) {
        super(props);
        let days = new Date().getDate() - 1;
        this.state = {
            modalOpan: false,
            data: {
                upi: "",
            },
            errors: {}
        };
        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.proceed = this.proceed.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.setEditData = this.setEditData.bind(this);
    }

    showModal() {
        const { modalOpan } = this.state;
        this.setState({ modalOpan: !modalOpan });
        this.reset();
        this.setEditData();
        if(modalOpan == true) {
        }
    }

    reset() {
        this.setState({ data: {
            upi: ""
        },})
    }

    setPaymentType(event) {
        const { data } = this.state;
        data["paymentType"] = parseInt(event.target.value, 10);
        this.setState({ data });
    }

    handleChange(event) {
        const { name, value, type } = event.target;

        let { data } = this.state;
        let val = value;

        // if ( name === "upi" ) {
        //     let regex_allowed = /^(?![\s-])[a-zA-Z ]*$/;
        //     if (value.length && regex_allowed.test(value) === false) {
        //       return false;
        //     }
        // }

        data[name] = val;
        this.setState({ data });
    }

    isValidData() {
        const { data, errors } = this.state
        let error = false
            if (data.upi === "") {
              errors.upi = "this field is required";
              error = true;
            }  
        this.setState({ errors })
        return error
    }
    proceed() {
        const { data } = this.state;
        const { dispatch, element, getData } = this.props;

        if (!this.isValidData()) {
            swal({
                title: "Are you sure ?",
                text: "Do you want to add bank UPI ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    let dataTosend = {
                        user_id : element.id,
                        upi : data.upi,
                    }

                    console.log('dataTosend',dataTosend)
                    dispatch(
                        profileActions.updateBankDetail(dataTosend, {
                            success: () => {
                                this.showModal()
                                getData()
                            }
                        })
                    );

                }
            });
        }
    }
    setEditData() {
        const { data } = this.state
        const { element } = this.props
        if(element && element.bank_account_details) {
            data.upi = element.bank_account_details.upi
            this.setState({ data })
        }
    }
    componentDidMount() {
            this.setEditData()

    }
    render() {
        const { modalOpan, data, errors } = this.state;
        const { element } = this.props;
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
          };
        let _this = this;
        return (
            <div>


                    <Button color="success" onClick={_this.showModal} >
                        { element && element.bank_account_details && element.bank_account_details.upi ? 'Update Bank UPI' : 'Add Bank UPI' } 
                    </Button>
                <Modal className="modal-success" isOpen={modalOpan}>
                    <ModalHeader toggle={_this.showModal}>Bank UPI<br />{" "}
                        {/* <small>Add remaining negotiated offer</small> */}
                    </ModalHeader>
                    <ModalBody>
                        
                        
                              <React.Fragment>
                                
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                      <i class="fab fa-amazon-pay"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      name="upi"
                                      maxLength="30"
                                      value={data.upi}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      type="text"
                                      placeholder="UPI"
                                    />
                                  </InputGroup>
                                  {errors.upi && (
                                    <FormFeedback>
                                      {errors.upi}
                                    </FormFeedback>
                                  )}
                                </div>
                              </React.Fragment>
                        
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.proceed}>
                            Proceed
                        </Button>
                        <Button onClick={_this.showModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

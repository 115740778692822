import { UserActivityConstants } from "../constants"


export function getUserActivity(state = {}, action) {
    switch (action.type) {
        case UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_REQUEST:
            return { loading: true }
        case UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_SUCCESS:
            return { data: action.result }
        case UserActivityConstants.GET_USER_ACTIVITY_DASHBOARD_FAILURE:
            return {}
        default:
            return state
    }
}
import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const notificationService = {
    addNotification,
    getNotifications,
    editNotification,
    deleteNotification,
    publishNotification
}

function addNotification(data) {
    var form_data = new FormData()

    form_data.append("current_password", data.oldPassword)
    form_data.append("new_password", data.newPassword)
    form_data.append("new_password_confirmation", data.confirmPassword)

    return axios.post(`${config.get('apiUrl')}/addNotification`, form_data).then(handleResponse)
}

function getNotifications(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_notification_detail`, form_data, setting).then(handleResponse)
}

function editNotification(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update_notification_detail`, form_data, setting).then(handleResponse)
}

function deleteNotification() {
    return axios.post(`${config.get('apiUrl')}/deleteNotification`).then(handleResponse)
}

function publishNotification(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/publish_notification`, form_data, setting).then(handleResponse)
}
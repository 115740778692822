import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Collapse } from 'reactstrap';
import { Link } from "react-router-dom"
import { salesAction } from '../../../actions/sales.action';
import { SALES_STATUS } from '../../../constants';
import swal from 'sweetalert'

import { TimelineStructure, NoteText, NotesInput } from './CommonComponents';


class CustomerResponseInterestedForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                action_status: -1,
                action_note: ""
            },
            errors: []
        }

        this.afterAction = this.afterAction.bind(this)
        this.setStatus = this.setStatus.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validation = this.validation.bind(this)
    }


    handleChange(event) {
        const { name, value } = event.target;
        const { formData } = this.state;
        formData[name] = value
        this.setState({ formData });
    }


    afterAction() {
        const { dispatch, customer_id } = this.props;
        dispatch(salesAction.getCustomerSalesStatusData({ customer_id: customer_id }));
    }

    handleSubmit(e) {
        e.preventDefault();
        const { formData, cs_id } = this.state;
        const { dispatch, customer_id, recordCounts } = this.props;
        let action_data = "";

        if(this.validation()) {
            return false
        }

        let dataToSend = {
            action_data: action_data,
            action_status: formData.action_status,
            action_type: SALES_STATUS.CUSTOMER_RESPONSE_INTERESTED_STATUS,
            action_note: formData.action_note,
            customer_id: customer_id,
            recordCounts: recordCounts
        };
        if (cs_id) {
            dataToSend.cs_id = cs_id
        }
        let _this = this
        swal({
            title: "Are you sure ?",
            text: "Do you want to update status?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(salesAction.updateCustomerSalesStatus(dataToSend, _this));
            }
        })
    }

    renderCustomerName(cell, row, rowIndex, formateExtraData) {
        return row.first_name + " " + row.last_name
    }

    setStatus(event) {
        const { formData } = this.state;
        formData[event.target.name] = parseInt(event.target.value, 10)
        this.setState({ formData })
    }

    validation() {
        const { formData, errors } = this.state;
        let error = false;
        if(formData.action_status === 0) {
            if(formData.action_note.trim() === "") {
                errors["action_note"] = "Please enter note.";
                error = true;
            }
        }
        this.setState({ errors })
        return error;
    }

    componentDidMount() {
        const { formData } = this.props;

        if (formData) {
            this.setState({
                formData: {
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                },
                oldFormData: {
                    ...formData,
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                },
                cs_id: formData.id
            })
        }

    }
    render() {
        const { loading } = this.props
        const { formData, errors, oldFormData } = this.state

        let iconStyle = {
            background: '#FFBC34', color: '#fff'
        }
        if (oldFormData && parseInt(oldFormData.action_status, 10) === 1) {
            iconStyle = {
                background: '#5AC146', color: '#fff'
            }
        }
        if (oldFormData && parseInt(oldFormData.action_status, 10) === 0) {
            iconStyle = {
                background: '#FA5838', color: '#fff'
            }
        }

        return (
            <React.Fragment>
                <TimelineStructure
                    title={"Is Customer Interested ?"}
                    iconStyle={iconStyle}
                    icon={<i className="fas fa-thumbs-up"></i>}
                    oldFormData={oldFormData}
                >
                    <Form  autocomplete="off" >
                        {oldFormData && parseInt(oldFormData.action_status, 10) === 1 &&
                            <React.Fragment>
                                Yes, Customer is Interested.

                                <NoteText action_note={formData.action_note} />
                            </React.Fragment>
                        }
                        {oldFormData && parseInt(oldFormData.action_status, 10) === 0 &&
                            <React.Fragment>
                                Customer is not interested.

                                <NoteText action_note={formData.action_note} />
                            </React.Fragment>
                        }

                        {!oldFormData &&
                            <React.Fragment>

                                <FormGroup >
                                    <div onChange={this.setStatus.bind(this)}>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="is_call_no" name="action_status" value="0" class="custom-control-input" checked={formData.action_status === 0} />
                                            <label class="custom-control-label" for="is_call_no" >Not interested</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="is_call_yes" name="action_status" value="1" class="custom-control-input" checked={formData.action_status === 1} />
                                            <label class="custom-control-label" for="is_call_yes" >Yes interested</label>
                                        </div>
                                    </div>
                                </FormGroup>

                                {/* <Collapse isOpen={formData.action_status === 0}> */}
                                    <NotesInput action_note={formData.action_note} handleChange={this.handleChange} errors={errors} />
                                {/* </Collapse> */}

                                <FormGroup>
                                    <Button color="primary" onClick={this.handleSubmit} disabled={loading || parseInt(formData.action_status, 10) === -1}>{loading ? "Please wait..." : "Update"}</Button> {" "}
                                    <Link className="btn btn-secondary" to={"/customer-sales-status"}> Cancel </Link>
                                </FormGroup>

                            </React.Fragment>}
                    </Form>
                </TimelineStructure>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.updateCustomerSalesStatus
    return {
        loading,
        data
    }
}

const connectedCustomerResponseInterestedForm = connect(mapStateToProps)(CustomerResponseInterestedForm)
export { connectedCustomerResponseInterestedForm as CustomerResponseInterestedForm }
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalBody, ModalHeader, ModalFooter, Button, Modal, Card, CardHeader, CardBody, ListGroup, ListGroupItem } from 'reactstrap'
import { TableLoader } from '../../../../components/Loaders';
import { InputText, InputDropDown } from '../../Settings/StageSetting/Components/FormElements';
import { userManagementActions } from '../../../actions';


const defaultData = {
    name : "",
    department_id : 0
}
class Departments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show : false,
            data : {...defaultData},
        }
        this.toggle = this.toggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.reset = this.reset.bind(this)
        this.edit = this.edit.bind(this)
        this.getData = this.getData.bind(this)
    }

    reset(){

    }

    
  handleChange(event) {
    let  _dt = event.target
    if(typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { data } = this.state;
    data[name] = value;
    this.setState(   data );
  }

  save(){
    const { data, isEdit } = this.state;

    const { dispatch } = this.props;

    let dataToSend = {
        name : data.name,
        department_id : data.department_id
    };

    if(isEdit){
        dataToSend.id = data.id   
    }

    this.setState({ 
        show:false,
        isEdit: false,
        data: {...defaultData}
    });
    
    dispatch(userManagementActions.saveDepartments(dataToSend, {
        success : () => {
            this.getData();  
        } 
    }))

    
    
  }

  edit(editData){

    let { data } = this.state;
    data = editData;
    this.setState({ isEdit:true, show: true, data });
  }

  
  toggle(value) {
    let { data, isEdit } = this.state;

    if(isEdit) {

        this.setState({ 
            show: value,
            data
         });
    }   else {

        this.setState({ 
            show: value,
            data: {...defaultData}
         });
    }
  }

  componentDidMount(){
     
    this.getData();
  }

  getData(){
    const { dispatch } = this.props;
    dispatch(userManagementActions.getDepartments());
    dispatch(userManagementActions.getDepartments());
    
  }

  remove(ld){
    const { dispatch } = this.props;
      
    dispatch(userManagementActions.saveDepartments({
        action : "remove",
        id : ld,
    }, {
        success : () => {
            this.getData();  
        } 
    }))

  }

    render() {
        const {data, show, isEdit } = this.state
        const {templates, loading, departments } = this.props
        console.log(templates);

        
        let Options = [{ title : "Not Assigned", value : 0}] 
        if(departments) {
            departments.map((item)=>{
                Options.push({ title : item.name, value : item.id})
            });
        }
        return (
            <div>
                <Card>
                    <CardHeader>
                        Departments 
                        <Button color="primary" className="pull-right" onClick={()=> {
                            this.toggle(true)
                            this.setState({ 
                                isEdit: false,
                                data : defaultData
                             })
                        }}
                        >Add Department </Button>
                    </CardHeader>
                    <CardBody>
                        {loading ? <TableLoader/> :
                        <ListGroup>
                        {templates && templates.length ? templates.map((item, idx) => {
                            return <ListGroupItem> {item.name}  &nbsp;&nbsp; 
                             <Button size="sm" color="primary"
                             onClick={() => this.edit({...item, idx})}><i className={"fa fa-edit"}>
                                 </i></Button> &nbsp;&nbsp;
                             <Button size="sm" color="danger" onClick={() => this.remove(item.id)}><i className={"fa fa-trash"}>
                                 </i></Button>
                                 </ListGroupItem  >
                        }) : <ListGroupItem> No data found</ListGroupItem>}
                        </ListGroup> }
                    </CardBody>
                </Card>

                <Modal
                    isOpen={show} 
                    toggle={() => this.toggle(false)}>
                    <ModalHeader>
                        {isEdit ? "Edit" : "Add" } Department
                    </ModalHeader>
                    <ModalBody>
                    <InputText
                        title={"Name"}
                        placeholder="Name"
                        value={data.name}
                        name="name"
                        onChange={this.handleChange}
                        maxLength="250"
                        />
                        
                    </ModalBody>
                    
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>
                        Save
                        </Button>{" "}
                        <Button color="warning" onClick={() => this.reset()}>
                        Reset
                        </Button>{" "}
                        <Button color="secondary" onClick={() => this.toggle(false)}>
                        Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {loading, data } = state.Departments
  return {
    loading,
    templates :data,
  }
    
}


const connectedCM = connect(mapStateToProps)(Departments)
export { connectedCM as Departments }

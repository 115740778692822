import { cronLogsConstants } from "../constants"

export function getCronLogsData(state = {}, action) {
    switch (action.type) {
        case cronLogsConstants.GET_CRONLOGS_DATA_REQUEST:
        return { loading: true }
        case cronLogsConstants.GET_CRONLOGS_DATA_SUCCESS:
        return { 
            data: action.result
            }
        case cronLogsConstants.GET_CRONLOGS_DATA_FAILURE:
        return {}
        default:
        return state
    }
}

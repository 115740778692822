export const dashboardReportsConstants = {

    GET_DASHBOARD_REPORTS_DATA_REQUEST: 'GET_DASHBOARD_REPORTS_DATA_REQUEST',
    GET_DASHBOARD_REPORTS_DATA_SUCCESS: 'GET_DASHBOARD_REPORTS_DATA_SUCCESS',
    GET_DASHBOARD_REPORTS_DATA_FAILURE: 'GET_DASHBOARD_REPORTS_DATA_FAILURE',

    GET_PERSON_WISE_SALES_REPORTS_DATA_REQUEST: 'GET_PERSON_WISE_SALES_REPORTS_DATA_REQUEST',
    GET_PERSON_WISE_SALES_REPORTS_DATA_SUCCESS: 'GET_PERSON_WISE_SALES_REPORTS_DATA_SUCCESS',
    GET_PERSON_WISE_SALES_REPORTS_DATA_FAILURE: 'GET_PERSON_WISE_SALES_REPORTS_DATA_FAILURE',

    GET_BA_PROCESS_STAGES_DATA_REQUEST: 'GET_BA_PROCESS_STAGES_DATA_REQUEST',
    GET_BA_PROCESS_STAGES_DATA_SUCCESS: 'GET_BA_PROCESS_STAGES_DATA_SUCCESS',
    GET_BA_PROCESS_STAGES_DATA_FAILURE: 'GET_BA_PROCESS_STAGES_DATA_FAILURE',

}
import React from 'react';
import { Col, Row, Button, Form } from 'reactstrap';

import { Translate } from "react-localize-redux";
import InlineError from '../../../components/Messages/InlineError';
import {  MobileNoInput, ReputeIDIndividualInput } from '../../../components/Forms/Inputs';

/*
 * Serach Form
 */

export const CustomerSupportSearchForm = ({ searching, searchdata, handleChange, getSearchResult, errors, reset, handleChangeSelect }) => {
    return (<React.Fragment>
        <Form autocomplete="off" onSubmit={getSearchResult} >
            <input autocomplete="false" name="hidden" type="text" style={{ display: "none" }} />
            <Row>

                <Col sm="6" lg="3" className="mb-1 p-1">
                    <ReputeIDIndividualInput name="ReputeId" selectValue={searchdata.ReputeIdValue} value={searchdata.ReputeId} handleChange={handleChange} disabledNOT={searchdata.PanNo || searchdata.AadharNo || searchdata.GstNo || searchdata.MobileNo} />
                </Col>

                <Col sm="6" lg="3" className="mb-1 p-1">
                    <MobileNoInput name="MobileNo" value={searchdata.MobileNo} handleChange={handleChange} disabledNOT={searchdata.ReputeId} />
                </Col>

                <Col sm="12" md="12" lg="3" className="mb-1 p-1">
                    <div className="btn-group">
                        <Button type="submit" color="primary" disabledNOT={searching} > {searching ? "Searching..." : <Translate id='Search' />} </Button> {" "}
                        <Button color="secondary" onClick={reset}> <Translate id='Reset' /> </Button>
                    </div>
                </Col>

                {errors.searchError &&
                    <Col sm="12" className="mb-1 p-1">
                        <InlineError message={errors.searchErrorMessage} />
                    </Col>}

            </Row>
        </Form>
    </React.Fragment>);
}
export const productTagConstants = {
    ADD__EDIT_PRODUCT_TAG_REQUEST: 'ADD__EDIT_PRODUCT_TAG_REQUEST',
    ADD__EDIT_PRODUCT_TAG_SUCCESS: 'ADD__EDIT_PRODUCT_TAG_SUCCESS',
    ADD__EDIT_PRODUCT_TAG_FAILURE: 'ADD__EDIT_PRODUCT_TAG_FAILURE',

    GET_PRODUCT_TAG_REQUEST: 'GET_PRODUCT_TAG_REQUEST',
    GET_PRODUCT_TAG_SUCCESS: 'GET_PRODUCT_TAG_SUCCESS',
    GET_PRODUCT_TAG_FAILURE: 'GET_PRODUCT_TAG_FAILURE',

    DELETE_PRODUCT_TAG_REQUEST: 'DELETE_PRODUCT_TAG_REQUEST',
    DELETE_PRODUCT_TAG_SUCCESS: 'DELETE_PRODUCT_TAG_SUCCESS',
    DELETE_PRODUCT_TAG_FAILURE: 'DELETE_PRODUCT_TAG_FAILURE',

    ADD_SUPERADMIN_INTERACTION_REQUEST : 'ADD_SUPERADMIN_INTERACTION_REQUEST',
    ADD_SUPERADMIN_INTERACTION_SUCCESS : 'ADD_SUPERADMIN_INTERACTION_SUCCESS',
    ADD_SUPERADMIN_INTERACTION_FAILURE : 'ADD_SUPERADMIN_INTERACTION_FAILURE',

    GET_UNREGISTERED_SELLER_REQUEST: 'GET_UNREGISTERED_SELLER_REQUEST',
    GET_UNREGISTERED_SELLER_SUCCESS: 'GET_UNREGISTERED_SELLER_SUCCESS',
    GET_UNREGISTERED_SELLER_FAILURE: 'GET_UNREGISTERED_SELLER_FAILURE',

    ADD_UNREGISTERED_SELLER_REQUEST: 'ADD_UNREGISTERED_SELLER_REQUEST',
    ADD_UNREGISTERED_SELLER_SUCCESS: 'ADD_UNREGISTERED_SELLER_SUCCESS',
    ADD_UNREGISTERED_SELLER_FAILURE: 'ADD_UNREGISTERED_SELLER_FAILURE',

    CHECK_DUPLICATE_UNREGISTERED_SELLER_REQUEST: 'CHECK_DUPLICATE_UNREGISTERED_SELLER_REQUEST',
    CHECK_DUPLICATE_UNREGISTERED_SELLER_SUCCESS: 'CHECK_DUPLICATE_UNREGISTERED_SELLER_SUCCESS',
    CHECK_DUPLICATE_UNREGISTERED_SELLER_FAILURE: 'CHECK_DUPLICATE_UNREGISTERED_SELLER_FAILURE',

}
import { notify } from 'reapop'
import { settingsConstants } from '../constants'
import { settingsService } from '../services'

export const settingsActions = {
    getSettings,
    editSettings,
}

function getSettings(data) {
    return dispatch => {
        dispatch(request())

        settingsService.getSettings(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: settingsConstants.GET_SETTINGS_REQUEST } }
    function success(result) { return { type: settingsConstants.GET_SETTINGS_SUCCESS, result } }
    function failure(error) { return { type: settingsConstants.GET_SETTINGS_FAILURE, error } }
}

function editSettings(data,callback) {
    return dispatch => {
        dispatch(request())

        settingsService.editSettings(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Successfully', status: "success" }))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: settingsConstants.EDIT_SETTINGS_REQUEST } }
    function success(result) { return { type: settingsConstants.EDIT_SETTINGS_SUCCESS, result } }
    function failure(error) { return { type: settingsConstants.EDIT_SETTINGS_FAILURE, error } }
}

export const authConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    SAVE_PASSWORD_REQUEST: 'SAVE_PASSWORD_REQUEST',
    SAVE_PASSWORD_SUCCESS: 'SAVE_PASSWORD_SUCCESS',
    SAVE_PASSWORD_FAILURE: 'SAVE_PASSWORD_FAILURE',

    WS_CONNECT : "WS_CONNECT",
    WS_DISCONNECT : "WS_DISCONNECT",
};

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableLoader } from "../../../components/Loaders";
import { Col, Row, Button, FormFeedback, Modal, ModalHeader, ModalFooter, ModalBody, Input } from 'reactstrap';
import BuyerDetails from './BuyerDetails';
import { validPhone } from '../../../helpers';
import { requirementLogsAction } from '../../actions';
class InviteBuyerRequirement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false,
            isSubmit: false,
            formData: {
                company_name: '',
                owner_name: '',
                mobile: ''
            },
            errors: {},
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.getBuyerRequirementInvites = this.getBuyerRequirementInvites.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    handleChange(event) {
        const { formData, errors } = this.state
        const { name, value, type } = event.target;
        let val = value;

        if (name === "company_name" || name === "owner_name") {
            let regex_allowed = /^[a-zA-Z ]+$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }
        if (name === "mobile") {
            let regex_allowed = /^[0-9]+$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }
        formData[name] = val;
        errors[name] = '';
        this.setState({ formData, errors });
    }

    handleToggle() {
        let { thisModel, isSubmit } = this.state;
        const { getData }  = this.props
        thisModel = !thisModel
        this.setState({
            thisModel: thisModel,
        }, () => {
            if (thisModel === true) {
                this.getBuyerRequirementInvites()
            }
            if (thisModel === false && isSubmit === true && typeof getData === "function") {
                getData();
            }
        })
    }

    isValid() {
        const { formData, errors } = this.state
        let error = false;

        if (!formData.company_name) {
            error = true
            errors.company_name = "this field is requred"
        }
        if (!formData.owner_name) {
            error = true
            errors.owner_name = "this field is requred"
        }
        if (!formData.mobile) {
            error = true
            errors.mobile = "this field is requred"
        }
        if (formData.mobile && !validPhone(formData.mobile)) {
            error = true
            errors.mobile = "invalid mobile"
        }

        this.setState({ errors })
        return error;
    }
    handelSubmit() {
        if (!this.isValid()) {
            const { formData } = this.state
            const { dispatch, requirement_details } = this.props
            let dataToSend = {
                company_name: formData.company_name,
                owner_name: formData.owner_name,
                mobile: formData.mobile,
                buyer_requirement_id : requirement_details.id
            }
            dispatch(requirementLogsAction.addBuyerRequirementInvite(dataToSend, {
                success: () => this.success()
            }));
            console.log('dataToSend', dataToSend)
        }
    }
    success() {
        const { formData } = this.state
        formData.company_name = '';
        formData.owner_name = '';
        formData.mobile = '';
        //        this.handleToggle();
        this.setState({ formData, isSubmit: true })
        this.getBuyerRequirementInvites()
        
    }
    getBuyerRequirementInvites() {
        const { dispatch, buyer_requirement_id } = this.props
        if (buyer_requirement_id) {
            let dataToSend = {
                buyer_requirement_id
            }
            dispatch(requirementLogsAction.getBuyerRequirementInvites(dataToSend));
        }
    }

    render() {
        const { formData, thisModel, errors } = this.state
        const { loading, data, requirement_details, invite_count } = this.props
       
        return (
            <React.Fragment>
                <Button size="sm"  color="primary" onClick={this.handleToggle}>Invitees { invite_count  > 0 ?  <label className="badge badge-light mb-0">{invite_count}</label>  : false }</Button>
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="lg" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> Invitees  </ModalHeader>
                    <ModalBody style={{ minHeight: '500px' }}>
                    <BuyerDetails data={requirement_details}/>
                        {loading ? <TableLoader /> :
                            (
                                <div className="table-responsive-sm animated fadeIn">
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Owner Name</th>
                                                <th>Mobile</th>
                                                <th>Qoute Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                                data && data.length ?
                                                    data.map(element => {
                                                        return (
                                                            <tr>

                                                                <td>{element.company_name}</td>
                                                                <td>{element.owner_name}</td>
                                                                <td>{element.mobile}</td>
                                                                <td>{element.qouteExistStatus}</td>
                                                                <th></th>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="4" className="text-center"> No invitees </td></tr>
                                            }
                                        <tr>
                                                <td>
                                                    <Input type="text" value={formData.company_name} name="company_name" placeholder="company name" onChange={this.handleChange} />
                                                    {errors && errors.company_name && (<FormFeedback> {errors.company_name} </FormFeedback>)}
                                                </td>
                                                <td>
                                                    <Input type="text" value={formData.owner_name} name="owner_name" placeholder="owner name" onChange={this.handleChange} />
                                                    {errors && errors.owner_name && (<FormFeedback> {errors.owner_name} </FormFeedback>)}
                                                </td>
                                                <td>
                                                    <Input type="text" value={formData.mobile} name="mobile" placeholder="mobile" maxLength="10" onChange={this.handleChange} />
                                                    {errors && errors.mobile && (<FormFeedback> {errors.mobile} </FormFeedback>)}
                                                </td>
                                                <th></th>
                                                <td><Button onClick={this.handelSubmit} color="primary" > Add invitee </Button></td>
                                            </tr>
                                           

                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleToggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getBuyerRequirementInvites
    return { loading, data }
}
const inviteBuyerRequirementConnected = connect(mapStateToProps)(InviteBuyerRequirement);
export { inviteBuyerRequirementConnected as InviteBuyerRequirement }
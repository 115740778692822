import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchLogsAction } from '../../actions';
import { CardBody, Card, CardHeader, Input, Button } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import { renderCronStatus } from '../../../helpers';
import moment from 'moment'
class SearchLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: {
				perPage: 20,
				totalItemsCount: 0
			},
			keywords: ''
		};
		this.getSearchLogData = this.getSearchLogData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	componentDidMount() {
		this.getSearchLogData();
	}

	getSearchLogData() {
		const { dispatch } = this.props;
		const { page  , keywords } = this.state;
		let dataToSend = {
			active_page: page.activePage,
			per_page: page.perPage,
			keywords: keywords
		};
		dispatch(searchLogsAction.getSearchLogData(dataToSend));
	}

	handlePageChange(pageNumber) {
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => this.getSearchLogData());
	}

	componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}


	handleChange(e) {
		const { name, value } = e.target

		this.setState({
			...this.state, [name]: value
		})
	}
	handleSearch(e) {
		const { page } = this.state

		this.setState({
			...this.state, page: {
				...page, activePage: 1
			}
		}, () => this.getSearchLogData())
	}
	render() {
		const { logs, loading } = this.props;
		const { page , keywords } = this.state;
		return (
			<Card>
				<CardHeader>
					<i className="fas fa-search" />
					<strong>Search Logs</strong>
				</CardHeader>
				<CardBody>
					<div className="d-flex mb-2 " >
						<Input name='keywords' className='w-25 mr-2' value={keywords} onChange={this.handleChange} placeholder='Search...' />
						<Button color='primary' onClick={this.handleSearch}>
							Search
						</Button>
					</div>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-striped table-bordered table-outline">
									<thead className="thead-light">
										<tr>
											<th>#</th>
											<th>Search From</th>
											<th>Search By</th>
											<th>Search Value</th>
											<th>Ip Address</th>
											<th>Date Time</th>

										</tr>
									</thead>
									<tbody>
										{logs && logs.search_list && logs.search_list.length ? (
											logs.search_list.map(function (eachData, index) {
												return (
													<React.Fragment>
														<tr>
															<td>{eachData.id}</td>
															<td>{eachData.section}</td>
															<td>{eachData.repute_id}  {eachData.name ? <React.Fragment> ({eachData.name})  </React.Fragment> : false} </td>
															<td>{eachData.search_parameters}</td>
															<td>{eachData.ip_address}</td>
															<td>{eachData.created_at ? moment(eachData.created_at).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
														</tr>
													</React.Fragment>
												);
											})
										) : (
											<tr>
												<td colSpan="4" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}

								/>
							)}
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getSearchLogsData;
	return {
		loading,
		logs: data
	};
}

const connectedSearchLog = connect(mapStateToProps)(SearchLog);
export { connectedSearchLog as SearchLog };

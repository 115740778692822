import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import { store } from '../../../helpers';
import { MarketingActions } from '../../actions/marketing.action';

const ResultBadge = ({ title, value }) => value ? <React.Fragment>
    <div className='col-sm-4'> <b> {title} </b> : </div>
    <div className='col-sm-8'> {value}  </div>
</React.Fragment>
    : ''

export const ScheduleForm = ({ data, fetchResults }) => {
    const [date_time, setDateTime] = useState('');
    const [counts, setCounts] = useState("");
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);

    const dispatch = store.dispatch;

    const validate = () => {
        let errors = {};
        if (date_time === "") {
            errors.date_time = "Please select schedule date and time"
        }
        if (counts === "" || counts === 0) {
            errors.counts = "Please select atleat one"
        } else if (counts > data.whatsapp_not_scheduled) {
            errors.counts = "Counts can not be more then " + data.whatsapp_not_scheduled + "."
        }
        setErrors(errors);
        return Object.keys(errors).length === 0 ? false : true;
    }

    const handleSubmit = () => {

        if (validate()) {
            return false;
        }

        dispatch(MarketingActions.scheduleMarketing({
            date_time : moment(date_time).format('YYYY-MM-DD hh:mm:ss'),
            counts,
            ...data.parameters
        }, {
            success : () => {
                setShow(false);
                fetchResults();
            }
        }));
    }

    return <div >
        <Button onClick={() => setShow(!show)} size="sm" color="info" className="mt-2">
            <i className='fas fa-calendar'></i> &nbsp;
            Schedule WhatsApp Campaign
        </Button>
        <Modal size="md" className="modal-danger" isOpen={show}>
            <ModalHeader toggle={() => setShow(!show)}>Schedule Whatsapp Campaign</ModalHeader>
            <ModalBody>

                <h6> Campaign For:</h6>
                <div className='row'>
                    <ResultBadge title="Template" value={data.parameters.template_name} />
                    <ResultBadge title="State" value={data.parameters.state_name} />
                    <ResultBadge title="District" value={data.parameters.city_name} />
                    <ResultBadge title="Sector" value={data.parameters.sector_name} />
                    <ResultBadge title="Not Scheduled" value={data.whatsapp_not_scheduled} />
                </div>

                <div className='form-group mt-4'>
                    <labal>Select schedule time</labal>
                    <DatePicker
                        container={'body'}
                        className="form-control width-100"
                        placeholderText="Select schedule time"
                        selected={date_time}
                        onChange={(date) => setDateTime(date)}
                        minDate={new Date()}
                        showTimeSelect
                        value={date_time ? moment(date_time).format("DD-MM-YYYY hh:mm A") : ''}
                    />
                    <FormFeedback>
                        {errors[`date_time`] && errors[`date_time`]}
                    </FormFeedback>

                </div>

                <div className='form-group'>
                    <labal>Numbers of business to schedule</labal>
                    <Input
                        value={counts}
                        placeholder={"Counts"}
                        onChange={(e) => setCounts(e.target.value)}
                    />
                    <FormFeedback>
                        {errors[`counts`] && errors[`counts`]}
                    </FormFeedback>

                </div>

                <div className='col-md-3'>
                    <br />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => handleSubmit()}>Submit</Button>

                <Button onClick={() => setShow(!show)}>Close</Button>
            </ModalFooter>
        </Modal>
    </div>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import MaskedInput from 'react-text-mask';
import { validPhone } from '../../../helpers';
import { notify } from 'reapop';
class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareholders: [{ name: "" }],
            errors: []
        };
        this.isSet = false
        this.setParentValue = this.setParentValue.bind(this);
        this.handleAddShareholder = this.handleAddShareholder.bind(this);
        this.handleRemoveShareholder = this.handleRemoveShareholder.bind(this);
        this.handleShareholderNameChange = this.handleShareholderNameChange.bind(this);
    }

    handleShareholderNameChange = idx => evt => {
        const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });
        this.setState({ shareholders: newShareholders }, () => {
            this.checkValidaion();
        });
        this.setParentValue(newShareholders);

    };

    handleAddShareholder = () => {

        const { shareholders } = this.state;

        let newSharreholders = shareholders.concat([{ name: "" }]);

        this.setParentValue(newSharreholders);

        this.setState({
            shareholders: newSharreholders
        });
    };

    handleRemoveShareholder = idx => () => {
        const { shareholders } = this.state;
        const { dispatch } = this.props;
        let newSharreholders = shareholders.filter((s, sidx) => idx !== sidx)
        if(newSharreholders.length) {
            this.setParentValue(newSharreholders);
            this.setState({
                shareholders: newSharreholders
            });
        }
        else {
            dispatch(notify({ title: "", position: "tc", message: 'At least one Mobile number is required', status: "warning" }));

        }

    };

    checkValidaion() {
        const { shareholders } = this.state;

        let errors = shareholders.map(a => {
            if (!a.name) {
                return "This field is required."
            } else if (!validPhone(a.name)) {
                return "Enter valid mobile number ."
            }
            return false

        })

        this.setState({ errors });
    }

    setParentValue(value) {
        const { handleChildValue } = this.props
        let data = value.map(a => { return a.name })
        handleChildValue({ name: "mobile", value: data })
    }


    componentWillReceiveProps(props) {
        const { mobileData, errors } = props;

        if (mobileData && this.isSet === false) {
            var dataArray = [];
            mobileData.map(d => {
                var md = {
                    name: d
                }
                dataArray.push(md);
                return false

            })
            this.isSet = true;
            this.setState({
                shareholders: dataArray
            });
        }

        if (errors) {
            this.setState({
                errors
            });
        }

    }

    render() {
        const { errors, shareholders } = this.state
        return (
            <div className="row">

                {shareholders.map((shareholder, idx) => (
                    <div key={idx} className="shareholder col-md-6 mb-2" >
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText> +91 </InputGroupText>
                            </InputGroupAddon>
                            <MaskedInput
                                className="form-control"
                                type="text"
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                name="name" placeholder={`Mobile Number ${idx + 1}`} value={shareholder.name} onChange={this.handleShareholderNameChange(idx)} />

                            <InputGroupAddon addonType="append" >
                                <InputGroupText className="text-danger cursor-pointer" onClick={this.handleRemoveShareholder(idx)}>
                                    <i className="fa fa-remove "></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            {errors[idx] && <FormFeedback >
                                {errors[idx]}
                            </FormFeedback>
                            }
                        </InputGroup>

                    </div>
                ))}

                <div className="col-md-6" >
                    <button type="button" onClick={this.handleAddShareholder} className="btn btn-primary" > {shareholders.length === 0 ? "Add mobile number" : "Add another"} </button>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedMobile = connect(mapStateToProps)(Mobile)
export { connectedMobile as Mobile }
import { axiosAjaxCall } from "./common.service";

export const dashboardService = {
    getDashboardData,
    getSalseStatusOverviewData,
    getDashboardDataLeadVsRegister,

}





function getDashboardDataLeadVsRegister(data) {    
    return axiosAjaxCall("get_leads_count", data);
}


function getDashboardData(data) {    
    return axiosAjaxCall("get_dashboard_data", data);
}

function getSalseStatusOverviewData(data) {    
    return axiosAjaxCall("sales_status_overview", data);
}

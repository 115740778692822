import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { validEmail } from '../../../helpers';
class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            shareholders: [],
            errors: []
        };

        this.isSet = false
        this.setParentValue = this.setParentValue.bind(this);
    }

    handleShareholderNameChange = idx => evt => {
        const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });

        this.setState({ shareholders: newShareholders }, () => {
            this.checkValidaion();
        });
        this.setParentValue(newShareholders);

    };

    setParentValue(value) {
        const { handleChildValue } = this.props
        let data = value.map(a => { return a.name })
        handleChildValue({ name: "email", value: data })
    }

    checkValidaion() {
        const { shareholders } = this.state;

        let errors = shareholders.map(a => {
            if (!a.name) {
                return "This field is required."
            } else if (!validEmail(a.name)) {
                return "Enter valid email address."
            }
            return false
        })

        this.setState({ errors });
    }

    handleAddShareholder = () => {
        const { shareholders } = this.state;

        let newShareholders = shareholders.concat([{ name: "" }])

        this.setParentValue(newShareholders);
        this.setState({
            shareholders: newShareholders
        });

    };

    handleRemoveShareholder = idx => () => {
        const { shareholders } = this.state;

        let newShareholders = shareholders.filter((s, sidx) => idx !== sidx)

        this.setParentValue(newShareholders);
        this.setState({
            shareholders: newShareholders
        });
    };

    componentWillReceiveProps(props) {
        const { emailData, errors } = props;

        if (emailData && this.isSet === false) {
            var dataArray = [];
            emailData.map(d => {
                var md = {
                    name: d
                }
                dataArray.push(md);
                return false;
            })

            this.isSet = true;
            this.setState({
                shareholders: dataArray
            });
        }

        if (errors) {
            this.setState({
                errors
            });
        }

    }

    render() {
        const { errors, shareholders } = this.state

        return (
            <div className="row">
                {shareholders.map((shareholder, idx) => (
                    <div key={idx} className="shareholder col-md-6 mb-2" >

                        <InputGroup>
                            <Input type="email" name="name" placeholder={`Email Id ${idx + 1}`} value={shareholder.name} onChange={this.handleShareholderNameChange(idx)} />

                            <InputGroupAddon addonType="append">
                                <InputGroupText className="text-danger cursor-pointer" onClick={this.handleRemoveShareholder(idx)}>
                                    <i className="fa fa-remove"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            {errors[idx] && <FormFeedback >
                                {errors[idx]}
                            </FormFeedback>
                            }
                        </InputGroup>

                    </div>
                ))}
                <div className="shareholder col-md-6 mb-2" >
                    <button
                        type="button"
                        onClick={this.handleAddShareholder}
                        className="btn btn-primary" > {shareholders.length === 0 ? "Add email id" : "Add another"} </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedEmail = connect(mapStateToProps)(Email)
export { connectedEmail as Email }
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { Button, Card, CardBody, CardHeader, FormFeedback } from 'reactstrap';
import { currencyFormat } from '../../../helpers';
import { ExpensesAction } from '../../actions';
import { ReportValuesModal } from '../RecentRegistrations/Common/ReportValuesModal';
import './expenses.css';

const Expenses = (props) => {
  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState([])
  const [errors, setErrors] = useState({})
  const [dateData, setDateData] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
    serachField: {
      dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
    },
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },

  })
  const { expensesData, total } = props


  const handleApply = () => {
    const { dispatch } = props;
    const { startDate, endDate } = dateData
    if (!validation()) {
      let dataToSend = {
        end_date: startDate.format('YYYY-MM-DD'),
        start_date: endDate.format('YYYY-MM-DD')
      };
      dispatch(ExpensesAction.getExpenses(dataToSend))
    }
  }

  useEffect(() => {
    handleApply()
  }, []);

  const toggle = (data) => {
    setShow(!show);
    setModalData(data)
  }

  const handleReset = () => {
    setDateData({
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
      serachField: {
        dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
      }
    })
    setData([])
    setErrors({})
    handleApply()

  }

  const validation = () => {
    const { startDate, endDate } = dateData
    let error = false;
    let errors = {};
    if (startDate === '' || endDate === '') {
      errors.startDate = 'Please select Date';
      error = true;
    }
    setErrors({ errors });
    return error;
  }

  const handleEvent = (event, picker) => {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + ' to ' + end;
    if (start === end) {
      label = start;
    }
    setDateData({
      ...dateData,
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label
      },
    })
  }

  useEffect(() => {
    setData(expensesData)
  }, [expensesData]);

  const { startDate, endDate, ranges, serachField } = dateData
  return (
    <React.Fragment>
      <Card className='' >
        <CardHeader>
          <i className="fa fa-rupee"></i>  Expenses
        </CardHeader>
        <CardBody>




          <div className="form-group">
            <div>
              <div className=' d-flex  mt-2' >
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onEvent={handleEvent}
                  ranges={ranges}
                >
                  <div className="input-group">
                    <input type="text" className="form-control" value={serachField.dateRange} placeholder="Select Date Range" id="date_picker" style={{ 'width': '220px' }} />
                    <span className="input-group-btn">
                      <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                        <i className="fa fa-calendar" />
                      </Button>
                    </span>

                  </div>
                </DateRangePicker>

                <Button color="primary" className="ml-2" onClick={handleApply}>
                  Fetch
                </Button>
                <Button color="secondary" className="ml-2" onClick={handleReset}>
                  Reset
                </Button>
              </div>
              <FormFeedback>{errors[`startDate`] && errors[`startDate`]}</FormFeedback>
            </div>
          </div>

          {<div className="table-responsive-sm  animated fadeIn">
            <table className="table table-sm table-condensed table-striped table-bordered table-outline">
              <thead className="thead-light">
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Google Ads</th>
                  <th>Facebook Ads</th>
                  <th>Aws</th>
                  <th>WM</th>
                  <th>MSG91</th>
                  <th>GridLine</th>
                  <th>Fixed Expense</th>
                  <th  className='text-right'>Total Expense </th>
                  <th  className='text-right'>Registered </th>
                  <th  className='text-right'>Recharges </th>
                  <th  className='text-right'>Total Revenue </th>
                  <th  className='text-right'>P&L </th>
                </tr>
              </thead>
              <tbody>
                {data && data.length ? (
                  data.map((eachData, index) => <React.Fragment key={index} >
                    <tr>
                      <td>{index + 1}</td>
                      <td>{eachData.date}</td>
                      <td>
                        <ReportValuesModal title='Add Google Ads Expense' 
                        currentvalue={eachData.google_ads_expense} 
                        displayValue={currencyFormat(eachData.google_ads_expense, true)}  type='google_ads_expense' getData={handleApply} data={eachData} />
                      </td>
                      <td>
                        <ReportValuesModal title='Add Facebook Ads Data' 
                        
                        
                        currentvalue={eachData.facebook_ads_expense} 
                        displayValue={currencyFormat(eachData.facebook_ads_expense, true)} type='facebook_ads_expense' getData={handleApply} data={eachData} />
                      </td>
                      <td>{currencyFormat(eachData.aws_expense, true)}</td>
                      <td>{currencyFormat(eachData.wm_cost, true)}</td>
                      <td>
                      <Tooltip
                          interactive
                          arrow={true}
                          html={<div>
                            <table className='table table-sm text-light table-bordered m-0' style={{ fontSize: "12px" }}>

                              <tr>
                                <th>Title</th>
                                <th>Count</th>
                                <th>Cost</th>
                              </tr>
                              <tr>
                                <td>SMS ({eachData.sms_cps}/SMS)</td>
                                <td>{eachData.sms_count}</td>
                                <td>{currencyFormat(eachData.sms_cost, true)}</td>
                              </tr>
                              <tr>
                                <td>WhatsApp({eachData.msg91_wm_cps}/SMS)</td>
                                <td>{eachData.msg91_wm_count}</td>
                                <td>{currencyFormat(eachData.msg91_wm_cost, true)}</td>
                              </tr>
                            </table>
                          </div>}
                        >
                          <span className='cursor-pointer'>
                            {currencyFormat(eachData.msg91, true)}
                          </span>
                        </Tooltip>
                      
                      </td>
                      <td >
                        <Tooltip
                          interactive
                          arrow={true}
                          html={<div>
                            <table className='table table-sm text-light table-bordered m-0' style={{ fontSize: "12px" }}>

                              <tr>
                                <th>Title</th>
                                <th>Count</th>
                                <th>Cost</th>
                              </tr>
                              <tr>
                                <td>PAN</td>
                                <td>{eachData.gridlines_pan_details_count}</td>
                                <td>{currencyFormat(eachData.gridlines_pan_details_cost, true)}</td>
                              </tr>
                              <tr>
                                <td>GST</td>
                                <td>{eachData.gridlines_gst_details_count}</td>
                                <td>{currencyFormat(eachData.gridlines_gst_details_cost, true)}</td>
                              </tr>
                              <tr>
                                <td>AADHAR</td>
                                <td>{eachData.gridlines_aadhar_details_count}</td>
                                <td>{currencyFormat(eachData.gridlines_aadhar_details_cost, true)}</td>
                              </tr>
                            </table>
                          </div>}
                        >
                          <span className='cursor-pointer'>
                            {currencyFormat(eachData.gridlines_total_cost, true)}
                          </span>
                        </Tooltip>
                      </td>
                      <td>
                        <ReportValuesModal title='Add Fixed Expense Data' 
                        currentvalue={eachData.fixed_expense} 
                        displayValue={currencyFormat(eachData.fixed_expense, true)} 
                        type='fixed_expense' getData={handleApply} data={eachData} />
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.total, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.earnings, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.recharges, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.revenue, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.profit_loss, true)}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                  )
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}

                

              </tbody>
              {total ? 
              <thead className="thead-light">
                  <tr>
                    <th></th>
                    <th></th>
                    <th>{currencyFormat(total.google_ads_expense, true)} </th>
                    <th>{currencyFormat(total.facebook_ads_expense, true)} </th>
                    <th>{currencyFormat(total.aws_expense, true)} </th>
                    <th>{currencyFormat(total.wm, true)} </th>
                    <th>{currencyFormat(total.msg91, true)} </th>
                    <th>{currencyFormat(total.gridlines, true)} </th>
                    <th>{currencyFormat(total.fixed_expense, true)} </th>
                    <th className='text-right'>{currencyFormat(total.total, true)} </th>
                    <th className='text-right'>{currencyFormat(total.earnings, true)} </th> 
                    <th className='text-right'>{currencyFormat(total.recharges, true)} </th>
                    <th className='text-right'>{currencyFormat(total.revenue, true)} </th>
                    <th className='text-right'>{currencyFormat(total.profit_loss, true)} </th>
                  </tr>
                </thead> : false} 
            </table>
          </div>}


        </CardBody>
      </Card>

    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const { data, total, loading } = state.getExpenses
  return {
    loading,
    expensesData: data,
    total
  }

}
const connectedExpenses = connect(mapStateToProps)(Expenses)
export { connectedExpenses as Expenses };


import { notify } from 'reapop'
import { promoCodeUsagesConstants } from '../constants'
import { promoCodeUsagesService } from '../services'

export const promoCodeUsagesAction = {
    getPromoCodeUsages,
}


function getPromoCodeUsages(data) {
    return dispatch => {
        dispatch(request())

        promoCodeUsagesService. getPromoCodeUsages(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type:  promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_REQUEST } }
    function success(result) { return { type:  promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_SUCCESS, result } }
    function failure(error) { return { type:  promoCodeUsagesConstants.GET_PROMO_CODE_USAGES_FAIL, error } }
}

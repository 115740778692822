import React, { Component } from 'react'
import { InputDropDown } from '../Settings/StageSetting/Components/FormElements';

export default class TreeDropdown extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data : {
                selectedUser : "",
            },
            errors : {}  
        }

        this.handleChange = this.handleChange.bind(this)
    }
    
    search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return myArray[i];
            }
        }
    }
    
    
    handleChange(event) {
        const { data} = this.state;
        const { name , value} = event.target;

        data[name] = value;

        let subUsers = [];
        let { users, setIndividual } = this.props
        if(value!== "") {
            let valueToJson = JSON.parse(value);
            let userId = valueToJson.id;
            
            let searchItem = this.search( userId, users);
            
            if(searchItem && searchItem.hasOwnProperty("all_report_by_persons") ){
                subUsers = searchItem.all_report_by_persons
            }
        }

        this.setState({data, subUsers}, () => {
            setIndividual(value)
        });


    }

    render() {

        let { users, parentUserID, setIndividual } = this.props
        let { data, subUsers , errors} = this.state 
            
        let usersOptions = [{ title: "Select User", value: JSON.stringify(parentUserID) }];

        if (users) {
            users.map((usr) => {
                usersOptions.push({ value: JSON.stringify(usr), title: usr.name });
            });
        }
    
        return (
            <React.Fragment>
            <div className="pull-left" style={{display:"inline-block", width : "auto"}} >
                
                <InputDropDown
                    
                    style={{display:"inline-block", width : "auto"}}
                      noMargin={true}
                      title={false}
                      name={"selectedUser"}
                      error={errors.selectedUser}
                      value={data.selectedUser}
                      onChange={this.handleChange}
                      options={usersOptions}
                      maxLength="250"
                      className={"input-sm"}
                    />

                 
                
            </div>
               
            {data.selectedUser !== "" && subUsers.length > 0 &&
            <React.Fragment>
                <div className="pull-left" style={{ width : "25px", lineHeight : "40px"  }} >
                    &nbsp;
                    &nbsp;  
                    <i className={"fa fa-caret-right"} style={{fontSize : "18px"}}> </i>
                
                </div>
                <TreeDropdown users={subUsers} parentUserID={data.selectedUser} setIndividual={setIndividual} />
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

import { notify } from 'reapop'
import { reputeinfoConstants } from '../constants'
import { reputeinfoService } from '../services'

export const reputeinfoActions = {
    getRecentRegistrations,
    getRecentRegistrAttempt,
    updateSettings,
    getSettings,
    postRecentRegistrCallStatus ,
}


function postRecentRegistrCallStatus(data , ref) {
    return dispatch => {
        dispatch(request())

        reputeinfoService.postRecentRegistrCallStatus(data)
            .then(
                verified =>dispatch(ref.getData) , 
                // dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.RECENT_REGISTRATIONS_CALLED_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.RECENT_REGISTRATIONS_CALLED_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.RECENT_REGISTRATIONS_CALLED_FAILURE, error } }
}


function getRecentRegistrations(data) {
    return dispatch => {
        dispatch(request())

        reputeinfoService.getRecentRegistrations(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.GET_RECENT_REGISTRATIONS_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.GET_RECENT_REGISTRATIONS_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.GET_RECENT_REGISTRATIONS_FAILURE, error } }
}
function getRecentRegistrAttempt(data) {
    return dispatch => {
        dispatch(request())

        reputeinfoService.getRecentRegistrAttempt(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.GET_RECENT_ATTEMPT_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.GET_RECENT_ATTEMPT_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.GET_RECENT_ATTEMPT_FAILURE, error } }
}

function getSettings(data) {
    return dispatch => {
        dispatch(request())

        reputeinfoService.getSettings(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.GET_SETTINGS_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.GET_SETTINGS_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.GET_SETTINGS_FAILURE, error } }
}

function updateSettings(data, callback) {
    return dispatch => {
        dispatch(request())

        reputeinfoService.updateSettings(data)
            .then(
                verified => {
                    dispatch(success(verified));
                    callback.success();
                },error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.UPDATE_SETTINGS_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.UPDATE_SETTINGS_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.UPDATE_SETTINGS_FAILURE, error } }
}



import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { GenerateTicketForm } from './GenerateTicketForm';
import { TicketsComments } from './TicketsComments';
import { TableLoader } from '../../../../components/Loaders';
import Interweave from 'interweave';
import moment from 'moment';
import TimeAgo from 'react-timeago'
import { TicketStatusActions } from './TicketStatusActions';
import { TICKET_STATUS } from '../../../constants';
import { IconStatus } from './components';
import { show, hide } from 'redux-modal';

class ViewTicketDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };

        this.openModal = this.openModal.bind(this);

    }

    componentWillReceiveProps(props) {
        if (this.state.isLoaded === false) {
            this.setState({ isLoaded: true })
        }
    }

    openModal() {
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"));
        setTimeout(function () {
            dispatch(show("bootstrap", {
                ModelSize: "md",
                ModelHeaderText: "Edit Ticket",
                ModelBodyText: <GenerateTicketForm {...this.props} isEdit={true} />,
                isFooter: false
            }));
        }, 100)

    }


    render() {
        const { loading, ticketsDetails } = this.props
        return (loading && this.state.isLoaded === false ? <TableLoader /> :
            <React.Fragment>
                <Card>
                    <CardBody>
                        <span className="h5"> {ticketsDetails.title} </span>
                        <span className="pull-right">
                            <TicketStatusActions {...this.props} /> &nbsp;
                            {(parseInt(ticketsDetails.ticket_status_id, 10) === TICKET_STATUS.NEW || parseInt(ticketsDetails.ticket_status_id, 10) === TICKET_STATUS.RE_OPEN || parseInt(ticketsDetails.ticket_status_id, 10) === TICKET_STATUS.IN_PROGRESS) &&
                                <React.Fragment>
                                    <Button color="primary" onClick={this.openModal}> Edit </Button>
                                    {/* <Button color="danger">Delete</Button> */}
                                </React.Fragment>
                            }
                        </span>
                    </CardBody>


                    <CardHeader>
                        <Row>
                            <Col md="6">
                                <p> <strong>Company</strong>: {ticketsDetails.company_name} ({ticketsDetails.company_formated_repute_id}) </p>
                                <p><strong>Status</strong>: <span className="h6"> <IconStatus ticket_status_id={ticketsDetails.ticket_status_id} status_name={ticketsDetails.ticket_status} /></span> </p>
                            </Col>
                            <Col md="6">
                                <p> <strong>Created By</strong>: {ticketsDetails.created_by_user_name}</p>
                                <p> <strong>Created At</strong>: {moment(new Date(ticketsDetails.created_at)).format('DD-MM-YYYY hh:mm A')}  ( <TimeAgo date={ticketsDetails.created_at} />  )</p>
                                <p> <strong>Last Update</strong>: {moment(ticketsDetails.updated_at).format('DD-MM-YYYY hh:mm A')}  ( <TimeAgo date={ticketsDetails.updated_at} />  )</p>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody className="p-0">

                        <div className=" ql-snow">
                            <div className="ql-editor">
                                <Interweave content={ticketsDetails.description} />
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <TicketsComments {...this.props} />

            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    const { ticketsDetails, loading } = state.getTicketDetails;

    return {
        loading,
        ticketsDetails
    };

}

const connectedViewTicketDetails = connect(mapStateToProps)(ViewTicketDetails);
export { connectedViewTicketDetails as ViewTicketDetails }

import { reputeinfoBalancePackageConstants } from '../constants'

export function getBalancePackage(state = {}, action) {
  switch (action.type) {
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_REQUEST:
      return { loading: true }
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SUCCESS:
      return { data: action.result }
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateBalancePackage(state = {}, action) {
  switch (action.type) {
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_REQUEST:
      return { loading: true }
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SUCCESS:
      return { data: action.result }
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_FAILURE:
      return {}
    default:
      return state
  }
}

export function getBalanceSetting(state = {}, action) {
  switch (action.type) {
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_REQUEST:
      return { loading: true }
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_SUCCESS:
      return { data: action.result }
    case reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateBalanceSetting(state = {}, action) {
  switch (action.type) {
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_REQUEST:
      return { loading: true }
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_SUCCESS:
      return { data: action.result }
    case reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_FAILURE:
      return {}
    default:
      return state
  }
}
export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`sms_text`]) {
        formIsValid = false
        errors[`sms_text`] = 'Can Not be empty'
    }
    if (!fields[`flow_id`]) {
        formIsValid = false
        errors[`flow_id`] = 'Can Not be empty'
    }
    return { errors, formIsValid }
}
import { profileConstants } from "../constants"

export function getProfile(state = {}, action) {
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
        return { loading: true }
    case profileConstants.GET_PROFILE_SUCCESS:
      return { data: action.result }
    case profileConstants.GET_PROFILE_FAILURE:
      return {}
    default:
      return state
  }
}

export function getDepositPayments(state = {}, action) {
  switch (action.type) {
    case profileConstants.GET_PAYMENTS_DEPOSIT_REQUEST:
        return { loading: true }
    case profileConstants.GET_PAYMENTS_DEPOSIT_SUCCESS:
      return { deposit_payments: action.result }
    case profileConstants.GET_PAYMENTS_DEPOSIT_FAILURE:
      return {}
    default:
      return state
  }
}

export function getCommissionPayments(state = {}, action) {
  switch (action.type) {
    case profileConstants.GET_COMMISIION_ACCOUNT_REQUEST:
        return { loading: true }
    case profileConstants.GET_COMMISIION_ACCOUNT_SUCCESS:
      return { 
        loading: false,
        comission_payments: action.result.comission_payments,
        total: action.result.totals
       }
    case profileConstants.GET_COMMISIION_ACCOUNT_FAILURE:
      return {}
    default:
      return state
  }
}

export function getHeaderData(state = {}, action) {
  switch (action.type) {
    case profileConstants.GET_HEADER_DATA_REQUEST:
        return { loading: true, ...state  }
    case profileConstants.GET_HEADER_DATA_SUCCESS:
      return action.result
    case profileConstants.GET_HEADER_DATA_FAILURE:
      return {}
    default:
      return state
  }
}


 import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap'
import { FaqActions } from '../../actions';
import { FAQlist } from './FAQlist';
import { TableLoader } from '../../../components/Loaders';

class FAQ extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '1'
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(FaqActions.getFAQ());
  }
  render() {
    const  {loading, data } = this.props
    return (
      <div className="">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                <i className="fa fa-question-circle"></i><strong>FAQ</strong>
              </CardHeader> 
              <CardBody>
                {loading ? <TableLoader/> :
                <FAQlist data={data}/> }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {data, loading} = state.getFAQ
  return {
    loading, 
    data
  }
  //
}

const connectedFAQ = connect(mapStateToProps)(FAQ)
export { connectedFAQ as FAQ }

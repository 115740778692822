import classnames from 'classnames';
import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Marketing } from './Marketing';
import { MobileNotificatios } from './MobileNotificatios';
import { WhatsappAnalysis } from './WhatsappAnalysis';

function MarketingTab() {
    const [ActiveTab, SetActiveTab] = useState('Marketing');

    const TABS = {
        Marketing: "Marketing",
        WhatsappAnalysis: "WhatsappAnalysis",
        MarketingAnalysis: "MarketingAnalysis",
        mobileNotifications: "mobileNotifications",
    };

    let toggle = (tab) => {
        SetActiveTab(tab);
    }

    return (
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: ActiveTab === TABS.Marketing,
                        })}
                        onClick={() =>
                            toggle(TABS.Marketing)
                        }
                    >
                        <i className="fa fa-envelope mx-2"></i><strong>Whatsapp Marketing</strong>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: ActiveTab === TABS.mobileNotifications,
                        })}
                        onClick={() =>
                            toggle(TABS.mobileNotifications)
                        }
                    >
                        <i className="fa fa-bell mx-2"></i><strong>Mobile Notification</strong>
                    </NavLink>
                </NavItem>
                <NavItem className=''>
                    <NavLink
                        className={classnames({
                            active: ActiveTab === TABS.WhatsappAnalysis,
                        })}
                        onClick={() => {
                            toggle(TABS.WhatsappAnalysis);
                        }}
                    >
                        <i className="fab fa-whatsapp mx-2"></i><strong>Whatsapp Analysis</strong>
                    </NavLink>
                </NavItem>
                {/* <NavLink
                    className={classnames({
                        active: ActiveTab === TABS.MarketingAnalysis,
                    })}
                    onClick={() => {
                        toggle(TABS.MarketingAnalysis);
                    }}
                >
                    <i className="fa fa-line-chart	 mx-2"></i><strong>Marketing Analysis</strong>
                </NavLink> */}

            </Nav>
            <TabContent activeTab={ActiveTab}>
                <TabPane tabId={TABS.Marketing}>
                    <Marketing />
                </TabPane>
                <TabPane tabId={TABS.mobileNotifications}>
                    <MobileNotificatios />
                </TabPane>

                <TabPane tabId={TABS.WhatsappAnalysis}>
                    <WhatsappAnalysis />
                </TabPane>
                {/* <TabPane tabId={TABS.MarketingAnalysis}>
                    <MarketingAnalysis />
                </TabPane> */}
            </TabContent>
        </React.Fragment>
    )
}

export default MarketingTab
import { accountConstants } from "../constants"

export function getAccountData(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_ACCOUNT_DATA_REQUEST:
        return { loading: true }
      case accountConstants.GET_ACCOUNT_DATA_SUCCESS:
        return { 
          data: action.result.acdata,
          company_list: action.result.company_list,
          balancePackages : action.result.balancePackages,
          total_amount_data: action.result.total_amount_data,
          total_records: action.result.total_records,
          default_balance : action.result.default_balance
         }
      case accountConstants.GET_ACCOUNT_DATA_FAILURE:
        return {}
      default:
        return state
    }
  }

export function getUserSalaryData(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_USER_SALARY_DATA_REQUEST:
        return { loading: true }
      case accountConstants.GET_USER_SALARY_DATA_SUCCESS:
        return { 
          data: action.result.salary_data,
          total: action.result.totals
         }
      case accountConstants.GET_USER_SALARY_DATA_FAILURE:
      case accountConstants.GET_USER_SALARY_DATA_RESET:
        return {}
      default:
        return state
    }
  }
  
  export function getChequeDetail(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_CHEQUE_DETAIL_REQUEST:
        return { loading: true }
      case accountConstants.GET_CHEQUE_DETAIL_SUCCESS:
        return { data: action.result }
      case accountConstants.GET_CHEQUE_DETAIL_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updateChequeDetail(state = {}, action) {
    switch (action.type) {
      case accountConstants.UPDATE_CHEQUE_DETAIL_REQUEST:
        return { loading: true }
      case accountConstants.UPDATE_CHEQUE_DETAIL_SUCCESS:
        return { data: action.result }
      case accountConstants.UPDATE_CHEQUE_DETAIL_FAILURE:
        return {}
      default:
        return state
    }
  }
  
  export function getCommissionData(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_COMMISSION_REQUEST:
        return { loading: true }
      case accountConstants.GET_COMMISSION_SUCCESS:
        return { data: action.result }
      case accountConstants.GET_COMMISSION_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function saveCompanyProductTags(state = {}, action) {
    switch (action.type) {
      case accountConstants.SAVE_COMPANY_PRODUCT_TAGS_REQUEST:
        return { loading: true }
      case accountConstants.SAVE_COMPANY_PRODUCT_TAGS_SUCCESS:
        return { data: action.result }
      case accountConstants.SAVE_COMPANY_PRODUCT_TAGS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getCompanyProductTags(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_COMPANY_PRODUCT_TAGS_REQUEST:
        return { loading: true }
      case accountConstants.GET_COMPANY_PRODUCT_TAGS_SUCCESS:
        return { data: action.result }
      case accountConstants.GET_COMPANY_PRODUCT_TAGS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function deleteCompanyProductTags(state = {}, action) {
    switch (action.type) {
      case accountConstants.DELETE_COMPANY_PRODUCT_TAGS_REQUEST:
        return { loading: true }
      case accountConstants.DELETE_COMPANY_PRODUCT_TAGS_SUCCESS:
        return { data: action.result }
      case accountConstants.DELETE_COMPANY_PRODUCT_TAGS_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getProductTagsAlphabatically(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_PRODUCT_TAG_REQUEST:
        return { loading: true }
      case accountConstants.GET_PRODUCT_TAG_SUCCESS:
        return { 
          tag_list: action.result.tag_list ?  action.result.tag_list : [], 
          data: action.result.tag_list ?  action.result.tag_list : [], 
        };
      case accountConstants.GET_PRODUCT_TAG_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function getDepositReceipt(state = {}, action) {
    switch (action.type) {
      case accountConstants.GET_DEPOSIT_RECEPIT_REQUEST:
        return { loading: true }
      case accountConstants.GET_DEPOSIT_RECEPIT_SUCCESS:
        return { data: action.result }
      case accountConstants.GET_DEPOSIT_RECEPIT_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updateDepositReceipt(state = {}, action) {
    switch (action.type) {
      case accountConstants.UPDATE_DEPOSIT_RECEPIT_REQUEST:
        return { loading: true }
      case accountConstants.UPDATE_DEPOSIT_RECEPIT_SUCCESS:
        return { data: action.result }
      case accountConstants.UPDATE_DEPOSIT_RECEPIT_FAILURE:
        return {}
      default:
        return state
    }
  }

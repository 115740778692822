export const customerDetail = {
    PINCODE_REQUEST: 'PINCODE_REQUEST',
    PINCODE_SUCCESS: 'PINCODE_SUCCESS',
    PINCODE_FAILURE: 'PINCODE_FAILURE',

    ADD_CUSTOMER_DETAIL_REQUEST: 'ADD_CUSTOMER_DETAIL_REQUEST',
    ADD_CUSTOMER_DETAIL_SUCCESS: 'ADD_CUSTOMER_DETAIL_SUCCESS',
    ADD_CUSTOMER_DETAIL_FAILURE: 'ADD_CUSTOMER_DETAIL_FAILURE',

    GET_CUSTOMER_DETAILS_REQUEST: 'GET_CUSTOMER_DETAILS_REQUEST',
    GET_CUSTOMER_DETAILS_SUCCESS: 'GET_CUSTOMER_DETAILS_SUCCESS',
    GET_CUSTOMER_DETAILS_FAILURE: 'GET_CUSTOMER_DETAILS_FAILURE',

    UPDATE_CUSTOMER_DETAILS_REQUEST: 'UPDATE_CUSTOMER_DETAILS_REQUEST',
    UPDATE_CUSTOMER_DETAILS_SUCCESS: 'UPDATE_CUSTOMER_DETAILS_SUCCESS',
    UPDATE_CUSTOMER_DETAILS_FAILURE: 'UPDATE_CUSTOMER_DETAILS_FAILURE',
    
    DELETE_CUSTOMER_DETAILS_REQUEST: 'DELETE_CUSTOMER_DETAILS_REQUEST',
    DELETE_CUSTOMER_DETAILS_SUCCESS: 'DELETE_CUSTOMER_DETAILS_SUCCESS',
    DELETE_CUSTOMER_DETAILS_FAILURE: 'DELETE_CUSTOMER_DETAILS_FAILURE',

    ASSIGN_CUSTOMER_DETAILS_REQUEST: 'ASSIGN_CUSTOMER_DETAILS_REQUEST',
    ASSIGN_CUSTOMER_DETAILS_SUCCESS: 'ASSIGN_CUSTOMER_DETAILS_SUCCESS',
    ASSIGN_CUSTOMER_DETAILS_FAILURE: 'ASSIGN_CUSTOMER_DETAILS_FAILURE',

    UPDATE_CUSTOMER_DETAILS_LANGUAGE_REQUEST: 'UPDATE_CUSTOMER_DETAILS_LANGUAGE_REQUEST',
    UPDATE_CUSTOMER_DETAILS_LANGUAGE_SUCCESS: 'UPDATE_CUSTOMER_DETAILS_LANGUAGE_SUCCESS',
    UPDATE_CUSTOMER_DETAILS_LANGUAGE_FAILURE: 'UPDATE_CUSTOMER_DETAILS_LANGUAGE_FAILURE',

    UPLOAD_CSV_REQUEST : 'UPLOAD_CSV_REQUEST',
    UPLOAD_CSV_SUCCESS : 'UPLOAD_CSV_SUCCESS',
    UPLOAD_CSV_FAILURE : 'UPLOAD_CSV_FAILURE',

    UPDATE_CUSTOMER_DETAILS_EMAIL_REQUEST: 'UPDATE_CUSTOMER_DETAILS_EMAIL_REQUEST',
    UPDATE_CUSTOMER_DETAILS_EMAIL_SUCCESS: 'UPDATE_CUSTOMER_DETAILS_EMAIL_SUCCESS',
    UPDATE_CUSTOMER_DETAILS_EMAIL_FAILURE: 'UPDATE_CUSTOMER_DETAILS_EMAIL_FAILURE',

}
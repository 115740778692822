import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { ListTab } from './ListTab';
import { MainTab } from './MainTab';

function UserActivity() {
    const [ActiveTab, SetActiveTab] = useState('DashboardTab');

    const TABS = {
        DashboardTab: "DashboardTab",
        UserActivityTab: "UserActivityTab",
    };

    let toggle = (tab) => {
        SetActiveTab(tab);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader> <i className="fas fa-chalkboard-teacher"></i> <b>User Activity Tracker</b></CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.DashboardTab,
                                        })}
                                        onClick={() =>
                                            toggle(TABS.DashboardTab)
                                        }
                                    >
                                        <span style={{ fontWeight: "bold" }}>Analytics</span>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem className=''>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.UserActivityTab,
                                        })}
                                        onClick={() => {
                                            toggle(TABS.UserActivityTab);
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold" }}>Trends</span>
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={ActiveTab}>
                                <TabPane tabId={TABS.DashboardTab}>

                                    <MainTab />
                                </TabPane>

                                {/* <TabPane tabId={TABS.UserActivityTab}>
                                    <ListTab />
                                </TabPane> */}
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export { UserActivity };


import React, { Component } from "react";
import { profileActions } from "../../actions";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";
import FormHelpText from '../../../components/Messages/FormHelpText';
import { currencyFormat } from "../../../helpers";

export default class UpdateBusinessTarget extends Component {
    constructor(props) {
        super(props);
        let days = new Date().getDate() - 1;
        this.state = {
            modalOpan: false,
            data: {
                amount: "",
            },
            errors: {}
        };
        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.proceed = this.proceed.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.setEditData = this.setEditData.bind(this);
    }

    showModal() {
        const { modalOpan } = this.state;
        this.setState({ modalOpan: !modalOpan });
        this.reset();
        this.setEditData();
        if (modalOpan == true) {
        }
    }

    reset() {
        this.setState({
            data: {
                amount: ""
            },
        })
    }

    setPaymentType(event) {
        const { data } = this.state;
        data["paymentType"] = parseInt(event.target.value, 10);
        this.setState({ data });
    }

    handleChange(event) {
        const { name, value, type } = event.target;

        let { data } = this.state;
        let val = value;

        if (name === "amount") {
            let regex_allowed = /^\d+(\.\d{0,2})?$/;
            if (value.length && regex_allowed.test(value) == false) {
                return false;
            }
        }

        data[name] = val;
        this.setState({ data });
    }

    isValidData() {
        const { data, errors } = this.state
        let error = false
        if (data.amount === "") {
            errors.amount = "this field is required";
            error = true;
        }
        this.setState({ errors })
        return error
    }
    proceed() {
        const { data } = this.state;
        const { dispatch, element, getData } = this.props;

        if (!this.isValidData()) {
            swal({
                title: "Are you sure ?",
                text: "Do you want to business target ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    let dataTosend = {
                        user_id: element.id,
                        business_target: data.amount,
                    }

                    console.log('dataTosend', dataTosend)
                    dispatch(
                        profileActions.updateBankDetail(dataTosend, {
                            success: () => {
                                this.showModal()
                                getData();
                                dispatch(profileActions.getHeaderDataCount())
                            }
                        })
                    );

                }
            });
        }
    }
    setEditData() {
        const { data } = this.state
        const { element } = this.props
        if (element && element.bank_account_details) {
            data.amount = element.amount
            this.setState({ data })
        }
    }
    componentDidMount() {
        this.setEditData()

    }
    render() {
        const { modalOpan, data, errors } = this.state;
        const { element } = this.props;
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
        };
        let _this = this;
        return (
            <div>

                <h5 className="mt-4">Your Monthly Business Target is {element && element.business_target ? parseInt(element.business_target,10) : 0}</h5>

                <Button color="success" onClick={_this.showModal} >
                    {element && element.bank_account_details && element.bank_account_details.amount ? 'Set Business Target' : 'Set Business Target'}
                </Button>
                <Modal className="modal-success" isOpen={modalOpan}>
                    <ModalHeader toggle={_this.showModal}>Set Business Target<br />{" "}
                        {/* <small>Add remaining negotiated offer</small> */}
                    </ModalHeader>
                    <ModalBody>


                        <React.Fragment>

                            <div className={" form-group mb-3"}>
                                <InputGroup>
                                    <InputGroupAddon
                                        addonType="prepend"
                                        id="ico-pan"
                                    >
                                        <InputGroupText>
                                            <i class="fas fa-bullseye"></i>{" "}
                                            <i style={mendetoryFields}>*</i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="amount"
                                        maxLength="4"
                                        value={data.amount}
                                        onChange={this.handleChange}
                                        className="form-control"
                                        type="text"
                                        placeholder="Business Target "
                                    />
                                </InputGroup>
                                {errors.amount && (
                                    <FormFeedback>
                                        {errors.amount}
                                    </FormFeedback>
                                )}
                            </div>
                        </React.Fragment>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.proceed}>
                            Proceed
                        </Button>
                        <Button onClick={_this.showModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

import { emailLogConstants } from "../constants"

export function getEmailLogs(state = {}, action) {
    switch (action.type) {
      case emailLogConstants.GET_EMAIL_LOGS_REQUEST:
        return { loading: true }
      case emailLogConstants.GET_EMAIL_LOGS_SUCCESS:
        return { data: action.result }
      case emailLogConstants.GET_EMAIL_LOGS_FAILURE:
        return {}
      default:
        return state
    }
  }
  
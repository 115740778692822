import { MilestoneLogsConstants } from "../constants"

export function getMilestoneLogs(state = {}, action) {
  switch (action.type) {
    case MilestoneLogsConstants.GET_MilestoneLogs_REQUEST:
      return { loading: true }
    case MilestoneLogsConstants.GET_MilestoneLogs_SUCCESS:
      return { data: action.result }
    case MilestoneLogsConstants.GET_MilestoneLogs_FAILURE:
      return {}
    default:
      return state
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  Button,  Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { TableLoader } from "../../../components/Loaders";
import { requirementLogsAction } from '../../actions'
import Pagination from "react-js-pagination";
import { AddSuperadminInteraction } from './AddSuperadminInteraction'
import DropdownList from 'react-widgets/lib/DropdownList';
import BuyerDetails from './BuyerDetails';
import { UnregisteredSellers } from './UnregisteredSellers'
import { Tooltip } from 'react-tippy';
class Selleres extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false,
            page : {
                perPage: 20,
                totalItemsCount: 0
            },
            serachField : {
                status : '1'
            }

        }
        this.handleToggle = this.handleToggle.bind(this);
        this.getData = this.getData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleToggle() {
        let { thisModel } = this.state;
        thisModel = !thisModel
        this.setState({
            thisModel: thisModel,
        },() => {
            if(thisModel == true) {
                this.getData()
            }
            else {
                this.handleReset()
            }
        })
    }

    handleChange(e) {
        const { serachField } = this.state
        const { name, value } = e.target;
        serachField[name] = value;

        this.setState({ serachField })
	}

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getData());
    }

    handleApply(e) {
        e.preventDefault();
        this.getData();
    }

    handleReset() {
        this.setState({
            serachField : {
                status : '1'
            }
        },() => this.getData());
    }

    getData() {
        const { buyer_requirement_id, dispatch } = this.props
        const { page, serachField } = this.state;
        let dataToSend = {
            buyer_requirement_id,
            active_page: page.activePage,
			per_page: page.perPage,
            status : serachField.status
        }
        dispatch(requirementLogsAction.getSellerDetail(dataToSend))
    }

    componentWillReceiveProps(props) {
		let { page } = this.state;
		page.totalItemsCount = 0;
		if (props.data) {
			page.totalItemsCount = props.data.total_records;
		}
	
		this.setState({
			page,
		});
	}

    render() {
        const { thisModel, page, serachField } = this.state
        const { loading, data, buyer_requirement_id , seller_count,unregistered_seller_count, requirement_details} = this.props
        return (
            <React.Fragment>
                {"  "}<Button size="sm"  color="primary" disabled={seller_count == 0 && unregistered_seller_count == 0 ? true : false} onClick={this.handleToggle}>Sellers { seller_count > 0 ?  <Tooltip interactive arrow={true} html={<small>Registered sellers count</small>}><label className="badge badge-light mb-0">{seller_count}</label></Tooltip>   : false }  { unregistered_seller_count > 0 ?  <Tooltip interactive arrow={true} html={<small>Unregistered sellers Count</small>}><label className="badge badge-warning mb-0">{unregistered_seller_count}</label></Tooltip>   : false }  </Button>
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="lg" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> Sellers  </ModalHeader>
                    <ModalBody style={{ minHeight: '500px' }}>
                        <BuyerDetails data={requirement_details}/>
                        {loading ? <TableLoader /> :
                            (
                                <div className="table-responsive-sm animated fadeIn">
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        
                                        <thead className="thead-light">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th>
                                                    <select className="form-control" name="status" value={serachField.status} onChange={this.handleChange}>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Not Interested</option>
                                                        <option value="3">Done</option>
                                                    </select>
                                                </th>
                                                <th>
                                                <Button color="primary" onClick={(e) => this.handleApply(e)} style={{ 'margin-left': '10px' }}>Fetch</Button>
                                                <Button  color="secondary"  onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Owner Name</th>
                                                <th>Owner Mobile</th>
                                                <th>Product Tags</th>
                                                <th>Quote Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                            {
                                                data && data.sellers_detail.length ?
                                                    data.sellers_detail.map(element => {
                                                        return (
                                                            <tr>

                                                                <td>{element.company_name}</td>
                                                                <td>{element.owner_name}</td>
                                                                <td>{element.owner_mobile}</td>
                                                                <td>
                                                                    {
                                                                       element.products &&  element.products.length ?
                                                                       element.products.map((eachProduct) => {
                                                                           return eachProduct.product_name
                                                                       }).join(",")
                                                                       :''
                                                                    }
                                                                </td>
                                                                <td>{element.status}</td>
                                                                <td> <AddSuperadminInteraction 
                                                                        buyer_requirement_id={buyer_requirement_id} 
                                                                        seller_company_reputeid={element.company_reputeid} 
                                                                        seller_call_detail={element.seller_call_detail}
                                                                        getData={this.getData}
                                                                    /> 
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="6" className="text-center"> No seller </td></tr>
                                            }

                                        </tbody>
                                    </table>
                                    {page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}
									
								/>
							)}
                                </div>
                            )}
                            <UnregisteredSellers buyer_requirement_id={buyer_requirement_id} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleToggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { data, loading } = state.getSellerDetail
    return { data , loading }
}
const SelleresConnected = connect(mapStateToProps)(Selleres);
export { SelleresConnected as Selleres }
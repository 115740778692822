import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { NoDocumentsAvailable } from "../../../../../components/ErrorPages/NoDocumentsAvailable";
import { DocumentPreview } from '../../Individual/Comman/DocumentsPreview';

const TABS = {
    DOCUMENTS: "documents",
    OTHER_DOCUMENTS: "other_documents",
}

class Documents extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.DOCUMENTS
        }

        this.toggle = this.toggle.bind(this)

    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }


    render() {

        const { profile } = this.props

        let documents = {};
        if (profile) {
            documents = profile.documents;
        }

        return (
            <div className="">

                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.DOCUMENTS })}
                                onClick={() => { this.toggle(TABS.DOCUMENTS) }}
                            >
                            <b> <i className="fa fa-files-o"> </i> {" "}  Company Verification Documents </b>

                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.OTHER_DOCUMENTS })}
                                onClick={() => { this.toggle(TABS.OTHER_DOCUMENTS) }}
                            >
                                <Translate id='Documents' />
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={TABS.DOCUMENTS}>
                            <div className="row">

                                {documents.hasOwnProperty("PHOTO") || documents.hasOwnProperty("PAN_CARD") || documents.hasOwnProperty("AADHAR_CARD") || documents.hasOwnProperty("GSTIN")  ? <React.Fragment>

                                    {documents.hasOwnProperty("PAN_CARD") ? DocumentPreview({
                                        title: "Pan Card",
                                        url: documents.PAN_CARD.url
                                    }) : ""}



                                    {documents.hasOwnProperty("GSTIN") ? DocumentPreview({
                                        title: "GSTIN",
                                        url: documents.GSTIN.url
                                    }) : ""}
                                </React.Fragment>
                                    : <React.Fragment> <NoDocumentsAvailable/> </React.Fragment>}
                            </div>


                        </TabPane>
                        {/* <TabPane tabId={TABS.OTHER_DOCUMENTS}>
                            <div className="row">
                                {other_documents && other_documents.length ? other_documents.map(function (eachDoc) {
                                    return DocumentPreview({
                                        title: eachDoc.value,
                                        url: eachDoc.url
                                    })
                                }) : <React.Fragment> <NoDocumentsAvailable/> </React.Fragment>}

                            </div>
                        </TabPane> */}
                    </TabContent>
                </div>


            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

const connectedDocuments = connect(mapStateToProps)(Documents)
export { connectedDocuments as Documents };


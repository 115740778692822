export const SMSConstants = {
    ADD_SMS_REQUEST: 'ADD_SMS_REQUEST',
    ADD_SMS_SUCCESS: 'ADD_SMS_SUCCESS',
    ADD_SMS_FAILURE: 'ADD_SMS_FAILURE',

    GET_SMS_REQUEST: 'GET_SMS_REQUEST',
    GET_SMS_SUCCESS: 'GET_SMS_SUCCESS',
    GET_SMS_FAILURE: 'GET_SMS_FAILURE',

    EDIT_SMS_REQUEST: 'EDIT_SMS_REQUEST',
    EDIT_SMS_SUCCESS: 'EDIT_SMS_SUCCESS',
    EDIT_SMS_FAILURE: 'EDIT_SMS_FAILURE',

    DELETE_SMS_REQUEST: 'DELETE_SMS_REQUEST',
    DELETE_SMS_SUCCESS: 'DELETE_SMS_SUCCESS',
    DELETE_SMS_FAILURE: 'DELETE_SMS_FAILURE',

    PUBLISH_SMS_REQUEST: 'PUBLISH_SMS_REQUEST',
    PUBLISH_SMS_SUCCESS: 'PUBLISH_SMS_SUCCESS',
    PUBLISH_SMS_FAILURE: 'PUBLISH_SMS_FAILURE',
}
import { notify } from 'reapop'
import { leadsConstants } from '../constants'
import { leadsService } from '../services'

export const leadsAction = {
    getLeads,
}


function getLeads(data) {
    return dispatch => {
        dispatch(request())

        leadsService.getLeads(data)
            .then(
                verified =>{
                    console.log(`verified`);
                    console.log(verified);
                     dispatch(success(verified))
                    },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type:  leadsConstants.GET_LEADS_REQUEST } }
    function success(result) { return { type:  leadsConstants.GET_LEADS_SUCCESS, result } }
    function failure(error) { return { type:  leadsConstants.GET_LEADS_FAIL, error } }
}

import { adminContentConfigConstants } from "../constants"

export function getAdminContentConfigtData(state = {}, action) {
    switch (action.type) {
      case adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_REQUEST:
        return { loading: true }
      case adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_SUCCESS:
        return { data: action.result }
      case adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function updateAdminContentConfigData(state = {}, action) {
    switch (action.type) {
      case adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_REQUEST:
        return { loading: true }
      case adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_SUCCESS:
        return { data: action.result }
      case adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_FAILURE:
        return {}
      default:
        return state
    }
  }  
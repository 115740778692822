import { notify } from 'reapop'
import { communicationTemplateConstants } from '../constants'
import { communicationTemplateService } from '../services'

export const communicationTemplateActions = {   
    addCommunicationTemplate,
    getCommunicationTemplate,
    editCommunicationTemplate,   
    publishCommunicationTemplate
}

function addCommunicationTemplate(data) {
    return dispatch => {
        dispatch(request({ data }));

        communicationTemplateService.addCommunicationTemplate(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_REQUEST } }
    function success(result) { return { type: communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_SUCCESS, result } }
    function failure(error) { return { type: communicationTemplateConstants.ADD_COMMUNICATION_TEMPLATE_FAILURE, error } }
}

function getCommunicationTemplate(data) {
    return dispatch => {
        dispatch(request())
        communicationTemplateService.getCommunicationTemplate(data)
            .then(
                result => 
                    dispatch(success(result)),
             
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_REQUEST } }
    function success(result) { return { type: communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_SUCCESS, result } }
    function failure(error) { return { type: communicationTemplateConstants.GET_COMMUNICATION_TEMPLATE_FAILURE, error } }
}

function editCommunicationTemplate(data,ref) {
    return dispatch => {
        dispatch(request())
        communicationTemplateService.editCommunicationTemplate(data)
            .then(
                result => { 
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Successfully', status: "success" }))
                    ref.afterSave()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_REQUEST } }
    function success(result) { return { type: communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_SUCCESS, result } }
    function failure(error) { return { type: communicationTemplateConstants.EDIT_COMMUNICATION_TEMPLATE_FAILURE, error } }
}

function publishCommunicationTemplate(data,ref) {
    return dispatch => {
        dispatch(request())
        communicationTemplateService.publishCommunicationTemplate(data)
            .then(
                verified => { 
                    dispatch(success(verified))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Published Successfully', status: "success" }))
                    ref.getCommunicationTemplate()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationTemplateConstants.PUBLISH_COMMUNICATION_TEMPLATE_REQUEST } }
    function success(result) { return { type: communicationTemplateConstants.PUBLISH_COMMUNICATION_TEMPLATE_SUCCESS, result } }
    function failure(error) { return { type: communicationTemplateConstants.PUBLISH_COMMUNICATION_TEMPLATE_FAILURE, error } }
}
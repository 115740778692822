import { SmsPackageConstants } from '../constants'

export function getSMSPackage(state = {}, action) {
  switch (action.type) {
    case SmsPackageConstants.GET_SMSPACKAGE_REQUEST:
      return { loading: true }
    case SmsPackageConstants.GET_SMSPACKAGE_SUCCESS:
      return { data: action.result }
    case SmsPackageConstants.GET_SMSPACKAGE_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateSMSPackage(state = {}, action) {
  switch (action.type) {
    case SmsPackageConstants.EDIT_SMSPACKAGE_REQUEST:
      return { loading: true }
    case SmsPackageConstants.EDIT_SMSPACKAGE_SUCCESS:
      return { data: action.result }
    case SmsPackageConstants.EDIT_SMSPACKAGE_FAILURE:
      return {}
    default:
      return state
  }
}
import { validPhone, validReputeIdIndividual, validEmail } from "../../../helpers";
export const handleEmailValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    // if (!fields[`email`]) {
    //     formIsValid = false
    //     errors[`email`] = 'Please enter email address' 
    // } 
     if (fields[`email`]) {
        errors[`email`] = [];
        
        fields[`email`].map( function( eachMobile,  index ) {
            if (!eachMobile) {
                formIsValid = false
                errors[`email`][index] = "Please enter email address"
            } else if (!validEmail(eachMobile)) {
                formIsValid = false
                errors[`email`][index]  = "Enter valid email address."
            }
            return false;
        })
    }
    return { errors, formIsValid }
}


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { whatsAppLogs } from '../../actions';
import { CardBody, Card, CardHeader } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import { renderCronStatus } from '../../../helpers';

class WhatsappSchedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: {
				perPage: 20,
				totalItemsCount: 0
			}
		};
		this.getWhatsappScheduleLogsData = this.getWhatsappScheduleLogsData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
	}

	componentDidMount() {
		this.getWhatsappScheduleLogsData();
	}

	getWhatsappScheduleLogsData() {
		const { dispatch } = this.props;
		const { page } = this.state;
		let dataToSend = {
			active_page: page.activePage,
			per_page: page.perPage
		};
		dispatch(whatsAppLogs.getWhatsappScheduleLogs(dataToSend));
	}

	handlePageChange(pageNumber) {
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => this.getWhatsappScheduleLogsData());
	}

	componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}
	render() {
		const { logs, loading } = this.props;
		const { page } = this.state;
		return (
			<Card>
				<CardHeader>
					<i className="fas fa-boxes" />
					<strong>Whatsapp Schedule  Logs</strong>
				</CardHeader>
				<CardBody>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-striped table-bordered table-outline">
									<thead className="thead-light">
										<tr>
											<th>Business Name</th>
											<th>Mobile</th>
											<th>Tempalte</th>
											<th>Created  At</th>
											<th>Execute  At</th>
										</tr>
									</thead>
									<tbody>
										{logs && logs.list && logs.list.length ? (
											logs.list.map(function (eachData, index) {
												return (
													<React.Fragment>
														<tr>
															<td>{eachData.business_name}</td>
															<td>{eachData.mobile}</td>
															<td>{eachData.tempalte}</td>
															<td>{eachData.created_at}</td>
															<td>{eachData.when_to_execute}</td>
														</tr>
													</React.Fragment>
												);
											})
										) : (
											<tr>
												<td colSpan="4" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}

								/>
							)}
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getWhatsappLogs;
	return {
		loading,
		logs: data
	};
}

const connectedWhatsappSchedule = connect(mapStateToProps)(WhatsappSchedule);
export { connectedWhatsappSchedule as WhatsappSchedule };


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Tooltip } from 'react-tippy';
import { Button } from 'reactstrap';
import { show } from 'redux-modal';
import swal from 'sweetalert';
import { verificationActions } from '../../../../actions';
import { documentStatus } from '../../../../constants';
import { AddReason } from './AddReason';

class VerifyDoucment extends Component {
    constructor(props) {
        super(props)
        this.verifyDocument = this.verifyDocument.bind(this)
        this.openModal = this.openModal.bind(this);
    }

    openModal(status) {
        const { dispatch, repute_id, document_type } = this.props;
        let dataToSend = {
            document_type: document_type,
            document_status: status,
            repute_id: repute_id,
            request_from: "company",
        }
        dispatch(show("bootstrap", {
            ModelSize: "md",
            ModelHeaderText: "Reason ",
            ModelBodyText: <AddReason data={dataToSend} />,
            isFooter: false
        }));
    }

    verifyDocument(status) {
        const { dispatch, repute_id, document_type } = this.props;

        let helptext = "Do you want to Reject ?"

        if (status === documentStatus.ACCEPTED) {
            helptext = "Do you want to Accept ?"
        }

        swal({
            title: "Are you sure ?",
            text: helptext,
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                let dataToSend = {
                    document_type: document_type,
                    document_status: status,
                    repute_id: repute_id,
                    request_from: "company",
                }

                dispatch(verificationActions.verifyIndividualCompany(dataToSend, this))

            }
        })

    }
    
    afterAction() {
        const { dispatch, repute_id } = this.props;
        dispatch(verificationActions.getCompanyDetail({ reputeId: repute_id }))
    }

    render() {

        const { status,is_cheque_verified } = this.props;

        return (<React.Fragment>
           {status !== documentStatus.ACCEPTED ?
                <React.Fragment>

                    {!is_cheque_verified ?
                        <div >
                            <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Cheque payment is not verified.">
                                <Button className={"btn-success"} size="xs" disabled={true} > Accept </Button>
                                <Button className={"btn-danger"} size="xs" disabled={true} > Reject </Button>
                            </Tooltip>
                        </div> : <div >
                            <Button className={"btn-success"} size="xs" onClick={(e) => this.verifyDocument(documentStatus.ACCEPTED)} > Accept </Button>
                    <Button className={"btn-danger"} size="xs" onClick={(e) => this.openModal(documentStatus.REJECTED)} > Reject </Button>
               </div>}
                </React.Fragment> : ""}
        </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {}
}


const connectedVerifyDoucment = withRouter(connect(mapStateToProps)(VerifyDoucment));

export { connectedVerifyDoucment as VerifyDoucment };


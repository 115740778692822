import React from "react";
import MaskedInput from 'react-text-mask';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';


export const PanInput = ({ name, value, handleChange, disabled }) => {
    return <MaskedInput
        className="form-control"
        type="text"
        mask={[/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[p|P|c|C|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G|k|K]/, /[A-Za-z]/, /\d/, /\d/, /\d/, /\d/, /[A-Za-z]/]}
        name={name}
        value={value}
        disabled={disabled}
        guide={false}
        onChange={handleChange}
        placeholder="PAN"
    />
}

export const SearchInput = ({ searchForm, handleChange, handleSubmit, handleReset }) => {
    return <React.Fragment>
        <Form inline autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
                <Input placeholder="Search.." name="keywords" value={searchForm.keywords} onChange={handleChange} /> &nbsp;&nbsp;
                <Button type="submit" color="primary"> Search</Button>  &nbsp;
                <Button color="secondary" onClick={handleReset}> Reset</Button>
            </FormGroup>
        </Form>
    </React.Fragment>
}

export const AadharInput = ({ name, value, handleChange, disabled }) => {
    return <MaskedInput
        className="form-control"
        type="text"
        mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
        placeholder="Aadhaar"
        name={name}
        value={value}
        guide={false}
        disabled={disabled}
        onChange={handleChange} />
}

export const GstNoInput = ({ name, value, handleChange, disabled }) => {
    return <MaskedInput
        className="form-control"
        type="text"
        mask={[/\d/, /\d/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[p|P|c|C|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G|k|K]/, /[A-Za-z]/, /\d/, /\d/, /\d/, /\d/, /[A-Za-z]/, /[A-Za-z0-9]/,  /[A-Za-z]/, /[A-Za-z0-9]/]}
        placeholder="GSTIN"
        name={name}
        value={value}
        guide={false}
        disabled={disabled}
        onChange={handleChange} />
}

export const ReputeIDCompanyInput = ({ name, value, handleChange, disabled, appendAddon }) => {
    return <React.Fragment>
        <InputGroup >
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    RC-
                </InputGroupText>
            </InputGroupAddon>
            <MaskedInput
                className="form-control"
                type="text"
                mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                placeholder="Repute Id"
                name={name}
                value={value}
                guide={false}
                disabled={disabled}
                onChange={handleChange} />
            {appendAddon}
        </InputGroup>
    </React.Fragment>

}

export const ReputeIDIndividualInput = ({ name, value, handleChange, disabled }) => {
    return <React.Fragment>
        <InputGroup >
            <InputGroupAddon addonType="prepend">
                <InputGroupText> RI-</InputGroupText>
            </InputGroupAddon>
            <MaskedInput
                className="form-control"
                type="text"
                mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                placeholder="Repute Id"
                name={name}
                value={value}
                guide={false}
                disabled={disabled}
                onChange={handleChange} />

        </InputGroup>
    </React.Fragment>

}
export const ReputeIDIndividualOrCompanyInput = ({ handleChangeSelect, selectValue, name, value, handleChange, disabled }) => {
    return <React.Fragment>
        <InputGroup>
            <select className="selectpicker form-control homesearchselectpicker" disabled={disabled} name={"ReputeIdValue"} onChange={handleChange} value={selectValue} >
                <option>RI</option>
                <option>RC</option>
            </select>
            <MaskedInput
                className="form-control"
                type="text"
                mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                placeholder="Repute Id"
                name={name}
                value={value}
                guide={false}
                disabled={disabled}
                onChange={handleChange} />
        </InputGroup>
    </React.Fragment>
}

export const MobileNoInput = ({ name, value, handleChange, disabled }) => {
    return <React.Fragment>
        <InputGroup >
            <InputGroupAddon addonType="prepend">
                <InputGroupText> +91 </InputGroupText>
            </InputGroupAddon>
            <MaskedInput
                className="form-control"
                type="text"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                placeholder="Mobile No"
                name={name}
                value={value}
                guide={false}
                disabled={disabled}
                onChange={handleChange} />
        </InputGroup>
    </React.Fragment>

}


export const SearchAndSortInput = ({ searchForm, handleChange, handleSubmit, handleReset, handleChangeDropDown, is_show_gstField }) => {
    return <React.Fragment>
        <Form inline autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
                <div>
                    <Label style={{ textAlign: 'left', justifyContent: 'left' }}>Search</Label>
                    <Input placeholder="Search.." name="keywords" value={searchForm.keywords} onChange={handleChange} />
                </div>
                &nbsp;&nbsp;
                <div>
                    <Label className='' style={{ textAlign: "left", justifyContent: "left" }} >Sort by Update</Label>
                    <DropdownList
                        className="form-control-select  "
                        style={{ width: "200px" }}
                        placeholder="Select Sort order"
                        valueField="value"
                        textField="label"
                        value={searchForm.dropdownValue}
                        onChange={(dt) => {
                            handleChangeDropDown('order_by', dt.value)
                        }}
                        data={[
                            { value: 'created_at', label: 'Last Created' },
                            { value: 'updated_at', label: 'Last Updated' },
                        ]}
                        name={"name"}
                    />
                </div>
                {is_show_gstField && <div>

                    <Label style={{ textAlign: "left", justifyContent: "left" }} >Sort By GST</Label>
                    <DropdownList
                        className="form-control-select  "
                        style={{ width: "200px" }}
                        placeholder="GST Filter"
                        valueField="value"
                        textField="label"
                        value={searchForm.dropdownValue}
                        onChange={(dt) => handleChangeDropDown('gst_filter', dt.value)}
                        data={[
                            { value: 'all', label: 'All' },
                            { value: 'with_gst', label: 'With GST' },
                            { value: 'without_gst', label: 'Without GST' },
                        ]}
                        name={"dropdown"}
                    />
                </div>}

                &nbsp;&nbsp;
                <div>
                    <Label>&nbsp;&nbsp;</Label>
                    <Button type="submit" color="primary">Search</Button>
                </div>&nbsp;
                <div>
                    <Label>&nbsp;&nbsp;</Label>
                    <Button color="secondary" className='mt-auto' onClick={handleReset}>Reset</Button>
                </div>
            </FormGroup>
        </Form>
    </React.Fragment>
}


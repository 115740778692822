import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { TabContentData } from './TabContentData'
import { ExceptionLogsActions, CriticalDue } from '../../actions';

const TABS = {
    directBadDebt: "directBadDebt",
    sendbillBadDebt: "sendbillBadDebt",
}

class BadDebtSearchLogs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.directBadDebt,
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            }
        }

        // this.toggle = this.toggle.bind(this)
        // this.getData = this.getData.bind(this)
    }

    // componentDidMount() {
    //     this.getData();
    // }

    // toggle(tab) {
    //     if (this.state.activeTab !== tab) {
    //         this.setState({
    //             activeTab: tab
    //         }, function () {
    //             this.getData();
    //         })
    //     }
    // }

    // getData() {
    //     const { dispatch } = this.props;
    //     const { page } = this.state;
    //     let dataToSend = {
    //         active_page: page.activePage,
    //         per_page: page.perPage,
    //         log_type: this.state.activeTab
    //     }
    //     dispatch(CriticalDue.getDirectCriticalDue(dataToSend));
    // }

    render() {
        const { data, loading } = this.props;

        return (
            <div>
                <h5 className="mb-4 mt-4 text-danger">
                    <i className="fas fa-rupee"></i> &nbsp; Critical Due Logs
                </h5>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.directBadDebt })}
                            // onClick={() => { this.toggle(TABS.directBadDebt) }}
                        >
                            <b>Direct Critical Due</b>

                        </NavLink>
                    </NavItem>
                    {/* <NavItem>

                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.sendbillBadDebt })}
                            onClick={() => { this.toggle(TABS.sendbillBadDebt) }}
                        >
                            <b>Send Bill Critical Due</b>

                        </NavLink>
                    </NavItem> */}

                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane  tabId={TABS.directBadDebt} >
                        <TabContentData loading={loading} data={data} isSuperAdmin={true} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.sendbillBadDebt}>
                        <h1>Coming Soon </h1>
                        {/* <TabContentData loading={loading} data={data} isSuperAdmin={false} activeTab={this.state.activeTab} /> */}
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getDirectCriticalDue;
    return {
        loading,
        data
    }
}
const connectedBadDebtSearchLogs = connect(mapStateToProps)(BadDebtSearchLogs)
export { connectedBadDebtSearchLogs as BadDebtSearchLogs }

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Button, FormFeedback } from 'reactstrap';
import { currencyFormat } from '../../../helpers';
import { MarketingActions } from '../../actions/marketing.action';

import Chart from "react-apexcharts";
import { ReportValuesModal } from './Common/ReportValuesModal';

function MarketingAnalysis(props) {
    const [data, setData] = useState([])
    const [errors, setErrors] = useState({})
    const [dateData, setDateData] = useState({
        startDate: moment().subtract(30, "days"),
        endDate: moment(),
        serachField: {
            dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
        },
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        },

    })
    const { WhatsappAnalysis } = props

    const handleApply = () => {
        const { dispatch } = props;
        const { startDate, endDate } = dateData
        if (!validation()) {
            let dataToSend = {
                end_date: startDate.format('YYYY-MM-DD'),
                start_date: endDate.format('YYYY-MM-DD')
            };

            dispatch(MarketingActions.marketingWhatsappAnalysis(dataToSend));
        }
    }

    useEffect(() => {
        setData(WhatsappAnalysis)
    }, [WhatsappAnalysis]);

    useEffect(() => {
        handleApply();
    }, []);

    const handleReset = () => {
        setDateData({
            startDate: moment().subtract(30, "days"),
            endDate: moment(),
            serachField: {
                dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
            }
        })
        setData([])
        setErrors({})
        handleApply();
    }

    const validation = () => {
        const { startDate, endDate } = dateData
        let error = false;
        let errors = {};
        if (startDate === '' || endDate === '') {
            errors.startDate = 'Please select Date';
            error = true;
        }
        setErrors({ errors });
        return error;
    }



    const handleEvent = (event, picker) => {
        var start = picker.startDate.format("DD MMM Y");
        var end = picker.endDate.format("DD MMM Y");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }
        setDateData({
            ...dateData,
            startDate: picker.startDate,
            endDate: picker.endDate,
            serachField: {
                dateRange: label
            },
        })
    }


 


    const { startDate, endDate, serachField, ranges } = dateData
    return (

        <React.Fragment>

            <div className="form-group">
                <div>
                    <div className=' d-flex  mt-2' >
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onEvent={handleEvent}
                            ranges={ranges}
                        >
                            <div className="input-group">
                                <input type="text" className="form-control" value={serachField.dateRange} placeholder="Select Date Range" id="date_picker" style={{ 'width': '220px' }} />
                                <span className="input-group-btn">
                                    <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                        <i className="fa fa-calendar" />
                                    </Button>
                                </span>

                            </div>
                        </DateRangePicker>

                        <Button color="primary" className="ml-2" onClick={handleApply}>
                            Fetch
                        </Button>
                        <Button color="secondary" className="ml-2" onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                    <FormFeedback>{errors[`startDate`] && errors[`startDate`]}</FormFeedback>
                </div>
            </div>
            {data && data.length ?
                <div className="card">
                    <div className="card-body">
                        <Chart
                            options={{
                                chart: {
                                    id: "Date"
                                },
                                xaxis: {
                                    categories: data.map((eachData) => {
                                        return eachData.date
                                    }).reverse()
                                },
                                //     tooltip: {
                                //         custom: function({series, seriesIndex, dataPointIndex, w}) {
                                //           var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                                //           return '<ul class="list">' +
                                //           '<li><b>Date</b>: ' + data.date + '</li>' +
                                //           '<li><b>Leads</b>: ' + data.leads + '</li>' +
                                //           '<li><b>Registered</b>: ' + data.registered + '</li>' +
                                //           '<li><b>Lead Percentage</b>: ' + data.percentage + '% </li>' +
                                //           '<li><b>Earnings</b>: ' + data.earning + '</li>' +
                                //           '</ul>';
                                //         }
                                //   }
                            }}

                            series={[
                                {
                                    name: "Leads",
                                    data: data.map((eachData) => {
                                        return eachData.leads
                                    }).reverse()
                                }
                                , {
                                    name: "Registerd",
                                    data: data.map((eachData) => {
                                        return eachData.registered
                                    }).reverse()
                                }
                            ]
                            }


                            type="line"
                            width="100%"
                            height={300}
                        />
                    </div>    </div> : false}

            {data && <div className="table-responsive-sm  animated fadeIn">
                <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                    <thead className="thead-light">
                    <tr>
                            <th></th>
                            <th></th>
                            <th colSpan={4} className='text-center bg-warning-light'>Downloads</th>
                            <th className='text-center bg-primary-light'></th>
                            <th className='text-center bg-success-light'></th>
                            <th></th>
                            <th></th>
                            <th> </th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>No.</th>
                            <th>Date</th>
                            <th className='text-center bg-warning-light'>Google</th>
                            <th className='text-center bg-warning-light'>Facebook</th>
                            <th className='text-center bg-warning-light'>Direct</th>
                            <th className='text-center bg-warning-darklight'>Total Downloads</th>
                            <th className='text-center bg-primary-light'>Leads</th>
                            <th className='text-center bg-success-light'>Registered</th>
                            <th>Download Registerd %</th>
                            <th>Lead Registered %</th>
                            <th>Rate </th>
                            <th>Earnings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length ? (
                            data.map(function (eachData, index) {
                                return (
                                    <React.Fragment key={index} >
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{eachData.date}</td>
                                            <td  style={{width:"10%"}}>
                                            
                                            <ReportValuesModal title='Add Google Downloads Data' currentvalue={eachData.downloads } type='regisration_analysis_registered' getData={handleApply}   data={eachData}  /> 
                                           </td>
                                           <td  style={{width:"10%"}}>
                                            <ReportValuesModal title='Add Facebook Downloads Data' currentvalue={eachData.facebook_downloads } type='regisration_analysis_facebook_downloads' getData={handleApply}   data={eachData}  /> 
                                           </td>
                                           <td  style={{width:"10%"}}>
                                            <ReportValuesModal title='Add Direct Downloads Data' currentvalue={eachData.direct_downloads } type='regisration_analysis_direct_downloads' getData={handleApply}   data={eachData}  /> 
                                           </td>
                                            <td >{eachData.total_downloads}</td>
                                            <td  >{eachData.leads}</td>
                                            <td >{eachData.registered}</td>
                                            <td>{eachData.downloads_percentage}</td>
                                            <td>{eachData.percentage}</td>
                                            <td >
                                                <span className='float-right'>
                                                    {(eachData.earning === 0 || eachData.registered === 0) ? '₹' + "0.00" : currencyFormat((eachData.earning / eachData.registered), true)}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='float-right'>
                                                    {currencyFormat(eachData.earning, true)}
                                                </span>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    No Data Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>}
            
        </React.Fragment>


    )
}

function mapStateToProps(state) {
    const { marketingWhatsappAnalysis } = state

    return {
        WhatsappAnalysis: marketingWhatsappAnalysis.data
    }
}


const connectedMarketingAnalysis = connect(mapStateToProps)(MarketingAnalysis)
export { connectedMarketingAnalysis as MarketingAnalysis };


import { SalesConstants } from '../constants'

export function getCustomerList(state = {}, action) {
  switch (action.type) {
    case SalesConstants.GET_CUSTOMER_LIST_REQUEST:
      return { loading: true }
    case SalesConstants.GET_CUSTOMER_LIST_SUCCESS:
      return { data: action.result }
    case SalesConstants.GET_CUSTOMER_LIST_FAILURE:
      return {}
    default:
      return state
  }
}

export function getCustomerStatusData(state = {}, action) {
  switch (action.type) {
    case SalesConstants.GET_CUSTOMER_SALES_STATUS_REQUEST:
      return { loading: true }
    case SalesConstants.GET_CUSTOMER_SALES_STATUS_SUCCESS:
      return { 
        data: action.result.customerSalesStatus,
        customerDetail: action.result.CustomerDetail,
        userData: action.result.Users
      }
    case SalesConstants.GET_CUSTOMER_SALES_STATUS_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateCustomerSalesStatus(state = {}, action) {
  switch (action.type) {
    case SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_REQUEST:
      return { loading: true }
    case SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_SUCCESS:
      return { data: action.result }
    case SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_FAILURE:
      return {}
    default:
      return state
  }
}

export function addDemoAppData(state = {}, action) {
  switch (action.type) {
    case SalesConstants.ADD_DEMO_APP_DATA_REQUEST:
      return { loading: true }
    case SalesConstants.ADD_DEMO_APP_DATA_SUCCESS:
      return { data: action.result }
    case SalesConstants.ADD_DEMO_APP_DATA_FAILURE:
      return {}
    default:
      return state
  }
}
 


import { notify } from 'reapop'
import { communicationMastersConstants } from '../constants'
import { communicationMastersService } from '../services'

export const communicationMastersActions = {
    saveCommunicationMasters,
    getCommunicationMasters
}



function getCommunicationMasters(data) {
    return dispatch => {
        dispatch(request())

        communicationMastersService.getCommunicationMasters(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationMastersConstants.GET_COMM_MASTERS_DATA_REQUEST } }
    function success(result) { return { type: communicationMastersConstants.GET_COMM_MASTERS_DATA_SUCCESS, result } }
    function failure(error) { return { type: communicationMastersConstants.GET_COMM_MASTERS_DATA_FAILURE, error } }
}

function saveCommunicationMasters(data, callback) {
    return dispatch => {
        dispatch(request())

        communicationMastersService.saveCommunicationMasters(data)
            .then(
                data => {
                    dispatch(success(data))
                    callback.success();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Communication details saved succesfully', status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationMastersConstants.SAVE_COMM_MASTERS_DATA_REQUEST } }
    function success(result) { return { type: communicationMastersConstants.SAVE_COMM_MASTERS_DATA_SUCCESS, result } }
    function failure(error) { return { type: communicationMastersConstants.SAVE_COMM_MASTERS_DATA_FAILURE, error } }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, FormFeedback, FormGroup, Input, FormText, Collapse } from 'reactstrap'
import { handleValidation } from './Validation'
import { SMSActions } from '../../actions'
import { TableLoader } from '../../../components/Loaders';
import { Link } from "react-router-dom";
import { DATA_TO_REPLACE_FOR_LIVE_PREVIEW } from '../../constants'

class AddSMS extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      addSMS: {
        id: '',
        sms_text: '',
        sms_type: '',
        flow_id: '',
      },
      errors: {},
      isChange: false,
    }
    this.getSms = this.getSms.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmitSMS = this.onSubmitSMS.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
  }

  componentDidMount() {
    this.getSms()
  }

  getSms() {
    const { dispatch } = this.props;
    const values = this.props.match.params.id
    let dataToSend = {
      id: values
    }
    dispatch(SMSActions.getSMS(dataToSend))
  }
  componentWillReceiveProps(props) {
    const { data } = props
    if (data) {
      this.setState({ addSMS: data })
    }
  }
  handleChange(e) {
    e.preventDefault()
    const { addSMS } = this.state
    addSMS[e.target.name] = e.target.value
    this.setState({ addSMS })
  }

  onSubmitSMS() {
    const { addSMS } = this.state
    const result = handleValidation(addSMS)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      const { dispatch } = this.props;
      this.setState({ saving: true })
      dispatch(SMSActions.editSMS(addSMS, this));
    }
  }
  afterSave() {
    this.props.history.push('/sms')
  }
  toggleChange(e) {
    e.preventDefault();
    this.setState({
      isChange: true
    });

  }

  renderLivePreview(text){
    for (let key in DATA_TO_REPLACE_FOR_LIVE_PREVIEW) {
      text = text.replace(key, DATA_TO_REPLACE_FOR_LIVE_PREVIEW[key])
    }
    return text
  }

  render() {
    const { errors, addSMS, saving, isChange } = this.state
    const { loading } = this.props
    return (
      <div className="">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mb-4 mt-4"> Update SMS </h3>

            {loading ? <TableLoader /> :
              <Form  autocomplete="off" style={{ width: '50%', margin: '0 auto' }}>

                <FormGroup>
                  <label className="font-weight-bold">SMS Text</label>
                  <span className="text-muted pull-right">{ parseInt(addSMS.sms_text.length,10 )} characters </span>
                  <Input placeholder="SMS Text" type="textarea" rows="5" maxLength="500" name="sms_text" value={addSMS.sms_text} onChange={this.handleChange} />
                  <FormText style={isChange ? { display: "none" } : {}}> <i> <b className="text-info"> {addSMS.help_text}  </b> </i>   <a className="text-danger pull-right font-weight-bold cursor-pointer" onClick={this.toggleChange} > Change </a></FormText>

                  <FormFeedback>
                    {errors[`sms_text`] && errors[`sms_text`]}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <label className="font-weight-bold">Flow ID</label>
                  <Input placeholder="Flow ID"   maxLength="500" name="flow_id" value={addSMS.flow_id} onChange={this.handleChange} />

                  <FormFeedback>
                    {errors[`flow_id`] && errors[`flow_id`]}
                  </FormFeedback>
                </FormGroup>

                <Collapse isOpen={isChange}>
                  <FormGroup>
                    <label className="font-weight-bold">Help Text</label>
                    <Input placeholder="Notification Text" type="text" rows="5" name="help_text" value={addSMS.help_text} onChange={this.handleChange} />
                    <FormFeedback>
                      {errors[`help_text`] && errors[`help_text`]}
                    </FormFeedback>
                  </FormGroup>
                </Collapse>


                <FormGroup>
                <label className="font-weight-bold">Live Preview</label> 
                <span className="text-muted pull-right">{ parseInt(this.renderLivePreview(addSMS.sms_text).length,10 )} characters </span><br/>
                  <span className="text-muted">{  this.renderLivePreview(addSMS.sms_text) } </span>
                  </FormGroup>

                <FormGroup>
                  <Button color="primary" onClick={this.onSubmitSMS} disabled={saving}> {saving ? "Saving..." : "Save Draft"}</Button>&nbsp;&nbsp;&nbsp;
                    <Link className="btn btn-secondary" to={"/sms"}> Cancel </Link>
                </FormGroup>


                

              </Form>}
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { loading, data } = state.getSMS
  const { user } = state.authentication
  return {
    loading,
    data :  data ? data.sms_data : {},
    authUser: user
  }

}

const connectedAddSMS = connect(mapStateToProps)(AddSMS)
export { connectedAddSMS as AddSMS }

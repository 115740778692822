export const SmsPackageConstants = {

    GET_SMSPACKAGE_REQUEST: 'GET_SMSPACKAGE_REQUEST',
    GET_SMSPACKAGE_SUCCESS: 'GET_SMSPACKAGE_SUCCESS',
    GET_SMSPACKAGE_FAILURE: 'GET_SMSPACKAGE_FAILURE',

    EDIT_SMSPACKAGE_REQUEST: 'EDIT_SMSPACKAGE_REQUEST',
    EDIT_SMSPACKAGE_SUCCESS: 'EDIT_SMSPACKAGE_SUCCESS',
    EDIT_SMSPACKAGE_FAILURE: 'EDIT_SMSPACKAGE_FAILURE',
}

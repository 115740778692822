


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import MaskedInput from 'react-text-mask'
import { supportActions } from '../../actions';
import InlineError from '../../../components/Messages/InlineError';


class CustomerSupportPINVerify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            support_pin: "",
            errors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.validation = this.validation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { value } = event.target;
        this.setState({ support_pin: value, errors: {} });
    }

    validation() {
        let error = false;
        const { errors, support_pin } = this.state;
        var numbers = /^[-+]?[0-9]+$/;

        if (support_pin.length !== 4 || support_pin.match(numbers) === false) {
            errors["support_pin"] = "Please enter valid support pin.";
            error = true;
        }

        this.setState({ errors })


        return error;

    }

    handleSubmit() {

        const { dispatch } = this.props;

        if (this.validation()) {
            return false;
        }

        let dataToSend = {
            support_pin: this.state.support_pin,
            repute_id: this.props.individual_repute_id
        }

        dispatch(supportActions.verifySupportPIN(dataToSend));
    }

    render() {
        const { errors } = this.state;
        const { verifying } = this.props;
        return (
            <Row>
                <Col md="4" lg="4">
                    <FormGroup>

                        <MaskedInput
                            className="form-control"
                            type="text"
                            mask={[/\d/, /\d/, /\d/, /\d/]}
                            name={"support_pin"}
                            placeholder={"Support PIN"}
                            value={this.state.support_pin}
                            onChange={this.handleChange} />
                        {errors.support_pin
                            && <InlineError message={errors.support_pin} />}

                    </FormGroup>
                </Col>

                <Col md="4">

                    <Button color="primary" onClick={this.handleSubmit} disabled={verifying} > {verifying ? "Please Wait..." : "Verify and Fetch Data"}  </Button>
                </Col>
            </Row>
        )
    }
}


function mapStateToProps(state) {

    const { verifying } = state.verifySupportPIN;

    return {
        verifying
    };

}

const connectedCustomerSupportPINVerify = connect(mapStateToProps)(CustomerSupportPINVerify);
export { connectedCustomerSupportPINVerify as CustomerSupportPINVerify }

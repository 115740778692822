


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Form, FormFeedback, FormGroup, FormText } from 'reactstrap'
import ReactQuill from 'react-quill';
import Editor from 'editor';
import { supportActions } from '../../../actions';
import { show, hide } from 'redux-modal';
import { ViewTicketDetails } from './ViewTicketDetails';

class GenerateTicketForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketDetails: {
                subject: "",
                description: "",
            },
            errors: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validation = this.validation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeHTML = this.handleChangeHTML.bind(this);

    }

    handleChange(event) {
        const { name, value } = event.target;
        const { ticketDetails } = this.state;
        ticketDetails[name] = value;
        this.setState({ ticketDetails });
    }

    handleChangeHTML(html) {
        const { ticketDetails } = this.state;
        ticketDetails["description"] = html;
        this.setState({ ticketDetails });
    }

    validation() {
        let error = false;
        const { errors, ticketDetails } = this.state;

        if (!ticketDetails.subject) {
            errors["subject"] = "Please enter ticket subject."
            error = true;
        }

        if (!ticketDetails.description) {
            errors["description"] = "Please enter ticket ddescription."
            error = true;
        }

        this.setState({ errors })
        return error;
    }

    handleSubmit() {
        const { ticketDetails } = this.state

        if (this.validation()) {
            return false;
        }

        const { dispatch, individual_repute_id, company_repute_id, isEdit } = this.props;

        if (isEdit) {
            let dataToSend = {
                subject: ticketDetails.subject,
                description: ticketDetails.description,
                ticket_id: ticketDetails.ticket_id,
            };

            dispatch(supportActions.createTicket(dataToSend, this));

            return false;
        }

        let dataToSend = {
            subject: ticketDetails.subject,
            description: ticketDetails.description,
            individual_repute_id: individual_repute_id,
            company_repute_id: company_repute_id,
        };

        dispatch(supportActions.createTicket(dataToSend, this));
    }

    afterSave() {

        const { dispatch, isEdit, inputParams } = this.props;
        const { ticketDetails } = this.state;
        dispatch(hide("bootstrap"))

        if (isEdit) {
            setTimeout(function () {
                dispatch(show("bootstrap", {
                    ModelSize: "lg",
                    ModelHeaderText: "Ticket Details : # " + ticketDetails.ticket_id,
                    ModelBodyText: <ViewTicketDetails {...this.props} ticket_id={ticketDetails.ticket_id} />,
                    isFooter: false
                }));
                dispatch(supportActions.getTicketDetails({ id: ticketDetails.ticket_id }));
            }, 100)
        } else {


            dispatch(supportActions.verifySupportPIN(inputParams));
        }

    }

    handleCancel() {
        const { dispatch } = this.props;

        dispatch(hide("bootstrap"))
    }

    componentDidMount() {
        const { isEdit, ticketsDetails } = this.props;

        if (isEdit) {
            if (ticketsDetails) {
                this.setState({
                    ticketDetails: {
                        subject: ticketsDetails.title,
                        description: ticketsDetails.description,
                        ticket_id: ticketsDetails.id,
                    }
                })
            }

        }
    }

    render() {
        const { ticketDetails, errors } = this.state;

        return (
            <React.Fragment>
                <Form>
                    <FormGroup>
                        <label className="font-weight-bold">Subject</label>
                        <Input placeholder="Subject" type="text" name="subject" value={ticketDetails.subject} onChange={this.handleChange} />
                        <FormText>A brief of your issue ticket</FormText>
                        <FormFeedback>
                            {errors[`subject`] && errors[`subject`]}
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <label className="font-weight-bold">Description</label>
                        <ReactQuill
                            theme={this.state.theme}
                            onChange={this.handleChangeHTML}
                            value={ticketDetails.description}
                            modules={Editor.modules}
                            formats={Editor.formats}
                            bounds={'.app'}
                            placeholder='Description' />
                        <FormText>Describe your issue here in details</FormText>

                        <FormFeedback>
                            {errors[`description`] && errors[`description `]}
                        </FormFeedback>
                    </FormGroup>
                    <Button color={"primary"} onClick={this.handleSubmit}>Submit</Button> &nbsp;
                    <Button color={"secondary"} onClick={this.handleCancel}>Cancel</Button>
                </Form>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    const { ticketsDetails } = state.getTicketDetails;
    const { inputParams } = state.verifySupportPIN;

    return {
        ticketsDetails,
        inputParams
    };

}

const connectedGenerateTicketForm = connect(mapStateToProps)(GenerateTicketForm);
export { connectedGenerateTicketForm as GenerateTicketForm }

import { notify } from 'reapop'
import { LegalConstants } from '../constants'
import { LegalService } from '../services'

export const LegalActions = {
    getLegal,
    publishLegal,
    saveLegal
}

function getLegal(data) {
    return dispatch => {
        dispatch(request())

        LegalService.getLegal(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: LegalConstants.GET_LEGAL_REQUEST } }
    function success(result) { return { type: LegalConstants.GET_LEGAL_SUCCESS, result } }
    function failure(error) { return { type: LegalConstants.GET_LEGAL_FAILURE, error } }
}

function publishLegal(data, ref) {
    return dispatch => {
        dispatch(request())

        LegalService.publishLegal(data)
            .then(
                result => {
                    ref.afterAction();
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Published.", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: LegalConstants.PUBLISH_LEGAL_REQUEST } }
    function success(result) { return { type: LegalConstants.PUBLISH_LEGAL_SUCCESS, result } }
    function failure(error) { return { type: LegalConstants.PUBLISH_LEGAL_FAILURE, error } }
}

function saveLegal(data) {
    return dispatch => {
        dispatch(request())

        LegalService.saveLegal(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: "Draft saved successfully.", status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: LegalConstants.EDIT_LEGAL_REQUEST } }
    function success(result) { return { type: LegalConstants.EDIT_LEGAL_SUCCESS, result } }
    function failure(error) { return { type: LegalConstants.EDIT_LEGAL_FAILURE, error } }
}

import React, { Component } from "react";
import { associationAction } from '../../actions';
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter} from "reactstrap";
import { TableLoader } from '../../../components/Loaders';
import { UpdateReputeID } from './UpdateReputeID';
import swal from "sweetalert";

class CompanyAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
    };
    this.showModal = this.showModal.bind(this);
    this.getAssociationCompany = this.getAssociationCompany.bind(this);

  }

  showModal() {
    const { modalOpan } = this.state;
    const { getData } = this.props;
    let _modalOpan = !modalOpan;
    this.setState({ modalOpan: _modalOpan }, () => {
      if (_modalOpan) {
        this.getAssociationCompany()
      } else {
        getData()
      }
    });
  }


  getAssociationCompany() {
    const { dispatch, association_id } = this.props;
    dispatch(associationAction.getAssociationCompany({
      association_id
    }));
  }


  delete(id) {
    const { dispatch } = this.props;

    swal({
      title: "Are you sure ?",
      text: "Do you want to remove company from association ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Cancel", "Yes, I am sure!"],
    }).then((confirm) => {
      if (confirm) {
        dispatch(
          associationAction.deleteAssociationLink({
            id: id,
            type: 'company',
          }, {
            success: () => {
              this.getAssociationCompany()
            }
          })
        );

      }
    });
  }

  render() {
    const { modalOpan, } = this.state;
    const { loading, data, eachData, association_id } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <Button color="warning" size="sm" onClick={_this.showModal}>
          Company <span className="badge badge-light mb-0 ">{eachData ? eachData.companies_count : 0}</span>
        </Button> {" "}
        <Modal className="modal-warning" isOpen={modalOpan} size="lg">
          <ModalHeader toggle={_this.showModal}>Company Association<br />{" "}
            {/* <small>Add remaining negotiated offer</small> */}
          </ModalHeader>
          <ModalBody>

            <div className="full-width">
              <UpdateReputeID type="company" getData={this.getAssociationCompany} association_id={association_id} />
            </div>
            {loading ? (
              <TableLoader />
            ) : (
              <React.Fragment>
                <div className="table-responsive-sm  animated fadeIn">
                  <table className="table table-sm table-condensed table-striped table-bordered table-outline pull-left">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Company</th>
                        <th>Owner</th>
                        <th>Owner Mobile</th>
                        <th className="text-center">Actions</th>

                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length ? (
                        data.map(function (eachData, index) {
                          return (
                            <React.Fragment>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{eachData.company_name} ({eachData.formatted_company_repute_id})</td>
                                <td>{eachData.owner_name} ({eachData.formatted_owner_repute_id})</td>
                                <td>{eachData.owner_mobile} </td>
                                <td className="text-center"> <Button color="danger" onClick={() => _this.delete(eachData.id)}><i className="fa fa-trash"></i></Button></td>

                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            {/* <Button color="warning" onClick={this.proceed}>
              Proceed
            </Button> */}
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, loading } = state.getAssociationCompany
  return {
    data, loading
  }
}

const connectedCompanyAssociation = connect(mapStateToProps)(CompanyAssociation)
export { connectedCompanyAssociation as CompanyAssociation }
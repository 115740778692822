import { notify } from 'reapop'
import { dashboardReportsConstants } from '../constants'
import { dashboardReportsService } from '../services'

export const dashboardReportsActions = {
    getDashboardReportsData,
    getPersonWiseReportsData,
    getProcessStages,
}



function getDashboardReportsData(data) {
    return dispatch => {
        dispatch(request())

        dashboardReportsService.getDashboardReportsData(data)
        .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_REQUEST } }
    function success(result) { return { params:data, type: dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_SUCCESS, result } }
    function failure(error) { return { type: dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_FAILURE, error } }
}


function getPersonWiseReportsData(data) {
    return dispatch => {
        dispatch(request())

        dashboardReportsService.getPersonWiseReportsData(data)
        .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_REQUEST } }
    function success(result) { return { params:data, type: dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_SUCCESS, result } }
    function failure(error) { return { type: dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_FAILURE, error } }
}

function getProcessStages(data) {
    return dispatch => {
        dispatch(request())

        dashboardReportsService.getProcessStages(data)
        .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_REQUEST } }
    function success(result) { return { type: dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_SUCCESS, result } }
    function failure(error) { return { type: dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_FAILURE, error } }
}

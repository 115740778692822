


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from '../../../../components/Tables/DataTable';
import { show } from 'redux-modal';
import { Button } from 'reactstrap';
import { ViewTicketDetails } from './ViewTicketDetails';
import { supportActions } from '../../../actions';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { IconStatus } from './components';

class CustomerTickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.openModal = this.openModal.bind(this);
        this.renderSubject = this.renderSubject.bind(this);
    }


    openModal(data) {
        const { dispatch } = this.props;
        dispatch(show("bootstrap", {
            ModelSize: "lg",
            ModelHeaderText: "Ticket Details : # " + data.id,
            ModelBodyText: <ViewTicketDetails {...this.props} ticket_id={data.id} />,
            isFooter:false
        }));
        dispatch(supportActions.getTicketDetails({ id: data.id }));
    }

    renderSubject(cell, row, rowIndex, formateExtraData) {
        return <Button color="link" size="link" onClick={() => this.openModal(row)}> {cell} </Button>
    }

    renderLastUpdate(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
             {moment(row.updated_at).format('DD-MM-YYYY hh:mm A')} (<TimeAgo date={row.updated_at} />)
        </React.Fragment>
    }
    renderStatus(cell, row, rowIndex, formateExtraData) {
        return  <IconStatus ticket_status_id={row.ticket_status_id}  status_name={row.ticket_status} />
    }
    render() {
        const { tickets } = this.props;

        const columns = [{
            text: "#",
            dataField: 'id',
        }, {
            text: "Subject",
            dataField: 'title',
            formatter: this.renderSubject
        }, {
            text: "Status",
            dataField: 'ticket_status',
            formatter: this.renderStatus 
        }, {
            text: "Last Update",
            dataField: 'updated_at',
            formatter: this.renderLastUpdate 
        }];
        return (
            tickets && tickets.length ?
                <React.Fragment>
                    <hr />
                    <p> <b>Tickets </b> </p>
                    <DataTable keyField="transaction_id"
                        data={tickets || []} columns={columns}
                        // filter={filterFactory()}
                        noDataIndication={"No data found"} />
                </React.Fragment> : ""
        )
    }
}


function mapStateToProps(state) {


}

const connectedCustomerTickets = connect(mapStateToProps)(CustomerTickets);
export { connectedCustomerTickets as CustomerTickets }

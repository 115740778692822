import React, { Component } from "react";
import { Card, CardBody, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, Badge } from "reactstrap";
import { processActions } from "../../../../actions";
import { AddNewStage } from "./AddNewStage";
import {StageItems} from "./StageItems";


import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import { connect } from "react-redux";
Sortable.mount(new MultiDrag(), new Swap());

class Stages extends Component {
    constructor(props) {
        super(props);
        this.state = {
          expand: "",
          stagesData : []
        };
        this.toggleExpand = this.toggleExpand.bind(this);
        this.save = this.save.bind(this)
        this.saveStageOrder = this.saveStageOrder.bind(this)
        
      }
    
    toggleExpand(idx) {
        const { expand } = this.state;
        if (idx === expand) {
            idx = "";
        }
        this.setState({ expand: idx });
    }

    componentDidMount(){
      const { stages } = this.props

      if(stages && stages.length > 0){
        this.setState({stagesData : stages})
      }

    }
    
    // componentWillReceiveProps(props){
    //   const { stages } = props

    //   if(stages && stages.length > 0){
    //     this.setState({stagesData : stages})
    //   }

    // }


    save(){  
        const { data } = this.state;
        const {  isEdit, getData, dispatch, processId } = this.props;
        
        if(this.validation()){
            return false;
        }
        let dataToSend = {
            process_name : data.process_name,
            process_order_number : 1,
            process_id : processId,
        }
        if(isEdit) {
            dataToSend.process_id = data.id
        }
        dispatch(processActions.saveProcess(dataToSend, {
            success:() => {
                this.clear()
                this.setState({ isOpen : false})
                getData()

            }
        }))
    }


    saveStageOrder(data){
      const {   dispatch, processId, getData } = this.props;
  
      let dataToSend = {
          action : "stage_order",
          is_stage : true,
          process_id : processId,
          order_data : JSON.stringify(data)
      }
    
      dispatch(processActions.saveProcess(dataToSend, {
          success:() => {
              // getData()
          }
      }))
    }

    render() {
        const { stages } = this.props
        
        const { expand, stagesData } = this.state;
        
        let _this = this;

        return (
            <Card>
                <CardBody>
              

            <ListGroup>
                
            <ListGroupItem active>
                <b>Stage List </b> &nbsp; &nbsp;  <AddNewStage
                    {...this.props}
                    
                    stage_order_number={stages.length && stages.length ? stages.length + 1 : 1 }
                />
            </ListGroupItem>

              {stagesData.length && stagesData.length > 0 ? (
<ReactSortable
      // here they are!
      group="groupStage"
      handle=".move-icon-stage"
      animation={200}
      delayOnTouchStart={true}
      delay={2}
      list={stagesData ? stagesData : []}
      onEnd={() => {
        // _this.setState({dataToSend : dataToSend})
        _this.saveStageOrder(_this.state.dataToSend)
      }}
      setList={(data) => { 
        let newOrderData = [];
        let dataToSend = [];
        data.map((each, idx)=>{
          let _d = { 
            id : each.id,
            stage_order_number : idx +1,
          }
          each.stage_order_number  = idx +1
          dataToSend.push(_d)
          newOrderData.push(each)
        })

        if(JSON.stringify(_this.state.stagesData) !== JSON.stringify(newOrderData ) ) {
          _this.setState({stagesData : newOrderData})
          _this.setState({dataToSend : dataToSend})
        }

      }}
    >
      <React.Fragment>
              { stagesData.map(function (stage) {
                return (
                  <React.Fragment>
                    <ListGroupItem onClick={() => _this.toggleExpand(stage.id)}>
                      <i className="fa fa-bars cursor-pointer move-icon-stage text-primary"></i>  &nbsp;&nbsp;
                        
                      <Badge color="primary"><b>{stage.stage_order_number}</b></Badge>  &nbsp;&nbsp;
                     
                      <b>{stage.stage_name}</b> &nbsp;&nbsp;
                      
                      <AddNewStage
                        isEdit={true}
                        {..._this.props}
                        current_data={stage}
                        stage_order_number={stage.stage_order_number}
                        RootItems={stages.stages}
                      />
                      
                      {expand === stage.id ? (
                        <i className={"fa fa-caret-down pull-right"}></i>
                      ) : (
                        <i className={"fa fa-caret-right pull-right"}></i>
                      )}
                    </ListGroupItem>
                    <Collapse isOpen={expand === stage.id}>
                        <ListGroupItem>
                            <StageItems current_data={stage} stageItems={stage.json} />
                        </ListGroupItem>
                    </Collapse>
                  </React.Fragment>
                );
                      
                    }) }
                    </React.Fragment>
                    </ReactSortable>)
              : <ListGroupItem header>No Stages Available</ListGroupItem>  } 
            </ListGroup>

            
            </CardBody>
            </Card>
        )
    }
}


function mapStateToProps(state) {
 
  return {
  };
}

const connectedProcess = connect(mapStateToProps)(Stages);
export { connectedProcess as Stages };

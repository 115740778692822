import { adminContentConfigConstants } from './adminContentConfig'

export const ADMIN_SETTING = {
    CONTENT_CONFIG : {
        HEADING : "Stages", 
        ITEMS : [
            {
                'key':adminContentConfigConstants.FIRST_CONTENT_FOR_TAKING_APPOINTMENT,
                'title' : 'RAW Lead',
                'path' : '/setting?st=raw-lead',
                // 'component' : <TEST/> 
            },

        ]
    } 
}

import React from 'react';
import { TICKET_STATUS } from "../../../constants";

export const IconStatus = ({ status_name, ticket_status_id }) => {
    let color;
    switch (parseInt(ticket_status_id, 10)) {
        case TICKET_STATUS.NEW:
        case TICKET_STATUS.RE_OPEN:
            color = "badge-primary"
            break;
        case TICKET_STATUS.IN_PROGRESS:
            color = "badge-warning"
            break;

        case TICKET_STATUS.RESOLVED:
            color = "badge-success"
            break;
        case TICKET_STATUS.CLOSE:
            color = "badge-secondary"
            break;
        default:
            color = "badge-primary"
    }

    return <span className={"badge badge-pill " + color}> {status_name} </span>
}

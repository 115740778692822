import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, CardHeader, Card } from 'reactstrap'
import { CustomerSupportSearch } from '../Support/CustomerSupportSearch'

class Support extends Component {
    render() {
        return (
            <CustomerSupportSearch/>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const connected =  connect(mapStateToProps)(Support)


export {connected as Support }
import { findFirst } from "obj-traverse/lib/obj-traverse";
import { findAndModifyFirst } from "obj-traverse/lib/obj-traverse";

import React, { Component } from "react";
import { connect } from "react-redux";
import UpdateEachStageContent from "./UpdateEachStageContent";

export class RenderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      json: "",
    };

    this.save = this.save.bind(this);
  }

  save(data) {
    let { json } = this.state;
    let { saveStageJSON } = this.props;

    let id = data.id;

    let item = findAndModifyFirst(
      { children: json },
      "children",
      { id: id },
      data
    );

    if (item) {
      this.setState({ json: item.children }, () =>
        saveStageJSON(item.children)
      );
    }
  }


  componentDidMount() {
    const { data } = this.props;

    this.setState({ json: data });
  }

  componentWillReceiveProps(props) {
    const { data } = props;
    let _data = data;
    if (typeof data === "string") {
      _data = JSON.parse(data);
    }

    this.setState({ json: _data });
  }

  render() {
    const { json } = this.state;
    return (
      <div>
        {typeof json === "object" &&
          json.length &&
          json.map((each) => {
            return (
              <UpdateEachStageContent current_data={each} save={this.save} />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RenderForm);

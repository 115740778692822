export const businessAccountTemplateConstants = {

    GET_BUSINESS_ACC_TEMP_DATA_REQUEST: 'GET_BUSINESS_ACC_TEMP_DATA_REQUEST',
    GET_BUSINESS_ACC_TEMP_DATA_SUCCESS: 'GET_BUSINESS_ACC_TEMP_DATA_SUCCESS',
    GET_BUSINESS_ACC_TEMP_DATA_FAILURE: 'GET_BUSINESS_ACC_TEMP_DATA_FAILURE',
    
    SAVE_BUSINESS_ACC_TEMP_DATA_REQUEST: 'SAVE_BUSINESS_ACC_TEMP_DATA_REQUEST',
    SAVE_BUSINESS_ACC_TEMP_DATA_SUCCESS: 'SAVE_BUSINESS_ACC_TEMP_DATA_SUCCESS',
    SAVE_BUSINESS_ACC_TEMP_DATA_FAILURE: 'SAVE_BUSINESS_ACC_TEMP_DATA_FAILURE',

}
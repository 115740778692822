import { axiosAjaxCall } from "./common.service";
import { showLoader } from "../../helpers";

export const processService = {
    saveProcess,
    getProcess,
}

function saveProcess(data) {   
    showLoader()
    return axiosAjaxCall("save_process", data);
}

function getProcess(data) {    
    return axiosAjaxCall("get_process", data);
}


import {  axiosAjaxCall } from './common.service'

export const searchLogService = {
    getSearchLogData,
 
}

function getSearchLogData(data) {
    return axiosAjaxCall("get_search_logs_details", data);
}

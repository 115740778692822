import { productsConstants } from "../constants"

export function getProducts(state = {}, action) {
//   console.log(action,"chexck");
    switch (action.type) {
      case productsConstants.GET_PRODUCTS_REQUEST:
          return { loading: true }
      case productsConstants.GET_PRODUCTS_SUCCESS:
        return { data: action.result }
      case productsConstants.GET_PRODUCTS_FAIL:
        return {}
      default:
        return state
    }
  }

  export function getProduct(state = {}, action) {
    //   console.log(action,"chexck");
        switch (action.type) {
          case productsConstants.GET_PRODUCT_REQUEST:
              return { loading: true }
          case productsConstants.GET_PRODUCT_SUCCESS:
            return { data: action.result }
          case productsConstants.GET_PRODUCT_FAIL:
            return {}
          default:
            return state
        }
      }
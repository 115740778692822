import config from 'react-global-configuration';

import axios from 'axios';

import { authHeader } from '../../helpers';

export const utilityService = {
    pincodeDetail,
    validateAadharNumber,
    validatePAN,
    validatePANCompany,
    getFormDetails,
    sendOTP,
    VerifyOTP,
    sendEmailOTP,
    VerifyEmailOTP,
    updateAddress,
    checkMobileExist
};

function pincodeDetail(pincode) {
    var form_data = new FormData();
    form_data.append("pincode", pincode);
    return axios.post(`${config.get('apiUrl')}/pincodedetail`, form_data).then(handleResponse);
}

function validatePAN(panNo, type) {
    var form_data = new FormData();
    form_data.append("pan_no", panNo);
    form_data.append("type", type);

    let setting = {
        headers: authHeader()
    }; 

    return axios.post(`${config.get('apiUrl')}/validatePanNumber`, form_data, setting).then(handleResponse);
}

function validateAadharNumber(data) {
    var form_data = new FormData();

    
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    
    let setting = {
        headers: authHeader()
    }; 

    return axios.post(`${config.get('apiUrl')}/validateAadharNumber`, form_data, setting).then(handleResponse);
}

function validatePANCompany(panNo, type) {
    var form_data = new FormData();
    form_data.append("pan_no", panNo);
    form_data.append("type", type);
    
    let setting = {
        headers: authHeader()
    };

    return axios.post(`${config.get('apiUrl')}/validateCompanyPanNumber`, form_data, setting).then(handleResponse);
}

function getFormDetails(type){

    let setting = {
        headers: authHeader()
    };

    var form_data = new FormData();
    form_data.append("type", type);

    return axios.post(`${config.get('apiUrl')}/getFormDetails`, form_data, setting).then(handleResponse);
}

function sendOTP(type, params ){

    let setting = {
        headers: authHeader()
    };

    var form_data = new FormData();
    form_data.append("request_type", type);
    form_data.append("mobile", params.mobile);
    
    if(params.reputeId) form_data.append("reputeId", params.reputeId);

    return axios.post(`${config.get('apiUrl')}/sendOTP`, form_data, setting).then(handleResponse);
}

function sendEmailOTP(type, params ){

    let setting = {
        headers: authHeader()
    };

    var form_data = new FormData();
    form_data.append("request_type", type);
    form_data.append("email", params.email);
    if(params.reputeId) form_data.append("reputeId", params.reputeId);

    return axios.post(`${config.get('apiUrl')}/sendEmailOTP`, form_data, setting).then(handleResponse);
}

function VerifyEmailOTP(type, params ){

    let setting = {
        headers: authHeader()
    };

    var form_data = new FormData();
    form_data.append("request_type", type);
    form_data.append("email", params.email);
    form_data.append("otp", params.otp);
    if(params.token) form_data.append("token", params.token);
    if(params.reputeId) form_data.append("reputeId", params.reputeId);

    return axios.post(`${config.get('apiUrl')}/verifyEmailOTP`, form_data, setting).then(handleResponse);
}


function VerifyOTP(type, params){

    let setting = {
        headers: authHeader()
    };

    var form_data = new FormData();
    form_data.append("request_type", type);
    form_data.append("mobile", params.mobile);
    if(params.reputeId) form_data.append("reputeId", params.reputeId);
    if(params.otp) form_data.append("otp", params.otp);
    if(params.token) form_data.append("token", params.token);
    return axios.post(`${config.get('apiUrl')}/verifyOTP`, form_data, setting).then(handleResponse);
}


function updateAddress(address){

    let setting = {
        headers: authHeader()
    };

    
    var form_data = new FormData();

    for (var key in address) {
        form_data.append(key, address[key]);
    }

    return axios.post(`${config.get('apiUrl')}/updateAddress`, form_data, setting).then(handleResponse);
}


function handleResponse(response) {
    return response.data;
}



function checkMobileExist(data) {
    var form_data = new FormData();

    let setting = {
        headers: authHeader()
    };

    form_data.append("mobile", data);
    return axios.post(`${config.get('apiUrl')}/check_mibile_exist`, form_data,setting).then(handleResponse);
}
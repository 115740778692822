export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`notification_text`]) {
        formIsValid = false
        errors[`notification_text`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
import { requirementLogsConstants } from "../constants"

export function getRequirementLogs(state = {}, action) {
    switch (action.type) {
        case requirementLogsConstants.GET_REQUIREMENT_LOG_REQUEST:
        return { loading: true }
        case requirementLogsConstants.GET_REQUIREMENT_LOG_SUCCESS:
        return { 
            data: action.result
            }
        case requirementLogsConstants.GET_REQUIREMENT_LOG_FAILURE:
        return {}
        default:
        return state
    }
}

export function getSellerDetail(state = {}, action) {
    switch (action.type) {
        case requirementLogsConstants.GET_SELLER_DETAIL_REQUEST:
        return { loading: true }
        case requirementLogsConstants.GET_SELLER_DETAIL_SUCCESS:
        return { 
            data: action.result
            }
        case requirementLogsConstants.GET_SELLER_DETAIL_FAILURE:
        return {}
        default:
        return state
    }
}

export function getBuyerRequirementInvites(state = {}, action) {
    switch (action.type) {
      case requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_REQUEST:
        return { loading: true };
      case requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_SUCCESS:
        return { data: action.result };
      case requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_FAILURE:
        return { fail: true };
      default:
        return state;
    }
  }

export function getUnregisteredSellerDetail(state = {}, action) {
    switch (action.type) {
        case requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_REQUEST:
        return { loading: true }
        case requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_SUCCESS:
        return { 
            data: action.result
            }
        case requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_FAILURE:
        return {}
        default:
        return state
    }
}

export function updateRequirementLocation(state = {}, action) {
  switch (action.type) {
      case requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_REQUEST:
      return { loading: true }
      case requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_SUCCESS:
      return { 
          data: action.result
          }
      case requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_FAILURE:
      return {}
      default:
      return state
  }
}


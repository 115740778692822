import React, { Component } from "react";
import {
  Button,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback
} from "reactstrap";
import {
  ReputeIDCompanyInput,
  ReputeIDIndividualInput,
  MobileNoInput,
} from "../../../components/Forms/Inputs";
import { connect } from "react-redux";
import { businessAccountsActions, associationAction } from "../../actions";
import { businessAccountsConstants } from '../../constants/businessAccounts.constants';
import FormGroup from "reactstrap/lib/FormGroup";

const defaultValue = { repute_id: "", type_id: "", mobile: "", verified_reputeid:'', verified_owmer_reputeid:'' };
class UpdateReputeID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: defaultValue,
      errors: {}
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.clear = this.clear.bind(this);
    this.verify = this.verify.bind(this);
    this.getIndiviualType = this.getIndiviualType.bind(this)

  }
  getIndiviualType() {
    const { dispatch } = this.props;
    dispatch(associationAction.getIndiviualType());
  }

  toggle(e) {

    if (e) {
      e.stopPropagation();
    }
    const { isEdit, current_data } = this.props;
    let { data } = this.state;
    data = { ...data, ...current_data };
    console.log(current_data);
    let isOpen = !this.state.isOpen;
    if (isOpen === true) {
      this.clear();
    }
    this.setState({ isOpen, data });
  }

  isValid() {
    const { data, errors } = this.state;
    const { type } = this.props;
    let error = false;
    if (type === "company") {
      if (!data.repute_id) {
        errors.repute_id = 'this field is required'
        error = true
      }
    }
    else {
      if (!data.mobile && !data.repute_id) {
        errors.mobile = 'Enter Repute ID or Mobile'
        error = true
      }
    }

    this.setState({ errors })
    return error;
  }

  isValidData() {
    const { data, errors } = this.state;
    const { type } = this.props;
    let error = false;
    if (type === "company") {
      if (!data.repute_id) {
        errors.repute_id = 'this field is required'
        error = true
      }
    }
    else {
      if (!data.mobile && !data.repute_id) {
        errors.mobile = 'Enter Repute ID or Mobile'
        error = true
      }
      if (!data.type_id) {
        errors.type_id = 'this field is required'
        error = true
      }
    }

    this.setState({ errors })
    return error;
  }

  verify() {

    const { data } = this.state;
    const { dispatch, type, id } = this.props;
    if (!this.isValid()) {

      let dataToSend = {
        id,
        type,
        repute_id: data.repute_id.replace(/-/g, ''),
        mobile: data.mobile
      };
      dispatch(businessAccountsActions.getDataFromReputeId(dataToSend, {
        success: (response) => {
          if (type == "individual" ) {
            data.verified_reputeid = response.individual_reputeid
            this.setState({ data })
          }
          if (type == "company" ) {
            data.verified_reputeid = response.company_reputeid
            data.verified_owmer_reputeid = response.owner_reputeid
            this.setState({ data })
          }
        }
      })
      );
    }

  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data, errors } = this.state;
    const { dispatch } = this.props;

    data[name] = value;
    if(name == 'repute_id' || name == 'mobile'  ){
      dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })
    }

    this.setState({ data, errors: {} });
  }

  save() {
    const { data } = this.state;
    const { dispatch, type, getData, association_id } = this.props;

    if (this.isValidData()) {
      return false;
    }

    let dataToSend = {
      repute_id: data.verified_reputeid,
      association_id: association_id
    };
    if (type === 'company') {
      dataToSend.owner_reputeid = data.verified_owmer_reputeid
      dispatch(associationAction.addAssociationCompany(dataToSend, {
        success: () => {
          this.toggle();
          getData()
          dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })

        }
      })
      );
    }
    else {
      dataToSend.type_id = data.type_id
      dispatch(associationAction.addAssociationIndividual(dataToSend, {
        success: () => {
          this.toggle();
          getData()
          this.clear()
        }
      })
      );
    }
  }


  clear() {
    let { data } = this.state;
    let { dispatch } = this.props;

    data = defaultValue
    dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })

    this.setState({ data, errors: {} });
  }

  componentDidMount() {
    this.getIndiviualType();
  }

  render() {
    const { data, errors } = this.state;
    const { indiviualTypes, type, reputeid_data } = this.props;


    return (
      <React.Fragment>


        <button
          className="btn btn-danger pull-right mb-2 "
          style={{ fontSize: 12 }}
          onClick={(e) => this.toggle(e)}
        >
          {type === "company" ? "Add Company" : "Add Individual"}
        </button>

        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>{type === "company" ? "Add Company" : "Add Individual"}</ModalHeader>
          <ModalBody>
            {type === "company" ? (
              <InputGroup>
                <ReputeIDCompanyInput
                  type="text"
                  name="repute_id"
                  placeholder={`Company Repute ID`}
                  value={data.repute_id}
                  handleChange={this.handleChange}
                />
                {errors.repute_id && (<FormFeedback> {errors.repute_id} </FormFeedback>)}
              </InputGroup>
            ) : (
              <React.Fragment>
                <InputGroup>
                  <ReputeIDIndividualInput
                    type="text"
                    name="repute_id"
                    value={data.repute_id}
                    placeholder={`Individual Repute ID`}
                    handleChange={this.handleChange}
                  />
                  {errors.repute_id && (<FormFeedback> {errors.repute_id} </FormFeedback>)}
                </InputGroup><br />
                <h6 className="text-center">OR</h6>
                <InputGroup>
                  <MobileNoInput
                    name="mobile"
                    value={data.mobile}
                    handleChange={this.handleChange}
                  />
                  {errors.mobile && (<FormFeedback> {errors.mobile} </FormFeedback>)}
                </InputGroup>
              </React.Fragment>
            )}
            {reputeid_data && (type === "company" ? <React.Fragment>
              

              <span style={{ margin: 5, }}> Company found :  <b>{reputeid_data.company_name}</b>  </span>
              <div style={{ margin: 5, }}>
                <b>Individuals</b>
              </div>
              {reputeid_data.individuals.map((each) => {
                return <div style={{ margin: 5, }}>   {each.individual_name}({each.repute_id}) {each.is_owner && <span className="badge badge-success" > Owner</span>} </div>
              })}

            </React.Fragment> : <React.Fragment>
              <span style={{ margin: 5, }}> Individual found :  <b>{reputeid_data.aadhaar_name}</b>  </span>

              <FormGroup className={' mt-4'}>

                  <label>Individual Person Type</label>
                <select className="form-control" name="type_id" onChange={this.handleChange} value={this.type_id}  >
                  <option value="">--- Select Type ---</option>
                  {
                    indiviualTypes && indiviualTypes.data && indiviualTypes.data.length ?
                      indiviualTypes.data.map((each) => {
                        return <option value={each.id}>{each.type}</option>
                      })
                      : ''
                  }
                </select>
                {errors.type_id && (<FormFeedback> {errors.type_id} </FormFeedback>)}
              </FormGroup>
            </React.Fragment>)}
          </ModalBody>
          <ModalFooter>
            {reputeid_data ?
              <Button color="primary" onClick={() => this.save()}>
                Save
              </Button> :
              <Button color="primary" onClick={() => this.verify()}>
                verify
              </Button>}
            <Button color="danger" onClick={() => this.toggle()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getDataFromReputeId;
  return {
    loading,
    reputeid_data: data,
    indiviualTypes: state.getIndiviualType
  }
};

const connected = connect(mapStateToProps)(UpdateReputeID);
export { connected as UpdateReputeID };
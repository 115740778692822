export const UserActivityConstants = {

    GET_USER_ACTIVITY_REQUEST: 'GET_USER_ACTIVITY_REQUEST',
    GET_USER_ACTIVITY_SUCCESS: 'GET_USER_ACTIVITY_SUCCESS',
    GET_USER_ACTIVITY_FAILURE: 'GET_USER_ACTIVITY_FAILURE',

    GET_USER_ACTIVITY_DASHBOARD_REQUEST: 'GET_USER_ACTIVITY_DASHBOARD_REQUEST',
    GET_USER_ACTIVITY_DASHBOARD_SUCCESS: 'GET_USER_ACTIVITY_DASHBOARD_SUCCESS',
    GET_USER_ACTIVITY_DASHBOARD_FAILURE: 'GET_USER_ACTIVITY_DASHBOARD_FAILURE',
}


import {  axiosAjaxCall } from './common.service'

export const FeedbackService = {
    getFeedback,
 
}

function getFeedback(data) {
    return axiosAjaxCall("get_feedback_logs", data);
}

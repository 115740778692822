import { axiosAjaxCall } from "./common.service";

export const businessAccountsService = {
    addQuickBusinessAccount,
    assignUser,
    saveReputeId,
    saveProcessStage,
    saveBusinessAccounts,
    getBusinessAccounts,
    getDataFromReputeId,
    getSectors,
    getStates,
    checkMobile ,
    getBlockBussinessData,
    blockBussinessData, 
    uploadCsvForMSG91FailedReason, 
}

function getSectors(data) {
    return axiosAjaxCall("get_sectors", data);
}
function getBlockBussinessData(data) {
    return axiosAjaxCall("get-business-by-keywords", data);
}
function blockBussinessData(data) {
    return axiosAjaxCall("block-business-by-keywords", data);
}
function uploadCsvForMSG91FailedReason(data) {
    return axiosAjaxCall("uploadCsvForMSG91FailedReason", data);
}
function getStates(data) {
    return axiosAjaxCall("get_states", data);
}
function addQuickBusinessAccount(data) {
    return axiosAjaxCall("add_quick_business", data);
}
function assignUser(data) {
    return axiosAjaxCall("assign_user_to_business", data);
}
function getDataFromReputeId(data) {
    return axiosAjaxCall("getDataFromReputeId", data);
}

function saveReputeId(data) {
    return axiosAjaxCall("save_business_account_repute_id", data);
}

function saveProcessStage(data) {
    return axiosAjaxCall("save_process_stage_data", data);
}

function saveBusinessAccounts(data) {
    return axiosAjaxCall("save_business_account_data", data);
}

function getBusinessAccounts(data) {
    return axiosAjaxCall("get_business_account_data", data);
}
function checkMobile(data) {
    return axiosAjaxCall("check_mobile", data);
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    Badge,
    UncontrolledTooltip,
  } from "reactstrap";
import { autoReminderCronLogsAction } from "../../actions";
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from "../../../components/Tables/DataTable";
import filterFactory from "react-bootstrap-table2-filter";
class AutoReminderCronLogs extends Component {
    constructor(props) {
        super(props)
        this.getChequeDetail = this.getChequeDetail.bind(this);
    }
    componentDidMount() {
        this.getChequeDetail();
      }
    getChequeDetail() {
      const { dispatch } = this.props;
      dispatch(autoReminderCronLogsAction.getAutoReminderCronLogs());
    }
    renderSendByCompany(cell, row, rowIndex, formateExtraData) {
      return row.sent_company_name + ' ('+ row.sent_company_formated_repute_id + ')';
    }
    renderReceivedByCompany(cell, row, rowIndex, formateExtraData) {
      return row.received_company_name + ' ('+ row.received_company_formated_repute_id + ')';
    }
    renderRI(cell, row, rowIndex, formateExtraData) {
      return (
        <React.Fragment>
         
          <Badge color={row.sent_option.sms? "success" : ""}>SMS</Badge> &nbsp;
          <Badge color={row.sent_option.email? "success" : ""}>Email</Badge> &nbsp;
          <Badge color={row.sent_option.notification? "success" : ""}>Notification</Badge>
        </React.Fragment>
      );
    }
    render() {
        const { loading, data } = this.props;
        const columns = [
        {
            text: "Type",
            dataField: "type",
        },
        {
          text: "Day",
          dataField: "day",
      },
        {
            text: "Send By (Creditor)",
            dataField: "",
            formatter: this.renderSendByCompany,
        },
        {
            text: "Received By (Debtor)",
            dataField: "",
            formatter: this.renderReceivedByCompany,
        },
        {
            text: "Schedule Time",
            dataField: "send_at",
        },
        {
            text: "Executed At",
            dataField: "executed_at",
        },
        {
          text: "",
          dataField: "",
          formatter: this.renderRI,
        },
        ];
        
        return (
            <div>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardHeader>
                      {" "}
                      <i className="icon-directions"></i> <b>Auto Reminder Cron Logs</b>
                    </CardHeader>
                    <CardBody>
                      {loading ? (
                        <TableLoader />
                      ) : (
                        <div>
                          <DataTable
                            keyField="id"
                            data={data || []}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication={"No data found"}
                          />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          );
    }
}

function mapStateToProps(state) {
    const { loading, data} = state.getAutoReminderCronLogs

    return {
        loading,
        data
    }
}

const connectedAutoReminderCronLogs = connect(mapStateToProps)(AutoReminderCronLogs)
export { connectedAutoReminderCronLogs as AutoReminderCronLogs }

import { notify } from 'reapop'
import { productTagConstants } from '../constants'
import { productTagService } from '../services'

export const productTagActions = {
    getProductTags,
    addEditProductTags,
    deleteProductTags,
    addSuperadminInteraction,
    getUnregisteredSeller,
    addUnregisteredSeller,
    checkDuplicateUnregisteredSeller
}

function getProductTags(data) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.getProductTags(data)
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.GET_PRODUCT_TAG_REQUEST, params : data } }
    function success(result) { return { type: productTagConstants.GET_PRODUCT_TAG_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.GET_PRODUCT_TAG_FAILURE, error } }
}


function addEditProductTags(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.addEditProductTags(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success(response)
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Product tag save successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.ADD__EDIT_PRODUCT_TAG_REQUEST } }
    function success(result) { return { type: productTagConstants.ADD__EDIT_PRODUCT_TAG_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.ADD__EDIT_PRODUCT_TAG_FAILURE, error } }
}

function deleteProductTags(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.deleteProductTags(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success(response)
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Product tag delete successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.DELETE_PRODUCT_TAG_REQUEST } }
    function success(result) { return { type: productTagConstants.DELETE_PRODUCT_TAG_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.DELETE_PRODUCT_TAG_FAILURE, error } }
}

function addSuperadminInteraction(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.addSuperadminInteraction(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success(response)
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Saved Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.ADD_SUPERADMIN_INTERACTION_REQUEST } }
    function success(result) { return { type: productTagConstants.ADD_SUPERADMIN_INTERACTION_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.ADD_SUPERADMIN_INTERACTION_FAILURE, error } }
}

function getUnregisteredSeller(data) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.getUnregisteredSeller(data)
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.GET_UNREGISTERED_SELLER_REQUEST, params : data } }
    function success(result) { return { type: productTagConstants.GET_UNREGISTERED_SELLER_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.GET_UNREGISTERED_SELLER_FAILURE, error } }
}

function addUnregisteredSeller(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.addUnregisteredSeller(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.ADD_UNREGISTERED_SELLER_REQUEST, params : data } }
    function success(result) { return { type: productTagConstants.ADD_UNREGISTERED_SELLER_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.ADD_UNREGISTERED_SELLER_FAILURE, error } }
}

function checkDuplicateUnregisteredSeller(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        productTagService.checkDuplicateUnregisteredSeller(data)
            .then(
                response => {
                    
                    dispatch(success(response))
                    callback.success(response)
                },
                error => {
                    
                    dispatch(failure(error.toString()));
                    callback.failure(error)
//                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_REQUEST, params : data } }
    function success(result) { return { type: productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_SUCCESS, result } }
    function failure(error) { return { type: productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_FAILURE, error } }
}
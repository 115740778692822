import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CompanySectorForm } from './CompanySectorForm'
import { Row, Col, CardBody, Card } from 'reactstrap'

class AddCompanySector extends Component {
    
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h3 className="text-center mb-4 mt-4"> Add Company Sector </h3>
                                <CompanySectorForm></CompanySectorForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
}

const connectedAddCompanySector = connect(mapStateToProps)(AddCompanySector)
export { connectedAddCompanySector as AddCompanySector }

import { landingPageData } from '../constants'
import { landingPageService } from '../services'
import { notify } from 'reapop'


export const landingPageActions = {
    sendLandingPageData,
    getLandingPageData,
    getLandingPageUserData
}

function sendLandingPageData(data, callBack) {
    return dispatch => {
        dispatch(request())

        landingPageService.sendLandingPageData(data)
            .then(
                result => {
                    dispatch(success(result))
                    callBack()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: landingPageData.SEND_LANDING_PAGE_DATA_REQUEST } }
    function success(result) { return { type: landingPageData.SEND_LANDING_PAGE_DATA_SUCCESS, result } }
    function failure(error) { return { type: landingPageData.SEND_LANDING_PAGE_DATA_FAILURE, error } }
}
function getLandingPageData(data) {
    return dispatch => {
        dispatch(request())

        landingPageService.getLandingPageData(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: landingPageData.GET_LANDING_PAGE_DATA_REQUEST } }
    function success(result) { return { type: landingPageData.GET_LANDING_PAGE_DATA_SUCCESS, result } }
    function failure(error) { return { type: landingPageData.GET_LANDING_PAGE_DATA_FAILURE, error } }
}
function getLandingPageUserData(data) {
    return dispatch => {
        dispatch(request())

        landingPageService.getLandingPageUserData(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: landingPageData.GET_LANDING_PAGE_USER_DATA_REQUEST } }
    function success(result) { return { type: landingPageData.GET_LANDING_PAGE_USER_DATA_SUCCESS, result } }
    function failure(error) { return { type: landingPageData.GET_LANDING_PAGE_USER_DATA_FAILURE, error } }
}

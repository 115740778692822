import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Form, Button } from 'reactstrap'
import { SMSPackageActions } from "../../actions";
import { SmsPackageCharges } from './smsPackageCharges';
import ReactQuill from 'react-quill'
import { Link } from "react-router-dom";
import { editorConfig } from '../../../components/Editor';
import { handleValidation } from './Validation';
import InlineError from '../../../components/Messages/InlineError';
import { withRouter } from 'react-router-dom';

class SmsPackageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [],
            editorHtml: '',
            theme: 'snow',
            formData: {
                title: '',
                description: '',
                chareslength: '',
                sms_charges:[]
            },
            errors: [],
            is_submit: false
        }
        this.isLoad = false;
        this.handleChange = this.handleChange.bind(this)
        this.handelSubmit = this.handelSubmit.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleChangeHTML = this.handleChangeHTML.bind(this)
        this.afterAction = this.afterAction.bind(this)
        this.addPlanOption = this.addPlanOption.bind(this)
        this.removePlanOption = this.removePlanOption.bind(this)
    }


    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        formData[name] = value
        this.setState({ formData })
    }
    handelSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const { formData } = this.state;
        const result = handleValidation(formData);
        this.setState({ errors: result.errors });

        if (result.formIsValid) {
            let dataToSend = this.state.formData;
            dispatch(SMSPackageActions.updateSMSPackage(dataToSend, this));
            this.setState({ is_submit: true });
        }
    }

    afterAction() {
        this.props.history.push('/sms-packages')
    }
    handleChecked(checked) {
        const { formData } = this.state;
        formData.permissions_list = checked
        this.setState({ formData })
    }

    handleChangeHTML(html) {
        const { formData } = this.state;
        formData.description = html
        this.setState({ formData })
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ formData: this.props.data })
        }
    }

    removePlanOption(index) {
        let { formData } = this.state

        if (formData.sms_charges.length <= 1) {
            alert("Atlest one is option required");
            return false;
        }
        formData.sms_charges.splice(index, 1);
        console.log(formData.sms_charges);
        this.setState({ formData })
    }

    addPlanOption() {

        let { formData } = this.state
        formData.sms_charges.push({
            total_amount: "",
            total_sms: "",
            validity_days: ""
        })
        
        this.setState({ formData })

    }

    render() {
        // const { loading } = this.props
        const { formData, errors,is_submit } = this.state

        let _this = this;

        return (
            <Form  autocomplete="off" onSubmit={this.handelSubmit} className="col-md-8 offset-md-2">
                <Row>
                    <Col xs="12">
                        <div className="form-group">
                            <label className="font-weight-bold" >Paxkage Title</label>
                            <input type="text" className="form-control" name="title" onChange={this.handleChange} value={formData.title} />
                            {errors.title && <InlineError message={errors.title} />}

                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold">Description</label>
                            <ReactQuill
                                theme={this.state.theme}
                                onChange={this.handleChangeHTML}
                                value={formData.description}
                                modules={editorConfig.modules}
                                formats={editorConfig.formats}
                                name="description"
                            />
                            {errors.description && <InlineError message={errors.description} />}

                        </div>
                       

                        <label className="font-weight-bold">Plan Options</label>
                        <section>
                            
									<div className="table-responsive-sm  animated fadeIn">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Total SMS</th>
                                        <th>Amount <br /><small> (Excluding GST) </small></th>
                                        <th>IGST (18%)</th>
                                        <th>CGST (9%)</th>
                                        <th>UTGST (9%)</th>
                                        <th>Amount <br /><small> (Including GST) </small></th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData && formData.sms_charges ?
                                            formData.sms_charges.map(function (element, index) {
                                                return (
                                                    <SmsPackageCharges detail={element} key={element.id} currentIndex={index} removePaln={(index) => _this.removePlanOption(index)}></SmsPackageCharges>
                                                )
                                            })
                                            : ''
                                    }
                                    <Button color="primary" onClick={this.addPlanOption}> Add Plan Option </Button>
                                </tbody>
                            </table>
                            </div>
                        </section>
                        <br /><br />
                        <button type="submit" className="btn btn-primary" disabled={is_submit}> {is_submit ? "Please wait..." : "Save"} </button> &nbsp;
                                                <Link className="btn btn-secondary" to={"/sms-packages"}> Cancel </Link>

                    </Col>
                </Row>
            </Form>


        )
    }
}


function mapStateToProps(state) {
    const { data } = state.getSMSPackage

    const { loading } = state.updateSubscription
    return {
        loading,
        data
    }
}
const connectedSmspackage = withRouter(connect(mapStateToProps)(SmsPackageForm))
export { connectedSmspackage as SmsPackageForm }
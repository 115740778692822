import React from 'react'
import { connect } from 'react-redux';
import { Button } from 'reactstrap'
import { MarketingActions } from '../../actions/marketing.action';;

function ScheduleStop(props) {
    const { dispatch, data, actionsFetch } = props

    let resetScheduleFunc = () => {
        let dataSend = {
            communication_template_master_id: data.parameters.communication_template_master_id
        }
        dispatch(MarketingActions.resetSchedule(dataSend, actionsFetch))
    }
    //


    return (
        <div>
            <Button className='bg-danger' onClick={resetScheduleFunc}>
                Reset Schedule
            </Button>
      
        </div>
    )
}


export default connect()(ScheduleStop)


import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { reputeinfoBalancePackageActions } from "../../actions";
import { TableLoader } from '../../../components/Loaders';
import { ReputeinfoBalancePackageForm } from './ReputeinfoBalancePackageForm';

class UpdateBalancePackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [],
            editorHtml: '',
            theme: 'snow',
            formData: {
                plan_title: '',
                chareslength: ''
            },
            errors: []
        }
        this.isLoad = false;
        this.getBalancePackage = this.getBalancePackage.bind(this);
    }

    componentDidMount() {
        this.getBalancePackage();
    }

   
    getBalancePackage() {
        const { dispatch } = this.props;
        const values = this.props.match.params.id
        let dataToSend = {
            id: values
        }
        dispatch(reputeinfoBalancePackageActions.getBalancePackage(dataToSend));
    }

    componentWillReceiveProps(props) {
        if (props.data) {
            this.setState({ formData: props.data })
        }
    }

    render() {
        
        const { loading } = this.props

        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h3 className="text-center mb-4 mt-4"> Balance/Billing Setting </h3>
                                {loading ? <TableLoader /> :
                                      <ReputeinfoBalancePackageForm />
                                    
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { loading, data } = state.getBalancePackage
    return {
        loading,
        data
    }
}
const connectedUpdateBalancePackage = connect(mapStateToProps)(UpdateBalancePackage)
export { connectedUpdateBalancePackage as UpdateBalancePackage }
export const MarketingConstants = {

    SCHDUELE_MARKETING_REQUEST: 'SCHDUELE_MARKETING_REQUEST',
    SCHDUELE_MARKETING_SUCCESS: 'SCHDUELE_MARKETING_SUCCESS',
    SCHDUELE_MARKETING_FAILURE: 'SCHDUELE_MARKETING_FAILURE',

    FETCH_BUSINESSES_RESULT_REQUEST: 'FETCH_BUSINESSES_RESULT_REQUEST',
    FETCH_BUSINESSES_RESULT_SUCCESS: 'FETCH_BUSINESSES_RESULT_SUCCESS',
    FETCH_BUSINESSES_RESULT_FAILURE: 'FETCH_BUSINESSES_RESULT_FAILURE',

    RESET_SCHEDULE_RESULT_REQUEST: 'RESET_SCHEDULE_RESULT_REQUEST',
    RESET_SCHEDULE_RESULT_SUCCESS: 'RESET_SCHEDULE_RESULT_SUCCESS',
    RESET_SCHEDULE_RESULT_FAILURE: 'RESET_SCHEDULE_RESULT_FAILURE',

    STOP_SCHEDULE_RESULT_REQUEST: 'STOP_SCHEDULE_RESULT_REQUEST',
    STOP_SCHEDULE_RESULT_SUCCESS: 'STOP_SCHEDULE_RESULT_SUCCESS',
    STOP_SCHEDULE_RESULT_FAILURE: 'STOP_SCHEDULE_RESULT_FAILURE',

    SET_SCHEDULE_STOP_START_RESULT_REQUEST: 'SET_SCHEDULE_STOP_START_RESULT_REQUEST',
    SET_SCHEDULE_STOP_START_RESULT_SUCCESS: 'SET_SCHEDULE_STOP_START_RESULT_SUCCESS',
    SET_SCHEDULE_STOP_START_RESULT_FAILURE: 'SET_SCHEDULE_STOP_START_RESULT_FAILURE',

    GET_WHATSAPP_STOP_COUNT_REQUEST: 'GET_WHATSAPP_STOP_COUNT_REQUEST',
    GET_WHATSAPP_STOP_COUNT_SUCCESS: 'GET_WHATSAPP_STOP_COUNT_SUCCESS',
    GET_WHATSAPP_STOP_COUNT_FAILURE: 'GET_WHATSAPP_STOP_COUNT_FAILURE',

    WHATSAPP_MARKETING_ANALYSIS_REQUEST: 'WHATSAPP_MARKETING_ANALYSIS_REQUEST',
    WHATSAPP_MARKETING_ANALYSIS_SUCCESS: 'WHATSAPP_MARKETING_ANALYSIS_SUCCESS',
    WHATSAPP_MARKETING_ANALYSIS_FAILURE: 'WHATSAPP_MARKETING_ANALYSIS_FAILURE',

    GET_SENDED_NOTIFICATIONS_REQUEST: 'GET_SENDED_NOTIFICATIONS_REQUEST',
    GET_SENDED_NOTIFICATIONS_SUCCESS: 'GET_SENDED_NOTIFICATIONS_SUCCESS',
    GET_SENDED_NOTIFICATIONS_FAILURE: 'GET_SENDED_NOTIFICATIONS_FAILURE',

    SEND_NOTIFICATIONS_REQUEST: 'SEND_NOTIFICATIONS_REQUEST',
    SEND_NOTIFICATIONS_SUCCESS: 'SEND_NOTIFICATIONS_SUCCESS',
    SEND_NOTIFICATIONS_FAILURE: 'SEND_NOTIFICATIONS_FAILURE',

    SET_REPORT_VALUES_MODAL_REQUEST: 'SET_REPORT_VALUES_MODAL_REQUEST',
    SET_REPORT_VALUES_MODAL_SUCCESS: 'SET_REPORT_VALUES_MODAL_SUCCESS',
    SET_REPORT_VALUES_MODAL_FAIL: 'SET_REPORT_VALUES_MODAL_FAIL',


}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adminContentConfigAction } from '../../../actions';
import { AddAdminContentConfig } from './AddAdminContentConfig';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
const TABS = {
	ENGLISH: 'FIRST_CONTENT_FOR_TAKING_APPOINTMENT_ENGLISH',
	HINDI: 'FIRST_CONTENT_FOR_TAKING_APPOINTMENT_HINDI',
	GUJARATI: 'FIRST_CONTENT_FOR_TAKING_APPOINTMENT_GUJARATI'
};

class AdminContentConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: TABS.ENGLISH,
			currentData: ''
		};
		this.getAdminContentConfigtData = this.getAdminContentConfigtData.bind(this);
		this.setData = this.setData.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		this.getAdminContentConfigtData();
	}

	getAdminContentConfigtData() {
		const { dispatch } = this.props;
		dispatch(adminContentConfigAction.getAdminContentConfigtData());
	}

	componentWillReceiveProps(props) {
		const { data } = props;
		if (data) {
			this.setState({ currentData: data[0] });
		}
	}

	setData(data) {
		if (data) {
			this.setState({ currentData: data });
		}
	}
	toggle(tab) {
		this.setState({ activeTab: tab });
	}
	render() {
		const { activeTab } = this.state;

		return (
			<div>
                <Card>
                    <CardBody>
                        <h3 className="mb-3">Content for taking appointment</h3>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === TABS.ENGLISH })}
							onClick={() => {
								this.toggle(TABS.ENGLISH);
							}}
						>
							English
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === TABS.HINDI })}
							onClick={() => {
								this.toggle(TABS.HINDI);
							}}
						>
							Hindi
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === TABS.GUJARATI })}
							onClick={() => {
								this.toggle(TABS.GUJARATI);
							}}
						>
							Gujarati
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId={TABS.ENGLISH}>
                        {TABS.ENGLISH === activeTab  &&
						<AddAdminContentConfig content_key={TABS.ENGLISH} />}
					</TabPane>
                    <TabPane tabId={TABS.HINDI}>
                        {TABS.HINDI === activeTab  &&
    <AddAdminContentConfig content_key={TABS.HINDI} /> }
					</TabPane>
                    <TabPane tabId={TABS.GUJARATI}>
                    {TABS.GUJARATI === activeTab  &&
    <AddAdminContentConfig content_key={TABS.GUJARATI} /> }
					</TabPane>
				</TabContent>
                
                </CardBody>
                </Card>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getAdminContentConfigtData;
	const { user } = state.authentication;
	return {
		loading,
		data,
		authUser: user
	};
}

const connectedAdminContentConfig = connect(mapStateToProps)(AdminContentConfig);
export { connectedAdminContentConfig as AdminContentConfig };

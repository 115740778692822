import { validPhone, validReputeIdIndividual, validEmail } from "../../../helpers";
export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`preferred_language`] || fields[`preferred_language`] === "0" || fields[`preferred_language`] === 0) {
        formIsValid = false
        errors[`preferred_language`] = 'Please select prefered language'
    } else if (fields[`preferred_language`] === "Other") {
        if (!fields["other_language"]) {
            formIsValid = false
            errors[`other_language`] = 'Please enter preferred language'
        }
    }

    return { errors, formIsValid }
}
import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Modal } from "reactstrap";
import {
  InputText,
  InputDropDown,
  InputCheckBox,
} from "../../Settings/StageSetting/Components/FormElements";
import { v4 as uuidv4 } from "uuid";
import { InputTypes } from "../../../constants/stages.constants";



export default class UpdateEachContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: { title: "", value: "  " },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.clear = this.clear.bind(this);
    this.renderInputTypeWiseInputElements = this.renderInputTypeWiseInputElements.bind(this)
    // this.validation = this.validation.bind(this)
  }

  toggle(e) {
    if (e) {
      e.stopPropagation();
    }
    const { isEdit, current_data } = this.props;
    let { data } = this.state;

    data = { ...data, ...current_data };
    console.log(current_data);
    let isOpen = !this.state.isOpen;
    if (isOpen === true) {
      this.clear();
    }
    this.setState({ isOpen, data });
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    if(data.inputType === InputTypes.Checkbox) {
      data[name] = event.target.checked
    } else {

      data[name] = value;
    }
    this.setState(data);
  }

  save() {
    const { data } = this.state;
    const {  save, index} = this.props
    
    save({...data}, index );
    
    this.toggle();
  }

  validation() {
    let { data } = this.state;
    let error = {};
    let isError = false;

    if (data.stage_name.trim() === "") {
      error.stage_name = "Enter stage code";
      isError = true;
    }

    if (data.heading.trim() === "") {
      error.heading = "Enter stage name";
      isError = true;
    }

    this.setState({ error });
    return isError;
  }
  clear() {
    let { data } = this.state;

    data.id = null;
    data.title = "";
    data.value = "";

    this.setState(data);
  }

  
 renderInputTypeWiseInputElements(data) {
  let renderedItem = false;

  
  switch (data.inputType) {
    case InputTypes.Heading:
        renderedItem = (
          <React.Fragment>
            <labal style={ { fontSize : "15", fontWeight:"bold" }}>{data.title}</labal>
          </React.Fragment>
        );
        break;
    case InputTypes.Text:
      renderedItem = (
        <React.Fragment>
            <InputText
              title={""}
              placeholder={data.title}
              name="value"
              value={data.value}
              onChange={this.handleChange}
              maxLength="250"
            />

        </React.Fragment>
      );
      break;
      case InputTypes.TextArea:
        renderedItem = (
          <React.Fragment>
              <InputText
                title={""}
                placeholder={data.title}
                name="value"
                type={"textarea"}
                value={data.value}
                onChange={this.handleChange}
                maxLength="250"
              />
  
          </React.Fragment>
        );
      break;
      case InputTypes.Checkbox:
      renderedItem = (
        <React.Fragment>
            <InputCheckBox
              title={data.title}
              placeholder={data.title}
              name="value"
              checked={data.value}
              onChange={this.handleChange}
              maxLength="250"
            />

        </React.Fragment>
      );
      break;
    case InputTypes.CheckList:
      renderedItem = (
        <React.Fragment>
          {data.items.map(function (eachItem) {
            return (
              <InputCheckBox
                title={eachItem.title}
                name={eachItem.name}
                // checked={eachItem.value}
                // onChange={this.handleChange}
              />
            );
          })}
        </React.Fragment>
      );
      break;
    //   case InputTypes.Radio:
    case InputTypes.SubItem:
      //   case InputTypes.Dropdown:
   
      renderedItem =
        Array.isArray(data.children) &&
        data.children.map((each) => {
          // return renderInputTypeWiseInputElements(each);
        });

      break;
  }
  return (
    <React.Fragment>
      <div style={{ marginLeft: "10px" }}>
        <label>
          <b> {data.heading}</b>
        </label>
        {renderedItem}
      </div>
    </React.Fragment>
  );
}

  render() {
    const { data, error } = this.state;
    const { template, isEdit } = this.props;

    // let Options = [{ title : "Not Specified", value : false}]
    // if(template) {
    //     template.stages.Items.map((item)=>{
    //         Options.push({ title : item.heading, value : item.stage_name})
    //     });
    // }
    return (
      <React.Fragment>
        <span onClick={(e) => this.toggle(e)}>
          <i className="fas fa-edit  text-primary cursor-pointer" />{" "}
        </span>

        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>{data.title}</ModalHeader>
          <ModalBody>
            {this.renderInputTypeWiseInputElements(data)}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>
            <Button color="secondary" onClick={() => this.clear()}>
              Clear
            </Button>
            <Button color="danger" onClick={() => this.toggle()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

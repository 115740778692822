import { notify } from 'reapop'
import { expenceStatementConstants } from '../constants'
import { expenceStatementService } from '../services'

export const expenceStatementActions = {
    addExpenceStatement,
    getExpenceStatement,
    getPayoutDetails,
    updatePayoutDetails,
    getAllPayoutDetails,

}

function addExpenceStatement(data, callback) {
    return dispatch => {
        dispatch(request())

        expenceStatementService.addExpenceStatement(data)
            .then(
                verified =>{ 
                    dispatch(success(verified))
                    callback.success(verified)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: expenceStatementConstants.ADD_COMMISSION_CALCULATION_REQUEST } }
    function success(result) { return { type: expenceStatementConstants.ADD_COMMISSION_CALCULATION_SUCCESS, result } }
    function failure(error) { return { type: expenceStatementConstants.ADD_COMMISSION_CALCULATION_FAILURE, error } }
}

function getExpenceStatement(data) {
    return dispatch => {
        dispatch(request())

        expenceStatementService.getExpenceStatement(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: expenceStatementConstants.GET_COMMISSION_CALCULATION_REQUEST } }
    function success(result) { return { type: expenceStatementConstants.GET_COMMISSION_CALCULATION_SUCCESS, result } }
    function failure(error) { return { type: expenceStatementConstants.GET_COMMISSION_CALCULATION_FAILURE, error } }
}

function getPayoutDetails(data) {
    return dispatch => {
        dispatch(request())

        expenceStatementService.getPayoutDetails(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: expenceStatementConstants.GET_PAYOUT_DETAILS_REQUEST } }
    function success(result) { return { type: expenceStatementConstants.GET_PAYOUT_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: expenceStatementConstants.GET_PAYOUT_DETAILS_FAILURE, error } }
}

function updatePayoutDetails(data,callback) {
    return dispatch => {
        dispatch(request())

        expenceStatementService.updatePayoutDetails(data)
            .then(
                verified => {
                    dispatch(success(verified))
                    callback.success()
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Deposited Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: expenceStatementConstants.UPDATE_PAYOUT_DETAILS_REQUEST } }
    function success(result) { return { type: expenceStatementConstants.UPDATE_PAYOUT_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: expenceStatementConstants.UPDATE_PAYOUT_DETAILS_FAILURE, error } }
}


function getAllPayoutDetails(data) {
    return dispatch => {
        dispatch(request())

        expenceStatementService.getAllPayoutDetails(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_REQUEST } }
    function success(result) { return { type: expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: expenceStatementConstants.GET_ALL_PAYOUT_DETAILS_FAILURE, error } }
}

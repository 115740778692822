import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, FormFeedback, Collapse } from 'reactstrap';
import { Link } from "react-router-dom"
import swal from 'sweetalert'

import { salesAction } from '../../../actions/sales.action';
import { SALES_STATUS } from '../../../constants';
import moment from 'moment';

import { TimelineStructure, NoteText, NotesInput, CommonDatePicker } from './CommonComponents';
import { ReputeIDIndividualInput } from '../../../../components/Forms/Inputs';
import { validReputeIdIndividual, validReputeIdCorporate } from '../../../../helpers';
import { CompanyReputeIDs } from './CompanyReputeIDs';
import FormHelpText from '../../../../components/Messages/FormHelpText';


class CustomerMeetingForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                action_status: -1,
                action_note: "",
                registeration_status: 0,
                schedule_date: new Date(),
                schedule_meeting_date: new Date(),
                company: []
            },
            errors: []
        }

        this.setStatus = this.setStatus.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validation = this.validation.bind(this)
        this.handleChildValue = this.handleChildValue.bind(this);
        this.startDemo = this.startDemo.bind(this)
        this.afterAction = this.afterAction.bind(this)

    }


    handleChange(event) {
        const { name, value } = event.target;
        const { formData, errors } = this.state;
        formData[name] = value
        errors[name] = ""
        this.setState({ formData, errors });
    }


    afterAction() {
        const { dispatch, customer_id } = this.props;
        dispatch(salesAction.getCustomerSalesStatusData({ customer_id: customer_id }));
    }

    handleSubmit(e) {
        e.preventDefault();
        const { formData, cs_id } = this.state;
        const { dispatch, customer_id, recordCounts } = this.props;

        if (this.validation()) {
            return false
        }

        let action_data = {};
        if (formData.action_status === 1) {
            action_data = {};
            action_data.registeration_status = formData.registeration_status;

            if (formData.registeration_status === 1) {
                action_data.individual_repute_id = "RI-" + formData.individual_repute_id
                action_data.company = formData.company
            }

        }

        
        if (formData.action_status === 2) {
            action_data = {
                schedule_meeting_date: moment(formData.schedule_meeting_date).format("YYYY-MM-DD HH:mm:ss"),
            }
        }


        let dataToSend = {
            action_data: JSON.stringify(action_data),
            action_status: formData.action_status,
            action_type: SALES_STATUS.MEETING,
            action_note: formData.action_note,
            customer_id: customer_id,
            recordCounts: recordCounts
        };
        if (cs_id) {
            dataToSend.cs_id = cs_id
        }


        let _this = this
        swal({
            title: "Are you sure ?",
            text: "Do you want to update status?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(salesAction.updateCustomerSalesStatus(dataToSend, _this));
            }
        })
    }

    renderCustomerName(cell, row, rowIndex, formateExtraData) {
        return row.first_name + " " + row.last_name
    }

    setStatus(event) {
        const { formData } = this.state;
        formData[event.target.name] = parseInt(event.target.value, 10)
        this.setState({ formData })
    }

    onChange = date => {
        const { formData } = this.state;
        formData["schedule_meeting_date"] = date;
        this.setState({ formData })
    }

    componentDidMount() {
        const { formData } = this.props;

        if (formData) {
            let action_data = formData.action_data ? JSON.parse(formData.action_data) : {};

            this.setState({
                formData: {
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : -1,
                    company: action_data.company !== undefined ? action_data.company : [""],
                    registeration_status: action_data.registeration_status !== undefined ? parseInt(action_data.registeration_status, 10) : -1,
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                    individual_repute_id: action_data.individual_repute_id !== undefined ? action_data.individual_repute_id : "",
                    schedule_meeting_date: action_data.schedule_meeting_date !== undefined ? action_data.schedule_meeting_date : "",
                },
                oldFormData: {
                    ...formData,
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    company: action_data.company !== undefined ? action_data.company : [""],
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : -1,
                    registeration_status: action_data.registeration_status !== undefined ? parseInt(action_data.registeration_status, 10) : -1,
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                    schedule_meeting_date: action_data.schedule_meeting_date !== undefined ? action_data.schedule_meeting_date : "",
                },
                cs_id: formData.id
            })
        }

    }

    validation() {
        const { formData, errors } = this.state;
        let error = false;
        if (formData.action_status === 1) {
            if (formData.registeration_status === 1) {
                if (!validReputeIdIndividual("RI-" + formData.individual_repute_id)) {
                    errors["individual_repute_id"] = "Please enter valid Repute ID.";
                    error = true;
                }
                errors["company"] = []
                formData.company.map(function (id, index) {
                    if (!id) {
                        errors["company"][index] = "This field is required."
                        error = true;

                    } else if (!validReputeIdCorporate(id)) {
                        errors["company"][index] = "Enter valid company repute id."
                        error = true;

                    }
                    return false
                })
            }

            if (formData.action_note.trim() === "") {
                errors["action_note"] = "Please enter Notes.";
                error = true;
            }
        }


        if (formData.action_status === 0) {
            if (formData.action_note.trim() === "") {
                errors["action_note"] = "Please enter Notes.";
                error = true;
            }
        }

        this.setState({ errors })
        return error;
    }


    handleChildValue(data) {
        const { formData } = this.state
        const { name, value } = data;
        formData[name] = value;
        this.setState({ formData })
    }

    startDemo() {
          const { dispatch } = this.props;
          dispatch(salesAction.addDemoAppData());
        //   this.afterAction();
    }

    // afterStartDemo() {
    //     const { auth } =  this.props
    //     if(auth ) {
    //         let access_code = auth.user.unique_id
    //         let userId = `9876${access_code}01`
    //         window.open("https://demo.reputeinfo.com/login?userID="+userId, "_blank")
    //     }
    // }
   
    render() {
        const { loading } = this.props
        const { formData, errors, oldFormData } = this.state

        let iconStyle = {
            background: '#FFBC34', color: '#fff'
        }
        if (oldFormData && parseInt(oldFormData.action_status, 10) === 1) {
            iconStyle = {
                background: '#5AC146', color: '#fff'
            }
        }
        if (oldFormData && parseInt(oldFormData.action_status, 10) === 0) {
            iconStyle = {
                background: '#FA5838', color: '#fff'
            }
        }

        return (
            <React.Fragment>
                
                <TimelineStructure
                    title={"Customer Meeting"}
                    iconStyle={iconStyle}
                    icon={<i className="fas fa-thumbs-up"></i>}
                    oldFormData={oldFormData}
                >
                    <Form >
                    {oldFormData && parseInt(oldFormData.action_status, 10) !== 2 && <React.Fragment>
                        <button className="btn btn-sm btn-primary mb-3 mt-1" type="button" onClick={this.startDemo}>Reset & Start Demo</button> <br/>
                    </React.Fragment>
                     } 
                    
                     {oldFormData && parseInt(oldFormData.action_status, 10) === 1 && parseInt(oldFormData.registeration_status, 10) === 1 &&
                            <React.Fragment>
                                Yes ! Customer is interested.

                                    <React.Fragment>

                                    <FormGroup className="mt-2">
                                        <label className="font-weight-bold">Repute ID</label>
                                        <ReputeIDIndividualInput name="individual_repute_id" value={formData.individual_repute_id} disabled={true} handleChange={this.handleChange} />

                                    </FormGroup>


                                    <FormGroup>
                                        <label className="font-weight-bold"> Company</label>
                                        {formData.company.length ?
                                            <CompanyReputeIDs handleChildValue={this.handleChildValue} errors={errors["company"]} company={formData.company} disabled={true} /> :
                                            <div> Company not added. </div>}
                                    </FormGroup>
                                </React.Fragment>

                                <NoteText action_note={formData.action_note} />
                            </React.Fragment>
                        }

                        {oldFormData && parseInt(oldFormData.action_status, 10) === 0 &&
                            <React.Fragment>
                                Customer is not interested.
                                <NoteText action_note={formData.action_note} />
                            </React.Fragment>
                        }

                        {oldFormData && parseInt(oldFormData.action_status, 10) === 2 &&
                            <React.Fragment> 
                                {formData.schedule_meeting_date && <React.Fragment>
                                <p> Meeting is rescheduled on {moment(formData.schedule_meeting_date).format("YYYY-MM-DD hh:mm A")} </p>
                            </React.Fragment>
                            }
                                <NoteText action_note={formData.action_note} />
                            </React.Fragment>
                        }

                        {(!oldFormData || (oldFormData && parseInt(oldFormData.action_status, 10) === 1 && parseInt(oldFormData.registeration_status, 10) === 0)) &&
                            <React.Fragment>

                                <FormGroup >
                                    {/* <label><b> Is Customer Interested ?  </b></label> */}
                                    <div onChange={this.setStatus.bind(this)}>
                                        
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="in_meeting_reschedule" name="action_status" value="2" class="custom-control-input" checked={formData.action_status === 2} />
                                            <label class="custom-control-label" for="in_meeting_reschedule" >Reschedule Meeting</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="in_meeting_interest_no" name="action_status" value="0" class="custom-control-input" checked={formData.action_status === 0} />
                                            <label class="custom-control-label" for="in_meeting_interest_no" >Not interested</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="in_meeting_interest_yes" name="action_status" value="1" class="custom-control-input" checked={formData.action_status === 1} />
                                            <label class="custom-control-label" for="in_meeting_interest_yes" >Yes interested</label>
                                        </div>
                                    </div>
                                    <FormFeedback>
                                        {errors[`action_status`] && errors[`action_status`]}
                                    </FormFeedback>
                                </FormGroup>

                                <Collapse isOpen={formData.action_status === 2}>
                                    <CommonDatePicker selectedDate={formData.schedule_meeting_date} onChange={this.onChange} />
                                </Collapse>


                                <Collapse isOpen={formData.action_status === 1}>

                                    <FormGroup >
                                        {/* <label><b> Is Customer Interested ?  </b></label> */}
                                        <div onChange={this.setStatus.bind(this)}>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="registered_later" name="registeration_status" value="0" class="custom-control-input" checked={formData.registeration_status === 0} />
                                                <label class="custom-control-label" for="registered_later" >Register Later</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="registered" name="registeration_status" value="1" class="custom-control-input" checked={formData.registeration_status === 1} />
                                                <label class="custom-control-label" for="registered" >Registered</label>
                                            </div>
                                        </div>
                                        <FormFeedback>
                                            {errors[`registeration_status`] && errors[`registeration_status`]}
                                        </FormFeedback>
                                    </FormGroup>


                                    <Collapse isOpen={formData.registeration_status === 1}>

                                        <FormGroup>
                                            <label className="font-weight-bold"> Individual Repute ID</label>
                                            <ReputeIDIndividualInput name="individual_repute_id" value={formData.individual_repute_id} handleChange={this.handleChange} />
                                            <FormFeedback>
                                                {errors[`individual_repute_id`] && errors[`individual_repute_id`]}
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup>
                                            <label className="font-weight-bold"> Company</label>
                                            <CompanyReputeIDs handleChildValue={this.handleChildValue} errors={errors["company"]} company={formData.company} />
                                        </FormGroup>

                                    </Collapse>
                                </Collapse>

                                <NotesInput action_note={formData.action_note} handleChange={this.handleChange} errors={errors} />

                                <Collapse isOpen={formData.action_status === 1 && formData.registeration_status === 1}>

                                    <FormGroup>
                                        <FormHelpText color={"text-danger "} message={"Note: Please make sure the above details are correct and complete. After this update, it is considered as complete and you can not update any more."}></FormHelpText>
                                    </FormGroup>
                                </Collapse>

                                <FormGroup>
                                    <Button color="primary" onClick={this.handleSubmit} disabled={loading || parseInt(formData.action_status, 10) === -1}>{loading ? "Please wait..." : "Update"}</Button> {" "}
                                    <Link className="btn btn-secondary" to={"/customer-sales-status"}> Cancel </Link>
                                </FormGroup>


                            </React.Fragment>}
                    </Form>
                </TimelineStructure>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.updateCustomerSalesStatus
    return {
        loading,
        data,
        auth: state.authentication,
    }
}

const connectedCustomerMeetingForm = connect(mapStateToProps)(CustomerMeetingForm)
export { connectedCustomerMeetingForm as CustomerMeetingForm }
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { profileActions } from '../../actions';
import { CardBody, Card, CardHeader, ModalBody, ModalHeader, ModalFooter, Button, Modal, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import CheckboxTree from 'react-checkbox-tree';
import { Tree, TreeNode } from 'react-organizational-chart';
import UpdateBankDetail from './UpdateBankDetail'
import UpdatePAN from './UpdatePAN';
import QRCode from '../../../assets/img/upi-qr-code.png';
import { currencyFormat } from '../../../helpers';
import { DepositeAccountDetails } from './DepositeAccountDetails';
import { Payments } from './Payments';
import UpdateBankUPI from './UpdateBankUPI'
import UpdateCommissionTarget from './UpdateCommissionTarget';
import UpdateBusinessTarget from './UpdateBusinessTarget';
class Profile extends Component {
    constructor(props) {
        super(props)
        this.getProfile = this.getProfile.bind(this);
    }

    getProfile() {
        const { dispatch } = this.props;
        dispatch(profileActions.getProfile())
    }
    componentDidMount() {
        this.getProfile();
    }

    renderTree(childs) {
        return childs.map((eachitem, idx) => {

            return <React.Fragment key={idx}>
                {eachitem.child.length ? <TreeNode label={<div>{eachitem.name} ({eachitem.user_type.name})</div>}>

                    {eachitem.child.length ? this.renderTree(eachitem.child) : false}

                </TreeNode> : <TreeNode label={<div>{eachitem.name} ({eachitem.user_type.name})</div>} />}

            </React.Fragment>

        })

    }
    render() {
        const { loading, data } = this.props
        return (<React.Fragment>

            <Card>
                <CardHeader>
                    <i className="fa fa-user" />
                    <strong>Profile</strong>
                </CardHeader>
                <CardBody>
                    {loading ? (
                        <TableLoader />
                    ) : (
                        <React.Fragment>

                            <div className="row" >
                                <div className="col-md-6" >
                                    <div className="row mb-2" >
                                        <div className="col-md-4"><b>Name </b></div>
                                        <div className="col-md-8">{data ? data.name : ''}</div>
                                    </div>
                                    <div className="row mb-2" >
                                        <div className="col-md-4"><b>Email </b></div>
                                        <div className="col-md-8">{data ? data.email : ''}</div>
                                    </div>
                                    <div className="row mb-2" >
                                        <div className="col-md-4"><b>Mobile </b></div>
                                        <div className="col-md-8">{data ? data.mobile : ''}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-4"><b>User Type </b></div>
                                        <div className="col-md-8">{data && data.user_type ? data.user_type.name : ''}&nbsp;
                                            {
                                                data && data.user_type && (data.user_type.name == 'Partner' || data.user_type.name == 'Reseller' || data.user_type.name == 'Associate' || data.user_type.name == 'Employee') ? <React.Fragment>
                                                    {data && data.is_active_account ? <span className='badge badge-success'>Active - {data.deposit_type} </span> : <span className='badge badge-danger'>Inactive</span>}
                                                    &nbsp;
                                                    {data && data.is_upgrade_account ? <span className='badge badge-danger'>Upgrade to {data.upgrade_user_type} - Pending </span> : false}
                                                </React.Fragment> : ""}
                                                {data && data.hasOwnProperty('first_recharge')  &&
                                            <table className='table table-sm table-striped table-bordered table-outline mt-2 text-center' style={{fontSize:"10px"}}>
                                                <thead>
                                                    <tr>
                                                        <th>First Recharge</th>
                                                        <th>Trail Recharge</th>
                                                        <th>Service Recharge</th>
                                                        <th>Flat Recharge</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td> {data.first_recharge} %</td>
                                                        <td>{data.trail_recharge} %</td>
                                                        <td>{data.service_recharge} %</td>
                                                        <td> ₹  {data.flat_recharge} </td>
                                                    </tr>
                                                </tbody>
                                            </table> }
                                        </div>
                                    </div>
                                    {
                                        data && data.user_type ?
                                            data.user_type.name == 'Partner' || data.user_type.name == 'Reseller' || data.user_type.name == 'Associate' || data.user_type.name == 'Employee' ?
                                                <React.Fragment>
                                                    <div className="row mb-2" >
                                                        <div className="col-md-4"><b>Deposit Amount </b></div>
                                                        <div className="col-md-8">
                                                            {data && data.deposit_amount ? <React.Fragment>
                                                                ₹ &nbsp;{currencyFormat(data.deposit_amount)}

                                                            </React.Fragment> : '₹ 0.00'}

                                                            &nbsp; &nbsp;
                                                            {data && data.total_pending_amount ? <React.Fragment>
                                                                <label className='badge badge-danger'>
                                                                    Pending - ₹ &nbsp;{currencyFormat(data.total_pending_amount)}
                                                                </label>
                                                            </React.Fragment> : ""}


                                                            &nbsp; &nbsp;
                                                            {data && data.total_deposited_amount ?
                                                                <React.Fragment>
                                                                    <label className='badge badge-success'>
                                                                        Received - ₹ &nbsp; {currencyFormat(data.total_deposited_amount)}
                                                                    </label>
                                                                </React.Fragment>
                                                                : ''}
                                                            &nbsp;

                                                            {data && data.total_deposited_amount ? <React.Fragment>
                                                                {/* <label className='badge badge-danger'> */}
                                                                <br />
                                                                Last Payment Date - {data.last_payment_date}
                                                                {/* </label> */}
                                                            </React.Fragment> : ""}
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2" >
                                                        <div className="col-md-4"><b>Deposit Lock-in </b></div>
                                                        <div className="col-md-8">{data && data.deposit_lockin ? data.deposit_lockin + ' days. (' + data.deposit_lockin_date + ')' : '-'}</div>
                                                    </div>
                                                </React.Fragment>
                                                : ''
                                            : ''
                                    }

                                    <div className="row mb-2">
                                        <div className="col-md-4"><b>PAN Number</b></div>
                                        <div className="col-md-8">{data && data.pan ? data.pan : ''} <UpdatePAN {...this.props} element={data} getData={this.getProfile} /></div>
                                    </div>
                                    {
                                        data && data.bank_account_details ?
                                            <React.Fragment>
                                                <h6 className='mt-2'>Your Account Details.
                                                    Your Commision will be deposit in this account.</h6>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Bank name </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details ? data.bank_account_details.bank_name : ''}</div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account holder name </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details ? data.bank_account_details.account_holder_name : ''}</div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account number	 </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details ? data.bank_account_details.account_number : ''}</div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-4"><b>IFSC code </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details ? data.bank_account_details.ifsc_code : ''}
                                                    </div>
                                                </div>
                                                <div className="row mb-2" >
                                                    <div className="col-md-4"><b>Account type </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details ? ( parseInt(data.bank_account_details.account_type, 10) == 1 ? 'Saving' : 'Current') : ''}</div>
                                                </div>
                                                {/* <div className="row mb-2" >
                                                    <div className="col-md-4"><b>UPI </b></div>
                                                    <div className="col-md-8">{data && data.bank_account_details && data.bank_account_details.upi  ? data.bank_account_details.upi  : ''}</div>
                                                </div> */}
                                            </React.Fragment>
                                            : ''
                                    }
                                    <UpdateBankDetail {...this.props} element={data} getData={this.getProfile} /><br />
                                    {/* <UpdateBankUPI    {...this.props} element={data} getData={this.getProfile} /> */}
                                    <UpdateBusinessTarget    {...this.props} element={data} getData={this.getProfile} />
                                    <UpdateCommissionTarget    {...this.props} element={data} getData={this.getProfile} />
                                </div>

                                <div className="col-md-6" >

                                    <DepositeAccountDetails />
                                    
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>



            <Card>
                <CardHeader>
                    <i className="fa fa-users" />
                    <strong>User Tree</strong>
                </CardHeader>
                <CardBody>

                    {data ? <Tree label={<div>{data.name}</div>}>

                        {this.renderTree(data.child)}

                    </Tree> : false}


                </CardBody>
            </Card>

        </React.Fragment>
        );

    }
}
function mapStateToProps(state) {
    const { loading, data } = state.getProfile
    return { loading, data }
}

const connectedProfile = connect(mapStateToProps)(Profile);
export { connectedProfile as Profile }
import { notify } from 'reapop'
import { accountConstants } from '../constants'
import { accountService } from '../services'

export const accountAction = {
    getAccountData,
    proceedExtend,
    proceedChequePayment,
    getUserSalaryData,
    getChequeDetail,
    updateChequeDetail,
    getCommissionData,
    saveCompanyProductTags,
    getCompanyProductTags,
    deleteCompanyProductTags,
    getProductTagsAlphabatically,
    getDepositReceipt,
    updateDepositReceipt,
    closeCredit,
    getAccountAnalysisData , 
}

function getAccountData(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getAccountData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_ACCOUNT_DATA_REQUEST } }
    function success(result) { return { type: accountConstants.GET_ACCOUNT_DATA_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_ACCOUNT_DATA_FAILURE, error } }
}
function getAccountAnalysisData(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getAccountAnalysisData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_ACCOUNT_ANALYSIS_DATA_REQUEST } }
    function success(result) { return { type: accountConstants.GET_ACCOUNT_ANALYSIS_DATA_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_ACCOUNT_ANALYSIS_DATA_FAILURE, error } }
}

function proceedExtend(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.proceedExtend(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                    dispatch(notify({ position: "tc", dismissible: false, message: "Balance added Successfully", status: "success" }))
                
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.EXTEND_PLAN_REQUEST } }
    function success(result) { return { type: accountConstants.EXTEND_PLAN_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.EXTEND_PLAN_FAILURE, error } }
}

function proceedChequePayment(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.proceedChequePayment(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                    dispatch(notify({ position: "tc", dismissible: false, message: data.type === 'renew' ? "Plan renew Successfully" : "Plan upgrade Successfully", status: "success" }))
                
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.EXTEND_CHEQUE_PAYMENT_REQUEST } }
    function success(result) { return { type: accountConstants.EXTEND_CHEQUE_PAYMENT_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.EXTEND_CHEQUE_PAYMENT_FAILURE, error } }
}

function getUserSalaryData(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getUserSalaryData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_USER_SALARY_DATA_REQUEST } }
    function success(result) { return { type: accountConstants.GET_USER_SALARY_DATA_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_USER_SALARY_DATA_FAILURE, error } }
}

function getChequeDetail(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getChequeDetail(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_CHEQUE_DETAIL_REQUEST } }
    function success(result) { return { type: accountConstants.GET_CHEQUE_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_CHEQUE_DETAIL_FAILURE, error } }
}

function updateChequeDetail(data,ref) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.updateChequeDetail(data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Cheque Detail Update Succesfully', status: "success" }))
                    ref.afterAction()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.UPDATE_CHEQUE_DETAIL_REQUEST } }
    function success(result) { return { type: accountConstants.UPDATE_CHEQUE_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.UPDATE_CHEQUE_DETAIL_FAILURE, error } }
}

function getCommissionData(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getCommissionData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_COMMISSION_REQUEST } }
    function success(result) { return { type: accountConstants.GET_COMMISSION_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_COMMISSION_FAILURE, error } }
}


function saveCompanyProductTags(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.saveCompanyProductTags(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.SAVE_COMPANY_PRODUCT_TAGS_REQUEST } }
    function success(result) { return { type: accountConstants.SAVE_COMPANY_PRODUCT_TAGS_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.SAVE_COMPANY_PRODUCT_TAGS_FAILURE, error } }
}


function getCompanyProductTags(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getCompanyProductTags(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_COMPANY_PRODUCT_TAGS_REQUEST } }
    function success(result) { return { type: accountConstants.GET_COMPANY_PRODUCT_TAGS_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_COMPANY_PRODUCT_TAGS_FAILURE, error } }
}


function deleteCompanyProductTags(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.deleteCompanyProductTags(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.DELETE_COMPANY_PRODUCT_TAGS_REQUEST } }
    function success(result) { return { type: accountConstants.DELETE_COMPANY_PRODUCT_TAGS_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.DELETE_COMPANY_PRODUCT_TAGS_FAILURE, error } }
}

function getProductTagsAlphabatically(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getProductTagsAlphabatically(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_PRODUCT_TAG_REQUEST } }
    function success(result) { return { type: accountConstants.GET_PRODUCT_TAG_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_PRODUCT_TAG_FAILURE, error } }
}

function getDepositReceipt(data) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.getDepositReceipt(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.GET_DEPOSIT_RECEPIT_REQUEST } }
    function success(result) { return { type: accountConstants.GET_DEPOSIT_RECEPIT_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.GET_DEPOSIT_RECEPIT_FAILURE, error } }
}

function updateDepositReceipt(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.updateDepositReceipt(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success()
                    dispatch(notify({ position: "tc", dismissible: false, message: 'status updated Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.UPDATE_DEPOSIT_RECEPIT_REQUEST } }
    function success(result) { return { type: accountConstants.UPDATE_DEPOSIT_RECEPIT_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.UPDATE_DEPOSIT_RECEPIT_FAILURE, error } }
}

function closeCredit(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        accountService.closeCredit(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success()
                    dispatch(notify({ position: "tc", dismissible: false, message: 'status updated Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: accountConstants.CLOSE_DEPOSIT_RECEPIT_REQUEST } }
    function success(result) { return { type: accountConstants.CLOSE_DEPOSIT_RECEPIT_SUCCESS, result } }
    function failure(error) { return { type: accountConstants.CLOSE_DEPOSIT_RECEPIT_FAILURE, error } }
}

export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`plan_title`]) {
        formIsValid = false
        errors[`plan_title`] = 'Can Not be empty'
    }

    if (!fields[`features_list`]) {
        formIsValid = false
        errors[`features_list`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
export const notificationConstants = {
    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATION_REQUEST',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATION_FAILURE',

    EDIT_NOTIFICATION_REQUEST: 'EDIT_NOTIFICATION_REQUEST',
    EDIT_NOTIFICATION_SUCCESS: 'EDIT_NOTIFICATION_SUCCESS',
    EDIT_NOTIFICATION_FAILURE: 'EDIT_NOTIFICATION_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

    PUBLISH_NOTIFICATION_REQUEST: 'PUBLISH_NOTIFICATION_REQUEST',
    PUBLISH_NOTIFICATION_SUCCESS: 'PUBLISH_NOTIFICATION_SUCCESS',
    PUBLISH_NOTIFICATION_FAILURE: 'PUBLISH_NOTIFICATION_FAILURE',
}
import React, { Component } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import BusinessDetails from "./BusinessDetails";
import IndividualDetailsSection from "./IndividualDetailsSection";



export default class DataSection extends Component {
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <BusinessDetails {...this.props} />
          </CardBody>
        </Card>
        
        <Card>
          <CardBody>
            <IndividualDetailsSection  {...this.props}/>
          </CardBody>
        </Card>
      </div>
    );
  }
}

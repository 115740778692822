

import {  axiosAjaxCall } from './common.service'

export const landingPageService = {
    getLandingPageData,
    sendLandingPageData,
    getLandingPageUserData
}

function getLandingPageData(data) {
    return axiosAjaxCall("get_landing_page_template" , data );
}
function getLandingPageUserData() {
    return axiosAjaxCall("get_get_in_touch_detail");
}

function sendLandingPageData(data) {
    return axiosAjaxCall("save_landing_page_template", data);
}






import React, { Component } from "react";
import { InputCheckBox } from "../../Settings/StageSetting/Components/FormElements";

export default class CheckLiistItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
        data : {}
    };
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event, index){
    
    const {  save} = this.props

    const { data } = this.state;

    data.items[index].value = event.target.checked
    
    this.setState({data}, () => 
    save({...data} ));

    
  }

  componentDidMount() {
    const { current_data } = this.props;

    if (current_data) {
      this.setState({ data: current_data });
    }
  }

  render() {
    const { data } = this.state;
    let _this = this;
    return (
      <div>
        <h5 style={{fontSize:"14px"}}> <i className="fa fa-caret-right"></i> &nbsp; {data.title}</h5>
        {data.items &&
          data.items.map(function (eachItem, index) {
            return (
              <InputCheckBox
                key={index}
                title={eachItem.title}
                name={eachItem.id}
                checked={eachItem.value}
                onChange={(e) => _this.handleChange(e, index)}
              />
            );
          })}
      </div>
    );
  }
}

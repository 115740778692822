import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback
  } from "reactstrap";
  import { Tooltip } from 'react-tippy';
  import { accountAction } from '../../actions';
  import { TableLoader } from '../../../components/Loaders';
  import { connect } from 'react-redux';
  import { currencyFormat } from '../../../helpers';
import moment from "moment";
class UserSalaryCommisionDetail extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
          modalOpan: false,        
        };
        this.showModal = this.showModal.bind(this);
       this.getCommissionData = this.getCommissionData.bind(this);
    }

    showModal() {
        const { modalOpan } = this.state;
        this.setState({ modalOpan: !modalOpan },() => {
            if(modalOpan == false) {
               this.getCommissionData();
            }
        });
    }
    getCommissionData() {
        const { element,startDate, dispatch } = this.props;
        console.log(startDate);
        let dataToSend = {
            user_id : element.user_id,
            month: moment(startDate).format('YYYY-MM-DD')
        }

        dispatch(accountAction.getCommissionData(dataToSend));
    }
    
	componentDidMount() {
	//	this.getCommissionData();
	}

  getDebitTotal(data) {
    let total = 0 ;
    data.map((d) => {
      total = total + parseInt(d.give_amount,10);
    })
    return total;
  }
  getCreditTotal(data) {
    let total = 0 ;
    data.map((d) => {
      total = total + parseInt(d.take_amount,10);
    })
    return total;
  }
  getCommissionTotal(data) {
   
    return  this.getCreditTotal(data) -  this.getDebitTotal(data)  ;
  }

  renerCommissionReference(type, percentage) { 
    if(type === "FLAT_INTRO_AMOUNT") {
      return "-"
    }
    else {
      return " @ " +percentage +"%"
    }
  }

  renderRowSpan(data){
    let span = 0;

    if(data.commission_type === "TRAIL_RECHARGE")  {   
      if(data.user_commission_log_id_child) {
        span = 4
      }   else {
        span = 2
      }

    } else {
      if(data.user_commission_log_id_child) {
        span = 2
      }   else {
        span = 1
      }
      
    }
    return span;
  }
    render() {
        const { modalOpan } = this.state;
        const { loading,data , element} = this.props;
    
        let _this = this;
        return (
          <div>
             <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Commission Detail">
                        
            <Button color="success" onClick={_this.showModal}>
              <i class="fas fa-money"></i>
            </Button>
            </Tooltip>
            <Modal className="modal-success" size="lg" isOpen={modalOpan}>
              <ModalHeader toggle={_this.showModal}>Commission  Detail<br />
                <small>{element ? element.name :''}</small>
                </ModalHeader>
              <ModalBody>
                {loading ? (<TableLoader />) : 
                    data ? (
						<div className="table-responsive">				
                            <div className="table-responsive-sm  animated fadeIn">
                                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                    <thead class="thead-light">
                                      <tr>
                                        <th>InvoiceNo</th>
                                        <th>Repute ID</th>
                                        <th>Comapny Name</th>
                                        <th>Balance Recharge</th>
                                        <th>Type</th>
                                        <th>Reference</th>
                                        <th>Credit</th>
                                        <th>Debit</th>
                                        <th>Debited To</th>
                                        <th>Date Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((d) => {
                                                return ( 
                                                  <React.Fragment>
                                                    <tr>
                                                        {d.commission_type !== 'SERVICE_RECHARGE' ?<React.Fragment>  
                                                          <td rowSpan={this.renderRowSpan(d)}>{ d.invoice_number }</td>
                                                          <td rowSpan={this.renderRowSpan(d)}>{ d.repute_id }</td>
                                                          <td rowSpan={this.renderRowSpan(d)}>{ d.company_name }</td>
                                                          <td rowSpan={this.renderRowSpan(d)}> {  
                                                          parseInt(d.recharge_amount,10) == 0 ? "-" :
                                                          <React.Fragment>
                                                          ₹  { currencyFormat( d.recharge_amount) }
                                                          </React.Fragment>}
                                                          </td>
                                                        </React.Fragment> : false
                                                        }   
                                                        <td rowSpan={d.user_commission_log_id_child ? '2': '1'}>{ d.commission_type }</td>
                                                        <td>{ this.renerCommissionReference(d.commission_type, d.take_percentage ) }</td>
                                                        <td>₹ { currencyFormat(d.take_amount) }</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{ d.created_at }</td>
                                                    </tr>
                                                    {d.user_commission_log_id_child? <tr>

                                                        <td>{ this.renerCommissionReference(d.commission_type, d.give_percentage) }</td>
                                                        <td></td>
                                                        <td>₹ { currencyFormat(d.give_amount) }</td>
                                                        <td>{d.debit_to_name}</td>
                                                        <td>{ d.created_at }</td>
                                                    </tr> : false}
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                          <th colSpan="6" className="text-right">Total</th>
                                          <th>₹ { currencyFormat(this.getCreditTotal(data)) }</th>
                                          <th>₹ { currencyFormat(this.getDebitTotal(data) )}</th>
                                          <th></th>
                                          <th></th>
                                        </tr>
                                        <tr>
                                          <th colSpan="7" className="text-right">Total Commission</th>
                                          <th>₹ { currencyFormat(this.getCommissionTotal(data)) }</th>
                                          <th></th>
                                          <th></th>
                                        </tr> 
                                    </tfoot>
                                </table>
                            </div>
						</div>
                    ) : (
                        ''
                    )}
                   
              </ModalBody>
              {/* <ModalFooter>
                <Button onClick={_this.showModal}>Close</Button>
              </ModalFooter> */}
            </Modal>
          </div>
        );
    }
  }

  function mapStateToProps(state) {
	const { loading, data } = state.getCommissionData;
   
	return {
		loading,
		data
	};
}

const connectedUserSalaryCommisionDetail = connect(mapStateToProps)(UserSalaryCommisionDetail);
export { connectedUserSalaryCommisionDetail as UserSalaryCommisionDetail };
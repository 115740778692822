import React, { Component } from 'react';
import { Button } from 'reactstrap';
import InlineError from "../../../../components/Messages/InlineError";
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { authActions } from '../../../actions';
import { GOOGLE_reCAPTCHA_SITE_KEY } from '../../../../api-config'
import { MobileNoInput } from '../../../../components/Forms/Inputs';
import ReCAPTCHA from "react-google-recaptcha";
import { validPhone } from '../../../../helpers';
import { ResetPasswordForm } from './ResetPasswordForm'
class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                MobileNo: "",
                captcha_result:""
            },
            errors: {},
            token: "",
            isOtpSend: false
        }
        this.handleChangeCaptcha = this.handleChangeCaptcha.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handelOtpSuccess = this.handelOtpSuccess.bind(this)

    }
    componentWillReceiveProps(props) {

        const { data } = props
        if (data) {
            this.setState({
                token: data.token
            })
        }
    }
    handleChangeCaptcha(value) {
        let name = "captcha_result";
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: ""
            }
        });
    }

    handleCancel() {
        this.props.history.push('/login')
    }
    handelOtpSuccess() {
        this.setState({
            isOtpSend: true
        })
    }
    // handle form submit event
    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { data } = this.state;
        const { dispatch } = this.props;

        if (this.isValidData()) {
            let dataToSend = {
                user_input: data.MobileNo
            }
            dispatch(authActions.forgetpassword(dataToSend, this))
        }
    }

    // form validation
    isValidData() {
        const { data } = this.state;
        let phoneErrorMessage = "", captcha_result = "", error = false;
        if (data.MobileNo === "") {
            phoneErrorMessage = "Please enter  mobile number";
            error = true;
        }
        else if (!validPhone(data.MobileNo)) {
            phoneErrorMessage = "Please enter valid mobile number";
            error = true;
        }
        
        if (data.captcha_result === "") {
            error = true;
            captcha_result = "Please complete above captcha challenge.";
        }

        this.setState({
            ...this.state,
            errors: {
                MobileNo: phoneErrorMessage,
                captcha_result
            }
        });
        return !error;
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            data: {
                [name]: value
            }
        });
    }
    render() {
        const { data, errors, isOtpSend,token } = this.state;
        const {  loading } = this.props;

        return (
            <div>
                {!isOtpSend ?
                    <React.Fragment>
                        <h3 className={" mb-3"}>  <Translate id="title.forget_password_title"> Forget Password ? </Translate> </h3>
                        <form  autocomplete="off" name="form" onSubmit={this.handleSubmit}>

                            <div className="mb-3">

                                <MobileNoInput name="MobileNo" value={data.MobileNo} handleChange={this.handleChange} />

                                {errors.MobileNo && <InlineError message={errors.MobileNo} />}

                            </div>

                            <div className="mb-3">
                                <ReCAPTCHA
                                    sitekey={GOOGLE_reCAPTCHA_SITE_KEY}
                                    onChange={this.handleChangeCaptcha}
                                />

                                {errors.captcha_result && <InlineError message={errors.captcha_result} />}

                            </div>

                            <Button type={"submit"} color="primary" className="px-4" disabled={loading}> {loading ? "Please wait..." : "Submit"}</Button> {" "}
                            <Button color="secondary" onClick={this.handleCancel}>Cancel</Button>
                        </form>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h3 className={" mb-3"}>  <Translate id="title.Change Password"> Reset Password </Translate> </h3>
                        <ResetPasswordForm token={token} />

                    </React.Fragment>}
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { registering } = state.registration;
    const { data } = state.forgotPassword
    return {
        registering,
        data
    };
}

const connectedForgotPasswordForm = withRouter(connect(mapStateToProps)(ForgotPasswordForm));
export { connectedForgotPasswordForm as ForgotPasswordForm };

import {  axiosAjaxCall } from './common.service'

export const expenceStatementService = {
    addExpenceStatement,
    getExpenceStatement,
    getPayoutDetails,
    updatePayoutDetails,
    getAllPayoutDetails,
}

function addExpenceStatement(data) {
    return axiosAjaxCall("calculateCommission", data);
}

function getExpenceStatement(data) {
    return axiosAjaxCall("getExpenceStatement", data);
}


function getPayoutDetails(data) {
    return axiosAjaxCall("getPayoutDetails", data);
}

function updatePayoutDetails(data) {
    return axiosAjaxCall("updatePayoutDetails", data);
}

function getAllPayoutDetails(data) {
    return axiosAjaxCall("getAllPayoutDetails", data);
}
export const profileConstants = {
    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    UPDATE_BANK_DETAIL_REQUEST: 'UPDATE_BANK_DETAIL_REQUEST',
    UPDATE_BANK_DETAIL_SUCCESS: 'UPDATE_BANK_DETAIL_SUCCESS',
    UPDATE_BANK_DETAIL_FAILURE: 'UPDATE_BANK_DETAIL_FAILURE',

    UPDATE_PAN_REQUEST: 'UPDATE_PAN_REQUEST',
    UPDATE_PAN_SUCCESS: 'UPDATE_PAN_SUCCESS',
    UPDATE_PAN_FAILURE: 'UPDATE_PAN_FAILURE',

    UPGRADE_USER_ACCOUNT_REQUEST: 'UPGRADE_USER_ACCOUNT_REQUEST',
    UPGRADE_USER_ACCOUNT_SUCCESS: 'UPGRADE_USER_ACCOUNT_SUCCESS',
    UPGRADE_USER_ACCOUNT_FAILURE: 'UPGRADE_USER_ACCOUNT_FAILURE',

    
    GET_PAYMENTS_DEPOSIT_REQUEST: 'GET_PAYMENTS_DEPOSIT_REQUEST',
    GET_PAYMENTS_DEPOSIT_SUCCESS: 'GET_PAYMENTS_DEPOSIT_SUCCESS',
    GET_PAYMENTS_DEPOSIT_FAILURE: 'GET_PAYMENTS_DEPOSIT_FAILURE',

    GET_COMMISIION_ACCOUNT_REQUEST: 'GET_COMMISIION_ACCOUNT_REQUEST',
    GET_COMMISIION_ACCOUNT_SUCCESS: 'GET_COMMISIION_ACCOUNT_SUCCESS',
    GET_COMMISIION_ACCOUNT_FAILURE: 'GET_COMMISIION_ACCOUNT_FAILURE',


    GET_HEADER_DATA_REQUEST: 'GET_HEADER_DATA_REQUEST',
    GET_HEADER_DATA_SUCCESS: 'GET_HEADER_DATA_SUCCESS',
    GET_HEADER_DATA_FAILURE: 'GET_HEADER_DATA_FAILURE',
}
import { searchLogsConstants } from "../constants"

export function getSearchLogsData(state = {}, action) {
    switch (action.type) {
        case searchLogsConstants.GET_SEARCH_LOG_DATA_REQUEST:
        return { loading: true }
        case searchLogsConstants.GET_SEARCH_LOG_DATA_SUCCESS:
        return { 
            data: action.result
            }
        case searchLogsConstants.GET_SEARCH_LOG_DATA_FAILURE:
        return {}
        default:
        return state
    }
}

import React, { Component, isValidElement } from 'react'
import { connect } from 'react-redux'
import {  Button,  Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Row, Col, Form, FormGroup, Input, InputGroup, FormFeedback, TabContent, TabPane, Nav, NavItem, NavLink, ButtonGroup } from 'reactstrap'
import { productTagActions } from '../../actions'
class AddSuperadminInteraction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false,
            formData : {
                not_interested : false,
                remark : ''
            },
            errors : {}
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getData = this.getData.bind(this);
        this.handelNotInterested = this.handelNotInterested.bind(this);
    }
    handelNotInterested(value) {
        const { formData } = this.state
        formData.not_interested = value
        this.setState({ formData })
    }

    handleToggle() {
        let { thisModel } = this.state;
        thisModel = !thisModel
        this.setState({
            thisModel: thisModel,
        },() => {
            if(thisModel == false) {
                this.reset()
            }
            else {
                this.getData()
            }
        })
    }

    handleChange(event) {
        const { name, value,type } = event.target
        const { formData, errors } = this.state
        let val = value;

        if (name === "remark") {
            let regex_allowed = /^[a-zA-Z0-9$#%/., -]+$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }

        if (type === "checkbox") {
            if (formData[name] === false) {
                val = true;
            } else if (formData[name] === true) {
                val = false;
            } else {
                val = false;
            }
        }
        formData[name] = val
        errors[name] = ''

        this.setState({ formData, errors })
    }
    isValid() {
        const { formData, errors } = this.state
        let error = false;

        if(!formData.remark) {
            error = true
            errors.remark = "this field is required"
        }

        this.setState({ errors })

        return error
    }
    handelSubmit() {
        const { buyer_requirement_id, seller_company_reputeid, dispatch, seller_call_detail, getData,invitee_id } = this.props
        const { formData } = this.state
        if(!this.isValid()) {
            let dataToSend = {
                buyer_requirement_id,
                seller_company_reputeid,
                not_interested : formData.not_interested,
                remark : formData.remark,
            }
            if(seller_company_reputeid) {
                dataToSend.seller_company_reputeid = seller_company_reputeid
            }
            else {
                dataToSend.invitee_id = invitee_id
            }
            if(seller_call_detail) {
                dataToSend.id = seller_call_detail.id
            }
            dispatch(productTagActions.addSuperadminInteraction(dataToSend,{
                success : () => {
                    this.handleToggle()
                    if (typeof getData === "function") {
                        getData();
                    }
                }
            }))
        }
    }
    reset() {
        const { formData } = this.state
        formData.remark = ''
        formData.not_interested = false
        this.setState({ formData })
    }

    getData() {
        const { seller_call_detail } = this.props
        const { formData } = this.state
        if(seller_call_detail) {
            formData.not_interested = seller_call_detail.not_interested == 1 ? true : false
            formData.remark = seller_call_detail.remark

            this.setState({ formData },()=> console.log('formData',formData))
        }
    }
    render() {
        const { thisModel , formData, errors} = this.state
        const { seller_call_detail } = this.props
        return (
            <React.Fragment>
                <Button size="sm"  color="primary" onClick={this.handleToggle}> Call { seller_call_detail && seller_call_detail.call_count > 0 ?  <label className="badge badge-light mb-0">{seller_call_detail.call_count}</label>  : false } </Button>
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="md" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> Call  </ModalHeader>
                    <ModalBody >
                        <Form  autoComplete="off" >
                            <FormGroup>
                                <label><b>Remark</b> </label>
                                <Input type="textarea" placeholder="Remarks..." name="remark" onChange={this.handleChange} value={formData.remark} maxLength="250" />
                                {errors[`remark`] && <FormFeedback> {errors[`remark`]}</FormFeedback>}
                            </FormGroup>
                            {/* <FormGroup style={{paddingLeft:'20px'}}>
                                <Input type="checkbox" name="not_interested"  checked={formData.not_interested} onChange={this.handleChange}  value={formData.not_interested} />  <label><b>Not Intererested</b> </label>
                            </FormGroup> */}
                            <FormGroup>
                                <ButtonGroup>
                                    <Button  onClick={() => this.handelNotInterested(true)} className={formData.not_interested ? 'border-success bg-success' : ''}>Send SMS</Button>
                                    <Button onClick={() => this.handelNotInterested(false)} className={formData.not_interested ? '' : 'border-success bg-success'}   >Not Intererested</Button>
                                </ButtonGroup>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={this.handelSubmit} color="primary">Complete Call</Button>
                        <Button onClick={this.handleToggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {  }
}
const AddSuperadminInteractionConnected = connect(mapStateToProps)(AddSuperadminInteraction);
export { AddSuperadminInteractionConnected as AddSuperadminInteraction }
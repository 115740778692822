export const verificationConstants = {
    GET_INDIVIDUALS_REQUEST: 'GET_INDIVIDUALS_REQUEST',
    GET_INDIVIDUALS_SUCCESS: 'GET_INDIVIDUALS_SUCCESS',
    GET_INDIVIDUALS_FAILURE: 'GET_INDIVIDUALS_FAILURE',

    GET_INDIVIDUAL_DETAIL_REQUEST: 'GET_INDIVIDUAL_DETAIL_REQUEST',
    GET_INDIVIDUAL_DETAIL_SUCCESS: 'GET_INDIVIDUAL_DETAIL_SUCCESS',
    GET_INDIVIDUAL_DETAIL_FAILURE: 'GET_INDIVIDUAL_DETAIL_FAILURE',
    GET_INDIVIDUAL_DETAIL_RESET: 'GET_INDIVIDUAL_DETAIL_RESET',

    VERIFY_INDIVIDUAL_REQUEST: 'VERIFY_INDIVIDUAL_REQUEST',
    VERIFY_INDIVIDUAL_SUCCESS: 'VERIFY_INDIVIDUAL_SUCCESS',
    VERIFY_INDIVIDUAL_FAILURE: 'VERIFY_INDIVIDUAL_FAILURE',

    GET_COMPANY_LIST_REQUEST: 'GET_COMPANY_LIST_REQUEST',
    GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
    GET_COMPANY_LIST_FAILURE: 'GET_COMPANY_LIST_FAILURE',

    GET_COMPANY_DETAIL_REQUEST: 'GET_COMPANY_DETAIL_REQUEST',
    GET_COMPANY_DETAIL_SUCCESS: 'GET_COMPANY_DETAIL_SUCCESS',
    GET_COMPANY_DETAIL_FAILURE: 'GET_COMPANY_DETAIL_FAILURE',
    GET_COMPANY_DETAIL_RESET: 'GET_COMPANY_DETAIL_RESET',

    VERIFY_COMPANY_REQUEST: 'VERIFY_COMPANY_REQUEST',
    VERIFY_COMPANY_SUCCESS: 'VERIFY_COMPANY_SUCCESS',
    VERIFY_COMPANY_FAILURE: 'VERIFY_COMPANY_FAILURE',

    ADD_TO_JUNK_USER: 'ADD_TO_JUNK_USER',
    ADD_TO_JUNK_USER_SUCCESS: 'ADD_TO_JUNK_USER_SUCCESS',
    ADD_TO_JUNK_USER_FAIL: 'ADD_TO_JUNK_USER_FAIL',

    FETCH_AND_VERIFY_REQUEST: 'FETCH_AND_VERIFY_REQUEST',
    FETCH_AND_VERIFY_SUCCESS: 'FETCH_AND_VERIFY_SUCCESS',
    FETCH_AND_VERIFY_FAIL: 'FETCH_AND_VERIFY_FAIL',


    AADHAR_REJECT_REQUEST: 'AADHAR_REJECT_REQUEST',
    AADHAR_REJECT_SUCCESS: 'AADHAR_REJECT_SUCCESS',
    AADHAR_REJECT_FAIL: 'AADHAR_REJECT_FAIL',

    PUT_GST_UPDATE_REQUEST: 'PUT_GST_UPDATE_REQUEST',
    PUT_GST_UPDATE_SUCCESS: 'PUT_GST_UPDATE_SUCCESS',
    PUT_GST_UPDATE_FAILURE: 'PUT_GST_UPDATE_FAILURE',


    SET_USER_VERIFY_REQUEST: 'SET_USER_VERIFY_REQUEST',
    SET_USER_VERIFY_SUCCESS: 'SET_USER_VERIFY_SUCCESS',
    SET_USER_VERIFY_FAIL: 'SET_USER_VERIFY_FAIL',

    SET_COMPANY_VERIFY_REQUEST: 'SET_COMPANY_VERIFY_REQUEST',
    SET_COMPANY_VERIFY_SUCCESS: 'SET_COMPANY_VERIFY_SUCCESS',
    SET_COMPANY_VERIFY_FAIL: 'SET_COMPANY_VERIFY_FAIL',
}
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { InputText, InputCheckBox } from "../FormElements";
export default class CheckListItems extends Component {
  render() {
    const { item, idx , removeOption, handleChange, isShowDelete} = this.props;
    return (
      <Row>

        <Col md="1">
          <label>
            <b> {idx + 1} </b>
          </label>
        </Col>

        <Col md="5">
          <InputText
            title={false}
            placeholder="Title"
            name="title"
            value={item.title}
            onChange={e => handleChange(e, idx)}
            maxLength="250"
          />
        </Col>

        <Col md="2">
          <InputCheckBox 
            title={false}
            checked={item.value}
            onChange={(e) => {
              handleChange({ name: "value", value : e.target.checked}, idx)
            }}
            />
        </Col>

        <Col md="4">
          <Button disabled={!isShowDelete} color={"danger"} onClick={() => removeOption()}>
            <i className={"fa fa-trash"}></i>
          </Button> 
        </Col>

      </Row>
    );
  }
}

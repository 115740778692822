import React, { Component } from 'react'
import { Translate } from "react-localize-redux"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from 'reactstrap'
import swal from "sweetalert"
import placeholder from '../../../../assets/img/individual-placeholder.png'
import { TableLoader } from '../../../../components/Loaders'
import { getStatusIcon } from '../../../../helpers'
import { verificationActions } from '../../../actions'
import { documentStatus, verificationConstants } from '../../../constants'
import { Documents } from './Comman/Documents'
import { VerifyDoucment } from './Comman/VerifyDoucment'

class IndividualDetail extends Component {
    constructor(props) {
        super(props)

        this.getData = this.getData.bind(this)
        this.goBack = this.goBack.bind(this);
        this.addToJunk = this.addToJunk.bind(this);
        this.setUserVerify = this.setUserVerify.bind(this)
    }
    goBack() {
        this.props.history.goBack();
    }
    addToJunk() {
        const { profile } = this.props;

        swal({
            title: "Are you sure ?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {

                this.props.dispatch(
                    verificationActions.addToJunkUser({
                        individual_reputeid: profile.formated_repute_id,
                        is_junk: profile.is_junk === 1 ? 0 : 1,
                    }, {
                        success: () => {
                            this.getData()
                        }
                    })
                );

            }
        });

    }


    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: verificationConstants.GET_INDIVIDUAL_DETAIL_RESET });
        this.getData();
    }

    getData() {
        const { dispatch, match: { params } } = this.props;
        dispatch(verificationActions.getIndividualDetail({ reputeId: params.reputeId }));
    }

    setUserVerify() {
        let _this = this
        swal({
            title: "Are you sure for complete user verify?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {
                const { dispatch, profile: { individual_repute_id } } = this.props;
                dispatch(verificationActions.setUserVerify({ user_repute_id: individual_repute_id
                }, {
                    success: () => {
                        setTimeout(() => {
                            _this.getData()
                        }, 2000)
                    }
                }));
            }
        });
    }

    render() {
        const {
            profile,
            loading
        } = this.props;
        return (
            <div className="">

                <Translate>
                    {({ translate }) =>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardHeader>
                                        <i className="fa fa-user-circle"></i><strong>Individual Detail </strong>
                                        <Button className='ml-5 pull-right' onClick={this.goBack} >
                                            Go  Back
                                        </Button>
                                        <Button className='pull-right mx-4 bg-success' onClick={this.setUserVerify}>
                                            User Verify
                                        </Button>
                                        {/* <Button className={`pull-right mx-2  bg-danger mr-5`} onClick={this.addToJunk} >
                                            {profile && profile.is_junk === 0 ? `Add To Junk` : `Remove from Junk`}
                                        </Button> */}
                                    </CardHeader>
                                    <CardBody>

                                        {loading ? <TableLoader /> : <React.Fragment>
                                            {profile ?
                                                <Row>
                                                    <Col lg="6">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <b> {translate("title.Photo")} </b>
                                                            </div>

                                                            <div className="col-md-8 mb-3">
                                                                <div className="thumbnail pull-left">
                                                                    <img src={profile.profile_photo || placeholder} style={{ "maxWidth": "120px" }} alt={profile.profile_photo} className="img-thumbnail" />
                                                                </div>
                                                                <div className="pull-left"> &nbsp;  &nbsp; {getStatusIcon(profile.photo_status, profile.rejected_reasons.PHOTO)} </div>
                                                                <VerifyDoucment status={profile.photo_status} repute_id={profile.formated_repute_id} document_type={"PHOTO"} is_cheque_verified={profile.is_cheque_verified} />
                                                            </div>


                                                            <div className="col-md-4">
                                                                <b>{translate("Repute ID")}</b>

                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                {profile.formated_repute_id}
                                                            </div>

                                                            <div className="col-md-4">
                                                                <b>{translate("title.Full Name")}</b>

                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                {profile.aadhaar_name} &nbsp;  &nbsp;
                                                                {getStatusIcon(profile.name_status, profile.rejected_reasons.NAME_VERIFICATION)} <VerifyDoucment status={documentStatus.ACCEPTED === profile.name_status ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"NAME_VERIFICATION"} is_cheque_verified={profile.is_cheque_verified} />
                                                            </div>


                                                            {/* <div className="col-md-4">
                                                                <b> {translate("Aadhaar")} </b>

                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                {profile.aadhaar_number} &nbsp;  &nbsp; {getStatusIcon(profile.aadhaar_status, profile.rejected_reasons.AADHAR_CARD)} <VerifyDoucment status={profile.aadhaar_status} repute_id={profile.formated_repute_id} document_type={"AADHAR_CARD"} is_cheque_verified={profile.is_cheque_verified} />

                                                            </div> */}

                                                            {/* <div className="col-md-4">
                                                                <b>{translate("PAN")}</b>
                                                            </div>
                                                            <div className="col-md-8   mb-3">
                                                                {profile.pan_number} &nbsp; &nbsp;

                                                                {getStatusIcon(profile.pan_status, profile.rejected_reasons.PAN_CARD)}  <VerifyDoucment status={profile.pan_status} repute_id={profile.formated_repute_id} document_type={"PAN_CARD"} is_cheque_verified={profile.is_cheque_verified} />
                                                            </div> */}
                                                            <div className="col-md-4">
                                                                <b>{translate("title.Mobile Number")}</b>
                                                            </div>
                                                            <div className="col-md-8  mb-3">

                                                                <div>
                                                                    {profile.mobile_number} &nbsp; &nbsp; <span className={"text-success"}><i className="fa fa-check-circle" id="mobileVerifiedTooltip"></i></span>
                                                                    <UncontrolledTooltip placement="top" target="mobileVerifiedTooltip">
                                                                        Verified
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div>




                                                            <div className="col-md-4">
                                                                <b>{translate("title.Email")}</b>

                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                {profile.email ?
                                                                    <div>
                                                                        <div>
                                                                            {profile.email} &nbsp; &nbsp;
                                                                            <span className={"text-success"}><i className="fa fa-check-circle" id="EmailVerifiedTooltip"></i></span>
                                                                            <UncontrolledTooltip placement="top" target="EmailVerifiedTooltip">
                                                                                Verified
                                                                            </UncontrolledTooltip>
                                                                        </div>
                                                                    </div>
                                                                    : <div>
                                                                        Not Available
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="col-md-4">
                                                                <b>{translate("Aadhaar No.")}</b>
                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                {profile.aadhaar_number ?
                                                                    <div>

                                                                        <div>
                                                                            {profile.aadhaar_number} &nbsp; &nbsp;
                                                                            <span className={"text-success"}><i className="fa fa-check-circle" id="AadharVerifiedTooltip"></i></span>
                                                                            <UncontrolledTooltip placement="top" target="AadharVerifiedTooltip">
                                                                                Verified
                                                                            </UncontrolledTooltip>
                                                                        </div>
                                                                    </div>
                                                                    : <div>
                                                                        Not Available
                                                                    </div>
                                                                }

                                                            </div>


                                                            {/* <div className="col-md-4">
                                                                <b> {translate("title.Address")}</b>
                                                            </div>
                                                            <div className="col-md-8  mb-3">
                                                                <div className="pull-left">
                                                                    {profile.address.line1}, <br />
                                                                    {profile.address.line2 ? profile.address.line2 + "," : ""}
                                                                    {profile.address.line2 ? <br /> : ""}
                                                                    {profile.address.city}, {profile.address.taluka},  <br />
                                                                    {profile.address.pincode}, {profile.address.district}, {profile.address.state}
                                                                </div>

                                                                {getStatusIcon(profile.address_status, profile.rejected_reasons.ADDRESS)} <VerifyDoucment status={documentStatus.ACCEPTED === profile.address_status ? documentStatus.ACCEPTED : documentStatus.INREVIEW} repute_id={profile.formated_repute_id} document_type={"ADDRESS"} is_cheque_verified={profile.is_cheque_verified} />
                                                            </div> */}
                                                            <div className="col-md-4">
                                                                <b>Registered Date and Time</b>
                                                            </div>
                                                            <div className="col-md-8   mb-3">
                                                                {profile.created_at}
                                                            </div>

                                                            {
                                                                profile && profile.verified_at ?
                                                                    <React.Fragment>
                                                                        <div className="col-md-4">
                                                                            <b>Verified At</b>
                                                                        </div>
                                                                        <div className="col-md-8   mb-3">
                                                                            {profile.verified_at}
                                                                        </div>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <h4>Companies</h4>

                                                        <Row>
                                                            {profile && profile.companies ?
                                                                profile.companies.map((each) => {
                                                                    return <Col md="6" >
                                                                        <div style={{ 'borderRadius': '10px', "border": '1px solid #c2c2c2', padding: 20 }}>
                                                                            <h5>
                                                                                {each.name}

                                                                            </h5>

                                                                            <small>({each.repute_id_formatted})</small>
                                                                            <Link to={'/verification/company/' + each.repute_id_formatted} className='btn btn-primary btn-block mt-4'>
                                                                                View Profile
                                                                            </Link>
                                                                        </div>
                                                                    </Col>
                                                                }) : false}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                : ""}
                                        </React.Fragment>}
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    }
                </Translate>

                <Documents profile={profile} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { profile, loading } = state.individualsDetailData;
    return {
        profile,
        loading
    }
}

const connectedIndividual = connect(mapStateToProps)(IndividualDetail)
export { connectedIndividual as IndividualDetail }


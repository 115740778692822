import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingsActions } from "../../actions";
import {  CardBody, Card, CardHeader, Button } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import { UpdateFixedSetting } from './UpdateFixedSettings'

class FixedSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getSettings = this.getSettings.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
       this.getSettings()
    }

    getSettings() {
        const { dispatch } = this.props;
        dispatch(settingsActions.getSettings());
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <UpdateFixedSetting editData={row} getSettings={this.getSettings} />
        </React.Fragment>
    }
    render() {
        const { data, loading } = this.props
        const columns = [
            {
                text: 'Key',
                dataField: 'key',
                sort: true
            },
            {
                text: 'Value',
                dataField: 'value',
                sort: true
            },
            {
                text: 'Action',
                dataField:'action',
                formatter: this.renderAction

            }]
        return (
            <Card>
                <CardHeader>
                    <i className="fa fa-cog"></i><strong>Fixed Setting</strong>
                </CardHeader>
                <CardBody>
                    {loading  ? <TableLoader /> :
                        <div className="">
                        {data && data.length > 0 ? (
                          <DataTable
                            keyField="id"
                            data={data}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No data found"
                          />
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getSettings    
    return {
        loading,
        data ,
    }
}

const connectedFixedSettings = connect(mapStateToProps)(FixedSettings)
export { connectedFixedSettings as FixedSettings }

import React, { useEffect } from "react";
import { landingPageActions } from "../../actions";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { APP_URL } from "../../../api-config";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import classnames from "classnames";
import CsvDownloader from "react-csv-downloader";
import moment from "moment";
import config from 'react-global-configuration';

const TABS = {
  List_Landing_Page: "List_Landing_Page",
  List_Leades: "List_Leades",
};

function LandingPageList(props) {
  const [activeTab, setActiveTab] = useState(TABS.List_Landing_Page);
  const [excelData, setExcelData] = useState({
    columns: "",
    is_disable: true,
    csv_data: [],
  });

  useEffect(() => {
    const { dispatch } = props;
    dispatch(landingPageActions.getLandingPageData());
    dispatch(landingPageActions.getLandingPageUserData());
  }, []);

  const toggle = (tab) => {
    setActiveTab(tab);
  };
  const handleShowFunc = (eachData) => {
    const { id } = eachData;
    const newURL = `${APP_URL}/landing_page/${id}`;
    window.open(newURL, "_blank");
  };

  const naviagateAddTemplate = () => {
    const { history } = props;
    history.push("/landing-page/add");
  };

  const handleEdit = (eachData) => {
    const { history } = props;
    const { id } = eachData;
    history.push(`landing-page/${id}`);
  };

  const { data, userData } = props;

  return (
    <Card>
      <CardHeader>
        <i className="fas fa-ad	"></i>
        <strong>Landing Page</strong>
        <Button
          color="primary"
          className="pull-right btn-sm"
          onClick={naviagateAddTemplate}
        >
          Add New Template
        </Button>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.List_Landing_Page,
              })}
              onClick={() => {
                toggle(TABS.List_Landing_Page);
              }}
            >
              <span style={{ fontWeight: "bold" }}>Landing Pages</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.List_Leades,
              })}
              onClick={() => {
                toggle(TABS.List_Leades);
              }}
            >
              <span style={{ fontWeight: "bold" }}>Leads</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.List_Landing_Page}>
            <div className="table-responsive-sm  animated fadeIn">
              <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                <thead className="thead-light">
                  <tr>
                    <th>SR. NO.</th>
                    <th>Title</th>
                    <th>Preview</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length ? (
                    data.map(function (element, index) {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{element.title}</td>
                          <td>
                            <Button
                              color="primary"
                              onClick={() => handleShowFunc(element)}
                            >
                              Show
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              className="mx-2"
                              onClick={() => handleEdit(element)}
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th colSpan="9" className="text-center">
                        No Data Found
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </TabPane>

          <TabPane tabId={TABS.List_Leades}>
            <div className="table-responsive-sm  animated fadeIn">
            <a className='btn btn-info pull-right mb-2' target='_blank' href={`${config.get('apiUrl')}/lead_data_export`}> Export</a>
              <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                <thead className="thead-light">
                  <tr>
                    <th>SR. NO.</th>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Templete Id</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {userData && userData.length ? (
                    userData.map(function (element, index) {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{element.name}</td>
                          <td>{element.mobile}</td>
                          <td>{element.template_id}</td>
                          <td>
                            {element.created_at
                              ? moment(element.created_at).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : "-"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th colSpan="9" className="text-center">
                        No Data Found
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

function mapStateToProps(state) {
  const { loading, data } = state.getLandingPageData;
  const { userData } = state.getLandingPageUserData;

  return {
    loading,
    data,
    userData: userData,
  };
}

const connectedLandingPageList = withRouter(
  connect(mapStateToProps)(LandingPageList)
);
export { connectedLandingPageList as LandingPageList };

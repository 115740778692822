import React, { Component } from "react";
import { accountAction } from "./../../actions/account.action";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";
import FormHelpText from '../../../components/Messages/FormHelpText';
import { currencyFormat } from "../../../helpers";

export default class UpdateDepositStatus extends Component {
  constructor(props) {
    super(props);
    let days = new Date().getDate() - 1;
    this.state = {
      modalOpan: false,
      data: {
        paymentType: 1,
        account_holder_name: "",
        bank_name: "",
        cheque_number: "",
        account_number: "",
        cheque_amount: "",
        cheque_verification_status: "0",
        description: "",
        amount: "",
        payment_reference: "",
      },
      errors: {}
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.proceed = this.proceed.bind(this);
    this.isValidData = this.isValidData.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan });
    this.reset();
  }

  reset() {
    this.setState({
      data: {
        paymentType: 1,
        account_holder_name: "",
        bank_name: "",
        cheque_number: "",
        account_number: "",
        cheque_amount: "",
        cheque_verification_status: "1",
        description: "",
      },
    })
  }

  setPaymentType(event) {
    const { data } = this.state;
    data["paymentType"] = parseInt(event.target.value, 10);
    this.setState({ data });
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    let { data } = this.state;
    let { element } = this.props;
    let val = value;

    if (name === "paymentType") {
      val = Number(value);
    }
    if (name === "bank_name" || name === "account_holder_name") {
      let regex_allowed = /^(?![\s-])[a-zA-Z ]*$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    if (name === "account_number" || name === "cheque_number") {
      let regex_allowed = /^[0-9]+$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    if (name == 'amount' && value != "") {
      if (parseInt(value, 10) > element.pending_amount) {
        return false;
      }
    }
    if (name === "cheque_amount" || name === "amount") {
      let regex_allowed = /^\d+(\.\d{0,2})?$/;
      if (value.length && regex_allowed.test(value) === false) {
        return false;
      }
    }
    data[name] = val;
    this.setState({ data });
  }

  isValidData() {
    const { data, errors } = this.state
    let error = false

    if (data.paymentType == 1) {
      if (data.payment_reference === "") {
        errors.payment_reference = "this field is required";
        error = true;
      }

      if (data.amount === "") {
        errors.amount = "this field is required";
        error = true;
      }
    }

    if (data.paymentType === 2) {
      if (data.account_holder_name === "") {
        errors.account_holder_name = "this field is required";
        error = true;
      } else if (data.account_holder_name.length > 30) {
        errors.account_holder_name =
          "bank name max length should be 30 characters";
        error = true;
      }

      if (data.bank_name === "") {
        errors.bank_name = "this field is required";
        error = true;
      } else if (data.bank_name.length > 30) {
        errors.bank_name = "bank name max length should be 30 characters";
        error = true;
      }

      if (data.cheque_number === "") {
        errors.cheque_number = "this field is required";
        error = true;
      } else if (data.cheque_number.length !== 6) {
        errors.cheque_number = "cheque number length should be 6 digits";
        error = true;
      }
      if (data.amount === "") {
        errors.amount = "this field is requied";
        error = true;
      }
      if (data.account_number === "") {
        errors.account_number = "this field is required";
        error = true;
      } else if (
        data.account_number.length < 9 ||
        data.account_number.length > 20
      ) {
        errors.account_number =
          "account number length should be 9 to 20 digits";
        error = true;
      }
      if (data.cheque_verification_status === "0") {
        errors.cheque_verification_status = "this field is required";
        error = true;
      }
    }
    this.setState({ errors })
    return error
  }
  proceed() {
    const { data } = this.state;
    const { dispatch, element, getData } = this.props;

    if (!this.isValidData()) {
      swal({
        title: "Are you sure ?",
        text: "Do you want to update status ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        buttons: ["Cancel", "Yes, I am sure!"],
      }).then((confirm) => {
        if (confirm) {
          let dataTosend = {
            user_id: element.user_id,
            payment_type: data.paymentType,
            description: data.description,
          }
          if (data.paymentType === 1) {
            dataTosend.payment_reference = data.payment_reference
            dataTosend.amount = data.amount
          }
          if (data.paymentType === 2) {
            dataTosend.bank_name = data.bank_name
            dataTosend.account_holder_name = data.account_holder_name
            dataTosend.account_number = data.account_number
            dataTosend.cheque_number = data.cheque_number
            dataTosend.amount = data.amount
            dataTosend.cheque_status = data.cheque_verification_status
          }
          console.log('dataTosend', dataTosend)
          dispatch(
            accountAction.updateDepositReceipt(dataTosend, {
              success: () => {
                this.showModal()
                getData()
              }
            })
          );

        }
      });
    }
  }
  componentDidMount() {
    const { data } = this.state
    const { default_balance } = this.props
    data.balance = default_balance ? default_balance : ''
    this.setState({ data })
  }
  render() {
    const { modalOpan, data, errors } = this.state;
    const { element } = this.props;
    const mendetoryFields = {
      color: "red",
      fontSize: "14px",
      marginLeft: "2px",
    };
    let _this = this;
    return (
      <div>
        <Tooltip className=" pull-left m-0 p-0 ml-2" interactive arrow={true} title="Update Payment Status">

          <Button color="success" class="pull-left"  onClick={_this.showModal} >
            <i class="fas fa-money"></i>
          </Button>
        </Tooltip>
        <Modal className="modal-success" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Update Payment Status<br />{" "}
            {/* <small>Add remaining negotiated offer</small> */}
          </ModalHeader>
          <ModalBody>
            <div className={" form-group mb-3"}>
              <label>Payment Type : </label>
              <div onChange={this.setPaymentType.bind(this)}>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadio1"
                    name="paymentType"
                    value="1"
                    class="custom-control-input"
                    checked={data.paymentType === 1}
                  />
                  <label
                    class="custom-control-label"
                    for="customRadio1"
                  >
                    UPI
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadio2"
                    name="paymentType"
                    value="2"
                    class="custom-control-input"
                    checked={data.paymentType === 2}
                  />
                  <label
                    class="custom-control-label"
                    for="customRadio2"
                  >
                    Cheque
                  </label>
                </div>
              </div>
            </div>
            {data.paymentType === 1 ? (
              <React.Fragment>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-file"></i>{" "}
                        <i style={mendetoryFields}>*</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="payment_reference"
                      value={data.payment_reference}
                      maxLength="20"
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      placeholder="Payment Reference"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <div className="row ">
                    <div className="col-5 mt-3 text-right">
                      <b> Plan Deposit Amount:</b>
                    </div>
                    <div className="col-6 mt-3">
                      ₹ {currencyFormat(element.pending_amount)}
                    </div>

                    <div className="col-5 mt-4  text-right">
                      <b> Received Amount:</b>
                    </div>

                    <div className="col-6 mt-3">
                      <InputGroup>
                        <InputGroupAddon
                          addonType="prepend"
                          id="ico-pan"
                        >
                          <InputGroupText>
                            <i className="fa fa-rupee"></i>{" "}
                            <i style={mendetoryFields}>*</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="amount"
                          value={data.amount}
                          maxLength={element.pending_amount.length}
                          onChange={this.handleChange}
                          className="form-control"
                          type="text"
                          placeholder="Amount"
                        />
                      </InputGroup>
                      {errors.amount && (
                        <FormFeedback>
                          {errors.amount}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="col-5 mt-3  text-right">
                      <b> Remaining Amount:</b>
                    </div>
                    <div className="col-6 mt-3">
                      ₹ {data.amount ? currencyFormat(element.pending_amount - data.amount) : currencyFormat(element.pending_amount)}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-list"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <select className="form-control" readonly name="cheque_verification_status" onChange={this.handleChange} value={data.cheque_verification_status} >
                      <option value="1"> Received </option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </React.Fragment>) : false}
            {data.paymentType === 2 ? (
              <React.Fragment>
                <FormHelpText
                  message={
                    "Cheque in the name of : BSOSPL REPUTEINFO"
                  }
                />
                <div className={" form-group mb-3"}>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-user"></i>{" "}
                        <i style={mendetoryFields}>*</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="account_holder_name"
                      maxLength="30"
                      value={data.account_holder_name}
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      placeholder="Account Holder Name"
                    />
                  </InputGroup>
                  {errors.account_holder_name && (
                    <FormFeedback>
                      {errors.account_holder_name}
                    </FormFeedback>
                  )}
                </div>
                <div className={" form-group mb-3"}>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-bank"></i>{" "}
                        <i style={mendetoryFields}>*</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="bank_name"
                      maxLength="30"
                      value={data.bank_name}
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      placeholder="Bank Name"
                    />
                  </InputGroup>
                  {errors.bank_name && (
                    <FormFeedback>
                      {errors.bank_name}
                    </FormFeedback>
                  )}
                </div>
                <div className={" form-group mb-3"}>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fas fa-money-check"></i>{" "}
                        <i style={mendetoryFields}>*</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="cheque_number"
                      maxLength="6"
                      value={data.cheque_number}
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      placeholder="Cheque Number"
                    />
                  </InputGroup>
                  {errors.cheque_number && (
                    <FormFeedback>
                      {errors.cheque_number}
                    </FormFeedback>
                  )}
                </div>
                <div className={" form-group mb-3"}>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-building-o"></i>{" "}
                        <i style={mendetoryFields}>*</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="account_number"
                      value={data.account_number}
                      maxLength="20"
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      placeholder="Account Number"
                    />
                  </InputGroup>
                  {errors.account_number && (
                    <FormFeedback>
                      {errors.account_number}
                    </FormFeedback>
                  )}
                </div>
                <FormGroup>
                  <div className="row ">
                    <div className="col-5 mt-3 text-right">
                      <b> Plan Deposit Amount:</b>
                    </div>
                    <div className="col-6 mt-3">
                      ₹ {currencyFormat(element.pending_amount)}
                    </div>

                    <div className="col-5 mt-4  text-right">
                      <b> Received Amount:</b>
                    </div>

                    <div className="col-6 mt-3">
                      <InputGroup>
                        <InputGroupAddon
                          addonType="prepend"
                          id="ico-pan"
                        >
                          <InputGroupText>
                            <i className="fa fa-rupee"></i>{" "}
                            <i style={mendetoryFields}>*</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="amount"
                          value={data.amount}
                          maxLength={element.pending_amount.length}
                          onChange={this.handleChange}
                          className="form-control"
                          type="text"
                          placeholder="Amount"
                        />
                      </InputGroup>
                      {errors.amount && (
                        <FormFeedback>
                          {errors.amount}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="col-5 mt-3  text-right">
                      <b> Remaining Amount:</b>
                    </div>
                    <div className="col-6 mt-3">
                      ₹ {data.amount ? currencyFormat(element.pending_amount - data.amount) : currencyFormat(element.pending_amount)}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      id="ico-pan"
                    >
                      <InputGroupText>
                        <i className="fa fa-list"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <select className="form-control" name="cheque_verification_status" onChange={this.handleChange} value={data.cheque_verification_status} >
                      {/* <option value="0">--- Select Status ---</option> */}
                      <option value="1"> Received </option>
                      {/* <option value="2"> Bounced </option>
                      <option value="3"> Overwriting </option>
                      <option value="4"> Stop Payment </option>
                      <option value="5"> Signature Mismatch </option> */}
                    </select>
                    {errors.cheque_verification_status && (
                      <FormFeedback>
                        {errors.cheque_verification_status}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </FormGroup>

              </React.Fragment>
            ) : (
              ""
            )}
            <FormGroup>
              <InputGroup>
                <InputGroupAddon
                  addonType="prepend"
                  id="ico-pan"
                >
                  <InputGroupText>
                    <i className="fa fa-list"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="description"
                  value={data.description}
                  maxLength="200"
                  onChange={this.handleChange}
                  className="form-control"
                  type="textarea"
                  placeholder="Description/Notes.."
                />
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
              Proceed
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import { notify } from 'reapop'
import { SupportConstants } from '../constants'
import { supportService } from '../services'

export const supportActions = {
    searchCustomer,
    verifySupportPIN,
    createTicket,
    getTicketDetails,
    saveTicketMessage,
    getTicketList,
    changeTicketStatus
}

function searchCustomer(data) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.searchCustomer(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.CUSTOMER_SUPPORT_SEARCH_REQUEST } }
    function success(result) { return { type: SupportConstants.CUSTOMER_SUPPORT_SEARCH_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.CUSTOMER_SUPPORT_SEARCH_FAILURE, error } }
}


function createTicket(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.createTicket(data)
            .then(
                result => {
                    dispatch(success(result));
                    ref.afterSave();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.CREATE_SUPPORT_TICKET_REQUEST } }
    function success(result) { return { type: SupportConstants.CREATE_SUPPORT_TICKET_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.CREATE_SUPPORT_TICKET_FAILURE, error } }
}


function verifySupportPIN(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.verifySupportPIN(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.VERIFY_SUPPORT_PIN_REQUEST } }
    function success(result) { return { type: SupportConstants.VERIFY_SUPPORT_PIN_SUCCESS, params:data , result } }
    function failure(error) { return { type: SupportConstants.VERIFY_SUPPORT_PIN_FAILURE, error } }
}


function getTicketDetails(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.getTicketDetails(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.GET_TICKET_DETAILS_REQUEST } }
    function success(result) { return { type: SupportConstants.GET_TICKET_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.GET_TICKET_DETAILS_FAILURE, error } }
}

function saveTicketMessage(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.saveTicketMessage(data)
            .then(
                result => {
                    dispatch(success(result));
                    ref.afterSave();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.SAVE_TICKET_MESSAGE_FAILURE } }
    function success(result) { return { type: SupportConstants.SAVE_TICKET_MESSAGE_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.SAVE_TICKET_MESSAGE_FAILURE, error } }
}

function changeTicketStatus(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.changeTicketStatus(data)
            .then(
                result => {
                    dispatch(success(result));
                    ref.afterSave();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.CHANGE_TICKET_STATUS_FAILURE } }
    function success(result) { return { type: SupportConstants.CHANGE_TICKET_STATUS_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.CHANGE_TICKET_STATUS_FAILURE, error } }
}

function getTicketList(data) {
    return dispatch => {
        dispatch(request({ data }));

        supportService.getTicketList(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SupportConstants.TICKETS_LIST_REQUEST } }
    function success(result) { return { type: SupportConstants.TICKETS_LIST_SUCCESS, result } }
    function failure(error) { return { type: SupportConstants.TICKETS_LIST_FAILURE, error } }
}

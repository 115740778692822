export const settingsConstants = {
    EDIT_SETTINGS_REQUEST: 'EDIT_SETTINGS_REQUEST',
    EDIT_SETTINGS_SUCCESS: 'EDIT_SETTINGS_SUCCESS',
    EDIT_SETTINGS_FAILURE: 'EDIT_SETTINGS_FAILURE',

    GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
    GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

    DELETE_SETTINGS_REQUEST: 'DELETE_SETTINGS_REQUEST',
    DELETE_SETTINGS_SUCCESS: 'DELETE_SETTINGS_SUCCESS',
    DELETE_SETTINGS_FAILURE: 'DELETE_SETTINGS_FAILURE',

    
}
import { axiosAjaxCall } from "./common.service";

export const ExpensesService = {
    getExpenses,
}


function getExpenses(data) {    
    return axiosAjaxCall("getExpenses", data);
}


import React, { Component } from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { show } from "redux-modal";
import swal from "sweetalert";
import { DataTable } from "../../../components/Tables/DataTable";
import { communicationTemplateActions } from "../../actions";
class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: ''
    };
    this.getCommunicationTemplate = this.getCommunicationTemplate.bind(this);
    this.renderAction = this.renderAction.bind(this);
  }

  // componentDidMount() {
  //   this.getCommunicationTemplate();
  // }

  getCommunicationTemplate() {
    const { getCommunicationTemplate } = this.props;
    if (typeof getCommunicationTemplate === "function") {
      getCommunicationTemplate();
    }
    // dispatch(communicationTemplateActions.getCommunicationTemplate());
  }
  publishCommunicationTemplate(data) {
    const { dispatch } = this.props;
    dispatch(
      communicationTemplateActions.publishCommunicationTemplate(data, this)
    );
  }

  renderAction(cell, row, rowIndex, formateExtraData) {
    const { dispatch, publishing } = this.props;
    const confirmPublish = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to publish from draft ? It will visible on reputeinfo.com",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let dataToSent = {
            // title: row.title,
            // email_subject: row.email_subject,
            // sms_text: row.sms_text,
            type: row.type,
            // sms_flow_id: row.sms_flow_id,
            // notification_title: row.notification_title,
            // notification_text: row.notification_text,
          };
          this.publishCommunicationTemplate(dataToSent);
        }
      });
    };
    const testSMS = () => {
      dispatch(
        show("bootstrap", {
          ModelSize: "md",
          ModelHeaderText: "Test SMS",
          ModelBodyText: row.sms_text,
          isFooter: false,
        })
      );
    };
    return (
      <React.Fragment>
        {parseInt(row.sub_version, 10) === 0 ? (
          <Link
            className="btn btn-success btn-sm"
            to={
              "/communication_template/update_communication_template/" + row.id
            }
          >
            {" "}
            New draft{" "}
          </Link>
        ) : (
          <Link
            className="btn btn-primary btn-sm"
            to={
              "/communication_template/update_communication_template/" + row.id
            }
          >
            {" "}
            Edit{" "}
          </Link>
        )}{" "}
        {parseInt(row.sub_version, 10) === 0 ? (
          ""
        ) : (
          <Button
            className="btn btn-danger btn-sm"
            onClick={(e) => confirmPublish()}
            to={
              "/communication_template/update_communication_template/" + row.id
            }
          >
            {" "}
            {publishing ? "Please Wait..." : "Publish to Reputeinfo"}{" "}
          </Button>
        )}{" "}
        &nbsp;
        <Button className="btn btn-warning btn-sm" onClick={(e) => testSMS()}>
          {" "}
          Test{" "}
        </Button>
      </React.Fragment>
    );
  }

  renderVersion(cell, row, rowIndex, formateExtraData) {
    return row.version + "." + row.sub_version;
  }
  wsw;

  renderSMSText(cell, row, rowIndex, formateExtraData) {
    row.short_description = row.sms_text;
    row.short_description =
      row.short_description.length > 80
        ? row.short_description.slice(0, 80) + "....."
        : row.short_description;
    return row.short_description;
  }
  renderEmailText(cell, row, rowIndex, formateExtraData) {
    row.short_description = row.email_text;
    row.short_description =
      row.short_description.length > 80
        ? row.short_description.slice(0, 80) + "....."
        : row.short_description;
    return row.short_description;
  }
  renderNotificationText(cell, row, rowIndex, formateExtraData) {
    row.short_description = row.notification_text;
    row.short_description =
      row.short_description.length > 80
        ? row.short_description.slice(0, 80) + "....."
        : row.short_description;
    return row.short_description;
  }

  render() {
    const data = this.props.templatedata
    console.log("notificationdata", data);
    const columns = [
      {
        text: "type",
        dataField: "type",
        sort: true,
        filter: textFilter({
          // placeholder: "type",
        }),
      },
      {
        text: "Title",
        dataField: "title",
        sort: true,
        filter: textFilter({
          // placeholder: "title",
          // default: "accept"
        }),
      },
      {
        text: "Mail Subject",
        dataField: "email_subject",
        sort: true,
      },
      {
        text: "Sms Text",
        sort: true,
        dataField: "sms_text",
        sort: true,
        formatter: this.renderSMSText,
      },
      {
        text: "Notification Title",
        sort: true,
        dataField: "notification_title",
        sort: true,
        // formatter: this.renderSMSText,
      },
      // {
      //   text: 'Usage',
      //   dataField: 'usage',
      //   sort: true,
      //   filter: textFilter({
      //     placeholder: 'Usage'
      //   }),
      // },     
      // {
      //   text: "Last Published",
      //   dataField: "last_published",
      //   sort: true,
      // },
      // {
      //   text: "Last Saved on",
      //   dataField: "updated_at",
      // },
      {
        text: "Version",
        dataField: "varsion",
        formatter: this.renderVersion,
      },
      {
        text: "Action",
        dataField: "action",
        formatter: this.renderAction,
      },
    ];

    return (
      <DataTable
        className={'!table-xs'}
        keyField="id"
        data={data || []}
        columns={columns}
        filter={filterFactory()}
        noDataIndication={"No data found"}
      />
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getCommunicationTemplate;

  console.log("data", data);
  const { user } = state.authentication;
  return {
    loading,
    data,
    // count: data ? data.total : 0,
    authUser: user,
  };
}

const connectedTemplate = connect(mapStateToProps)(
  Template
);
export { connectedTemplate as Template };

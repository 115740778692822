import { authentication, forgotPassword, registration, savePassword } from './auth.reducer'
import { websocket } from './websocket.reducer'

import { dashboard, getSalseStatusOverviewData, dashboardLeadVSRegister, dashboardBadDebt, dashboardUserAnalysis } from './dashboard.reducer'
import {
  addEmail, deleteEmail, editEmail, getEmails, publishEmail, sendEmail
} from './email.reducer'
import {
  addFAQ, deleteFAQ, getFAQ,
  saveFAQ
} from './faq.reducer'
import {
  addNotification, deleteNotification, editNotification, getNotifications, publishNotifications
} from './notification.reducer'
import {
  addSMS, deleteSMS, editSMS, getSMS
} from './sms.reducer'

import {
  getCommunicationTemplate,editCommunicationTemplate
} from './communicationTemplate.reducer'

import {
  addUser, addUserType, Departments,
  getCommissionAmounts,
  getCommissionSlab, getUsers, getUserTypes,
  JobTitles
} from './userManagement.reducer'
import {
  companiesData,
  companyDetail,
  gstDetail, individualsData,
  individualsDetailData, updateCompany, verifyIndividualCompany
} from './verification.reducer'


import {
  getLegal, publishLegal, saveLegal
} from './legal.reducer'

import {
  changeTicketStatus, createTicket, customerSearchData, getTicketDetails, getTicketList, saveTicketMessage, verifySupportPIN
} from './support.reducer'

import { addCompanySectorDetail, deleteCompanySectorDetail, getCompanySectorDetail, updateCompanySectorDetail } from './companySector.reducer'
import { assignCustomerDetail, deleteCustomerDetail, getCustomerDetail, updateCustomerDetail, updateCustomerDetailLanguage, updateCustomerDetilEmail, uploadCsvData } from './customerDetail.reducer'
import { getSubscription, updateSubscription } from './subscription.reducer'

import { addDemoAppData, getCustomerList, getCustomerStatusData, updateCustomerSalesStatus } from './sales.reducer'
import { getSMSPackage, updateSMSPackage } from './smsPackage.reducer'

import { deleteCompanyProductTags, getAccountData, getChequeDetail, getCommissionData, getCompanyProductTags, getDepositReceipt, getProductTagsAlphabatically, getUserSalaryData, saveCompanyProductTags, updateChequeDetail, updateDepositReceipt } from './account.reducer'
import { getAdminContentConfigtData, updateAdminContentConfigData } from './adminContentConfig.reducer'
import { getCronLogsData } from './cronLogs.reducer'
import { getExceptionLogs } from './exceptionLosgs.reducer'
import { getSearchLogsData } from './searchLogs.reducer'
import { getSmsLogsData } from './smsLogs.reducer'
import { getUserLoginLogs } from './userLoginLogs.reducer'
// Packages
import { reducer as notificationsReducer } from 'reapop'
import { reducer as modal } from 'redux-modal'
import {
  businessAccounts, getDataFromReputeId, sectors, states,
  getBlockBusiness
} from './businessAccounts.reducer'
import { businessAccountTemplate } from './businessAccountTemplate.reducer'
import { communicationMasters } from './communicationMaster.reducer'
import { communicationMastersTemplates } from './communicationMastersTemplates.reducer'
import { process } from './process.reducer'

import {
  addAssociation, addAssociationCompany, addAssociationIndividual, addGroupType, addIndiviualType, changeStatusAssociation, deleteAssociation, getAssociation, getAssociationCompany, getAssociationIndividual, getGroupType, getIndiviualType
} from './association.reucer'
import { getAutoReminderCronLogs } from './autoReminderCronLogs.reducer'
import { getContactusDetail } from './contactusDetail.reducer'
import { baPrpcessStages, dashboardReports, personWiseReports } from './dashboardReports.reducer'
import { getEmailLogs } from './emaillogs.reducer'
import { addExpenceStatement, getAllPayoutDetails, getExpenceStatement, getPayoutDetails, updatePayoutDetails } from './expenceStatement.reducer'
import {
  addEditinformations, getinformations
} from './information.reducer'


import {
  getSettings, editSettings
} from './settings.reducer'
import { fetchBusinessResults } from './marketing.reducer'
import { getMilestoneLogs } from './milestoneLogs.reducer'
import {
  addEditProductTags, addSuperadminInteraction, addUnregisteredSeller, checkDuplicateUnregisteredSeller, deleteProductTags, getProductTags, getUnregisteredSeller
} from './productTag.reducer'
import {
  getCommissionPayments, getDepositPayments, getHeaderData, getProfile
} from './profile.reducer'
import { getProfileWeightageSetting, getSetting, recentRegisternAttempt, recentRegistrations } from './reputeinfo.reducer'
import { getBalancePackage, getBalanceSetting, updateBalancePackage, updateBalanceSetting } from './reputeinfoBalance.reducer'
import { getBuyerRequirementInvites, getRequirementLogs, getSellerDetail, getUnregisteredSellerDetail } from './requirementLog.reducer'
import { getSubscriptionLogs } from './subscriptionLog.reducer'
import { tasks } from './tasks.reducer'
import { getWhatsappLogs, getWhatsappLogsRi } from './whatsapplog.reducer'
import { marketingWhatsappAnalysis, MobileNotificatios, reportValuesModal } from './marketing.reducer'
import { getDirectCriticalDue } from './criticaldue.reducer'
import { getExpenses } from './expenses.reducer'
import { getVoiceLogsData } from './voiceLogs.reducer'
import { getFeedbackData } from './feedback.reducer'
import { getVideoData } from './video.reducer'
import { getLandingPageData, getLandingPageUserData } from './landingPage.reducer'
import { getDailyMis } from './dailyMis.reducer'
import {getPromocodes , getBanner } from  './promocode.reducer'
import {getSendBillTransaction} from  './sendBillTransaction.reducer'
import { getPromoCodeUsages } from './promoCodeUsages.reducer'
import { getUserActivity } from './userActivity.reducer'
import { getLeads } from './leads.reducer'
import { getInquiries } from './Inquiries.reducer'
import { getProducts,getProduct } from './products.reducer'

const individualReducer = {  
  getProducts,
  getProduct,
  getInquiries,
  getLeads,
  getPromoCodeUsages,
  getDailyMis,
  getLandingPageData,
  getLandingPageUserData,
  getSendBillTransaction ,
  // promocodes 
  getPromocodes,
  getBanner,
  // Individual Auth
  authentication,
  registration,
  savePassword,
  forgotPassword,
  // Notification Toaster 
  notifications: notificationsReducer(),
  modal,

  // for Websocke connections
  websocket,

  // verification 
  individualsData,
  individualsDetailData,

  verifyIndividualCompany,

  companiesData,
  companyDetail,
  gstDetail,
  updateCompany,

  // user management
  addUser,
  getUsers,
  getCommissionAmounts,

  addUserType,
  getUserTypes,
  getCommissionSlab,
  JobTitles,
  Departments,


  // email
  addEmail,
  getEmails,
  editEmail,
  deleteEmail,
  publishEmail,
  sendEmail,

  //SMS
  addSMS,
  getSMS,
  editSMS,
  deleteSMS,

  //Communication template
  getCommunicationTemplate,
  editCommunicationTemplate,

  //FAQ
  addFAQ,
  getFAQ,
  saveFAQ,
  deleteFAQ,

  //Notification
  addNotification,
  getNotifications,
  editNotification,
  deleteNotification,
  publishNotifications,

  //Dashboard
  dashboard,
  getSalseStatusOverviewData,

  dashboardReports,
  personWiseReports,
  baPrpcessStages,

  getLegal,
  saveLegal,
  publishLegal,

  dashboardLeadVSRegister,

  // subscription
  getSubscription,
  updateSubscription,

  // customerDetail
  getCustomerDetail,
  updateCustomerDetail,
  deleteCustomerDetail,
  assignCustomerDetail,
  updateCustomerDetailLanguage,
  uploadCsvData,
  updateCustomerDetilEmail,

  // company sector
  getCompanySectorDetail,
  addCompanySectorDetail,
  deleteCompanySectorDetail,
  updateCompanySectorDetail,

  // sales
  getCustomerList,
  getCustomerStatusData,
  updateCustomerSalesStatus,
  addDemoAppData,

  // support
  customerSearchData,
  verifySupportPIN,
  getTicketList,

  // sms packages
  getSMSPackage,
  updateSMSPackage,
  createTicket,
  getTicketDetails,
  saveTicketMessage,
  changeTicketStatus,

  // Account
  getAccountData,
  getUserSalaryData,
  getChequeDetail,
  updateChequeDetail,
  getCommissionData,
  saveCompanyProductTags,
  getCompanyProductTags,
  deleteCompanyProductTags,
  getProductTagsAlphabatically,
  getDepositReceipt,
  updateDepositReceipt,

  // Exception Logs
  getExceptionLogs,

  // User Login Logs
  getUserLoginLogs,

  // milestone Logs
  getMilestoneLogs,

  // email logs
  getEmailLogs,

  //Cron Logs
  getCronLogsData,
  getSmsLogsData,
  getSearchLogsData,
  getAutoReminderCronLogs,
  getSubscriptionLogs,
  getContactusDetail,

  businessAccounts,
  businessAccountTemplate,
  communicationMasters,
  states,
  sectors,

  communicationMastersTemplates,
  process,
  tasks,

  //Admin Content Config
  getAdminContentConfigtData,
  updateAdminContentConfigData,
  getDataFromReputeId,

  // product tags
  getProductTags,
  addEditProductTags,
  deleteProductTags,
  addSuperadminInteraction,
  getUnregisteredSeller,
  addUnregisteredSeller,
  checkDuplicateUnregisteredSeller,

  // requirement logs
  getRequirementLogs,
  getSellerDetail,
  getBuyerRequirementInvites,
  getUnregisteredSellerDetail,

  getBalancePackage,
  updateBalancePackage,
  getBalanceSetting,
  updateBalanceSetting,

  // profile
  getProfile,
  getDepositPayments,
  getCommissionPayments,
  getHeaderData,
  // association
  getAssociation,
  getIndiviualType,
  addIndiviualType,
  addAssociation,
  deleteAssociation,
  changeStatusAssociation,
  getAssociationIndividual,
  addAssociationIndividual,
  getAssociationCompany,
  addAssociationCompany,
  getGroupType,
  addGroupType,
  addExpenceStatement,
  getExpenceStatement,
  getPayoutDetails,
  updatePayoutDetails,
  getAllPayoutDetails,
  getinformations,
  addEditinformations,
  getSettings,
  editSettings,


  recentRegistrations,
  recentRegisternAttempt,
  getSetting,
  getProfileWeightageSetting,

  fetchBusinessResults,

  //whats app 
  getWhatsappLogs,
  getWhatsappLogsRi,

  marketingWhatsappAnalysis,
  getBlockBusiness,
  // MobileNotificatios,
  getDirectCriticalDue,
  reportValuesModal,
  getExpenses,
  getVoiceLogsData,
  getFeedbackData,
  getVideoData,
  getUserActivity,
  
}

export default individualReducer
import {  axiosAjaxCall } from './common.service'

export const cronLogsService = {
    getCronLogsData,
 
}

function getCronLogsData(data) {
    return axiosAjaxCall("get_cron_logs_details", data);
}

import { notify } from 'reapop'
import { reputeinfoBalancePackageConstants, reputeinfoConstants } from '../constants'
import { reputeinfoBalancePackageService } from '../services'

export const reputeinfoBalancePackageActions = {
    getBalancePackage,
    updateBalancePackage,
    getProfileWeightageSetting,
    updateProfileWeightageSetting,
    getBalanceSetting,
    updateBalanceSetting
}

function getBalancePackage(data) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.getBalancePackage(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_REQUEST } }
    function success(result) { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_FAILURE, error } }
}


function updateBalancePackage(data,callback) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.updateBalancePackage(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Update Successfully', status: "success" }))
                    callback.afterAction()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_REQUEST } }
    function success(result) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_FAILURE, error } }
}




function getBalanceSetting(data) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.getBalanceSetting(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_REQUEST } }
    function success(result) { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoBalancePackageConstants.GET_REPUTEINFOBALANCE_SETTING_FAILURE, error } }
}


function updateBalanceSetting(data,callback) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.updateBalanceSetting(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Update Successfully', status: "success" }))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_REQUEST } }
    function success(result) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_FAILURE, error } }
}



function getProfileWeightageSetting(data) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.getProfileWeightageSetting(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_REQUEST } }
    function success(result) { return { type: reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoConstants.GET_PROFILE_WIGHTAGE_SETTINGS_FAILURE, error } }
}


function updateProfileWeightageSetting(data,callback) {
    return dispatch => {
        dispatch(request({ data }));
        reputeinfoBalancePackageService.updateProfileWeightageSetting(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Update Successfully', status: "success" }))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_REQUEST } }
    function success(result) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_SUCCESS, result } }
    function failure(error) { return { type: reputeinfoBalancePackageConstants.EDIT_REPUTEINFOBALANCE_SETTING_FAILURE, error } }
}

import config from 'react-global-configuration'
import { handleResponse, axiosAjaxCall } from './common.service'
import axios from 'axios'
import { authHeader, showLoader } from '../../helpers';

export const customerDetailService = {
    getPincodeData,
    addCustomerDetails,
    getCustomerDetail,
    updateCustomerDetail,
    updateCustomerDetailLanguage,
    deleteCustomerDetail,
    assignCustomerDetail,
    uploadCsvData,
    updateCustomerDetilEmail
}

function getPincodeData(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/pincodedetail`, form_data, setting).then(handleResponse)
}

function addCustomerDetails(data) {
    return axiosAjaxCall("add_customer_detail", data);
}

function getCustomerDetail(data) {
    return axiosAjaxCall("get_customer_details", data);
}

function updateCustomerDetail(data) {
    return axiosAjaxCall("update_customer_detail", data);
}

function updateCustomerDetailLanguage(data) {
    return axiosAjaxCall("update_customer_detail_language", data);
}

function deleteCustomerDetail(data) {
    showLoader();    
    return axiosAjaxCall("delete_customer_detail", data);
}
function assignCustomerDetail(data) {
    showLoader();    
    return axiosAjaxCall("assign_customer_detail", data);
}

function uploadCsvData(data) {
    showLoader();    
    return axiosAjaxCall("upload_csv_data", data);
}

function updateCustomerDetilEmail(data) {
    showLoader();    
    return axiosAjaxCall("update_customer_detail_email", data);
}
import React from 'react'
import { connect } from 'react-redux'
import { UserActivity, reputeinfoActions } from '../../actions';
import { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink, Badge } from 'reactstrap';
import { useState } from 'react';

const RegistrationAnalysis = (props) => {
    const [count, setCount] = useState('');


    useEffect(() => {
        getData()
    }, []);

    let getData = () => {
        const { dispatch } = props
        // dispatch(reputeinfoActions.getRecentRegistrations('asdfasdfdsssssasdaaslasxlkamslk'));
    }
    return (
        <div>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <i className="fa fa-area-chart"></i><strong>Registration  Analysis</strong>
                        </CardHeader>

                        <CardBody>
                            <table className="table table-hover table-striped text-center text-uppercase">
                                <thead>
                                    <tr >
                                        <th></th>
                                        <th>Today</th>
                                        <th>Week</th>
                                        <th>Month </th>
                                        <th>Year</th>
                                        <th>LifeTime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>lalsns</td>
                                        <td>Total Registration</td>
                                        <td>Total Lead </td>
                                        <td>Lead % (Registration/Lead)</td>
                                    </tr>
                                    <tr>
                                        <td>lalsns</td>
                                        <td>Total Registration</td>
                                        <td>Total Lead </td>
                                        <td>Lead % (Registration/Lead)</td>
                                    </tr>
                                    <tr>
                                        <td>lalsns</td>
                                        <td>Total Registration</td>
                                        <td>Total Lead </td>
                                        <td>Lead % (Registration/Lead)</td>
                                    </tr>

                                </tbody>
                            </table>
                        </CardBody>


                        {count && <CardBody>
                            <table className="table table-hover table-striped text-center text-uppercase">
                                <thead>
                                    <tr >
                                        <th></th>
                                        <th>Today</th>
                                        <th>Week</th>
                                        <th>Month </th>
                                        <th>Year</th>
                                        <th>LifeTime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        count && count.whatsapp_optout &&
                                        <tr>
                                            <td><Badge bg="danger" style={{ backgroundColor: 'red' }}>STOP</Badge></td>
                                            <td>{count.whatsapp_optout.today}</td>
                                            <td>{count.whatsapp_optout.week}</td>
                                            <td>{count.whatsapp_optout.month}</td>
                                            <td>{count.whatsapp_optout.year}</td>
                                            <td>{count.whatsapp_optout.lifetime}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({

    // const { verifying } = state.verifyEmailOtp
    // return {
    //     verifying,
    //     verifyOtp: state.verifyEmailOtp
    // }

})

const connected = connect(mapStateToProps)(RegistrationAnalysis)
export { connected as RegistrationAnalysis };
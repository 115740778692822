export const businessAccountsConstants = {

    SAVE_PROCESS_STAGE_REQUEST: 'SAVE_PROCESS_STAGE_REQUEST',
    SAVE_PROCESS_STAGE_SUCCESS: 'SAVE_PROCESS_STAGE_SUCCESS',
    SAVE_PROCESS_STAGE_FAILURE: 'SAVE_PROCESS_STAGE_FAILURE',

    ASSIGN_BUSINESS_REQUEST: 'ASSIGN_BUSINESS_REQUEST',
    ASSIGN_BUSINESS_SUCCESS: 'ASSIGN_BUSINESS_SUCCESS',
    ASSIGN_BUSINESS_FAILURE: 'ASSIGN_BUSINESS_FAILURE',

    GET_DATA_FROM_RI_REQUEST: 'GET_DATA_FROM_RI_REQUEST',
    GET_DATA_FROM_RI_SUCCESS: 'GET_DATA_FROM_RI_SUCCESS',
    GET_DATA_FROM_RI_FAILURE: 'GET_DATA_FROM_RI_FAILURE',


    GET_BUSINESS_ACC_DATA_REQUEST: 'GET_BUSINESS_ACC_DATA_REQUEST',
    GET_BUSINESS_ACC_DATA_SUCCESS: 'GET_BUSINESS_ACC_DATA_SUCCESS',
    GET_BUSINESS_ACC_DATA_FAILURE: 'GET_BUSINESS_ACC_DATA_FAILURE',

    SAVE_BUSINESS_ACC_DATA_REQUEST: 'SAVE_BUSINESS_ACC_DATA_REQUEST',
    SAVE_BUSINESS_ACC_DATA_SUCCESS: 'SAVE_BUSINESS_ACC_DATA_SUCCESS',
    SAVE_BUSINESS_ACC_DATA_FAILURE: 'SAVE_BUSINESS_ACC_DATA_FAILURE',

    GET_SECTORS_REQUEST: 'GET_SECTORS_REQUEST',
    GET_SECTORS_SUCCESS: 'GET_SECTORS_SUCCESS',
    GET_SECTORS_FAILURE: 'GET_SECTORS_FAILURE',

    GET_STATES_REQUEST: 'GET_STATES_REQUEST',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILURE: 'GET_STATES_FAILURE',

    GET_BLOCK_BUSSINESS_DATA_REQUEST: 'GET_BLOCK_BUSSINESS_DATA_REQUEST',
    GET_BLOCK_BUSSINESS_DATA_SUCCESS: 'GET_BLOCK_BUSSINESS_DATA_SUCCESS',
    GET_BLOCK_BUSSINESS_DATA_FAILURE: 'GET_BLOCK_BUSSINESS_DATA_FAILURE',

    BLOCK_BUSSINESS_DATA_REQUEST: "BLOCK_BUSSINESS_DATA_REQUEST",
    BLOCK_BUSSINESS_DATA_SUCCESS: "BLOCK_BUSSINESS_DATA_SUCCESS",
    BLOCK_BUSSINESS_DATA_FAILURE: "BLOCK_BUSSINESS_DATA_FAILURE",
}
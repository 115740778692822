import { findAndModifyFirst } from "obj-traverse/lib/obj-traverse";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button, Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { TableLoader } from "../../../components/Loaders";
import {
  businessAccountsActions,
  businessAccountTemplateActions
} from "../../actions";
import { InputTypes } from "../../constants/stages.constants";
import { Communication } from "./Components/Communications/Communication";
import { ProcessStage } from "./Components/ProcessStage";
import UpdateEachContent from "./Components/UpdateEachContent";
import { UpdateReputeID } from "./Components/UpdateReputeID";

class AddBusinessAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isLoaded: false,
      business_details: [],
      individual_details: {
        individuals: [],
      },
    };
    this.addNewIndividual = this.addNewIndividual.bind(this);
    this.save = this.save.bind(this);
    this.saveToServer = this.saveToServer.bind(this);
    this.saveIndividuals = this.saveIndividuals.bind(this);
    this.getData = this.getData.bind(this);
  }

  addNewIndividual(defaultFirst) {
    const { template } = this.state;
    let new_individual = {
      individual_id: uuidv4(),
      ...template.individual_details.individual_template,
    };

    let { individual_details } = this.state;
    individual_details.individuals.push(new_individual);

    this.setState(
      {
        individual_details,
      },
      () => {
        if (defaultFirst !== true) {
          this.saveToServer();
          this.getData();
        }
      }
    );
  }

  getData() {
    const {
      dispatch,
      match: { params },
    } = this.props;

    let business_details_id = params.businessId;

    if (business_details_id) {
      const {isLoaded } = this.state 
      this.setState({
        business_details_id,
        isEdit: true,
        current_stage: false,
      });
      dispatch(
        businessAccountsActions.getBusinessAccounts({
          business_details_id,
          from_update: true,
          isLoaded : isLoaded
        })
      );
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.getData();

    dispatch(businessAccountTemplateActions.getBusinessAccountTemplate());
  }

  componentWillReceiveProps(props) {
    let { isEdit, individual_details } = this.state;
    let {
      businessAccountTemplate,
      businessDetails,
      individualDetails,
      currentStageJson,
    } = props;

    if (isEdit) {
      if (businessDetails) {
        let businessDetails_parse = businessDetails
          ? JSON.parse(businessDetails)
          : [];
        let individual_details_parse = individualDetails
          ? JSON.parse(individualDetails)
          : [];
        individual_details.individuals = individual_details_parse;
        let current_stage = currentStageJson
          ? JSON.parse(currentStageJson.json)
          : false;
          
        this.setState({
          template: businessAccountTemplate,
          business_details: businessDetails_parse,
          individual_details,
          current_stage,
          isLoaded : true
          // stages:businessAccountTemplate.stages.Items
        });
      }
    } else {
      if (businessAccountTemplate) {
        // let template_data = JSON.parse(businessAccountTemplate);
        if (
          typeof businessAccountTemplate === "object" &&
          businessAccountTemplate.hasOwnProperty("individual_details")
        ) {
          this.setState(
            {
              template: businessAccountTemplate,
              business_details:
                businessAccountTemplate.business_details.children,
            },
            () => {
              this.addNewIndividual(true);
            }
          );
        }
      }
    }
  }

  save(data) {
    let { business_details } = this.state;

    let id = data.id;

    let item = findAndModifyFirst(
      { children: business_details },
      "children",
      { id: id },
      data
    );

    if (item) {
      this.setState({ business_details: item.children });
      this.saveToServer();
    }
  }

  saveIndividuals(data, index) {
    let { individual_details } = this.state;

    let id = data.id;

    let item = findAndModifyFirst(
      individual_details.individuals[index],
      "children",
      { id: id },
      data
    );

    if (item) {
      individual_details.individuals[index] = item;
      this.setState({ individual_details });
      this.saveToServer();
    }
  }

  saveToServer() {
    const { dispatch } = this.props;
    const {
      business_details_id,
      company_repute_id,
      isEdit,
      business_details,
      individual_details,
    } = this.state;

    let dataToSend = {
      business_details: JSON.stringify(business_details),
      individual_details: JSON.stringify(individual_details.individuals),
      // stages: JSON.stringify(stages),
    };

    if (isEdit) {
      dataToSend.business_details_id = business_details_id;
    }

    dispatch(
      businessAccountsActions.saveBusinessAccounts(dataToSend, {
        success: (result) => {
          if (isEdit === false && result && result.id) {
            this.props.history.push("/business-account/edit/" + result.id);
          } else {
            this.getData();
          }
        },
      })
    );
  }

  renderValue(data) {
    let renderedValue = false;

    switch (data.inputType) {
      case InputTypes.Text:
      case InputTypes.TextArea:
        renderedValue = data.value;
      break;
      
      case InputTypes.Checkbox:
        renderedValue = data.value ? "Yes" : "No";
        break;
        
      case InputTypes.Radio:
        renderedValue = data.value ;
        break;
    }
    return renderedValue;
  }

  render() {
    const {
      business_details,
      individual_details,
      isEdit,
      business_details_id,
    } = this.state;
    const { loading, company_repute_id} = this.props;
    let _this = this;

    return (
      <div>
        <h5 className="mb-4 mt-4 ">
          <i className="fas fa-cog" /> &nbsp; {isEdit ? "" : "Add"} Business
          Account {isEdit ? " : " + business_details_id : ""}
        </h5>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <h5>
                  Business Details   
                  { isEdit ?
                  <UpdateReputeID getData={this.getData}  Heading="Registered Company Repute ID" type="company" repute_id={company_repute_id} id={business_details_id}/> : "" }
                  <Button
                    color="primary"
                    size="sm"
                    className={"pull-right"}
                    onClick={() => this.addNewIndividual()}
                  >
                    {" "}
                    Add New Individual
                  </Button>
                </h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    business_details &&  business_details.map((each) => {
                      return (
                        <Col md="3">
                          <b>{each.title}</b> <br />
                          {this.renderValue(each)} &nbsp;
                          
                          <UpdateEachContent
                            save={_this.save}
                            current_data={each}
                          />
                        </Col>
                      );
                    })
                  )}
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {loading ? (
                  <TableLoader />
                ) : (
                  individual_details.individuals.map(
                    (eachIndividual, index) => {
                      return (
                        <React.Fragment>
                          <h5>Individual Details 
                          { isEdit && eachIndividual.individual_id ? <UpdateReputeID getData={this.getData} Heading="Registered Individual Repute ID" type="individual" repute_id={eachIndividual.formated_repute_id} id={eachIndividual.individual_id}/> : "" }
                 
                          </h5>
                          <Row>
                            {eachIndividual.children.map((each) => {
                              return (
                                <Col md="3">
                                  <b>{each.title}</b> <br />
                                  {this.renderValue(each)} &nbsp;
                                  <UpdateEachContent
                                    index={index}
                                    save={this.saveIndividuals}
                                    current_data={each}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                          <br />
                          <br />
                        </React.Fragment>
                      );
                    }
                  )
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            {isEdit && (
              <Communication
                business_details_id={business_details_id}
                individuals={individual_details.individuals}
              />
            )}

            {loading ? (
              <TableLoader />
            ) : (
              <ProcessStage
                {...this.props}
                {...this.state}
                individuals={individual_details.individuals}
                getData={this.getData}
                business_details_id={business_details_id}
              />
            )}

            
            
          {/* {loading ? (
              <TableLoader />
            ) : (
              <SupportStage
                {...this.props}
                {...this.state}
                individuals={individual_details.individuals}
                getData={this.getData}
                business_details_id={business_details_id}
              />
            )} */}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { businessAccountTemplate, businessAccounts } = state;
  return {
    businessAccountTemplate,
    loading: businessAccounts.loading,
    businessDetails: businessAccounts.businessDetails,
    company_repute_id: businessAccounts.company_repute_id,
    individualDetails: businessAccounts.individualDetails,
    currentStageJson: businessAccounts.currentStageJson,
  };
}

const connected = connect(mapStateToProps)(AddBusinessAccount);
export { connected as AddBusinessAccount };

 
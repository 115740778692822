export const communicationMastersConstants = {

    GET_COMM_MASTERS_DATA_REQUEST: 'GET_COMM_MASTERS_DATA_REQUEST',
    GET_COMM_MASTERS_DATA_SUCCESS: 'GET_COMM_MASTERS_DATA_SUCCESS',
    GET_COMM_MASTERS_DATA_FAILURE: 'GET_COMM_MASTERS_DATA_FAILURE',
    
    SAVE_COMM_MASTERS_DATA_REQUEST: 'SAVE_COMM_MASTERS_DATA_REQUEST',
    SAVE_COMM_MASTERS_DATA_SUCCESS: 'SAVE_COMM_MASTERS_DATA_SUCCESS',
    SAVE_COMM_MASTERS_DATA_FAILURE: 'SAVE_COMM_MASTERS_DATA_FAILURE',

}
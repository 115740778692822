export const LegalConstants = {

    GET_LEGAL_REQUEST: 'GET_LEGAL_REQUEST',
    GET_LEGAL_SUCCESS: 'GET_LEGAL_SUCCESS',
    GET_LEGAL_FAILURE: 'GET_LEGAL_FAILURE',

    PUBLISH_LEGAL_REQUEST: 'PUBLISH_LEGAL_REQUEST',
    PUBLISH_LEGAL_SUCCESS: 'PUBLISH_LEGAL_SUCCESS',
    PUBLISH_LEGAL_FAILURE: 'PUBLISH_LEGAL_FAILURE',

    EDIT_LEGAL_REQUEST: 'EDIT_LEGAL_REQUEST',
    EDIT_LEGAL_SUCCESS: 'EDIT_LEGAL_SUCCESS',
    EDIT_LEGAL_FAILURE: 'EDIT_LEGAL_FAILURE',

}
import React from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import moment from 'moment'
import { TableLoader } from '../../../components/Loaders'

function UserActivityMainTab() {
    let logs = false
    let loading = false
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-dashboard" />
                <strong>Finance Status Dashboard</strong>
            </CardHeader>
            <CardBody>
                {loading ? (
                    <TableLoader />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive-sm  animated fadeIn">
                            <table className="table table-sm table-condensed table-striped table-bordered table-outline d-flex " style={{ border: '2px solid #909090' }}>
                                <thead className="thead-light col-9">
                                    <tr>
                                        <th className='col-3'></th>
                                        <th className='col-1'>Today</th>
                                        <th className='col-1'>Yesterday</th>
                                        <th className='col-1'>Week</th>
                                        <th className='col-1'>Month</th>
                                        <th className='col-1'>Year</th>
                                        <th className='col-1'>Lifetime</th>
                                    </tr>
                                    <tr>
                                        <th>No. of Register Customer</th>
                                        <td>12</td>
                                        <td>12</td>
                                        <td>12</td>
                                        <td>12</td>
                                        <td>12</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <th>No. of BadDebt </th>
                                        <td>21</td>
                                        <td>21</td>
                                        <td>21</td>
                                        <td>21</td>
                                        <td>21</td>
                                        <td>21</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <th>Searches</th>
                                        <td> 111</td>
                                        <td> 111</td>
                                        <td> 111</td>
                                        <td> 111</td>
                                        <td> 111</td>
                                        <td> 111</td>
                                        <td> 10</td>
                                    </tr>
                                    <tr>
                                        <th>No. of Unregister Customer</th>
                                        <td> 222</td>
                                        <td> 222</td>
                                        <td> 222</td>
                                        <td> 222</td>
                                        <td> 222</td>
                                        <td> 222</td>
                                    </tr>
                                    <tr>
                                        <th>No. of Unregister BadDebt</th>
                                        <td> 333</td>
                                        <td> 333</td>
                                        <td> 333</td>
                                        <td> 333</td>
                                        <td> 333</td>
                                        <td> 333</td>
                                    </tr>
                                    <tr>
                                        <th>No. of Send Bill Transactions</th>
                                        <td>44</td>
                                        <td>12</td>
                                        <td>44</td>
                                        <td>44</td>
                                        <td>44</td>
                                        <td>44</td>
                                    </tr>
                                    <tr>
                                        <th>No. of Receive Bill Transactions</th>
                                        <td>55</td>
                                        <td>55</td>
                                        <td>55</td>
                                        <td>55</td>
                                        <td>55</td>
                                        <td>55</td>
                                    </tr>

                                </thead>
                                <thead className="thead-light col-2   ">

                                    <tr>
                                        <th className=''>Total Activity</th>
                                        <td className='bg-success'>1123</td>
                                    </tr>
                                    <tr>
                                        <th className=''>Total Revenue</th>
                                        <td className='bg-success'>1123</td>
                                    </tr>
                                    <tr>
                                        <th className=''>Total Balance</th>
                                        <td className='bg-success'>1123</td>
                                    </tr>
                                </thead>
                                {/* <TableCard /> */}
                            </table>
                        </div>
                        {/* {page.totalItemsCount <= page.perPage ? (
                            ''
                        ) : (
                            <Pagination
                                activePage={page.activePage}
                                itemsCountPerPage={page.perPage}
                                totalItemsCount={page.totalItemsCount}
                                pageRangeDisplayed={5}
                                linkClass="page-link"
                                itemClass="page-item"
                                onChange={this.handlePageChange}

                            />
                        )} */}
                    </React.Fragment>
                )}
            </CardBody>
        </Card>
    )
}



export default UserActivityMainTab
import React from 'react'
import { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';
import FinanceStatusMainTab from './MainTab';
import FinanceStatusListTab from './ListTab';

function FinanceStatus() {
    const [ActiveTab, SetActiveTab] = useState('RegisterationAttempt');

    const TABS = {
        Registered: "Registered",
        RegisterationAttempt: "RegisterationAttempt",
    };

    let toggle = (tab) => {
        SetActiveTab(tab);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader> <i className="fas fa-chalkboard-teacher"></i> <b>Finance Status Tracker</b></CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.Registered,
                                        })}
                                        onClick={() =>
                                            toggle(TABS.Registered)
                                        }
                                    >
                                        <span style={{ fontWeight: "bold" }}>Finance Status Dashboard</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className=''>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.RegisterationAttempt,
                                        })}
                                        onClick={() => {
                                            toggle(TABS.RegisterationAttempt);
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold" }}>Finance Status  Trending</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={ActiveTab}>
                                <TabPane tabId={TABS.Registered}>
                                    <FinanceStatusMainTab />
                                </TabPane>
                                <TabPane tabId={TABS.RegisterationAttempt}>
                                    <FinanceStatusListTab />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export { FinanceStatus }
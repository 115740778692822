


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap'
import { supportActions } from '../../../actions';
import { TICKET_STATUS } from '../../../constants';

class TicketStatusActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketDetails: {
                comment: "",
            },
            errors: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.afterSave = this.afterSave.bind(this);

    }



    handleSubmit(status) {

        const { dispatch, ticket_id } = this.props;

        let dataToSend = {
            status: status,
            ticket_id: ticket_id
        };

        dispatch(supportActions.changeTicketStatus(dataToSend, this));
    }

    afterSave() {
        const { dispatch, ticket_id } = this.props;
        dispatch(supportActions.getTicketDetails({ id: ticket_id }));
        this.setState({
            ticketDetails: {
                comment: "",
            }
        })
    }


    render() {
        const { ticketsDetails } = this.props
        let btn;
        switch (parseInt(ticketsDetails.ticket_status_id, 10)) {
            case TICKET_STATUS.NEW:
            case TICKET_STATUS.RE_OPEN:
                btn = <Button color="warning" onClick={() => this.handleSubmit(TICKET_STATUS.IN_PROGRESS)}> Mark as In Progress </Button>
                break;
            case TICKET_STATUS.IN_PROGRESS:
                btn = <Button color="success" onClick={() => this.handleSubmit(TICKET_STATUS.RESOLVED)}>  Mark as Resolved </Button>
                break;

            case TICKET_STATUS.RESOLVED:
                btn = <React.Fragment>
                    <Button color="primary" onClick={() => this.handleSubmit(TICKET_STATUS.RE_OPEN)}> Reopen Ticket </Button> &nbsp;
                    <Button color="secondary" onClick={() => this.handleSubmit(TICKET_STATUS.CLOSE)}> Close Ticket </Button>
                </React.Fragment>
                break;
            default:
                btn = "";
        }

        return (
            <React.Fragment>
                {btn}
            </React.Fragment>
        )

    }
}


function mapStateToProps(state) {

    const { ticketsDetails, loading } = state.getTicketDetails;

    return {
        loading,
        ticketsDetails
    };

}

const connectedTicketStatusActions = connect(mapStateToProps)(TicketStatusActions);
export { connectedTicketStatusActions as TicketStatusActions }

export const informationConstants = {
    ADD__EDIT_INFORMATION_REQUEST: 'ADD__EDIT_INFORMATION_REQUEST',
    ADD__EDIT_INFORMATION_SUCCESS: 'ADD__EDIT_INFORMATION_SUCCESS',
    ADD__EDIT_INFORMATION_FAILURE: 'ADD__EDIT_INFORMATION_FAILURE',

    GET_INFORMATION_REQUEST: 'GET_INFORMATION_REQUEST',
    GET_INFORMATION_SUCCESS: 'GET_INFORMATION_SUCCESS',
    GET_INFORMATION_FAILURE: 'GET_INFORMATION_FAILURE',

    DELETE_INFORMATION_REQUEST: 'DELETE_INFORMATION_REQUEST',
    DELETE_INFORMATION_SUCCESS: 'DELETE_INFORMATION_SUCCESS',
    DELETE_INFORMATION_FAILURE: 'DELETE_INFORMATION_FAILURE',

    
}
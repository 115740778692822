import { validPhone, validReputeIdIndividual, validEmail } from "../../../../helpers";
export const handleAddressValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`pinCode`]) {
        formIsValid = false
        errors[`pinCode`] = 'Please enter valid pincode'
    }
    return { errors, formIsValid }
}
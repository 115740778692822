import queryString from 'query-string';
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, Row } from 'reactstrap';
import { editorConfig } from '../../../components/Editor';
import { TableLoader } from '../../../components/Loaders';
import { LegalActions } from '../../actions';
import { handleValidation } from './Validation';

class AddLegal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorHtml: '',
      theme: 'snow',
      LegalFormData: {
        id: "",
        ContentData: "",
        ContantFor: "Content for ... "
      },
      errors: {},
    }
    this.isLoad = false;
    this.handleChangeHTML = this.handleChangeHTML.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props;

    const values = queryString.parse(this.props.location.search)
    dispatch(LegalActions.getLegal({ content_for: values.cf }));
  }

  componentWillReceiveProps(props) {
    const { data } = props;
    const { LegalFormData } = this.state
    if (!this.isLoad && data && data.id) {
      LegalFormData.id = data.id;
      LegalFormData.ContantFor = data.content_for;
      LegalFormData.ContentData = data.content;
      this.isLoad = true;
      this.setState({ LegalFormData })
    }

  }


  handleChangeHTML(html) {
    this.setState({
      LegalFormData: {
        ...this.state.LegalFormData,
        ContentData: html
      }
    })
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }


  handleSubmit() {
    const { LegalFormData } = this.state
    const { dispatch } = this.props
    const result = handleValidation(LegalFormData)
    this.setState({ errors: result.errors })

    if (result.formIsValid) {
      dispatch(LegalActions.saveLegal({
        id: LegalFormData.id,
        content_for: LegalFormData.ContantFor,
        content: LegalFormData.ContentData,
      }));
    }
  }


  render() {
    const { errors, LegalFormData } = this.state
    const { loading, saving } = this.props
    return (<div className="">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <i className="icon-list"></i><strong>Legal Draft: {LegalFormData.ContantFor} </strong>
            </CardHeader>
            <CardBody>
              {loading ? <TableLoader /> :
                <Form>
                  <Row>
                    <Col xs="12">
                      <ReactQuill
                        theme={this.state.theme}
                        onChange={this.handleChangeHTML}
                        value={LegalFormData.ContentData}
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        bounds={'.app'}
                        placeholder={this.props.placeholder}
                      />
                      <FormFeedback>
                        {errors[`ContentData`] && errors[`ContentData`]}
                      </FormFeedback>
                    </Col>
                  </Row>

                  <Button color="primary" onClick={this.handleSubmit} disabled={saving}> {saving ? "Saving..." : "Save Draft"} </Button> {" "}
                  <Link className="btn btn-secondary" to={"/legal"}> Cancel </Link>

                </Form>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getLegal
  const { saving } = state.saveLegal
  return {
    loading,
    data,
    saving
  }
}

const connectedAddLegal = connect(mapStateToProps)(AddLegal)
export { connectedAddLegal as AddLegal };


import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Input, InputGroup, FormFeedback, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { connect } from 'react-redux'
import { Mobile } from './mobile'
import { Email } from './email';
import { customerDetailAction } from "../../actions";
import { utilityService } from '../../services'
import { handleValidation } from './Validation'
import { Translate } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import { ReputeIDIndividualInput, MobileNoInput } from '../../../components/Forms/Inputs';
import { Link } from "react-router-dom"
import AddressForm from '../../../components/Forms/AddressForm';
import classnames from 'classnames'
import { CustomerPrioritiesOptions } from './CustomerPriorities';
const TABS = {
    ReferrerID: "ReferrerID",
    ReferrerMobile: "AleReferrerMobilerts",
}

class CustomerForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            formData: {
                id: '',
                first_name: '',
                last_name: '',
                company_name: '',
                mobile: [""],
                email: [],
                address_id: '',
                line1: '',
                line2: '',
                city: '',
                taluka: '',
                pinCode: '',
                district: '',
                state: '',
                referrer_repute_id: '',
                referrer_mobile_number: '',
                referrer_name: '',
                referrer_company_name: '',
                preferred_language: '',
                other_language: '',
                sector: '',
                notes: '',
                priority: 3,
            },
            errors: {},
            activeTab: TABS.ReferrerID

        }

        this.handelSubmit = this.handelSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChildValue = this.handleChildValue.bind(this);
        this.getPincodeDetails = this.getPincodeDetails.bind(this);
        this.afterSave = this.afterSave.bind(this);
        this.toggle = this.toggle.bind(this)

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          })
        }
      }

    afterSave() {
        this.props.history.push('/customer-detail')
    }

    handelSubmit(e) {
        e.preventDefault()

        const { formData } = this.state;

        const result = handleValidation(formData)

        this.setState({ errors: result.errors })

        if (result.formIsValid) {
            const { dispatch } = this.props;
            formData.referrer_repute_id = formData.referrer_repute_id.replace(/-/g,'');
            if (formData.id) {
                dispatch(customerDetailAction.updateCustomerDetail(formData, this));
            } else {
                dispatch(customerDetailAction.addCustomerDetails(formData, this));
            }

        }
    }

    /*
    *  Function : getPincodeDetails
    *  check pincode is valid or not and set data of pincode 
    */

    getPincodeDetails() {
        const { formData } = this.state;

        let self = this;

        let pincodeRegex = /^[1-9][0-9]{5}$/

        if (!formData.pinCode || pincodeRegex.test(formData.pinCode) === false) {
            self.setState({
                ...self.state,
                errors: {
                    ...self.state.errors,
                    pinCode: "please enter valid pin code",
                },
            });

            return false;
        }

        utilityService.pincodeDetail(formData.pinCode).then(function (response) {
            if (response.result) {
                self.setState({
                    ...self.state,
                    errors: {
                        ...self.state.errors,
                        pinCode: "",
                    },
                    formData: {
                        ...formData,
                        state: response.data.state,
                        district: response.data.district,
                    }
                });

            } else {

                self.setState({
                    ...self.state,
                    errors: {
                        ...self.state.errors,
                        pinCode: response.message,
                    }
                });

            }
        });

    }

    checkMobileExist(mobile,pos) {
        const { formData,errors } = this.state;

         const result = handleValidation(formData)
         this.setState({ errors: result.errors })
        let self = this;
        mobile = mobile.replace(/_/g, '')
        if (mobile.length === 10) {
            utilityService.checkMobileExist(mobile).then(function (response) {
                if (response.result) {
                    errors[`mobile`][pos -1] = ""
                    self.setState({
                        ...self.state,
                        errors: errors
                    });
    
                } else {
                    errors[`mobile`][pos -1] = response.message
                    self.setState({
                        ...self.state,
                        errors: errors
                    });
    
                }
            }); 
        }
    }
    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        if (name === "first_name" || name === "last_name" || name === "other_language") {

            let regex_one_word = /^[a-zA-Z]+$/
            if (value.length && regex_one_word.test(value) === false) {
                return false;
            }
        }

        if (name === "company_name" || name === "notes" || name === "line1" || name === "line2" || name === "city" || name === "state" || name === "taluka") {
            let regex_allowed = /^[a-zA-Z0-9 .,]+$/
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }


        formData[name] = value;

        this.setState({ formData })
        console.log(formData)
        const result = handleValidation(formData)
        this.setState({ errors: result.errors })
    }

    handleChildValue(data) {
        const { formData } = this.state
        const { name, value } = data;
        formData[name] = value;
        this.setState({ formData })

        if(name === 'mobile') {
            let totalElements = value.length;
            let mobile = value[totalElements -1];
            this.checkMobileExist(mobile,totalElements)
        }
    }

    componentDidMount() {

        const { detail } = this.props


        if (detail && typeof detail === "object") {
            this.setState({
                formData: {
                    id: detail.id,
                    first_name: detail.first_name,
                    last_name: detail.last_name,
                    company_name: detail.company_name,
                    mobile: detail.mobile ? detail.mobile.split(',') : [],
                    email: detail.email ? detail.email.split(',') : [],
                    address_id: detail.address_id,
                    line1: detail.address && detail.address.line1 !== null ? detail.address.line1 : '',
                    line2: detail.address && detail.address.line2 !== null ? detail.address.line2 : '',
                    city: detail.address  && detail.address.city !== null ? detail.address.city : '',
                    taluka: detail.address && detail.address.taluka !== null  ? detail.address.taluka : '',
                    pinCode: detail.address && detail.address.pincode !== null ? detail.address.pincode : '',
                    district: detail.address && detail.address.district !== null  ? detail.address.district : '',
                    state: detail.address && detail.address.state !== null ? detail.address.state : '',
                    referrer_repute_id: detail.referrer_repute_id !== null ? detail.referrer_repute_id : '',
                    referrer_mobile_number: detail.referrer_mobile_number !== null ? detail.referrer_mobile_number : '',
                    referrer_name: detail.referrer_name !== null ? detail.referrer_name : '',
                    referrer_company_name: detail.referrer_company_name !== null ? detail.referrer_company_name : '',
                    preferred_language: detail.preferred_language,
                    other_language: detail.other_language !== null ?  detail.other_language : '',
                    sector: detail.sector !== null ?  detail.sector : '',
                    notes: detail.notes ? detail.notes : '',
                    priority: detail.priority ? detail.priority : 3,
                }
            })
            
            if(detail.referrer_mobile_number) {
                this.setState({activeTab:TABS.ReferrerMobile})
            }
        }
    }

    render() {
        const { errors, formData } = this.state
        const { loading } = this.props
        return (

            <Translate>
                {({ translate }) =>
                    <Form  autocomplete="off" className="col-md-6 offset-md-3" onSubmit={this.handelSubmit}>
                        <FormGroup>
                            <label><b>Mobile Number</b></label>
                            <Mobile handleChildValue={this.handleChildValue} errors={errors["mobile"]} mobileData={formData.mobile} />
                        </FormGroup>
                        
                        <FormGroup>
                            <label><b>Customer Name</b><i className={"mendetoryFields"}>*</i></label>
                            <Row>
                                <Col xs="6">
                                    <InputGroup>
                                        <Input placeholder="First Name" onChange={this.handleChange} value={formData.first_name} name="first_name" maxLength="50" />
                                    </InputGroup>
                                    <FormFeedback>
                                        {errors[`first_name`] && errors[`first_name`]}
                                    </FormFeedback>
                                </Col>
                                <Col xs="6">
                                    <InputGroup>
                                        <Input placeholder="Last Name" onChange={this.handleChange} value={formData.last_name} name="last_name" maxLength="50" />
                                    </InputGroup>
                                    <FormFeedback>
                                        {errors[`last_name`] && errors[`last_name`]}
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <label><b>Company Name</b><i className={"mendetoryFields"}>*</i> </label>
                            <Input placeholder="Company Name" onChange={this.handleChange} value={formData.company_name} name="company_name" maxLength="100" />
                            <FormFeedback>
                                {errors[`company_name`] && errors[`company_name`]}
                            </FormFeedback>

                        </FormGroup>



                        <FormGroup>
                            <label><b>Email ID</b></label>
                            <Email handleChildValue={this.handleChildValue} errors={errors["email"]} emailData={formData.email} maxLength="150" />
                        </FormGroup>


                        <FormGroup>
                            <label><b>Address</b></label>

                            <AddressForm data={formData} errors={errors} translate={translate} handleChange={this.handleChange} getPincodeDetails={this.getPincodeDetails} />
                        </FormGroup>

                        <Row>
                             <FormGroup className="col-md-6">
                                <label><b>Referrer Person Name</b> </label>
                                <InputGroup>
                                    <Input placeholder="Referrer Person Name" maxLength="170" onChange={this.handleChange} value={formData.referrer_name} name="referrer_name" />
                                </InputGroup>
                                <FormFeedback>
                                    {errors[`referrer_name`] && errors[`referrer_name`]}
                                </FormFeedback>
                            </FormGroup>

                            <FormGroup className="col-md-6">
                                <label><b>Referrer Company Name</b> </label>
                                <InputGroup>
                                    <Input placeholder="Refrrer Comapmy Name" maxLength="170" onChange={this.handleChange} value={formData.referrer_company_name} name="referrer_company_name" />
                                </InputGroup>
                                <FormFeedback>
                                    {errors[`referrer_company_name`] && errors[`referrer_company_name`]}
                                </FormFeedback>
                            </FormGroup>
                             <Col xs="12">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === TABS.ReferrerID })}
                                            onClick={() => { this.toggle(TABS.ReferrerID) }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>Referrer ID</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === TABS.ReferrerMobile })}
                                            onClick={() => { this.toggle(TABS.ReferrerMobile) }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>Referrer Mobile</span>
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab} className="mb-4">
                                    <TabPane tabId={TABS.ReferrerID}>

                                        <FormGroup className="col-md-12">
                                            <ReputeIDIndividualInput name="referrer_repute_id" value={formData.referrer_repute_id} handleChange={this.handleChange} />
                                            <FormFeedback>
                                                {errors[`referrer_repute_id`] && errors[`referrer_repute_id`]}
                                            </FormFeedback>
                                        </FormGroup>
                                    </TabPane>
                                    <TabPane tabId={TABS.ReferrerMobile}>
                                        <FormGroup className="col-md-12">
                                            <MobileNoInput name="referrer_mobile_number" handleChange={this.handleChange} value={formData.referrer_mobile_number} />
                                            <FormFeedback>
                                                {errors[`referrer_mobile_number`] && errors[`referrer_mobile_number`]}
                                            </FormFeedback>
                                        </FormGroup>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>

                        <FormGroup>
                            <label><b>Preferred Language</b><i className={"mendetoryFields"}>*</i> </label>

                            <Row>
                                <Col xs="6">
                                    <select className="form-control" name="preferred_language" onChange={this.handleChange} value={formData.preferred_language} >
                                        <option value="0">--- Select Language ---</option>
                                        <option> English </option>
                                        <option> हि ंदी </option>
                                        <option> ગુજરતી </option>
                                        <option> Other </option>
                                    </select>
                                    <FormFeedback>
                                        {errors[`preferred_language`] && errors[`preferred_language`]}
                                    </FormFeedback>
                                </Col>
                                <Col xs="6">
                                    {
                                        formData.preferred_language === 'Other' ?
                                            <InputGroup>
                                                <Input placeholder="Your Preferred Language" name="other_language" onChange={this.handleChange} value={formData.other_language} maxLength="50" />

                                                {errors[`other_language`] &&
                                                    <FormFeedback> {errors[`other_language`]}</FormFeedback>}

                                            </InputGroup>
                                            : ''
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <label><b>Sector</b> </label>
                            <Input placeholder="Sector" onChange={this.handleChange} value={formData.sector} name="sector" maxLength="100" />
                            <FormFeedback>
                                {errors[`sector`] && errors[`sector`]}
                            </FormFeedback>

                        </FormGroup>
                        <FormGroup>
                            <label><b>Notes</b> </label>
                            <Input type="textarea" placeholder="Notes" name="notes" onChange={this.handleChange} value={formData.notes} maxLength="250" />
                        </FormGroup>
                        
                        <FormGroup>
                            <label><b>Priority</b> </label>
                            <CustomerPrioritiesOptions handleChange={this.handleChange}  priority={formData.priority} />
                        </FormGroup>

                        <button className="btn btn-primary" disabled={loading}> {loading ? "Please wait..." : "Save"} </button> {" "}
                        <Link className="btn btn-danger" to="/customer-detail">Cancel</Link>

                    </Form >
                }
            </Translate>
        )
    }
}
function mapStateToProps(state) {
    const { loading } = state.updateCustomerDetail
    return {
        loading,
    }
}

const connectedCustomerForm = withRouter(connect(mapStateToProps)(CustomerForm))
export { connectedCustomerForm as CustomerForm }
export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true
    var email_text =  fields[`email_text`].replace(/<[^>]*>/g, '') 
    if (!fields[`email_text`] || email_text === '') {
        formIsValid = false
        errors[`email_text`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
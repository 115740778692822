import { criticalDueConstants } from "../constants"


export function getDirectCriticalDue(state = {}, action) {
    switch (action.type) {
        case criticalDueConstants.GET_DIRECT_CRITICAL_DUE_REQUEST:
            return { loading: true }
        case criticalDueConstants.GET_DIRECT_CRITICAL_DUE_SUCCESS:
            return { data: action.result }
        case criticalDueConstants.GET_DIRECT_CRITICAL_DUE_FAILURE:
            return {}
        default:
            return state
    }
}
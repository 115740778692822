import { dashboardConstants } from "../constants"

export function dashboard(state = {}, action) {
    switch (action.type) {
      case dashboardConstants.GET_DASHBOARD_DATA_REQUEST:
        return { loading: true }
      case dashboardConstants.GET_DASHBOARD_DATA_SUCCESS:
        return action.result;
      case dashboardConstants.GET_DASHBOARD_DATA_FAILURE:
        return {}
      default:
        return state
    }
  }

  export function dashboardLeadVSRegister(state = {}, action) {
    switch (action.type) {
      case dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_REQUEST:
        return { loading: true }
      case dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_SUCCESS:
        return action.result;
      case dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_FAILURE:
        return {}
      default:
        return state
    }
  }


  export function getSalseStatusOverviewData(state = {}, action) {
    switch (action.type) {
      case dashboardConstants.GET_SALSE_STATUS_OVERVIEW_REQUEST:
        return { loading: true }
      case dashboardConstants.GET_SALSE_STATUS_OVERVIEW_SUCCESS:
//        return action.result;
        return {salseStatus:action.result}   
      case dashboardConstants.GET_SALSE_STATUS_OVERVIEW_FAILURE:
        return {}
      default:
        return state
    }
  }  
  
import axios from 'axios';
import config from 'react-global-configuration';
import { APP_API_URL } from '../../api-config';
import { authHeader, hideLoader, showLoader } from '../../helpers';

export function handleResponse(response, withMesssage = false) {
    hideLoader();
    const data = response.data;

    if (response.status === 200) {
        if (data.message === "Unauthorised") {
            return Promise.reject(data.message);
        } else {
            if (data.result) {
                return withMesssage ? Promise.resolve(data) : Promise.resolve(data.data);
            } else {
                return Promise.reject(data.message);
            }
        }
    } else {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

}

export function axiosAjaxCall(method_name, data){
    showLoader();    
    
    var form_data = new FormData()
    let setting = {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data"
        }
    }

    for (var key in data) {
        form_data.append(key, data[key]);
    }
    return axios.post(`${config.get('apiUrl')}/${method_name}`, form_data, setting).then(handleResponse)
}


export function axiosAppApiAjaxCall(method_name, data, appendHeaders = {}){
    showLoader();    
    
    var form_data = new FormData()
    let setting = {
        headers: { 
            Authorization : "testing",
            ...appendHeaders,
        }
    }

    for (var key in data) {
        form_data.append(key, data[key]);
    }
    return axios.post(`${APP_API_URL}/admin/${method_name}`, form_data, setting).then(handleResponse)
}
import {  axiosAjaxCall } from './common.service'

export const smsLogsService = {
    getSmsLogsData,
 
}

function getSmsLogsData(data) {
    return axiosAjaxCall("get_sms_logs_details", data);
}

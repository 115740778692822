import { verificationConstants } from "../constants"

export function individualsData(state = {}, action) {
  switch (action.type) {
    case verificationConstants.GET_INDIVIDUALS_REQUEST:
      return { loading: true }
    case verificationConstants.GET_INDIVIDUALS_SUCCESS:
      return { 
        individuals: action.result.individual_data,
        total_individuals: action.result.total_individuals
      }
    case verificationConstants.GET_INDIVIDUALS_FAILURE:
      return {}
    default:
      return state
  }
}

export function individualsDetailData(state = {}, action) {
  switch (action.type) {
    case verificationConstants.GET_INDIVIDUAL_DETAIL_REQUEST:
      if(state.hasOwnProperty('profile')) {
        return state
      }
      return { loading: true }
    case verificationConstants.GET_INDIVIDUAL_DETAIL_SUCCESS:
      return { profile: action.result }
    case verificationConstants.GET_INDIVIDUAL_DETAIL_FAILURE:
    case verificationConstants.GET_INDIVIDUAL_DETAIL_RESET:
      return {}
    default:
      return state
  }
}

export function verifyIndividualCompany(state = {}, action) {
  switch (action.type) {
    case verificationConstants.VERIFY_INDIVIDUAL_REQUEST:
      return { individualAllLoading: true }
    case verificationConstants.VERIFY_INDIVIDUAL_SUCCESS:
      return { individualAllData: action.result }
    case verificationConstants.VERIFY_INDIVIDUAL_FAILURE:
      return {}
    default:
      return state
  }
}


export function companiesData(state = {}, action) {
  switch (action.type) {
    case verificationConstants.GET_COMPANY_LIST_REQUEST:
      return { loading: true }
    case verificationConstants.GET_COMPANY_LIST_SUCCESS:
      return { 
        companies: action.result.companies,
        total_companies: action.result.total_companies
       }
    case verificationConstants.GET_COMPANY_LIST_FAILURE:
      return {}
    default:
      return state
  }
}

export function companyDetail(state = {}, action) {
  switch (action.type) {
    case verificationConstants.GET_COMPANY_DETAIL_REQUEST: 
      if(state.hasOwnProperty('profile')) {
        return state
      }
      return { loading: true }
    case verificationConstants.GET_COMPANY_DETAIL_SUCCESS:
      return { profile: action.result }
    case verificationConstants.GET_COMPANY_DETAIL_FAILURE:
    case verificationConstants.GET_COMPANY_DETAIL_RESET:
      return {}
    default:
      return state
  }
}

export function gstDetail(state = {}, action) {
  switch (action.type) {
    case verificationConstants.GET_GST_DETAIL_REQUEST: 
      if(state.hasOwnProperty('profile')) {
        return state
      }
      return { loading: true }
    case verificationConstants.GET_GST_DETAIL_SUCCESS:
      return { profile: action.result }
    case verificationConstants.GET_GST_DETAIL_FAILURE:
    case verificationConstants.GET_GST_DETAIL_RESET:
      return {}
    default:
      return state
  }
}

export function updateCompany(state = {}, action) {
  switch (action.type) {
    case verificationConstants.VERIFY_COMPANY_REQUEST:
      return { loading: true }
    case verificationConstants.VERIFY_COMPANY_SUCCESS:
      return { }
    case verificationConstants.VERIFY_COMPANY_FAILURE:
      return {}
    default:
      return state
  }
}

export function updateIndividual(state = {}, action) {
  switch (action.type) {
    case verificationConstants.VERIFY_COMPANY_REQUEST:
      return { loading: true }
    case verificationConstants.VERIFY_COMPANY_SUCCESS:
      return { }
    case verificationConstants.VERIFY_COMPANY_FAILURE:
      return {}
    default:
      return state
  }
}
import React, { Component } from "react";
import { AddNewItem } from "../Components/AddNewItem";
import { DisplayListItems } from "../Components/DisplayListItems";
import { removeFromItems } from "../Components/FormElements";
import { processActions } from "../../../../actions";
import { connect } from "react-redux";
import { ListGroupItem } from "reactstrap";
import { findAndDeleteFirst } from 'obj-traverse/lib/obj-traverse'

class StageItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stageItems : []
    }

    this.getStageQuestionstData = this.getStageQuestionstData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.SaveItem = this.SaveItem.bind(this);
  }

  componentDidMount() {
    this.getStageQuestionstData();
  }

  getStageQuestionstData() {
    const { Items } = this.props;

    if (Items) {
      this.setState({ Items: Items });
    }
  }

  componentDidMount(props) {
    const { current_data } = this.props;
    
    if (current_data) {
      if(current_data.json) {
        let _stageItems =  JSON.parse(current_data.json);

        let stageItems = typeof _stageItems === "object" ?  (Array.isArray(_stageItems) ? _stageItems : [])  : []; 
       
        this.setState({ stageItems });
      }
    }
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  removeItem(item_id) {
    const { stageItems } = this.state;

    let Items =  findAndDeleteFirst({ children : stageItems }  , "children", {id : item_id})

    if(Items !== false) {
      this.SaveItem(Items.children);
    }
  }

  SaveItem(stageItems) {

    const {current_data,  dispatch, getData } = this.props;
   
  
    let dataToSend = {
        stage_id : current_data.id,
        stageItems: JSON.stringify(stageItems),
        is_stage : true
    }

    this.setState({stageItems})
      dispatch(processActions.saveProcess(dataToSend, {
        success:() => {
            // this.clear()
            // this.setState({ isOpen : false})
            // getData()
        }
    }))
  }

  render() {
    const { stageItems } = this.state;

    return (
      <div>

            <ListGroupItem color="success">
                <b>Stage Items </b> 
                &nbsp; &nbsp;  
                
        <AddNewItem {...this.props} 
          RootItems={stageItems}
          Items={stageItems}
          SaveItem={this.SaveItem} />
            
            </ListGroupItem>
        <DisplayListItems
          {...this.props}
          RootItems={stageItems}
          Items={stageItems}
          SaveItem={this.SaveItem}
          removeItem={this.removeItem}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

const connectedStageItems = connect(mapStateToProps)(StageItems);
export { connectedStageItems as StageItems };

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BusinessAccountStageStatus } from './BusinessAccountStageStatus'
import { PersonWiseStatus } from './PersonWiseStatus'

class DashboardBusinessAccountReports extends Component {
    render() {
        return (
            <div>
                
                <h5 className="mb-4 mt-4 ">
                    <i className="fa fa-dashboard"></i> &nbsp; Dashboard Reports 
                </h5>
                
                <BusinessAccountStageStatus/>

                <PersonWiseStatus/>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dashboardReports } = state
    return {
    };
}



const connected = connect(mapStateToProps)(DashboardBusinessAccountReports) 
export {connected as DashboardBusinessAccountReports}

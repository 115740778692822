import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader, Button } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import Pagination from "react-js-pagination";
import { subscriptionLogActions } from "../../actions";
class SubscriptionLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },
        }
        this.getSubscriptionLogs = this.getSubscriptionLogs.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    componentDidMount() {
        this.getSubscriptionLogs()
    }

    getSubscriptionLogs() {
        const { dispatch } = this.props;
        const { page } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
        }
        dispatch(subscriptionLogActions.getSubscriptionLogs(dataToSend));
    }
    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getSubscriptionLogs());
        
    }
    componentWillReceiveProps(props) {
        let { page } = this.state
        page.totalItemsCount = 0;
        if (props.data) {
            page.totalItemsCount = props.data.total_records
        }
        this.setState({
            page,
        })
    }
    render() {
        let { loading, data } = this.props
        const { page } = this.state;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="icon-directions"></i> <b>Subscription Logs</b></CardHeader>
                            <CardBody>
                                {
                                    loading ? <TableLoader /> :
                                    <div className="table-responsive-sm  animated fadeIn">
                                    <table className="table table-sm table-condensed  table-bordered  table-outline ">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Refrance Number</th>
                                                <th>Repute ID</th>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Total SMS</th>
                                                <th>Subscription Type</th>
                                                <th>Order Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.subscriptions_list && data.subscriptions_list.length > 0 ? 
                                                data.subscriptions_list.map((d,i) => {
                                                    return (<tr>
                                                        <td>{d.reference_number}</td>
                                                        <td>{d.formated_reputeid}</td>
                                                        <td>{d.name}</td>
                                                        <td>{d.subscription_start_date}</td>
                                                        <td>{d.subscription_end_date}</td>
                                                        <td>{d.total_sms}</td>
                                                        <td>{d.subscription_type}</td>
                                                        <td>{d.order_type}</td>
                                                    </tr>)            
                                                })
                                                : <tr><td colSpan="9" className="text-center" style={{ "background-color": "rgba(0, 0, 0, 0.05)"}}>No Data Found</td></tr>

                                            }
                                        </tbody>
                                    </table>
                                            
                                    {page.totalItemsCount <= page.perPage ? "" :
                                    <Pagination
                                        activePage={page.activePage}
                                        itemsCountPerPage={page.perPage}
                                        totalItemsCount={page.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        linkClass="page-link"
                                        itemClass="page-item"
                                        onChange={this.handlePageChange}
                                        // hideNavigation={false}
                                        // hideFirstLastPages={true}
                                    />}
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getSubscriptionLogs
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user

    }
}
const connectedSubscriptionLogs = connect(mapStateToProps)(SubscriptionLogs)
export { connectedSubscriptionLogs as SubscriptionLogs }

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader, Button, Form,FormGroup,FormFeedback ,Input} from 'reactstrap';
import { accountAction } from '../../actions'
import { TableLoader } from '../../../components/Loaders';
import { show,hide } from 'redux-modal'
import swal from 'sweetalert';

class UpdateChequeDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                repute_id:'',
                cheque_detail_id: '',
                cheque_verification_status : 1,
                notes: ''
            },
            errors: {},
        }
        this.handelVerifyCheque = this.handelVerifyCheque.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handelCansel = this.handelCansel.bind(this)
        this.afterAction = this.afterAction.bind(this)
    }
    setStatus(event) {

        const { formData } = this.state
        formData["cheque_verification_status"] = parseInt(event.target.value, 10)
        this.setState({ formData })
    }

    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        formData[name] = value;

        this.setState({ formData })
    }

    componentDidMount() {
        const { data } = this.props;
        let { formData } = this.state;
        if (data) {
            formData.cheque_detail_id = data.id
            formData.repute_id = data.repute_id
            //formData.cheque_verification_status = data.cheque_verification_status
            this.setState({ formData })
        }
    }
    handelVerifyCheque(e) {
        e.preventDefault();
        const { formData } = this.state;
        const { dispatch } = this.props;
        if( !formData.notes.trim() ){
            let errors = {};
            errors.notes = "enter notes";
            this.setState({errors})
            return false
            
        }
        swal({
            title: "Are you sure ?",
            text: "Do you want to update cheque detail?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(accountAction.updateChequeDetail(formData, this));
            }
        })
    }

    handelCansel(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"))
    }
    afterAction() {
        const { dispatch, getChequeDetail } = this.props;
        dispatch(hide("bootstrap"))
        getChequeDetail()
    }
    render() {
        const { errors, formData } = this.state
        return (

            <React.Fragment>
                <Form autocomplete="off">
                    <Row>
                        <Col md="12">
                            <FormGroup >
                                <label><b> Cheque Verification Status </b></label>
                                <div onChange={this.setStatus.bind(this)}>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio1" name="cheque_verification_status" value="1" class="custom-control-input" checked={this.state.formData.cheque_verification_status === 1} />
                                        <label class="custom-control-label" for="customRadio1">Received</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio2" name="cheque_verification_status" value="2" class="custom-control-input" checked={this.state.formData.cheque_verification_status === 2} />
                                        <label class="custom-control-label" for="customRadio2">Bounced</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio3" name="cheque_verification_status" value="3" class="custom-control-input" checked={this.state.formData.cheque_verification_status === 3} />
                                        <label class="custom-control-label" for="customRadio3">Overwriting</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio4" name="cheque_verification_status" value="4" class="custom-control-input" checked={this.state.formData.cheque_verification_status === 4} />
                                        <label class="custom-control-label" for="customRadio4">Stop Payment</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio5" name="cheque_verification_status" value="5" class="custom-control-input" checked={this.state.formData.cheque_verification_status === 5} />
                                        <label class="custom-control-label" for="customRadio5">Signature Mismatch</label>
                                    </div>
                                </div>
                                <FormFeedback>
                                    {errors[`cheque_verification_status`] && errors[`cheque_verification_status`]}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup >
                            <label><b> Note </b></label>
                            <Input placeholder="Note" type="textarea" onChange={this.handleChange} value={formData.notes} name="notes" maxLength="100" />
                            <FormFeedback>
                                {errors[`notes`] && errors[`notes`]}
                            </FormFeedback>

                            </FormGroup>
                        </Col>
                    </Row><hr />
                    <button className="btn btn-secondary btn-sm pull-right" onClick={this.handelCansel} style={{'marginLeft':'10px'}} > Cancel </button>
                    <button type="button" onClick={this.handelVerifyCheque} className="btn btn-primary btn-sm pull-right"  > Update </button>
                </Form>
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    // const { loading, data } = state.getDataFromReputeId
    // console.log(data)
    return {
        // loading, 
        // reputeid_data : data
    }
}

const connectedUpdateChequeDetail = connect(mapStateToProps)(UpdateChequeDetail)
export { connectedUpdateChequeDetail as UpdateChequeDetail }

import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { TableLoader } from '../../../components/Loaders';
import { Collapse } from 'reactstrap';
import Pagination from "react-js-pagination";
import { ExceptionLogsActions } from '../../actions';
import { connect } from 'react-redux'
import { Button, Input } from "reactstrap";
import moment from 'moment';

class TabContentData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tooltipOpen: false,
            collapse: [],
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },
            keywords: '',
            activeTab: ""
        }

        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getExceptionLogs = this.getExceptionLogs.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }
    getExceptionLogs() {
        const { dispatch } = this.props;
        const { page, keywords } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            log_type: this.state.activeTab,
            keywords: keywords
        }
        dispatch(ExceptionLogsActions.getExceptionLogs(dataToSend));
    }
    toggle(index) {
        this.setState({
            collapse: {
                [index]: !this.state.collapse[index]
            }
        });
    }
    componentWillReceiveProps(props) {
        let { page, activeTab } = this.state
        page.totalItemsCount = 0;
        if (props.logs) {
            page.totalItemsCount = props.logs.total_records
        }
        if (props.activeTab) {
            activeTab = props.activeTab
        }
        this.setState({
            page,
            activeTab
        })
    }
    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getExceptionLogs());

    }
    handleChange(e) {
        const { name, value } = e.target

        this.setState({
            ...this.state, [name]: value
        })
    }
    handleSearch() {
        const { page } = this.state

        this.setState({
            ...this.state, page: {
                ...page, activePage: 1
            }
        }, () => this.getExceptionLogs())
    }
    render() {
        const { logs, loading, isSuperAdmin } = this.props
        const { collapse, page, keywords } = this.state
        let _this = this;

        const format = (str) => {
            let parts = str.split(/(\#+\b)/gi)
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = <span key={i}> {i !== 1 && <br />} {parts[i]}</span>;
            }
            return <div>{parts}</div>;
        }
        return (loading ? <TableLoader /> :
            <React.Fragment>
                <div className="d-flex mb-2 " >
                    <Input name='keywords' className='w-25 mr-2' value={keywords} onChange={this.handleChange} placeholder='Search...' />
                    <Button color='primary' onClick={this.handleSearch}>
                        Search
                    </Button>
                </div>
                <div className="table-responsive-sm  animated fadeIn">
                    <table className="table table-sm table-condensed  table-bordered  table-outline ">
                        <thead className="thead-light">
                            <tr>
                                <th style={{ width: "3%" }}></th>
                                <th>#</th>
                                <th>{!isSuperAdmin ? <Translate id='Repute ID' /> : 'User ID'}</th>
                                <th>Login Type</th>
                                <th style={{ width: "50%" }}>Message</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                logs && logs.exception_list && logs.exception_list.length > 0 ?
                                    logs.exception_list.map(function (eachData, index) {
                                        return <React.Fragment>
                                            <tr>
                                                <td style={{ width: "20px" }}>
                                                    {collapse[index] ?
                                                        <i className="fa fa-minus text-danger cursor-pointer" onClick={(e) => _this.toggle(index)}> </i> :
                                                        <i className="fa fa-plus text-success cursor-pointer" onClick={(e) => _this.toggle(index)}> </i>}
                                                </td>
                                                <td>{eachData.id}</td>
                                                <td>{eachData.name ? eachData.repute_id + "(" + eachData.name + ")" : ''}</td>
                                                <td>{eachData.login_type}</td>
                                                <td>{eachData.message}</td>
                                                <td>{eachData.created_at ? moment(eachData.created_at).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                                            </tr>
                                            <tr class={"collapse-td "}>
                                                <td colspan="8">
                                                    <Collapse isOpen={collapse[index]} style={{ 'padding': '10px' }}>

                                                        <div className="table-responsive-sm  animated fadeIn">
                                                            <table className="table-striped table-sm table-condensed " style={{ 'box-shadow': '2px 6px #888888a8' }} width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Message  </b></td>
                                                                        <td>{eachData.message}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Parameters  </b></td>
                                                                        <td>{eachData.parameters}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>File  </b></td>
                                                                        <td>{eachData.file}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Line  </b></td>
                                                                        <td>{eachData.line}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Status Code  </b></td>
                                                                        <td>{eachData.code}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td><b>Trace  </b></td>
                                                                        <td> <div style={{ width: "100%", height: "100px", overflow: "scroll" }} >{format(eachData.trace)}</div></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Collapse>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    })
                                    : <tr><td colSpan="6" className="text-center">No Data Found</td></tr>
                            }

                        </tbody>
                    </table>
                </div>
                {page.totalItemsCount <= page.perPage ? "" :
                    <Pagination
                        activePage={page.activePage}
                        itemsCountPerPage={page.perPage}
                        totalItemsCount={page.totalItemsCount}
                        pageRangeDisplayed={5}
                        linkClass="page-link"
                        itemClass="page-item"
                        onChange={this.handlePageChange}
                    // hideNavigation={false}
                    // hideFirstLastPages={true}
                    />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getExceptionLogs;
    return {
        loading,
        data
    }
}
const connectedTabContentData = connect(mapStateToProps)(TabContentData)
export { connectedTabContentData as TabContentData }

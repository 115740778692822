import React, { Component } from 'react'
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { InputTypes } from '../../../constants/stages.constants';

export default class ViewAccountDetailsData extends Component {
  
  renderValue(data) {
    let renderedValue = false;

    switch (data.inputType) {
      case InputTypes.Text:
      case InputTypes.TextArea:
        renderedValue = data.value ? data.value : "-";
        break;
      case InputTypes.Checkbox:
        renderedValue = data.value ? "Yes" : "No";
        break;
    }
    return renderedValue;
  }
  render() {
    const {business_details ,  individual_details} = this.props 
    return <div>
        <Card>
          <CardHeader>
            <h5>Business Details</h5>
          </CardHeader>
          <CardBody>
            <Row>
              {business_details && business_details.map((each) => {
                return (
                  <Col md="3">
                    <b>{each.title}</b> <br />
                    {this.renderValue(each)}
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {individual_details && individual_details.map((eachIndividual, index) => {
              return (
                <React.Fragment>
                  <h5>Individual Details</h5>
                  <Row>
                    {eachIndividual.children.map((each) => {
                      return (
                        <Col md="3">
                          <b>{each.title}</b> <br />
                          {this.renderValue(each)}
                        </Col>
                      );
                    })}
                  </Row>
                  <br />
                  <br />
                </React.Fragment>
              );
            })}
          </CardBody>
        </Card>

      </div>
    
  }
}

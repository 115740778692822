import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import { TableLoader } from "../../../components/Loaders";
import Pagination from "react-js-pagination";
import { UserLoginLogsActions } from "../../actions";
import { connect } from "react-redux";
import moment from 'moment'
import { Button, Input } from "reactstrap";

class TabContentData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      page: {
        activePage: 1,
        perPage: 20,
        totalItemsCount: 0,
      },
      activeTab: "",
      keywords: ''
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.getUserLoginLogs = this.getUserLoginLogs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  getUserLoginLogs() {
    const { dispatch } = this.props;
    const { page, keywords } = this.state;
    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
      log_type: this.state.activeTab,
      keywords: keywords

    };
    dispatch(UserLoginLogsActions.getUserLoginLogs(dataToSend));
  }

  componentWillReceiveProps(props) {
    let { page, activeTab } = this.state;
    page.totalItemsCount = 0;
    if (props.logs) {
      page.totalItemsCount = props.logs.total_records;
    }
    if (props.activeTab) {
      activeTab = props.activeTab;
    }
    this.setState({
      page,
      activeTab,
    });
  }
  handlePageChange(pageNumber) {
    const { page } = this.state;
    page.activePage = pageNumber;
    this.setState({ page }, () => this.getUserLoginLogs());
  }
  handleChange(e) {
    const { name, value } = e.target

    this.setState({
      ...this.state, [name]: value
    })
  }
  handleSearch(e) {
    const { page } = this.state

    this.setState({
      ...this.state, page: {
        ...page, activePage: 1
      }
    }, () => this.getUserLoginLogs())
  }

  render() {
    const { logs, loading, isSupperAdmin, isDebtorCreditor } = this.props;
    const { page, keywords } = this.state;
    console.log(this.state);
    return loading ? (
      <TableLoader />
    ) : (
      <React.Fragment>
        <div className="d-flex mb-2 " >
          <Input name='keywords' className='w-25 mr-2' value={keywords} onChange={this.handleChange} placeholder='Search...' />
          <Button color='primary' onClick={this.handleSearch}>
            Search
          </Button>
        </div>
        <div className="table-responsive-sm  animated fadeIn">
          <table className="table table-sm table-condensed table-striped table-bordered  table-outline">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>
                  {!isSupperAdmin ? <Translate id="Repute ID" /> : "User ID"}
                </th>
                {isDebtorCreditor ? <th>Company Repute ID</th> : ""}

                {!isSupperAdmin ? (
                  <React.Fragment>
                    <th>Login Type</th>
                    <th>OS Version</th>
                    <th>App Version</th>
                  </React.Fragment>
                ) : (
                  false
                )}
                <th>IP Address</th>
                <th>Login At</th>
                <th>Logout At</th>
              </tr>
            </thead>
            <tbody>
              {logs && logs.loginLogs_list.length ? (
                logs.loginLogs_list.map(function (eachData, index) {
                  return (
                    <React.Fragment>
                      <tr>
                        <td>{eachData.id}</td>
                        <td>
                          {eachData.name
                            ? (!isSupperAdmin
                              ? eachData.individual_reputeid
                              : eachData.user_id) +
                            "  (" +
                            eachData.name +
                            ")"
                            : !isSupperAdmin
                              ? eachData.individual_reputeid
                              : eachData.user_id}
                        </td>
                        {isDebtorCreditor ? (
                          <td>
                            {eachData.company_name
                              ? eachData.company_reputeid +
                              " (" +
                              eachData.company_name +
                              ")"
                              : eachData.company_reputeid}
                          </td>
                        ) : (
                          ""
                        )}

                        {!isSupperAdmin ? (
                          <React.Fragment>
                            <td>{eachData.login_type}</td>
                            <td>{eachData.os_version}</td>
                            <td>{eachData.app_version}</td>
                          </React.Fragment>
                        ) : (
                          false
                        )}
                        <td>{eachData.ip_address}</td>
                        {/* <td>{eachData.login_at}</td> */}
                        <td>{eachData.login_at ? moment(eachData.login_at).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                        <td>{eachData.logout_at ? moment(eachData.logout_at).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                        {/* <td>{eachData.logout_at}</td> */}
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {page.totalItemsCount <= page.perPage ? (
          ""
        ) : (
          <Pagination
            activePage={page.activePage}
            itemsCountPerPage={page.perPage}
            totalItemsCount={page.totalItemsCount}
            pageRangeDisplayed={5}
            linkClass="page-link"
            itemClass="page-item"
            onChange={this.handlePageChange}
          // hideNavigation={false}
          // hideFirstLastPages={true}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getUserLoginLogs;
  return {
    loading,
    data,
  };
}
const connectedTabContentData = connect(mapStateToProps)(TabContentData);
export { connectedTabContentData as TabContentData };

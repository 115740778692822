import { notify } from 'reapop'
import { autoReminderCronLogsConstants } from '../constants'
import { autoReminderCronLogsService } from '../services'

export const autoReminderCronLogsAction = {
    getAutoReminderCronLogs,
}

function getAutoReminderCronLogs(data) {
    return dispatch => {
        dispatch(request({ data }));

        autoReminderCronLogsService.getAutoReminderCronLogs(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_REQUEST } }
    function success(result) { return { type: autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_SUCCESS, result } }
    function failure(error) { return { type: autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_FAILURE, error } }
}
import generator from 'generate-password';
import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
	Button, Col, Collapse, Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText, Row
} from 'reactstrap';
import InlineError from '../../../components/Messages/InlineError';
import { currencyFormat, validEmail, validPhone } from '../../../helpers';
import { userManagementActions } from '../../actions/userManagement.action';
import { DEFAULT_USER_TYPE, LAST_AMOUNT, PERMISSIONS, PERMISSIONS_FOR_FORM } from '../../constants';
import { InputDropDown } from '../Settings/StageSetting/Components/FormElements';
import { CommissionForm } from './CommissionForm';
const defaultVal = [{ min: 0, max: LAST_AMOUNT, commission_rate: 0 }];
const notAllowPermission = ['Partner', 'Associate', 'Reseller', 'Employee'];
class UserForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: [],
			addUser: {
				name: '',
				mobileNo: '',
				type: '0',
				permissions: [],
				email: '',
				password: '',
				is_commission: false,
				CommissionSlab: defaultVal,
				is_auto_assign: false,
				reporting_to_user_id: 0,
				job_title_id: 0,
				first_recharge: '',
				trail_recharge: '',
				service_recharge: '',
				flat_recharge: '',
				deposit_type: '',
				deposit_amount: '',
				associate_deposit: [
					{ type: 'Associate', amount: 0 }
				],
				employee_deposit: [
					{ type: 'Employee', amount: 0 }
				],
				reseller_deposit: [
					{ type: 'Reseller S1', amount: 5000 },
					{ type: 'Reseller S2', amount: 15000 },
				],
				partner_deposit: [
					{ type: 'Partner P1', amount: 30000 },
					{ type: 'Partner P2', amount: 40000 },
					{ type: 'Partner P3', amount: 50000 },
				],

			},
			type: 'password',
			errors: {}
		};

		this.generatePassword = this.generatePassword.bind(this);
		this.showHide = this.showHide.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onSubmitUser = this.onSubmitUser.bind(this);
		this.afterSave = this.afterSave.bind(this);
		this.isValidate = this.isValidate.bind(this);
		this.isValidateSlabs = this.isValidateSlabs.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.updateCommissionSlab = this.updateCommissionSlab.bind(this);
	}

	componentDidMount() {
		const { addUser } = this.state;
		addUser['password'] = generator.generate({
			length: 10,
			numbers: true
		});
		this.getData();

		this.setState({ addUser });
	}
	componentWillReceiveProps(props) {
		const { addUser } = this.state;
		const { isEdit, userData } = props;

		let Users = [];
		if (isEdit && userData) {
			addUser.user_id = userData.id;
			addUser.name = userData.name;
			addUser.mobileNo = userData.mobile;

			addUser.type = userData.user_type ?  userData.user_type.id : "";
			addUser.type_name =  userData.user_type ? userData.user_type.name : "";
			addUser.email = userData.email;
			addUser.job_title_id = userData.job_title_id;
			addUser.reporting_to_user_id = userData.reporting_to_user_id;
			addUser.permissions = userData.user_type ? userData.permission.split(','): [];
			addUser.is_commission =
				userData.commission_slab_rate && userData.commission_slab_rate.length ? true : false;
			addUser.CommissionSlab =
				userData.commission_slab_rate && userData.commission_slab_rate.length
					? userData.commission_slab_rate
					: defaultVal;
			addUser.is_auto_assign = parseInt(userData.is_auto_assign, 10) === 1 ? true : false;
			Users = userData.all_users;

			addUser.first_recharge = userData.first_recharge
			addUser.trail_recharge = userData.trail_recharge
			addUser.service_recharge = userData.service_recharge
			addUser.flat_recharge = userData.flat_recharge
			addUser.deposit_amount = userData.deposit_amount
		}

		this.setState({ addUser, Users: Users });
	}

	getData() {
		const { dispatch } = this.props;
		dispatch(userManagementActions.getUserTypes());
		dispatch(userManagementActions.getJobTitles());

	}

	updateCommissionSlab(data) {
		const { addUser } = this.state;
		addUser.CommissionSlab = data;
		this.setState({ addUser });
	}

	showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		});
	}

	generatePassword() {
		this.setState({
			autoGeneratePassword: generator.generate({
				length: 10,
				numbers: true
			})
		});
	}

	handleChange(e) {
		e.preventDefault();
		const { addUser, errors } = this.state;
		const { UserTypes, authUser } = this.props;
		const { name, value, checked } = e.target;

		if (name === 'name') {
			let regex_one_word = /^[\\p{L} a-zA-Z.'-]+$/;
			if (value.length && regex_one_word.test(value) === false) {
				return false;
			}
		}

		if (name === 'type' && UserTypes) {
			UserTypes.map(function (eachType) {
				if (parseInt(value, 10) === parseInt(eachType.id, 10)) {
					addUser['type_name'] = eachType.name;
				}
				return false;
			});

			if (addUser['type_name'] === DEFAULT_USER_TYPE.SUPER_ADMIN) {
				addUser['permissions'] = [PERMISSIONS.ALL];
			} else {
				addUser['permissions'] = [];
			}
			errors['permissions'] = '';
		}
		if (addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate') {
			if (name == 'first_recharge' && (parseFloat(value,10) > parseFloat(authUser.first_recharge,10))) {
				return false;
			}
			if (name == 'trail_recharge' && (parseFloat(value,10) > parseFloat(authUser.trail_recharge,10))) {
				return false;
			}
			if (name == 'service_recharge' && (parseFloat(value,10) > parseFloat(authUser.service_recharge,10))) {
				return false;
			}
			if (name == 'flat_recharge' && (parseFloat(value,10) > parseFloat(authUser.flat_recharge,10))) {
				return false;
			}
		}
		if (name == 'deposit_type') {
			if (addUser.type_name == 'Partner') {
				let selected_deposit_type = addUser['partner_deposit'].find(f => f.type == value);
				addUser['deposit_amount'] = selected_deposit_type.amount
			}
			if (addUser.type_name == 'Reseller') {
				let selected_deposit_type = addUser['reseller_deposit'].find(f => f.type == value);
				addUser['deposit_amount'] = selected_deposit_type.amount
			}
			if (addUser.type_name == 'Employee') {
				let selected_deposit_type = addUser['employee_deposit'].find(f => f.type == value);
				addUser['deposit_amount'] = selected_deposit_type.amount
			}
			if (addUser.type_name == 'Associate') {
				let selected_deposit_type = addUser['associate_deposit'].find(f => f.type == value);
				addUser['deposit_amount'] = selected_deposit_type.amount
			}
		}
		addUser[name] = value;
		errors[name] = '';
		this.setState({ addUser }, () => console.log('addUser', addUser));
	}

	handleChangeCheck(e) {
		const { addUser, errors } = this.state;
		const { name, value, checked } = e.target;
		addUser[name] = checked;
		if (addUser.CommissionSlab === undefined && checked) {
			addUser.CommissionSlab = defaultVal;
		}
		this.setState({ addUser });
	}

	handleChecked(checked) {
		const { addUser } = this.state;
		addUser.permissions = checked;
		this.setState({ addUser });
	}

	isValidate() {
		const { addUser } = this.state;
		const { isEdit, authUser } = this.props;

		let errors = {};

		if (addUser.name.trim() === '') {
			errors['name'] = 'Please enter name.';
		}

		if (!addUser.mobileNo) {
			errors['mobileNo'] = 'Please enter indian mobile number.';
		} else if (!validPhone(addUser.mobileNo)) {
			errors['mobileNo'] = 'Please enter valid mobile number.';
		}

		if (addUser.type === '0') {
			errors['type'] = 'Please select type';
		}


		// if (addUser.permissions.length === 0) {
		// 	errors['permissions'] = 'Please select user permission.';
		// }

		if (addUser.email === '') {
			errors['email'] = 'Please enter email address';
		} else if (!validEmail(addUser.email)) {
			errors['email'] = 'Please enter valid email address.';
		}

		if (!isEdit) {
			if (addUser.password === '') {
				errors['password'] = 'Please enter password.';
			} else if (addUser.password.length < 6) {
				errors['password'] = 'Password should have atleaset 6 characters.';
			}
		}

		if (addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate') {

			if (addUser.first_recharge === '') {
				errors['first_recharge'] = 'Please enter first recharge';
			}
			else if (parseFloat(addUser.first_recharge) > parseFloat(authUser.first_recharge)) {
				errors['first_recharge'] = 'First recharge should be less than ' + authUser.first_recharge + '%';
			}
			if (addUser.trail_recharge === '') {
				errors['trail_recharge'] = 'Please enter trail recharge';
			}
			else if (parseFloat(addUser.trail_recharge) > parseFloat(authUser.trail_recharge)) {
				errors['trail_recharge'] = 'Trail recharge should be less than ' + authUser.trail_recharge + '%';
			}
			if (addUser.service_recharge === '') {
				errors['service_recharge'] = 'Please enter service recharge';
			}
			else if (parseFloat(addUser.service_recharge) > parseFloat(authUser.service_recharge)) {
				errors['service_recharge'] = 'Service recharge should be less than ' + authUser.service_recharge + '%';
			}
			if (addUser.flat_recharge === '') {
				errors['flat_recharge'] = 'Please enter flat recharge';
			}
			else if (parseFloat(addUser.flat_recharge) > parseFloat(authUser.flat_recharge)) {
				errors['flat_recharge'] = 'Flat recharge should be less than ' + authUser.flat_recharge;
			}

			if (!isEdit) {
				if (addUser.deposit_type === '') {
					errors['deposit_type'] = 'Please select type';
				}
			}
		}
		if (this.isValidateSlabs()) {
			return true;
		}

		this.setState({
			errors
		});

		return Object.keys(errors).length === 0 ? false : true;
	}

	isValidateSlabs() {
		const { addUser } = this.state;

		let isError = false;
		let errors = [];
		let data = addUser.CommissionSlab;

		data.map(function (eachData, i) {
			data[i] = eachData;
			data[i].error = '';
			errors[i] = {};
			if (data[i].commission_rate === '') {
				errors[i]['commission_rate'] = 'this field is required.';
				isError = true;
			}

			if (data[i].min === '') {
				errors[i]['min'] = 'this field is required.';
				isError = true;
			} else if (
				i > 0 &&
				data[i].min !== '' &&
				data[i - 1].min !== '' &&
				parseFloat(data[i - 1].min) > parseFloat(data[i].min)
			) {
				errors[i]['min'] = 'Amount should be greater then previous min amount.';
				isError = true;
			}
		});

		this.setState({
			errors
		});

		return isError;
	}

	onSubmitUser() {
		const { dispatch } = this.props;
		const { addUser } = this.state;

		if (!this.isValidate()) {
			let DataToSend = {
				name: addUser.name,
				mobile: addUser.mobileNo,
				user_type: addUser.type,
				type_name: addUser.type_name,
				permission: addUser.permissions,
				email: addUser.email,
				password: addUser.password,
				is_commission: addUser.is_commission,
				is_auto_assign: addUser.is_auto_assign,
				job_title_id: addUser.job_title_id,
				reporting_to_user_id: addUser.reporting_to_user_id,
				commission_slab_data: JSON.stringify(addUser.CommissionSlab)
			};

			if (addUser.user_id) {
				DataToSend.user_id = addUser.user_id;
			}

			if (addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate') {
				DataToSend.first_recharge = addUser.first_recharge
				DataToSend.trail_recharge = addUser.trail_recharge
				DataToSend.service_recharge = addUser.service_recharge
				DataToSend.flat_recharge = addUser.flat_recharge
				DataToSend.deposit_amount = addUser.deposit_amount
				DataToSend.deposit_type = addUser.deposit_type
			}

			dispatch(userManagementActions.addUser(DataToSend, this));
		}
	}

	afterSave() {
		const { addUser } = this.state;
		const { dispatch } = this.props;

		if (addUser.user_id) {
			dispatch(userManagementActions.getUsers({ user_id: addUser.user_id}));
		} else {
			this.setState({
				addUser: {
					name: '',
					mobileNo: '',
					type: '0',
					permissions: [],
					email: '',
					password: '',
				}
			});
		}
	}


	render() {
		const { addUser, type, errors, Users } = this.state;
		const { loading, UserTypes, isEdit, JobTitles, getCommissionAmounts, fetching, authUser, userData } = this.props;


		let JobTitlesOptions = [{ title: "Not Assigned", value: false }]
		if (JobTitles) {
			JobTitles.map((item) => {
				JobTitlesOptions.push({ title: item.name, value: item.id })
			});
		}


		let usersOptions = [{ title: "Not Assigned", value: false }]
		if (Users) {
			Users.map((item) => {
				let job_title_name = item.job_title ? item.job_title.name : "Not Set Job Title";
				usersOptions.push({ title: item.name + " - " + job_title_name + "", value: item.id })
			});
		}

		let isEditCommissionUser = isEdit && userData && userData.user_type && (
			userData.user_type.name == 'Partner'
			|| userData.user_type.name == 'Reseller'
			|| userData.user_type.name == 'Employee'
			|| userData.user_type.name  == 'Associate') && (authUser.user_type_name != DEFAULT_USER_TYPE.SUPER_ADMIN);

		return (
			<Form autocomplete="off" className="col-md-6 offset-md-3">
				<Row>
					<Col xs="12">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="fa fa-user" />
								</InputGroupText>
							</InputGroupAddon>
							<Input placeholder="Name" name="name" value={addUser.name} onChange={this.handleChange} 
								disabled={isEditCommissionUser} />
						</InputGroup>
						{errors.name && <InlineError message={errors.name} />}
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs="12">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText> +91 </InputGroupText>
							</InputGroupAddon>
							<MaskedInput
								className="form-control"
								type="text"
								mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
								placeholder="Mobile No"
								name={'mobileNo'}
								value={addUser.mobileNo}
								guide={false}
								disabled={isEditCommissionUser}
								onChange={this.handleChange}
							/>
						</InputGroup>
						{errors.mobileNo && <InlineError message={errors.mobileNo} />}
					</Col>
				</Row>
				<br />

				<Row>
					<Col xs="12">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="fa fa-envelope" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								placeholder="Email"
								name="email"
								value={addUser.email}
								disabled={isEditCommissionUser}
								onChange={this.handleChange}
							/>
						</InputGroup>
						{errors.email && <InlineError message={errors.email} />}
					</Col>
				</Row>
				<br />
				{isEdit ? (
					<React.Fragment>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Row>
							<Col xs="12">
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fa fa-asterisk" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										type={type}
										placeholder="Password"
										name="password"
										value={addUser.password}
										onChange={this.handleChange}
									/>
									<InputGroupAddon
										addonType="append"
										className="password__show"
										onClick={this.showHide}
									>
										<InputGroupText style={{ width: '65px', cursor: 'pointer' }}>
											{type === 'input' ? 'Hide' : 'Show'}
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
								{errors.password && <InlineError message={errors.password} />}
							</Col>
						</Row>
						<br />
					</React.Fragment>
				)}

				<Row>

					{isEdit ?
						<React.Fragment>
							{userData && userData.user_type && (
								userData.user_type.name == 'Partner'
								|| userData.user_type.name == 'Reseller'
								|| userData.user_type.name == 'Employee'
								|| userData.user_type.name  == 'Associate') ?

								<Col xs="12"></Col>

								: <React.Fragment>

<Col xs="12">
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
													<i className="fa fa-pencil" />
												</InputGroupText>
											</InputGroupAddon>

											<Input
												type="select"
												name="type"
												value={addUser.type}
												onChange={this.handleChange}
												style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
											>
												<option value="0">Select user type</option>
												{UserTypes ? (
													UserTypes.map(function (eachType) {
														return (
															<option key={eachType.id} value={eachType.id}>
																{eachType.name}
															</option>
														);
													})
												) : (
													''
												)}
											</Input>
										</InputGroup>
										{errors.type && <InlineError message={errors.type} />}
									</FormGroup>
								</Col>
								</React.Fragment>}
						</React.Fragment>
						: <React.Fragment>


							<Col xs="12">
								<FormGroup>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
												<i className="fa fa-pencil" />
											</InputGroupText>
										</InputGroupAddon>

										<Input
											type="select"
											name="type"
											value={addUser.type}
											onChange={this.handleChange}
											style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
										>
											<option value="0">Select user type</option>
											{UserTypes ? (
												UserTypes.map(function (eachType) {
													return (
														<option key={eachType.id} value={eachType.id}>
															{eachType.name}
														</option>
													);
												})
											) : (
												''
											)}
										</Input>
									</InputGroup>
									{errors.type && <InlineError message={errors.type} />}
								</FormGroup>
							</Col>
							<Col xs="12">
								{
									addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate' ?

										<FormGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
														<i className="fa fa-pencil" />
													</InputGroupText>
												</InputGroupAddon>

												<Input
													type="select"
													name="deposit_type"
													value={addUser.deposit_type}
													onChange={this.handleChange}
													style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
												>
													<option value="0">Select deposit type</option>
													{isEdit ? <React.Fragment>

													</React.Fragment> :
														<React.Fragment>
															{addUser.type_name == 'Partner' ? (
																addUser.partner_deposit.map(function (eachType, index) {
																	return (
																		<option key={index} value={eachType.type}>
																			{eachType.type} - {'₹' + currencyFormat(eachType.amount)}
																		</option>
																	);
																})
															) : (
																addUser.type_name == 'Reseller' ? (
																	addUser.reseller_deposit.map(function (eachType, index) {
																		return (
																			<option key={index} value={eachType.type}>
																				{eachType.type} - {'₹' + currencyFormat(eachType.amount)}
																			</option>
																		);
																	})
																)
																	: addUser.type_name == 'Employee' ? (
																		addUser.employee_deposit.map(function (eachType, index) {
																			return (
																				<option key={index} value={eachType.type}>
																					{eachType.type} - {'₹' + currencyFormat(eachType.amount)}
																				</option>
																			);
																		})
																	) :
																		addUser.type_name == 'Associate' ? (
																			addUser.associate_deposit.map(function (eachType, index) {
																				return (
																					<option key={index} value={eachType.type}>
																						{eachType.type} - {'₹' + currencyFormat(eachType.amount)}
																					</option>
																				);
																			})
																		) : ''
															)}</React.Fragment>}
												</Input>
											</InputGroup>
											{errors.deposit_type && <InlineError message={errors.deposit_type} />}
										</FormGroup>
										: false
								}
							</Col>
						</React.Fragment>}
					<Col xs="12">
						{
							addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate' ?
								<React.Fragment>
									<h5 className=" mb-4 mt-4"> Commission </h5>
									<CommissionForm {...getCommissionAmounts} {...fetching} authUser={authUser} errors={errors} addUser={addUser} handleChange={this.handleChange} />
								</React.Fragment>
								: ''
						}
					</Col>
					<Col xs="12">
						{
							notAllowPermission.indexOf(addUser.type_name) == -1 &&


							<Collapse isOpen={addUser.type !== '0'}>
								<h5 className="mb-4 mt-4"> Permissions </h5>
								<FormGroup>
									<Collapse
										isOpen={addUser.type_name && addUser.type_name === DEFAULT_USER_TYPE.SUPER_ADMIN}
									>
										All pemissions
									</Collapse>

									<Collapse
										isOpen={addUser.type_name && addUser.type_name !== DEFAULT_USER_TYPE.SUPER_ADMIN}
									>
										<CheckboxTree
											nodes={PERMISSIONS_FOR_FORM}
											checked={addUser.permissions}
											expanded={this.state.expanded}
											// noCascade={true}
											onCheck={this.handleChecked}
											onExpand={(expanded) => this.setState({ expanded })}
										/>
									</Collapse>

									{errors.permissions && <InlineError message={errors.permissions} />}
								</FormGroup>
							</Collapse>
						}
					</Col>
				</Row>

				{/* <h5 className=" mb-4 mt-4"> Commission </h5>

				<FormGroup>
					<label class="switch switch-label switch-primary" for="is_commission">
						<input
							type="checkbox"
							className="switch-input"
							id="is_commission"
							checked={addUser.is_commission}
							name="is_commission"
							onChange={(e) => this.handleChangeCheck(e)}
						/>
						<span class="switch-slider" data-checked="YES" data-unchecked="NO" />
					</label>

					<Collapse isOpen={addUser.is_commission === true}>
						<ManageCommissionSlab
							errors={errors}
							isValidate={this.isValidateSlabs}
							CommissionSlab={addUser.CommissionSlab}
							update={this.updateCommissionSlab}
						/>
					</Collapse>
				</FormGroup> */}

				<React.Fragment>
					{
						addUser.type_name == 'Partner' || addUser.type_name == 'Reseller' || addUser.type_name == 'Employee' || addUser.type_name == 'Associate' ? ''
							: (
								<React.Fragment>
									<h5 className=" mb-4 mt-4"> Auto Assign </h5>

									<FormGroup>
										<label class="switch switch-label switch-primary" for="is_auto_assign">
											<input
												type="checkbox"
												className="switch-input"
												id="is_auto_assign"
												checked={addUser.is_auto_assign}
												name="is_auto_assign"
												onChange={(e) => this.handleChangeCheck(e)}
											/>
											<span class="switch-slider" data-checked="YES" data-unchecked="NO" />
										</label>
									</FormGroup>

									<InputDropDown
										title={"Job title"}
										value={addUser.job_title_id}
										name={"job_title_id"}
										onChange={this.handleChange}
										options={JobTitlesOptions}
									/>


									<InputDropDown
										title={"Reporting To User"}
										value={addUser.reporting_to_user_id}
										name={"reporting_to_user_id"}
										onChange={this.handleChange}
										options={usersOptions}
									/>

								</React.Fragment>
							)
					}
				</React.Fragment>


				<Row>
					<Col xs="12">
						<Button color="primary" onClick={this.onSubmitUser} disabled={loading}>
							{loading ? 'Please wait...' : 'Save'}
						</Button>{' '}
						&nbsp;
						<Link className="btn btn-secondary" to={'/user-management/users'}>
							Back
						</Link>
					</Col>
				</Row>
			</Form >
		);
	}
}

function mapStateToProps(state) {
	const { loading } = state.addUser;
	const { data } = state.getUserTypes;
	const { fetching, commission_data } = state.getCommissionAmounts;
	const { user } = state.authentication
	return {
		loading,
		fetching,
		commission_data,
		UserTypes: data,
		JobTitles: state.JobTitles.data,
		authUser: user
	};
}

const connectedUserForm = withRouter(connect(mapStateToProps)(UserForm));
export { connectedUserForm as UserForm };


import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Col, Row, Button, Form, Badge, Modal, ModalHeader, ModalFooter, ModalBody,
    FormGroup, Input, InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormFeedback,
    Label
} from 'reactstrap';
import { reputeinfoBalancePackageActions } from "../../actions";
class UpdateWeightage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                type: '',
                value: '',
                visibility: 1,
            },
            errors: {},
            thisModel: false,
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    handleToggle() {
        const { thisModel, formData } = this.state;
        const { editData, getBalanceSetting } = this.props

        this.setState({
            thisModel: !thisModel,
        });
        console.log('thisModel', thisModel)
        if (thisModel == true) {
            this.reset()

        }
    }
    handleChange(event) {
        const { name, value, type } = event.target;
        let { formData, errors } = this.state;
        let val = value;
        formData[name] = val
        errors[name] = ''

        // set value to state
        this.setState({ formData, errors },
            () => { }
        );
    }
    isValid() {
        const { formData, errors } = this.state
        let error = false;
        if (!formData.value) {
            error = true
            errors.value = "this field is requited"
        }

        this.setState({ errors })
        return error;
    }

    handelSubmit() {
        if (!this.isValid()) {
            const { formData } = this.state
            const { dispatch, editData } = this.props

            let dataToSend = {
                id: formData.id,
                value: formData.value,
                visibility: formData.visibility,
            }
            dispatch(reputeinfoBalancePackageActions.updateProfileWeightageSetting(dataToSend, {
                success: () => this.handleToggle()
            }))
        }
    }
    reset() {
        let { formData } = this.state
        const { editData, getBalanceSetting } = this.props
        formData.id = ''
        formData.value = ''
        formData.type = ''
        formData.visibility = 0
        this.setState({ formData, erroes: {} })
        if (typeof getBalanceSetting == 'function') {
            getBalanceSetting()
        }
    }

    setEditData(editData) {
        let { formData } = this.state
        if (editData) {
            formData.id = editData.id
            formData.key = editData.key
            formData.type = editData.type
            formData.value = editData.value
            formData.visibility = editData.visibility
            this.setState({ formData })
        }

    }
    componentDidMount() {
        const { editData } = this.props
        if (editData) {
            this.setEditData(editData)
        }
    }

    render() {
        const { title, editData } = this.props
        const { formData, thisModel, errors } = this.state
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
        };
        return (
            <React.Fragment>
                <Button size='sm' color="primary" onClick={this.handleToggle}>{"Edit"}</Button>&nbsp;
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="md" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> {editData ? "Edit" : "Add"} Weightage   </ModalHeader>
                    <ModalBody >
                        
                        <FormGroup>
                            <Input type="text" value={formData.key} name="key" disabled={"disabled"} />
                        </FormGroup>
                        
                        <FormGroup>
                            <Input type="text" value={formData.value} name="value" onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>

                            <Input type='select'  value={formData.visibility} name="visibility"  onChange={this.handleChange} >
                                <option value={1}>Visible</option>
                                <option value={0}>Not Visible</option>
                            </Input>

                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handelSubmit}>Submit </Button>

                        <Button color="secondary" onClick={this.handleToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedUpdateBalanceSetting = connect(mapStateToProps)(UpdateWeightage);
export { connectedUpdateBalanceSetting as UpdateWeightage }
import 'babel-polyfill';
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import config from 'react-global-configuration';
import { LocalizeProvider } from "react-localize-redux";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { API_ROOT, WEB_SOCKET_URL } from "./api-config";
import { store } from './helpers';
import { unregister } from "./registerServiceWorker";
import { App } from "./views/App";


// Styles
import 'react-tippy/dist/tippy.css';
// CoreUI Icons Set
import 'bootstrap/dist/css/bootstrap.min.css';


// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';

// react Date picker
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


import "react-datepicker/dist/react-datepicker.css";
// Add the css styles...

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import 'react-widgets/dist/css/react-widgets.css';

import 'animate.css';
import './scss/style.css';

// React bootstrap table 
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import 'react-vertical-timeline-component/style.min.css';
import './scss/custom.css';

//  css for bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
// import "react-image-gallery/styles/css/image-gallery.css";
// Set url from config file for globelly available 
config.set({
  apiUrl: API_ROOT, 
  webSocketUrl: WEB_SOCKET_URL 
});

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <Router history={hist}>
        <App />
      </Router>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root")
);

unregister();
// registerServiceWorker();
 
import React, { Component } from 'react'
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { show } from 'redux-modal'
import swal from 'sweetalert'
import { TableLoader } from '../../../components/Loaders'
import { DataTable } from '../../../components/Tables/DataTable'
import { SMSActions } from '../../actions'

class SMS extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
    this.getSMS = this.getSMS.bind(this)
    this.renderAction = this.renderAction.bind(this)
  }

  componentDidMount() {
    this.getSMS()
  }

  getSMS() {
    const { dispatch } = this.props;
    dispatch(SMSActions.getSMS());
  }
  publishSMS(data) {
    const { dispatch } = this.props;
    dispatch(SMSActions.publishSMS(data, this));

  }


  renderAction(cell, row, rowIndex, formateExtraData) {
    const { dispatch, publishing } = this.props;
    const confirmPublish = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to publish from draft ? It will visible on reputeinfo.com",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let dataToSent = {
            sms_type: row.sms_type
          };
          this.publishSMS(dataToSent)
        }
      });
    }
    const testSMS = () => {
      dispatch(show("bootstrap", {
        ModelSize: "md",
        ModelHeaderText: "Test SMS",
        ModelBodyText: row.sms_test_text,
        isFooter: false
      }));

    }
    return <React.Fragment>
      {parseInt(row.sub_version, 10) === 0 ?
        <Link className="btn btn-success btn-sm" to={"/sms/update_sms/" + row.id}> New draft </Link> :
        <Link className="btn btn-primary btn-sm" to={"/sms/update_sms/" + row.id}> Edit </Link>} {" "}
      {parseInt(row.sub_version, 10) === 0 ? "" :
        <Button className="btn btn-danger btn-sm" onClick={(e) => confirmPublish()} to={"/sms/update_sms/" + row.id}> {publishing ? "Please Wait..." : "Publish to Reputeinfo"} </Button>} &nbsp;
      <Button className="btn btn-warning btn-sm" onClick={(e) => testSMS()} > Test </Button>
    </React.Fragment>
  }

  renderVersion(cell, row, rowIndex, formateExtraData) {
    return row.version + "." + row.sub_version;
  } wsw

  renderSMSText(cell, row, rowIndex, formateExtraData) {
    row.short_description = row.sms_text
    row.short_description = row.short_description.length > 80 ? row.short_description.slice(0, 80) + '.....' : row.short_description
    return row.short_description
  }

  render() {
    const { data, loading, count } = this.props
    const columns = [{
      text: 'SMS Text',
      dataField: 'sms_text',
      formatter: this.renderSMSText,
      sort: true,
      // filter: textFilter({
      //   placeholder: 'SMS Text'
      // }),
    }, {
      text: 'Description',
      dataField: 'help_text',
      sort: true,
      filter: textFilter({
        placeholder: 'Description'
      }),
    }, {
      text: 'Usage',
      dataField: 'usage',
      sort: true,
      filter: textFilter({
        placeholder: 'Usage'
      }),
    }, {
      text: 'Last Published',
      dataField: 'last_published',
      sort: true
    }, {
      text: 'Last Saved on',
      dataField: 'updated_at',
    }, {
      text: 'Version',
      dataField: 'varsion',
      formatter: this.renderVersion,
    },
    {
      text: 'Action',
      dataField: 'action',
      formatter: this.renderAction
    }]

    return (
      <div className="">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                <i className="fas fa-sms"></i><strong>SMS</strong> <span className="badge badge-primary">{count ? count : 0} </span>
              </CardHeader>
              <CardBody>
                {loading ? <TableLoader /> :
                  <DataTable keyField="id"
                    data={data || []} columns={columns}
                    filter={filterFactory()}
                    noDataIndication={"No data found"} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getSMS
  const { user } = state.authentication
  return {
    loading,
    data: data ? data.sms_data : [],
    count: data ? data.total_sms : 0,
    authUser: user

  }

}

const connectedSMS = connect(mapStateToProps)(SMS)
export { connectedSMS as SMS }


import { notify } from 'reapop'
import { FAQConstants } from '../constants'
import { FAQService } from '../services'

export const FaqActions = {
    addFAQ,
    getFAQ,
    saveFAQ,
    deleteFAQ
}

function addFAQ(data) {
    return dispatch => {
        dispatch(request({ data }));

        FAQService.addFAQ(data)
            .then(
                notVerified => dispatch(success(notVerified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: FAQConstants.ADD_FAQ_REQUEST } }
    function success(result) { return { type: FAQConstants.ADD_FAQ_SUCCESS, result } }
    function failure(error) { return { type: FAQConstants.ADD_FAQ_FAILURE, error } }
}

function getFAQ(data) {
    return dispatch => {
        dispatch(request())

        FAQService.getFAQ(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: FAQConstants.GET_FAQ_REQUEST } }
    function success(result) { return { type: FAQConstants.GET_FAQ_SUCCESS, result } }
    function failure(error) { return { type: FAQConstants.GET_FAQ_FAILURE, error } }
}

function saveFAQ(data,ref) {
    return dispatch => {
        dispatch(request())

        FAQService.saveFAQ(data)
            .then(
                result => {
                    dispatch(success(result))
                    if(ref) {
                        ref.afterSave()
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: FAQConstants.EDIT_FAQ_REQUEST } }
    function success(result) { return { type: FAQConstants.EDIT_FAQ_SUCCESS, result } }
    function failure(error) { return { type: FAQConstants.EDIT_FAQ_FAILURE, error } }
}

function deleteFAQ(data, ref) {
    return dispatch => {
        dispatch(request())

        FAQService.deleteFAQ(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(getFAQ());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: FAQConstants.DELETE_FAQ_REQUEST } }
    function success(result) { return { type: FAQConstants.DELETE_FAQ_SUCCESS, result } }
    function failure(error) { return { type: FAQConstants.DELETE_FAQ_FAILURE, error } }
}
export const SalesConstants = {
    GET_CUSTOMER_LIST_REQUEST: 'GET_CUSTOMER_LIST_REQUEST',
    GET_CUSTOMER_LIST_SUCCESS: 'GET_CUSTOMER_LIST_SUCCESS',
    GET_CUSTOMER_LIST_FAILURE: 'GET_CUSTOMER_LIST_FAILURE',

    GET_CUSTOMER_SALES_STATUS_REQUEST: 'GET_CUSTOMER_SALES_STATUS_REQUEST',
    GET_CUSTOMER_SALES_STATUS_SUCCESS: 'GET_CUSTOMER_SALES_STATUS_SUCCESS',
    GET_CUSTOMER_SALES_STATUS_FAILURE: 'GET_CUSTOMER_SALES_STATUS_FAILURE',

    
    UPDATE_CUSTOMER_SALES_STATUS_REQUEST: 'UPDATE_CUSTOMER_SALES_STATUS_REQUEST',
    UPDATE_CUSTOMER_SALES_STATUS_SUCCESS: 'UPDATE_CUSTOMER_SALES_STATUS_SUCCESS',
    UPDATE_CUSTOMER_SALES_STATUS_FAILURE: 'UPDATE_CUSTOMER_SALES_STATUS_FAILURE',

    ADD_DEMO_APP_DATA_REQUEST: 'ADD_DEMO_APP_DATA_REQUEST',
    ADD_DEMO_APP_DATA_SUCCESS: 'ADD_DEMO_APP_DATA_SUCCESS',
    ADD_DEMO_APP_DATA_FAILURE: 'ADD_DEMO_APP_DATA_FAILURE',
}
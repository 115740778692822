import React, { Component } from 'react';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button } from 'reactstrap';
import InlineError from "../../../../components/Messages/InlineError";
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { authActions } from '../../../actions';
import MaskedInput from 'react-text-mask'
import FormHelpText from '../../../../components/Messages/FormHelpText';
import { validPassword } from '../../../../helpers';

class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                cPassword: "",
                cRetypePassword: "",
                code:"",
                
            },
            token:"",
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const { token } = this.props;
            
        if(token) {
            this.setState({
                token
            })
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                [name]: value
            }
        });
    }
    handleCancel() {
        this.props.history.push('/login')
      }
    // handle form submit event
    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { data,token } = this.state;
        const { dispatch } = this.props;

        this.setState({
            isVAlidCode: true
        });

        if (this.isValidData()) {

            let dataToSend = {
                token,
                "pin":data.code,
                "new_password": data.cPassword,
                "confirm_password": data.cRetypePassword
            };
            
            dispatch(authActions.saveNewPassword(this, dataToSend));
        }
    }

    // form validation
    isValidData() {
        const { data, errors } = this.state;
        let cPasswordError = false, cPasswordErrorMessage = false, cRetypePasswordError = false, cRetypePasswordErrorMessage = false, error = false, codeErrorMessage = false;

        if (data.cPassword === "") {
            cPasswordError = true;
            cPasswordErrorMessage = "this field is required";
            error = true;
        } else if (!validPassword(data.cPassword)) {
            cPasswordError = true;
            cPasswordErrorMessage = "Password does not match specified format.";
            error = true;
        } else if (data.cPassword !== data.cRetypePassword) {
            cRetypePasswordError = true;
            cRetypePasswordErrorMessage = "Retype password does not matched";
            error = true;
        }
        if (data.cRetypePassword === "") {
            cRetypePasswordError = true;
            cRetypePasswordErrorMessage = "this field is required";
            error = true;
        }
        
        if (data.code === "") {
            codeErrorMessage = true;
            codeErrorMessage = "this field is required";
            error = true;
        }
        else if(data.code.replace(/[^0-9]/g, "").length < 6)
        {
            codeErrorMessage = true;
            codeErrorMessage = "Enter Valid Code";
            error = true;
        }
        this.setState({
            ...this.state,
            errors: {
                ...errors,
                cPasswordError,
                cPasswordErrorMessage,
                cRetypePasswordError,
                cRetypePasswordErrorMessage,
                codeErrorMessage
            }
        });
        return !error;
    }

    render() {
        const { data, errors } = this.state;
        const { saving } = this.props;

        return (
            <React.Fragment>
                <Translate>
                    {({ translate }) =>
                        <form  autocomplete="off" name="form" onSubmit={this.handleSubmit}>

                            <div className="mb-3">
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-lock"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password"
                                        value={data.password}
                                        name={"cPassword"}
                                        onChange={this.handleChange}
                                        placeholder={translate("title.password")} />
                                </InputGroup>
                                {errors.cPasswordError && <InlineError message={errors.cPasswordErrorMessage} />}
                                <FormHelpText message= {"Password must include : 8-20 Characters, at least one capital latter,  one number, one special character, no spaces."} />

                            </div>

                            <div className="mb-3">
                                <InputGroup >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-lock"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password"
                                        value={data.cRetypePassword}
                                        name={"cRetypePassword"}
                                        onChange={this.handleChange}
                                        placeholder={translate("title.retype_password")} />
                                </InputGroup>

                                {errors.cRetypePasswordError && <InlineError message={errors.cRetypePasswordErrorMessage} />}

                            </div>
                            <div className="mb-3">
                            <InputGroup >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-check"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <MaskedInput 
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} 
                                        className="form-control" 
                                        type="text" 
                                        name="code" 
                                        onChange={this.handleChange} 
                                        placeholder={'Verification code'} />
                                </InputGroup>

                                {errors.codeErrorMessage && <InlineError message={errors.codeErrorMessage} />}
                            </div>
                            <Button type={"submit"} color="primary" className="px-2" disabled={saving}> {saving ? "Please wait..." : "Save Password" }</Button>
                            <Button color="secondary" type="button" style={{'marginLeft':'10px'}} onClick={this.handleCancel}>Cancel</Button> 

                        </form>
                    }
                </Translate>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { saving } = state.savePassword;
    return {
        saving
    };
}

const connectedResetPasswordForm = withRouter(connect(mapStateToProps)(ResetPasswordForm));
export { connectedResetPasswordForm as ResetPasswordForm };

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { hide } from 'redux-modal';
import swal from 'sweetalert';
import { verificationActions } from '../../../../actions';
class AddReason extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                reason: ""
            },
            errors: {}
        }
        this.handleCancel = this.handleCancel.bind(this);
        this.handleValidation = this.handleValidation.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handelSubmit = this.handelSubmit.bind(this)
    }

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"))
    }

    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target
        formData[name] = value
        this.setState({ formData })
        this.handleValidation()
    }

    handleValidation() {
        const { formData, errors } = this.state
        let error = false;
        if (formData['reason'] === '') {
            errors['reason'] = "This field is required"
            error = true
        }
        else {
            errors['reason'] = ""
            error = false
        }
        this.setState({
            errors
        })
        return error
    }

    handelSubmit(e) {
        e.preventDefault()
        const { formData } = this.state
        let result = this.handleValidation()
        let helptext = "Do you want to Reject ?"
        if (!result) {
            const { dispatch, data } = this.props;
            data.reason = formData.reason
            swal({
                title: "Are you sure ?",
                text: helptext,
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {              
                    dispatch(verificationActions.verifyIndividualCompany(data, this))
                }
                else {
                    this.handleCancel()
                }
            })
        }
    }

    afterAction() {
        const { dispatch, data } = this.props;
        dispatch(verificationActions.getCompanyDetail({ reputeId: data.repute_id }))
        this.handleCancel()
    }
    render() {
        const { formData, errors } = this.state
        return (
            <React.Fragment>
                <Form  autocomplete="off" >
                    <FormGroup >

                        <Input type="textarea" placeholder="Reason..." onChange={this.handleChange} value={formData.reason} name="reason" />
                        <FormFeedback>
                            {errors[`reason`] && errors[`reason`]}
                        </FormFeedback>
                    </FormGroup>
                    <button className="btn btn-primary " onClick={this.handelSubmit}> Submit </button>
                </Form>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {

    return {
    };

}

const connectedAddReason = connect(mapStateToProps)(AddReason);
export { connectedAddReason as AddReason }

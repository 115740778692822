import { businessAccountsConstants } from "../constants/businessAccounts.constants";

export function businessAccounts(state = {}, action) {
  switch (action.type) {
    case businessAccountsConstants.GET_BUSINESS_ACC_DATA_REQUEST:
      let loading = true;
      if (action.params && action.params.isLoaded === true) {
        loading = false;
      }
      return { ...state, loading: loading };
    case businessAccountsConstants.GET_BUSINESS_ACC_DATA_SUCCESS:
      // console.log(ac tion.result);
      let data = {};
      if (action.params && action.params.from_update === true) {
        data = { loading: false };
      } else {
        data = { ...state, loading: false };
      }
      let retult = action.result;

      if (retult.businessAccounts) {
        data.businessAccounts = retult.businessAccounts;
      }

      if (retult.businessDetails) {
        data.businessDetails = retult.businessDetails;
      }

      if (retult.communicationMasterTemplates) {
        data.communicationMasterTemplates = retult.communicationMasterTemplates;
      }

      if (retult.individualDetails) {
        data.individualDetails = retult.individualDetails;
      }
      
      if (retult.currentStageJson) {
        data.currentStageJson = retult.currentStageJson;
      } else {
        data.currentStageJson = false;
      }

      if (retult.reportingUsers) {
        data.reportingUsers = retult.reportingUsers;
      }

      if (retult.company_repute_id) {
        data.company_repute_id = retult.company_repute_id;
      }

      if (retult.total_records) {
        data.total_records = retult.total_records;
      }

      return data;
    case businessAccountsConstants.GET_BUSINESS_ACC_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

export function getDataFromReputeId(state = {}, action) {
  switch (action.type) {
    case businessAccountsConstants.GET_DATA_FROM_RI_REQUEST:
      return { ...state, loading: true };
    case businessAccountsConstants.GET_DATA_FROM_RI_SUCCESS:
      return { data: action.result };
    case businessAccountsConstants.GET_DATA_FROM_RI_FAILURE:
      return {};
    default:
      return state;
  }
}
export function getBlockBusiness(state = {}, action) {
  switch (action.type) {
    case businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_REQUEST:
      return { ...state, loading: true };
    case businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_SUCCESS:
      return { data: action.result };
    case businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}


export function sectors(state = {}, action) {
  switch (action.type) {
    case businessAccountsConstants.GET_SECTORS_REQUEST:
      return { ...state, loading: true };
    case businessAccountsConstants.GET_SECTORS_SUCCESS:
      return {sectors : action.result};
    case businessAccountsConstants.GET_SECTORS_FAILURE:
      return {};
    default:
      return state;
  }
}


export function states(state = {}, action) {
  switch (action.type) {
    case businessAccountsConstants.GET_STATES_REQUEST:
      return { ...state, loading: true };
    case businessAccountsConstants.GET_STATES_SUCCESS:
      return {states : action.result};
    case businessAccountsConstants.GET_STATES_FAILURE:
      return {};
    default:
      return state;
  }
}
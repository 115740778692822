import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  CardBody, Card } from 'reactstrap'
import { CustomerForm } from './CustomerForm'
class AddCustomer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


    render() {
        return (
            <Card>
                <CardBody>
                    <h3 className="text-center mb-4 mt-4"> Add Customer Details </h3>
                    <CustomerForm />
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedAddCustomer = connect(mapStateToProps)(AddCustomer)
export { connectedAddCustomer as AddCustomer }

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink , Badge } from 'reactstrap';
import { MarketingActions } from '../../actions/marketing.action';
import { useEffect } from 'react';

const WhatsappAnalysis = (props) => {
    const [count, setCount] = useState('');

    useEffect(() => {
        const { dispatch } = props;
        dispatch(MarketingActions.WhatsappStopCount(setCount))
    }, []);
    return (
        <div>
            <Row>
                <Col xs="12">
         
           
                        {count &&
                            <table className="table table-hover table-striped text-center text-uppercase">
                                <thead>
                                    <tr >
                                        <th></th>
                                        <th>Today</th>
                                        <th>Week</th>
                                        <th>Month </th>
                                        <th>Year</th>
                                        <th>LifeTime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        count && count.whatsapp_optout &&
                                        <tr>
                                            <td><Badge bg="danger" style={{backgroundColor : 'red'}}>STOP</Badge></td>
                                            <td>{count.whatsapp_optout.today}</td>
                                            <td>{count.whatsapp_optout.week}</td>
                                            <td>{count.whatsapp_optout.month}</td>
                                            <td>{count.whatsapp_optout.year}</td>
                                            <td>{count.whatsapp_optout.lifetime}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                       }
            
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}


const connectedWhatsappAnalysis = connect(mapStateToProps)(WhatsappAnalysis)
export { connectedWhatsappAnalysis as WhatsappAnalysis }
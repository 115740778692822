export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`question`]) {
        formIsValid = false
        errors[`question`] = 'Can Not be empty'
    }

    if (!fields[`answer`]) {
        formIsValid = false
        errors[`answer`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card } from 'reactstrap'
import { CompanySectorForm } from './CompanySectorForm'
import { CompanySectorAction } from '../../actions/companySector.action'
import { TableLoader } from '../../../components/Loaders';

class EditCompanySector extends Component {
    constructor(props) {
        super(props)
        this.getCompanySectorData = this.getCompanySectorData.bind(this)
    }
    componentDidMount() {
        this.getCompanySectorData()
    }
    getCompanySectorData() {
        const values = this.props.match.params.id
        let dataToSend = {
            id: values
        }
        const { dispatch } = this.props;
        dispatch(CompanySectorAction.getCompanySectorData(dataToSend));
    }
    render() {
        const { loading, data } = this.props

        return (
            <Card>
                <CardBody>
                    <h3 className="text-center mb-4 mt-4"> Edit Company Sector </h3>
                    {
                        loading ? <TableLoader /> :
                            (data ? 
                            <CompanySectorForm detail={data}></CompanySectorForm> :  <div className="text-center h4  m-5 p-6"> <i className="fa fa-exclamation-triangle text-danger"></i> Sector Not found. </div>)
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getCompanySectorDetail
    return {
        loading,
        data
    }

}

const connectedEditCompanySector = connect(mapStateToProps)(EditCompanySector)
export { connectedEditCompanySector as EditCompanySector }

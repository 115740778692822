import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { ManageCommissionSlabForm } from './ManageCommissionSlabForm';
import { LAST_AMOUNT } from '../../../constants';



class ManageCommissionSlab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			data:  [ { min: 0, max: LAST_AMOUNT, commission_rate: 0 } ],
			errors: []
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.mapAndSetData = this.mapAndSetData.bind(this);
		this.onAddNewSlab = this.onAddNewSlab.bind(this);
	}

	componentDidMount() {
		// this.mapAndSetData(this.props.CommissionSlab, this.props.errors);
	}

	componentWillReceiveProps(props) {
		this.mapAndSetData(props.CommissionSlab, props.errors);
	}

	mapAndSetData(CommissionSlab, errors) {
		let { data } = this.state;

		if (CommissionSlab && CommissionSlab.length) {
			if (this.state.loaded === false) {
				let new_data = [];
				CommissionSlab.map(function(eachData, i) {
					new_data.push({
						min: eachData.min,
						max: eachData.max,
						commission_rate: eachData.commission_rate
					});
					return false;
				});
				data = new_data;
				this.setState({ data, loaded: true });
			}
		}

		if (errors) {
			this.setState({ errors });
		}
	}

	handleChange(e, index) {
		e.preventDefault();
		const { data } = this.state;
		const { name, value } = e.target;

		if (name === 'min') {
			data[index].min = value;

			if (index === data.length - 1) {
				data[index].max = LAST_AMOUNT;
			}

			if (value) {
				data[index - 1].max = parseFloat(value) - 1;
			}
		} else {
			data[index][name] = value;
		}

		this.setState(
			{
				data
			},
			() => {
				this.props.update(data);
			}
		);
	}

	handleDelete(e, index) {
		e.preventDefault();
		const { data } = this.state;

		if (data.length <= 1) {
			alert('atleast one data required.');
			return false;
		}

		data.map(function(eachData, i) {
			if (index === i) {
				if (data.length && index === 0) {
					data[i + 1].min = 0;
				} else if (data.length - 1 === index) {
					data[i - 1].max = LAST_AMOUNT;
				} else {
					data[i - 1].max = parseFloat(data[i + 1].min) - 1;
				}
				data.splice(i, 1);
			}
		});

		this.setState({
			data
		});

		this.props.update(data);
	}

	onAddNewSlab() {
		const { data } = this.state;

		let last = data[data.length - 1];
		data[data.length - 1].max = parseFloat(data[data.length - 1].min) + 1;

		data.push({
			min: parseFloat(data[data.length - 1].max) + 1,
			max: LAST_AMOUNT,
			commission_rate: 0
		});

		this.setState({
			data
		});
		this.props.update(data);
	}

	render() {
		const { data, type, errors } = this.state;

		let _this = this;
		return (
			<React.Fragment>
				
				<div className="table-responsive-sm  animated fadeIn">
				<table className="table">
					<tr>
						<th>Sr.</th>
						<th>Min Amount</th>
						<th> Max Amount </th>
						<th> Commission Percentage </th>
						<th> Action </th>
					</tr>

					{data.map(function(eachData, index) {
						return (
							<ManageCommissionSlabForm
								key={index}
								index={index}
								maxCount={data.length}
								formData={eachData}
								errors={errors[index] || {}}
								handleChange={_this.handleChange}
								handleDelete={_this.handleDelete}
							/>
						);
					})}

					<tr>
						<td colSpan="5">
							<Button color="success" onClick={this.onAddNewSlab}>
								{' '}
								Add new slab{' '}
							</Button>{' '}
							&nbsp;
						</td>
					</tr>
				</table>
					</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	// const { data, fetching } = state.getCommissionSlab
	return {};
}

const connectedManageCommissionSlab = withRouter(connect(mapStateToProps)(ManageCommissionSlab));
export { connectedManageCommissionSlab as ManageCommissionSlab };

import React, { Component } from 'react';

import { connect } from "react-redux";
import { Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { show } from 'redux-modal';
import FormHelpText from '../../../components/Messages/FormHelpText';
import { businessAccountsActions } from '../../actions';

class Msg91BlockCsvModal extends Component {

    constructor(props) {
		super(props);
		this.state = {
			data :{
				sector : ''
			},
			errors: {}
		};
		this.validate = this.validate.bind(this);
		this.uploadCSV = this.uploadCSV.bind(this);
		this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
		this.uploadFormData = this.uploadFormData.bind(this);
		this.handelSubmit = this.handelSubmit.bind(this);
	}

    
	fileSelectedHandler(event) {
		if (event.target && event.target.files[0]) {
			this.setState({
				message: event.target.files[0].name,
				selectedFile: event.target.files[0],
				FileSize: event.target.files[0].size
			});
		}
	}

    
	handelSubmit(e) {
		e.preventDefault();
		const { dispatch } = this.props;
		const { selectedFile, data } = this.state;
		if (!this.validate()) {
			let dataToSend = {
				sector: data.sector,
				csv_file: selectedFile
			};

			// return false;
			
			dispatch(businessAccountsActions.uploadCsvForMSG91FailedReason(dataToSend, this));
		}
	}

    validate() {
		const { selectedFile, errors, data } = this.state;
        let error = false;
        errors.selectedFile="";
		// enter sector
		 if (!selectedFile) {
            errors.selectedFile = "Please Select CSV file";
            error = true;
            alert(errors.selectedFile);
        }   else if( !selectedFile.name.toLowerCase().endsWith('.csv')) {
            errors.selectedFile = "Please Select CSV file";
            error = true;
            alert(errors.selectedFile);
        }
        this.setState({errors});
		return error;
	}

    uploadFormData() {
        const { data, errors } = this.state;
        return (
            <React.Fragment>
                <Form autocomplete="off" onSubmit={this.handelSubmit}>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Input
                                    placeholder="Uplod File"
                                    className="form-control"
                                    type="file"
                                    name="selectedFile"
                                    onChange={this.fileSelectedHandler}
                                />
                                {errors[`selectedFile`] && <FormFeedback key="selectedFile"> {errors[`selectedFile`]}</FormFeedback>}
                            </FormGroup>
                            <FormHelpText> CSV file must be in spedifed format. Download Format </FormHelpText>
                        </Col>
                    </Row>
                    <button className="btn btn-primary btn-sm pull-right"> Upload </button>
                </Form>
            </React.Fragment>
        );
    }
    uploadCSV() {
        const { dispatch } = this.props;
        dispatch(
            show('bootstrap', {
                ModelSize: 'md',
                ModelHeaderText: 'Upload CSV',
                ModelBodyText: this.uploadFormData(),
                isFooter: false
            })
        );
    }
    render() {
        return (
            <button
                className="btn btn-primary btn-sm pull-right"
                style={{ 'marginLeft': '5px' }}
                onClick={(e) => this.uploadCSV()}
            >
                {' '}
                Upload CSV{' '}
            </button>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
  }
  
  const connected = connect(mapStateToProps)(Msg91BlockCsvModal);
  export { connected as Msg91BlockCsvModal };


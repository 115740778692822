import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reputeinfoBalancePackageActions } from "../../actions";
import { CardBody, Card, CardHeader, Button } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";
import { UpdateWeightage } from './UpdateWeightage'

class ProfileWeightageSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getBalanceSetting = this.getBalanceSetting.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
        this.getBalanceSetting()
    }

    getBalanceSetting() {
        const { dispatch } = this.props;
        dispatch(reputeinfoBalancePackageActions.getProfileWeightageSetting());
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <UpdateWeightage editData={row} getBalanceSetting={this.getBalanceSetting} />
        </React.Fragment>
    }
    renderVisibity(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            {row.visibility == 1 ? <span className='badge badge-success'>Visible</span> : <span className='badge badge-danger'>Not Visible</span>}
        </React.Fragment>
    }
    render() {
        const { settings, loading } = this.props
        const columns = [
            {
                text: 'Key',
                dataField: 'key',
                sort: true
            },
            {
                text: 'Value',
                dataField: 'value',
                sort: true
            },
            {
                text: 'Visiblity',
                dataField: 'visibility',
                formatter: this.renderVisibity
            },
            {
                text: 'Action',
                dataField: 'action',
                formatter: this.renderAction

            }]
        return (
            <div className='row'>
                <div className='col-6'>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-users"></i><strong>Individual Profile Weightage Settings</strong>
                        </CardHeader>
                        <CardBody>
                            {loading ? <TableLoader /> :
                                <div className="">
                                    <DataTable keyField="id"
                                        data={settings && settings.individual || []} columns={columns}
                                        filter={filterFactory()}
                                        noDataIndication={"No data found"} />

                                </div>
                            }
                        </CardBody>
                    </Card>
                </div>
                <div className='col-6'>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-building"></i><strong>Company Profile Weightage Settings</strong>
                        </CardHeader>
                        <CardBody>
                            {loading ? <TableLoader /> :
                                <div className="">
                                    <DataTable keyField="id"
                                        data={settings && settings.company || []} columns={columns}
                                        filter={filterFactory()}
                                        noDataIndication={"No data found"} />

                                </div>
                            }
                        </CardBody>
                    </Card>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, settings } = state.getProfileWeightageSetting
    const { user } = state.authentication

    console.log('data ---> ', settings);
    return {
        loading,
        settings,
        authUser: user

    }
}

const connectedReputeinfoBalanceSetting = connect(mapStateToProps)(ProfileWeightageSetting)
export { connectedReputeinfoBalanceSetting as ProfileWeightageSetting }

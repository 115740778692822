import config from 'react-global-configuration'
import { axiosAjaxCall, handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const requirementLogService = {
    getRequirementLogs,
    getSellerDetail,
    addBuyerRequirementInvite,
    getBuyerRequirementInvites,
    getUnregisteredSellerDetail,
    updateRequirementLocation,
}

function getBuyerRequirementInvites(data) {
    var form_data = data;

    let setting = {
        headers: authHeader()
    };
    return axios.post(`${config.get('apiUrl')}/get_buyer_requirement_invites`, form_data, setting).then(handleResponse);
}

function getRequirementLogs(data) {
    // return axiosAjaxCall('get_requirement_logs', data);
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_requirement_logs`, form_data, setting).then(handleResponse)
}

function getSellerDetail(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_seller_detail`, form_data, setting).then(handleResponse)
    
}

function addBuyerRequirementInvite(data) {
    return axiosAjaxCall('add_buyer_requirement_invite', data);
}

function getUnregisteredSellerDetail(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    
    return axios.post(`${config.get('apiUrl')}/get_unregistered_seller_detail`, form_data, setting).then(handleResponse)
    
}

function updateRequirementLocation(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    
    return axios.post(`${config.get('apiUrl')}/update_requirement_location`, form_data, setting).then(handleResponse)
    
}
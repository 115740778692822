import React from 'react';
import { Translate } from "react-localize-redux";
import PageNotFound from "../views/PageNotFound";

import { PERMISSIONS } from '../individual/constants';
import { AddBusinessAccount } from '../individual/views/BusinessAccounts/AddBusinessAccount';
import { BusinessAccounts } from '../individual/views/BusinessAccounts/BusinessAccounts';
import { CommunicationMaster } from '../individual/views/Settings/CommunicationMaster/CommunicationMaster';
// Individual Components


// Individual Components
import {
  AddCompanySector, AddCustomer, AddEmail, AddFAQ, AddLegal, AddNotification, AddSMS,AddCommunicationTemplate, AddUser, Association, AssociationIndividualType, AutoReminderCronLogs, BadDebtSearchLogs, ChequeDetail, Company, CompanyDetail, CompanyList, CompanySector, ContactUsDetail, CronLogs, CustomerDetails, CustomerSalesStatus, CustomerSupportSearch, DashBoard, DashboardBusinessAccountReports, EditCompanySector, EditCustomer, EditUser, EmailLogs, Emails, ExceptionLogs, ExpenceStatement, FAQ, ForgetPassword, GroupType, Individual, IndividualDetail, Information, JobTitles, Legal, Login, ManageCommissionSlab, MilestoneLogs, Notifications, ProductTags, Profile, ProfileWeightageSetting,FixedSettings, ReputeinfoBalancePackage, ReputeinfoBalanceSetting, RequirementLogs, SearchLog,
  // AdminContentConfig,
  Setting, SMS, CommunicationTemplate,SMSLogs, Smspackage, SubscriptionLogs, Subscriptions, SupportTemplate, Tasks, Tickets, UnregisteredSeller, UpdateBalancePackage, UpdateSalesStatus, UpdateSmsPackage, UpdateSubscription, UserLoginLogs, Users, UserSalary, UserTypes, ViewInformation, WhatsappSchedule,
  WhatsappSend, UserActivity, WhatsappReceive, FinanceStatus, AccountStatementTab, 
  Expenses,DailyMis,Leads,Products,
  Feedback , VoiceLogs, Videos,  LandingPageAdd, LandingPageList, Promocode, SendBillTrasanction
} from "../individual/views";
import { Marketing } from '../individual/views/Marketing/Marketing';
import { Payments } from '../individual/views/Profile/Payments';
import { RecentRegistrations } from '../individual/views/RecentRegistrations/RecentRegistrations';
import { TicketsOld } from '../individual/views/Support/TicketsOld';
import { Departments } from '../individual/views/UserManagement/Departments/Departments';
import MarketingTab from '../individual/views/Marketing/Tab';

var indexRoutes = [

  // Users
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/tasks", name: 'Tasks', component: Tasks, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/marketing", name: 'Markting', component: MarketingTab, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/business-account", name: 'Business Accounts', component: BusinessAccounts, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/business-account/add", name: 'Business Details', component: AddBusinessAccount, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/business-account/edit/:businessId", name: 'Business Details', component: AddBusinessAccount, private: true },
  { permission: PERMISSIONS.SUPER_ADMIN_SETTING, path: "/business-account-template", name: 'Business Account Template', component: Setting, private: true },
  { permission: PERMISSIONS.SUPER_ADMIN_SETTING, path: "/communication-template", name: 'Business Account Template', component: CommunicationMaster, private: true },
  { permission: PERMISSIONS.SUPER_ADMIN_SETTING, path: "/support-template", name: 'Support Template', component: SupportTemplate, private: true },

  // old


  // Users
  { main: true, permission: PERMISSIONS.DASHBOARD, path: "/dashboard", name: <Translate id='Dashboard' />, component: DashBoard, private: true },
  { main: true, permission: PERMISSIONS.SEND_BILL_TRANSACTION, path: "/send-bill-trasanction", name:'SendBillTrasanction', component:SendBillTrasanction, private: true },
  { main: true, permission: PERMISSIONS.DAILY_MIS, path: "/daily-mis", name:'DailyMis', component: DailyMis, private: true },
  { main: true, permission: PERMISSIONS.EXPENSES, path: "/expenses", name:'Expenses', component: Expenses, private: true },
  { main: true, permission: PERMISSIONS.LEADS, path: "/leads", name:'Leads', component: Leads, private: true },
  { main: true, permission: PERMISSIONS.PRODUCTS, path: "/products", name:'Products', component: Products, private: true },
  { main: true, permission: PERMISSIONS.PROMOCODE, path: "/promo-code", name:'PromoCode', component: Promocode, private: true },
  { main: true, permission: PERMISSIONS.DASHBOARD_REPORTS, path: "/dashboard-reports", name: <Translate id='Company' />, component: DashboardBusinessAccountReports, private: true },
  { main: true, permission: PERMISSIONS.LANDING_PAGE_ADD, path: "/landing-page/:action", name: 'Landing Page', component: LandingPageAdd, private: true },
  { main: true, permission: PERMISSIONS.LANDING_PAGE_LIST, path: "/landing-page", name: 'Landing Page', component: LandingPageList, private: true },
  { main: true, permission: PERMISSIONS.RECENT_REGISTRATIONS, path: "/recent-registrations", name: <Translate id='Company' />, component: RecentRegistrations, private: true },
  { main: true, permission: PERMISSIONS.COMPANY, path: "/verification/company", name: <Translate id='Company' />, component: Company, private: true },
  { permission: PERMISSIONS.COMPANY, path: "/verification/company/:reputeId", name: <Translate id='Company' />, component: CompanyDetail, private: true },
  { main: true, permission: PERMISSIONS.INDIVIDUAL, path: "/verification/Individual", name: <Translate id='Individual' />, component: Individual, private: true },
  { permission: PERMISSIONS.INDIVIDUAL, path: "/verification/Individual/:reputeId", name: <Translate id='Individual' />, component: IndividualDetail, private: true },
  { main: true, permission: PERMISSIONS.LEGAL, path: "/legal", name: 'Legal', component: Legal, private: true },
  { permission: PERMISSIONS.LEGAL, path: "/legal/edit/:legalId", name: 'Legal', component: AddLegal, private: true },
  { permission: PERMISSIONS.ADD_USER, path: "/user-management/edit-user/:userId", name: 'Add User', component: EditUser, private: true },
  { permission: PERMISSIONS.ADD_USER, path: "/user-management/add-user", name: 'Add User', component: AddUser, private: true },
  { main: true, permission: PERMISSIONS.LIST_USER, path: "/user-management/users", name: 'Users', component: Users, private: true },
  { permission: PERMISSIONS.USER_TYPES, path: "/user-management/users-type", name: 'Users Type', component: UserTypes, private: true },
  { permission: PERMISSIONS.USER_TYPES, path: "/user-management/departments", name: 'Departments', component: Departments, private: true },
  { permission: PERMISSIONS.USER_TYPES, path: "/user-management/job-titles", name: 'Job Titles', component: JobTitles, private: true },
  { main: true, permission: PERMISSIONS.EMAIL, path: "/emails", name: 'Emails', component: Emails, private: true },
  { permission: PERMISSIONS.EDIT_EMAIL, path: "/emails/edit/:id", name: 'Emails', component: AddEmail, private: true },
  { permission: PERMISSIONS.COMMUNICATION_TEMPLATE, path: "/communication_template", name: 'Communication template', component: CommunicationTemplate, private: true },
  { permission: PERMISSIONS.SMS, path: "/sms", name: 'SMS', component: SMS, private: true },
  { permission: PERMISSIONS.SMS, path: "/sms/update_sms/:id", name: 'Add SMS', component: AddSMS, private: true },
  { permission: PERMISSIONS.COMMUNICATION_TEMPLATE, path: "/communication_template/update_communication_template/:id", name: 'Add communication template', component: AddCommunicationTemplate, private: true },
  { permission: PERMISSIONS.NOTIFICATIONS, path: "/notification/update_notification/:id", name: 'Add Notification', component: AddNotification, private: true },

  { main: true, permission: PERMISSIONS.CUSTOMER_SALES_STATUS, path: "/customer-sales-status", name: "customer sales status", component: CustomerSalesStatus, private: true },
  { permission: PERMISSIONS.CUSTOMER_SALES_STATUS, path: "/customer-sales-status/:customerId", name: "customer sales status update", component: UpdateSalesStatus, private: true },
  { main: true, permission: PERMISSIONS.CUSTOMER_SUPPORT, path: "/customer-support", name: "customer support", component: CustomerSupportSearch, private: true },
  { main: true, permission: PERMISSIONS.TICKETS, path: "/tickets-old", name: 'Tickets', component: TicketsOld, private: true },
  { main: true, permission: PERMISSIONS.TICKETS, path: "/tickets", name: 'Tickets', component: Tickets, private: true },
  { permission: PERMISSIONS.NOTIFICATIONS, path: "/notification/notifications", name: 'Notifications', component: Notifications, private: true },
  { permission: PERMISSIONS.LEGAL, path: "/legal", name: 'Legal', component: Legal, private: true },
  { permission: PERMISSIONS.LEGAL_MODIFY, path: "/legal/edit/:legalId", name: 'Legal', component: AddLegal, private: true },
  { permission: PERMISSIONS.SUBSCRIPTION, path: "/subscriptions", name: 'Subscriptions', component: Subscriptions, private: true },
  { permission: PERMISSIONS.SUBSCRIPTIONS, path: "/subscriptions/edit/:id", name: 'Update Subscriptions', component: UpdateSubscription, private: true },
  { permission: PERMISSIONS.CUSTOMERDETAIL, path: "/customer-detail", name: 'CustomerDetails', component: CustomerDetails, private: true },
  { permission: PERMISSIONS.NEW_CUSTOMER, path: "/customer-detail/add", name: 'AddCustomer', component: AddCustomer, private: true },
  { permission: PERMISSIONS.EDIT_CUSTOMER_DETAIL, path: "/customer-detail/edit/:id", name: 'EditCustomer', component: EditCustomer, private: true },
  { main: true, permission: PERMISSIONS.SUPPORT, path: "/support", name: <Translate id='Dashboard' />, component: DashBoard, private: true },
  { main: true, permission: PERMISSIONS.NOTIFICATIONS, path: "/notification/notifications", name: 'Notifications', component: Notifications, private: true },
  { main: true, permission: PERMISSIONS.LIST_FAQ, path: "/FAQ", name: 'FAQ', component: FAQ, private: true },
  { permission: PERMISSIONS.ADD_FAQ, path: "/FAQ/AddFAQ", name: 'Add FAQ', component: AddFAQ, private: true },
  { permission: PERMISSIONS.EDIT_FAQ, path: "/FAQ/Edit/:faqId", name: 'Edit FAQ', component: AddFAQ, private: true },
  { main: true, permission: PERMISSIONS.SUBSCRIPTION, path: "/subscriptions", name: 'Subscriptions', component: Subscriptions, private: true },
  { permission: PERMISSIONS.SUBSCRIPTION_DETAILS, path: "/subscriptions/edit/:id", name: 'Update Subscriptions', component: UpdateSubscription, private: true },
  { permission: PERMISSIONS.COMPANY_SECTOR_LIST, path: "/company-sector", name: 'CompanySector', component: CompanySector, private: true },
  { permission: PERMISSIONS.ADD_COMPANY_SECTOR, path: "/company-sector/add", name: 'AddCompanySector', component: AddCompanySector, private: true },
  { permission: PERMISSIONS.EDIT_COMPANY_SECTOR, path: "/company-sector/edit/:id", name: 'EditCompanySector', component: EditCompanySector, private: true },
  { permission: PERMISSIONS.SMS_PACKAGE, path: "/sms-packages", name: 'sms_package', component: Smspackage, private: true },
  { permission: PERMISSIONS.SMS_PACKAGE, path: "/sms-packages/edit/:id", name: 'Edit_sms_package', component: UpdateSmsPackage, private: true },
  { permission: PERMISSIONS.ACCOUNT_LIST, path: "/income-statement", name: 'Income Statement', component: AccountStatementTab, private: true },
  { permission: PERMISSIONS.USERS_SALARY, path: "/users-commission-report", name: 'Users Commission Report', component: UserSalary, private: true },
  { permission: PERMISSIONS.CHEQUE_DETAIL, path: "/cheque-detail", name: 'Cheque Detail', component: ChequeDetail, private: true },
  { permission: PERMISSIONS.COMPANY_LIST, path: "/company-list", name: 'Company List', component: CompanyList, private: true },
  { permission: PERMISSIONS.MANAGE_COMMISSION_SLAB, path: "/manage-commission-slab", name: 'Manage Commission Slab', component: ManageCommissionSlab, private: true },
  { permission: PERMISSIONS.EXCEPTION_LOGS, path: "/exception-logs", name: 'ExceptionLogs', component: ExceptionLogs, private: true },
  { permission: PERMISSIONS.EXCEPTION_LOGS, path: "/critical-due-logs", name: 'BadDebtSearchLogs', component: BadDebtSearchLogs, private: true },
  { permission: PERMISSIONS.LOGIN_LOGS, path: "/user-login-logs", name: 'LoginLogs', component: UserLoginLogs, private: true },
  { permission: PERMISSIONS.LOGIN_LOGS, path: "/cron-logs", name: 'CronLogs', component: CronLogs, private: true },
  { permission: PERMISSIONS.SMS_LOGS, path: "/sms-logs", name: 'SMS Logs', component: SMSLogs, private: true },
  { permission: PERMISSIONS.SMS_LOGS, path: "/voice-logs", name: 'Voice Logs', component: VoiceLogs, private: true },
  { permission: PERMISSIONS.SEARCH_LOGS, path: "/search-logs", name: 'Search Logs', component: SearchLog, private: true },
  // { permission: PERMISSIONS.ADMIN_CONTENT_CONFIG, path: "/admin-content-config", name: 'AdminContentConfig', component: AdminContentConfig, private: true },
  // { permission: PERMISSIONS.UPDATE_ADMIN_CONTENT_CONFIG,  path: "/setting", name: 'Setting', component: Setting, private: true },
  { permission: PERMISSIONS.AUTO_REMINDER_CRON_LOGS, path: "/auto-reminder-cron-logs", name: 'Auto Reminder Cron Log', component: AutoReminderCronLogs, private: true },

  { permission: PERMISSIONS.SUBSCRIPTION_LOGS, path: "/subscription-logs", name: 'SubscriptionLogs', component: SubscriptionLogs, private: true },
  { permission: PERMISSIONS.CONTACT_US_DETAIL, path: "/contactus-detail", name: 'ContactUsDetail', component: ContactUsDetail, private: true },
  { permission: PERMISSIONS.FEEDBACK, path: "/feedback-detail", name: 'Feedback', component: Feedback, private: true },
  { permission: PERMISSIONS.VIDEOS, path: "/video-detail", name: 'Videos', component: Videos, private: true },
  { permission: PERMISSIONS.EMAIL_LOGS, path: "/email-logs", name: 'EmailLogs', component: EmailLogs, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/whatsapp/receive", name: 'WhatsappReceive', component: WhatsappReceive, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/whatsapp/send", name: 'WhatsappSend', component: WhatsappSend, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/whatsapp/schedule", name: 'WhatsappSchedule', component: WhatsappSchedule, private: true },
  { permission: PERMISSIONS.USER_ACTIVITY, path: "/user_activity", name: 'UserActivity', component: UserActivity, private: true },
  { permission: PERMISSIONS.FINANCE_STATUS, path: "/finance_status", name: 'FinanceStatus', component: FinanceStatus, private: true },
  { permission: PERMISSIONS.MILESTONE_LOGS, path: "/milestone-logs", name: 'MilestoneLogs', component: MilestoneLogs, private: true },
  { permission: PERMISSIONS.PRODUCT_TAGS, path: "/product-tags", name: 'ProductTags', component: ProductTags, private: true },
  { permission: PERMISSIONS.REQUIREMENTS, path: "/requirements", name: 'RequirementLogs', component: RequirementLogs, private: true },
  { permission: PERMISSIONS.UNREGISTERED_SELLER, path: "/unregistered-seller", name: 'UnregisteredSeller', component: UnregisteredSeller, private: true },

  { permission: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE, path: "/reputeinfo-balance", name: 'ReputeinfoBalancePackage', component: ReputeinfoBalancePackage, private: true },
  { permission: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE, path: "/reputeinfo-balance/edit/:id", name: 'UpdateBalancePackage', component: UpdateBalancePackage, private: true },
  { permission: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE, path: "/reputeinfo-balance-setting", name: 'ReputeinfoBalanceSetting', component: ReputeinfoBalanceSetting, private: true },
  { permission: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE, path: "/reputeinfo-profile-weightage-setting", name: 'ReputeinfoProfileWeightageSetting', component: ProfileWeightageSetting, private: true },

  { permission: PERMISSIONS.PROFILE, path: "/profile", name: 'Profile', component: Profile, private: true },
  { permission: PERMISSIONS.ASSOCIATION_INDIVIUAL_TYPE, path: "/association/indviual-type", name: 'AssociationIndividualType', component: AssociationIndividualType, private: true },
  { permission: PERMISSIONS.GROUP, path: "/groups", name: 'Association', component: Association, private: true },
  { permission: PERMISSIONS.GROUP_TYPE, path: "/group-type", name: 'GroupType', component: GroupType, private: true },
  { permission: PERMISSIONS.BUSINESS_ACCOUNT, path: "/payments", name: 'Association', component: Payments, private: true },
  { permission: PERMISSIONS.ExpenceStatement, path: "/expense-type", name: 'ExpenceStatement', component: ExpenceStatement, private: true },
  { permission: PERMISSIONS.INFORMATION, path: "/information", name: 'Information', component: Information, private: true },
  { permission: PERMISSIONS.FIXED_SETTINGS, path: "/settings", name: 'Settings', component: FixedSettings, private: true },

  // Guest
  { path: "/view-information", name: 'Information', component: ViewInformation },
  { path: "/forget-password", name: <Translate id='Forget Password' />, component: ForgetPassword },
  { path: "/login", name: <Translate id='Login' />, component: Login },
  { path: "/", name: <Translate id='Home' />, component: Login },
  { path: "/*", name: <Translate id='pageNotFound' />, component: PageNotFound }
]

export default indexRoutes

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormFeedback, Input } from "reactstrap";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import Pagination from "react-js-pagination";
import { TableLoader } from "../../../components/Loaders";
import { leadsAction } from "../../actions";
import { CardBody, Col, Row } from "reactstrap";
import Detail from "./Detail";

class DetailList extends Component {
  constructor(props) {
    super(props);

    let days = new Date().getDate();
    this.state = {
      // startDate: moment().subtract(days, 'days'),
      startDate: moment(),
      endDate: moment(),
      serachField: {
        dateRange: "",
      },
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      SearchKey: "",
      errors: {},
      columns: "",
      csv_data: [],
      modalOpan: false,
      is_disable: true,
      isDateChanged: false,
      page: {
        activePage: 1,
        perPage: 50,
        totalItemsCount: 0,
      },
      modalOpan: false,
    };
    this.componentRef = "";
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan }, () => {
      if (modalOpan == false) {
        // this.getCommissionData();
      }
    });
  }

  componentDidMount() {
    const { page } = this.state;
    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
    };

    this.getData(dataToSend);
  }

  handleEvent(event, picker) {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + " to " + end;
    if (start === end) {
      label = start;
    }
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label,
      },
      errors: {},
      isDateChanged: true,
    });
  }

  getData() {
    const { dispatch } = this.props;
    const { page, keywords, startDate, endDate, isDateChanged } = this.state;

    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
      // keywords: keywords,
    };
    if (isDateChanged) {
      dataToSend[`start_date`] = startDate.format("YYYY-MM-DD");
      dataToSend[`end_date`] = endDate.format("YYYY-MM-DD");
    }
    dispatch(leadsAction.getLeads(dataToSend));
  }

  handleFetch() {
    this.setState(
      {
        active_page: 1,
      },
      () => this.getData()
    );
  }

  handlePageChange(pageNumber) {
    const { page } = this.state;
    page.activePage = pageNumber;
    this.setState({ page }, () => this.getData());
  }
  componentWillReceiveProps(props) {
    let { page, activeTab } = this.state;
    page.totalItemsCount = 0;
    if (props.data) {
      page.totalItemsCount = props.data.pagination.total;
    }
    if (props.activeTab) {
      activeTab = props.activeTab;
    }
    this.setState({
      page,
      activeTab,
    });
  }

  handleReset() {
    let days = new Date().getDate() - 1;

    this.setState(
      {
        ...this.state,
        startDate: moment().subtract(days, "days"),
        endDate: moment(),
        serachField: {
          dateRange: "",
        },
        keywords: "",
        isDateChanged: false,
      },
      () => this.getData()
    );
  }

  render() {
    let { loading, data } = this.props;
    const { page, serachField, errors } = this.state;

    return (
      <div>
        <Row>
          <Col xs="12">
            <CardBody>
              <div>
                <Form autocomplete="off">
                  <div className="form-group">
                    <div className="d-flex mb-2">
                      <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onEvent={this.handleEvent}
                        ranges={this.state.ranges}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Date Range"
                            value={serachField.dateRange}
                            id="date_picker"
                            style={{ width: "220px" }}
                          />
                          <span className="input-group-btn">
                            <Button
                              className="default date-range-toggle"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fa fa-calendar" />
                            </Button>
                          </span>
                        </div>
                      </DateRangePicker>

                      <Button
                        color="primary"
                        onClick={this.handleFetch}
                        style={{ "margin-left": "10px" }}
                      >
                        Fetch
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.handleReset}
                        style={{ "margin-left": "10px" }}
                      >
                        Reset
                      </Button>
                      <span
                        style={{ fontSize: 20 }}
                        className="mx-4  mt-auto ml-auto"
                      >
                        <b>
                          Total :{" "}
                          <span className="bg-success mx-2 px-2 rounded  ">
                            {" "}
                            {data && data.pagination.total}
                          </span>
                        </b>
                      </span>
                    </div>
                    <FormFeedback>
                      {errors[`dateRange`] && errors[`dateRange`]}
                    </FormFeedback>
                  </div>
                </Form>

                {loading ? (
                  <TableLoader />
                ) : (
                  <div
                    ref={(el) => (this.componentRef = el)}
                    className="table-responsive-sm  animated fadeIn"
                  >
                    <table className="table table-sm table-condensed  table-bordered  table-outline ">
                      <thead className="thead-light">
                        <tr>
                          <th>No.</th>
                          <th>Name of product company</th>
                          <th>Name of product lead company</th>
                          <th>Customer name</th>
                          <th>Email address</th>
                          <th>Phone number</th>
                          <th>Created_at</th>
                          <th>Source</th>
                          <th>Products inquired</th>
                          <th style={{ width: "15%" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.data && data.data.length > 0 ? (
                          data.data.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {(page.activePage - 1) * page.perPage +
                                    (i + 1)}
                                </td>
                                <td>{d.name_of_product_company || "-"}</td>
                                <td>{d.company_name || "-"}</td>
                                <td>{d.name || "-"}</td>
                                <td>{d.email || "-"}</td>
                                <td>{d.phone || "-"}</td>
                                <td>{d.created_at || "-"}</td>
                                <td>-</td>
                                <td>
                                  {d.lead_inquiry_products &&
                                  d.lead_inquiry_products.length
                                    ? d.lead_inquiry_products.map(
                                        (product, index) => (
                                          <span key={index}>
                                            {product.product &&
                                            product.product.product_name
                                              ? `${product.product.product_name}`
                                              : "Unknown Product"}
                                            {index !==
                                            d.lead_inquiry_products.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        )
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  <Detail data={d} index={i} />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="10"
                              className="text-center"
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                              }}
                            >
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    {page.totalItemsCount <= page.perPage ? (
                      ""
                    ) : (
                      <Pagination
                        activePage={page.activePage}
                        itemsCountPerPage={page.perPage}
                        totalItemsCount={page.totalItemsCount}
                        pageRangeDisplayed={5}
                        linkClass="page-link"
                        itemClass="page-item"
                        onChange={this.handlePageChange}
                      />
                    )}
                  </div>
                )}
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getLeads;
  console.log(data, "data");
  console.log("loading", loading);
  return {
    loading,
    data,
  };
}
const connected = connect(mapStateToProps)(DetailList);
export { connected as DetailList };



import React, { Component } from 'react';
import { connect } from 'react-redux';
import { whatsAppLogs } from '../../../actions';
import { Card, CardBody, CardHeader, Input, Label, Button } from 'reactstrap';
import { TableLoader } from '../../../../components/Loaders';
import Pagination from "react-js-pagination";


const defaultState = {
    page: {
        perPage: 20,
        totalItemsCount: 0,
        activePage: 1
    },
    number: "",
};

class WhatsappSendRI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: {
                perPage: 20,
                totalItemsCount: 0
            },
            number: "",
        };
        this.getWhatsappSendLogsData = this.getWhatsappSendLogsData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getWhatsappSendLogsData();
    }

    getWhatsappSendLogsData(isSearch) {
        const { dispatch } = this.props;
        const { page, number } = this.state;
        let dataToSend = {

            per_page: page.perPage,
            keywords: number
        };
        if (isSearch) {
            dataToSend[`active_page`] = 1
        } else {
            dataToSend[`active_page`] = page.activePage
        }

        dispatch(whatsAppLogs.getWhatsappSendLogsRi(dataToSend));
    }

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getWhatsappSendLogsData());
    }

    componentWillReceiveProps(props) {
        let { page, activeTab } = this.state;
        page.totalItemsCount = 0;
        if (props.logs) {
            page.totalItemsCount = props.logs.total_records;
        }
        if (props.activeTab) {
            activeTab = props.activeTab;
        }
        this.setState({
            page,
            activeTab
        });
    }

    handleChange(e) {
        const { value, name } = e.target
        if (/^[0-9]*$/.test(value) !== false) {
            this.setState({ [name]: value });
        }
    }

    reset() {
        this.setState({
            ...defaultState
        }, () => this.getWhatsappSendLogsData())
    }
    
    render() {
        const { logs, loading } = this.props;
        const { page, number } = this.state;
        return (
            <Card>
                <CardHeader>
                    <i className="fas fa-boxes" />
                    <strong>Reputeinfo Whatsapp Send</strong>
                </CardHeader>
                <CardBody>
                    {loading ? (
                        <TableLoader />
                    ) : (
                        <React.Fragment>
                            <div className="w-100 d-flex mb-3">
                                <div className="w-25">
                                    <Label
                                        style={{ textAlign: "left", justifyContent: "left" }}
                                    >
                                        <b>   Mobile </b>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Mobile"
                                        name="number"
                                        value={number}
                                        onChange={this.handleChange}
                                        maxlength={12}
                                    />
                                </div>
                                <div
                                    className="btn p-0"
                                    style={{
                                        alignSelf: "end",
                                    }}
                                >
                                    <Button
                                        onClick={() => this.getWhatsappSendLogsData(true)}
                                        className="btn  ml-3"
                                        color='primary'
                                    >
                                        Fetch
                                    </Button>
                                </div>
                                <div
                                    className="btn p-0"
                                    style={{
                                        alignSelf: "end",
                                    }}
                                >
                                    <Button
                                        onClick={() => this.reset()}
                                        className="btn btn-primary  ml-3"
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </div>
                            <div className="table-responsive-sm  animated fadeIn">
                                <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Mobile</th>
                                            <th>Template</th>
                                            <th>Send At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logs && logs.list && logs.list.length ? (
                                            logs.list.map(function (eachData, index) {
                                                return (
                                                    <React.Fragment key={index} >
                                                        <tr>
                                                            <td>{eachData.mobile}</td>
                                                            <td>{eachData.template}</td>
                                                            <td>{eachData.sent_at}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {page.totalItemsCount <= page.perPage ? (
                                ''
                            ) : (
                                <Pagination
                                    activePage={page.activePage}
                                    itemsCountPerPage={page.perPage}
                                    totalItemsCount={page.totalItemsCount}
                                    pageRangeDisplayed={5}
                                    linkClass="page-link"
                                    itemClass="page-item"
                                    onChange={this.handlePageChange}
                                />
                            )}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getWhatsappLogsRi;
    return {
        loading,
        logs: data
    };
}

const connectedWhatsappSend = connect(mapStateToProps)(WhatsappSendRI);
export { connectedWhatsappSend as WhatsappSendRI };


import React, { Component } from 'react'
import { connect } from 'react-redux'
import {    Col, Row, Button,  Modal, ModalHeader, ModalFooter, ModalBody,     Input} from 'reactstrap';
import { settingsActions } from "../../actions";
class UpdateFixedSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id : '',
                key : '',
                value : '',
            },
            errors: {},
            thisModel: false,
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    handleToggle() {
        const { thisModel } = this.state;

        this.setState({
            thisModel: !thisModel,
        });
        if (thisModel == true) {
            this.reset()
        }
    }
    handleChange(event) {
        const { name, value } = event.target;
        let { formData, errors } = this.state;
        let val = value;
        formData[name] = val
        errors[name] = ''

        // set value to state
        this.setState({ formData, errors },
            () => { }
        );
    }
    isValid() {
        const { formData, errors } = this.state
        let error = false;
        if (!formData.value) {
            error = true
            errors.value = "this field is requited"
        }

        this.setState({ errors })
        return error;
    }

    handelSubmit() {
        if (!this.isValid()) {
            const { formData } = this.state
            const { dispatch } = this.props
            
            let dataToSend = {
                id: formData.id,
                value: formData.value,
            }
            dispatch(settingsActions.editSettings(dataToSend, {
                success: () => this.handleToggle()
            }))
        }
    }
    reset() {
        let { formData } = this.state
        const {  getSettings } = this.props
        formData.id = ''
        formData.key = ''
        formData.value = ''       
        this.setState({ formData, erroes: {} })
        if (typeof getSettings == 'function') {
            getSettings()
        }
    }

    setEditData(editData) {
        let { formData } = this.state
        if (editData) {
            formData.id = editData.id
            formData.key = editData.key
            formData.value = editData.value
                this.setState({ formData })
        }

    }
    componentDidMount() {
        const { editData } = this.props
        if (editData) {
            this.setEditData(editData)
        }
    }

    render() {
        const {  editData } = this.props
        const { formData, thisModel } = this.state

        return (
            <React.Fragment>
                <Button size='sm'  color="primary" onClick={this.handleToggle}>{"Edit"}</Button>&nbsp;
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="md" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> {editData ? "Edit" : "Add"} Fixed Expenses  </ModalHeader>
                    <ModalBody >
                        <Row sm="12">
                            <Col>
                                <Input type="text" value={formData.key} name="type" disabled={"disabled"}  /><br/>
                            </Col>
                        </Row >
                        <Row sm="12">
                            <Col>
                                <Input type="text" value={formData.value} name="value" onChange={this.handleChange}  />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handelSubmit}>Submit </Button>
                        <Button color="secondary" onClick={this.handleToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedUpdateFixedSetting = connect(mapStateToProps)(UpdateFixedSetting);
export { connectedUpdateFixedSetting as UpdateFixedSetting }
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader, Button, FormFeedback, Form, Input } from 'reactstrap';
import Pagination from "react-js-pagination";
import { accountAction } from '../../actions'
import { TableLoader } from '../../../components/Loaders';
import { Translate } from "react-localize-redux"
import { accountConstants } from '../../constants';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { handleValidation } from './Validation'
import CsvDownloader from 'react-csv-downloader';
import ExtendPlan from './ExtendPlan';
import UpgradePlan from './UpgradePlan';
import { ReputeIDCompanyInput } from './../../../components/Forms/Inputs';
import ChequePayment from './ChequePayment'
import SubscriptionHistory from './SubscriptionHistory'
import { CompanyProductTags } from './CompanyProductTags'
class CompanyList extends Component {
    constructor(props) {
        super(props)
        let days = new Date().getDate() - 1;
        this.state = {
            startDate: moment().subtract(days, 'days'),
            endDate: moment(),
            serachField: {
                ReputeId: "",
                company_name: "",

            },
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },

            errors: {},
            columns: '',
            csv_data: [],
            modalOpan: false,
            is_disable: true
        }
        //        this.getAccountData = this.getAccountData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        this.handleReset();
    }
    componentWillReceiveProps(props) {
        const { total_records } = props
        let { page } = this.state;

        page.totalItemsCount = total_records
        this.setState({ page })

    }

    handleReset() {
        const { dispatch } = this.props;
        let days = new Date().getDate() - 1;
        this.setState({
            serachField: {
                ReputeId: "",
                company_name: ""
            },
            errors: {}
        });


        dispatch({ type: accountConstants.GET_ACCOUNT_DATA_FAILURE });

    }


    handleChange(event) {
        const { name, value } = event.target;
        const { serachField, errors } = this.state;

        if (name === "company_name") {
            let regex_allowed = /^(?![\s-])[a-zA-Z 0-9]*$/;
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }
        serachField[name] = value;
        errors[name] = "";

        this.setState({ serachField, errors });
    }

    handleSubmit() {
        const { serachField, page } = this.state;
        const { dispatch } = this.props;
        let dataToSend = {
            ReputeId: serachField.ReputeId,
            company_name: serachField.company_name,
            active_page: page.activePage,
            per_page: page.perPage,
            section: 'company_list'
        }
        dispatch(accountAction.getAccountData(dataToSend));

    }

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.handleSubmit());

    }


    render() {
        const { loading, company_list, total_amount_data } = this.props
        const { serachField, errors, columns, csv_data, page } = this.state

        let _this = this;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="fas fa-money-check-alt"></i> <b>Company List</b></CardHeader>
                            <CardBody>

                                <Form autocomplete="off">
                                    <div className="form-group">

                                        <div >
                                            <Row>
                                                <Col sm="2">
                                                    {/* 

                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            //onApply={this.handleApply}
                                            onEvent={this.handleEvent}
                                            ranges={this.state.ranges}
                                        >
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Select Date Range" value={serachField.dateRange} id="date_picker" style={{ 'width': '220px' }} />
                                                <span className="input-group-btn">
                                                    <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                                        <i className="fa fa-calendar" />
                                                    </Button>
                                                </span>
                                            </div>
                                        </DateRangePicker> */}

                                                    <ReputeIDCompanyInput name="ReputeId" value={serachField.ReputeId} handleChange={this.handleChange} />

                                                </Col>
                                                <Col sm="2"><Input type="text" name="company_name" placeholder="Company Name" value={serachField.company_name} onChange={this.handleChange} /></Col>
                                                <Col sm="4">

                                                    <Button color="primary" onClick={(e) => this.handleSubmit()} style={{ 'margin-left': '10px' }}>Fetch</Button>
                                                    <Button color="secondary" onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>
                                                    <FormFeedback>
                                                        {errors[`dateRange`] && errors[`dateRange`]}
                                                    </FormFeedback>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </Form>
                                {
                                    loading ? <TableLoader /> :
                                        (company_list ? <div className="table-responsive">



                                            <hr />

                                            <div className="table-responsive-sm  animated fadeIn">
                                                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>Repute ID</th>
                                                            <th>Name</th>
                                                            {/* <th>Current Plan</th>
                                                    <th>Remaining SMS</th> */}
                                                            <th>Is Linked</th>
                                                            <th>Balance</th>
                                                            <th>Registered Date</th>
                                                            <th>Plan Expiry Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {company_list.length ?
                                                            company_list.map(function (element, index) {
                                                                return (
                                                                    <tr>
                                                                        <td>{element.formated_repute_id}</td>
                                                                        <td>{element.company_name}</td>
                                                                        {/* <td>{element.current_plan}</td>
                                                            <td>{element.total_sms}</td> */}
                                                                        <td>

                                                                            {element.is_linked ? <React.Fragment>
                                                                                <small>
                                                                                    <b>
                                                                                        <label class="badge badge-primary m-0">I</label> &nbsp; {element.user_name} 
                                                                                        <hr class="m-1"/>
                                                                                        <label class="badge badge-primary m-0">S</label> &nbsp; {element.user_name_service}
                                                                                    </b>
                                                                                </small>
                                                                            </React.Fragment> : 'Not Linked'}

                                                                        </td>
                                                                        <td>{element.balance}</td>
                                                                        <td>{element.created_at}</td>
                                                                        <td>{element.formated_expiry_date}</td>
                                                                        <td>
                                                                            <Row>
                                                                                <Col sm="3">
                                                                                    <ExtendPlan {..._this.props} element={element} getData={_this.handleSubmit} />
                                                                                    {/* {element.current_plan === "Premium" ? 
                                                                            <ExtendPlan {..._this.props} element={element} getData={_this.handleSubmit}/> 
                                                                            : "" }
                                                                            {element.current_plan === "Basic" ? 
                                                                            <UpgradePlan {..._this.props} element={element}  getData={_this.handleSubmit}/>
                                                                            : "" }                                                                     */}
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <ChequePayment {..._this.props} element={element} getData={_this.handleSubmit} />
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <SubscriptionHistory {..._this.props} element={element} getData={_this.handleSubmit} />
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <CompanyProductTags {..._this.props} element={element} getData={_this.handleSubmit} />
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>)
                                                            })

                                                            : <tr>
                                                                <th colSpan="7" className="text-center">No Data Found</th>
                                                            </tr>}
                                                    </tbody>

                                                </table>

                                                {page.totalItemsCount <= page.perPage ? "" :
                                                    <Pagination
                                                        activePage={page.activePage}
                                                        itemsCountPerPage={page.perPage}
                                                        totalItemsCount={page.totalItemsCount}
                                                        pageRangeDisplayed={5}
                                                        linkClass="page-link"
                                                        itemClass="page-item"
                                                        onChange={this.handlePageChange}
                                                    // hideNavigation={false}
                                                    // hideFirstLastPages={true}
                                                    />}
                                            </div>
                                        </div> : "")
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, company_list, balancePackages, default_balance, total_records } = state.getAccountData
    return {
        loading,
        company_list,
        balancePackages,
        total_records,
        default_balance
    }
}

const connectedCompanyList = connect(mapStateToProps)(CompanyList)
export { connectedCompanyList as CompanyList }

import React from 'react'
import { PropTypes } from 'prop-types'
import { FormFeedback } from 'reactstrap'
import { Translate } from "react-localize-redux"

const InlineError = ({ message }) =>   (
    <FormFeedback > <Translate id={message}> {message} </Translate></FormFeedback>
)

InlineError.propTypes = {
    message : PropTypes.string.isRequired
}

export default InlineError
import { notify } from 'reapop'
import { customerDetail } from '../constants'
import { customerDetailService } from '../services'

export const customerDetailAction= {
    pincodeData,
    addCustomerDetails,
    getCustomerDetail,
    updateCustomerDetail,
    updateCustomerDetailLanguage,
    delteCustomerDetail,
    assignCustomerDetail,
    uploadCsvData,
    updateCustomerDetilEmail
}

function pincodeData(data) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.getPincodeData(data)
            .then(
                notVerified => {
                    dispatch(success(notVerified));
                    return notVerified
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.PINCODE_REQUEST } }
    function success(result) { return { type: customerDetail.PINCODE_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.PINCODE_FAILURE, error } }
}

function addCustomerDetails(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.addCustomerDetails(data)
            .then(
                response => {
                    ref.afterSave();
                    dispatch(success(response));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Added Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_REQUEST } }
    function success(result) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_FAILURE, error } }
}

function getCustomerDetail(data) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.getCustomerDetail(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.GET_CUSTOMER_DETAILS_REQUEST } }
    function success(result) { return { type: customerDetail.GET_CUSTOMER_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.GET_CUSTOMER_DETAILS_FAILURE, error } }
}

function updateCustomerDetail(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.updateCustomerDetail(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Updated Successfully', status: "success" }))
                    ref.afterSave();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_REQUEST } }
    function success(result) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_FAILURE, error } }
}

function updateCustomerDetailLanguage(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.updateCustomerDetailLanguage(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Updated Successfully', status: "success" }))
                    ref.afterAction();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_REQUEST } }
    function success(result) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_LANGUAGE_FAILURE, error } }
}

function delteCustomerDetail(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.deleteCustomerDetail(data)
            .then(
                result => {
                    ref.afterAction();
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Deleted Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_REQUEST } }
    function success(result) { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_FAILURE, error } }
}

function assignCustomerDetail(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.assignCustomerDetail(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Assigned Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_REQUEST } }
    function success(result) { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.DELETE_CUSTOMER_DETAILS_FAILURE, error } }
}

function uploadCsvData(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.uploadCsvData(data)
            .then(
                result => {
                    dispatch(success(result));
                    ref.afterUploadCSV();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Upload Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.UPLOAD_CSV_REQUEST } }
    function success(result) { return { type: customerDetail.UPLOAD_CSV_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.UPLOAD_CSV_FAILURE, error } }
}

function updateCustomerDetilEmail(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        customerDetailService.updateCustomerDetilEmail(data)
            .then(
                result => {
                    ref.afterAction();
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Customer detail updated successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_REQUEST } }
    function success(result) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: customerDetail.UPDATE_CUSTOMER_DETAILS_EMAIL_FAILURE, error } }
}
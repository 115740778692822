import React, { Component } from 'react'
import { map } from 'core-js/fn/array'
import { DisplayListItems } from '../Components/DisplayListItems'
import { AddNewItem } from '../Components/AddNewItem';
import { findAndDeleteFirst } from 'obj-traverse/lib/obj-traverse'
export default class BusinessDetails extends Component {

    constructor(props){
        super(props)
        this.SaveItem = this.SaveItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    SaveItem(Items){
        const {template, updateMainJSON} = this.props
        
        template.business_details = {};
        template.business_details.children = Items;
        console.log("test ------> ", template.business_details);
        updateMainJSON(template);
        
    }

    removeItem(item_id) {
        const {template} = this.props
        
        let Items =  findAndDeleteFirst(template.business_details, "children", {id : item_id})

        if(Items) {
            this.SaveItem(Items.children)
        }

      }
    
    
    render() {
        let { template } = this.props
        return (
            <div>
            
            <h3>Business Details</h3>  

            <AddNewItem RootItems={template.business_details.children ||[]} Items={template.business_details.children ||[]} SaveItem={this.SaveItem} />  

            <DisplayListItems RootItems={template.business_details.children ||[]} Items={template.business_details.children||[]} SaveItem={this.SaveItem} removeItem={this.removeItem} />

            </div>
        )
    }
}

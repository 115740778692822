import {  axiosAjaxCall } from './common.service'
import { showLoader } from '../../helpers';

export const saleservice = {
    getCustomerList,
    getCustomerSalesStatusData,
    updateCustomerSalesStatus,
    addDemoAppData
}


function getCustomerList(data) {
    return axiosAjaxCall("sales_customer_list", data);
}

function getCustomerSalesStatusData(data) {
    return axiosAjaxCall("get_customer_sales_status_data", data);
}

function updateCustomerSalesStatus(data) {
    showLoader();
    return axiosAjaxCall("update_customer_sales_status", data);
}

function addDemoAppData(data) {
    showLoader();    
    return axiosAjaxCall("add_demo", data);
}
 


import React from 'react'
import { Button, Card, CardBody, CardHeader, Pagination, Table, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import moment from 'moment'
import { TableLoader } from '../../../components/Loaders'
import { useState } from 'react';
import ModalFooter from 'reactstrap/lib/ModalFooter';

function ListTab() {
    const [modalOpen, setModalOpen] = useState(false)
    const [page, setpage] = useState({
        activePage: 1,
        perPage: 50,
        totalItemsCount: 10000
    },);
    let logs = false
    let loading = false

    let handlePageChange = (pageNumber) => {
        setpage(...page, page.activePage = pageNumber);
        console.log(...page, page.activePage = pageNumber);
        // this.setState({ page }, () => this.getData());
    }
    let ShowActivity = () => {
        toggle()
        console.log('aaaaaaaaaaaaa');
    }
    let ShowRevenue = () => {
        toggle()
        console.log('rrrrrrrrrrrrr');
    }
    let toggle = () => {
        setModalOpen(!modalOpen)
    }
    return (
        <Card>
            <CardHeader>
                <i className="fas fa-user" />
                <strong>Finance Status Trending</strong>
            </CardHeader>
            <CardBody>
                {loading ? (
                    <TableLoader />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive-sm  animated fadeIn">
                            <table className="table table-sm table-condensed table-striped table-bordered table-outline  " style={{ border: '2px solid #909090' }}>
                                <thead className="thead-light">
                                    <tr className=''>
                                        <th className=''>No.</th>
                                        <th className=''>Company Name</th>
                                        <th className=''>Revenue</th>
                                        <th className=''>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className=''>
                                        <td className=''>1</td>
                                        <td className=''>abc Enterprices</td>
                                        <td className=''>123456 <Button onClick={ShowRevenue} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                        <td className=''>876598<Button onClick={ShowActivity} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                    </tr>
                                    <tr className=''>
                                        <td className=''>2</td>
                                        <td className=''>abc Enterprices</td>
                                        <td className=''>123456 <Button onClick={ShowRevenue} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                        <td className=''>876598<Button onClick={ShowActivity} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                    </tr>
                                    <tr className=''>
                                        <td className=''>3</td>
                                        <td className=''>abc Enterprices</td>
                                        <td className=''>123456 <Button onClick={ShowRevenue} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                        <td className=''>876598<Button onClick={ShowActivity} className='pull-right bg-primary'> <i className="fa fa-eye" /></Button> </td>
                                    </tr>
                                </tbody>
                                {/* <tbody>
                                    {logs && logs.sms_list && logs.sms_list.length ? (
                                        logs.sms_list.map(function (eachData, index) {
                                            return (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>{eachData.id}</td>
                                                        <td>{eachData.formatted_company_repute_id}  {eachData.company_name ? <React.Fragment>({eachData.company_name}) </React.Fragment> : false} </td>
                                                        <td>{eachData.receiver_mobile_no}</td>
                                                        <td>{eachData.sms_type}</td>
                                                        <td>{eachData.sms_text}</td>
                                                        <td>{eachData.credit_used}</td>
                                                        <td>{eachData.status}</td>
                                                        <td>{eachData.sent_time ? moment(eachData.sent_time).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                                                        <td>{eachData.delivered_time ? moment(eachData.delivered_time).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody> */}
                            </table>
                        </div>
                        {/* {page.totalItemsCount <= page.perPage ? (
                            ''
                        ) : ( */}
                        <Modal isOpen={modalOpen} toggle={toggle} size={"lg"}>
                            <ModalHeader toggle={toggle}>{'header'}</ModalHeader>
                            <ModalBody>

                                <table className="table table-sm table-condensed table-striped table-bordered table-outline d-flex " style={{ border: '2px solid #909090' }}>
                                    <thead className="thead-light">
                                        <tr className='col-1'>
                                            <th className=''>No.</th>
                                            <td className=''>1</td>
                                        </tr>
                                    </thead>
                                    <thead className="thead-light  col-4">
                                        <tr>
                                            <th className='col-2' >Company Name </th>
                                            <td className='col-2' >Kiran Enterprices</td>
                                        </tr>
                                        <tr>
                                            <th>Customer Name </th>
                                            <td>Kiran Enterprices</td>
                                        </tr>
                                        <tr>
                                            <th>GST Number</th>
                                            <td> 26dkbpp5681q1z1</td>
                                        </tr>
                                        <tr>
                                            <th>PAN</th>
                                            <td> dkbpp5681q</td>
                                        </tr>
                                        <tr>
                                            <th>gst Mobile</th>
                                            <td> 123456765</td>
                                        </tr>
                                        <tr>
                                            <th>gst email</th>
                                            <td> adasldkj@gmail.con</td>
                                        </tr>
                                        <tr>
                                            <th>current mobile</th>
                                            <td> 123456765</td>
                                        </tr>
                                        <tr>
                                            <th>current email</th>
                                            <td> lkaskamsddla@gmail.com</td>
                                        </tr>
                                        <tr>
                                            <th>Repute ID </th>
                                            <td> RI 1221-122-2-12</td>
                                        </tr>
                                    </thead>
                                    <thead className="thead-light col-5">
                                        <tr>
                                            <th className=''></th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>Week</th>
                                            <th>Month</th>
                                            <th>Year</th>
                                            <th>Lifetime</th>
                                        </tr>
                                        <tr>
                                            <th>No. of Register User </th>
                                            <td>12</td>
                                            <td>12</td>
                                            <td>12</td>
                                            <td>12</td>
                                            <td>12</td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <th>No. of BadDebt </th>
                                            <td>21</td>
                                            <td>21</td>
                                            <td>21</td>
                                            <td>21</td>
                                            <td>21</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <th>Searches</th>
                                            <td> 111</td>
                                            <td> 111</td>
                                            <td> 111</td>
                                            <td> 111</td>
                                            <td> 111</td>
                                            <td> 111</td>
                                        </tr>
                                        <tr>
                                            <th>No. of Unregister User</th>
                                            <td> 222</td>
                                            <td> 222</td>
                                            <td> 222</td>
                                            <td> 222</td>
                                            <td> 222</td>
                                            <td> 222</td>
                                        </tr>
                                        <tr>
                                            <th>No. of Unregister BadDebt</th>
                                            <td> 333</td>
                                            <td> 333</td>
                                            <td> 333</td>
                                            <td> 333</td>
                                            <td> 333</td>
                                            <td> 333</td>
                                        </tr>
                                        <tr>
                                            <th>No. of Send Bill Transactions</th>
                                            <td>44</td>
                                            <td>44</td>
                                            <td>44</td>
                                            <td>44</td>
                                            <td>44</td>
                                            <td>44</td>
                                        </tr>
                                        <tr>
                                            <th>No. of Receive Bill Transactions</th>
                                            <td>55</td>
                                            <td>55</td>
                                            <td>55</td>
                                            <td>55</td>
                                            <td>55</td>
                                            <td>55</td>
                                        </tr>
                                    </thead>
                                    <thead className="thead-light col-2   ">
                                        <tr>
                                            <th className=''>Total Activity</th>
                                            <td className='bg-success'>1123</td>
                                        </tr>
                                        <tr>
                                            <th className=''>Total Revenue</th>
                                            <td className='bg-success'>1123</td>
                                        </tr>
                                        <tr>
                                            <th className=''>Total Balance</th>
                                            <td className='bg-success'>1123</td>
                                        </tr>
                                    </thead>
                                </table>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={toggle} >OK</Button>
                                <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                        </Modal>

                        <Pagination
                            activePage={page.activePage}
                            itemsCountPerPage={page.perPage}
                            totalItemsCount={page.totalItemsCount}
                            pageRangeDisplayed={5}
                            linkClass="page-link"
                            itemClass="page-item"
                            onChange={handlePageChange}
                        />

                        {/* // )} */}
                    </React.Fragment>
                )}
            </CardBody>
        </Card>
    )
}

export default ListTab
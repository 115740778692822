import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AssignToDropDown } from "./Components/AssignToDropDown";
import { ViewAccountDetails } from "./Components/ViewAccountDetails";
const TABS = {
  Assigned: "assigned",
  Introduce: "introduce",
}

class TabContentData extends Component {
  constructor(props) {
    super(props);
   
    this.editData = this.editData.bind(this);
  }

  editData(d) {
    this.props.history.push("/customer-detail/edit/" + d.id);
  }
 
  render() {
    const { data, activeTab, getData } = this.props;
    let _this = this;
    return (
      <div>



            {/* {loading ? 
                        <TableLoader/> :  */}
            <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
              <thead class="thead-light">
                <tr>
                  <th>Business Name</th>
                  {
                    activeTab == TABS.Assigned ?
                    <React.Fragment>
                      <th>Process</th>
                      <th>Assign to</th>
                    </React.Fragment>
                    : ''
                  }
                  <th>District</th>
                  <th>State</th>
                  <th>Sector Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              {data.length ? (
                data.map((item) => {
                  return (
                    <tr>
                      <td>{item.name}</td>
                       {
                        activeTab == TABS.Assigned ? 
                        <React.Fragment>
                      <td>{item.process_stage ? item.process_stage.process_name : "Not Available  " }</td>
                      <td>
                        <AssignToDropDown key={item.id} item={item} getData={getData} />
                      </td>
                         
                        </React.Fragment>
                        :''}
                        
                      <td>{item.city_name}</td>
                      <td>{item.state_name}</td>
                      <td>{item.sector_name}</td>
                      
                      <td>
                      {
                        activeTab == TABS.Assigned ? 
                        <ViewAccountDetails key={item.id} business_details_id={item.id}  item={item}  getData={getData} />
                          :
                        <Link
                          className="btn btn-primary btn-sm "
                          to={"/business-account/edit/" + item.id}
                        >
                          Edit
                        </Link>}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colspan="4"> No data found</td>
                </tr>
              )}
            </table>
          {/* } */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const connected = connect(mapStateToProps)(TabContentData);
export { connected as TabContentData };


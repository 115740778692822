import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import { Card, CardBody, CardGroup, Col, Row } from 'reactstrap';
import { ForgotPasswordForm } from "./Forms/ForgotPassworForm"
class ForgetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.classList.remove('sidebar-lg-show')
    document.body.classList.remove('sidebar-fixed')
  }

  render() {

    return (
      <div className="app-login flex-row align-items-center">
        <Row className="justify justify-content-center w-100">
          <Col md="4">

            <Translate>
              {({ translate }) =>
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                          <React.Fragment>
                             <ForgotPasswordForm  />
                        </React.Fragment>
                    </CardBody>
                  </Card>
                </CardGroup>
              }
            </Translate>
          </Col>
        </Row>
      </div>
    );
  }
}


function mapStateToProps(state) {
}

const connectedForgetPassword = connect(mapStateToProps)(ForgetPassword);
export { connectedForgetPassword as ForgetPassword };


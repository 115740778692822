export const associationConstants = {
    GET_ASSOCIATION_REQUEST: 'GET_ASSOCIATION_REQUEST',
    GET_ASSOCIATION_SUCCESS: 'GET_ASSOCIATION_SUCCESS',
    GET_ASSOCIATION_FAILURE: 'GET_ASSOCIATION_FAILURE',

    GET_INIVIUAL_TYPE_REQUEST: 'GET_INIVIUAL_TYPE_REQUEST',
    GET_INIVIUAL_TYPE_SUCCESS: 'GET_INIVIUAL_TYPE_SUCCESS',
    GET_INIVIUAL_TYPE_FAILURE: 'GET_INIVIUAL_TYPE_FAILURE',

    ADD_INIVIUAL_TYPE_REQUEST: 'ADD_INIVIUAL_TYPE_REQUEST',
    ADD_INIVIUAL_TYPE_SUCCESS: 'ADD_INIVIUAL_TYPE_SUCCESS',
    ADD_INIVIUAL_TYPE_FAILURE: 'ADD_INIVIUAL_TYPE_FAILURE',

    DELETE_INIVIUAL_TYPE_REQUEST: 'DELETE_INIVIUAL_TYPE_REQUEST',
    DELETE_INIVIUAL_TYPE_SUCCESS: 'DELETE_INIVIUAL_TYPE_SUCCESS',
    DELETE_INIVIUAL_TYPE_FAILURE: 'DELETE_INIVIUAL_TYPE_FAILURE',

    ADD_ASSOCIATION_REQUEST: 'ADD_ASSOCIATION_REQUEST',
    ADD_ASSOCIATION_SUCCESS: 'ADD_ASSOCIATION_SUCCESS',
    ADD_ASSOCIATION_FAILURE: 'ADD_ASSOCIATION_FAILURE',

    DELETE_ASSOCIATION_REQUEST: 'DELETE_ASSOCIATION_REQUEST',
    DELETE_ASSOCIATION_SUCCESS: 'DELETE_ASSOCIATION_SUCCESS',
    DELETE_ASSOCIATION_FAILURE: 'DELETE_ASSOCIATION_FAILURE',

    CHANGE_STATUS_ASSOCIATION_REQUEST: 'CHANGE_STATUS_ASSOCIATION_REQUEST',
    CHANGE_STATUS_ASSOCIATION_SUCCESS: 'CHANGE_STATUS_ASSOCIATION_SUCCESS',
    CHANGE_STATUS_ASSOCIATION_FAILURE: 'CHANGE_STATUS_ASSOCIATION_FAILURE',

    GET_ASSOCIATION_INDIVIUAL_REQUEST: 'GET_ASSOCIATION_INDIVIUAL_REQUEST',
    GET_ASSOCIATION_INDIVIUAL_SUCCESS: 'GET_ASSOCIATION_INDIVIUAL_SUCCESS',
    GET_ASSOCIATION_INDIVIUAL_FAILURE: 'GET_ASSOCIATION_INDIVIUAL_FAILURE',

    ADD_ASSOCIATION_INDIVIUAL_REQUEST: 'ADD_ASSOCIATION_INDIVIUAL_REQUEST',
    ADD_ASSOCIATION_INDIVIUAL_SUCCESS: 'ADD_ASSOCIATION_INDIVIUAL_SUCCESS',
    ADD_ASSOCIATION_INDIVIUAL_FAILURE: 'ADD_ASSOCIATION_INDIVIUAL_FAILURE',

    GET_ASSOCIATION_COMPANY_REQUEST: 'GET_ASSOCIATION_COMPANY_REQUEST',
    GET_ASSOCIATION_COMPANY_SUCCESS: 'GET_ASSOCIATION_COMPANY_SUCCESS',
    GET_ASSOCIATION_COMPANY_FAILURE: 'GET_ASSOCIATION_COMPANY_FAILURE',

    ADD_ASSOCIATION_COMPANY_REQUEST: 'ADD_ASSOCIATION_COMPANY_REQUEST',
    ADD_ASSOCIATION_COMPANY_SUCCESS: 'ADD_ASSOCIATION_COMPANY_SUCCESS',
    ADD_ASSOCIATION_COMPANY_FAILURE: 'ADD_ASSOCIATION_COMPANY_FAILURE',

    GET_GROUP_TYPE_REQUEST: 'GET_GROUP_TYPE_REQUEST',
    GET_GROUP_TYPE_SUCCESS: 'GET_GROUP_TYPE_SUCCESS',
    GET_GROUP_TYPE_FAILURE: 'GET_GROUP_TYPE_FAILURE',

    ADD_GROUP_TYPE_REQUEST: 'ADD_GROUP_TYPE_REQUEST',
    ADD_GROUP_TYPE_SUCCESS: 'ADD_GROUP_TYPE_SUCCESS',
    ADD_GROUP_TYPE_FAILURE: 'ADD_GROUP_TYPE_FAILURE',

    DELETE_GROUP_TYPE_REQUEST: 'DELETE_GROUP_TYPE_REQUEST',
    DELETE_GROUP_TYPE_SUCCESS: 'DELETE_GROUP_TYPE_SUCCESS',
    DELETE_GROUP_TYPE_FAILURE: 'DELETE_GROUP_TYPE_FAILURE',
};
import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const communicationTemplateService = {  
    addCommunicationTemplate, 
    getCommunicationTemplate,
    editCommunicationTemplate,  
    publishCommunicationTemplate,   
}

function addCommunicationTemplate(data) {
       
    // return axiosAjaxCall("add-communication-template", data);
    var form_data = new FormData()

    form_data.append("current_password", data.oldPassword)
    form_data.append("new_password", data.newPassword)
    form_data.append("new_password_confirmation", data.confirmPassword)

    return axios.post(`${config.get('apiUrl')}/addTemplate`, form_data).then(handleResponse)
}
function getCommunicationTemplate(data) {
       
    // return axiosAjaxCall("get-communication-template", data);
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get-communication-template`, form_data, setting).then(handleResponse)
}

function editCommunicationTemplate(data) {      
    // return axiosAjaxCall("update-communication-template", data);

    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update-communication-template`, form_data, setting).then(handleResponse)
}

function publishCommunicationTemplate(data) {
    // showLoader();    
    // return axiosAjaxCall("publish-communication-template", data);
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/publish-communication-template`, form_data, setting).then(handleResponse)
}

import { associationConstants } from "../constants"

export function getAssociation(state = {}, action) {
    switch (action.type) {
      case associationConstants.GET_ASSOCIATION_REQUEST:
        return { loading: true }
      case associationConstants.GET_ASSOCIATION_SUCCESS:
        return {  data: action.result }
      case associationConstants.GET_ASSOCIATION_FAILURE:
        return {}
      default:
        return state
    }
}

export function getIndiviualType(state = {}, action) {
    switch (action.type) {
      case associationConstants.GET_INIVIUAL_TYPE_REQUEST:
        return { loading: true }
      case associationConstants.GET_INIVIUAL_TYPE_SUCCESS:
        return {  data: action.result }
      case associationConstants.GET_INIVIUAL_TYPE_FAILURE:
        return {}
      default:
        return state
    }
}

export function addIndiviualType(state = {}, action) {
    switch (action.type) {
      case associationConstants.ADD_INIVIUAL_TYPE_REQUEST:
        return { loading: true }
      case associationConstants.ADD_INIVIUAL_TYPE_SUCCESS:
        return {  data: action.result }
      case associationConstants.ADD_INIVIUAL_TYPE_FAILURE:
        return {}
      default:
        return state
    }
}

export function deleteIndiviualType(state = {}, action) {
  switch (action.type) {
    case associationConstants.DELETE_INIVIUAL_TYPE_REQUEST:
      return { loading: true }
    case associationConstants.DELETE_INIVIUAL_TYPE_SUCCESS:
      return {  data: action.result }
    case associationConstants.DELETE_INIVIUAL_TYPE_FAILURE:
      return {}
    default:
      return state
  }
}

export function addAssociation(state = {}, action) {
  switch (action.type) {
    case associationConstants.ADD_ASSOCIATION_REQUEST:
      return { loading: true }
    case associationConstants.ADD_ASSOCIATION_SUCCESS:
      return {  data: action.result }
    case associationConstants.ADD_ASSOCIATION_FAILURE:
      return {}
    default:
      return state
  }
}
export function deleteAssociation(state = {}, action) {
  switch (action.type) {
    case associationConstants.DELETE_ASSOCIATION_REQUEST:
      return { loading: true }
    case associationConstants.DELETE_ASSOCIATION_SUCCESS:
      return {  data: action.result }
    case associationConstants.DELETE_ASSOCIATION_FAILURE:
      return {}
    default:
      return state
  }
}
export function changeStatusAssociation(state = {}, action) {
  switch (action.type) {
    case associationConstants.CHANGE_STATUS_ASSOCIATION_REQUEST:
      return { loading: true }
    case associationConstants.CHANGE_STATUS_ASSOCIATION_SUCCESS:
      return {  data: action.result }
    case associationConstants.CHANGE_STATUS_ASSOCIATION_FAILURE:
      return {}
    default:
      return state
  }
}


export function getAssociationIndividual(state = {}, action) {
  switch (action.type) {
    case associationConstants.GET_ASSOCIATION_INDIVIUAL_REQUEST:
      return { loading: true }
    case associationConstants.GET_ASSOCIATION_INDIVIUAL_SUCCESS:
      return {  data: action.result }
    case associationConstants.GET_ASSOCIATION_INDIVIUAL_FAILURE:
      return {}
    default:
      return state
  }
}
export function addAssociationIndividual(state = {}, action) {
  switch (action.type) {
    case associationConstants.ADD_ASSOCIATION_INDIVIUAL_REQUEST:
      return { loading: true }
    case associationConstants.ADD_ASSOCIATION_INDIVIUAL_SUCCESS:
      return {  data: action.result }
    case associationConstants.ADD_ASSOCIATION_INDIVIUAL_FAILURE:
      return {}
    default:
      return state
  }
}
export function getAssociationCompany(state = {}, action) {
  switch (action.type) {
    case associationConstants.GET_ASSOCIATION_COMPANY_REQUEST:
      return { loading: true }
    case associationConstants.GET_ASSOCIATION_COMPANY_SUCCESS:
      return {  data: action.result }
    case associationConstants.GET_ASSOCIATION_COMPANY_FAILURE:
      return {}
    default:
      return state
  }
}
export function addAssociationCompany(state = {}, action) {
  switch (action.type) {
    case associationConstants.ADD_ASSOCIATION_COMPANY_REQUEST:
      return { loading: true }
    case associationConstants.ADD_ASSOCIATION_COMPANY_SUCCESS:
      return {  data: action.result }
    case associationConstants.CHANGE_STATUS_ASSOCIATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function getGroupType(state = {}, action) {
  switch (action.type) {
    case associationConstants.GET_GROUP_TYPE_REQUEST:
      return { loading: true }
    case associationConstants.GET_GROUP_TYPE_SUCCESS:
      return {  data: action.result }
    case associationConstants.GET_GROUP_TYPE_FAILURE:
      return {}
    default:
      return state
  }
}

export function addGroupType(state = {}, action) {
  switch (action.type) {
    case associationConstants.ADD_GROUP_TYPE_REQUEST:
      return { loading: true }
    case associationConstants.ADD_GROUP_TYPE_SUCCESS:
      return {  data: action.result }
    case associationConstants.ADD_GROUP_TYPE_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteGroupType(state = {}, action) {
  switch (action.type) {
    case associationConstants.DELETE_GROUP_TYPE_REQUEST:
      return { loading: true }
    case associationConstants.DELETE_GROUP_TYPE_SUCCESS:
      return {  data: action.result }
    case associationConstants.DELETE_GROUP_TYPE_FAILURE:
      return {}
    default:
      return state
  }
}
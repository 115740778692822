import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { SendBillTrasanctionTab } from "./SendBillTransactionTab";
const TABS = {
  Registered: "registered",
  Unregistered: "unregistered",
  All: "all",
};

class SendBillTrasanction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TABS.Registered,
      active_page: 1,
      per_page: 20,
    };
  }

  handleChangeTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        active_page: 1,
      });
    }
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>
            <i className="nav-icon fas fa-file-contract"></i> Send Bill
            Transaction
          </CardHeader>
          <CardBody>
            <Nav tabs>
              {Object.values(TABS).map((tab) => (
                <NavItem key={tab}>
                  <NavLink
                    className={this.state.activeTab === tab ? "active" : ""}
                    onClick={() => this.handleChangeTab(tab)}
                  >
                    <b>{tab.charAt(0).toUpperCase() + tab.slice(1)}</b>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {Object.values(TABS).map((tab) => (
                <TabPane key={tab} tabId={tab}>
                  {this.state.activeTab === tab && (
                    <SendBillTrasanctionTab activeTab={this.state.activeTab} />
                  )}
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    data,
  };
};

const connected = connect(mapStateToProps)(SendBillTrasanction);
export { connected as SendBillTrasanction };

import { smsLogsConstants } from "../constants"

export function getSmsLogsData(state = {}, action) {
    switch (action.type) {
        case smsLogsConstants.GET_SMS_LOG_DATA_REQUEST:
        return { loading: true }
        case smsLogsConstants.GET_SMS_LOG_DATA_SUCCESS:
        return { 
            data: action.result
            }
        case smsLogsConstants.GET_SMS_LOG_DATA_FAILURE:
        return {}
        default:
        return state
    }
}

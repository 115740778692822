import { findFirst } from "obj-traverse/lib/obj-traverse";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Button, FormGroup, Input } from "reactstrap";
import { communicationMastersActions } from "../../../../actions/communicationMasters.action";
import { InputDropDown } from "../../../Settings/StageSetting/Components/FormElements";
import InlineError from "../../../../../components/Messages/InlineError";
import { clean_text, clean_tag } from "../../../../../helpers";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";
import moment from "moment";

const CommType = {
  Instant: "instant",
  Schedule: "schedule",
  NotSelected: "NotSelected",
};

const defaultChannels = {
  sms: false,
  email: false,
  whatsapp: false,
  call: false,
};

const exclude_tags = ["individual_name", "business_name", "mobile_number", "email", "schedule_date_time"];

const defaultData = {
  individual_ids: [],
  business_account_id: "",
  communication_channels: defaultChannels,
  communication_template_master_id: false,
  when_to_execute: "",
};

class CommunicationSmsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      communication_type: CommType.Instant,
      errors: {},
      data: { ...defaultData },
      tagsData: {},
      tagDataOptions: {},
    };
    this.setType = this.setType.bind(this);
    this.collapse = this.collapse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectIndividual = this.selectIndividual.bind(this);
    this.selectChannels = this.selectChannels.bind(this);
    this.validation = this.validation.bind(this);
    this.reset = this.reset.bind(this);
    this.tagForm = this.tagForm.bind(this);
    this.handleChangeTagForm = this.handleChangeTagForm.bind(this);

    this.renderFormData = this.renderFormData.bind(this);
  }
  setType(type) {
    this.setState({ communication_type: type });
  }
  componentDidMount() {
    let items = localStorage.getItem("communication_masters");

    if (items) {
      items = JSON.parse(items);
      this.setState({ items });
    }
  }

  save() {
    const { dispatch, business_details_id } = this.props;
    const { communication_type, data, tagsData } = this.state;

    if (this.validation()) {
      return false;
    }

    let dataToSend = {
      business_account_id: business_details_id,
      individual_ids: data.individual_ids,
      communication_channels: JSON.stringify(data.communication_channels),
      communication_template_master_id: data.communication_template_master_id,
      is_scheduled: CommType.Schedule === communication_type ? 1 : 0,
      when_to_execute: moment(data.when_to_execute).format("YYYY-MM-DD HH:mm:ss"),
      communication_data: JSON.stringify(tagsData),
    };

    dispatch(
      communicationMastersActions.saveCommunicationMasters(dataToSend, {
        success: () => {
          this.reset();
        },
      })
    );
  }

  reset() {
    this.setState({
      data: { ...defaultData },
    });
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState({ data }, () => {
      if (name === 'communication_template_master_id') {
        this.renderFormData();
      }
    });
  }

  renderFormData() {

    const { data,tagsData } = this.state;
    const { communicationMasterTemplates } = this.props;
    let tags = [];
    if (communicationMasterTemplates) {
      communicationMasterTemplates.length && communicationMasterTemplates.map((each) => {
        // dropdownOptions.push({ title: each.name, value: each.id });
        if (each.id == data.communication_template_master_id && each.tags) {
          tags = each.tags.split(",");
        }
      });
    }

    tags.map((tg) => {
      let tag_type = "text";

      let options = {
        type: "text",
        min: 0,
        max: 300,
      }

      if (tg.indexOf("#") !== -1) {
        let _option = [];
        // ex: tag_name#type:number|min:0|max:200
        tag_type = tg.substr(tg.indexOf("#") + 1);
        _option = tag_type.split("|");
        _option.map((each) => {
          let splt = each.split(":");
          if (options.hasOwnProperty(splt[0])) {
            options[splt[0]] = splt[1];
          }
        })
      }

      let tag_key = clean_tag(tg);
      if (exclude_tags.indexOf(tag_key) !== -1) {
        tagsData[tag_key] = tag_key
      }
    })
    this.setState({ tagsData });
  }

  validation() {
    const { data, communication_type } = this.state;
    let error = false;
    let errors = {};
    if (data.communication_template_master_id === false) {
      error = true;
      errors.communication_template_master_id = "select communication channel";
    }

    if (data.individual_ids.length < 1) {
      error = true;
      errors.individual_ids = "select atleast one individual";
    }

    if (CommType.Schedule === communication_type) {
      if (data.when_to_execute === "") {
        error = true;
        errors.when_to_execute = "select date";
      }
    }

    this.setState({ errors });
    return error;
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  selectIndividual(individual_id) {
    const { data } = this.state;

    let position = data.individual_ids.indexOf(individual_id);
    if (position != -1) {
      data.individual_ids.splice(position, 1);
    } else {
      data.individual_ids.push(individual_id);
    }
    this.setState({ data });
  }

  selectChannels(e) {
    const { data } = this.state;
    const { name, value, checked } = e.target;

    // console.log(e.target);
    data.communication_channels[name] = checked;
    this.setState({ data });
  }




  renderCommunicationTags() {
    const { data } = this.state;
    const { communicationMasterTemplates } = this.props;
    let tags = [];
    // let  = [];

    if (communicationMasterTemplates) {
      communicationMasterTemplates.map((each) => {
        // dropdownOptions.push({ title: each.name, value: each.id });
        if (each.id == data.communication_template_master_id && each.tags) {
          tags = each.tags.split(",");
        }
      });
    }

    return tags.map((tg) => {
      return (
        <React.Fragment>
          <span className="badge badge-success"> {clean_text(tg)}</span> &nbsp;
        </React.Fragment>
      );
    });
  }

  tagForm() {
    const { data, tagsData, tagDataOptions } = this.state;
    const { communicationMasterTemplates } = this.props;
    let tags = [];
    let _this = this;
    if (communicationMasterTemplates) {
      communicationMasterTemplates.length && communicationMasterTemplates.map((each) => {
        // dropdownOptions.push({ title: each.name, value: each.id });
        if (each.id == data.communication_template_master_id && each.tags) {
          tags = each.tags.split(",");
        }
      });
    }

    return tags && tags.map((tg) => {
      let tag_type = "text";

      let options = {
        type: "text",
        min: 0,
        max: 300,
      }

      if (tg.indexOf("#") !== -1) {
        let _option = [];
        // ex: tag_name#type:number|min:0|max:200
        tag_type = tg.substr(tg.indexOf("#") + 1);
        _option = tag_type.split("|");
        _option.map((each) => {
          let splt = each.split(":");
          if (options.hasOwnProperty(splt[0])) {
            options[splt[0]] = splt[1];
          }
        })
      }


      let tag_key = clean_tag(tg);
      let read_only = false;
      if (exclude_tags.indexOf(tag_key) !== -1) {
        read_only = true;
        tagsData[tag_key] = tag_key
      }



      if (JSON.stringify(tagDataOptions[tag_key]) !== JSON.stringify(options)) {
        tagDataOptions[tag_key] = options;
        this.setState({ tagDataOptions })
      }

      if (options.type === "number") {
        return (
          <React.Fragment>
            <FormGroup class={read_only ? "d-none" : ""}>
              <MaskedInput
                readOnly={read_only}
                disabled={read_only}
                className={"form-control"}
                mask={createNumberMask({
                  prefix: "",
                  postfix: "",
                  includeThousandsSeparator: false,
                  allowDecimal: true,
                  minValue: options.min,
                })}
                maxLength={options.max.length}
                name={tag_key}
                value={tagsData[tag_key]}
                onChange={_this.handleChangeTagForm}
                placeholder={clean_text(tg)}
              />
            </FormGroup>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <FormGroup class={read_only ? "d-none" : ""}>
            <Input
              readOnly={read_only}
              disabled={read_only}
              name={clean_text(tg)}
              value={tagsData[tag_key]}
              onChange={_this.handleChangeTagForm}
              placeholder={clean_text(tg)}
            />
          </FormGroup>
        </React.Fragment>
      );
    });
  }

  handleChangeTagForm(e) {
    const { name, value } = e.target;
    const { tagsData, tagDataOptions } = this.state;

    tagsData[name] = value;
    let options = tagDataOptions[name];
    if (options) {
      console.log(options);
      if (options.hasOwnProperty("max") && parseInt(value, 10) > parseInt(options.max, 10)) {
        return false;
      }
    }
    this.setState({ tagsData });
  }

  render() {
    const { communication_type, data, items, errors } = this.state;
    const { individuals, communicationMasterTemplates } = this.props;

    let dropdownOptions = [{ title: "Select communication for", value: false }];


    if (communicationMasterTemplates) {
      communicationMasterTemplates.map((each) => {
        dropdownOptions.push({ title: each.name, value: each.id });
        
      });
    }
    
    // console.log(dropdownOptions);

    if (items) {
      items.map((item) => {
        dropdownOptions.push({ value: "", title: item.name });
      });
    }

    let _this = this;
    return (
      <div>
        <label>
          <b>Select Individual(s)</b>
        </label>
        {individuals &&
          individuals.map((individual) => {
            let name = "not specified";
            let mobile = "not specified";
            let individual_id = individual.individual_id;
            let foundItem = findFirst(individual, "children", {
              dataType: "name",
            });
            if (foundItem) {
              name = foundItem.value ? foundItem.value : name;
            }
            let foundMobile = findFirst(individual, "children", {
              dataType: "mobile",
            });
            if (foundMobile) {
              mobile = foundMobile.value ? foundMobile.value : mobile;
            }
            return (
              <FormGroup>
                <label>
                  <input
                    type="checkbox"
                    checked={data.individual_ids.indexOf(individual_id) != -1}
                    onClick={() => this.selectIndividual(individual_id)}
                  />
                  &nbsp; {name} ({mobile})
                </label>
              </FormGroup>
            );
          })}
        {errors.individual_ids && (
          <InlineError message={errors.individual_ids} />
        )}

        <br />
        <label>
          <b>Communication For</b>
        </label>

        <InputDropDown
          title={false}
          name={"communication_template_master_id"}
          placeholder={data.title}
          onChange={this.handleChange}
          options={dropdownOptions}
          value={data.communication_template_master_id}
          error={errors.communication_template_master_id}
          maxLength="250"
        />

        {_this.renderCommunicationTags()}

        <br />
        <br />

        {_this.tagForm()}

        <div>
          <label>
            <b>Communication Channel</b>
          </label>

          <FormGroup>
            <label>
              <input
                type="checkbox"
                name={"sms"}
                checked={data.communication_channels.sms}
                onClick={this.selectChannels}
              />
              &nbsp; {"SMS"}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <input
                type="checkbox"
                name={"whatsapp"}
                checked={data.communication_channels.whatsapp}
                onClick={this.selectChannels}
              />
              &nbsp; {"Whatsapp"}
            </label>
          </FormGroup>

          {/* <FormGroup>
                      <label>
                        <input
                          type="checkbox"
                          name={"email"}
                          checked={data.communication_channels.email}
                          onClick={this.selectChannels}
                        />
                        &nbsp; {"Email"}
                      </label>
                    </FormGroup>
                     */}

          <div class="btn-group">
            <Button
              color={
                communication_type === CommType.Instant
                  ? "primary"
                  : "secondary"
              }
              onClick={() => this.setType(CommType.Instant)}
            >
              Instant
            </Button>
            <Button
              color={
                communication_type === CommType.Schedule
                  ? "primary"
                  : "secondary"
              }
              onClick={() => this.setType(CommType.Schedule)}
            >
              Schedule
            </Button>
          </div>
          <br />
          <br />

          {communication_type === CommType.Schedule && (
            <React.Fragment>
              <FormGroup>
                <DatePicker
                  className="form-control"
                  placeholderText="Select schedule time"
                  selected={data.when_to_execute}
                  onChange={(date) =>
                    this.handleChange({ name: "when_to_execute", value: date })
                  }

                  // dateFormat="DD-MM-YYYY hh:mm"
                  // maxDate={lastMonth}
                  minDate={new Date()}
                  showTimeSelect
                  value={data.when_to_execute ? moment(data.when_to_execute).format("DD-MM-YYYY hh:mm A") : ""}
                // showMonthYearPicker
                />
                {errors.when_to_execute && (
                  <InlineError message={errors.when_to_execute} />
                )}
              </FormGroup>
            </React.Fragment>
          )}
          <br />

          <Button color="primary" onClick={() => this.save()}>
            Done
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { businessAccountTemplate, businessAccounts } = state;
  return {
    communicationMasterTemplates: businessAccounts.communicationMasterTemplates,
  };
}

const connected = connect(mapStateToProps)(CommunicationSmsForm);
export { connected as CommunicationSmsForm };

import React from 'react';
import {  Col } from 'reactstrap';

export const RenderText = ({title, l, text, size}) => {
    return <React.Fragment>
        <Col md={ size || "4" } lg="3" sm="6"  className="mb-1"> <strong> {title}</strong> <br/>
        { l ? <i className="fa fa-spin fa-spinner"></i> : ( text ? text : "-")  }
        </Col>
    </React.Fragment>
}
export const RenderSalesStatus = ({status}) => {
    return <React.Fragment>
         {status.last_status}
        {status.last_note && <React.Fragment>
            <br/> <i className="text-muted"> <b>Note :</b> {status.last_note}  </i>
            </React.Fragment>}
    </React.Fragment>
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { supportActions } from "../../actions";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import classnames from 'classnames'
import {TicketListOld} from './TicketListOld';

const TABS = {
    Inporgress: "Inporgress",
    Complete: "Complete"
}

class TicketsOld extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: TABS.Inporgress
        }

        this.getTicketList = this.getTicketList.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.getTicketList()
    }

    getTicketList() {
        const { dispatch } = this.props;
        const { activeTab } = this.state;
        let dataTosend = {
            status:activeTab
        }
        dispatch(supportActions.getTicketList(dataTosend));
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this.getTicketList()
            })
        }
    }
    render() {
        const { data, loading } = this.props
        
        return (
            <div>
                    <h5 className="mb-4 mt-4">
                    <i className="fa fa-ticket"></i> &nbsp; Tickets
                </h5>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.Inporgress })}
                        onClick={() => { this.toggle(TABS.Inporgress) }}
                    >
                        <i class="fa fa-ticket text-warning" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Open / In Porgress</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.Complete })}
                        onClick={() => { this.toggle(TABS.Complete) }}
                    >
                        <i className="fa fa-ticket text-success" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Complete</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={TABS.Inporgress}>
                    {
                        loading ? <TableLoader /> : 
                        <TicketListOld ticketList={data} />
                    }

                </TabPane>
                <TabPane tabId={TABS.Complete}>
                    {
                        loading ? <TableLoader /> : 
                        <TicketListOld ticketList={data} />
                    }


                </TabPane>
            </TabContent>
            </div>
        )
    }

}


function mapStateToProps(state) {
    const { loading, data } = state.getTicketList
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user

    }
}

const connectedTickets = connect(mapStateToProps)(TicketsOld)
export { connectedTickets as TicketsOld }

export const accountConstants = {
    GET_ACCOUNT_DATA_REQUEST: 'GET_ACCOUNT_DATA_REQUEST',
    GET_ACCOUNT_DATA_SUCCESS: 'GET_ACCOUNT_DATA_SUCCESS',
    GET_ACCOUNT_DATA_FAILURE: 'GET_ACCOUNT_DATA_FAILURE',

    EXTEND_PLAN_REQUEST: 'EXTEND_PLAN_REQUEST',
    EXTEND_PLAN_SUCCESS: 'EXTEND_PLAN_SUCCESS',
    EXTEND_PLAN_FAILURE: 'EXTEND_PLAN_FAILURE',

    EXTEND_CHEQUE_PAYMENT_REQUEST: 'EXTEND_CHEQUE_PAYMENT_REQUEST',
    EXTEND_CHEQUE_PAYMENT_SUCCESS: 'EXTEND_CHEQUE_PAYMENT_SUCCESS',
    EXTEND_CHEQUE_PAYMENT_FAILURE: 'EXTEND_CHEQUE_PAYMENT_FAILURE',

    GET_USER_SALARY_DATA_REQUEST: 'GET_USER_SALARY_DATA_REQUEST',
    GET_USER_SALARY_DATA_SUCCESS: 'GET_USER_SALARY_DATA_SUCCESS',
    GET_USER_SALARY_DATA_FAILURE: 'GET_USER_SALARY_DATA_FAILURE',
    GET_USER_SALARY_DATA_RESET: 'GET_USER_SALARY_DATA_RESET',

    GET_CHEQUE_DETAIL_REQUEST: 'GET_CHEQUE_DETAIL_REQUEST',
    GET_CHEQUE_DETAIL_SUCCESS: 'GET_CHEQUE_DETAIL_SUCCESS',
    GET_CHEQUE_DETAIL_FAILURE: 'GET_CHEQUE_DETAIL_FAILURE',

    UPDATE_CHEQUE_DETAIL_REQUEST: 'UPDATE_CHEQUE_DETAIL_REQUEST',
    UPDATE_CHEQUE_DETAIL_SUCCESS: 'UPDATE_CHEQUE_DETAIL_SUCCESS',
    UPDATE_CHEQUE_DETAIL_FAILURE: 'UPDATE_CHEQUE_DETAIL_FAILURE',

    GET_COMMISSION_REQUEST : 'GET_COMMISSION_REQUEST',
    GET_COMMISSION_SUCCESS : 'GET_COMMISSION_SUCCES',
    GET_COMMISSION_FAILURE : 'GET_COMMISSION_FAILURE',

    SAVE_COMPANY_PRODUCT_TAGS_REQUEST : 'SAVE_COMPANY_PRODUCT_TAGS_REQUEST',
    SAVE_COMPANY_PRODUCT_TAGS_SUCCESS : 'SAVE_COMPANY_PRODUCT_TAGS_SUCCESS',
    SAVE_COMPANY_PRODUCT_TAGS_FAILURE : 'SAVE_COMPANY_PRODUCT_TAGS_FAILURE',
    
    GET_COMPANY_PRODUCT_TAGS_REQUEST : 'GET_COMPANY_PRODUCT_TAGS_REQUEST',
    GET_COMPANY_PRODUCT_TAGS_SUCCESS : 'GET_COMPANY_PRODUCT_TAGS_SUCCESS',
    GET_COMPANY_PRODUCT_TAGS_FAILURE : 'GET_COMPANY_PRODUCT_TAGS_FAILURE',

    DELETE_COMPANY_PRODUCT_TAGS_REQUEST : 'DELETE_COMPANY_PRODUCT_TAGS_REQUEST',
    DELETE_COMPANY_PRODUCT_TAGS_SUCCESS : 'DELETE_COMPANY_PRODUCT_TAGS_SUCCESS',
    DELETE_COMPANY_PRODUCT_TAGS_FAILURE : 'DELETE_COMPANY_PRODUCT_TAGS_FAILURE',

    GET_PRODUCT_TAG_REQUEST : 'GET_PRODUCT_TAG_REQUEST',
    GET_PRODUCT_TAG_SUCCESS : 'GET_PRODUCT_TAG_SUCCESS',
    GET_PRODUCT_TAG_FAILURE: 'GET_PRODUCT_TAG_FAILURE',

    GET_DEPOSIT_RECEPIT_REQUEST : 'GET_DEPOSIT_RECEPIT_REQUEST',
    GET_DEPOSIT_RECEPIT_SUCCESS : 'GET_DEPOSIT_RECEPIT_SUCCESS',
    GET_DEPOSIT_RECEPIT_FAILURE: 'GET_DEPOSIT_RECEPIT_FAILURE',

    UPDATE_DEPOSIT_RECEPIT_REQUEST : 'UPDATE_DEPOSIT_RECEPIT_REQUEST',
    UPDATE_DEPOSIT_RECEPIT_SUCCESS : 'UPDATE_DEPOSIT_RECEPIT_SUCCESS',
    UPDATE_DEPOSIT_RECEPIT_FAILURE: 'UPDATE_DEPOSIT_RECEPIT_FAILURE',

    CLOSE_DEPOSIT_RECEPIT_REQUEST : 'CLOSE_DEPOSIT_RECEPIT_REQUEST',
    CLOSE_DEPOSIT_RECEPIT_SUCCESS : 'CLOSE_DEPOSIT_RECEPIT_SUCCESS',
    CLOSE_DEPOSIT_RECEPIT_FAILURE: 'CLOSE_DEPOSIT_RECEPIT_FAILURE',

    GET_ACCOUNT_ANALYSIS_DATA_REQUEST : 'GET_ACCOUNT_ANALYSIS_DATA_REQUEST' , 
    GET_ACCOUNT_ANALYSIS_DATA_SUCCESS : 'GET_ACCOUNT_ANALYSIS_DATA_SUCCESS' , 
    GET_ACCOUNT_ANALYSIS_DATA_FAILURE : 'GET_ACCOUNT_ANALYSIS_DATA_FAILURE' , 
    
}
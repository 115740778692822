import React, { Component } from "react";
import { connect } from "react-redux";
import { supportActions } from "../../actions";
import { Row, Col, Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { CustomerSupportSearchForm } from "./CustomerSupportSearchForm";

import { validReputeId, validPhone } from "../../../helpers";
import { CustomerSupportPINVerify } from "./VerifySupportPIN";
import { CustomerCompanies } from "./CustomerCompanies";
import { TableLoader } from "../../../components/Loaders";
import { SupportConstants } from "../../constants";

class CustomerSupportSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetched: false,
      
      searchdata: {
        ReputeId: "",
        MobileNo: "",
      },
      errors: {},
    };

    this.containerHeight = 0;
    this.currentTotalRecords = 0;
    this.handleChange = this.handleChange.bind(this);
    this.getSearchResult = this.getSearchResult.bind(this);
    this.reset = this.reset.bind(this);
  }

  /*
   *   Fetch Result action and validations
   */

  getSearchResult(event = false) {
    if (event) {
      event.preventDefault();
    }

    const { dispatch } = this.props;

    dispatch({ type: SupportConstants.VERIFY_SUPPORT_PIN_FAILURE });
    const { searchdata } = this.state;

    if (!searchdata.ReputeId && !searchdata.MobileNo) {
      this.setState({
        errors: {
          searchError: true,
          searchErrorMessage:
            "Please fill out atleat one field to search record.",
        },
      });
      return false;
    }

    if (searchdata.ReputeId && !validReputeId("RI-" + searchdata.ReputeId)) {
      this.setState({
        errors: {
          searchError: true,
          searchErrorMessage: "Please enter valid Repute ID.",
        },
      });
      return false;
    }

    if (searchdata.MobileNo && !validPhone(searchdata.MobileNo)) {
      this.setState({
        errors: {
          searchError: true,
          searchErrorMessage: "Please enter valid Mobile No.",
        },
      });
      return false;
    }

let searchData = {
      repute_id:
        searchdata.ReputeId === "" ? ""
          : searchdata.ReputeIdValue + "-" + searchdata.ReputeId,
      mobile_no: searchdata.MobileNo,
    };

    this.setState({
      isFetched: true,
    });

    dispatch(supportActions.searchCustomer(searchData));
  }

  /*
   *   action perform on component render
   */

  componentDidMount() {
    this.reset();
  }

  /*
   *   reset search form
   */

  reset() {
    const { dispatch } = this.props;
    this.setState({
      isFetched: false,
      searchdata: {
        ReputeIdValue: "RI",
        ReputeId: "",
        MobileNo: "",
      },
      errors: {},
    });

    dispatch({ type: SupportConstants.CUSTOMER_SUPPORT_SEARCH_FAILURE });
    dispatch({ type: SupportConstants.VERIFY_SUPPORT_PIN_FAILURE });
  }

  /*
   *   handle change event of inputs
   */
  handleChange(event) {
    const { name, value } = event.target;
    const { searchdata } = this.state;
    let val = value;

    this.setState({
      searchdata: {
        ...searchdata,
        [name]: val,
      },
      errors: {},
    });
  }

  render() {
    const { searchdata, errors, isFetched } = this.state;
    const { data, searching, companyList } = this.props;

    let individual = false;
    if (data && data.individuals[0]) {
      individual = data.individuals[0];
    }
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col md="3">
                <i class="fas fa-headset"></i> &nbsp;
                <strong> Customer Support</strong>
              </Col>
              <Col md="9">
                <CustomerSupportSearchForm
                  handleChangeSelect={this.handleChangeSelect}
                  searchdata={searchdata}
                  handleChange={this.handleChange}
                  getSearchResult={this.getSearchResult}
                  searchResult={data}
                  searching={searching}
                  errors={errors}
                  reset={this.reset}
                />
              </Col>
            </Row>
          </CardBody>

          {isFetched ? (
            <React.Fragment>
              {searching ? (
                <TableLoader />
              ) : (
                <React.Fragment>
                  {individual ? (
                    <React.Fragment>
                      <Row>
                        <Col md="4">
                          <CardBody>
                            {" "}
                            <i class="fa fa-user"></i>{" "}
                            <b>
                              {individual.name} (
                              {individual.formated_individual_repute_id}){" "}
                            </b>{" "}
                          </CardBody>
                        </Col>
                        <Col md="8">
                          <Collapse isOpen={companyList ? false : true}>
                            <CardBody
                              style={{ borderBottom: "1px solid #c8ced3" }}
                            >
                              <CustomerSupportPINVerify
                                individual_repute_id={
                                  individual.individual_repute_id
                                }
                              />
                            </CardBody>
                          </Collapse>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) : (
                    <CardBody style={{ borderBottom: "1px solid #c8ced3" }}>
                      Customer Not Found
                    </CardBody>
                  )}

                  <CustomerCompanies
                    individual_repute_id={individual.individual_repute_id}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            ""
          )}
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, searching } = state.customerSearchData;
  const { companyList } = state.verifySupportPIN;

  return {
    data,
    searching,
    companyList,
  };
}

const connectedCustomerSupportSearch = connect(mapStateToProps)(
  CustomerSupportSearch
);
export { connectedCustomerSupportSearch as CustomerSupportSearch };

export const promocodeConstants = {
    GET_PROMOCODES_REQUEST: 'GET_PROMOCODES_REQUEST',
    GET_PROMOCODES_SUCCESS: 'GET_PROMOCODES_SUCCESS',
    GET_PROMOCODES_FAILURE: 'GET_PROMOCODES_FAILURE',


    GET_PROMOCODES_BANNER_REQUEST: 'GET_PROMOCODES_BANNER_REQUEST',
    GET_PROMOCODES_BANNER_SUCCESS: 'GET_PROMOCODES_BANNER_SUCCESS',
    GET_PROMOCODES_BANNER_FAILURE: 'GET_PROMOCODES_BANNER_FAILURE',


    SAVE_PROMOCODES_REQUEST: 'SAVE_PROMOCODES_REQUEST',
    SAVE_PROMOCODES_SUCCESS: 'SAVE_PROMOCODES_SUCCESS',
    SAVE_PROMOCODES_FAILURE: 'SAVE_PROMOCODES_FAILURE',

    DELETE_PROMOCODE_REQUEST: 'DELETE_PROMOCODE_REQUEST',
    DELETE_PROMOCODE_SUCCESS: 'DELETE_PROMOCODE_SUCCESS',
    DELETE_PROMOCODE_FAILURE: 'DELETE_PROMOCODE_FAILURE',

    EDIT_PROMOCODES_REQUEST: 'EDIT_PROMOCODES_REQUEST',
    EDIT_PROMOCODES_SUCCESS: 'EDIT_PROMOCODES_SUCCESS',
    EDIT_PROMOCODES_FAILURE: 'EDIT_PROMOCODES_FAILURE',

    SAVE_PROMOCODES_BANNER_REQUEST: 'SAVE_PROMOCODES_BANNER_REQUEST',
    SAVE_PROMOCODES_BANNER_SUCCESS: 'SAVE_PROMOCODES_BANNER_SUCCESS',
    SAVE_PROMOCODES_BANNER_FAILURE: 'SAVE_PROMOCODES_BANNER_FAILURE',


    DELETE_PROMOCODES_BANNER_REQUEST: 'DELETE_PROMOCODES_BANNER_REQUEST',
    DELETE_PROMOCODES_BANNER_SUCCESS: 'DELETE_PROMOCODES_BANNER_SUCCESS',
    DELETE_PROMOCODES_BANNER_FAILURE: 'DELETE_PROMOCODES_BANNER_FAILURE',
}
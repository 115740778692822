import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Button, CardBody, Col, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import swal from "sweetalert";
import { promocodeActions } from '../../actions';
import { InputText } from '../Settings/StageSetting/Components/FormElements';


const initialData = {
    description: '',
    status: 1
}

function PromoCodeBanner(props) {
    const [show, setShow] = useState(false)
    const [bannerImageWeb, setBannerImageWeb] = useState('')
    const [bannerImageMobile, setBannerImageMobile] = useState('')
    const [formData, setFormData] = useState({
        ...initialData
    })
    const [errors, setErrors] = useState({})
    const [dateData, setDateData] = useState({
        startDate: moment(),
        endDate: moment().add(30, 'day'),
        serachField: {
            dateRange: moment().format("DD MMM Y") + "-" + moment().add(30, 'day').format("DD MMM Y")
        }
    })


    const toggle = () => {
        setShow(!show)
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        let _value = value

        setFormData({ ...formData, [name]: _value })
        setErrors({ ...errors, [name]: '' })
    }

    const save = () => {
        const { dispatch } = props
        const { startDate, endDate } = dateData
        if (!valid()) {
            // let form_data = new FormData();
            
            // for (var key in formData) {
            //     form_data.append(key, formData[key]);
            // }
            // form_data.append('start_date', moment(startDate).format('YYYY/MM/DD'))
            // form_data.append('expiry_date', moment(endDate).format('YYYY/MM/DD'))
            // form_data.append('banner_image_web',bannerImageWeb)
            // form_data.append('banner_image_mobile', bannerImageMobile)
            
            let dataToSend = {
                ...formData,
                banner_image_web: bannerImageWeb,
                banner_image_mobile: bannerImageMobile,
                start_date: moment(startDate).format('YYYY/MM/DD'),
                expiry_date: moment(endDate).format('YYYY/MM/DD')
            }
            dispatch(promocodeActions.saveBanner(dataToSend, {
                success: afterSave
            }))
        }
    }

    const afterSave = () => {
        reset()
        getData()
        toggle()
    }

    const reset = () => {
        setFormData({ ...initialData })
        setErrors({})
        setBannerImageWeb('')
        setBannerImageMobile('')
    }

    const valid = () => {
        const { description } = formData
        const { startDate, endDate } = dateData
        let error = false
        setErrors({});


        if (!description) {
            errors.description = 'Please enter description'
            error = true
        }

        if (!startDate && !endDate) {
            errors.expiry_date = 'Please select date range  '
            error = true
        }

        if (!bannerImageWeb) {
            errors.banner_image_web = 'Please Upload Image  '
            error = true
        }
        if (!bannerImageMobile) {
            errors.banner_image_mobile  = 'Please Upload Image  '
            error = true
        }

        setErrors({
            ...errors
        })
        return error

    }

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        const { dispatch } = props
        dispatch(promocodeActions.getBanner())
    }

    const handleDelete = (id) => {
        const { dispatch } = props

        let dataToSend = { id: id }

        swal({
            title: "Are you sure ?",
            text: `Do you want to delete this banner ?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Yes, I am sure!"],
        }).then((confirm) => {
            if (confirm) {
                dispatch(promocodeActions.deleteBanner(dataToSend, {
                    success: getData
                }))
            }
        })

    }

    const handleEdit = (data, type) => {

        const { dispatch } = props
        const { id, description, banner_image, start_date, expiry_date } = data
        let dataToSend = {
            id,
            [type]: data[type] === 0 ? 1 : 0,
            description,
            banner_image,
            start_date,
            expiry_date
        }
        dispatch(promocodeActions.saveBanner(dataToSend, {
            success: getData
        }))


    }


    const handleEvent = (event, picker) => {
        var start = picker.startDate.format("DD MMM Y");
        var end = picker.endDate.format("DD MMM Y");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }
        setDateData({
            ...dateData,
            startDate: picker.startDate,
            endDate: picker.endDate,
            serachField: {
                dateRange: label
            },
        })
    }

    const handleFileChange = (event) => {

        const file = event.target.files[0];
        const name = event.target.name
        if (name === 'banner_image_web') {
            setBannerImageWeb(file)
        }
        if (name === 'banner_image_mobile') {
            setBannerImageMobile(file)
        }

    };

    const { startDate, endDate, serachField } = dateData
    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                  
                        <CardBody>
                            <span className='pull-right' ><Button size='sm' color='primary' onClick={toggle} > + Add Banner </Button> </span>

                            <div className="table-responsive-sm mt-5">
                                <table className="table table-hover  ">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Banner Image Web</th>
                                            <th>Banner Image Mobile</th>
                                            <th>Description</th>
                                            <th>Start At </th>
                                            <th>Expires At </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            props.data && props.data.length ? props.data.map((eachData, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td> <img src={eachData.banner_image_web}  alt="banner image" style={{maxWidth: "100px", maxHeight: "100px"}} /> </td>
                                                        <td> <img src={eachData.banner_image_mobile} alt="banner image"  style={{maxWidth: "100px", maxHeight: "100px"}} /> </td>
                                                        <td >{eachData.description}</td>
                                                        <td>{eachData.start_date}</td>
                                                        <td>{eachData.expiry_date}</td>
                                                        <td className='d-flex' >
                                                            <input className=' d-block ' type="checkbox" checked={eachData.status} onClick={() => handleEdit(eachData, 'status')} />
                                                            <span className={`mx-2 badge ${eachData.status ? 'bg-success' : 'bg-danger'}`} >{eachData.status ? 'Active' : 'Inactive'}</span>
                                                        </td>
                                                        <td> <span className='cursor-pointer' onClick={() => handleDelete(eachData.id, eachData.code)} > <i className="fa fa-trash-o text-danger font-xl"></i> </span> </td>
                                                    </tr>
                                                )
                                            })
                                                :
                                                <tr>
                                                    <td colSpan={7} className='text-center' >No Data Found </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                   
                </Col>
            </Row>
            <Modal
                size='md'
                isOpen={show}
            >
                <ModalHeader
                    toggle={toggle} >
                    Add New Banner
                </ModalHeader>
                <ModalBody>
                    <div className='' >
                        <div >
                            <div className='mb-2' >Select Range<span className='text-danger' >*</span> </div>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onEvent={handleEvent}
                            >
                                <div className="input-group">
                                    <input type="text" className="form-control" value={serachField.dateRange} placeholder="Select Date Range" id="date_picker" style={{ 'width': '220px' }} onClick={e => e.preventDefault()} />

                                </div>
                            </DateRangePicker>
                        </div>
                        <div className='' >
                            <InputText
                                isRequired
                                error={errors.description}
                                title={"Description"}
                                placeholder="Description"
                                value={formData.description}
                                name="description"
                                onChange={handleChange}
                            />
                        </div>
                        <div  >
                            <label>Banner Image Web </label>
                            <Input placeholder="Banner Image Web " type='file' name={`banner_image_web`} onChange={handleFileChange} accept="image/png, image/gif, image/jpeg" />
                            <FormFeedback>
                                {errors[`banner_image_web`] && errors[`banner_image_web`]}
                            </FormFeedback>
                        </div>
                        <div  >
                            <label>Banner Image Mobile</label>
                            <Input placeholder="Banner Image Mobile " type='file' name={`banner_image_mobile`} onChange={handleFileChange} accept="image/png, image/gif, image/jpeg" />
                            <FormFeedback>
                                {errors[`banner_image_mobile`] && errors[`banner_image_mobile`]}
                            </FormFeedback>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={save}>
                        Save
                    </Button>{" "}
                    <Button color="info" onClick={reset}>
                        Reset
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>

    )
}



function mapStateToProps(state) {
    const { loading, data } = state.getBanner
    return { loading, data }
}

const connectedPromoCodeBanner = connect(mapStateToProps)(PromoCodeBanner);
export default connectedPromoCodeBanner;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableLoader } from "../../../components/Loaders";
import { Col, Row, Button, FormFeedback, Modal, ModalHeader, ModalFooter, ModalBody, Input, Form,FormGroup } from 'reactstrap';
import { requirementLogsAction } from '../../actions';
import LocationSearchInput from '../../../components/Forms/LocationSearchInput';
import  GoogleApiWrapper  from '../../../components/GoogleMap'
class UpdateLocation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false,
            isSubmit: false,
            formData: {
                lat : '',
				long : '',
				location_data : '',
            },
            errors: {},
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.reset = this.reset.bind(this);
    }
	updateData(name,value) {
		const { formData,errors } = this.state;
		formData[name] = value
		errors['lat'] = ''
		this.setState({ formData })
	}

    handleToggle() {
        let { thisModel, isSubmit } = this.state;
        thisModel = !thisModel
        this.setState({
            thisModel: thisModel,
        },()=>{
            if(thisModel == false) {
                this.reset()
            }
        })
    }

    isValid() {
        const { formData, errors } = this.state
        let error = false;

        if (!formData.lat) {
			error = true
			errors.lat = 'this field is requird'
		}

        this.setState({ errors })
        return error;
    }
    handelSubmit() {
        if (!this.isValid()) {
            const { formData } = this.state
            const { dispatch, requirement_details } = this.props
            let dataToSend = {
                lat : formData.lat,
				long : formData.long,
				location_data : formData.location_data,
                buyer_requirement_id : requirement_details.id
            }
            dispatch(requirementLogsAction.updateRequirementLocation(dataToSend, {
                success: () => this.success()
            }));
        }
    }
    success() {
        this.handleToggle();
    }

    reset() {
		const { formData } = this.state;
        const { getData }  = this.props
		formData.lat =  ''
		formData.long = ''
		formData.location_data = ''
		this.setState({
			formData,
			errors: {}
		});
        if (typeof getData === "function") {
            getData();
        }

	}
    componentDidMount() {
        const { requirement_details } = this.props
        const { formData } = this.state;
        if(requirement_details) {

            formData.lat =  requirement_details.lat
            formData.long = requirement_details.long
            formData.location_data = requirement_details.delivery_location
    
            this.setState({ formData })
        }
    }
    render() {
        const { formData, thisModel, errors } = this.state
        const { loading, data, requirement_details, invite_count } = this.props
       
        return (
            <React.Fragment>
                <span className="pull-right" onClick={this.handleToggle}><i className="fa fa-edit"></i></span>
                {/* <Button size="sm"  color="primary" onClick={this.handleToggle}>Invitees { invite_count  > 0 ?  <label className="badge badge-light mb-0">{invite_count}</label>  : false }</Button> */}
                <Modal className="modal-primary" isOpen={thisModel} backdrop="static" size="md" toggle={this.handleToggle} >
                    <ModalHeader toggle={this.handleToggle}> Update Location  </ModalHeader>
                    <ModalBody style={{ minHeight: '450px' }}>
                        {loading ? <TableLoader /> :
                            (
                                <Form>
                                                                    <FormGroup >
									<label><b>Location</b></label>
									<LocationSearchInput updateData={this.updateData} address={formData.location_data} />
									{errors && errors.lat && (<FormFeedback> {errors.lat} </FormFeedback>)}
								</FormGroup>
                                <FormGroup>
                                    <div style={{position:"relative", width:"100%"}}>
                                        
									{
										formData.lat && formData.long ?
										<GoogleApiWrapper selectedPlace={formData} height={330}  width={"100%"} updateData={this.updateData} />	
										: ''
									}
                                    
                                    </div>
                                    </FormGroup>
                                </Form>
                            )}
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={() => this.handelSubmit()}>
								Save
							</Button>{" "}
                        <Button onClick={this.handleToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getBuyerRequirementInvites
    return { loading, data }
}
const UpdateLocationConnected = connect(mapStateToProps)(UpdateLocation);
export { UpdateLocationConnected as UpdateLocation }
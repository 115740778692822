import React, { Component } from 'react'
import { connect } from 'react-redux'
import filterFactory, {textFilter} from "react-bootstrap-table2-filter"
import { Row, Col, CardBody, Card, CardHeader, Button, Input, FormFeedback } from 'reactstrap'
import { Link } from "react-router-dom"
import { DataTable } from '../../../components/Tables/DataTable'
import { notify } from 'reapop';
import swal from 'sweetalert'
import { emailActions } from "../../actions";
import { TableLoader } from '../../../components/Loaders';
import { show, hide } from 'redux-modal'
import { validEmail } from '../../../helpers';

class Emails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formdata: [],
      errors: {}
    }
    this.getEmails = this.getEmails.bind(this);
    this.renderAction = this.renderAction.bind(this)
    this.handelPublishData = this.handelPublishData.bind(this)
    this.semdEmail = this.semdEmail.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.isValidate = this.isValidate.bind(this)
    this.handelHideModel = this.handelHideModel.bind(this)
  }

  componentDidMount() {
    this.getEmails()
  }

  getEmails() {
    const { dispatch } = this.props;
    dispatch(emailActions.getEmails());
  }

  handelPublishData(data) {
    const { dispatch } = this.props;
    dispatch(emailActions.publishEmail(data, this));
  }

  handleChange(e) {
    e.preventDefault()
    const { formdata, errors } = this.state
    formdata[e.target.name] = e.target.value
    errors[e.target.name] = ""
    this.isValidate();
    this.setState({ formdata })
  }

  isValidate() {
    let errors = {};
    if (!validEmail(this.state.formdata.recipient_email)) {
      errors["recipient_email"] = "Please enter valid email address"
      this.setState({ errors });
      return false;
    }
    return true
  }


  semdEmail(data) {
    const { dispatch } = this.props;

    if (!this.isValidate()) {
      dispatch(notify({ position: "tc", dismissible: false, message: "Enter valid email address", status: "error" }));

      return false;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to send email to entered recipient email?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let dataToSent = {
          email: this.state.formdata.recipient_email,
          type: data.type
        };

        dispatch(emailActions.sendEmail(dataToSent, this));
      }
    });
  }
  handelHideModel() {
    const { dispatch } = this.props;
    dispatch(hide("bootstrap"))
  }
  renderAction(cell, row, rowIndex, formateExtraData) {
    const { dispatch, publishing } = this.props;

    const confirmPublish = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to publish from draft ? It will visible on reputeinfo.com",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let dataToSent = {
            id: this.id,
            type: row.type
          };
          this.handelPublishData(dataToSent)
        }
      });
    }




    const testEmail = () => {
      dispatch(show("bootstrap", {
        ModelSize: "md",
        ModelHeaderText: "Test Email",
        ModelBodyText: <div>
          <Input type="email" placeholder="Recipient Email Address" required name={"recipient_email"} value={this.state.formdata.recipient_email} onChange={this.handleChange} />
          <FormFeedback> {this.state.errors.recipient_email}  </FormFeedback>
        </div>,
        ActionFunction: (e) => this.semdEmail(row),
        ActionButtonText: "Send Email"
      }));

    }
    return <React.Fragment>
      {parseInt(row.sub_version, 10) === 0 ?
        <Link className="btn btn-success btn-sm" to={"emails/edit/" + row.id}> New draft </Link> :
        <Link className="btn btn-primary btn-sm" to={"emails/edit/" + row.id}> Edit </Link>} {" "}
      {parseInt(row.sub_version, 10) === 0 ? "" :
        <Button className="btn btn-danger btn-sm" onClick={(e) => confirmPublish()} to={"update_notification/" + row.id}> {publishing ? "Please Wait..." : "Publish to Reputeinfo"} </Button>} &nbsp;
      <Button className="btn btn-warning btn-sm" onClick={(e) => testEmail()} > Test </Button>
    </React.Fragment>
  }
  renderVersion(cell, row, rowIndex, formateExtraData) {
    return row.version + "." + row.sub_version;
  }
  renderEmailText(cell, row, rowIndex, formateExtraData) {
    if (row.email_text.includes("<")) {
      row.short_description = row.email_text.replace(/<[^>]*>/g, '')
    }
    else {
      row.short_description = row.email_text;
    }
    row.short_description = row.short_description.length > 80 ? row.short_description.slice(0, 80) + '.....' : row.short_description
    return row.short_description
  }
  render() {
    const { data, loading, count } = this.props
    const columns = [{
      text: 'Subject',
      dataField: 'subject',
      sort: true,
      filter: textFilter({
        placeholder: 'Subject'
      }),
    }, {
      text: 'Description',
      dataField: "description",
      sort: true,
      filter: textFilter({
        placeholder: 'Description'
      }),
    },
    {
      text: 'Last Published',
      dataField: 'last_published',
      sort: true
    }, {
      text: 'Last Saved on',
      dataField: 'updated_at',
    }, {
      text: 'Version',
      dataField:'',
      formatter: this.renderVersion,
    },
    {
      text: 'Action',
      dataField:'id',
      formatter: this.renderAction
    }
    ]

    return (
      <div className="">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                <i className="fa fa-envelope"></i><strong>Emails</strong> <span className="badge badge-primary">{count ? count : 0} </span>
              </CardHeader>
              <CardBody>
                {loading ? <TableLoader /> :
                  <DataTable keyField="id"
                    data={data || []} columns={columns}
                    filter={filterFactory()}
                    noDataIndication={"No data found"} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getEmails
  const { user } = state.authentication
  return {
    loading,
    data : data ? data.email_data : [],
    count : data ? data.total_email : 0,
    authUser: user
  }
}

const connectedEmails = connect(mapStateToProps)(Emails)
export { connectedEmails as Emails }

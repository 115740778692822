import { PERMISSIONS } from "../../individual/constants";

export function getNavigation(data) {
    
    let navigations = data.navigations || [];
    let authentication = data.authentication;

    let permissions =  authentication.user ? (authentication.user.permission ? authentication.user.permission.split(',') : [] ) : [];

    let new_nav = {
        items: []
    }

    // for all permission 
    if (permissions.indexOf(PERMISSIONS.ALL) !== -1) {
        return navigations;
    }

    navigations.items.map(function (eachNav) {
        if (eachNav.children) {
            let newChilds = generateChild(eachNav, permissions)
            if (newChilds.children.length) {
                new_nav.items.push(newChilds);
            }
        } else {
            if (permissions.indexOf(eachNav.menu_id) !== -1) {
                new_nav.items.push(eachNav);
            }
        }
        return false
    })

    return new_nav;
}

function generateChild(parent, permissions) {
    let new_parent = { ...parent};
    new_parent.children = [];

    parent.children.map(function (eachNav) {
        
        if (eachNav.children) {
            let newChilds = generateChild(eachNav, permissions)
            if (newChilds.children.length) {
                new_parent.children.push(newChilds);
            }
        } else {

            if (permissions.indexOf(eachNav.menu_id) !== -1) {
                new_parent.children.push(eachNav);
            }
        }
        return false
    })

    return new_parent;
}


import moment from 'moment';
import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import { TableLoader } from '../../../components/Loaders';
import { contactusDetailActions } from "../../actions";
import CallDone from './CallDone';


class ContactUsDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            },
        }
        this.getContactusDetail = this.getContactusDetail.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCalledButton = this.handleCalledButton.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        this.getContactusDetail()
    }


    handleCalledButton(data) {
        const { dispatch } = this.props
        let dataToSend = {
            id: data,
            status: 1
        }
        swal({
            title: "Are you sure you called this number?",
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((confirm) => {
            if (confirm) {
                dispatch(contactusDetailActions.postContactUsCallStatus(dataToSend, this));
            }
        });
    }


    getContactusDetail() {
        const { dispatch } = this.props;
        const { page, keywords } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            keywords: keywords
        }
        dispatch(contactusDetailActions.getContactusDetail(dataToSend));
    }
    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getContactusDetail());

    }
    componentWillReceiveProps(props) {
        let { page } = this.state
        page.totalItemsCount = 0;
        if (props.data) {
            page.totalItemsCount = props.data.total_records
        }
        this.setState({
            page,
        })
    }

    handleChange(e) {
        const { name, value } = e.target

        this.setState({
            ...this.state, [name]: value
        })
    }
    handleSearch(e) {
        const { page } = this.state

        this.setState({
            ...this.state, page: {
                ...page, activePage: 1
            }
        }, () => this.getContactusDetail())
    }

    render() {
        let { loading, data } = this.props
        const { page, keywords } = this.state;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="icon-directions"></i> <b>ContactUs Detail</b></CardHeader>
                            <CardBody>
                                <div className="d-flex mb-2 " >
                                    <Input name='keywords' className='w-25 mr-2' value={keywords} onChange={this.handleChange} placeholder='Search...' />
                                    <Button color='primary' onClick={this.handleSearch}>
                                        Search
                                    </Button>
                                </div>
                                {
                                    loading ? <TableLoader /> :
                                        <div className="table-responsive-sm  animated fadeIn">
                                            <table className="table table-sm table-condensed  table-bordered  table-outline ">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Company Name</th>
                                                        <th>Pin Code</th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Subject</th>
                                                        <th>Message</th>
                                                        <th>Date & Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data && data.contact_list && data.contact_list.length > 0 ?
                                                            data.contact_list.map((d, i) => {
                                                                return (<tr>
                                                                    <td>{d.first_name + ' ' + d.last_name}</td>
                                                                    <td>{d.company_name}</td>
                                                                    <td>{d.pincode}</td>
                                                                    <td>{d.email}</td>
                                                                    <td>{d.mobile}</td>
                                                                    <td>{d.subject}</td>
                                                                    <td style={{ 'width': '50%' }}>{d.message}</td>

                                                                    <td style={{ 'width': '10%' }}>{moment(d.created_at).format('DD MMM YYYY hh:mm A')}</td>
                                                                    <td>
                                                                        {
                                                                            <CallDone
                                                                                status={d.is_call_done}
                                                                                handleCalledButton={this.handleCalledButton}
                                                                                _id={d.id}
                                                                            />
                                                                        }                                                            </td>
                                                                </tr>)
                                                            })
                                                            : <tr><td colSpan="9" className="text-center" style={{ "background-color": "rgba(0, 0, 0, 0.05)" }}>No Data Found</td></tr>

                                                    }
                                                </tbody>
                                            </table>

                                            {page.totalItemsCount <= page.perPage ? "" :
                                                <Pagination
                                                    activePage={page.activePage}
                                                    itemsCountPerPage={page.perPage}
                                                    totalItemsCount={page.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    onChange={this.handlePageChange}
                                                // hideNavigation={false}
                                                // hideFirstLastPages={true}
                                                />}
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getContactusDetail
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user

    }
}
const connectedContactUsDetail = connect(mapStateToProps)(ContactUsDetail)
export { connectedContactUsDetail as ContactUsDetail };


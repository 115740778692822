import { notify } from 'reapop'
import { subscriptionConst } from '../constants'
import { subscriptionService } from '../services'

export const subscriptionActions = {
    getSubscription,
    updateSubscription
}
function getSubscription(data) {
    return dispatch => {
        dispatch(request())

        subscriptionService.getSubscription(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: subscriptionConst.GET_SUBSCRIPTION_REQUEST } }
    function success(result) { return { type: subscriptionConst.GET_SUBSCRIPTION_SUCCESS, result } }
    function failure(error) { return { type: subscriptionConst.GET_SUBSCRIPTION_FAILURE, error } }
}

function updateSubscription(data,ref) {
    return dispatch => {
        dispatch(request())

        subscriptionService.updateSubscription(data)
            .then(
                result =>{  
                    dispatch(success(result));
           
                    dispatch(notify({ position: "tc",  message: 'Updated successfully', status: "success" }))
                    ref.afterAction()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: subscriptionConst.UPDATE_SUBSCRIPTION_REQUEST } }
    function success(result) { return { type: subscriptionConst.UPDATE_SUBSCRIPTION_SUCCESS, result } }
    function failure(error) { return { type: subscriptionConst.UPDATE_SUBSCRIPTION_FAILURE, error } }
}

export const SupportConstants = {
    CUSTOMER_SUPPORT_SEARCH_REQUEST: 'CUSTOMER_SUPPORT_SEARCH_REQUEST',
    CUSTOMER_SUPPORT_SEARCH_SUCCESS: 'CUSTOMER_SUPPORT_SEARCH_SUCCESS',
    CUSTOMER_SUPPORT_SEARCH_FAILURE: 'CUSTOMER_SUPPORT_SEARCH_FAILURE',

    VERIFY_SUPPORT_PIN_REQUEST: 'VERIFY_SUPPORT_PIN_REQUEST',
    VERIFY_SUPPORT_PIN_SUCCESS: 'VERIFY_SUPPORT_PIN_SUCCESS',
    VERIFY_SUPPORT_PIN_FAILURE: 'VERIFY_SUPPORT_PIN_FAILURE',

    
    CREATE_SUPPORT_TICKET_REQUEST: 'CREATE_SUPPORT_TICKET_REQUEST',
    CREATE_SUPPORT_TICKET_SUCCESS: 'CREATE_SUPPORT_TICKET_SUCCESS',
    CREATE_SUPPORT_TICKET_FAILURE: 'CREATE_SUPPORT_TICKET_FAILURE',

    GET_TICKET_DETAILS_REQUEST: 'GET_TICKET_DETAILS_REQUEST',
    GET_TICKET_DETAILS_SUCCESS: 'GET_TICKET_DETAILS_SUCCESS',
    GET_TICKET_DETAILS_FAILURE: 'GET_TICKET_DETAILS_FAILURE',

    
    CHANGE_TICKET_STATUS_REQUEST: 'CHANGE_TICKET_STATUS_REQUEST',
    CHANGE_TICKET_STATUS_SUCCESS: 'CHANGE_TICKET_STATUS_SUCCESS',
    CHANGE_TICKET_STATUS_FAILURE: 'CHANGE_TICKET_STATUS_FAILURE',
    
    SAVE_TICKET_MESSAGE_REQUEST: 'SAVE_TICKET_MESSAGE_REQUEST',
    SAVE_TICKET_MESSAGE_SUCCESS: 'SAVE_TICKET_MESSAGE_SUCCESS',
    SAVE_TICKET_MESSAGE_FAILURE: 'SAVE_TICKET_MESSAGE_FAILURE',

    TICKETS_LIST_REQUEST: 'TICKETS_LIST_REQUEST',
    TICKETS_LIST_SUCCESS: 'TICKETS_LIST_SUCCESS',
    TICKETS_LIST_FAILURE: 'TICKETS_LIST_FAILURE',
}
import { notify } from 'reapop'
import { tasksService } from '../services'
import { tasksConstants } from '../constants'

export const tasksActions = {
    saveTasks,
    getTasks
    
}



function getTasks(data) {
    return dispatch => {
        dispatch(request())

        tasksService.getTasks(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: tasksConstants.GET_TASKS_DATA_REQUEST } }
    function success(result) { return { type: tasksConstants.GET_TASKS_DATA_SUCCESS, result } }
    function failure(error) { return { type: tasksConstants.GET_TASKS_DATA_FAILURE, error } }
}

function saveTasks(data, callback) {
    return dispatch => {
        dispatch(request())

        tasksService.saveTasks(data)
            .then(
                data => {
                    dispatch(success(data))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: tasksConstants.SAVE_TASKS_DATA_FAILURE } }
    function success(result) { return { type: tasksConstants.SAVE_TASKS_DATA_SUCCESS, result } }
    function failure(error) { return { type: tasksConstants.SAVE_TASKS_DATA_FAILURE, error } }
}

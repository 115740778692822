import { informationConstants } from "../constants"


export function getinformations(state = {}, action) {
  switch (action.type) {
    case informationConstants.GET_INFORMATION_REQUEST:
      return { loading: true }
    case informationConstants.GET_INFORMATION_SUCCESS:
      return { data: action.result }
    case informationConstants.GET_INFORMATION_FAILURE:
      return {}
    default:
      return state
  }
}

export function addEditinformations(state = {}, action) {
    switch (action.type) {
      case informationConstants.ADD__EDIT_INFORMATION_REQUEST:
        return { loading: true }
      case informationConstants.ADD__EDIT_INFORMATION_SUCCESS:
        return { data: action.result }
      case informationConstants.ADD__EDIT_INFORMATION_FAILURE:
        return {}
      default:
        return state
    }
  }
  
import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const profileService = {
    getProfile,
    updateBankDetail,
    upgradeUserAccount,
    getDepositPayments,
    getCommissionPayments,
    getHeaderDataCount,
    updatePAN
}

function getHeaderDataCount(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_header_data_counts`, form_data, setting).then(handleResponse)
}

function getProfile(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_profile`, form_data, setting).then(handleResponse)
}

function getDepositPayments(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_deposits_payments`, form_data, setting).then(handleResponse)
}

function getCommissionPayments(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_commission_payments`, form_data, setting).then(handleResponse)
}

function updateBankDetail(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/updateBankDetail`, form_data, setting).then(handleResponse)
}

function updatePAN(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/updatePAN`, form_data, setting).then(handleResponse)
}
function upgradeUserAccount(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/upgrade_user_account`, form_data, setting).then(handleResponse)
}
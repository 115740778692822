import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'react-quill/dist/quill.snow.css'
import { Button, Form, FormFeedback, Input, FormGroup, FormText, Collapse } from 'reactstrap'
import { handleValidation } from './Validation'
import { notificationActions } from '../../actions'
import { TableLoader } from '../../../components/Loaders';
import { Link } from "react-router-dom";
import { DATA_TO_REPLACE_FOR_LIVE_PREVIEW } from '../../constants'

class AddNotification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addNotification: {
        id: '',
        notification_text: '',
        notification_type: '',
        help_text: ''
      },
      isChange: false,
      errors: {},
      saving: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.getNotifications = this.getNotifications.bind(this)
    this.onSubmitNotification = this.onSubmitNotification.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
  }

  handleChange(e) {
    e.preventDefault()
    const { addNotification } = this.state
    addNotification[e.target.name] = e.target.value
    this.setState({ addNotification })
  }

  componentDidMount() {
    this.getNotifications()
  }
  getNotifications() {
    const { dispatch } = this.props;
    const values = this.props.match.params.id
    let dataToSend = {
      id: values
    }
    dispatch(notificationActions.getNotifications(dataToSend))
  }
  onSubmitNotification() {
    const { addNotification } = this.state
    const result = handleValidation(addNotification)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      const { dispatch } = this.props;
      this.setState({ saving: true })
      dispatch(notificationActions.editNotification(this.state.addNotification, this))
      //localStorage.setItem('notificationActiveTab', this.state.addNotification.notification_category)
    }
  }

  afterSave() {
    const { addNotification } = this.state
    this.props.history.push('/notification/notifications?tab=' + addNotification.notification_category)
  }
  componentWillReceiveProps(props) {
    const { data } = props
    if (data) {
      this.setState({ addNotification: data })
    }
  }
  toggleChange(e){
    e.preventDefault();
    this.setState({
      isChange : true
    });

  }

  renderLivePreview(text){
    for (let key in DATA_TO_REPLACE_FOR_LIVE_PREVIEW) {
      text = text.replace(key, DATA_TO_REPLACE_FOR_LIVE_PREVIEW[key])
    }
    return text
  }
  
  render() {
    const { errors, saving, addNotification, isChange } = this.state
    const { loading } = this.props

    return (
      <div className="">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center mb-4 mt-4"> Update Notification </h3>

            {loading ? <TableLoader /> :
              <Form  autocomplete="off" className="col-md-6 offset-md-3">

                <FormGroup>
                  <label className="font-weight-bold">Notification Text</label>
                  <span className="text-muted pull-right">{ parseInt(addNotification.notification_text.length,10 )} characters </span>
                  <Input placeholder="Notification Text" type="textarea" rows="5" name="notification_text" value={this.state.addNotification.notification_text} onChange={this.handleChange} />

                  <FormText style={isChange ? { display: "none" } : {}}> <i> <b className="text-info"> {addNotification.help_text}  </b> </i>   <a className="text-danger pull-right font-weight-bold cursor-pointer" onClick={ this.toggleChange} > Change </a></FormText>

                  <FormFeedback>
                    {errors[`notification_text`] && errors[`notification_text`]}
                  </FormFeedback>
                </FormGroup>

                <Collapse isOpen={isChange}>
                  <FormGroup>
                    <label className="font-weight-bold">Help Text</label>
                    <Input placeholder="Notification Text" type="text" rows="5" name="help_text" value={this.state.addNotification.help_text} onChange={this.handleChange} />
                    <FormFeedback>
                      {errors[`help_text`] && errors[`help_text`]}
                    </FormFeedback>
                  </FormGroup>
                </Collapse>

                
                <FormGroup>
                <label className="font-weight-bold">Live Preview</label>   <span className="text-muted pull-right">{ parseInt(this.renderLivePreview(addNotification.notification_text).length,10 )} characters </span><br/>
                 <span className="text-muted">{  this.renderLivePreview(addNotification.notification_text) } </span>
                  </FormGroup>


                <FormGroup>
                  <Button color="primary" onClick={this.onSubmitNotification} disabled={saving}>{saving ? "Saving..." : "Save Draft"}</Button>&nbsp;&nbsp;&nbsp;
                    <Link className="btn btn-secondary" to={"/notification/notifications"}> Cancel </Link>
                </FormGroup>

              </Form>}
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { loading, data } = state.getNotifications
  const { user } = state.authentication
  return {
    loading,
    data :  data ? data.notification_data : {},
    authUser: user
  }

}

const connectedAddNotification = connect(mapStateToProps)(AddNotification)
export { connectedAddNotification as AddNotification }

import { landingPageData } from "../constants"


export function getLandingPageData(state = {}, action) {
    switch (action.type) {
        case landingPageData.GET_LANDING_PAGE_DATA_REQUEST:
            return { loading: true }
        case landingPageData.GET_LANDING_PAGE_DATA_SUCCESS:
            return { data: action.result }
        case landingPageData.GET_LANDING_PAGE_DATA_FAILURE:
            return {}
        default:
            return state
    }
}
export function getLandingPageUserData(state = {}, action) {
    switch (action.type) {
        case landingPageData.GET_LANDING_PAGE_USER_DATA_REQUEST:
            return { loading: true }
        case landingPageData.GET_LANDING_PAGE_USER_DATA_SUCCESS:
            return { userData: action.result }
        case landingPageData.GET_LANDING_PAGE_USER_DATA_FAILURE:
            return {}
        default:
            return state
    }
}


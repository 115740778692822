import { ExpensesConstants } from "../constants"


export function getExpenses(state = {}, action) {
    switch (action.type) {
        case ExpensesConstants.GET_EXPENSES_REQUEST:
            return { loading: true }
        case ExpensesConstants.GET_EXPENSES_SUCCESS:
            return action.result
        case ExpensesConstants.GET_EXPENSES_FAIL:
            return {}
        default:
            return state
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Label, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { TableLoader } from '../../../../components/Loaders';
import { communicationMastersTemplatesActions } from '../../../actions/communicationMastersTemplate.action';
import { InputDropDown, InputText } from '../StageSetting/Components/FormElements';
import { CommunicationChannelTemplateModal } from './Components/CommunicationChannelTemplateModal';

const defaultData = {
    name: "",
    category: 1,
    tags: "",
    is_hook: false,
}
class CommunicationMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: { ...defaultData },
        }
        this.toggle = this.toggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.reset = this.reset.bind(this)
        this.edit = this.edit.bind(this)
        this.getData = this.getData.bind(this)
    }

    reset() {

    }


    handleChange(event) {
        let _dt = event.target
        if (typeof event.target === "undefined") {
            _dt = event;
        }
        const { name, value, type } = _dt;
        const { data } = this.state;

        if (type === 'checkbox') {
            data[name] = !data[name]
        } else {
            data[name] = value;
        }
        this.setState(data);
    }

    save() {
        const { data, isEdit } = this.state;

        const { dispatch } = this.props;

        // let fields = data.field.map((item) => (item.id));   
        let dataToSend = {
            name: data.name,
            category: data.category,
            tags: data.tags,
            is_hook: data.is_hook ? 1 : 0,
        };

        if (isEdit) {
            dataToSend.id = data.id
        }

        this.setState({
            show: false,
            isEdit: false,
            data: { ...defaultData }
        });

        dispatch(communicationMastersTemplatesActions.saveCommunicationMastersTemplates(dataToSend, {
            success: () => {
                this.getData();
            }
        }))



    }

    edit(editData) {

        let { data } = this.state;
        data = editData;
        this.setState({ isEdit: true, show: true, data });
    }


    toggle(value) {
        let { data, isEdit } = this.props;

        if (isEdit) {

            this.setState({
                show: value,
                data
            });

        } else {

            this.setState({
                show: value,
                data: { ...defaultData }
            });
        }
    }

    componentDidMount() {

        this.getData();
    }

    getData() {
        const { dispatch } = this.props;

        dispatch(communicationMastersTemplatesActions.getCommunicationMastersTemplates())

    }

    remove(ld) {
        const { dispatch } = this.props;

        dispatch(communicationMastersTemplatesActions.saveCommunicationMastersTemplates({
            action: "remove",
            id: ld,
        }, {
            success: () => {
                this.getData();
            }
        }))

    }

    render() {
        const { data, show, isEdit } = this.state
        const { templates, loading } = this.props
        console.log(templates);
        let _this = this;
        return (
            <div>
                <Card>
                    <CardHeader>
                        Communication Master Lists
                        <Button color="primary" className="pull-right" onClick={() => {
                            this.toggle(true)
                            this.setState({
                                isEdit: false,
                                data: defaultData
                            })
                        }}
                        >Add Communication Master </Button>
                    </CardHeader>
                    <CardBody>
                        {loading ? <TableLoader /> :

                            <Row>
                                {templates && templates.length ? templates.map((item, idx) => {
                                  
                                        return <Col md="3">
                                            <Card style={{backgroundColor: item.schedule_whatsapp_template && item.schedule_whatsapp_template.status === 'active' ? "#fff" : "#ccc"}}>
                                                <CardBody >

                                                    <div style={{ display: 'inline-block' }}>
                                                        <h5>{item.name}</h5>
                                                    
                                                        <h6>
                                                            {item.schedule_whatsapp_template ? item.schedule_whatsapp_template.title : ''}
                                                        </h6>
                                                        <h6> 
                                                        <small>
                                                            {item.schedule_whatsapp_template && item.schedule_whatsapp_template.active_template_title ? <React.Fragment>
                                                            <b> Secondary Active </b>
                                                            {item.schedule_whatsapp_template.active_template_title}</React.Fragment> : false}
                                                            </small>
                                                        </h6>
                                                        {item.schedule_whatsapp_template &&  item.schedule_whatsapp_template && item.schedule_whatsapp_template.media_type === "video" &&  <video
                                                        src={item.schedule_whatsapp_template.media_url}
                                                        width={"100%"}
                                                        controls={true}
                                                    a
                                                        >

                                                        </video>}

                                                        <span>
                                                            {/* <Tooltip title={item.hasOwnProperty('schedule_sms_template') && item.schedule_sms_template ? 'Done' : 'Not Set'}>
                                                        <span style={{fontSize:"16px"}}  className={item.hasOwnProperty('schedule_sms_template') && item.schedule_sms_template ? 'badge badge-success cursor-pointer' : 'badge badge-danger cursor-pointe'}>
                                                            <i className='fa fa-envelope'></i>
                                                        </span>
                                                    </Tooltip> */}
                                                            {/* &nbsp; */}

                                                            <Tooltip title={item.hasOwnProperty('schedule_whatsapp_template') && item.schedule_whatsapp_template ? 'Done' : 'Not Set'}>
                                                                <span style={{ fontSize: "14px" }} className={item.hasOwnProperty('schedule_whatsapp_template') && item.schedule_whatsapp_template && item.schedule_whatsapp_template.status === 'active' ? 'badge badge-success cursor-pointer' : 'badge badge-danger cursor-pointe'}>
                                                                    <i className='fa fa-whatsapp'></i>
                                                                    &nbsp;
                                                                    {item.schedule_whatsapp_template ? item.schedule_whatsapp_template.status : 'Not Set'}
                                                                </span>
                                                            </Tooltip>
                                                            &nbsp;&nbsp;
                                                            {item.is_hook ?
                                                                <span style={{ fontSize: "14px" }} className="badge badge-primary">
                                                                    HOOK
                                                                </span> : false}
                                                        </span>
                                                    </div>




                                                </CardBody>
                                                <CardFooter>
                                                    <CommunicationChannelTemplateModal
                                                        getData={_this.getData}
                                                        channelData={item}
                                                    /> &nbsp;&nbsp;

                                                    <Tooltip title={'Edit Template Master'}>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => this.edit({ ...item, idx })}

                                                        ><i className={"fa fa-edit"}>

                                                            </i></Button>
                                                    </Tooltip>

                                                    &nbsp;&nbsp;

                                                    <Tooltip title={'Delete'}>
                                                        <Button size="sm" color="danger" onClick={() => this.remove(item.id)}><i className={"fa fa-trash"}>
                                                        </i></Button></Tooltip>

                                                </CardFooter>
                                            </Card>
                                        </Col  >
                                        
                                }) : <ListGroupItem> No data found</ListGroupItem>}
                            </Row>}


                    </CardBody>
                </Card>


                <Modal
                    isOpen={show}
                    toggle={() => this.toggle(false)}>
                    <ModalHeader>
                        {isEdit ? "Edit" : "Add"} Communication Master
                    </ModalHeader>
                    <ModalBody>
                        <InputText

                            title={"Title"}
                            placeholder="Name"
                            value={data.name}
                            name="name"
                            onChange={this.handleChange}
                            maxLength="250"
                        />

                        <InputDropDown
                            title={"Category"}
                            placeholder="category"
                            value={data.category}
                            name="category"
                            onChange={this.handleChange}
                            options={[
                                { value: '1', title: 'Video Marketing' },
                                { value: '2', title: 'Communication' }
                            ]}
                        />

                        <InputText
                            row={5}
                            type={"textarea"}
                            title={"Tags"}
                            placeholder="tags"
                            value={data.tags}
                            name="tags"
                            onChange={this.handleChange}
                            maxLength="1000"
                        />

                        <label><i className="fa fa-info-circle"></i> &nbsp;Example:
                            <br />
                            <small>
                                <b>tag_name1#type:text, tag_name2#type:number, tag_name3#type:number|min:0|max:200 </b>
                            </small>
                        </label>


                        {/* <TagsInput
                            data={data}
                            handleChange={_this.handleChange}
                        /> */}

                        <div className="alert alert-success">
                            You can use following default variables in template for auto replacement. it will apply while sending sms/whatsapp message<br />
                            Default variables <b>business_name</b> , <b>individual_name</b>
                            <div class="text-danger"><b>Important! : </b>For whatsapp maintain order of tags as per template variables.</div>
                        </div>


                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox"
                                    name='is_hook'
                                    checked={data.is_hook}
                                    onChange={this.handleChange} />{' '}
                                Is HOOK ?
                            </Label>
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>
                            Save
                        </Button>{" "}
                        <Button color="warning" onClick={() => this.reset()}>
                            Reset
                        </Button>{" "}
                        <Button color="secondary" onClick={() => this.toggle(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    const { loading, templates } = state.communicationMastersTemplates
    return {
        loading,
        templates,
    }

}


const connectedCM = connect(mapStateToProps)(CommunicationMaster)
export { connectedCM as CommunicationMaster };


import { promocodeConstants } from "../constants"

export function getPromocodes(state = {}, action) {
    switch (action.type) {
      case promocodeConstants.GET_PROMOCODES_REQUEST:
          return { loading: true }
      case promocodeConstants.GET_PROMOCODES_SUCCESS:
        return { data: action.result }
      case promocodeConstants.GET_PROMOCODES_FAILURE:
        return {}
      default:
        return state
    }
  }
  

export function getBanner(state = {}, action) {
    switch (action.type) {
      case promocodeConstants.GET_PROMOCODES_BANNER_REQUEST:
          return { loading: true }
      case promocodeConstants.GET_PROMOCODES_BANNER_SUCCESS:
        return { data: action.result }
      case promocodeConstants.GET_PROMOCODES_BANNER_FAILURE:
        return {}
      default:
        return state
    }
  }
  

import { FAQConstants } from "../constants"

export function addFAQ(state = {}, action) {
  switch (action.type) {
    case FAQConstants.ADD_FAQ_REQUEST:
      return { loading: true }
    case FAQConstants.ADD_FAQ_SUCCESS:
      return { data: action.result }
    case FAQConstants.ADD_FAQ_FAILURE:
      return {}
    default:
      return state
  }
}

export function getFAQ(state = {}, action) {
  switch (action.type) {
    case FAQConstants.GET_FAQ_REQUEST:
      return { loading: true }
    case FAQConstants.GET_FAQ_SUCCESS:
      return { data: action.result }
    case FAQConstants.GET_FAQ_FAILURE:
      return {}
    default:
      return state
  }
}

export function saveFAQ(state = {}, action) {
  switch (action.type) {
    case FAQConstants.EDIT_FAQ_REQUEST:
      return { saving: true }
    case FAQConstants.EDIT_FAQ_SUCCESS:
      return { data: action.result }
    case FAQConstants.EDIT_FAQ_FAILURE:
      return {}
    default:
      return state
  }
}

export function deleteFAQ(state = {}, action) {
  switch (action.type) {
    case FAQConstants.DELETE_FAQ_REQUEST:
      return { loading: true }
    case FAQConstants.DELETE_FAQ_SUCCESS:
      return { data: action.result }
    case FAQConstants.DELETE_FAQ_FAILURE:
      return {}
    default:
      return state
  }
}
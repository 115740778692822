import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { MarketingActions } from '../../actions/marketing.action';
;


function ScheduleEnableDisable(props) {
    const { dispatch, data } = props
    const [scheduleStatus, setScheduleStatus] = useState('');
    useEffect(() => {
        getStatusScheduleFunc()
    }, []);

    let getStatusScheduleFunc = () => {
        dispatch(MarketingActions.getWhatsappMarketingStatus(setScheduleStatus))
    }
    let stopStartScheduleFunc = () => {
        let dataToSend = {
            status: scheduleStatus === 'enable' ? 'disable' : 'enable'
        }
        dispatch(MarketingActions.setWhatsappMarketingStatus(dataToSend, MarketingActions.getWhatsappMarketingStatus(setScheduleStatus)))
    }

    return (
        <div>
            <Alert className='mt-2 ' style={{ border: '1px solid #909090' }} >
                <span>Note : Stop Sending Whatsapp Schedules Videos is</span> <b style={{ textDecoration: "underline" }} className={`${scheduleStatus === 'enable' ? 'text-success' : 'text-danger'}   `}> {scheduleStatus}d</b>
                <Button className={`${scheduleStatus === 'enable' ? "bg-danger" : 'bg-success'} mx-2`} onClick={stopStartScheduleFunc}>
                    {scheduleStatus === 'enable' ? "Disable" : 'Enable'}
                </Button>
            </Alert>
        </div>
    )
}

export default connect()(ScheduleEnableDisable)
import React from 'react'
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import InlineError from '../../../../components/Messages/InlineError';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


export const ManageCommissionSlabForm = ({ errors, formData, index, handleChange, handleDelete, maxCount }) => {
    return <React.Fragment>
        <tr>
            <td>
                {index + 1}
            </td>
            <td>

                <InputGroup>
                    <InputGroupAddon addonType="prepend" >
                        <InputGroupText > ₹ </InputGroupText>
                    </InputGroupAddon>
                    <MaskedInput
                        className="form-control"
                        type="text"
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            integerLimit: 17,
                            minValue: 1
                        })}
                        placeholder="Minimum Amount"
                        name={"min"}
                        maxlength={7}
                        value={formData.min}
                        guide={false}
                        disabled={index == 0}
                        onChange={(e) => handleChange(e, index)} />
                </InputGroup>
                {errors.min && <InlineError message={errors.min} />}
            </td>
            <td>
              
                    {(maxCount === (index + 1)) ?
                        <Input value="or more"  disabled={true} /> :
                        <InputGroup>
                        <InputGroupAddon addonType="prepend" >
                            <InputGroupText > ₹ </InputGroupText>
                        </InputGroupAddon>
                        <MaskedInput
                            className="form-control"
                            type="text"
                            mask={createNumberMask({
                                prefix: '',
                                postfix: '',
                                includeThousandsSeparator: false,
                                allowDecimal: true,
                                integerLimit: 17,
                                minValue: 1
                            })}
                            placeholder="Maximum Amount"
                            name={"max"}
                            value={formData.max}
                            guide={false}
                            onChange={(e) => handleChange(e, index)}
                            disabled={true} /> </InputGroup>}

                
                {errors.max && <InlineError message={errors.max} />}
            </td>
            <td>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" >
                        <InputGroupText > % </InputGroupText>
                    </InputGroupAddon>
                    <MaskedInput
                        className="form-control"
                        type="text"
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            integerLimit: 2,
                            minValue: 1
                        })}
                        placeholder="Commission Percent"
                        name={"commission_rate"}
                        value={formData.commission_rate}
                        guide={false}
                        onChange={(e) => handleChange(e, index)} />
                </InputGroup>
                {errors.commission_rate && <InlineError message={errors.commission_rate} />}
            </td>
            <td>
                <Button color="danger" className="m-0" onClick={(e) => handleDelete(e, index)} > Delete </Button>
            </td>
        </tr>
    </React.Fragment>

}


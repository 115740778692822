import React, { Component } from "react"
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { GuestLayout, UserLayout } from './Layout'
import indexRoutes from "../routes/index.jsx"
import { OverlayLoader } from "../components/Loaders";

import $script from 'scriptjs';

import { GOOGLE_MAP_API_KEY } from '../api-config'
import { authActions } from "../individual/actions"
import { isLoggedIn } from "../helpers"

const MINUTES_TO_AUTO_LOGOUT = 15 // in mins
const CHECK_INTERVAL = 5000 // in ms
const STORE_KEY = 'lastAction';



class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageFound: 1,
        }


        this.check();
        this.initListener();
        this.initInterval();
    }

    // autologout
    getLastAction() {
        return parseInt(localStorage.getItem(STORE_KEY), 10);
    }
    setLastAction(lastAction) {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }
    initListener() {
        document.body.addEventListener('click', () => this.reset());
        document.body.addEventListener('mouseover', () => this.reset());
        document.body.addEventListener('mouseout', () => this.reset());
        document.body.addEventListener('keydown', () => this.reset());
        document.body.addEventListener('keyup', () => this.reset());
        document.body.addEventListener('keypress', () => this.reset());
    }
    reset() {
        this.setLastAction(Date.now());
    }
    initInterval() {
        // if (!isLocalhost) {
            setInterval(() => {
                this.check();
            }, CHECK_INTERVAL);
        // }
    }
    check() {
        const now = Date.now();
        const timeleft = this.getLastAction() + MINUTES_TO_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        let isTimeout = diff < 0;
        if (isLoggedIn() && isTimeout) {
            const { dispatch } = this.props;


            // dispatch(authActions.logout())

            // this.props.history.push('/login')
            // localStorage.clear();
        }
    }

    // ------


    componentWillMount() {
        const { location } = this.props
        for (let i = 0; i < indexRoutes.length; i++) {
            if (indexRoutes[i].path === location.pathname) {
                this.setState({
                    pageFound: 1
                })
            }
        }

        $script([`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`], 'google-map-js')
    }

    render() {
        const { individualAuth } = this.props
        let whoLogin = "guest"
        if (individualAuth.loggedIn) {
            whoLogin = "individual"
        }
        return (
            <React.Fragment>
                {whoLogin === "guest" && <GuestLayout />}
                {(whoLogin === "individual") && <UserLayout />}

                <OverlayLoader />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        individualAuth: state.authentication,
        router: state.routerReducer
    }
}

const connectedApp = withRouter(connect(mapStateToProps)(App))
export { connectedApp as App }
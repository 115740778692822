import { notify } from 'reapop'
import { sendBillTransactionConstants } from '../constants'
import { sendBillTransactionService } from '../services'

export const sendBillTransactionAction = {
    getSendBillTransaction,
}


function getSendBillTransaction(data) {
    return dispatch => {
        dispatch(request())

        sendBillTransactionService.getSendBillTransaction(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_REQUEST } }
    function success(result) { return { type: sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_SUCCESS, result } }
    function failure(error) { return { type: sendBillTransactionConstants.GET_SEND_BILL_TRANSACTION_FAIL, error } }
}

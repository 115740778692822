import { communicationMastersConstants } from "../constants/communicationMasters.constants";

export function communicationMasters(state = {}, action) {
  switch (action.type) {
    case communicationMastersConstants.GET_BUSINESS_ACC_DATA_REQUEST:
      return { loading: true };
    case communicationMastersConstants.GET_BUSINESS_ACC_DATA_SUCCESS:
      return action.result;
    case communicationMastersConstants.GET_BUSINESS_ACC_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}

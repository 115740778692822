import { notify } from 'reapop'
import { userManagementConstants } from '../constants'
import { userManagementService } from '../services'

export const userManagementActions = {
    addUser,
    getUsers,
    deleteUser,
    changeUserStatus,
    addUserType,
    getUserTypes,
    deleteUserType,

        
    getDepartments,
    saveDepartments,
    
    getJobTitles,
    saveJobTitles,

    getCommissionSlab,
    saveCommissionSlab,
    getCommissionAmounts,
}

function addUser(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        userManagementService.addUser(data)
            .then(
                result => {
                    ref.afterSave();
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: "User saved successfully.", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.ADD_USER_REQUEST } }
    function success(result) { return { type: userManagementConstants.ADD_USER_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.ADD_USER_FAILURE, error } }
}

function getUsers(data) {
    return dispatch => {
        dispatch(request())
        userManagementService.getUsers(data)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_USERS_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_USERS_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_USERS_FAILURE, error } }
}


function deleteUser(data,ref){
    return dispatch => {
        dispatch(request())

        userManagementService.deleteUser(data)
            .then(
                result => {
                    ref.afterAction();
                    // dispatch(notify({ position: "tc", dismissible: false, message: "User status updated.", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.DELETE_USER_REQUEST } }
    function success(result) { return { type: userManagementConstants.DELETE_USER_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.DELETE_USER_FAILURE, error } }
}

function changeUserStatus(data) {
    return dispatch => {
        dispatch(request())

        userManagementService.changeUserStatus(data)
            .then(
                result => {
                    dispatch(notify({ position: "tc", dismissible: false, message: "User status updated.", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.CHANGE_USER_STATUS_REQUEST } }
    function success(result) { return { type: userManagementConstants.CHANGE_USER_STATUS_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.CHANGE_USER_STATUS_FAILURE, error } }
}



function addUserType(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        userManagementService.addUserType(data)
            .then(
                result => {
                    ref.afterSave();
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: "User type added successfully", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.ADD_USER_TYPE_REQUEST } }
    function success(result) { return { type: userManagementConstants.ADD_USER_TYPE_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.ADD_USER_TYPE_FAILURE, error } }
}

function getUserTypes() {
    return dispatch => {
        dispatch(request())

        userManagementService.getUserTypes()
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_USER_TYPES_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_USER_TYPES_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_USER_TYPES_FAILURE, error } }
}


function deleteUserType(data,ref){
    return dispatch => {
        dispatch(request())

        userManagementService.deleteUserType(data)
            .then(
                result => {
                    ref.afterAction();
                    dispatch(notify({ position: "tc", dismissible: false, message: "User type deleted successfully", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.DELETE_USER_TYPE_REQUEST } }
    function success(result) { return { type: userManagementConstants.DELETE_USER_TYPE_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.DELETE_USER_TYPE_FAILURE, error } }
}



function getCommissionSlab(data){
    return dispatch => {
        dispatch(request())

        userManagementService.getCommissionSlab(data)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_COMISSION_SLAB_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_COMISSION_SLAB_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_COMISSION_SLAB_FAILURE, error } }
}

function saveCommissionSlab(data,ref){
    return dispatch => {
        dispatch(request())

        userManagementService.saveCommissionSlab(data)
            .then(
                result => {
                    dispatch(notify({ position: "tc", dismissible: false, message: "Commission slab updated successfully.", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_REQUEST } }
    function success(result) { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_FAILURE, error } }
}

function getDepartments(data){
    return dispatch => {
        dispatch(request())

        userManagementService.getDepartments(data)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_DEPARTMENTS_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_DEPARTMENTS_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_DEPARTMENTS_FAILURE, error } }
}

function saveDepartments(data,callback){
    return dispatch => {
        dispatch(request())

        userManagementService.saveDepartments(data)
            .then(
                result => {
                    dispatch(notify({ position: "tc", dismissible: false, message: "department updated successfully.", status: "success" }))
                    callback.success();
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.SAVE_DEPARTMENTS_REQUEST } }
    function success(result) { return { type: userManagementConstants.SAVE_DEPARTMENTS_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.SAVE_DEPARTMENTS_FAILURE, error } }
}

function getJobTitles(data){
    return dispatch => {
        dispatch(request())

        userManagementService.getJobTitles(data)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_JOB_TITLES_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_JOB_TITLES_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_JOB_TITLES_FAILURE, error } }
}

function saveJobTitles(data,callback){
    return dispatch => {
        dispatch(request())

        userManagementService.saveJobTitles(data)
            .then(
                result => {
                    callback.success()
                    dispatch(notify({ position: "tc", dismissible: false, message: "job title updated successfully.", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_REQUEST } }
    function success(result) { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.UPDATE_COMISSION_SLAB_FAILURE, error } }
}

function getCommissionAmounts(data,callback){
    return dispatch => {
        dispatch(request())

        userManagementService.getCommissionAmounts(data)
            .then(
                result => {
                    callback.success()
                    dispatch(notify({ position: "tc", dismissible: false, message: "job title updated successfully.", status: "success" }))
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: userManagementConstants.GET_COMISSION_AMOUNT_REQUEST } }
    function success(result) { return { type: userManagementConstants.GET_COMISSION_AMOUNT_SUCCESS, result } }
    function failure(error) { return { type: userManagementConstants.GET_COMISSION_AMOUNT_FAILURE, error } }
}
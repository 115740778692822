import { notify } from 'reapop'
import { processService } from '../services'
import { processConstants } from '../constants'

export const processActions = {
    saveProcess,
    getProcess
    
}



function getProcess(data) {
    return dispatch => {
        dispatch(request())

        processService.getProcess(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: processConstants.GET_PROCESS_DATA_REQUEST } }
    function success(result) { return { type: processConstants.GET_PROCESS_DATA_SUCCESS, result } }
    function failure(error) { return { type: processConstants.GET_PROCESS_DATA_FAILURE, error } }
}

function saveProcess(data, callback) {
    return dispatch => {
        dispatch(request())

        processService.saveProcess(data)
            .then(
                data => {
                    dispatch(success(data))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: processConstants.SAVE_PROCESS_DATA_FAILURE } }
    function success(result) { return { type: processConstants.SAVE_PROCESS_DATA_SUCCESS, result } }
    function failure(error) { return { type: processConstants.SAVE_PROCESS_DATA_FAILURE, error } }
}

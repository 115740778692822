import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, FormFeedback, Input } from 'reactstrap';
import { MarketingActions } from '../../../actions/marketing.action';

const ReportValuesModal = ({ dispatch, currentvalue, displayValue,  type, data, getData }) => {
    
    const [foamData, setFoamData] = useState({
        value : currentvalue
    });
    const [errors, setErrors] = useState({});

    const [show, setShow] = useState(false)

    const handleSubmit = () => {
        const { value } = foamData

        if (!valid()) {
            let dataToSend = {
                type: type,
                key: data.date,
                value: value
            }
            dispatch(MarketingActions.reportValuesModal(dataToSend, {
                success: () => {
                    toggle()
                    getData()
                }
            }));
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFoamData({ ...foamData, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const valid = () => {
        let errors = {}
        let error = false
        const { value } = foamData

        if (!value) {
            errors[`value`] = "Please Enter Valid Data"
            error = true;
        }

        setErrors({
            ...errors
        })

        return error

    }

    const toggle = () => {
        setShow(!show);
    }


    return (
        <React.Fragment>
            {show ?
                <React.Fragment>
                    <ButtonGroup>
                        <Input
                            size="sm" placeholder=' Number of Downloads ' value={foamData.value} name='value' onChange={handleChange} />
                        <Button
                            size="sm"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            <i className='fa fa-save' />
                        </Button>
                        <Button
                            size="sm"
                            color="danger"
                            onClick={toggle}
                        >
                            <i className='fa fa-times' />
                        </Button>
                    </ButtonGroup>
                    {errors.value && <FormFeedback>{errors.value}</FormFeedback>}
                </React.Fragment>
                :
                <React.Fragment>
                    { displayValue ? displayValue : currentvalue}
                    <span className='pull-right cursor-pointer' onClick={() => toggle()} > <i class="fa fa-pencil text-primary  "></i> </span>
                </React.Fragment>
            }
        </React.Fragment>
    )
}


const connectedReportValuesModal = connect()(ReportValuesModal)
export { connectedReportValuesModal as ReportValuesModal };


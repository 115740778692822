import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, FormFeedback, Collapse } from 'reactstrap';
import { Link } from "react-router-dom"
import swal from 'sweetalert'

import { salesAction } from '../../../actions/sales.action';
import { SALES_STATUS } from '../../../constants';
import moment from 'moment';

import { TimelineStructure, NoteText, CommonDatePicker, NotesInput } from './CommonComponents';

class CustomerAppointmentForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                action_status: -1,
                action_note: "",
                schedule_status: -1,
                schedule_date: new Date(),
                schedule_meeting_date: new Date(),
            },
            errors: []
        }

        this.afterAction = this.afterAction.bind(this)
        this.setStatus = this.setStatus.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleChange(event) {
        const { name, value } = event.target;
        const { formData } = this.state;
        formData[name] = value
        this.setState({ formData });
    }


    afterAction() {
        const { dispatch, customer_id } = this.props;
        dispatch(salesAction.getCustomerSalesStatusData({ customer_id: customer_id }));
    }

    handleSubmit(e) {
        e.preventDefault();
        const { formData, cs_id } = this.state;
        const { dispatch, customer_id,recordCounts } = this.props;

        let action_data = "";
        if (formData.action_status === 1) {
            action_data = {
                schedule_meeting_date: moment(formData.schedule_meeting_date).format("YYYY-MM-DD HH:mm:ss"),
            }
        }

        if (formData.action_status === 0) {
            action_data = {
                schedule_status: formData.schedule_status,
                schedule_date: moment(formData.schedule_date).format("YYYY-MM-DD HH:mm:ss"),
            }
        }

        let dataToSend = {
            action_data: JSON.stringify(action_data),
            action_status: formData.action_status,
            action_type: SALES_STATUS.APPOINTMENT,
            action_note: formData.action_note,
            customer_id: customer_id,
            recordCounts: recordCounts
        };
        if (cs_id) {
            dataToSend.cs_id = cs_id
        }

        let _this = this
        swal({
            title: "Are you sure ?",
            text: "Do you want to update status?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(salesAction.updateCustomerSalesStatus(dataToSend, _this));
            }
        })

    }

    renderCustomerName(cell, row, rowIndex, formateExtraData) {
        return row.first_name + " " + row.last_name
    }

    setStatus(event) {
        const { formData } = this.state;
        let val = parseInt(event.target.value, 10)
        formData[event.target.name] = val;

        if (val === 1) {
            formData.action_data = "";
        }

        this.setState({ formData })
    }

    onChange = date => {
        const { formData } = this.state;
        formData["schedule_date"] = date;
        this.setState({ formData })
    }

    onChangeMeeting = date => {
        const { formData } = this.state;
        formData["schedule_meeting_date"] = date;
        this.setState({ formData })
    }
    componentDidMount() {
        const { formData } = this.props;

        if (formData) {
            let action_data = formData.action_data ? JSON.parse(formData.action_data) : {};

            this.setState({
                formData: {
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : -1,
                    meeting_schedule_date: action_data.schedule_meeting_date !== undefined ? moment(action_data.schedule_meeting_date, "YYYY-MM-DD HH:mm:ss").toDate() :new Date(),
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                },
                oldFormData: {
                    ...formData,
                    action_status: parseInt(formData.action_status, 10),
                    action_note: formData.action_note && formData.action_note !== "null" ? formData.action_note : "",
                    schedule_status: action_data.schedule_status !== undefined ? parseInt(action_data.schedule_status, 10) : -1,
                    meeting_schedule_date: action_data.meeting_schedule_date !== undefined ? parseInt(action_data.meeting_schedule_date, 10) :new Date(),
                    schedule_date: action_data.schedule_date !== undefined ? moment(action_data.schedule_date, "YYYY-MM-DD HH:mm:ss").toDate() : new Date(),
                },
                cs_id: formData.id
            })
        }

    }
    render() {
        const { loading } = this.props
        const { formData, errors, oldFormData } = this.state

        let iconStyle = {
            background: '#FFBC34', color: '#fff'
        }
        if (oldFormData && parseInt(oldFormData.action_status, 10) === 1) {
            iconStyle = {
                background: '#5AC146', color: '#fff'
            }
        }

        return (
            <React.Fragment>
                <TimelineStructure
                    title={"Is customer appointment taken ?"}
                    iconStyle={iconStyle}
                    icon={<i className="fas fa-calendar-check"></i>}
                    oldFormData={oldFormData}
                >
                    <Form  autocomplete="off" >
                        {oldFormData && oldFormData.action_status === 0 && oldFormData.schedule_status === 1 && oldFormData.schedule_date !== "" ? <React.Fragment>
                            Call re scheduled on {moment(formData.schedule_date).format("YYYY-MM-DD hh:mm A")}
                        </React.Fragment> :
                            <React.Fragment>

                                {oldFormData && parseInt(oldFormData.action_status, 10) === 1 ?
                                    <React.Fragment>
                                        <p>Yes, Cutomer appointment is taken.</p>
                                        {formData.meeting_schedule_date && <React.Fragment>
                                            <p> Meeting scheduled on {moment(formData.meeting_schedule_date).format("YYYY-MM-DD hh:mm A")} </p>
                                        </React.Fragment>
                                        }

                                        <NoteText action_note={formData.action_note} />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>

                                        <FormGroup >
                                            <div onChange={this.setStatus.bind(this)}>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="is_call_no" name="action_status" value="0" class="custom-control-input" checked={formData.action_status === 0} />
                                                    <label class="custom-control-label" for="is_call_no" >No</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="is_call_yes" name="action_status" value="1" class="custom-control-input" checked={formData.action_status === 1} />
                                                    <label class="custom-control-label" for="is_call_yes" >Yes</label>
                                                </div>
                                            </div>
                                            <FormFeedback>
                                                {errors[`action_status`] && errors[`action_status`]}
                                            </FormFeedback>
                                        </FormGroup>

                                        {/* {oldFormData ? <React.Fragment>
                                            {oldFormData.action_status === 0 && oldFormData.schedule_date !== "" ? <React.Fragment> */}
                                        {/* {moment(oldFormData.schedule_date).isSameOrAfter(moment()) ? */}
                                        {/* <React.Fragment> */}
                                        {/* Call scheduled on {moment(formData.schedule_date).format("YYYY-MM-DD hh:mm A")} &nbsp;
                                                        <span class="fa-stack fa-xs">
                                                    <i class="fa fa-square fa-stack-2x  text-primary"></i>
                                                    <i class="fa fa-pencil fa-stack-1x  text-white"></i>
                                                    </span>
                                                </React.Fragment> : */}
                                        {/* <React.Fragment> */}
                                        {/* Call scheduled time is passed. Do you want to reschedule date and time ? */}
                                        {/* <Button color="danger" className={"btn btn-sm "}> Reschedule call </Button> */}
                                        {/* </React.Fragment>} */}

                                        {/* </React.Fragment> : ""}

                                        </React.Fragment> : */}

                                        <Collapse isOpen={formData.action_status === 0}>
                                            <FormGroup>
                                                <label className="font-weight-bold">Do you want to re schedule call?</label>
                                                <div onChange={this.setStatus.bind(this)}>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="schedule_status_no" name="schedule_status" value="0" class="custom-control-input" checked={formData.schedule_status === 0} />
                                                        <label class="custom-control-label" for="schedule_status_no" >Not now</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="schedule_status_yes" name="schedule_status" value="1" class="custom-control-input" checked={formData.schedule_status === 1} />
                                                        <label class="custom-control-label" for="schedule_status_yes" >Schedule Call</label>
                                                    </div>
                                                </div>
                                            </FormGroup>


                                            <Collapse isOpen={formData.schedule_status === 1}>
                                                <CommonDatePicker selectedDate={formData.schedule_date} onChange={this.onChange} />
                                            </Collapse>




                                        </Collapse>


                                        <Collapse isOpen={formData.action_status === 1}>

                                            <label className="font-weight-bold">Select meeting time</label>
                                            <CommonDatePicker selectedDate={formData.schedule_meeting_date} onChange={this.onChangeMeeting} />

                                        </Collapse>

                                            <NotesInput action_note={formData.action_note} handleChange={this.handleChange} errors={errors} />

                                        <FormGroup>
                                            <Button color="primary" onClick={this.handleSubmit} disabled={loading || parseInt(formData.action_status, 10) === -1}>{loading ? "Please wait..." : "Update"}</Button> {" "}
                                            <Link className="btn btn-secondary" to={"/customer-sales-status"}> Cancel </Link>
                                        </FormGroup>

                                    </React.Fragment>}
                            </React.Fragment>}
                    </Form>

                </TimelineStructure>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.updateCustomerSalesStatus
    return {
        loading,
        data
    }
}

const connectedCustomerAppointmentForm = connect(mapStateToProps)(CustomerAppointmentForm)
export { connectedCustomerAppointmentForm as CustomerAppointmentForm }
import { notify } from 'reapop'
import { MarketingConstants } from '../constants'
import { MarketingService } from '../services'

export const MarketingActions = {
    fetchResult,
    scheduleMarketing,
    resetSchedule,
    getWhatsappMarketingStatus,
    setWhatsappMarketingStatus,
    WhatsappStopCount,
    marketingWhatsappAnalysis,
    getSendedNotifications,
    SendNotifications,
    reportValuesModal,
}


function reportValuesModal(data, callback) {
    return dispatch => {
        dispatch(request())
        MarketingService.reportValuesModal(data)
            .then(
                result => {
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Data Addded Successfully.', status: "success" }))
                    callback.success();
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }


    function request() { return { type: MarketingConstants.SET_REPORT_VALUES_MODAL_REQUEST } }
    function success(result) { return { type: MarketingConstants.SET_REPORT_VALUES_MODAL_SUCCESS , result } }
    function failure(error) { return { type: MarketingConstants.SET_REPORT_VALUES_MODAL_FAIL , error } }
}

function setWhatsappMarketingStatus(data, ref) {
    return dispatch => {
        dispatch(request())
        MarketingService.setWhatsappMarketingStatus(data)
            .then(
                result => {
                    console.log(data);
                    data.status === 'enable' ?
                        dispatch(notify({ position: "tc", dismissible: false, message: 'Enabled Scheduled  Successfully.', status: "success" }))
                        :
                        dispatch(notify({ position: "tc", dismissible: false, message: 'Disabled Scheduled  Successfully.', status: "success" }))
                    dispatch(success(result))
                    dispatch(ref)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.SET_SCHEDULE_STOP_START_RESULT_REQUEST } }
    function success(result) { return { type: MarketingConstants.SET_SCHEDULE_STOP_START_RESULT_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.SET_SCHEDULE_STOP_START_RESULT_FAILURE, error } }
}
function getWhatsappMarketingStatus(states) {
    return dispatch => {
        dispatch(request())

        MarketingService.getWhatsappMarketingStatus()
            .then(
                result => {
                    states(result.status)
                    // dispatch(success(result))
                    // dispatch(notify({ position: "tc", dismissible: false, message: 'Get Status Successfully.', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.STOP_SCHEDULE_RESULT_REQUEST } }
    function success(result) { return { type: MarketingConstants.STOP_SCHEDULE_RESULT_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.STOP_SCHEDULE_RESULT_FAILURE, error } }
}
function resetSchedule(data, ref) {
    return dispatch => {
        dispatch(request())

        MarketingService.resetSchedule(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Reset Schedule Successfully.', status: "success" }))
                    dispatch(ref)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.RESET_SCHEDULE_RESULT_REQUEST } }
    function success(result) { return { type: MarketingConstants.RESET_SCHEDULE_RESULT_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.RESET_SCHEDULE_RESULT_FAILURE, error } }
}
function fetchResult(data, ref) {
    return dispatch => {
        dispatch(request())

        MarketingService.fetchResult(data)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.FETCH_BUSINESSES_RESULT_REQUEST } }
    function success(result) { return { type: MarketingConstants.FETCH_BUSINESSES_RESULT_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.FETCH_BUSINESSES_RESULT_FAILURE, error } }
}

function WhatsappStopCount(state) {
    return dispatch => {
        dispatch(request())

        MarketingService.WhatsappStopCount()
            .then(
                result => {
                    state(result)
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.GET_WHATSAPP_STOP_COUNT_REQUEST } }
    function success(result) { return { type: MarketingConstants.GET_WHATSAPP_STOP_COUNT_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.GET_WHATSAPP_STOP_COUNT_FAILURE, error } }
}

function scheduleMarketing(data, callback) {
    return dispatch => {
        dispatch(request())

        MarketingService.scheduleMarketing(data)
            .then(
                result => {
                    callback.success();
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message: "Scheduled successfully.", status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.SCHDUELE_MARKETING_REQUEST } }
    function success(result) { return { type: MarketingConstants.SCHDUELE_MARKETING_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.SCHDUELE_MARKETING_FAILURE, error } }
}


function marketingWhatsappAnalysis(data, callback) {
    return dispatch => {
        dispatch(request())

        MarketingService.marketingWhatsappAnalysis(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_REQUEST } }
    function success(result) { return { type: MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_FAILURE, error } }
}
function getSendedNotifications(data, callback) {
    return dispatch => {
        dispatch(request())

        MarketingService.getSendedNotifications(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            )
    }

    function request() { return { type: MarketingConstants.GET_SENDED_NOTIFICATIONS_REQUEST } }
    function success(result) { return { type: MarketingConstants.GET_SENDED_NOTIFICATIONS_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.GET_SENDED_NOTIFICATIONS_FAILURE, error } }
}
function SendNotifications(data, callback) {
    return dispatch => {
        dispatch(request())

        MarketingService.SendNotifications(data)
            .then(
                result => {
                    dispatch(success(result));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    callback()
                }
            )
    }

    function request() { return { type: MarketingConstants.SEND_NOTIFICATIONS_REQUEST } }
    function success(result) { return { type: MarketingConstants.SEND_NOTIFICATIONS_SUCCESS, result } }
    function failure(error) { return { type: MarketingConstants.SEND_NOTIFICATIONS_FAILURE, error } }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { validateItem } from "../../../../helpers/common";
import { businessAccountsActions } from "../../../actions";
import { RenderForm } from "./RenderForm";

const defaultData = {
  individual: "",
  json: "",
  notes: "",
};

class SupportStage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: defaultData,
      errors: [],
    };

    this.save = this.save.bind(this);
    this.saveStageJSON = this.saveStageJSON.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validation = this.validation.bind(this);
  }

  saveStageJSON(json) {
    const { data } = this.state;
    data.json = json;
    this.setState({ data });
  }

  save(save_type) {
    const {
      dispatch,
      getData,
      business_details_id,
      ticket_id,
      individual_id,
    } = this.props;
    const { data } = this.state;

    if (this.validation() === true) {
      return false;
    }

    let dataToSend = {
      type: "support",
      reference_id: ticket_id,
      save_type: save_type,
      notes: data.notes ? data.notes : "",
      json:
        typeof data.json === "string" ? data.json : JSON.stringify(data.json),
      communicated_individual_ids: individual_id ? individual_id : "",
      business_account_id: business_details_id,
    };

    dispatch(
      businessAccountsActions.saveProcessStage(dataToSend, {
        success: () => {
          this.setState({ data: defaultData }, getData());
        },
      })
    );
  }

  validation() {
    const { data } = this.state;

    let errorsList = [];

    let Items =
      typeof data.json === "string" ? JSON.parse(data.json) : data.json;

    Items.map((eachItem) => {
      validateItem(eachItem, errorsList);
    });

    this.setState({
      errors: errorsList,
    });

    return errorsList.length > 0 ? true : false;
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState(data);
  }

  componentWillReceiveProps(props) {
    const { currentStageJson } = props;
    if (currentStageJson) {
      const { data } = this.state;

      data.individual = currentStageJson.communicated_individual_ids;
      data.notes = currentStageJson.notes ? currentStageJson.notes : "";
      data.json = currentStageJson.json ? currentStageJson.json : "";

      this.setState({ data });
    } else {
      // this.setState({ data:defaultData })
    }
  }

  componentDidMount() {
    const { currentStageJson } = this.props;
    if (currentStageJson) {
      const { data } = this.state;

      data.individual = currentStageJson.communicated_individual_ids;
      data.notes = currentStageJson.notes ? currentStageJson.notes : "";
      data.json = currentStageJson.json ? currentStageJson.json : "";

      this.setState({ data });
    } else {
      // this.setState({ data:defaultData })
    }
  }

  render() {
    const { currentStageJson, current_stage } = this.props;

    const { data, errors } = this.state;

    let _this = this;

    return currentStageJson != false && currentStageJson ? (
      <Card>
        <CardBody>
          <React.Fragment>
            <ListGroup>
              {currentStageJson && (
                <ListGroupItemHeading>
                  {currentStageJson.process_name} &nbsp;{" "}
                  <i className="fa fa-angle-right"></i> &nbsp;{" "}
                  {currentStageJson.stage_name}
                </ListGroupItemHeading>
              )}

              {current_stage && current_stage.length > 0 ? (
                <ListGroupItem>
                  <RenderForm
                    data={current_stage}
                    saveStageJSON={_this.saveStageJSON}
                  />
                </ListGroupItem>
              ) : (
                "No Stages Available"
              )}

              <FormGroup>
                <label>
                  <b>Notes</b>
                </label>
                <Input
                  onChange={this.handleChange}
                  name="notes"
                  placeholdeer="notes"
                  value={data.notes}
                  type="textarea"
                />
              </FormGroup>
            </ListGroup>
            {errors && errors.length
              ? errors.map((eachText) => {
                  return <Alert color="danger">{eachText}</Alert>;
                })
              : false}
            <Button
              style={{ marginTop: "30px" }}
              color="primary"
              onClick={() => this.save("save")}
            >
              Save
            </Button>
            &nbsp;
            <Button
              style={{ marginTop: "30px" }}
              color="primary"
              onClick={() => this.save("repeat")}
            >
              Complete and Repeat
            </Button>
            &nbsp;
            <div className="pull-right">
              <Button
                style={{ marginTop: "30px" }}
                color="success"
                onClick={() => this.save("complete")}
              >
                Complete and Next
              </Button>
              &nbsp;
              <Button
                className="pull-right"
                style={{ marginTop: "30px" }}
                color="danger"
                onClick={() => this.save("close")}
              >
                Complete and Close
              </Button>
            </div>
          </React.Fragment>
        </CardBody>
      </Card>
    ) : (
      false
    );
  }
}

const mapStateToProps = (state) => ({});

const connected = connect(mapStateToProps)(SupportStage);
export { connected as SupportStage };

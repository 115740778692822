import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { TabContentData } from './TabContentData'
import { ExceptionLogsActions } from '../../actions';

const TABS = {
    Reputeinfo: "reputeinfo",
    SuperAdmin: "superadmin",
}

class ExceptionLogs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.Reputeinfo,
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            }
        }

        this.toggle = this.toggle.bind(this)
        this.getExceptionLogs = this.getExceptionLogs.bind(this)
    }

    componentDidMount() {
        this.getExceptionLogs();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, function () {
                this.getExceptionLogs();
            })
        }
    }

    getExceptionLogs() {
        const { dispatch } = this.props;
        const { page } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            log_type: this.state.activeTab
        }
        dispatch(ExceptionLogsActions.getExceptionLogs(dataToSend));
    }

    render() {
        const { data, loading } = this.props;

        return (
            <div>
                <h5 className="mb-4 mt-4">
                    <i className="fas fa-bug"></i> &nbsp; Exception Logs
                </h5>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.Reputeinfo })}
                            onClick={() => { this.toggle(TABS.Reputeinfo) }}
                        >
                            <b>Repute Info</b>

                        </NavLink>
                    </NavItem>
                    <NavItem>

                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.SuperAdmin })}
                            onClick={() => { this.toggle(TABS.SuperAdmin) }}
                        >
                            <b>Admin Cpanel</b>

                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={TABS.SuperAdmin}>
                        <TabContentData loading={loading} logs={data} isSuperAdmin={true} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.Reputeinfo}>
                        <TabContentData loading={loading} logs={data} isSuperAdmin={false} activeTab={this.state.activeTab} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getExceptionLogs;
    return {
        loading,
        data
    }
}
const connectedExceptionLogs = connect(mapStateToProps)(ExceptionLogs)
export { connectedExceptionLogs as ExceptionLogs }

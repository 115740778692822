import React, { Component } from "react";
import {
  Collapse,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import { AddNewProcess } from "./AddNewProcess";
import { Stages } from "./Stages";
import { connect } from "react-redux";
import { processActions, userManagementActions } from "../../../../actions";

import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import { TableLoader } from "../../../../../components/Loaders";
Sortable.mount(new MultiDrag(), new Swap());

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: "",
      processListData: [],
    };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.getData = this.getData.bind(this);
    this.saveProcessOrder = this.saveProcessOrder.bind(this);
  }

  toggleExpand(idx) {
    const { expand } = this.state;
    if (idx === expand) {
      idx = "";
    }
    this.setState({ expand: idx });
  }

  componentDidMount() {
    this.getData();

    const { dispatch } = this.props;
    dispatch(userManagementActions.getJobTitles());
  }

  getData() {
    const { dispatch, type } = this.props;

    let dataToSend = {
      type: type ? type : "business_account",
    };

    dispatch(processActions.getProcess(dataToSend));
  }

  saveProcessOrder(data) {
    const { dispatch } = this.props;

    let dataToSend = {
      action: "process_order",
      order_data: JSON.stringify(data),
    };

    dispatch(
      processActions.saveProcess(dataToSend, {
        success: () => {
          setTimeout(() => {
            this.getData();
          }, 1000);
        },
      })
    );
  }

  componentWillReceiveProps(props) {
    const { processList } = props;
    if (processList && processList.length) {
      this.setState({
        processListData: processList,
      });
    }
  }

  render() {
    const { processList, loading, page_title, onlyOne } = this.props;

    const { expand, processListData } = this.state;

    let _this = this;

    return (
      <Card>
        <CardBody>
          <h3>
            {page_title ? page_title : "Process"} &nbsp;

            {onlyOne === true ?  ( processListData && processListData.length === 0 && <AddNewProcess
              {...this.props}
              getData={this.getData}
              process_order_number={
                processList && processList.length ? processList.length + 1 : 1
              }
            /> ) : 
            <AddNewProcess
              {...this.props}
              getData={this.getData}
              process_order_number={
                processList && processList.length ? processList.length + 1 : 1
              }
            />}
          </h3>

          {loading ? (
            <TableLoader />
          ) : (
            <ListGroup>
              {processListData && processListData.length > 0 ? (
                <ReactSortable
                  // here they are!
                  group="groupName"
                  handle=".move-icon-process"
                  animation={200}
                  delayOnTouchStart={true}
                  delay={2}
                  list={processListData ? processListData : []}
                  onEnd={() => {
                    // _this.setState({dataToSend : dataToSend})
                    _this.saveProcessOrder(_this.state.dataToSend);
                  }}
                  setList={(data) => {
                    let newOrderData = [];
                    let dataToSend = [];
                    data.map((each, idx) => {
                      let _d = {
                        id: each.id,
                        process_order_number: idx + 1,
                      };
                      each.process_order_number = idx + 1;
                      dataToSend.push(_d);
                      newOrderData.push(each);
                    });

                    _this.setState({ processListData: newOrderData });
                    _this.setState({ dataToSend: dataToSend });
                  }}
                >
                  <React.Fragment>
                    {processListData.map(function (each_process) {
                      console.log("&&& ---> ", each_process);
                      return (
                        <React.Fragment>
                          <div>
                            <ListGroupItem
                              onClick={() =>
                                _this.toggleExpand(each_process.id)
                              }
                            >
                              <i className="fa fa-bars cursor-pointer move-icon-process text-primary"></i>{" "}
                              &nbsp;&nbsp;
                              <Badge color="danger">
                                <b>{each_process.process_order_number}</b>
                              </Badge>{" "}
                              &nbsp;&nbsp;
                              <b>{each_process.process_name}</b> &nbsp;&nbsp;
                              <AddNewProcess
                                isEdit={true}
                                {..._this.props}
                                current_data={each_process}
                                getData={_this.getData}
                                process_order_number={
                                  each_process.process_order_number
                                }
                                // RootItems={template.stages}
                              />
                              {expand === each_process.id ? (
                                <i
                                  className={"fa fa-caret-down pull-right"}
                                ></i>
                              ) : (
                                <i
                                  className={"fa fa-caret-right pull-right"}
                                ></i>
                              )}
                            </ListGroupItem>

                            <Collapse isOpen={expand === each_process.id}>
                              <Stages
                                getData={_this.getData}
                                processId={each_process.id}
                                stages={each_process.process_stage_templates}
                              />
                            </Collapse>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </ReactSortable>
              ) : (
                false
              )}
            </ListGroup>
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { processList, loading } = state.process;
  return {
    processList,
    loading,
  };
}

const connectedProcess = connect(mapStateToProps)(Process);
export { connectedProcess as Process };

import { dashboardReportsConstants } from "../constants"

export function dashboardReports(state = {}, action) {
    switch (action.type) {
      case dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_REQUEST:
        return { ...state, loading: true }
      case dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_SUCCESS:
        return  { loading: false, params: action.params, ...action.result };
      case dashboardReportsConstants.GET_DASHBOARD_REPORTS_DATA_FAILURE:
        return {}
      default:
        return state
    }
  }

export function personWiseReports(state = {}, action) {
    switch (action.type) {
      case dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_REQUEST:
        return { loading: true }
      case dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_SUCCESS:
        return   {params: action.params, ...action.result};
      case dashboardReportsConstants.GET_PERSON_WISE_SALES_REPORTS_DATA_FAILURE:
        return {}
      default:
        return state
    }
  }

export function baPrpcessStages(state = {}, action) {
    switch (action.type) {
      case dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_REQUEST:
        return { loading: true }
      case dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_SUCCESS:
        return action.result;
      case dashboardReportsConstants.GET_BA_PROCESS_STAGES_DATA_FAILURE:
        return {}
      default:
        return state
    }
  }

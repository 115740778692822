import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Form, Input, InputGroup, FormFeedback, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router';
import { customerDetailAction } from "../../../actions";
import swal from 'sweetalert';
import { show, hide } from 'redux-modal';
import { handleCustomerNameValidation } from './CustomerNameValidation'
class UpdateCustomerName extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                first_name: '',
                last_name: '',
            },
            errors: {},
        }
        this.handleChange = this.handleChange.bind(this)
        this.handelUpdateCustomerName = this.handelUpdateCustomerName.bind(this)
        this.afterAction = this.afterAction.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handelCansel = this.handelCansel.bind(this)
    }
    toggle() {
        const { onClose } = this.props
        onClose()
    }


    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        if (name === "first_name" || name === "last_name") {

            let regex_one_word = /^[a-zA-Z]+$/
            if (value.length && regex_one_word.test(value) === false) {
                return false;
            }
        }

        formData[name] = value;

        this.setState({ formData })
    }

    afterAction() {
        const { dispatch, getCustomerList } = this.props;
        dispatch(hide("bootstrap"))
        getCustomerList()
    }
    handelCansel() {
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"))
    }
    handelUpdateCustomerName(e) {
        e.preventDefault();
        const { formData } = this.state;
        const { dispatch } = this.props;

        const result = handleCustomerNameValidation(formData)
        this.setState({ errors: result.errors })
            if (result.formIsValid) {
                swal({
                    title: "Are you sure ?",
                    text: "Do you want to update customer name?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((confirm) => {
                    if (confirm) {
                        formData.type = 'customer_name'
                        dispatch(customerDetailAction.updateCustomerDetilEmail(formData, this));
                    }
                })
            }

        // else {
        //     dispatch(notify({ position: "tc", dismissible: false, message: '', status: "error" }))

        // }

    }
    componentDidMount() {
        const { customerDetail } = this.props;
        let { formData } = this.state;
        if (customerDetail) {
            formData.id = customerDetail.id
            formData.first_name = customerDetail.first_name ? customerDetail.first_name : ''
            formData.last_name = customerDetail.last_name ? customerDetail.last_name : ''
            this.setState({ formData })
        }

    }
    render() {
        const { errors, formData } = this.state
        return (

            <React.Fragment>
                <Form autocomplete="off" >
                    <Row>
                        <Col md="12">
                            <FormGroup >
                            <Row>
                                <Col xs="6">
                                    <InputGroup>
                                        <Input placeholder="First Name" onChange={this.handleChange} value={formData.first_name} name="first_name" maxLength="50" />
                                    </InputGroup>
                                    <FormFeedback>
                                        {errors[`first_name`] && errors[`first_name`]}
                                    </FormFeedback>
                                </Col>
                                <Col xs="6">
                                    <InputGroup>
                                        <Input placeholder="Last Name" onChange={this.handleChange} value={formData.last_name} name="last_name" maxLength="50" />
                                    </InputGroup>
                                </Col>
                            </Row>

                            </FormGroup>
                        </Col>
                    </Row><hr />
                    <button className="btn btn-secondary btn-sm pull-right" onClick={this.handelCansel} style={{'marginLeft':'10px'}} > Cancel </button>
                    <button type="button" onClick={this.handelUpdateCustomerName} className="btn btn-primary btn-sm pull-right"  > Update </button>
                </Form>
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    const { loading, data, customerDetail } = state.getCustomerStatusData
    return {
        loading,
        data,
        customerDetail
    }
}

const connectedUpdateCustomerName = withRouter(connect(mapStateToProps)(UpdateCustomerName))
export { connectedUpdateCustomerName as UpdateCustomerName }
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { UserForm } from './UserForm';

class AddUser extends Component {

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mb-4 mt-4"> ADD USER </h3>
                    <UserForm />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedAddUser = connect(mapStateToProps)(AddUser)
export { connectedAddUser as AddUser }
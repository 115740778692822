import { notify } from 'reapop'
import { contactusDetailConstants } from '../constants'
import { contactusDetailService } from '../services'

export const contactusDetailActions = {
    getContactusDetail,
    postContactUsCallStatus , 

}
function getContactusDetail(data) {
    return dispatch => {
        dispatch(request())

        contactusDetailService.getContactusDetail(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: contactusDetailConstants.GET_CONTACTUS_DETAIL_REQUEST } }
    function success(result) { return { type: contactusDetailConstants.GET_CONTACTUS_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: contactusDetailConstants.GET_CONTACTUS_DETAIL_FAILURE, error } }
}


function postContactUsCallStatus(data , callback) {
    return dispatch => {
        dispatch(request())

        contactusDetailService.postContactUsCallStatus(data)
            .then(
                result =>{ dispatch(callback.getContactusDetail); dispatch(success(result))} , 
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: contactusDetailConstants.GET_CONTACTUS_CALL_STATUS_REQUEST } }
    function success(result) { return { type: contactusDetailConstants.GET_CONTACTUS_CALL_STATUS_SUCCESS, result } }
    function failure(error) { return { type: contactusDetailConstants.GET_CONTACTUS_CALL_STATUS_FAILURE, error } }
}
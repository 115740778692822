import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from "../../helpers";

export const contactusDetailService = {
    getContactusDetail,
    postContactUsCallStatus,
}

function getContactusDetail(dataToSend) {
    var form_data = dataToSend
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/getContactusDetail`, form_data, setting).then(handleResponse)
}
function postContactUsCallStatus(dataToSend) {
    var form_data = dataToSend
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/updateContactUsCallStatus`, form_data, setting).then(handleResponse)
}
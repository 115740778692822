import { axiosAjaxCall } from "./common.service";

export const dashboardReportsService = {
    getDashboardReportsData,
    getPersonWiseReportsData,
    getProcessStages
}

function getDashboardReportsData(data) {    
    return axiosAjaxCall("get_stages_report", data);
}

function getPersonWiseReportsData(data) {    
    return axiosAjaxCall("get_person_wise_report", data);
}

function getProcessStages(data) {    
    return axiosAjaxCall("get_business_account_process_stages", data);
}

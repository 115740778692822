import React from 'react';

export const CustomerPrioritiesOptions = ({ handleChange, priority }) => {
	let Options = [
		{ text: 'Minor', value: 1, class:"bg-teal text-white" },
		{ text: 'Low', value: 2, class:"bg-purple text-white" },
		{ text: 'Normal', value: 3, class:"bg-primary text-white" },
		{ text: 'High', value: 4, class:"bg-red text-white" },
		{ text: 'Very High', value: 5, class:"bg-pink text-white" }
    ];
    var result = Options.filter(obj => {
        return obj.value === parseInt(priority,10)
      })
	return (
		<div>
			<select className={result && result[0] ? result[0].class : ""} name="priority" onChange={handleChange} value={priority}>{Options.map((e, i) => <option className={e.class} value={e.value}> {e.text} </option>)}</select>
		</div>
	);
};

export const RenderPrioritesText = ({ priority }) => {
    let status;
    switch(priority) {
        case 1: status = <span className="badge badge-light bg-teal text-white" > Minor </span>; break;
        case 2: status = <span className="badge badge-light bg-purple text-white" > Low </span>; break;
        case 3: status = <span className="badge badge-primary" >Normal  </span>; break;
        case 4: status = <span className="badge badge-danger" > High </span>; break;
        case 5: status = <span className="badge badge-light  bg-pink text-white " > Very High </span>; break;
        default :  status = <span className="badge badge-primary" > Normal </span>;
    }
    return  status;
};

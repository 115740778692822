import { notify } from 'reapop'
import { SmsPackageConstants } from '../constants'
import { SmsPackageService } from '../services'

export const SMSPackageActions = {
    getSMSPackage,
    updateSMSPackage
}

function getSMSPackage(data) {
    return dispatch => {
        dispatch(request({ data }));
        SmsPackageService.getSMSPackage(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SmsPackageConstants.GET_SMSPACKAGE_REQUEST } }
    function success(result) { return { type: SmsPackageConstants.GET_SMSPACKAGE_SUCCESS, result } }
    function failure(error) { return { type: SmsPackageConstants.GET_SMSPACKAGE_FAILURE, error } }
}


function updateSMSPackage(data,ref) {
    return dispatch => {
        dispatch(request({ data }));
        SmsPackageService.updateSMSPackage(data)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(notify({ position: "tc", dismissible: false, message:'Update Successfully', status: "success" }))
                    ref.afterAction()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SmsPackageConstants.EDIT_SMSPACKAGE_REQUEST } }
    function success(result) { return { type: SmsPackageConstants.EDIT_SMSPACKAGE_SUCCESS, result } }
    function failure(error) { return { type: SmsPackageConstants.EDIT_SMSPACKAGE_FAILURE, error } }
}

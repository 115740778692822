import React from 'react';

export const ErrorPageTemplate = ({ text, helptext, icon }) => (
    <div className="no-data-warning text-center">
        <div className="row">
            <div className="col-md-6 offset-md-3">
                {icon ? icon : <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ fontSize: "70px", color: "#f26522" }}> </i>}
                <h3> {text} </h3>
                <h5> {helptext}</h5>
            </div>
        </div>
    </div>
)
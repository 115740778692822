import { settingsConstants } from "../constants"


export function getSettings(state = {}, action) {
  switch (action.type) {
    case settingsConstants.GET_SETTINGS_REQUEST:
      return { loading: true }
    case settingsConstants.GET_SETTINGS_SUCCESS:
      return { data: action.result }
    case settingsConstants.GET_SETTINGS_FAILURE:
      return {}
    default:
      return state
  }
}

export function editSettings(state = {}, action) {
    switch (action.type) {
      case settingsConstants.EDIT_SETTINGS_REQUEST:
        return { loading: true }
      case settingsConstants.EDIT_SETTINGS_SUCCESS:
        return { data: action.result }
      case settingsConstants.EDIT_SETTINGS_FAILURE:
        return {}
      default:
        return state
    }
  }
  
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody, CardHeader,
  Collapse,
  Nav,
  NavItem,
  NavLink, TabContent, TabPane
} from "reactstrap";
import { communicationMastersActions } from "../../../../actions/communicationMasters.action";
import { CommunicationCallForm } from "./CommunicationCallForm";
import { CommunicationSmsForm } from "./CommunicationSmsForm";


const CommType = {
  Instant: "instant",
  Schedule: "schedule",
  NotSelected: "NotSelected",
};

const defaultChannels = {
  sms: false,
  email: false,
  whatsapp: false,
  call: false,
};

const TABS = {
  sms: "sms",
  call: "call",
};

class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      activeTab: TABS.sms,
    };
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {  items, collapse } = this.state;
    const {  communicationMasterTemplates } = this.props;

    return (
      <Card>
        <CardHeader onClick={() => this.collapse()}>
          <h5 style={{ marginBottom: 0 }}>
            {" "}
            <i className="fa fa-comments-o "></i> &nbsp;Communication
            <i
              className={
                "pull-right " +
                (collapse ? "fa fa-caret-down" : "fa fa-caret-right")
              }
            ></i>
          </h5>
        </CardHeader>
        <Collapse isOpen={collapse}>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.sms,
                  })}
                  onClick={() => {
                    this.toggle(TABS.sms);
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>SMS/WhatsApp</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TABS.call,
                  })}
                  onClick={() => {
                    this.toggle(TABS.call);
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Call</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={TABS.sms}>
                
                <CommunicationSmsForm {...this.props}/>
              </TabPane>
              <TabPane tabId={TABS.call}>
                <CommunicationCallForm {...this.props}/>
              </TabPane>
            </TabContent>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  
  return {
  };
}

const connected = connect(mapStateToProps)(Communication);
export { connected as Communication };


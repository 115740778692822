import classnames from 'classnames'
import React, { Component } from 'react'
import { Translate } from "react-localize-redux"
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { userHasPermission } from '../../../../helpers'
import { verificationActions } from '../../../actions'
import { FALSE, PERMISSIONS } from '../../../constants'
import { CompaniesTabContentData } from './TabContentData'


const TABS = {
    VERIFIED: "2",
    NOT_VERIFIED: "3",
    AADHAR_ADDED: '4',
    ALL: "1",
    NOT_ACTIVE:"5",
}

class Company extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: localStorage.getItem("tab_company") || TABS.NOT_VERIFIED
        }

        this.toggle = this.toggle.bind(this)
        this.getData = this.getData.bind(this)


    }

    componentDidMount() {
        if (userHasPermission(PERMISSIONS.COMPANY_NOT_VERIFIED)) {
            this.setState({ activeTab: TABS.NOT_VERIFIED }, () => this.getData());
        } else if (userHasPermission(PERMISSIONS.COMPANY_VERIFIED)) {
            this.setState({ activeTab: TABS.VERIFIED }, () => this.getData());
        } else if (userHasPermission(PERMISSIONS.COMPANY_ALL)) {
            this.setState({ activeTab: TABS.ALL }, () => this.getData());
        } else {
            this.setState({ activeTab: FALSE }, () => this.getData());
        }
    }

    toggle(tab) {

        if (this.state.activeTab !== tab || localStorage.getItem("tab_company") !== tab) {
            localStorage.setItem("tab_company", tab)
            localStorage.setItem('company_Verification_page_no', 1)
            this.setState({
                activeTab: tab
            }, function () {
                this.getData();
            })
        }
    }

    getData() {
        const { dispatch } = this.props;

        let dataToSend = {
            type: localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") : this.state.activeTab,
            active_page: localStorage.getItem("company_Verification_page_no") ? Number(localStorage.getItem("company_Verification_page_no")) : 1,
            per_page: 15,
            keywords: ''
        }
        dispatch(verificationActions.getCompanies(dataToSend));
    }

    render() {
        const {
            companies,
            loading
        } = this.props;

        return (
            <div>
                <h5 className="mb-4 mt-4">
                    <i className="fa fa-building"></i> &nbsp; Company Verification
                </h5>


                <Nav tabs>

                    {userHasPermission(PERMISSIONS.COMPANY_NOT_VERIFIED) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") === TABS.NOT_VERIFIED : '') || (!localStorage.getItem("tab_company") && this.state.activeTab === TABS.NOT_VERIFIED) })}
                                onClick={() => { this.toggle(TABS.NOT_VERIFIED) }}
                            >

                                <i className="fa fa-building text-danger"></i> &nbsp;&nbsp;
                                <b>
                                    <Translate id='Not Verified / Partially Verified' />
                                </b>

                            </NavLink>
                        </NavItem>}

                        {userHasPermission(PERMISSIONS.COMPANY_ALL) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") === TABS.AADHAR_ADDED : '') || (!localStorage.getItem("tab_company") && this.state.activeTab === TABS.AADHAR_ADDED) })}
                                onClick={() => { this.toggle(TABS.AADHAR_ADDED) }}
                            >
                                <i className="fa fa-building text-warning"></i> &nbsp;&nbsp;
                                <b>
                                    Recent Aadhar (Not Verified) 
                                </b>
                            </NavLink>
                        </NavItem>}
                        {userHasPermission(PERMISSIONS.COMPANY_ALL) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") === TABS.NOT_ACTIVE : '') || (!localStorage.getItem("tab_company") && this.state.activeTab === TABS.NOT_ACTIVE) })}
                                onClick={() => { this.toggle(TABS.NOT_ACTIVE) }}
                            >
                                <i className="fa fa-building text-warning"></i> &nbsp;&nbsp;
                                <b>
                                    Not Active GST
                                </b>
                            </NavLink>
                        </NavItem>}
                    {userHasPermission(PERMISSIONS.COMPANY_VERIFIED) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") === TABS.VERIFIED : '') || (!localStorage.getItem("tab_company") && this.state.activeTab === TABS.VERIFIED) })}
                                onClick={() => { this.toggle(TABS.VERIFIED) }}
                            >
                                <i className="fa fa-building text-success"></i> &nbsp;&nbsp;
                                <b>
                                    <Translate id='Verified' />
                                </b>

                            </NavLink>
                        </NavItem>}

                    {userHasPermission(PERMISSIONS.COMPANY_ALL) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_company") ? localStorage.getItem("tab_company") === TABS.ALL : '') || (!localStorage.getItem("tab_company") && this.state.activeTab === TABS.ALL) })}
                                onClick={() => { this.toggle(TABS.ALL) }}
                            >

                                <i className="fa fa-building text-warning"></i> &nbsp;&nbsp;
                                <b>
                                    <Translate id='All' />
                                </b>

                            </NavLink>
                        </NavItem>}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={TABS.NOT_VERIFIED}>
                        <CompaniesTabContentData loading={loading} individuals={companies} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.NOT_ACTIVE}>
                        <CompaniesTabContentData loading={loading} individuals={companies} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.VERIFIED}>
                        <CompaniesTabContentData loading={loading} individuals={companies} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.ALL}>
                        <CompaniesTabContentData loading={loading} individuals={companies} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.AADHAR_ADDED}>
                        <CompaniesTabContentData loading={loading} individuals={companies} activeTab={this.state.activeTab} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { companies, loading } = state.companiesData;
    return {
        companies,
        loading
    }
}
const connectedCompany = connect(mapStateToProps)(Company)
export { connectedCompany as Company }


import React, { Component } from "react"
import { Route, Switch } from "react-router"
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import { Container } from 'reactstrap'
import { Header } from "../../components"
import Footer from "../../components/Footer.jsx"
import PrivateRoute from '../../components/PrivateRoute'
import {
    AppFooter,
    AppHeader,
} from '@coreui/react'
import indexRoutes from "../../routes/index.jsx"
import { navigations } from "../../routes/navigations"
import {
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react'
import { Link } from "react-router-dom"
import { informationActions } from "../../individual/actions"

class InformationAlert extends Component {
    constructor(props) {
        super(props)
        this.getinformations = this.getinformations.bind(this)
    }

    getinformations() {
        const { dispatch } = this.props;
        dispatch(informationActions.getinformations())
    }
    componentDidMount() {
        this.getinformations()
    }

    render() {
        const {  information } = this.props

        let className = '';
        let icontxt = '';

        if (information && information.length) {
            if (information[0].type == 'Critical') {
                className = 'alert alert-danger'
                icontxt = <i class="fas fa-exclamation-triangle"></i>
            }
            else if (information[0].type == 'Normal') {
                className = 'alert alert-success'
                icontxt = <i className="fa  fa-info-circle text-success"></i>
            }
            else {
                className = 'alert alert-info'
                icontxt = <i className="fa fa-info-circle text-info"></i>
            }
        }
        return (<div>
            <NotificationsSystem theme={theme} />
            {
                information && information.length && information[0].status ?
                    <div className={className + "  mt-3"} role="alert">
                        <b>
                            {icontxt}
                            &nbsp; &nbsp;
                            {information[0].subject}
                            <Link className="pull-right" to='/view-information'> Read More...</Link> 
                        </b>
                    </div>
                    : ''
            }
        </div>

        )
    }
}

function mapStateToProps(state) {
    const { alert } = state
    const { data } = state.getinformations
    return {
        alert,
        information: data
    }
}

const connectedGuestLayout = withRouter(connect(mapStateToProps)(InformationAlert))
export { connectedGuestLayout as InformationAlert }
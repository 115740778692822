import { axiosAjaxCall } from "./common.service";

export const businessAccountTemplateService = {
    saveBusinessAccountTemplate,
    getBusinessAccountTemplate
}

function saveBusinessAccountTemplate(data) {    
    return axiosAjaxCall("save_business_account_template    ", data);
}

function getBusinessAccountTemplate(data) {    
    return axiosAjaxCall("get_business_account_template", data);
}

import React, { Component } from 'react'
import { ModalBody, ModalHeader, ModalFooter, Button, Modal } from 'reactstrap'
import { InputText, InputDropDown } from '../Components/FormElements'
import { v4 as uuidv4 } from 'uuid';
import { processActions, userManagementActions } from '../../../../actions';
import { connect } from 'react-redux';

class AddNewProcess extends Component {
    constructor(props){
        super(props)
        this.state = {
            isOpen :false,
            data : {
                process_name: "",
                assigned_default_job_title_id	:0,
            },
            error: {}
        }
        this.toggle = this.toggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this)
        this.remove = this.remove.bind(this)
        this.clear = this.clear.bind(this)
        this.validation = this.validation.bind(this)
    }


    toggle(e){
        if(e){
            e.stopPropagation();
        }
        const {isEdit, current_data} = this.props;
        let {data} = this.state;
        
        if(isEdit === true) {
            data = {...data, ...current_data}
        }
        let isOpen = !this.state.isOpen
        if(isOpen === true){
            this.clear()
        }
        this.setState({isOpen, data })
    }

    handleChange(event){
        
        let  _dt = event.target
        if(typeof event.target === "undefined") {
            _dt = event;
        }

        const { name, value } = _dt;
        const { data } = this.state;

        data[name] = value;
        this.setState(data);
    }

    save(){  
        const { data } = this.state;
        const {  isEdit, type, getData, dispatch, process_order_number } = this.props;
        
        if(this.validation()){
            return false;
        }

        let dataToSend = {
            type : type ? type : "business_account",
            process_name : data.process_name,
            assigned_default_job_title_id	 : data.assigned_default_job_title_id	,
            process_order_number : process_order_number,
        }
        if(isEdit) {
            dataToSend.process_id = data.id
        }
        dispatch(processActions.saveProcess(dataToSend, {
            success:() => {
                this.clear()
                this.setState({ isOpen : false})
                getData()

            }
        }))
    }

    validation(){
        let {data} = this.state;
        let error = {}
        let isError = false;
        
        
        if( data.process_name.trim() === ""){
            error.process_name = "Enter stage name"
            isError = true
        }
        
        this.setState({error});
        return isError;
    }

    remove(e){
        if(e){
            e.stopPropagation();
        }
        let {data} = this.state;
        const {current_data,  dispatch, getData } = this.props;
  
        
        let dataToSend = {
            process_id : current_data.id,
            action : "remove",
            
        }
    
        dispatch(processActions.saveProcess(dataToSend, {
            success:() => {
                this.clear()
                this.setState({ isOpen : false})
                getData()
            }
        }))
    }
    clear(){
        let {data} = this.state;

        data.id = null;
        data.stage_name = "";
        
        this.setState(data);
    }

    render() {
        const {  data, error } = this.state;
        const { JobTitles, isEdit } = this.props;


		let JobTitlesOptions = [{ title : "Not Assigned", value : false}] 
        if(JobTitles) {
            JobTitles.map((item)=>{
                JobTitlesOptions.push({ title : item.name, value : item.id})
            });
		}

        return (
            <React.Fragment>
                {isEdit ? <React.Fragment>
                    <Button size="sm"  color={"primary"} onClick={(e) => this.toggle(e)}><i className={"fa fa-pencil"}></i></Button> &nbsp;                   
                    <Button size="sm"  color={"danger"} onClick={(e) => this.remove(e)}><i className={"fa fa-trash"}></i></Button>
                </React.Fragment>
                :
                <Button size="sm"   color={"primary"} onClick={(e) => this.toggle(e)}>Add New Process</Button>
                }
         
        <Modal isOpen={this.state.isOpen}>
            <ModalHeader> { isEdit ? "Edit Process" : "Add New Process" }</ModalHeader>
            <ModalBody>
                
                <InputText
                    title={"Process name"}
                    placeholder="Process name"
                    name="process_name"
                    value={data.process_name}
                    error={error.process_name}
                    onChange={this.handleChange}
                    maxLength="250"
                />


                <InputDropDown
                  title={"Default Assign to Job Title"}
                  value={data.assigned_default_job_title_id	}
                  name={"assigned_default_job_title_id"}
                  onChange={this.handleChange}
                  options={JobTitlesOptions}
                />

                
                
            </ModalBody>
            <ModalFooter>
                <Button color="primary"  onClick={() => this.save()}>Save</Button>
                <Button color="secondary" onClick={() => this.clear()}>Clear</Button>
                <Button color="danger" onClick={() => this.toggle()}>Close</Button>
            </ModalFooter>
        </Modal></React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    const {processList, loading} = state.process
    return {
        processList,
        loading,
		JobTitles : state.JobTitles.data,
    }
  }
  
const connectedAddNewProcess = connect(mapStateToProps)(AddNewProcess);
export { connectedAddNewProcess as AddNewProcess };

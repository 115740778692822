import React from 'react';
import { ErrorPageTemplate } from './ErrorPageTemplate';


export const CompanyVerificationPending = () => (
    <ErrorPageTemplate
        text={"Company Account Verification is pending"}
        helptext={"Upload all required documents for verification. If already uploaded all documents then we will notify you soon once the verification process is complete."}
    />
)


export const NotAllowAccess = () => (
    <ErrorPageTemplate
        icon={<i className="fa fa-times-circle-o" aria-hidden="true" style={{ fontSize: "70px", color: "#f26522" }}> </i>}
        text={"Invalid Request"}
        helptext={"Not allow to access this page."}
    />
)


export const NotDataFound = () => (
    <ErrorPageTemplate text={"No data found"} />
)

export const VerificationPending = () => (
    <ErrorPageTemplate
        text={"Account Verification is pending"}
        helptext={"Upload all required documents for verification. If already uploaded all documents then we will notify you soon once the verification process is complete."}
    />
)

export const IndividualAccountDisabled = () => (
    <ErrorPageTemplate
        text={"Account Disabled"}
        helptext={"Your individual account's services are disabled. Please renew your individual account to continue use our services."}
    />
)


export const CompanyAccountDisabled = () => (
    <ErrorPageTemplate
        text={"Account Disabled"}
        helptext={"Your company account's services are disabled. Please renew your company account to continue use our services."}
    />
)


import React from 'react';
import { PropTypes } from 'prop-types';
import { FormText } from 'reactstrap';

const FormHelpText = ({ message, color }) =>   (
    <FormText> <i> <b className={ color || "text-info"} > {message}  </b> </i></FormText>
);

FormHelpText.propTypes = {
    message : PropTypes.string.isRequired
}

export default FormHelpText;
import React, { Component } from "react";
import { InputTypes } from "../../../constants/stages.constants";
import { InputCheckBox } from "../../Settings/StageSetting/Components/FormElements";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";

export default class EachStageDataRender extends Component {
  constructor(props) {
    super(props);

    this.renderData = this.renderData.bind(this);
  }

  renderEachItem(item) {
    console.log(item);
    switch (item.inputType) {
      case InputTypes.Heading:
        return (
          <label>
            <b>{item.title}</b>
          </label>
        );

      case InputTypes.Text:
        return (
          <React.Fragment>
            <FormGroup>
              <label>
                <b>{item.title}</b>
              </label>
              <Input value={item.value} />
            </FormGroup>
          </React.Fragment>
        );

      case InputTypes.TextArea:
        return (
          <React.Fragment>
            <FormGroup>
              <label>
                <b>{item.title}</b>
              </label>
              <Input type="textarea" value={item.value} />
            </FormGroup>
          </React.Fragment>
        );
      case InputTypes.Checkbox:
        return (
          <React.Fragment>
            <div>
              <InputCheckBox
                key={item.id}
                // disabled={true}
                title={item.title}
                name={item.id}
                checked={item.value}
              />
            </div>
          </React.Fragment>
        );

      case InputTypes.CheckList:
        return (
          <React.Fragment>
            <div>
              <label>
                <b>{item.title}</b>
              </label>
              <div>
                {item.items.map((ChlistItem) => {
                  return (
                    <InputCheckBox
                      key={ChlistItem.id}
                      // disabled={true}
                      title={ChlistItem.title}
                      name={ChlistItem.id}
                      checked={ChlistItem.value}
                    />
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        );

      case InputTypes.Radio:
          console.log(item);
        return (
          <React.Fragment>
            <div>
                <FormGroup>
              <label>
                <b>{item.title}</b>
              </label> <br/>
                {item.items.map((ChlistItem, idx) => {
                  return ( 
                    <FormGroup check inline={item.hasOwnProperty("attribute") && item.attribute.hasOwnProperty("inline") ? item.attribute.inline : false }>
                    <Label check  inline={item.hasOwnProperty("attribute") && item.attribute.hasOwnProperty("inline") ? item.attribute.inline : false }>
                      
                    <Input
                      type="radio"
                      key={idx}
                      title={ChlistItem.title}
                      name={item.id}
                      value={ChlistItem.title}
                      checked={ChlistItem.title == item.value }
                    />{" "}
                    {ChlistItem.title}
                    </Label>
                    </FormGroup>
                  );
                })}
              </FormGroup>
            </div>
          </React.Fragment>
        );

      case InputTypes.SubItem:
        return (
          <React.Fragment>
              
            <label>
              <b>{item.title}</b>
            </label>

            {item.children.map((itm) => {
              return <div style={{paddingLeft : "20px"}}>  
              {this.renderEachItem(itm)}
             </div>
            })}
           
          </React.Fragment>
        );
    }
  }

  renderData() {
    const { processStage } = this.props;
    let data =
      typeof processStage.json === "string"
        ? JSON.parse(processStage.json)
        : processStage;

    return (
      data.length &&
      data.map((eachitem) => {
        return this.renderEachItem(eachitem);
      })
    );
  }

  render() {
    return <div>{this.renderData()}</div>;
  }
}

import React, { Component } from "react";
import { associationAction } from '../../actions';
import { CompanySectorAction } from '../../actions/companySector.action'
import swal from "sweetalert";
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
  Form
} from "reactstrap";
import AddressForm from '../../../components/Forms/AddressForm';
import { Translate } from "react-localize-redux";
import { utilityService } from '../../services'

class EditIndividualAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
      data: {
        type_id : ""
      },
      errors: {}
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isValidData = this.isValidData.bind(this);
    this.getIndiviualType = this.getIndiviualType.bind(this);
    this.save = this.save.bind(this);
  }
  showModal() {
    const { modalOpan,data } = this.state;
    const { eachData } = this.props;
    let _modalOpan = !modalOpan;
    this.setState({ modalOpan: _modalOpan }, () => {
      if (_modalOpan) {
        this.getIndiviualType();
        console.log(eachData);
        data.type_id = eachData.association_individual_type_id
        this.setState({data})
      }
    });
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    let { data } = this.state;
    let val = value;

    data[name] = val;
    this.setState({ data });
  }

  isValidData() {
    const { data, errors } = this.state
    let error = false
    if (!data.type_id) {
      error = true
      errors.name = 'this field is required'
    }

    this.setState({ errors })
    return error
  }

  resetForm() {
    const { data } = this.state;
    data.type_id = '';

    this.setState({ data, errors: {} })
  }

  getIndiviualType() {
    const { dispatch } = this.props;
    dispatch(associationAction.getIndiviualType());
  }

  save() {
    const { data } = this.state;
    const { dispatch, type, getData, eachData } = this.props;
   
    if (this.isValidData()) {
      return false;
    }

    let dataToSend = {
      type_id : data.type_id,

      is_edit :true,
      id : eachData.id
    }

    dispatch(associationAction.addAssociationIndividual(dataToSend, {
      success: () => {
        this.showModal();
        getData()
      }
    })
    );
  }


  render() {
    const { modalOpan, data, errors } = this.state;
    const { indiviualTypes } = this.props;
    let _this = this;
    const mendetoryFields = {
      color: "red",
      fontSize: "14px",
      marginLeft: "2px",
    };
    return (
      <React.Fragment>
        <Button color="primary"  onClick={_this.showModal}>
        <i className="fa fa-pencil"></i>
        </Button>
        <Modal className="modal-success" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Edit Individual Association
          </ModalHeader>
          <ModalBody>

            <FormGroup className={' mt-4'}>

              <label>Individual Person Type</label>
              <select className="form-control" name="type_id" onChange={this.handleChange} value={data.type_id}  >
                <option value="">--- Select Type ---</option>
                {
                  indiviualTypes && indiviualTypes.data && indiviualTypes.data.length ?
                    indiviualTypes.data.map((each) => {
                      return <option selected={data.type_id == each.id} value={each.id}>{each.type}</option>
                    })
                    : ''
                }
              </select>
              {errors.type_id && (<FormFeedback> {errors.type_id} </FormFeedback>)}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={_this.save}>
              Save
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    indiviualTypes: state.getIndiviualType
  }
}

const connected = connect(mapStateToProps)(EditIndividualAssociation)
export { connected as EditIndividualAssociation }
import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  Button,
  Modal, ModalBody,
  ModalFooter, ModalHeader
} from "reactstrap";
import swal from "sweetalert";
import { TableLoader } from '../../../components/Loaders';
import { associationAction } from '../../actions';
import { EditIndividualAssociation } from "./EditIndividualAssociation";
import { UpdateReputeID } from './UpdateReputeID';

class IndividualAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
    };
    this.showModal = this.showModal.bind(this);
    this.getAssociationIndividual = this.getAssociationIndividual.bind(this);

  }

  showModal() {
    const { modalOpan } = this.state;
    const { getData } = this.props;
    let _modalOpan = !modalOpan
    this.setState({ modalOpan: _modalOpan }, () => {
      if (_modalOpan) {
        this.getAssociationIndividual()
      } else {
        getData()
      }
    });
  }

  delete(id) {
    const { dispatch } = this.props;

    swal({
      title: "Are you sure ?",
      text: "Do you want to remove individual from association ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Cancel", "Yes, I am sure!"],
    }).then((confirm) => {
      if (confirm) {
        dispatch(
          associationAction.deleteAssociationLink({
            id: id,
            type: 'individual',
          }, {
            success: () => {
              this.getAssociationIndividual()
            }
          })
        );

      }
    });
  }

  getAssociationIndividual() {
    const { dispatch, association_id } = this.props;
    dispatch(associationAction.getAssociationIndividual({
      association_id
    }));
  }

  render() {
    const { modalOpan, } = this.state;
    const { loading, data, eachData, association_id } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <Button color="primary" size="sm" onClick={_this.showModal}>
          Individual  <span className="badge badge-light mb-0 ">{eachData ? eachData.individuals_count : 0}</span>
        </Button> {" "}
        <Modal className="modal-primary" isOpen={modalOpan} size="lg">
          <ModalHeader toggle={_this.showModal}>Individual Association<br />{" "}
            {/* <small>Add remaining negotiated offer</small> */}
          </ModalHeader>
          <ModalBody>
            <UpdateReputeID type="individual" getData={this.getAssociationIndividual} association_id={association_id} />
            {loading ? (
              <TableLoader />
            ) : (
              <React.Fragment>
                <div className="table-responsive-sm  animated fadeIn">
                  <table className="table table-sm table-condensed table-striped table-bordered table-outline pull-left">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Repute ID</th>
                        <th>Mobile</th>
                        <th>Individual Type</th>
                        <th>Created At</th>
                        <th className="text-center">Actions</th>

                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length ? (
                        data.map(function (eachData, index) {
                          return (
                            <React.Fragment>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{eachData.individual_name}</td>
                                <td>{eachData.formatted_individual_repute_id}</td>
                                <td>{eachData.individual_mobile}</td>
                                <td>{eachData.individual_type}</td>
                                <td>{eachData.created_at}</td>
                                <td className="text-center"> 
                                  <EditIndividualAssociation eachData={eachData} getData={_this.getAssociationIndividual} /> &nbsp;
                                  <Button color="danger"  onClick={() => _this.delete(eachData.id)}><i className="fa fa-trash"></i></Button> 
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            {/* <Button color="warning" onClick={this.proceed}>
              Proceed
            </Button> */}
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, loading } = state.getAssociationIndividual
  return {
    data, loading
  }
}

const connectedIndividualAssociation = connect(mapStateToProps)(IndividualAssociation)
export { connectedIndividualAssociation as IndividualAssociation };

import React  , {useState} from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { AccountList } from './account';
import classnames from 'classnames'
// import {AccountAnalysis }  from  './AccountAnalysis'


const TABS = {
    IncomeStatement: "IncomeStatement",
    IncomeAnalysis: "IncomeAnalysis",
}

const AccountStatementTab = () => {
    const [activeTab, setActiveTab] = useState(TABS.IncomeStatement);

    let toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (

        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === TABS.IncomeStatement })}
                        onClick={() => { toggle(TABS.IncomeStatement) }}
                    >
                        <b>Income Statement</b>
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === TABS.IncomeAnalysis })}
                        onClick={() => { toggle(TABS.IncomeAnalysis) }}
                    >
                        <b>Income Analysis</b>
                    </NavLink>
                </NavItem> */}


            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={TABS.IncomeStatement}>
                    <AccountList />
                </TabPane>
                {/* <TabPane tabId={TABS.IncomeAnalysis}>
                    <AccountAnalysis/>
                    <h1>Coming Soon</h1>
                </TabPane> */}
            </TabContent>
        </React.Fragment>

    )
}

export  {AccountStatementTab}
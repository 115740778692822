import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { TabContentData } from './TabContentData'
import { UserLoginLogsActions } from '../../actions';

const TABS = {
    Individual: "individual",
    DebtorCreditor: "debtorcreditor", 
    SuperAdmin: "superadmin",
}

class UserLoginLogs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.Individual,
            page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
            } ,
            keywords:''
        }

        this.toggle = this.toggle.bind(this)
        this.getUserLoginLogs = this.getUserLoginLogs.bind(this)
    }

    componentDidMount() {
        this.getUserLoginLogs();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, function () {
                this.getUserLoginLogs();
            })
        }
    }

    getUserLoginLogs() {
        const { dispatch } = this.props;
        const { page   , keywords } = this.state;
        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            log_type: this.state.activeTab , 
            keywords : keywords
        }
        dispatch(UserLoginLogsActions.getUserLoginLogs(dataToSend));
    }

    render() {
        const { data, loading } = this.props;

        return (
            <div>
                <h5 className="mb-4 mt-4">
                    <i className="fas fa-user-lock"></i> &nbsp; Login Details
                </h5>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.Individual })}
                            onClick={() => { this.toggle(TABS.Individual) }}
                        >
                            <b>Repute Info - Individual</b>

                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.DebtorCreditor })}
                            onClick={() => { this.toggle(TABS.DebtorCreditor) }}
                        >
                            <b> Repute Info - Company</b>

                        </NavLink>
                    </NavItem>
                    <NavItem>

                        <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.SuperAdmin })}
                            onClick={() => { this.toggle(TABS.SuperAdmin) }}
                        >
                            <b> Admin Cpanel</b>

                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={TABS.Individual}>
                        <TabContentData loading={loading} logs={data} isSupperAdmin={false} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.DebtorCreditor}>
                        <TabContentData loading={loading} logs={data} isSupperAdmin={false} isDebtorCreditor={true} activeTab={this.state.activeTab} />
                    </TabPane>
                    <TabPane tabId={TABS.SuperAdmin}>
                        <TabContentData loading={loading} logs={data} isSupperAdmin={true} activeTab={this.state.activeTab} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getUserLoginLogs;
    return {
        loading,
        data
    }
}
const connectedUserLoginLogs = connect(mapStateToProps)(UserLoginLogs)
export { connectedUserLoginLogs as UserLoginLogs }

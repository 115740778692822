


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormFeedback, FormGroup } from 'reactstrap'
import ReactQuill from 'react-quill';
import Editor from 'editor';
import { supportActions } from '../../../actions';
import {  hide } from 'redux-modal';

class AddComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketDetails: {
                comment: "",
            },
            errors: {}
        };

        this.validation = this.validation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeHTML = this.handleChangeHTML.bind(this);
        this.afterSave = this.afterSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
 
    }



    handleChangeHTML(html) {
        const { ticketDetails } = this.state;
        ticketDetails["comment"] = html;
        this.setState({ ticketDetails });
    }

    validation() {
        let error = false;
        const { errors, ticketDetails } = this.state;


        if (!ticketDetails.comment) {
            errors["comment"] = "Please enter ticket comment."
            error = true;
        }

        this.setState({ errors })
        return error;
    }

    handleCancel() {
        const { dispatch } = this.props;
        
        dispatch(hide("bootstrap"))
    }
    
    handleSubmit() {
        const { ticketDetails } = this.state

        if (this.validation()) {
            return false;
        }

        const { dispatch, ticket_id } = this.props;

        let dataToSend = {
            message: ticketDetails.comment,
            ticket_id : ticket_id
        };

        dispatch(supportActions.saveTicketMessage(dataToSend, this));
    }

    afterSave() {
        const { dispatch, ticket_id } = this.props;
        dispatch(supportActions.getTicketDetails({ id: ticket_id })); 
        this.setState({ ticketDetails: {
            comment: "",
        } })
    }
 
    render() {
        const { ticketDetails, errors } = this.state;

        return (
            <React.Fragment>
                <Form>
                    <FormGroup>
                        {/* <label className="font-weight-bold">Write comments</label> */}
                        <ReactQuill
                            theme={this.state.theme}
                            onChange={this.handleChangeHTML}
                            value={ticketDetails.comment}
                            modules={Editor.modules}
                            formats={Editor.formats}
                            bounds={'.app'}
                            placeholder='Write a comment'
                        />
                        <FormFeedback>
                            {errors[`comment`] && errors[`comment `]}
                        </FormFeedback>
                    </FormGroup>
                    <Button color="primary" onClick={this.handleSubmit}>Submit</Button> &nbsp;
                    <Button color="secondary" onClick={this.handleCancel}>Cancel</Button>
                </Form>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    // const { saving } = state.createTicket;

    // return {
    //     saving
    // };

}

const connectedAddComments = connect(mapStateToProps)(AddComments);
export { connectedAddComments as AddComments }

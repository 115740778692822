import React from 'react';
import {  DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withLocalize } from 'react-localize-redux';

import { AppHeaderDropdown } from '@coreui/react';




const LanguageDropdown = ({languages, activeLanguage, setActiveLanguage}) => {
  let lang = "ENG";
  const getClass = (languageCode) => {
    lang = languageCode === activeLanguage.code ? activeLanguage.name : lang;
    return languageCode === activeLanguage.code ? 'active' : '';
  };
  
  return (
  <AppHeaderDropdown direction="down" className="px-2" style={{display: 'none'}}>
     <DropdownToggle  size="sm" color="primary"  caret nav  >
     <i className={ activeLanguage ? activeLanguage.icon : ""  } ></i> 
      <span className="d-sm-down-none"> &nbsp; { activeLanguage? activeLanguage.name : lang }  &nbsp; </span>
     </DropdownToggle>
     <DropdownMenu right style={{ right: 'auto' }}>
       {languages.map(lang => 
       <DropdownItem key={ lang.code } className={getClass(lang.code)} onClick={() => setActiveLanguage(lang.code)}><i className={lang.icon} ></i> {lang.name}</DropdownItem>
       )}
      </DropdownMenu>
   </AppHeaderDropdown>
  );
};

export default withLocalize(LanguageDropdown);


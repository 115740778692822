import React, { Component } from 'react';
import { connect } from 'react-redux';
import { smsLogsAction } from '../../actions';
import { CardBody, Card, CardHeader, Input, Button } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import { renderCronStatus } from '../../../helpers';
import moment from 'moment'
class SMSLogs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: {
				perPage: 20,
				totalItemsCount: 0
			}
		};
		this.getSMSLogsData = this.getSMSLogsData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);

	}

	componentDidMount() {
		this.getSMSLogsData();
	}

	getSMSLogsData() {
		const { dispatch } = this.props;
		const { page  ,keywords  } = this.state;
		let dataToSend = {
			active_page: page.activePage,
			per_page: page.perPage , 
			keywords : keywords
		};
		dispatch(smsLogsAction.getSmsLogData(dataToSend));
	}

	handlePageChange(pageNumber) {
		const { page } = this.state;
		page.activePage = pageNumber;
		this.setState({ page }, () => this.getSMSLogsData());
	}

	componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}

	handleChange(e) {
		const { name, value } = e.target

		this.setState({
			...this.state, [name]: value
		})
	}
	
	handleSearch(e) {
		const { page } = this.state

		this.setState({
			...this.state, page: {
				...page, activePage: 1
			}
		}, () => this.getSMSLogsData())
	}


	render() {
		const { logs, loading } = this.props;
		const { page  , keywords } = this.state;
		return (
			<Card>
				<CardHeader>
					<i className="fas fa-envelope" />
					<strong>Sms Logs</strong>
				</CardHeader>
				<CardBody>
					<div className="d-flex mb-2 " >
						<Input name='keywords' className='w-25 mr-2' value={keywords} onChange={this.handleChange} placeholder='Search...' />
						<Button color='primary' onClick={this.handleSearch}>
							Search
						</Button>
					</div>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-striped table-bordered table-outline">
									<thead className="thead-light">
										<tr>
											<th>#</th>
											<th>Sent By Company</th>
											<th>Mobile</th>
											<th>SMS Type</th>
											<th>SMS Text</th>
											<th>Credit Used</th>
											<th>status</th>
											<th>Sent At</th>
											<th>Delivered At</th>

										</tr>
									</thead>
									<tbody>
										{logs && logs.sms_list && logs.sms_list.length ? (
											logs.sms_list.map(function (eachData, index) {
												return (
													<React.Fragment>
														<tr>
															<td>{eachData.id}</td>
															<td>{eachData.formatted_company_repute_id}  {eachData.company_name ? <React.Fragment>({eachData.company_name}) </React.Fragment> : false} </td>
															<td>{eachData.receiver_mobile_no}</td>
															<td>{eachData.sms_type}</td>
															<td>{eachData.sms_text}</td>
															<td>{eachData.credit_used}</td>
															<td>{eachData.status}</td>
															<td>{eachData.sent_time ? moment(eachData.sent_time).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
															<td>{eachData.delivered_time ? moment(eachData.delivered_time).format('DD/MM/YYYY hh:mm:ss A') : '-'}</td>
														</tr>
													</React.Fragment>
												);
											})
										) : (
											<tr>
												<td colSpan="4" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}

								/>
							)}
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getSmsLogsData;
	return {
		loading,
		logs: data
	};
}

const connectedSMSLogs = connect(mapStateToProps)(SMSLogs);
export { connectedSMSLogs as SMSLogs };

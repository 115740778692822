import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
  ModalFooter,
  Col,
  Row,
  ButtonGroup,
} from "reactstrap";
import { InputCheckBox } from "../Settings/StageSetting/Components/FormElements";
import { tasksActions } from "../../actions";
import InlineError from "../../../components/Messages/InlineError";
import DatePicker from "react-datepicker";
import moment from "moment";


class UpdateCallTaskModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      data: {
          note : "",
          is_call_done : "",
          is_reschedule : false,
          reschedule_date_time : "",
      },
      errors : {}
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  toggle() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  handleChange(e){
      
        const { data } = this.state
        let _e  = e;
        if( e.target) {
            _e = e.target; 
        }   
        const { name, value } = _e

        data[name] = value;

        this.setState({data});
        
  }
  
  save(){
    const { data, isEdit } = this.state;

    const { dispatch, getData, taskData } = this.props;

    if(this.validation()){
        return false;
    }

    let dataToSend = {
        schedule_master_id: taskData.id,
        note : data.note,
        is_communicated : data.is_call_done ? 1 : 0,
        is_reschedule : data.is_reschedule ? 1 : 0,
        reschedule_date_time:  data.is_reschedule ? moment(data.reschedule_date_time).format("YYYY-MM-DD HH:mm:ss") : "",
    };

    dispatch(tasksActions.saveTasks(dataToSend, {
        success : () => {
            getData(); 
            this.toggle() 
        } 
    }))

    
    
  }

  validation() {
        const { data } = this.state;

        let errors = {}
        let error = false;
        if(data.note.trim() == ""){
            errors.note = "Enter Call Note";
            error = true;
        }

        
        if(data.is_call_done === ""){
            error = true;
            errors.is_call_done = "Select any one option";
        }

        
        if(data.is_reschedule === true){
            if(data.reschedule_date_time === "") {
            error = true;
            errors.reschedule_date_time = "Select any one option";
            }
        }

        this.setState({errors});

        return error
  }
  render() {
      let {taskData} = this.props; 
      let {data, errors} = this.state; 
      let _this = this;
    return (
      <div>
        <Button color="primary" onClick={() => this.toggle()}>
          Update
        </Button>

        <Modal isOpen={this.state.show}>
          <ModalHeader>Update Call Details</ModalHeader>
          <ModalBody>
                
                <Row>
                    
              <Col md="4">
                <Label><b>Business Name : </b></Label> 
              </Col>
              <Col md="8">
                <Label>{taskData.business_name}</Label>
              </Col>
              
              <Col md="4">
              <Label><b>Individual Name : </b></Label>
              </Col>
              <Col md="8">  
              <Label>{taskData.individual_name}</Label>
              </Col>

              <Col md="4">
              <Label><b>Mobile : </b></Label>
              </Col>
              <Col md="8">            
              <Label>{taskData.individual_mobile}</Label>            
              </Col>
             
              <Col md="4">
                <Label><b>Email : </b></Label>
              </Col>
              <Col md="8">            
                <Label>{taskData.individual_email}</Label>
              </Col>

              <Col md="4">
                <Label><b>Is communicated ? </b></Label>
              </Col>
              <Col md="8"> 
              
              <ButtonGroup>
                <Button 
                color={data.is_call_done === true ? "primary" : ""} 
                 onClick={(e) => {
                    this.handleChange({ name: "is_call_done", value : true})
                }}>Yes</Button>
                <Button color={data.is_call_done === false ? "primary" : ""} onClick={(e) => {
                    this.handleChange({ name: "is_call_done", value : false})
                }}>NO</Button>
              </ButtonGroup>
              { errors.is_call_done && <InlineError message={errors.is_call_done} /> }

              </Col>


                </Row>


            <FormGroup>
              <Label>Call Note</Label>
              <Input type={"textarea"} 
              name="note" value={data.note}
              style={{height:100}}
              onChange={this.handleChange} />
                    { errors.note && <InlineError message={errors.note} /> }

            </FormGroup>

            <FormGroup>
              <InputCheckBox
                title="Reschedule"
                checked={data.is_reschedule}
                onChange={(e) => {
                    this.handleChange({ name: "is_reschedule", value : e.target.checked})
                }}
                />

            </FormGroup>


            
            {data.is_reschedule === true && (
                      <React.Fragment>

                    <FormGroup>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select schedule time"
                        selected={data.reschedule_date_time}
                        onChange={(date) => _this.handleChange({ name :"reschedule_date_time" , value : date })}
                        // dateFormat="DD-MM-YYYY"
                        // maxDate={lastMonth}
                        minDate={new Date()}
                        showTimeSelect
                        value={data.reschedule_date_time ? moment(data.reschedule_date_time).format("DD-MM-YYYY hh:mm A") : ""}
                        // showMonthYearPicker
                      />
                    { errors.reschedule_date_time && <InlineError message={errors.reschedule_date_time} /> }
                    </FormGroup>
                        </React.Fragment>
                    
                    )}

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              {" "}
              Save{" "}
            </Button>
            <Button className="btn-danger" onClick={() => this.toggle()}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

let connected =  connect(
    mapStateToProps
  )(UpdateCallTaskModal);

export {connected as UpdateCallTaskModal }

import { LegalConstants } from "../constants"


export function getLegal(state = {}, action) {
  switch (action.type) {
    case LegalConstants.GET_LEGAL_REQUEST:
      return { loading: true }
    case LegalConstants.GET_LEGAL_SUCCESS:
      return { data: action.result }
    case LegalConstants.GET_LEGAL_FAILURE:
      return {}
    default:
      return state
  }
}

export function saveLegal(state = {}, action) {
  switch (action.type) {
    case LegalConstants.EDIT_LEGAL_REQUEST:
      return { saving: true }
    case LegalConstants.EDIT_LEGAL_SUCCESS:
      return {}
    case LegalConstants.EDIT_LEGAL_FAILURE:
      return {}
    default:
      return state
  }
}

export function publishLegal(state = {}, action) {
  switch (action.type) {
    case LegalConstants.PUBLISH_LEGAL_REQUEST:
      return { publishing: true }
    case LegalConstants.PUBLISH_LEGAL_SUCCESS:
      return {}
    case LegalConstants.PUBLISH_LEGAL_FAILURE:
      return {}
    default:
      return state
  }
}


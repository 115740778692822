import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { productsAction } from "../../actions";
// import ImageGallery from "react-image-gallery";
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
    };
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan }, () => {
      if (this.state.modalOpan === true) {
        this.getProduct();
      } else {
      }
    });
  }

  getProduct() {
    const { product, dispatch } = this.props;

    let dataToSend = {
      product_uuid: product.uuid,
    };

    dispatch(productsAction.getProduct(dataToSend));
  }


  render() {
    const { modalOpan } = this.state;
    const { data,  index } = this.props;

    let _this = this;
    return (
      <div>
        <Tooltip
          className="pull-left m-0 p-0 ml-2"
          interactive
          arrow={true}
          title="Product Leads Details"
        >
          <Button color="primary" class="pull-left" onClick={_this.showModal}>
            View Details
          </Button>
        </Tooltip>
        <Modal className="modal-primary" size="lg" isOpen={modalOpan}>
          <ModalHeader  className="modal-primary"   toggle={_this.showModal}>
            Product Leads Details {index + 1}
            <br />
          </ModalHeader>
          <ModalBody>
            <div className="d-flex p-5 justify-content-between" style={{ width: "100%"}}>
              <div className="d-flex" style={{ height: "50%"}}>
                <div>
                  {/* <ImageGallery
                    items={
                      (data &&
                        data.images &&
                        data.images.map((each) => {
                          return {
                            original: each.image_url,
                            thumbnail: each.image_url,
                            thumbnailHeight: 40,
                            loading: "lazy",
                          };
                        })) ||
                      []
                    }
                    thumbnailPosition={"left"}
                    showPlayButton={false}
                    useTranslate3D={true}
                    showNav={true}
                    style={{ height: "400px"}}
                  
                  /> */}
                </div>
              </div>
              <div className="mx-5" style={{ width: "50%"}}>
                <h3 className="text-dark"><b>{data && data.product_name}</b></h3>
                <h2 className='d-inline fw-bold font-weight-normal my-2 text-dark'>
                  {data && data.is_price_visible ? ( <span>₹ {data && data.product_price}</span>   ) : (
                             <span>Ask For Price</span>
                      )}</h2><span className=''>/ {data && data.unit&& data.unit.unit_name}</span>
                
                <div className="my-2"><h3 className='' data-tip=""> Product Category : {data &&data.categories&&data.categories.category_name}</h3></div>

                <div className='mt-8 my-2' dangerouslySetInnerHTML={{ __html: data &&data.product_description }} />

                <div className='my-2'>
                            {data &&data.product_custom_details&& data.product_custom_details.length ? <>

                                {/* <h3 className='font-medium text-base  my-3'> Product Specifications : </h3> */}

                                {data.product_custom_details.map((each) => (<>
                                    <div className='d-flex'>
                                        <div className='mr-5'>{each.attribute_key}</div>
                                        <div className=''>{each.attribute_value}</div>
                                    </div>
                                    <hr />
                                </>))}
                            </> : false}
                  </div>

                  <div className='my-2' >
                                <h4 className=' '>Tags for Product</h4>
                                <div className='d-flex '>
                                    {data &&data.tags&&data.tags.length ? <>

                                        {data.tags.map((each) => (<>
                                            <div className="p-2 bg-light mx-2 px-2 rounded font-weight-bolder">{each.tag_name}</div>
                                        </>))}

                                    </>
                                        : false}
                                </div>
                    </div>


                </div>
           </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getProduct;
  console.log(data, "data");
  console.log("loading", loading);
  return {
    loading,
    data,
  };
}
const connected = connect(mapStateToProps)(Detail);
export { connected as Detail };

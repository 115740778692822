import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card, CardHeader, Button } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import Pagination from "react-js-pagination";
import { associationAction } from '../../actions';
import {AddAssociation} from './AddAssociation'
import {IndividualAssociation} from './IndividualAssociation'
import {CompanyAssociation} from './CompanyAssociation'
import swal from 'sweetalert';
class Association extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page : {
                perPage: 20,
                totalItemsCount: 0
            }
		};
        this.getAssociation = this.getAssociation.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
        this.renderAddress = this.renderAddress.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.deleteAssociation = this.deleteAssociation.bind(this);
    }

    componentDidMount() {
		this.getAssociation();
	}
    getAssociation() {
        const { dispatch } = this.props;
		const { page } = this.state;
		let dataToSend = {
			active_page: page.activePage,
			per_page: page.perPage
		};
		dispatch(associationAction.getAssociation(dataToSend));
    } 

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getAssociation());
    }
    componentWillReceiveProps(props) {
		let { page, activeTab } = this.state;
		page.totalItemsCount = 0;
		if (props.logs) {
			page.totalItemsCount = props.logs.total_records;
		}
		if (props.activeTab) {
			activeTab = props.activeTab;
		}
		this.setState({
			page,
			activeTab
		});
	}

	changeStatus(data) {
		const { dispatch } = this.props;
		let dataToSend = {
			id : data.id,
			is_published : !data.is_published 
		}
		dispatch(associationAction.changeStatusAssociation(dataToSend,{
			success : () => {
				this.getAssociation()
			}
		}));
	}
	deleteAssociation(data) {
		const { dispatch } = this.props;
		var dataToSend = {
			id: data.id
		};
		swal({
			title: data.deleted_at ? 'Are you sure you want to undelete ?' : 'Are you sure you want to delete ?',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				// Here delete email id and call action here
				dispatch(associationAction.deleteAssociation(dataToSend,{
					success : () => {
						this.getAssociation()
					}
				}));
			}
		});

	}
    renderAddress(address) {
        return (
            <React.Fragment>
				{address.line1 ? address.line1 + ', ' :''}
				{address.line2 ? address.line2  + ', ' : ''}
				{address.city ? address.city + ', '  : '' }
				{address.taluka ? address.taluka   + ', \n' :''}
				{address.district + ', ' + address.state + ', ' + address.pincode}

            </React.Fragment>
        )
    }
	renderStatus(status) {
		return	status ? <label class="badge badge-success mr-2">Published</label> : <label class="badge badge-danger mr-2 product-name-badge">Unublished</label>
	}
    render() {
        const { logs, loading } = this.props;
		const { page } = this.state;
        let _this = this;
        return (
			<Card>
				<CardHeader>
					<i className="fas fa-users" />
					<strong>Groups</strong>
                    <AddAssociation getData={this.getAssociation} />
				</CardHeader>
				<CardBody>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
                        <div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-striped table-bordered table-outline">
									<thead className="thead-light">
										<tr>
											<th>#</th>
											<th>Name</th>
											<th>Description</th>
											<th>Sector</th>
											<th>Address</th>
											<th>Minimum Member Limit</th>
											<th>Minimum Company Balance</th>
                                            <th>Is Published</th>
											<th>Action</th>

										</tr>
									</thead>
									<tbody>
										{logs && logs.association_list && logs.association_list.length ? (
											logs.association_list.map(function(eachData, index) {
												return (
													<React.Fragment>
														<tr>
															<td>{ index + 1}</td>
															<td>{eachData.name} {eachData.group_type ? <span className='badge badge-primary'>{eachData.group_type}</span> : false } </td>
															<td>{eachData.description}</td>
															<td>{eachData.sector}</td>
															<td>{_this.renderAddress(eachData.address)}</td>
															<td>{eachData.member_company_limit}</td>
															<td>{eachData.minimum_member_company_balance}</td>
															<td>{_this.renderStatus(eachData.is_published) }</td>
                                                            <td>
																<React.Fragment>
																	<Button onClick={() => _this.changeStatus(eachData)} className="btn-sm" color={eachData.is_published ? 'danger' : 'success'}> { eachData.is_published ? 'Unublish' :'Publish' }  </Button> {'  '}
																	<IndividualAssociation eachData={eachData} association_id={eachData.id} getData={_this.getAssociation} />
																	<CompanyAssociation  eachData={eachData} association_id={eachData.id} getData={_this.getAssociation} />
																	<AddAssociation eachData={eachData} getData={_this.getAssociation} is_edit={true} />
																	<Button onClick={() => _this.deleteAssociation(eachData)} className="btn-sm" > { eachData.deleted_at ? 'Undelete' : 'Delete'}  </Button> {'  '}
																</React.Fragment>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										) : (
											<tr>
												<td colSpan="8" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
                            {page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}
									
								/>
							)}
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		);
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getAssociation;
    
	return {
		loading,
		logs : data
	};
}

const connectedAssociation = connect(mapStateToProps)(Association)
export { connectedAssociation as Association}
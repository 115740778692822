import React, { Component } from 'react';
import { connect } from 'react-redux';
import filterFactory from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import { Row, Col, CardBody, Card, CardHeader,Button } from 'reactstrap';
import { DataTable } from '../../../components/Tables/DataTable';
import { LegalActions } from '../../actions';
import swal from 'sweetalert';
import { TableLoader } from '../../../components/Loaders';

class Legal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editLegalData: {},
            showAddLegal: false
        }

        this.afterAction = this.afterAction.bind(this);
        this.renderAction = this.renderAction.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(LegalActions.getLegal());
    }           

    renderAction(cell, row, rowIndex, formateExtraData){
        
        const { dispatch, publishing } = this.props;
        const confirmPublish = () => {
            swal({
                title: "Are you sure?",
                text: "Do you want to publish from draft ? It will visible on reputeinfo.com",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    let dataToSent = {
                        content_for : row.content_for
                    };
            
                    dispatch(LegalActions.publishLegal(dataToSent, this));
                }
            });
        }

        return <React.Fragment>
            { parseInt(row.sub_version,10) === 0 ? 
            <Link className="btn btn-success btn-sm" to={"/legal/edit/"+row.id + "?cf="+row.content_for}> New draft </Link>  : 
            <Link className="btn btn-primary btn-sm" to={"/legal/edit/"+row.id + "?cf="+row.content_for}> Edit </Link> } {" "}
            {  parseInt(row.sub_version,10) === 0 ?  "" : 
            <Button  className="btn btn-danger btn-sm"  onClick={(e) => confirmPublish()}  to={"/legal/edit/"+row.id + "?cf="+row.content_for}> { publishing ? "Please Wait..." : "Publish to Reputeinfo" } </Button> }
        </React.Fragment>
    }

    afterAction(){
        const { dispatch } = this.props;
        dispatch(LegalActions.getLegal());
    }

    renderVersion(cell, row, rowIndex, formateExtraData){
        return row.version + "." + row.sub_version ;
    }



    render() {

        const {data, loading} = this.props;

        const columns = [{
            text: 'Content For',
            dataField: 'content_for',
            sort: true
        }, {
            text: 'Description',
            dataField: 'description',
            sort: true
        }, {
            text: 'Last Published',
            dataField: 'last_published',
            sort: true
        }, {
            text: 'Last Saved on',
            dataField: 'updated_at',
        }, {
            text: 'Version',
            dataField: 'version',
            formatter: this.renderVersion,
        }, {
            text: 'Action',
            dataField: 'action',
            formatter: this.renderAction
        }];

        return (
            <div className="">

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <i className="fa fa-legal"></i><strong>Legal</strong>
                            </CardHeader>
                            <CardBody>
                                {loading ? <TableLoader /> :
                                <DataTable keyField="id"
                                    data={data || []} columns={columns}
                                    filter={filterFactory()}
                                noDataIndication={"No data found"} /> }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getLegal;
    return {
        loading,
        data
    }
}

const connectedLegal = connect(mapStateToProps)(Legal)
export { connectedLegal as Legal }

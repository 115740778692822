import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalBody, ModalHeader, ModalFooter, Button, Modal, Card, CardHeader, CardBody, ListGroup, ListGroupItem, FormFeedback } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { InputText, InputDropDown } from '../Settings/StageSetting/Components/FormElements';
import { associationAction } from '../../actions';
import swal from 'sweetalert'

const defaultData = {
    name : "",
    department_id : 0,
    order_no_in_department:0,
}
class AssociationIndividualType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show : false,
            data : {...defaultData},
            formData: {
                individual_type: '',
            },
            errors : {}
        }
        this.toggle = this.toggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.reset = this.reset.bind(this)
        this.edit = this.edit.bind(this)
        this.getData = this.getData.bind(this)
        this.isValid = this.isValid.bind(this)
    }

    reset(){

    }

    
  handleChange(event) {
    let  _dt = event.target
    if(typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { formData } = this.state;
    formData[name] = value;
    this.setState(   formData );
  }

  isValid() {
      const { formData, errors} = this.state
      let error = false
      if(!formData.individual_type) {
        errors.individual_type = 'this field is required'
        error = true
      }

      this.setState({ errors })

      return error;
  }

  save(){
    const { formData, isEdit } = this.state;

    const { dispatch } = this.props;

    if(!this.isValid()) {

        let dataToSend = {
            individual_type : formData.individual_type,
        };
    
        if(isEdit){
            dataToSend.id = formData.id   
        }
    

        
        dispatch(associationAction.addIndiviualType(dataToSend, {
            success : () => {
                this.setState({ 
                    show:false,
                    isEdit: false,
                });
                this.getData();          
            } 
        }))

    }    
    
    
  }

  edit(editData){
    let { formData } = this.state;
    formData.individual_type = editData.type;
    formData.id = editData.id;
    this.setState({ isEdit:true, show: true, formData });
  }

  
  toggle(value) {
    let {formData, isEdit } = this.state;
    formData.individual_type = ''
    this.setState({ 
        show: value,
        formData
     });
  }

  componentDidMount(){
     
    this.getData();
  }

  getData(){
    const { dispatch } = this.props;
    let dataToSend = {}
    dispatch(associationAction.getIndiviualType(dataToSend));
    
  }

  remove(id) {
    const { dispatch } = this.props;
    let dataToSend = {
        id:id
    }
    let _this = this
    swal({
        title: "Are you sure you want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            // Here delete email id and call action here
            dispatch(associationAction.deleteIndiviualType(dataToSend, {
                success : () => {
                    _this.getData();  
                } 
            }))

        }
    });
  }


    render() {
        const {data, show, isEdit, formData, errors } = this.state
        const {templates, loading, departments } = this.props
        console.log(templates);

        
        let Options = [{ title : "Not Assigned", value : 0}] 
        if(departments) {
            departments.map((item)=>{
                Options.push({ title : item.name, value : item.id})
            });
        }
        return (
            <div>
                <Card>
                    <CardHeader>
                        Indiviual Types 
                        <Button color="primary" className="pull-right" onClick={()=> {
                            this.toggle(true)
                            this.setState({ 
                                isEdit: false,
                                data : defaultData
                             })
                        }}
                        >Add Indiviual Type </Button>
                    </CardHeader>
                    <CardBody>
                        {loading ? <TableLoader/> :
                        <ListGroup>
                        {templates && templates.length ? templates.map((item, idx) => {
                            return <ListGroupItem> <label className="badge badge-success">{idx +1}</label> {item.type}  &nbsp;&nbsp; 
                             <Button size="sm" color="primary"
                             onClick={() => this.edit({...item, idx})}><i className={"fa fa-edit"}>
                                 </i></Button> &nbsp;&nbsp;
                             <Button size="sm" color="danger" onClick={() => this.remove(item.id)}><i className={"fa fa-trash"}>
                                 </i></Button>
                                 </ListGroupItem  >
                        }) : <ListGroupItem> No data found</ListGroupItem>}
                        </ListGroup> }
                    </CardBody>
                </Card>

                <Modal
                    isOpen={show} 
                    toggle={() => this.toggle(false)}>
                    <ModalHeader>
                        {isEdit ? "Edit" : "Add" } Indiviual Type
                    </ModalHeader>
                    <ModalBody>
                    <InputText
                        title={"Indiviual Type"}
                        placeholder="Indiviual Type"
                        value={formData.individual_type}
                        name="individual_type"
                        onChange={this.handleChange}
                        maxLength="250"
                        />
                         {errors.individual_type && (
                    <FormFeedback>
                      {errors.individual_type}
                    </FormFeedback>
                  )}
                     </ModalBody>
                    
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>
                        Save
                        </Button>{" "}
                        <Button color="secondary" onClick={() => this.toggle(false)}>
                        Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {loading, data } = state.getIndiviualType
  return {
    loading,
    templates :data,
    departments : state.Departments.data
  }
    
}


const connectedCM = connect(mapStateToProps)(AssociationIndividualType)
export { connectedCM as AssociationIndividualType }

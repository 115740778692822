import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Card, CardBody, CardHeader } from "reactstrap";

import { GenerateTickets } from "./Tickets/GenerateTickets";
import { CustomerTickets } from "./Tickets/CustomerTickets";

import moment from "moment";

class CustomerCompanies extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      companyList,
      individual_repute_id,
      supportProcessTemplate,
    } = this.props;
    const _this = this;
    return companyList ? (
      <Col md="12">
        <Card>
          <CardHeader>
            {" "}
            <i class="fa fa-building"></i> <b> Companies </b>{" "}
          </CardHeader>
          {companyList.length > 0 ? (
            companyList.map(function (eachData) {
              return (
                <React.Fragment>
                  <CardBody style={{ borderBottom: "1px solid #c8ced3" }}>
                    <b>
                      {eachData.company_name} (
                      {eachData.formated_company_repute_id}){" "}
                    </b>
                    
                    <GenerateTickets
                      {..._this.props}
                      company_repute_id={eachData.company_repute_id}
                      individual_repute_id={individual_repute_id}
                      current_stage={supportProcessTemplate}
                    />
                    
                    <CustomerTickets
                      {..._this.props}
                      tickets={eachData.tickets}
                    />

                  </CardBody>
                </React.Fragment>
              );
            })
          ) : (
            <CardBody style={{ borderBottom: "1px solid #c8ced3" }}>
              No Company Found
            </CardBody>
          )}
        </Card>
      </Col>
    ) : (
      ""
    );
  }
}

function mapStateToProps(state) {
  const { companyList, supportProcessTemplate } = state.verifySupportPIN;

  return {
    companyList,
    supportProcessTemplate,
  };
}

const connectedCustomerCompanies = connect(mapStateToProps)(CustomerCompanies);
export { connectedCustomerCompanies as CustomerCompanies };

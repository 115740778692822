import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from "react-localize-redux"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Row, Col, CardBody, Card, CardHeader, Button } from 'reactstrap'
import { Link } from "react-router-dom";
import { DataTable } from '../../../components/Tables/DataTable'
import Dropdown from './comman'
import swal from 'sweetalert'
import { userManagementActions } from '../../actions/userManagement.action';
import { TableLoader } from '../../../components/Loaders';
import { DEFAULT_USER_TYPE, LAST_AMOUNT } from '../../constants';
import { UncontrolledTooltip } from 'reactstrap';
import { generateTextForPermission } from '../../../helpers';

import { currencyFormat } from '../../../helpers';

import { UpgradeUserAccount } from './UpgradeUserAccount';

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.renderIsActive = this.renderIsActive.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.afterAction = this.afterAction.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {

        this.getUsers()
    }

    getUsers() {
        const { dispatch } = this.props;
        dispatch(userManagementActions.getUsers());
    }

    renderIsActive(cell, row, rowIndex, formateExtraData) {

        return this.props.authUser.id === row.id ?
            <React.Fragment>
                {parseInt(row.is_active, 6) === 1 ? "Active" : "Inactive"}
            </React.Fragment>
            : <React.Fragment>
                <Dropdown user={row}  {...this.props} afterAction={this.afterAction} />
            </React.Fragment>
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        const { dispatch, UserTypes } = this.props
        const confirmDelete = () => {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this user!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    dispatch(userManagementActions.deleteUser({ id: row.id }, this));
                }
            });
        }

        let isCommissionUsers = row && row.user_type && row.user_type.name && (row.user_type.name == 'Partner'
            || row.user_type.name == 'Reseller'
            || row.user_type.name == 'Employee'
            || row.user_type.name == 'Associate');

        return (<React.Fragment>
            <Link className="btn btn-primary btn-sm" to={"/user-management/edit-user/" + row.id}>
                {isCommissionUsers && this.props.authUser.user_type_name != DEFAULT_USER_TYPE.SUPER_ADMIN ? "Edit Commission" : "Edit"}
            </Link>
            {/* {this.props.authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ?
                <Button color="danger" disabled={row.user_type ? (row.user_type.name === DEFAULT_USER_TYPE.ADMIN || row.user_type.name === DEFAULT_USER_TYPE.SUPER_ADMIN) : ""} onClick={confirmDelete} size="sm">Delete</Button> : false} */}
            {isCommissionUsers ? <UpgradeUserAccount UserTypes={UserTypes} userData={row} /> : false}

        </React.Fragment>
        )
    }

    afterAction() {
        // swal("Poof! User has been deleted!", {
        //     icon: "success",
        // });
        this.getUsers();
    }

    renderUsertype(cell, row) {
        if (row.user_type) {

            if (row.deposit_plan) {
                return <React.Fragment>
                    {row.user_type.name}  <br />
                    <small title="Deposit Plan"><b>({row.deposit_plan.deposit_type}) </b> </small>
                </React.Fragment>

            } else {

                return row.user_type.name;
            }
        }

        return "-";
    }
    renderIsAutoAssign(cell, row) {
        return (
            <React.Fragment>
                <i className={"fas fa-clipboard-check  cursor-pointer " + (row.is_auto_assign == 1 ? "text-primary" : "text-dark")} id={'tooltip_auto_assingn' + row.id}></i>
                <UncontrolledTooltip placement="top" target={'tooltip_auto_assingn' + row.id}>
                    {row.is_auto_assign == 1 ? 'Enabled Auto Assign' : 'Disabled Auto Assign'}
                </UncontrolledTooltip>
                &nbsp;  &nbsp; &nbsp;
                <i className={"fas fa-user-lock cursor-pointer " + (row.permission === 'ALL' ? "text-danger" : "text-primary")} id={'tooltip_permission' + row.id}></i>
                <UncontrolledTooltip placement="top" target={'tooltip_permission' + row.id}>
                    {<div className="text-left">
                        <label className="font-weight-bolder ">Permissions</label><br />
                        {generateTextForPermission(row.permission)}
                    </div>}
                </UncontrolledTooltip>
                &nbsp;  &nbsp; &nbsp;
                {/* <i className={"fas fa-money-bill-wave cursor-pointer " + ( row.commission_slab_rate && row.commission_slab_rate.length  ? "text-primary" : "text-dark")}   id={'tooltip_commission' + row.id}></i>
                <UncontrolledTooltip placement="top" target={'tooltip_commission' + row.id}>
                    {
                        row.commission_slab_rate.length ?
                            <React.Fragment>
                                <div className="text-left" style={{width : "250px"}}>
                                <label className="font-weight-bolder ">Commission Slab Rates</label>
                                <div className="row">
                                    <div className="col-sm-4 font-weight-bolder">Min</div>
                                    <div className="col-sm-4 font-weight-bolder">Max</div>
                                    <div className="col-sm-4 font-weight-bolder">%</div>
                                </div>
                                {
                                    row.commission_slab_rate.map(function (each, index) {
                                        return <div className="row">
                                            <div className="col-sm-4">₹ {each.min}</div>
                                            <div className="col-sm-4"> {parseInt(each.max,10)  === LAST_AMOUNT ? "or More" : "₹ " + each.max }</div>
                                            <div className="col-sm-4">{each.commission_rate}</div>
                                        </div>
                                    })
                                }
                                </div>
                            </React.Fragment>
                            : 'Disabled Commission Slab Rates'

                    }

                </UncontrolledTooltip> */}
            </React.Fragment>

        )
    }
    renderSalary(salary) {
        return "₹ " + currencyFormat(salary);
    }
    render() {
        const { data, loading } = this.props

        let columns = [
            {
                text: <Translate id='Name' />,
                dataField: 'name',
                sort: true,
                filter: textFilter({
                    placeholder: "User Name",
                })
            }, {
                text: 'Email',
                dataField: 'email',
                sort: true,
                filter: textFilter({
                    placeholder: "Email Id",
                })
            }, {
                text: <Translate id='Mobile Number' />,
                dataField: 'mobile',
                sort: true,
                filter: textFilter({
                    placeholder: "Mobile",
                })
            }];


        if (this.props.authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN) {
            columns.push({
                text: 'Job Title',
                dataField: 'job_title.name',
                sort: true
            });
        }

        columns.push({
            text: <Translate id='Type' />,
            dataField: 'user_type',
            formatter: this.renderUsertype,
            sort: true
        });

        if (this.props.authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN) {
            columns.push({
                text: "reporting to",
                dataField: 'reporting_to_person.name',
            });
        }

        if (this.props.authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN) {
            columns.push({
                text: "Status",
                dataField: 'is_active',
                formatter: this.renderIsActive,
                sort: true
            });
        }
        if (this.props.authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN) {

            columns.push({
                text: "",
                dataField: '',
                formatter: this.renderIsAutoAssign,
            });
        }


        columns.push({
            text: <Translate id='Action' />,
            dataField: 'action',
            formatter: this.renderAction
        });


        return (
            <div className="">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <i className="fa fa-users"></i><strong> <Translate id="Users" /></strong>
                            </CardHeader>
                            <CardBody>
                                {loading ? <TableLoader /> :
                                    <DataTable keyField="id"
                                        data={data || []} columns={columns}
                                        filter={filterFactory()}
                                        noDataIndication={"No data found"} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getUsers
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user,
    }
}

const connectedUsers = connect(mapStateToProps)(Users)
export { connectedUsers as Users }

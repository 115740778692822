import { notify } from 'reapop'
import { criticalDueConstants } from '../constants'
import { criticalDueService } from '../services'

export const CriticalDue = {
    getDirectCriticalDue,
}

function getDirectCriticalDue(data) {
    return dispatch => {
        dispatch(request({ data }));

        criticalDueService.getDirectCriticalDue(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: criticalDueConstants.GET_DIRECT_CRITICAL_DUE_REQUEST } }
    function success(result) { return { type: criticalDueConstants.GET_DIRECT_CRITICAL_DUE_SUCCESS, result } }
    function failure(error) { return { type: criticalDueConstants.GET_DIRECT_CRITICAL_DUE_FAILURE, error } }
}
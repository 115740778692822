import {  axiosAjaxCall } from './common.service'
import {  showLoader } from "../../helpers";

export const MilestoneLogService = {
    getMilestoneLogs
}

function getMilestoneLogs(data) {
    
    showLoader();    
    return axiosAjaxCall("get_milestone_logs", data);
}
export const landingPageData = {
    SEND_LANDING_PAGE_DATA_REQUEST: 'SEND_LANDING_PAGE_DATA_REQUEST',
    SEND_LANDING_PAGE_DATA_SUCCESS: 'SEND_LANDING_PAGE_DATA_SUCCESS',
    SEND_LANDING_PAGE_DATA_FAILURE: 'SEND_LANDING_PAGE_DATA_FAILURE',

    GET_LANDING_PAGE_DATA_REQUEST: 'GET_LANDING_PAGE_DATA_REQUEST',
    GET_LANDING_PAGE_DATA_SUCCESS: 'GET_LANDING_PAGE_DATA_SUCCESS',
    GET_LANDING_PAGE_DATA_FAILURE: 'GET_LANDING_PAGE_DATA_FAILURE',

    GET_LANDING_PAGE_USER_DATA_REQUEST: 'GET_LANDING_PAGE_USER_DATA_REQUEST',
    GET_LANDING_PAGE_USER_DATA_SUCCESS: 'GET_LANDING_PAGE_USER_DATA_SUCCESS',
    GET_LANDING_PAGE_USER_DATA_FAILURE: 'GET_LANDING_PAGE_USER_DATA_FAILURE',
    
}
import React from 'react'
import { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PromoCodeData from './PromoCodeData';
import { PromoCodeUsageList } from './PromoCodeUsageList';
import PromoCodeBanner from './PromoCodeBanner'

function Promocode() {
    const [ActiveTab, SetActiveTab] = useState('PromocodeBanner');

    const TABS = {
        Promocode: "Promocode",
        PromocodeUsage: "PromocodeUsage",
        PromocodeBanner: "PromocodeBanner",
    };

    let toggle = (tab) => {
        SetActiveTab(tab);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader> <i className="fa fa-gift"></i>  <b>Promo Code</b></CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.PromocodeUsage,
                                        })}
                                        onClick={() =>
                                            toggle(TABS.PromocodeUsage)
                                        }
                                    >
                                        <span style={{ fontWeight: "bold" }}>Codes</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className=''>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.Promocode ,
                                        })}
                                        onClick={() => {
                                            toggle(TABS.Promocode );
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold" }}>Usages</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className=''>
                                    <NavLink
                                        className={classnames({
                                            active: ActiveTab === TABS.PromocodeBanner ,
                                        })}
                                        onClick={() => {
                                            toggle(TABS.PromocodeBanner );
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold" }}>Banners</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={ActiveTab}>
                                <TabPane tabId={TABS.PromocodeUsage }>
                                    <PromoCodeData />
                                </TabPane>
                                <TabPane tabId={TABS.Promocode}>
                                <PromoCodeUsageList />
                                </TabPane>
                                <TabPane tabId={TABS.PromocodeBanner}>
                                <PromoCodeBanner />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export { Promocode }
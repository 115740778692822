import { axiosAjaxCall } from "./common.service";

export const dailyMisService = {
    getDailyMis,
}


function  getDailyMis(data) {    
    return axiosAjaxCall("get-daily-mis", data);
}


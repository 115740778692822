import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { InputText, InputRadio } from "../FormElements";

export default class RadioItems extends Component {
  render() {
    const { item, idx , removeOption, handleChange, handleChangeItem, isShowDelete, data} = this.props;
    return (
      <Row>
        <Col md="1">
          <label>
            <b> {idx + 1} </b>
          </label>
        </Col>

        <Col md="5">
          <InputText
            title={false}
            placeholder="Title"
            name="title"
            value={item.title}
            onChange={e => handleChangeItem(e, idx)}
            maxLength="250"
          />
        </Col>

        <Col md="2">
          <InputRadio 
            name={data.id}
            title={false}
            checked={data.value === item.title }
            onChange={(e) => {
              handleChange({ name: "value", value : item.title}, idx)
            }}
            />
        </Col>

        <Col md="4">
          <Button disabled={!isShowDelete} color={"danger"} onClick={() => removeOption()}>
            <i className={"fa fa-trash"}></i>
          </Button> 
        </Col>

      </Row>
    );
  }
}

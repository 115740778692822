import React from 'react'
import ContentLoader from 'react-content-loader'

// Document page loader 
export const DocumentsLoader = () => (
  <ContentLoader height={80} >
    <rect x="0" y="4" rx="0" ry="0" width="50" height="5" />
    <rect x="54" y="4" rx="0" ry="0" width="50" height="5" />
    <rect x="0" y="13" rx="3" ry="3" width="400" height="2" />
    <rect x="0" y="22" rx="2" ry="2" width="400" height="15" />
    <rect x="0" y="42" rx="2" ry="2" width="400" height="15" />
  </ContentLoader>
);

// table loader or use as common loader
export const TableLoader = () =>   (
  <ContentLoader height={100} >
  <rect x="0" y="5" rx="4" ry="4" width="400" height="15" />
  <rect x="0" y="30" rx="3" ry="3" width="300" height="10" />
  <rect x="0" y="50" rx="3" ry="3" width="250" height="10" />
  <rect x="0" y="70" rx="3" ry="3" width="300" height="10" />
  <rect x="0" y="90" rx="3" ry="3" width="200" height="10" />
</ContentLoader>
);


export const TimelineLoader = () =>   (
  <React.Fragment>

<div  className="d-sm-none">
  <ContentLoader height={200} >
  <rect x="0" y="15" rx="30" ry="30" width="30" height="30" />
  <rect x="40" y="15" rx="1" ry="1" width="400" height="30" />
  <rect x="12" y="0" rx="1" ry="1" width="5" height="200" />

  <rect x="0" y="60" rx="30" ry="30" width="30" height="30" />
  <rect x="40" y="60" rx="1" ry="1" width="400" height="30" />

  <rect x="0" y="105" rx="30" ry="30"  width="30" height="30" />
  <rect x="40" y="105" rx="1" ry="1" width="400" height="30" />

  <rect x="0" y="150" rx="30" ry="30"  width="30" height="30" />
  <rect x="40" y="150" rx="1" ry="1" width="400" height="30" />

</ContentLoader>
</div>

<div  className="d-none d-sm-block">
<ContentLoader height={130}>
  <rect x="0" y="5" rx="10" ry="10" width="15" height="15" />
  <rect x="20" y="6" rx="1" ry="1" width="400" height="13" />
  <rect x="6" y="0" rx="1" ry="1" width="2" height="70" />

  <rect x="0" y="25" rx="10" ry="10" width="15" height="15" />
  <rect x="20" y="26" rx="1" ry="1" width="400" height="13" />

  <rect x="0" y="45" rx="10" ry="10" width="15" height="15" />
  <rect x="20" y="46" rx="1" ry="1" width="400" height="13" />


</ContentLoader>
</div>
 </React.Fragment>
)
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Input, FormFeedback } from 'reactstrap'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';
import { MarketingActions } from '../../actions/marketing.action';
import { connect } from 'react-redux';

function MobileNotificatios(props) {
    const [data, setData] = useState({ devices: 0 })
    const [formData, setFormData] = useState({
        notification: '',
        dateTime: ''
    })
    const [errors, setError] = useState({})

    const handleChange = (name, value) => {
        formData[name] = value
        errors[name] = ''
        setFormData({
            ...formData
        })
        setError({
            ...errors
        })
    }

    const handleSubmit = () => {
        const { dispatch } = props

        if (!valid()) {
            swal({
                title: "Are you sure ?",
                text: 'Do you want to Send Notification?',
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    dispatch(MarketingActions.SendNotifications(formData, reset));
                }
            })

        }
    }

    const valid = () => {
        const { notification, dateTime } = formData
        let error = false

        if (!notification) {
            errors[`notification`] = "Please Enter Notification Message."
            error = true
        }
        if (!dateTime) {
            errors[`dateTime`] = "Please Select Date and Time."
            error = true
        }

        setError({
            ...errors
        })
        return error

    }

    const reset = () => {
        const { dispatch } = props

        setFormData({
            notification: '',
            dateTime: ''
        })

        dispatch(MarketingActions.getSendedNotifications());
    }


    useEffect(() => {
        const { dispatch } = props
        dispatch(MarketingActions.getSendedNotifications());
    }, []);

    const { devices } = data
    const { notification, dateTime } = formData

    // const minTime = new Date(); // Set the minimum time to before now
    // minTime.setMinutes(minTime.getMinutes());


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h5>Total Devices : <span className='bg-success px-2 rounded' >{devices}</span> </h5>
                    <label><b>Notification</b> </label>
                    <Input type="textarea" placeholder="Notification..." name="notification" maxLength="250" onChange={(e) => handleChange(e.target.name, e.target.value)} value={notification} />
                    <FormFeedback>
                        {errors[`notification`] && errors[`notification`]}
                    </FormFeedback>
                    <div className='form-group mt-4'>
                        <labal> <b> Select schedule time </b> </labal>
                        <labal> <b> Select schedule time </b> </labal>

                        <DatePicker
                            container={'body'}
                            className="form-control width-100"
                            placeholderText="Select schedule time"
                            selected={dateTime}
                            onChange={(date) => handleChange('dateTime', date)}
                            minDate={new Date()}
                            // minTime={minTime}
                            // maxTime={moment().add(0, 'day').startOf('day').toDate() - 1000}
                            showTimeSelect
                            value={dateTime ? moment(dateTime).format("DD-MM-YYYY hh:mm A") : ''}



                        />
                        <FormFeedback>
                            {errors[`dateTime`] && errors[`dateTime`]}
                        </FormFeedback>
                    </div>
                    <Button className='' onClick={reset}>
                        Reset
                    </Button>
                    <Button className='bg-primary ml-3' onClick={handleSubmit}>
                        Submit
                    </Button>
                </CardBody>
            </Card>


            <Card>
                <CardBody>
                    <h5 className='' >Sended Notifications : <span className='bg-success px-2 rounded' >3</span> </h5>
                    <div className="table-responsive border">
                        <table className="table table-striped align-middle">
                            <thead className="table">
                                <tr>
                                    <th>No.</th>
                                    <th>Notification</th>
                                    <th>Devices</th>
                                    <th>Date & Time </th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                <tr  >
                                    <td>1</td>
                                    <td>lorem lorem l,asdca asd asd asd as dved thytu k iol kthg brfg hbrtyu y</td>
                                    <td>222</td>
                                    <td>22/05/2023 12:51</td>
                                </tr>
                                <tr >
                                    <td>2</td>
                                    <td>lorem lorem l,asdca asd asd asd as dved thytu k iol kthg brfg hbrtyu y</td>
                                    <td>222</td>
                                    <td>22/05/2023 12:51</td>
                                </tr>
                                <tr >
                                    <td>3</td>
                                    <td>lorem lorem l,asdca asd asd asd as dved thytu k iol kthg brfg hbrtyu y</td>
                                    <td>222</td>
                                    <td>22/05/2023 12:51</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    console.log(state.MobileNotificatios);
    // const { loading, data } = state.MobileNotificatios;
    // return {
    //     loading,
    //     data
    // }
}
const connectedMobileNotificatios = connect(mapStateToProps)(MobileNotificatios)
export { connectedMobileNotificatios as MobileNotificatios }

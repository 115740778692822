export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`title`]) {
        formIsValid = false
        errors[`title`] = 'Can Not be empty'
    }

    if (!fields[`description`]) {
        formIsValid = false
        errors[`description`] = 'Can Not be empty'
    }

    return { errors, formIsValid }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card, CardHeader, Button, } from 'reactstrap';
import { TableLoader } from "../../../components/Loaders";
import Interweave from 'interweave';


import { AddInformation } from './AddInformation'
import { informationActions } from '../../actions';
import moment from 'moment';
class ViewInformation extends Component {
	constructor(props) {
		super(props)
		this.getinformations = this.getinformations.bind(this)
	}

	getinformations() {
		const { dispatch } = this.props;
		dispatch(informationActions.getinformations())
	}
	componentDidMount() {
		this.getinformations()
	}
	render() {
		const { loading, data } = this.props
		return (
			<React.Fragment>
				<div className='bg-white p-5'>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>

							{data && data.length ? <React.Fragment>

								<h1 >{data[0].subject} </h1>
								<div className='mt-2 mb-3'>
									<i className='fa fa-clock'></i><span> &nbsp; &nbsp;{moment(data[0].updated_at).format("DD-MMMM-YYYY h:m A")}</span>
								</div>
								<div className='mb-3'>
									<Interweave content={data[0].information} />
								</div>
							</React.Fragment> : <React.Fragment>
								<h1 className='text-center'> Not available at moment </h1>
							</React.Fragment>}

						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getinformations
	return { loading, data }
}

const connectedInformation = connect(mapStateToProps)(ViewInformation)
export { connectedInformation as ViewInformation }
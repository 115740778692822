import { notify } from 'reapop'
import { promocodeConstants  } from '../constants'
import { promocodeService } from '../services'

export const promocodeActions = {
    getPromocodes ,
    savePromocode ,
    deletePromocode ,
    editPromoCode ,
    getBanner ,
    saveBanner ,
    deleteBanner
}

function getPromocodes() {
    return dispatch => {
        dispatch(request());

        promocodeService.getPromocode()
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.GET_PROMOCODES_REQUEST } }
    function success(result) { return { type: promocodeConstants.GET_PROMOCODES_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.GET_PROMOCODES_FAILURE, error } }
}
function getBanner() {
    return dispatch => {
        dispatch(request());

        promocodeService.getBanner()
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.GET_PROMOCODES_BANNER_REQUEST } }
    function success(result) { return { type: promocodeConstants.GET_PROMOCODES_BANNER_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.GET_PROMOCODES_BANNER_FAILURE, error } }
}


function saveBanner(data , callback ) {
    return dispatch => {
        dispatch(request());

        promocodeService.saveBanner(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.SAVE_PROMOCODES_BANNER_REQUEST } }
    function success(result) { return { type: promocodeConstants.SAVE_PROMOCODES_BANNER_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.SAVE_PROMOCODES_BANNER_FAILURE, error } }
}

function deleteBanner(data , callback ) {
    return dispatch => {
        dispatch(request());

        promocodeService.deleteBanner(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.DELETE_PROMOCODES_BANNER_REQUEST } }
    function success(result) { return { type: promocodeConstants.DELETE_PROMOCODES_BANNER_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.DELETE_PROMOCODES_BANNER_FAILURE, error } }
}

function savePromocode(data , callback ) {
    return dispatch => {
        dispatch(request());

        promocodeService.savePromocode(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.SAVE_PROMOCODES_REQUEST } }
    function success(result) { return { type: promocodeConstants.SAVE_PROMOCODES_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.SAVE_PROMOCODES_FAILURE, error } }
}


function editPromoCode(data , callback ) {
    return dispatch => {
        dispatch(request());

        promocodeService.editPromoCode(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.EDIT_PROMOCODES_REQUEST } }
    function success(result) { return { type: promocodeConstants.EDIT_PROMOCODES_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.EDIT_PROMOCODES_FAILURE, error } }}


function deletePromocode(data , callback) {
    return dispatch => {
        dispatch(request());

        promocodeService.deletePromocode(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: promocodeConstants.DELETE_PROMOCODE_REQUEST } }
    function success(result) { return { type: promocodeConstants.DELETE_PROMOCODE_SUCCESS, result } }
    function failure(error) { return { type: promocodeConstants.DELETE_PROMOCODE_FAILURE, error } }
}



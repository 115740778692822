import { notify } from 'reapop'
import { businessAccountTemplateConstants } from '../constants'
import { businessAccountTemplateService } from '../services'

export const businessAccountTemplateActions = {
    saveBusinessAccountTemplate,
    getBusinessAccountTemplate
}



function saveBusinessAccountTemplate(data) {
    return dispatch => {
        dispatch(request())

        businessAccountTemplateService.saveBusinessAccountTemplate(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountTemplateConstants.SAVE_BUSINESS_ACC_TEMP_DATA_REQUEST } }
    function success(result) { return { type: businessAccountTemplateConstants.SAVE_BUSINESS_ACC_TEMP_DATA_SUCCESS, result } }
    function failure(error) { return { type:             businessAccountTemplateConstants.SAVE_BUSINESS_ACC_TEMP_DATA_FAILURE, error } }
}

function getBusinessAccountTemplate(data) {
    return dispatch => {
        dispatch(request())

        businessAccountTemplateService.getBusinessAccountTemplate(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_REQUEST } }
    function success(result) { return { type: businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_SUCCESS, result } }
    function failure(error) { return { type: businessAccountTemplateConstants.GET_BUSINESS_ACC_TEMP_DATA_FAILURE, error } }
}

export const reputeinfoConstants = {

    GET_RECENT_REGISTRATIONS_REQUEST: 'GET_RECENT_REGISTRATIONS_REQUEST',
    GET_RECENT_REGISTRATIONS_SUCCESS: 'GET_RECENT_REGISTRATIONS_SUCCESS',
    GET_RECENT_REGISTRATIONS_FAILURE: 'GET_RECENT_REGISTRATIONS_FAILURE',

    GET_RECENT_ATTEMPT_REQUEST: 'GET_RECENT_ATTEMPT_REQUEST',
    GET_RECENT_ATTEMPT_SUCCESS: 'GET_RECENT_ATTEMPT_SUCCESS',
    GET_RECENT_ATTEMPT_FAILURE: 'GET_RECENT_ATTEMPT_FAILURE',

    UPDATE_SETTINGS_REQUEST: 'UPDATE_SETTINGS_REQUEST',
    UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
    UPDATE_SETTINGS_FAILURE: 'UPDATE_SETTINGS_FAILURE',

    GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
    GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

    GET_PROFILE_WIGHTAGE_SETTINGS_REQUEST: 'GET_PROFILE_WIGHTAGE_SETTINGS_REQUEST',
    GET_PROFILE_WIGHTAGE_SETTINGS_SUCCESS: 'GET_PROFILE_WIGHTAGE_SETTINGS_SUCCESS',
    GET_PROFILE_WIGHTAGE_SETTINGS_FAILURE: 'GET_PROFILE_WIGHTAGE_SETTINGS_FAILURE',

    RECENT_REGISTRATIONS_CALLED_REQUEST: 'RECENT_REGISTRATIONS_CALLED_REQUEST',
    RECENT_REGISTRATIONS_CALLED_SUCCESS: 'RECENT_REGISTRATIONS_CALLED_SUCCESS',
    RECENT_REGISTRATIONS_CALLED_FAILURE: 'RECENT_REGISTRATIONS_CALLED_FAILURE',
    
}
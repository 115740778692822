import { authConstants } from '../constants';


/*
*  Individaul Login 
*/

// login automantically if exist in local storage
try {
  var user = localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : false;
}
catch(e) {
  localStorage.removeItem('user')
  localStorage.removeItem('company')
}

// set user value
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {};
    case authConstants.LOGOUT:
      return {};
    default:
      return state
  }
}


export function registration(state = {}, action) {
    switch (action.type) {
      case authConstants.REGISTER_REQUEST:
        return { registering: true };
      case authConstants.REGISTER_SUCCESS:
        return {};
      case authConstants.REGISTER_FAILURE:
        return {};
      default:
        return state
    }
  }


export function savePassword(state = {}, action) {
    switch (action.type) {
      case authConstants.SAVE_PASSWORD_REQUEST:
        return { saving: true };
      case authConstants.SAVE_PASSWORD_SUCCESS:
        return { data: action.result }
      case authConstants.SAVE_PASSWORD_FAILURE:
        return {};
      default:
        return state
    }
  }

  export function forgotPassword(state = {}, action) {
    switch (action.type) {
      case authConstants.FORGET_PASSWORD_REQUEST:
        return { saving: true };
      case authConstants.FORGET_PASSWORD_SUCCESS:
        return { data: action.user }
      case authConstants.FORGET_PASSWORD_FAILURE:
        return {};
      default:
        return state
    }
  }
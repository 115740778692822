import { notify } from 'reapop';
import { authConstants } from '../constants';
import { authService } from '../services';
import { getRedirectToAuthenticatedPage } from '../../helpers';

export const authActions = {
    login,
    logout,
    register,
    changePassword,
    forgetpassword,
    saveNewPassword,
};

function login(username, password, props) {
    return dispatch => {
        dispatch(request({ username }));
        authService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(notify({ position : "tc", message: "Login successfull", status: 200 }));
                    setTimeout(function(){
                        props.history.push(getRedirectToAuthenticatedPage())
                    }, 1000)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position : "tc", main:".main", message: error.toString(),  status: "error" }));
                }
            );
    };

    function request(user) { return { type: authConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout() {
    authService.logout();
    return { type: authConstants.LOGOUT };
}

function register(user, props) {
    return dispatch => {
        dispatch(request(user));

        authService.register(user)
            .then(
                user => {
                    dispatch(success());
                    props.history.push('/login');
                    dispatch(notify({  title: "Individual Registration",  position : "tc", message: "Registration successfull", status: 200 }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({   position : "tc", dismissible : false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request(user) { return { type: authConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: authConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: authConstants.REGISTER_FAILURE, error } }
}

function forgetpassword(data,ref) {

    return dispatch => {
        dispatch(request({ data }));

        authService.forgetpassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    // props.history.push('/login');  
                    ref.handelOtpSuccess();    
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({   position : "tc", dismissible : false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request(user) { return { type: authConstants.FORGET_PASSWORD_REQUEST, user } }
    function success(user) { return { type: authConstants.FORGET_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authConstants.FORGET_PASSWORD_FAILURE, error } }
}

function changePassword(data, ref) {

    return dispatch => {
        dispatch(request({ data }));

        authService.changePassword(data)
            .then(
                user => {
                    dispatch(success(user));  
                    dispatch(notify({  title: "Change password",  position : "tc", message: "Change password successfully", status: 200 }));
                    ref.afterSave();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({   position : "tc", dismissible : false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request(user) { return { type: authConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: authConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function saveNewPassword(ref, data) {

    return dispatch => {
        dispatch(request());

        authService.saveNewPassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(notify({   position : "tc", message: "New password updated successfully", status: 200 }));
                    ref.handleCancel();    
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({   position : "tc", dismissible : false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request() { return { type: authConstants.SAVE_PASSWORD_REQUEST } }
    function success(user) { return { type: authConstants.SAVE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authConstants.SAVE_PASSWORD_FAILURE, error } }
}
import React from 'react';
import { Redirect, Route } from 'react-router-dom';


import {
  isLoggedIn
} from '../helpers';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isLoggedIn() ? (
      props.accessTo === "admin" ? 
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}
      /> : <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}
        />
      )
  )} />
);

export default PrivateRoute; 
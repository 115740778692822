import { notify } from 'reapop'
import { businessAccountsConstants } from '../constants'
import { businessAccountsService } from '../services'

export const businessAccountsActions = {
    addQuickBusinessAccount,
    assignUser,
    saveProcessStage,
    saveBusinessAccounts,
    saveReputeId,
    getDataFromReputeId,
    getBusinessAccounts,
    getSectors,
    getStates,
    getBlockBussinessData , 
    blockBussinessData , 
    uploadCsvForMSG91FailedReason , 
    
}

function addQuickBusinessAccount(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.addQuickBusinessAccount(data)
            .then(
                data => {
                    callback.success();
                    dispatch(success(data))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Business account added successfully.", status: "success" }))
                },
                error => {
                    callback.failure();
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.ASSIGN_BUSINESS_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_FAILURE, error } }
}

function getSectors(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.getSectors(data)
            .then(
                data => {
                    dispatch(success(data))
                    // dispatch(notify({ position: "tc", dismissible: false, message: "Business account added successfully.", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.GET_SECTORS_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.GET_SECTORS_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.GET_SECTORS_FAILURE, error } }
}
function getStates(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.getStates(data)
            .then(
                data => {
                    dispatch(success(data))
                    // dispatch(notify({ position: "tc", dismissible: false, message: "Business account added successfully.", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.GET_STATES_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.GET_STATES_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.GET_STATES_FAILURE, error } }
}
function assignUser(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.assignUser(data)
            .then(
                data => {
                    callback.success();
                    dispatch(success(data))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Business account assigned successfully.", status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.ASSIGN_BUSINESS_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_FAILURE, error } }
}

function saveReputeId(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.saveReputeId(data)
            .then(
                data => {
                    callback.success();
                    dispatch(success(data))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Repute ID assigned successfully.", status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.ASSIGN_BUSINESS_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.ASSIGN_BUSINESS_FAILURE, error } }
}

function getDataFromReputeId(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.getDataFromReputeId(data)
            .then(
                data => {
                    callback.success(data);
                    dispatch(success(data))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.GET_DATA_FROM_RI_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.GET_DATA_FROM_RI_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE, error } }
}





function saveProcessStage(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.saveProcessStage(data)
            .then(
                data => {
                    callback.success();
                    dispatch(success(data))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Process stage is updated successfully", status: "success" }))

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.SAVE_PROCESS_STAGE_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.SAVE_PROCESS_STAGE_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.SAVE_PROCESS_STAGE_FAILURE, error } }
}

function getBusinessAccounts(data, state) {
    return dispatch => {
        dispatch(request())
        businessAccountsService.getBusinessAccounts(data)
            .then(
                (data) => { 
                    dispatch(success(data)) 
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.GET_BUSINESS_ACC_DATA_REQUEST, params: data } }
    function success(result) { return { type: businessAccountsConstants.GET_BUSINESS_ACC_DATA_SUCCESS, result, params: data } }
    function failure(error) { return { type: businessAccountsConstants.GET_BUSINESS_ACC_DATA_FAILURE, error } }
}
function getBlockBussinessData(data, state) {
    return dispatch => {
        dispatch(request())
        businessAccountsService.getBlockBussinessData(data)
            .then(
                (data) => { 
                    dispatch(success(data)) 
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_REQUEST, params: data } }
    function success(result) { return { type: businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_SUCCESS, result, params: data } }
    function failure(error) { return { type: businessAccountsConstants.GET_BLOCK_BUSSINESS_DATA_FAILURE, error } }
}
function blockBussinessData(data, callback) {
    return dispatch => {
        dispatch(request())
        businessAccountsService.blockBussinessData(data)
            .then(
                response => { 
                    dispatch(success(response)) 
                    callback.success();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Block updated successfully.', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_REQUEST, params: data } }
    function success(result) { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_SUCCESS, result, params: data } }
    function failure(error) { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_FAILURE, error } }
}

function uploadCsvForMSG91FailedReason(data, callback) {
    return dispatch => {
        dispatch(request())
        businessAccountsService.uploadCsvForMSG91FailedReason(data)
            .then(
                response => { 
                    dispatch(success(response)) 
                    callback.success();
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Block updated successfully.', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_REQUEST, params: data } }
    function success(result) { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_SUCCESS, result, params: data } }
    function failure(error) { return { type: businessAccountsConstants.BLOCK_BUSSINESS_DATA_FAILURE, error } }
}

function saveBusinessAccounts(data, callback) {
    return dispatch => {
        dispatch(request())

        businessAccountsService.saveBusinessAccounts(data)
            .then(
                data => {
                    dispatch(success(data))
                    callback.success(data)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: businessAccountsConstants.SAVE_BUSINESS_ACC_DATA_REQUEST } }
    function success(result) { return { type: businessAccountsConstants.SAVE_BUSINESS_ACC_DATA_SUCCESS, result } }
    function failure(error) { return { type: businessAccountsConstants.SAVE_BUSINESS_ACC_DATA_FAILURE, error } }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy';
import { currencyFormat } from '../../../helpers';
import { expenceStatementActions } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
class PayoutDetails extends Component {
    constructor(props) {
        super(props)
        this.getTotalAmount = this.getTotalAmount.bind(this)
        this.getPayoutDetails = this.getPayoutDetails.bind(this)
    }
    getPayoutDetails() {
        const { dispatch } = this.props
        dispatch(expenceStatementActions.getAllPayoutDetails());
    }
    componentDidMount() {
        this.getPayoutDetails()
    }
    getTotalAmount() {
        const { loading, data } = this.props

        let total_commission_amount = 0;


        data && data.map((eachData) => {
            total_commission_amount = total_commission_amount + parseFloat(eachData.commission_amount, 10);
        })

        return currencyFormat(total_commission_amount)
    }
    render() {
        const { loading, data } = this.props
        return (
            <React.Fragment>
                {
                    loading ? <TableLoader /> :

                        <div className="col-12">

                            <table className="table table-sm table-condensed table-outline">
                                <thead>
                                    <tr>
                                        {/* <th>User Name</th> */}
                                        <th>User Name</th>
                                        <th>Commisssion Amount</th>
                                        <th>Payment Receipt</th>
                                        <th>Deposited</th>
                                        <th>Deposit Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data && data.length ? (
                                        data.map((eachData) => {
                                            return (
                                                <tr>
                                                    {/* <td>{eachData.user_name}</td> */}
                                                    <td>{eachData.user_name}</td>
                                                    <td>₹ {eachData.commission_amount}</td>
                                                    <td>{eachData.receipt_number}</td>
                                                    <td>{eachData.is_deposited ?
                                                        <React.Fragment>
                                                            Yes
                                                            <Tooltip
                                                                className="btn m-0 p-0 ml-1 mr-1"
                                                                interactive
                                                                arrow={true} html={
                                                                    <React.Fragment>
                                                                        <table style={{ width: "300px", 'textAlign': "left", 'fontSize': '13px' }}>
                                                                            <tr>
                                                                                <th width="50%">Payment Receipt</th>
                                                                                <td>{eachData.receipt_number}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th width="50%">Payment Type</th>
                                                                                <td>{eachData.payment_type}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th width="50%">Reference Number</th>
                                                                                <td>{eachData.reference_number}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th width="50%">Remarks</th>
                                                                                <td>{eachData.remarks ? eachData.remarks : "-"}</td>
                                                                            </tr>

                                                                        </table>
                                                                    </React.Fragment>}>
                                                                <i className='fa fa-info-circle text-primary'></i>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                        : 'No'}</td>
                                                    <td>{eachData.deposited_date ? eachData.deposited_date : '-'}</td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        {/* <th></th> */}
                                        <th>Total</th>
                                        <th>₹  {this.getTotalAmount()}</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getAllPayoutDetails
    return {
        loading,
        data
    }
}
const PayoutDetailsconnected = connect(mapStateToProps)(PayoutDetails)
export { PayoutDetailsconnected as PayoutDetails }
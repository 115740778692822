import React, { Component } from "react"
import { Route, Switch } from "react-router"
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import { Container } from 'reactstrap'
import { Header } from "../../components"
import Footer from "../../components/Footer.jsx"
import PrivateRoute from '../../components/PrivateRoute'
import {
    AppFooter,
    AppHeader,
} from '@coreui/react'
import indexRoutes from "../../routes/index.jsx"
import { navigations } from "../../routes/navigations"
import {
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react'

import {InformationAlert} from './InformationAlert';

class GuestLayout extends Component {

    render() {

        let navigation = navigations.guest

        return (
            <div className="app">
                <AppHeader fixed>
                    <Header />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarForm />
                        <AppSidebarNav navConfig={navigation} {...this.props} />
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <Container fluid>
                            <NotificationsSystem theme={theme} />
                            <InformationAlert/>
                            <Switch>
                                {indexRoutes.map((prop, key) => {
                                    if (prop.private) {
                                        return <PrivateRoute path={prop.path} key={key} exact component={prop.component} />
                                    }
                                    return <Route path={prop.path} key={key} exact component={prop.component} />
                                })}
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <Footer />
                </AppFooter>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state
    return {
        alert
    }
}

const connectedGuestLayout = withRouter(connect(mapStateToProps)(GuestLayout))
export { connectedGuestLayout as GuestLayout }
// import React from 'react';

// import { Card, CardBody, CardHeader } from 'reactstrap';
// export function DocumentPreview(data) {

//     const iframStyle = {
//         border: 0,
//         width: "100%",
//         height: "300px"
//     }

//     return <div className={`col-md-${data.isFullWidth ? "12" : '4'}`}>
//         <Card>
//             <CardHeader> <b> {data.title} </b> </CardHeader>
//             <CardBody>
//                 <iframe src={data.url}
//                     style={iframStyle}
//                     title={data.title} />
//             </CardBody>
//             {/* <CardFooter>
//             <Button> Action </Button>    
//             <Button> Action 2 </Button>    
//         </CardFooter> */}
//         </Card></div>
// }


import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


class DocumentPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DocumentPreviewModel: false
    }

    this.DocumentPreviewToggle = this.DocumentPreviewToggle.bind(this);
  }


  DocumentPreviewToggle(event) {
    event.preventDefault();
    const { DocumentPreviewModel } = this.state;
    this.setState({
      ...this.state,
      DocumentPreviewModel: !DocumentPreviewModel
    });
  }


  render() {

    const { isDisabled, DocumentURL, size, title, btntitle,  } = this.props;

    const iframStyle = {
      border: 0,
      width: "100%",
      height: "calc(100vh - 225px)"
    }

    return (
      <React.Fragment>

        <Button size={size || "md"} color={"primary"} disabled={isDisabled} onClick={this.DocumentPreviewToggle}> 
        {btntitle ? btntitle :"View"}
         </Button>

        <Modal isOpen={this.state.DocumentPreviewModel} size={"lg"} toggle={this.DocumentPreviewToggle} className={"modal-primary"}>
          <ModalHeader > {title ? title :"Document"}</ModalHeader>
          <ModalBody>
            <iframe src={DocumentURL}
              style={iframStyle}
              title={"Document Preview"} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.DocumentPreviewToggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DocumentPreview

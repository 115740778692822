import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const informationService = {
    getinformations,
    addEditinformations,
}

function getinformations(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_information`, form_data, setting).then(handleResponse)
}

function addEditinformations(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/add_edit_information`, form_data, setting).then(handleResponse)
}
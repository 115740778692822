export const CompanySector = {

    GET_COMNAPY_SECTOR_DETAILS_REQUEST: 'GET_COMNAPY_SECTOR_DETAILS_REQUEST',
    GET_COMPANY_SECTOR_DETAILS_SUCCESS: 'GET_COMPANY_SECTOR_DETAILS_SUCCESS',
    GET_COMPANY_SECTOR_DETAILS_FAILURE: 'GET_COMPANY_SECTOR_DETAILS_FAILURE',

    ADD_COMNAPY_SECTOR_DETAILS_REQUEST: 'ADD_COMNAPY_SECTOR_DETAILS_REQUEST',
    ADD_COMPANY_SECTOR_DETAILS_SUCCESS: 'ADD_COMPANY_SECTOR_DETAILS_SUCCESS',
    ADD_COMPANY_SECTOR_DETAILS_FAILURE: 'ADD_COMPANY_SECTOR_DETAILS_FAILURE',

    DELETE_COMNAPY_SECTOR_DETAILS_REQUEST: 'DELETE_COMNAPY_SECTOR_DETAILS_REQUEST',
    DELETE_COMPANY_SECTOR_DETAILS_SUCCESS: 'DELETE_COMPANY_SECTOR_DETAILS_SUCCESS',
    DELETE_COMPANY_SECTOR_DETAILS_FAILURE: 'DELETE_COMPANY_SECTOR_DETAILS_FAILURE',

    UPDATE_COMNAPY_SECTOR_DETAILS_REQUEST: 'UPDATE_COMNAPY_SECTOR_DETAILS_REQUEST',
    UPDATE_COMPANY_SECTOR_DETAILS_SUCCESS: 'UPDATE_COMPANY_SECTOR_DETAILS_SUCCESS',
    UPDATE_COMPANY_SECTOR_DETAILS_FAILURE: 'UPDATE_COMPANY_SECTOR_DETAILS_FAILURE',
}
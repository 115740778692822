import React from 'react';
import {  Input } from 'reactstrap';
import MaskedInput from 'react-text-mask';

import InlineError from "../Messages/InlineError";

/*
*   Function for Common Address Form 
*/

const AddressForm = ({ errors, data, translate, handleChange, getPincodeDetails }) => (
    <div>
        <div className={' form-group mb-3'}>
            <Input className="form-control" name="line1" value={data.line1} onChange={handleChange} type="text" placeholder={translate("title.Line 1")} />
            {errors.line1 && <InlineError message={errors.line1} />}
        </div>

        <div className={' form-group mb-3'}>
            <Input className="form-control" name="line2" type="text" value={data.line2} onChange={handleChange} placeholder={translate("title.Line 2")} />
            {errors.line2 && <InlineError message={errors.line2} />}
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className={' form-group mb-3'}>
                    <Input className="form-control" type="text" name="city" value={data.city} onChange={handleChange} placeholder={translate("title.City")} />
                    {errors.city && <InlineError message={errors.city} />}
                </div>
            </div>
            <div className="col-md-6">
                <div className={' form-group mb-3'}>
                    <Input className="form-control" type="text" name="taluka" value={data.taluka} onChange={handleChange} placeholder={translate("title.Tehsil/Taluka")} />
                    {errors.taluka && <InlineError message={errors.taluka} />}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4">
                <div className={' form-group mb-3'}>
                    <MaskedInput
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        className="form-control"
                        type="text" name="pinCode" value={data.pinCode} onChange={handleChange} onBlur={getPincodeDetails} placeholder={'PIN (Required)'} />
                        {errors.pinCode && <InlineError message={errors.pinCode} />}

                </div>
            </div>

            <div className="col-md-4">
                <div className={' form-group mb-3'}>
                    <Input className="form-control" type="text" name="district" value={data.district} disabled={true} onChange={handleChange} placeholder={translate("title.District")} />
                </div>
            </div>
            <div className="col-md-4">
                <div className={' form-group mb-3'}>
                    <Input className="form-control" type="text" name="state"
                        onChange={handleChange}
                        disabled={true}
                        value={data.state}
                        placeholder={translate("title.State")} />
                </div>
            </div>
        </div>
    </div>
);

export default AddressForm;
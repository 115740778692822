import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


class WebSocketManager extends Component {

    componentWillMount() {

        try {
        }
        catch (e) {
            return false;
        }

    }

    render() {
        return <React.Fragment> </React.Fragment>;
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedWebSocketManager = withRouter(connect(
    mapStateToProps,
)(WebSocketManager));

export { connectedWebSocketManager as WebSocketManager };
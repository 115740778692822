


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { GenerateTicketForm } from './GenerateTicketForm';

import { show } from 'redux-modal';

class GenerateTickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        const { dispatch } = this.props;
        dispatch(show("bootstrap", {
            ModelSize: "md",
            ModelHeaderText: "Generate New Ticket",
            ModelBodyText: <GenerateTicketForm {...this.props}/>,
            isFooter:false
          }));
    }
   

    render() {
        return (
            <React.Fragment>
                 <Button color="primary" size="sm" className="pull-right" onClick={this.openModal} > Generate New Ticket </Button>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    // const { saving } = state.createTicket;

    // return {
    //     saving
    // };

}

const connectedGenerateTickets = connect(mapStateToProps)(GenerateTickets);
export { connectedGenerateTickets as GenerateTickets }

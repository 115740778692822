import {  axiosAjaxCall } from './common.service'

export const accountService = {
    getAccountData,
    proceedExtend,
    proceedChequePayment,
    getUserSalaryData,
    getChequeDetail,
    updateChequeDetail,
    getCommissionData,
    saveCompanyProductTags,
    getCompanyProductTags,
    deleteCompanyProductTags,
    getProductTagsAlphabatically,
    getDepositReceipt,
    updateDepositReceipt,
    closeCredit,
    getAccountAnalysisData ,
    
}

function getAccountAnalysisData(data) {
    return axiosAjaxCall("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
}
function getAccountData(data) {
    return axiosAjaxCall("get_account_detail", data);
}
function proceedExtend(data) {
    return axiosAjaxCall("extend_plan", data);
}
function proceedChequePayment(data) {
    return axiosAjaxCall("extend_plan_by_cheque", data);
}
function getUserSalaryData(data) {
    return axiosAjaxCall("get_user_salary", data);
}
function getChequeDetail(data) {
    return axiosAjaxCall("get_cheque_detail", data);
}
function updateChequeDetail(data) {
    return axiosAjaxCall("update_cheque_detail", data);
}

function getCommissionData(data) {
    return axiosAjaxCall("get_commission_data", data);
}

function saveCompanyProductTags(data) {
    return axiosAjaxCall("addSellerProductTagSA", data);
}
function getCompanyProductTags(data) {
    return axiosAjaxCall("getSellerProductsSA", data);
}

function deleteCompanyProductTags(data) {
    return axiosAjaxCall("deleteSellerProductTag2SA", data);
}

function getProductTagsAlphabatically(data) {
    return axiosAjaxCall("getProductTagsAlphabaticallySA", data);
}

function getDepositReceipt(data) {
    return axiosAjaxCall("getDepositReceipt", data);
}

function updateDepositReceipt(data) {
    return axiosAjaxCall("updateDepositReceipt", data);
}

function closeCredit(data) {
    return axiosAjaxCall("closeCredit", data);
}

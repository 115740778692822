import {  axiosAjaxCall } from './common.service'
import { showLoader } from '../../helpers';

export const reputeinfoService = {
    getRecentRegistrations,
    getRecentRegistrAttempt,
    updateSettings,
    getSettings,
    postRecentRegistrCallStatus,

}
function postRecentRegistrCallStatus(data) {
    showLoader();
    return axiosAjaxCall("updateLeadCallStatus ", data);
}



function getRecentRegistrAttempt(data) {
    showLoader();
    return axiosAjaxCall("getRecentRegistrAttempt", data);
}
function getRecentRegistrations(data) {
    showLoader();
    return axiosAjaxCall("getRecentRegistrations", data);
}

function updateSettings(data) {
    showLoader();
    return axiosAjaxCall("updateSettings", data);
}

function getSettings(data) {
    showLoader();
    return axiosAjaxCall("getSettings", data);
}
import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import { authHeader } from '../../helpers';

export const SMSService = {
    addSMS,
    getSMS,
    editSMS,
    deleteSMS,
    publishSMS
}

function addSMS(data) {
    var form_data = new FormData()

    form_data.append("current_password", data.oldPassword)
    form_data.append("new_password", data.newPassword)
    form_data.append("new_password_confirmation", data.confirmPassword)

    return axios.post(`${config.get('apiUrl')}/addSMS`, form_data).then(handleResponse)
}

function getSMS(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/get_sms_detail`, form_data, setting).then(handleResponse)
}

function editSMS(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/update_sms_detail`, form_data, setting).then(handleResponse)
}

function deleteSMS() {
    return axios.post(`${config.get('apiUrl')}/deleteSMS`).then(handleResponse)
}

function publishSMS(data) {
    var form_data = data
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/publish_sms_detail`, form_data, setting).then(handleResponse)
}
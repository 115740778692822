import React, { Component } from "react";
import { accountAction } from "./../../actions/account.action";
import swal from "sweetalert";

import { Tooltip } from "react-tippy";

import moment from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";

export default class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    let days = new Date().getDate() - 1;
    this.state = {
      modalOpan: false,
      data: {
        sms: "",
        days: "",
      },
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.proceed = this.proceed.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan });
  }

  handleChange(event) {
    const { name, value } = event.target;
    let { data } = this.state;
    let val = value;

    let regex_allowed = /^[0-9]+$/;
    if (value.length && regex_allowed.test(value) === false) {
      return false;
    }
    data[name] = value;
    this.setState({ data });
  }

  proceed() {
    const { data } = this.state;
    const { dispatch, element, getData } = this.props;

    swal({
      title: "Are you sure ?",
      text: "Do you want to Upgrade Basic to Premium ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Cancel", "Yes, I am sure!"],
    }).then((confirm) => {
      if (confirm) {
        dispatch(
          accountAction.proceedExtend(
            {
              repute_id: element.repute_id,
              invoice_no: element.invoice_no,
              sms: data.sms,
              days: data.days,
              type: "upgrade",
            },
            {
              success: () => {
                this.showModal();
                getData();
              },
            }
          )
        );
      }
    });
  }

  render() {
    const { modalOpan, data } = this.state;
    const { element } = this.props;
    let _this = this;
    return (
      <div>
        <Tooltip
          className="btn m-0 p-0"
          interactive
          arrow={true}
          title="Upgrade Basic to Premium"
        >
          <Button color="primary" onClick={_this.showModal}>
            <i class="fas fa-arrow-circle-up"></i>
          </Button>
        </Tooltip>
        <Modal className="modal-primary" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>
            Upgrade Basic to Premium <br />{" "}
            <small>Trial for new customer</small>
          </ModalHeader>
          <ModalBody>
              <Row>
                <Col md="5" className="text-right mb-1">
                  Name of company :
                </Col>
                <Col md="7" className="mb-1">{element.company_name}</Col>

                <Col md="5" className="text-right mb-1">
                  Repute ID :
                </Col>
                <Col md="7" className="mb-1">{element.formated_repute_id}</Col>

                <Col md="5" className="text-right  mb-1">
                  Subscription Plan :
                </Col>
                <Col md="7" className="mb-1">{element.current_plan}</Col>

                <Col md="5" className="text-right mb-4">
                  Current Expiry Date :
                </Col>
                <Col md="7">{element.formated_expiry_date}</Col>

                <Col md="5" className="text-right mb-1">
                  Today's Date :
                </Col>
                <Col md="7">{moment().format('DD MMM YYYY hh:mm A')}</Col>


                <Col md="5" className="text-right mb-1 pt-2">
                 Add Days :
                </Col>
                <Col md="7">
                  <Input
                    maxlength={3}
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Days"
                    name={"days"}
                    value={data.days}
                  />
                </Col>


                <Col md="5" className="text-right  mb-4">
                  New Expiry Date :
                </Col>
                <Col md="7" className="mb-1">{moment().add(data.days, 'days').format('DD MMM YYYY hh:mm A')}</Col>



                <Col md="5" className="text-right mb-1">
                  SMS Qty Remaining :
                </Col>
                <Col md="7" className="mb-1">{element.total_sms}</Col>

                <Col md="5" className="text-right mb-1 pt-2 ">
                  
                  Add SMS :
                </Col>
                <Col md="7" className="mb-1"><Input
                maxlength={4}
                type="text"
                onChange={this.handleChange}
                name={"sms"}
                value={data.sms}
                placeholder="SMS"
              /></Col>

                <Col md="5" className="text-right">
                  Total SMS  :
                </Col>
                <Col md="7" >{data.sms? parseInt(element.total_sms)   + parseInt(data.sms) : element.total_sms  }</Col>

              </Row>
              

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
              Proceed
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

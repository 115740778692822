import { notify } from 'reapop'
import { profileConstants } from '../constants'
import { profileService } from '../services'

export const profileActions = {
    getProfile,
    updateBankDetail,
    updatePAN,
    getDepositPayments,
    getCommissionPayments,
    upgradeUserAccount,
    getHeaderDataCount,
}

function getProfile(data) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.getProfile(data)
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.GET_PROFILE_REQUEST } }
    function success(result) { return { type: profileConstants.GET_PROFILE_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.GET_PROFILE_FAILURE, error } }
}

function updateBankDetail(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.updateBankDetail(data)
            .then(
                response => {
                    dispatch(success(response))
                    dispatch(notify({ position: "tc", dismissible: false, message: "Saved Successfully.", status: "success" }))

                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.UPDATE_BANK_DETAIL_REQUEST } }
    function success(result) { return { type: profileConstants.UPDATE_BANK_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.UPDATE_BANK_DETAIL_FAILURE, error } }
}


function updatePAN(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.updatePAN(data)
            .then(
                response => {
                    dispatch(success(response))
                    dispatch(notify({ position: "tc", dismissible: false, message: "PAN updated successfully.", status: "success" }))

                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.UPDATE_PAN_REQUEST } }
    function success(result) { return { type: profileConstants.UPDATE_PAN_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.UPDATE_PAN_FAILURE, error } }
}



function upgradeUserAccount(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.upgradeUserAccount(data)
            .then(
                response => {
                    dispatch(success(response))
                    dispatch(notify({ position: "tc", dismissible: false, message: "User account upgraded successfully.", status: "success" }))

                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.UPGRADE_USER_ACCOUNT_REQUEST } }
    function success(result) { return { type: profileConstants.UPGRADE_USER_ACCOUNT_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.UPGRADE_USER_ACCOUNT_FAILURE, error } }
}

function getDepositPayments(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.getDepositPayments(data)
            .then(
                response => {
                    dispatch(success(response))
                  
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.GET_PAYMENTS_DEPOSIT_REQUEST } }
    function success(result) { return { type: profileConstants.GET_PAYMENTS_DEPOSIT_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.GET_PAYMENTS_DEPOSIT_FAILURE, error } }
}


function getCommissionPayments(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.getCommissionPayments(data)
            .then(
                response => {
                    dispatch(success(response))

                },
                error => {
                    // dispatch(failure(error.toString()));
                    // dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.GET_COMMISIION_ACCOUNT_REQUEST } }
    function success(result) { return { type: profileConstants.GET_COMMISIION_ACCOUNT_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.GET_COMMISIION_ACCOUNT_FAILURE, error } }
}

function getHeaderDataCount(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        profileService.getHeaderDataCount(data)
            .then(
                response => {
                    dispatch(success(response))

                },
                error => {
                    // dispatch(failure(error.toString()));
                    // dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: profileConstants.GET_HEADER_DATA_REQUEST } }
    function success(result) { return { type: profileConstants.GET_HEADER_DATA_SUCCESS, result } }
    function failure(error) { return { type: profileConstants.GET_HEADER_DATA_FAILURE, error } }
}


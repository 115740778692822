import { findFirst } from "obj-traverse/lib/obj-traverse";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
    Button,
    FormGroup
} from "reactstrap";
import { communicationMastersActions } from "../../../../actions/communicationMasters.action";
import { InputDropDown } from "../../../Settings/StageSetting/Components/FormElements";
import InlineError from "../../../../../components/Messages/InlineError";
import moment from "moment";

const CommType = {
  Instant: "instant",
  Schedule: "schedule",
  NotSelected: "NotSelected",
};

const defaultChannels = {
  sms: false,
  email: false,
  whatsapp: false,
  call: true,
};


const defaultData = {
  individual_ids: [],
  business_account_id: "",
  communication_channels: defaultChannels,
  communication_template_master_id: false,
  when_to_execute: "",
}
class CommunicationCallForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      communication_type: CommType.Instant,
      errors:{},
      data: {...defaultData},
    };
    this.setType = this.setType.bind(this);
    this.collapse = this.collapse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectIndividual = this.selectIndividual.bind(this);
    this.selectChannels = this.selectChannels.bind(this);
    this.validation = this.validation.bind(this);
    this.reset = this.reset.bind(this)
  }
  setType(type) {
    this.setState({ communication_type: type });
  }
  componentDidMount() {
    let items = localStorage.getItem("communication_masters");

    if (items) {
      items = JSON.parse(items);
      this.setState({ items });
    }
  }

  save() {
    const { dispatch, business_details_id } = this.props;
    const { communication_type, data } = this.state;

    if (this.validation()) {
      return false;
    }

    let dataToSend = {
      business_account_id: business_details_id,
      individual_ids: data.individual_ids,
      communication_channels: JSON.stringify(data.communication_channels),
      communication_template_master_id: data.communication_template_master_id,
      is_scheduled: CommType.Schedule === communication_type ? 1 : 0,
      when_to_execute:  moment(data.when_to_execute).format("YYYY-MM-DD HH:mm:ss"),
    };

  
    dispatch(communicationMastersActions.saveCommunicationMasters(dataToSend, {
      success: () => {
        this.reset()
      }
    }));

  }
  
  reset(){
    this.setState({
      data : {...defaultData}
    })
  }


  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState(data);
  }

  validation() {
    const { data, communication_type } = this.state;
    let error = false;
    let errors = {}
    if (data.communication_template_master_id === false) {
      error = true;
      errors.communication_template_master_id = "select communication channel"
      
    }

    if (data.individual_ids < 1) {
      error = true;
      errors.individual_ids = "select individual";
    }

    if(CommType.Schedule === communication_type) {
      if(data.when_to_execute === ""){
        error = true;
        errors.when_to_execute = "select date";
      }
    }
    
    this.setState({errors});
    return error;
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  selectIndividual(individual_id) {
    const { data } = this.state;

    let position = data.individual_ids.indexOf(individual_id);
    if (position != -1) {
      data.individual_ids.splice(position, 1);
    } else {
      data.individual_ids.push(individual_id);
    }
    this.setState({ data });
  }


  selectChannels(e) {
    const { data } = this.state;
    const { name, value, checked } = e.target;

    // console.log(e.target);
    data.communication_channels[name] = checked;

    this.setState({ data });
  }

  render() {
    const { communication_type, data, items, errors } = this.state;
    const { individuals, communicationMasterTemplates } = this.props;
    
    let dropdownOptions = [{ title: "Select communication for", value: false }];
    
    if (communicationMasterTemplates) {
      communicationMasterTemplates.map((each) => {
        // if(!each.tags) {
          dropdownOptions.push({ title: each.name, value: each.id });
        // }
      });
    }

    if (items) {
      items.map((item) => {
        dropdownOptions.push({ value: "", title: item.name });
      });
    }
    let individualOptions=[{ title: "Select Individual", value: false }];
    
    if (individuals) {
        
        individuals.map((individual) => {
            let individual_id = individual.individual_id;
                      let foundItem = findFirst(individual, "children", {
                        dataType: "name",
                      });
                      let name = "name not specified"
                      if (foundItem) {
                        name = foundItem.value ? foundItem.value : name;
                      }
        individualOptions.push({ value: individual_id, title: name });
      });
    }
    return (
            <div>
                  <label>
                    <b>Select Individual</b>
                  </label>
                  
                  <InputDropDown
                    title={false}
                    name={"individual_ids"}
                    // placeholder={data.title}
                    value={data.individual_ids}
                    onChange={this.handleChange}
                    options={individualOptions}
                    maxLength="250"
                  />
                { errors.individual_ids && <InlineError message={errors.individual_ids} /> }

                  <label>
                    <b>Communication For</b>
                  </label>


                  <InputDropDown
                    title={false}
                    name={"communication_template_master_id"}
                    // placeholder={data.title}
                    onChange={this.handleChange}
                    options={dropdownOptions}
                    value={data.communication_template_master_id}
                    error={errors.communication_template_master_id}
                    maxLength="250"
                  />
                  <div>
                    <label>
                      <b>Communication Channel</b>
                    </label>
        
                    <FormGroup>
                      <label>
                        <input
                         disabled={true}
                          type="checkbox"
                          name={"call"}
                          checked={data.communication_channels.call}
                          onClick={this.selectChannels}
                        />
                        &nbsp; {"Call"}
                      </label>
                    </FormGroup>

                    <div class="btn-group">
                      <Button
                        color={
                          communication_type === CommType.Instant
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => this.setType(CommType.Instant)}
                      >
                        Instant
                      </Button>
                      <Button
                        color={
                          communication_type === CommType.Schedule
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => this.setType(CommType.Schedule)}
                      >
                        Schedule
                      </Button>
                    </div>
                    <br />
                    <br />

                    {communication_type === CommType.Schedule && (
                      <React.Fragment>

                    <FormGroup>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select schedule time"
                        selected={data.when_to_execute}
                        onChange={(date) => this.handleChange({ name :"when_to_execute" , value : date })}
                        // dateFormat="DD-MM-YYYY hh:mm"
                        // maxDate={lastMonth}
                        minDate={new Date()}
                        showTimeSelect
                        value={data.when_to_execute ? moment(data.when_to_execute).format("DD-MM-YYYY hh:mm A") : ""}
                        // showMonthYearPicker
                      />
                    { errors.when_to_execute && <InlineError message={errors.when_to_execute} /> }
                    </FormGroup>
                        </React.Fragment>
                    
                    )}
                    

                    <br />

                    <Button color="primary" onClick={() => this.save()}>
                      Done
                    </Button>
                  </div>
                </div>
              
    );
  }
}

function mapStateToProps(state) {
  const { businessAccountTemplate, businessAccounts } = state;
  return {
    communicationMasterTemplates: businessAccounts.communicationMasterTemplates,
  };
}

const connected = connect(mapStateToProps)(CommunicationCallForm);
export { connected as CommunicationCallForm };


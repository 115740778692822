import config from 'react-global-configuration'
import { axiosAjaxCall, handleResponse } from './common.service'


export const settingsService = {
    getSettings,
    editSettings,
}

function getSettings(data) {
    return axiosAjaxCall("get-settings", data);
}

function editSettings(data) {
    return axiosAjaxCall("update-setting", data);
}

import { subscriptionConst } from "../constants"


export function getSubscription(state = {}, action) {
    switch (action.type) {
        case subscriptionConst.GET_SUBSCRIPTION_REQUEST:
            return { loading: true }
        case subscriptionConst.GET_SUBSCRIPTION_SUCCESS:
            return { data: action.result }
        case subscriptionConst.GET_SUBSCRIPTION_FAILURE:
            return {}
        default:
            return state
    }
}

export function updateSubscription(state = {}, action) {
    switch (action.type) {
        case subscriptionConst.UPDATE_SUBSCRIPTION_REQUEST:
            return { loading: true }
        case subscriptionConst.UPDATE_SUBSCRIPTION_SUCCESS:
            return { data: action.result }
        case subscriptionConst.UPDATE_SUBSCRIPTION_FAILURE:
            return {}
        default:
            return state
    }
}
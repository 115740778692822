import {  axiosAjaxCall } from './common.service'
import {  showLoader } from '../../helpers';

export const adminContentConfigService = {
    getAdminContentConfigtData,
    updateAdminContentConfigData
}

function getAdminContentConfigtData(data) {
    return axiosAjaxCall("get_admin_content_configs", data);
}
function updateAdminContentConfigData(data) {
    showLoader()
    return axiosAjaxCall("update_admin_content_configs", data);
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, FormFeedback, Button } from 'reactstrap';
import { informationActions } from '../../actions';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CKEditor from "../../../components/Editor/CKeditor";
class AddInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorHtml: '',
            theme: 'snow',
            formData: {
                subject: '',
                information: '',
                status: false,
                type: '',
            },
            modalOpan: false,
            errors: {}
        }
        this.handelToggle = this.handelToggle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeHTML = this.handleChangeHTML.bind(this)
        this.isValid = this.isValid.bind(this)
    }
    handelToggle() {
        const { modalOpan } = this.state
        this.setState({
            modalOpan: !modalOpan
        }, () => {
            if (!modalOpan == true) {
                console.log(modalOpan)
                this.setEditData()
            }
        })
    }
    handleChangeHTML(event) {
    
        this.setState({
            formData: {
            ...this.state.formData,
            information: event.editor.getData()
          }
        })
    }
    handleChange(event) {
        const { name, value, type } = event.target;

        let { formData, errors } = this.state;
        let val = value;
        if (type === "checkbox") {
            if (formData[name] === false) {
              val = true;
            } else if (formData[name] === true) {
              val = false;
            } else {
              val = false;
            }
          }
        formData[name] = val;
        errors[name] = ''
        this.setState({ formData });
    }

    isValid() {
        const { formData, errors } = this.state;
        let error = false;
        if (!formData.subject) {
            error = true
            errors.subject = "this field is requred"
        }
        if (!formData.information) {
            error = true
            errors.information = "this field is requred"
        }
        if (!formData.type) {
            error = true
            errors.type = "this field is requred"
        }
        this.setState({ errors })
        return error
    }
    handleSubmit() {
        if (!this.isValid()) {
            const { formData, errors } = this.state;
            const { PayoutDetails, dispatch, getData } = this.props;
            let dataToSend = {
                subject : formData.subject,
                information : formData.information,
                type : formData.type,
                status : formData.status
            }

            dispatch(informationActions.addEditinformations(dataToSend, {
                success: () => {
                    this.handelToggle()
                    getData()
                }
            }));
        }
    }
    setEditData() {
        const { formData } = this.state;
        const { editData } = this.props
        if(editData) {
            formData.subject = editData.subject
            formData.information = editData.information
            formData.type = editData.type
            formData.status = editData.status ? true : false

            this.setState({ formData })
        }
    }
    render() {

        const { modalOpan, formData, errors } = this.state
        const {isEdit} = this.props
        return (<div>

             <React.Fragment>
                {
                    !isEdit ?
                    <button className="btn btn-success pull-right btn-sm" onClick={this.handelToggle}> Add Information</button>
                    : <button className="btn btn-success  btn-sm" onClick={this.handelToggle}> Edit</button>
                }
                <br />

                <Modal isOpen={modalOpan} size={"lg"} className="modal-success" toggle={this.handelToggle}    >
                    <ModalHeader toggle={this.handelToggle} >
                        {"Information"}
                    </ModalHeader>
                    <ModalBody >

                                
                                <div className={" form-group mb-3"}>
                                    <label>Subject</label>
                                    <Input
                                        name="subject"
                                        value={formData.subject}
                                        maxLength="50"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        type="text"
                                        placeholder="Subject"
                                    />
                                    {errors.subject && (
                                        <FormFeedback>
                                            {errors.subject}
                                        </FormFeedback>
                                    )}
                                </div>
                                <div className={" form-group mb-3"}>
                                    <label>Information </label><br />
                                    <CKEditor
                                        content={formData.information}
                                        events={{
                                            "blur": this.handleChangeHTML,
                                            "afterPaste": this.handleChangeHTML,
                                            "change": this.handleChangeHTML
                                        }}
                                    />
                                    {errors.information && (
                                        <FormFeedback>
                                            {errors.information}
                                        </FormFeedback>
                                    )}
                                </div>
                                <div className={" form-group mb-3"}>
                                    <label>Type  </label>
                                    <select className="form-control" name="type" onChange={this.handleChange} value={formData.type} >
                                        <option value="0">--- Select type ---</option>
                                        <option> Critical </option>
                                        <option> Normal </option>
                                        <option> Informative </option>
                                    </select>
                                    {errors.type && (
                                        <FormFeedback>
                                            {errors.type}
                                        </FormFeedback>
                                    )}
                                </div>
                                <div className={" form-group mb-3"}>
                                    <div class="custom-checkbox custom-control">
                                        <input type="checkbox"
                                        id="status"
                                        class="custom-control-input"
                                        checked={formData.status}
                                        name="status"
                                        onChange={this.handleChange} />
                                        <label class="custom-control-label" for="status"> Active</label>
                                    </div>
                                </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={this.handelToggle}>Close</Button>
                    </ModalFooter>
                </Modal>


            </React.Fragment>
        </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getPayoutDetails
    return {
        loading,
        data
    }
}

const AddInformationconnected = connect(mapStateToProps)(AddInformation);
export { AddInformationconnected as AddInformation}
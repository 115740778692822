import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, CardHeader, Button, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import { accountAction } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
import {  accountConstants, LAST_AMOUNT } from '../../constants';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { currencyFormat } from '../../../helpers';
import {UserSalaryCommisionDetail} from './UserSalaryCommisionDetail';
class UserSalary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),
			errors: {}
		};
		this.handleApply = this.handleApply.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.validation = this.validation.bind(this);
	}

	componentDidMount() {
		this.handleReset();
	}

	validation() {
		let { startDate } = this.state;
		let error = false;
		let errors = {};
		if (startDate === '') {
			errors.startDate = 'Please select month';
			error = true;
		}
		this.setState({
			errors
		});
		return error;
	}

	handleReset() {
		const { dispatch } = this.props;
		this.setState({
			startDate: new Date(),
			errors: {}
		});

		dispatch({ type: accountConstants.GET_USER_SALARY_DATA_RESET });
	}
	handleChange(date) {
		this.setState({
			startDate: date,
			errors: {}
		});
	}
	handleApply(event, picker) {
		const { dispatch } = this.props;
		let { startDate } = this.state;
		if (!this.validation()) {
			let dataToSend = {
				month: moment(startDate).format('YYYY-MM-DD')
			};
			dispatch(accountAction.getUserSalaryData(dataToSend));
		}
	}

	renderCommission(row) {
		let id = Math.random().toString(36).substr(2, 9);
		return (
			<React.Fragment>
				{parseInt(row.commission_applicable, 10) === 1 ? (
					<React.Fragment>
						<span className="helpLink" id={'commission_' + id}>
							₹ {currencyFormat(row.commission_amount)}
						</span>
						<UncontrolledTooltip placement="top" target={'commission_' + id}>
							
						<div className="table-responsive-sm  animated fadeIn">
								<table className="text-left" style={{ minWidth: '250px' }}>
									<tr>
										<th className="w-75"> Total Sales Amount </th>
										<td> ₹ {row.total_sales_amount} </td>
									</tr>
									<tr>
										<td>
											<b> Commission</b> <br />
											₹ {row.commission_slab_rate_min}{' '}
											{parseInt(row.commission_slab_rate_max, 10) === LAST_AMOUNT ? (
												'or More'
											) : (
												'- ₹ ' + row.commission_slab_rate_max
											)}{' '}
											( {parseFloat(row.commission_rate)} %)
										</td>
										<td> ₹ {row.commission_amount} </td>
									</tr>
								</table>
							</div>
						</UncontrolledTooltip>
					</React.Fragment>
				) : (
					<React.Fragment>
						<span className="helpLink" id={'commission_' + id}>
							N/A
						</span>
						<UncontrolledTooltip className="" placement="top" target={'commission_' + id}>
							Commission Not Applicable
						</UncontrolledTooltip>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}

	render() {
		const { loading, data, total } = this.props;
		const { errors, startDate } = this.state;

		var lastMonth = new Date(); // current date
		// lastMonth.setDate(1); // going to 1st of the month
		// lastMonth.setHours(-1);

		let _this = this;
		return (
			<div>
				<Row>
					<Col xs="12">
						<Card>
							<CardHeader>
								{' '}
								<i className="fas fa-money-check-alt" /> <b>Users Commission Report</b>
							</CardHeader>
							<CardBody>
								<div className="form-group">
									<div>
										<DatePicker
											className="form-control"
											placeholderText="Select a month"
											selected={startDate}
											onChange={(date) => this.handleChange(date)}
											dateFormat="MM/yyyy"
											maxDate={lastMonth}
											showMonthYearPicker
										/>

										<Button color="primary" className="ml-2" onClick={(e) => this.handleApply()}>
											Fetch
										</Button>
										<Button color="secondary" className="ml-1" onClick={(e) => this.handleReset()}>
											Reset
										</Button>
										<FormFeedback>{errors[`startDate`] && errors[`startDate`]}</FormFeedback>
									</div>
								</div>
								{loading ? (
									<TableLoader />
								) : data ? (
									<div className="table-responsive">
										<hr />
										{/* <DataTable keyField="id"
                                                data={data || []} columns={columns}
                                                filter={filterFactory()}
												noDataIndication={"No data found"} /> */}
												
									<div className="table-responsive-sm  animated fadeIn">
										<table className="table table-sm table-condensed table-bordered table-striped  table-outline">
											<thead class="thead-light">
												<tr>
													<th colSpan="2"></th>
													<th colSpan="5" className="text-center">Commission Amounts</th>
													<th colSpan="1"></th>
												</tr>

												<tr>
													<th>Name</th>
													<th>Month</th>
													<th className="text-center">First Recharge</th>
													<th className="text-center">Trail Recharge</th>
													<th className="text-center">Service Recharge</th>
													<th className="text-center">Flat Recharge</th>
													<th className="text-center">Total Amount</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{data.length ? (
													data.map(function(element, index) {
														return (
															<tr>
																<td>{element.name}</td>
																<td>{element.month_year}</td>
																<td  className="text-right">₹ {currencyFormat(element.commission_amount_first_recharge)}</td>
																<td  className="text-right">₹ {currencyFormat(element.commission_amount_trail_recharge)}</td>
																<td className="text-right">₹ {currencyFormat(element.commission_amount_service_recharge)}</td>
																<td className="text-right">₹ {currencyFormat(element.commission_amount_flat_recharge)}</td>
																<td className="text-right">₹ {currencyFormat(element.total_salary_amount)}</td>
																<td><UserSalaryCommisionDetail  element={element} startDate={startDate} /></td>
															</tr>
														);
													})
												) : (
													<tr>
														<th colSpan="9" className="text-center">
															No Data Found
														</th>
													</tr>
												)}
											</tbody>
											<tfoot>
												<tr>
													<th colSpan="2" className="text-right">
														Total Amount
													</th>
													<th className="text-right"> ₹ {currencyFormat(total.commission_amount_first_recharge)}</th>
													<th className="text-right"> ₹ {currencyFormat(total.commission_amount_trail_recharge)}</th>
													<th className="text-right"> ₹ {currencyFormat(total.commission_amount_service_recharge)}</th>
													<th className="text-right"> ₹ {currencyFormat(total.commission_amount_flat_recharge)}</th>
													<th className="text-right"> ₹ {currencyFormat(total.total_salary_amount)}</th>
												</tr>
											</tfoot>
										</table>
									</div>
									</div>
								) : (
									''
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data, total } = state.getUserSalaryData;
	return {
		loading,
		data,
		total
	};
}

const connectedUserSalary = connect(mapStateToProps)(UserSalary);
export { connectedUserSalary as UserSalary };

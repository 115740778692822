import {  axiosAjaxCall } from './common.service'
import {  showLoader } from "../../helpers";

export const emailService = {
    addEmail,
    getEmails,
    editEmail,
    deleteEmail,
    publishEmail,
    sendEmail
}

function addEmail(data) {
    
    showLoader();    
    return axiosAjaxCall("add_email_data", data);
}

function getEmails(data) {
       
    return axiosAjaxCall("get_email_data", data);
}

function editEmail(data) {      
    return axiosAjaxCall("update_email_data", data);
}

function deleteEmail(data) {
    showLoader();    
    return axiosAjaxCall("delete_email_data", data);
}

function publishEmail(data) {
    showLoader();    
    return axiosAjaxCall("publish_email_data", data);
}
function sendEmail(data) {
    showLoader();    
    return axiosAjaxCall("send_test_email", data);
}
import moment from "moment";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Input, Label } from "reactstrap";
import { TableLoader } from "../../../components/Loaders";
import { whatsAppLogs } from "../../actions";

const defaultState = {
  page: {
    activePage: 1,
    perPage: 100,
    totalItemsCount: 0,
  },
  number: "",
}
class WhatsappReceive extends Component {
  constructor(props) {
    super(props);
    this.state = { ...defaultState };
    this.getWhatsappReceiveLogsData =  this.getWhatsappReceiveLogsData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.getWhatsappReceiveLogsData();
  }

  reset() {
    this.setState({ ...defaultState }, () => this.getWhatsappReceiveLogsData())
  }

  getWhatsappReceiveLogsData(isSearch) {
    const { dispatch } = this.props;
    const { page, number } = this.state;

    let dataToSend = {
      per_page: page.perPage,
      keywords: number
  };
  if (isSearch) {
      dataToSend[`active_page`] = 1
  } else {
      dataToSend[`active_page`] = page.activePage
  }


    dispatch(whatsAppLogs.getWhatsappReceiveLogs(dataToSend));
  }
  handlePageChange(pageNumber) {
    const { page } = this.state;
    page.activePage = pageNumber;
    this.setState({ page }, () => this.getWhatsappReceiveLogsData());
  }

  componentWillReceiveProps(props) {
    let { page, activeTab } = this.state;
    page.totalItemsCount = 0;
    if (props.logs) {
      page.totalItemsCount = props.logs.total_records;
    }
    if (props.activeTab) {
      activeTab = props.activeTab;
    }
    this.setState({
      page,
      activeTab,
    });
  }

  handleChange (e){
    const {value , name }  = e.target 
      if (/^[0-9]*$/.test(value) !== false) {
        this.setState({ [name]: value });
      }
  }

  render() {
    const { logs, loading } = this.props;
    const { page, number } = this.state;
    return (
      <Card>
        <CardHeader>
          <i className="fas fa-boxes" />
          <strong>Whatsapp Received Logs</strong>
        </CardHeader>
        <CardBody>
          {loading ? (
            <TableLoader />
          ) : (
            <React.Fragment>
              <div>
                <div className="w-100 d-flex mb-3">
                  <div className="w-25">
                    <Label
                      style={{ textAlign: "left", justifyContent: "left" }}
                    >
                      Mobile
                    </Label>
                    <Input
                      type="text"
                      placeholder="Mobile"
                      name="number"
                      value={number}
                      onChange={this.handleChange}
                      maxlength={12}
                    />
                  </div>
                  <div
                    className="btn p-0"
                    style={{
                      alignSelf: "end",
                    }}
                  >
                    <Button
                     color="primary"
                      onClick={()=> this.getWhatsappReceiveLogsData (true )}
                      className="btn ml-3"
                    >
                      Fetch
                    </Button>
                    <Button
                      onClick={this.reset}
                      className="btn btn-secondary ml-3"
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="chat">
                    <div className="chat-container">
                      <div className="conversation">
                        <div class="conversation-container">
                          {logs && logs.list && logs.list.length ? (
                            logs.list.map(function (eachData, index) {
                              return (
                                <React.Fragment>
                                  <div className="message received">
                                    <div class="mb-3">
                                      <b className={"m-2"}>
                                        ~ {eachData.customer_name}
                                      </b>
                                      <i className="pull-right text-muted">
                                        {eachData.sender}
                                      </i>
                                    </div>

                                    {/* <td>{eachData.content_type}{eachData.sender}</td> */}
                                    <div className="text-center">
                                      <div className="text-center">
                                        {eachData.content_type === "video" && (
                                          <video
                                            style={{ maxHeight: "300px" }}
                                            controls={true}
                                            src={eachData.url}
                                          ></video>
                                        )}
                                        {eachData.content_type === "image" && (
                                          <img
                                            style={{ maxHeight: "300px" }}
                                            controls={true}
                                            src={eachData.url}
                                          />
                                        )}
                                        {eachData.content_type === "button" && (
                                          <i className="badge badge-primary">
                                            {eachData.button.text}
                                          </i>
                                        )}
                                        {eachData.content_type === "file" && (
                                          <a
                                            className="btn btn-primary btn-block"
                                            href={eachData.url}
                                            target="_blank"
                                          >
                                            Open file
                                          </a>
                                        )}
                                      </div>

                                      {eachData.caption}
                                      {eachData.text}
                                    </div>

                                    <span class="metadata">
                                      <span class="time">
                                        <b>
                                          <i className="fa fa-clock"></i>{" "}
                                          {moment(
                                            moment
                                              .utc(eachData.received_at)
                                              .toDate()
                                          ).format(
                                            "DD-MM-YYYY hh:mm:ss a"
                                          )}{" "}
                                          &nbsp;
                                        </b>
                                      </span>
                                    </span>
                                  </div>

                                  {/* <tr> */}
                                  {/* <td>{index}</td> */}
                                  {/* <td>{eachData.sender}</td> */}
                                  {/* <td>{eachData.content_type}</td> */}
                                  {/* <td className='text-center'>
																
															</td> */}
                                  {/* <td>{eachData.received_at}</td> */}
                                  {/* </tr> */}
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {page.totalItemsCount <= page.perPage ? (
                ""
              ) : (
                <Pagination
                  activePage={page.activePage}
                  itemsCountPerPage={page.perPage}
                  totalItemsCount={page.totalItemsCount}
                  pageRangeDisplayed={5}
                  linkClass="page-link"
                  itemClass="page-item"
                  onChange={this.handlePageChange}
                />
              )}
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getWhatsappLogs;
  return {
    loading,
    logs: data,
  };
}

const WhatsappReceiveLogs = connect(mapStateToProps)(WhatsappReceive);
export { WhatsappReceiveLogs as WhatsappReceive };


import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import {
  InputDropDown,
  InputText,
  InputCheckBox,
} from "../Components/FormElements";
import CheckList from "../Components/CheckList/CheckList";
import Radio from "../Components/Radio/Radio";
import { v4 as uuidv4 } from "uuid";
import {
  InputTypes,
  InputTypeOptions,
  DataTypesOptions,
  DataTypes,
} from "../../../../constants/stages.constants";

let optionWiseitems = {
  CheckList: [{ title: "", value: "" }],
};

let defaultAttributes = {
  Radio: { inline: false },
};

let defaultData = {
  title: "",
  value: "",
  inputType: InputTypes.Text,
  dataType: DataTypes.TEXT,
  items: optionWiseitems.CheckList,
  arrtibutes: {},
};

class AddNewItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      data: { ...defaultData },
    };
    this.renderInputTypeWiseOptions = this.renderInputTypeWiseOptions.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.reset = this.reset.bind(this);
    this.handleChangeAttr = this.handleChangeAttr.bind(this);
  }

  setData(data) {
    if (data) {
      this.setState({ currentData: data });
    }
  }

  handleChangeAttr(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { data } = this.state;

    if (data.hasOwnProperty("attribute")) {
    } else {
      data.attribute = {};
    }

    if (name === "inline") {
      data["attribute"][name] = event.target.checked;
    } else {
      data["attribute"][name] = value;
    }

    this.setState({ data });
  }

  renderInputTypeWiseOptions() {
    let renderedItem = false;
    const { data } = this.state;
    const _this = this;

    switch (data.inputType) {
      case InputTypes.Text:
        renderedItem = (
          <React.Fragment>
            
            <Col md="4">
            <InputText
              title={"Default value"}
              placeholder="Default value"
              name="value"
              value={data.value}
              onChange={this.handleChange}
              maxLength="250"
            />
            </Col>
          </React.Fragment>
        );
        break;
      case InputTypes.CheckList:
        renderedItem = (
          <React.Fragment>
            <CheckList data={data} handleChange={(e) => this.handleChange(e)} />
          </React.Fragment>
        );
        break;
      case InputTypes.Radio:
        renderedItem = (
          <React.Fragment>
            
            <Col md="8">
              <Radio data={data} handleChange={(e) => this.handleChange(e)} />
            </Col>
            
            <Col md="4">
            <Label>
              <b>View Preferance</b>
            </Label>

            <InputCheckBox
              name={"inline"}
              title="Display Inline"
              checked={
                data.hasOwnProperty("attribute") &&
                data.attribute.hasOwnProperty("inline")
                  ? data.attribute.inline
                  : defaultAttributes.Radio.inline
              }
              onChange={_this.handleChangeAttr}
            />
            </Col>
          </React.Fragment>
        );
        break;
    }
    return renderedItem;
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { data } = this.state;
    data[name] = value;

    if (name === "inputType") {
    
      data["attribute"] = {};
      data["items"] = [];
      data["dataType"] = "";
      data["value"] = "";
      
      if (value === InputTypes.CheckList || value === InputTypes.Radio ) {
        data["items"] = optionWiseitems.CheckList;
      } else if (value === InputTypes.Text) {
        data["dataType"] = defaultData.TEXT;
      } else if (value === InputTypes.Radio) {
        data["attribute"] = { inline: true };
      }

    }

    this.setState(data);
  }

  save() {
    const { data } = this.state;
    const {
      SaveItem,
      isEdit,
      isSubItem,
      ItemData,
      RootItems,
      Items,
    } = this.props;
    let _data = { ...data };

    if (isSubItem && ItemData.inputType === InputTypes.SubItem) {
      RootItems.forEach(function iter(a, idx) {
        if (ItemData.id === a.id) {
          _data.id = uuidv4();
          if (a.hasOwnProperty("children")) {
            a.children.push({ ..._data });
          } else {
            a.children = [{ ..._data }];
          }
        }
        Array.isArray(a.children) && a.children.forEach(iter);
      });
    } else {
      if (isEdit) {
      } else {
        // add new item
        _data.id = uuidv4();
        RootItems.push({ ..._data });
      }
    }

    SaveItem(RootItems);

    this.toggle(false);
    this.reset();
  }

  reset() {
    let data = { ...defaultData };
    this.setState({ data });
  }

  toggle(value) {
    const { isEdit, ItemData } = this.props;
    let { data } = this.state;
    if (isEdit) {
      data = ItemData;
    }
    this.setState({ show: value, data }, () => {
      if (!isEdit) {
        this.reset();
      }
    });
  }

  render() {
    const { show, data } = this.state;
    const { isEdit, isSubItem } = this.props;

    return (
      <React.Fragment>
        {isSubItem ? (
          <button
            className="btn btn-primary btn-sm"
            title={isEdit ? "Edit" : "Add new sub item"}
            onClick={() => this.toggle(true)}
          >
            {isEdit ? (
              <i className={"fa fa-edit"}></i>
            ) : (
              <i className={"fa fa-plus"}></i>
            )}
          </button>
        ) : (
          <button
            className="btn btn-primary btn-sm"
            title={isEdit ? "Edit" : "Add sub item"}
            onClick={() => this.toggle(true)}
          >
            {isEdit ? <i className={"fa fa-edit"}></i> : "Add New Item"}
          </button>
        )}

        <Modal
          isOpen={show}
          size={"lg"}
          toggle={() => this.toggle(false)}
          className="modal-primary"
        >
          <ModalHeader> {isEdit ? "Edit Item" : "Add New Item"} </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="8">
                <InputText
                  title={"Heading"}
                  placeholder="Heading"
                  value={data.title}
                  name="title"
                  onChange={this.handleChange}
                  maxLength="250"
                />
              </Col>

              <Col md="4">
                <InputDropDown
                  title={"Input Type"}
                  value={data.inputType}
                  name={"inputType"}
                  onChange={this.handleChange}
                  options={InputTypeOptions}
                />
              </Col>
            </Row>
            <Row>
              {(data.inputType === InputTypes.Text || data.inputType === InputTypes.TextArea) && (
                <React.Fragment>
                 
                  <Col md="4">
                    <InputDropDown
                      title={"Data Type"}
                      value={data.dataType}
                      name={"dataType"}
                      onChange={this.handleChange}
                      options={DataTypesOptions}
                    />
                  </Col>
                  <Col md="8">
                    <InputText
                      title={"Data validation string"}
                      name={"validate"}
                      value={
                          data.hasOwnProperty("attribute") &&
                        data.attribute.hasOwnProperty("validate")
                          ? data.attribute.validate
                          : ""
                      }
                      onChange={this.handleChangeAttr}
                    />

<small>
                    <b>Eg. required | type:numbers | min:1 | max:5 | minNum:1000 | maxNum:2000</b><br/>
                    <b> Here, all options are optional.</b> <br/>
                    <b> "required" for field is mandatory</b><br/>
                    <b> "type" for field is allow numbers or text. default type is text.</b><br/>
                    <b> "min" and "max" for field's charecters length </b><br/>
                    <b> "minNum" and "maxNum" for number's maximum or minimum value to allow </b>
</small>
                  </Col>
                </React.Fragment>
              )}
            {this.renderInputTypeWiseOptions()}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            <Button color="warning" onClick={() => this.reset()}>
              Reset
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedAddNewItem = connect(mapStateToProps)(AddNewItem);
export { connectedAddNewItem as AddNewItem };

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, FormFeedback } from 'reactstrap';
import { currencyFormat } from '../../../helpers';
import { dailyMisAction } from '../../actions';
import { ReportValuesModal } from '../RecentRegistrations/Common/ReportValuesModal';


const DailyMis = (props) => {
  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState([])
  const [errors, setErrors] = useState({})
  const [dateData, setDateData] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
    serachField: {
      dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
    },
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },

  })
  const { dailyMisData, total } = props


  const handleApply = () => {
    const { dispatch } = props;
    const { startDate, endDate } = dateData
    if (!validation()) {
      let dataToSend = {
        end_date: startDate.format('YYYY-MM-DD'),
        start_date: endDate.format('YYYY-MM-DD')
      };
      dispatch(dailyMisAction.getDailyMis(dataToSend))
    }
  }

  useEffect(() => {
    handleApply()
  }, []);

  const toggle = (data) => {
    setShow(!show);
    setModalData(data)
  }

  const handleReset = () => {
    setDateData({
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
      serachField: {
        dateRange: moment().subtract(30, "days").format("DD MMM Y") + "-" + moment().format("DD MMM Y")
      }
    })
    setData([])
    setErrors({})
    handleApply()

  }

  const validation = () => {
    const { startDate, endDate } = dateData
    let error = false;
    let errors = {};
    if (startDate === '' || endDate === '') {
      errors.startDate = 'Please select Date';
      error = true;
    }
    setErrors({ errors });
    return error;
  }

  const handleEvent = (event, picker) => {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + ' to ' + end;
    if (start === end) {
      label = start;
    }
    setDateData({
      ...dateData,
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label
      },
    })
  }

  useEffect(() => {
    setData(dailyMisData)
  }, [dailyMisData]);

  const { startDate, endDate, ranges, serachField } = dateData
  return (
    <React.Fragment>
      <Card className='' >
        <CardHeader>
          <i className="far fa-chart-bar"></i> Daily MIS
        </CardHeader>
        <CardBody>
          <div className="form-group">
            <div>
              <div className=' d-flex  mt-2' >
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onEvent={handleEvent}
                  ranges={ranges}
                >
                  <div className="input-group">
                    <input type="text" className="form-control" value={serachField.dateRange} placeholder="Select Date Range" id="date_picker" style={{ 'width': '220px' }} />
                    <span className="input-group-btn">
                      <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                        <i className="fa fa-calendar" />
                      </Button>
                    </span>
                  </div>
                </DateRangePicker>

                <Button color="primary" className="ml-2" onClick={handleApply}>
                  Fetch
                </Button>
                <Button color="secondary" className="ml-2" onClick={handleReset}>
                  Reset
                </Button>
              </div>
              <FormFeedback>{errors[`startDate`] && errors[`startDate`]}</FormFeedback>
            </div>
          </div>

          {<div className="table-responsive-sm  animated fadeIn">
            <table className="table table-sm table-condensed table-striped table-bordered table-outline">
              <thead className="thead-light">
                <tr>
                  <th colSpan={4} ></th>
                  <th colSpan={2} >Registrations</th>
                  <th colSpan={2} >Recharges</th>
                  <th ColSpan={3}></th>
                  <th colSpan={3} >Send Bill Count</th>
                  <th colSpan={3} >Send Bill Accepted</th>
                  <th colSpan={3} >Critical Due Count</th>
                  <th colSpan={3} >Add Debtor Count</th>
                </tr>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Downloads</th>
                  <th>Uninstalls</th>
                  <th>Count</th>
                  <th>Amount</th>
                  <th>Count</th>
                  <th>Amount</th>
                  <th>Total Revenue</th>
                  <th>Expected</th>
                  <th>Shortfall</th>
                  <th  title='Registered Send Bill Count' >R</th>
                  <th  title='UnRegistered Send Bill Count'>U</th>                 
                  <th  title='Total Send Bill Count'>T</th>
                  <th  title='Registered Send Bill Accepted' >R</th>
                  <th  title='UnRegistered Send Bill Accepted'>U</th>
                  <th  title='Total Send Bill Accepted'>T</th>
                  <th  title='Registered Critical Due Count'>R</th>
                  <th  title='Direct Critical Due Count'>D</th>
                  <th  title='Total Critical Due Count'>T</th>
                  <th  title='Registered Add Debtor Count'>R</th>
                  <th  title='UnRegistered Add Debtor Count'>U</th>
                  <th  title='Total Add Debtor Count'>T</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length ? (
                  data.map((eachData, index) => <React.Fragment key={index} >
                    <tr>
                      {
                      console.log(eachData)
                      }
                      <td>{index + 1}</td>
                      <td>{eachData.date}</td>
                      <td>
                        <ReportValuesModal title='Downloads'
                          currentvalue={eachData.downloads}
                          displayValue={eachData.downloads} type='downloads' getData={handleApply} data={eachData} />
                      </td>
                      <td>
                        <ReportValuesModal title='Uninstalls'
                          currentvalue={eachData.uninstalls}
                          displayValue={eachData.uninstalls} type='uninstalls' getData={handleApply} data={eachData} />
                      </td>
                      <td>
                        <span className='float-right'>
                          {eachData.register_users}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.earnings, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {eachData.recharge_count}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.recharges, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.revenue, true)}
                        </span>
                      </td>
                      <td>
                        <span className='float-right'>
                          {currencyFormat(eachData.expected, true)}
                        </span>
                      </td>
                      <td><span className='float-right'>
                        {currencyFormat(eachData.shortfall, true)}
                      </span>
                      </td>
                      <td title='Registered Send Bill Count'><span className='float-right'>
                     {eachData.send_bill_register}
                      </span>
                      </td>
                      <td title='UnRegistered Send Bill Count'><span className='float-right'>
                      {eachData.send_bill_unregister}
                      </span>
                      </td>
                      <td title='Total Send Bill Count'><span className='float-right'>
                      {eachData.total_send_bill}
                      </span>
                      </td>
                      <td title='Registered Send Bill Accepted'><span className='float-right'>
                     {eachData.send_bill_accepted_register}
                      </span>
                      </td>
                      <td title='UnRegistered Send Bill Accepted'><span className='float-right'>
                      {eachData.send_bill_accepted_unregister}
                      </span>
                      </td>
                      <td title='Total Send Bill Accepted'><span className='float-right'>
                      {eachData.total_send_bill_accepted}
                      </span>
                      </td>
                      <td title='Registered Critical Due Count'><span className='float-right'>
                        {eachData.critical_due_register}
                      </span>
                      </td>
                      <td title='Direct Critical Due Count'><span className='float-right'>
                        {eachData.critical_due_direct}
                      </span>
                      </td>
                      <td title='Total Critical Due Count'><span className='float-right'>
                        {eachData.total_critical_due}
                      </span>
                      </td>
                      <td title='Registered Add Debtor Count'><span className='float-right'>
                        {eachData.register_debtors}
                      </span>
                      </td>
                      <td title='UnRegistered Add Debtor Count'><span className='float-right'>
                        {eachData.unregister_debtors}
                      </span>
                      </td>
                      <td  title='Total Add Debtor Count'><span className='float-right'>
                        {eachData.total_debtors}
                      </span>
                      </td>

                    </tr>
                  </React.Fragment>
                  )
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}



              </tbody>
              {total ?
                <thead className="thead-light">
                  <tr>
                    <th></th>
                    <th></th>
                    <th className='text-right'>{total.downloads}</th>
                    <th className='text-right'>{total.uninstalls}</th>
                    <th className='text-right'>{total.earning_count}</th>
                    <th className='text-right'>{currencyFormat(total.earnings, true)}</th>
                    <th className='text-right'>{total.recharge_count}</th>
                    <th className='text-right'>{currencyFormat(total.recharges, true)} </th>
                    <th className='text-right'>{currencyFormat(total.revenue, true)} </th>
                    <th className='text-right'>{currencyFormat(total.expected, true)} </th>
                    <th className='text-right'>{currencyFormat(total.shortfall, true)}  </th>
                    <th className='text-right'> {total.send_bill_register} </th>
                    <th className='text-right'> {total.send_bill_unregister} </th>
                    <th className='text-right'> {total.total_send_bill} </th>
                    <th className='text-right'> {total.send_bill_accepted_register} </th>
                    <th className='text-right'> {total.send_bill_accepted_unregister} </th>
                    <th className='text-right'> {total.total_send_bill_accepted} </th>
                    <th className='text-right'> {total.critical_due_register} </th>
                    <th className='text-right'> {total.critical_due_direct} </th>
                    <th className='text-right'> {total.total_critical_due} </th>
                    <th className='text-right'> {total.register_debtors} </th>
                    <th className='text-right'> {total.unregister_debtors} </th>
                    <th className='text-right'> {total.total_debtors} </th>
                  </tr>
                </thead> : false}
            </table>
          </div>}


        </CardBody>
      </Card>

    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const { data, total, loading } = state.getDailyMis
  return {
    loading,
    dailyMisData: data,
    total
  }

}
const connectedDailyMis = connect(mapStateToProps)(DailyMis)
export { connectedDailyMis as DailyMis };


import { axiosAjaxCall } from "./common.service";
import { showLoader } from "../../helpers";

export const communicationMastersTemplatesService = {
    saveCommunicationMastersTemplates,
    getCommunicationMastersTemplates,
}

function saveCommunicationMastersTemplates(data) {    
    showLoader();
    return axiosAjaxCall("save_communication_template_master", data);
}

function getCommunicationMastersTemplates(data) {    
    return axiosAjaxCall("get_communication_template_master", data);
}


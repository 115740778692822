import { MarketingConstants } from '../constants'

export function fetchBusinessResults(state = {}, action) {
  switch (action.type) {
    case MarketingConstants.FETCH_BUSINESSES_RESULT_REQUEST:
      return { loading: true }
    case MarketingConstants.FETCH_BUSINESSES_RESULT_SUCCESS:
      return { data: action.result }
    case MarketingConstants.FETCH_BUSINESSES_RESULT_FAILURE:
      return {}
    default:
      return state
  }
}


export function marketingWhatsappAnalysis(state = {}, action) {
  switch (action.type) {
    case MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_REQUEST:
      return { loading: true }
    case MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_SUCCESS:
      return { data: action.result }
    case MarketingConstants.WHATSAPP_MARKETING_ANALYSIS_FAILURE:
      return {}
    default:
      return state
  }
}
export function MobileNotificatios(state = {}, action) {
  switch (action.type) {
    case MarketingConstants.GET_SENDED_NOTIFICATIONS_REQUEST:
      return { loading: true }
    case MarketingConstants.GET_SENDED_NOTIFICATIONS_SUCCESS:
      return { data: action.result }
    case MarketingConstants.GET_SENDED_NOTIFICATIONS_FAILURE:
      return {}
    default:
      return state
  }
}
export function reportValuesModal(state = {}, action) {
  switch (action.type) {
    case MarketingConstants.SET_REPORT_VALUES_MODAL_REQUEST:
      return { loading: true }
    case MarketingConstants.SET_REPORT_VALUES_MODAL_SUCCESS:
      return { data: action.result }
    case MarketingConstants.SET_REPORT_VALUES_MODAL_FAIL:
      return {}
    default:
      return state
  }
}
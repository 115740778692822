import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import swal from 'sweetalert';
import { TableLoader } from "../../../components/Loaders";
import { businessAccountsActions } from "../../actions";
import { Msg91BlockCsvModal } from "./Msg91BlockCsvModal";
class BlockBusinessAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      business_tag: "",
      data: [],
      errors: {},
      active_page: 1,
      per_page: 20,
      total_data: 0,

    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reset = this.reset.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleBlock = this.handleBlock.bind(this);
  }

  handlePageChange(page_no) {
    this.setState({
      active_page: page_no
    }, () => this.onSubmit())
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ business_tag: value, data: [], total_data: 0 });
    console.log(this.state.business_tag);
  }

  handleValidation = (data) => {
    const errors = {};
    var business_tag = data;

    if (!business_tag) {
      errors["business_tag"] = "Business keyword Required";
    } else if (business_tag.length < 3) {
      errors["business_tag"] = "Enter 3 or more keyword ";
    }

    let formIsValid = Object.keys(errors).length == 0;

    return { errors, formIsValid };
  };

  onSubmit(e) {
    const { dispatch } = this.props

    if (e) {
      e.preventDefault();
    }

    const { business_tag, active_page } = this.state;
    const result = this.handleValidation(business_tag);
    this.setState({ errors: result.errors });
    if (result.formIsValid) {
      let dataToSend = {
        active_page: active_page,
        keyword: business_tag
      };
      dispatch(businessAccountsActions.getBlockBussinessData(dataToSend));
    }
  }

  reset() {
    this.setState({
      business_tag: "",
      loading: false,
      data: [],
      errors: {},
      active_page: 1,
      per_page: 20,
      total_data: 0
    });

  }

  componentWillReceiveProps(props) {
    const { getBlockBusiness } = props
    if (getBlockBusiness.loading !== true && getBlockBusiness.data && getBlockBusiness.data.total) {
      this.setState({
        data: getBlockBusiness.data, total_data: getBlockBusiness.data.total
      })
    }
  }

  handleBlock() {
    const { dispatch } = this.props
    const { business_tag } = this.state
    swal({
      title: "Are you sure ?",
      text: "Do you want to Block this Items.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Cancel", "Yes, I am sure!"],
    }).then((confirm) => {
      if (confirm) {

        let dataToSend = {
          // active_page: active_page,
          keyword: business_tag
        };
        dispatch(businessAccountsActions.blockBussinessData(dataToSend, {
          success: () => {
            this.reset();
          }
        }));

      }
    });
  }

  render() {
    const { errors, business_tag, loading, data } = this.state;

    return (
      <div className="">
        <Row>
          <Form inline onSubmit={this.onSubmit} autoComplete="off" >
            <FormGroup className="ml-3">
              <div>
                <Label className='mb-2' style={{ textAlign: "left", justifyContent: "left" }}>
                  Business Keyword
                </Label>
                <div>
                  <Input
                    placeholder="Search.."
                    name="keywords"
                    value={business_tag}
                    onChange={this.handleChange}
                  />
                  <Button
                    color="primary"
                    type='submit '
                    className='ml-3'
                  >
                    Fetch
                  </Button>
                  <Button
                    color="secondary"
                    className="ml-3"
                    onClick={this.reset}
                  >
                    Reset
                  </Button>
                  <Msg91BlockCsvModal/>
                </div>
                <FormFeedback> <b>{errors.business_tag}</b> </FormFeedback>
              </div>
            </FormGroup>
          </Form>
        </Row>
        <hr />
        <Row>
          {loading ? (
            <TableLoader />
          ) : (
            <React.Fragment>
              {this.state.total_data !== 0 && <div className="mt-3 ml-3 ">
                <span style={{ fontSize: 20, marginRight: 20 }}>
                  {" "}
                  Count :{" "}
                  <label className=" badge badge-success p-2">
                    {this.state.total_data}
                  </label>
                </span>
                <Button onClick={this.handleBlock} className="btn btn-danger"  >Block</Button>
              </div>}
              <br />
              <div className="mt-3 px-3 table-responsive  animated fadeIn">
                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                  <thead className="thead-light">
                    <tr>
                      <td style={{ width: "2%" }}>NO</td>
                      <td style={{ width: "30%" }}>Business</td>
                      <td style={{ width: "30%" }}>City</td>
                      <td style={{ width: "18%" }}>State</td>
                      <td style={{ width: "20%" }}>Sector</td>
                    </tr>
                  </thead>

                  <tbody>
                    {data && data.data && data.data.length && data.data.total !== 0 ? (
                      data.data.map((d, index) => (
                        <tr key={index}>
                          <td style={{ width: "2%" }}>{index + 1}</td>
                          <td style={{ width: "30%" }}>{d.business_name}</td>
                          <td style={{ width: "30%" }}>{d.city}</td>
                          <td style={{ width: "18%" }}>{d.state}</td>
                          <td style={{ width: "20%" }}>{d.sector}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="5"> No data found</td>
                      </tr>
                    )}
                  </tbody>

                  {this.state.data && this.state.total_data ? (
                    <React.Fragment>
                      {this.state.total_data <= this.state.per_page ? (
                        ""
                      ) : (
                        <Pagination
                          activePage={this.state.active_page}
                          itemsCountPerPage={this.state.per_page}
                          totalItemsCount={this.state.total_data}
                          pageRangeDisplayed={5}
                          linkClass="page-link"
                          itemClass="page-item"
                          onChange={this.handlePageChange}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    false
                  )}
                </table>
              </div>
            </React.Fragment>
          )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sectors } = state.sectors;
  const { loading, states } = state.states;
  const { user } = state.authentication;
  const { getBlockBusiness } = state;

  return {
    loading,
    sectors,
    states,
    authUser: user,
    getBlockBusiness,
  };
}

const connectedBlockBusinessAccount = connect(mapStateToProps)(BlockBusinessAccount);
export { connectedBlockBusinessAccount as BlockBusinessAccount };


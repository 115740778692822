import React, { Component } from "react";
import { profileActions } from "./../../actions";
import swal from "sweetalert";
import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback,InputGroup, InputGroupAddon, InputGroupText 
} from "reactstrap";
import MaskedInput from "react-text-mask";
import { validPANCorporate } from '../../../helpers'
export default class UpdatePAN extends Component {
    constructor(props) {
        super(props);
        let days = new Date().getDate() - 1;
        this.state = {
            modalOpan: false,
            data: {
                pan: "",
            },
            errors: {}
        };
        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.proceed = this.proceed.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.setEditData = this.setEditData.bind(this);
    }

    showModal() {
        const { modalOpan } = this.state;
        this.setState({ modalOpan: !modalOpan });
        this.reset();
        this.setEditData();
        if(modalOpan == true) {
        }
    }

    reset() {
        this.setState({ data: {
            pan: "",
        },})
    }

    handleChange(event) {
        const { name, value, type } = event.target;

        let { data } = this.state;
        let val = value;
        
        // convert to lowercase data to  uppercase
        if ( name === "pan" ) {
            val = value.toUpperCase();
        }
        
        data[name] = val;
        this.setState({ data });
    }

    isValidData() {
        const { data, errors } = this.state
        let error = false
        if (data.pan === "") {
            errors.pan = "this field is required";
            error = true;
        } else if (!validPANCorporate(data.pan)) {
            errors.pan = "Please enter valid pan number";
            error = true;
        }
      
        this.setState({ errors })
        return error
    }
    proceed() {
        const { data } = this.state;
        const { dispatch, element, getData } = this.props;

        if (!this.isValidData()) {
            swal({
                title: "Are you sure ?",
                text: "Do you want to update PAN ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    let dataTosend = {
                        user_id : element.id,
                        pan : data.pan,
                    }

                    console.log('dataTosend',dataTosend)
                    dispatch(
                        profileActions.updatePAN(dataTosend, {
                            success: () => {
                                this.showModal()
                                getData()
                            }
                        })
                    );

                }
            });
        }
    }
    setEditData() {
        const { data } = this.state
        const { element } = this.props
        if(element) {
            data.pan = element.pan
            this.setState({ data })
        }
    }
    componentDidMount() {
            this.setEditData()

    }
    render() {
        const { modalOpan, data, errors } = this.state;
        const { element } = this.props;
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
          };
        let _this = this;
        return (
            <React.Fragment>


                    <Button color="link" size="sm" onClick={_this.showModal} >
                        { element && element.pan ? 'Change' : 'Add' } 
                    </Button>
                <Modal className="modal-primary" isOpen={modalOpan}>
                    <ModalHeader toggle={_this.showModal}>{ element && element.pan ? 'Update PAN' : 'Add PAN' }<br />{" "}
                        {/* <small>Add remaining negotiated offer</small> */}
                    </ModalHeader>
                    <ModalBody>
                        
                        
                              <React.Fragment>
                               
                                <div className={" form-group mb-3"}>
                                  <InputGroup>
                                    <InputGroupAddon
                                      addonType="prepend"
                                      id="ico-pan"
                                    >
                                      <InputGroupText>
                                        <i className="fa fa-user"></i>{" "}
                                        <i style={mendetoryFields}>*</i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <MaskedInput
                                        name="pan"
                                        value={data.pan}
                                        onChange={this.handleChange}
                                        guide={false}
                                        onBlur={this.validatePAN}
                                        mask={[
                                          /[A-Za-z]/,
                                          /[A-Za-z]/,
                                          /[A-Za-z]/,
                                          /[p|P|c|C|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G|k|K]/,
                                          /[A-Za-z]/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /[A-Za-z]/,
                                        ]}
                                        className="form-control"
                                        type="text"
                                        placeholder={'Enter your PAN'}
                                      />
                                  </InputGroup>
                                  {errors.pan && (
                                    <FormFeedback>
                                      {errors.pan}
                                    </FormFeedback>
                                  )}
                                </div>
                                
                              </React.Fragment>
                        
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.proceed}>
                            Submit
                        </Button>
                        <Button onClick={_this.showModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

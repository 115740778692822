import {  axiosAjaxCall } from './common.service'

export const associationService = {
    getAssociation,
    getIndiviualType,
    addIndiviualType,
    deleteIndiviualType,
    addAssociation,
    deleteAssociation,
    changeStatusAssociation,
    getAssociationIndividual,
    addAssociationIndividual,
    getAssociationCompany,
    deleteAssociationLink,
    addAssociationCompany,
    getGroupType,
    addGroupType,
    deleteGroupType
    
}

function getAssociation(data) {
    return axiosAjaxCall("get_association", data);
}

function getIndiviualType(data) {
    return axiosAjaxCall("get_individual_type", data);
}

function addIndiviualType(data) {
    return axiosAjaxCall("add_individual_type", data);
}

function deleteIndiviualType(data) {
    return axiosAjaxCall("delete_individual_type", data);
}

function addAssociation(data) {
    return axiosAjaxCall("add_association", data);
}

function deleteAssociationLink(data) {
    return axiosAjaxCall("delete_association_link", data);
}

function deleteAssociation(data) {
    return axiosAjaxCall("delete_association", data);
}

function changeStatusAssociation(data) {
    return axiosAjaxCall("change_status_association", data);
}

function getAssociationIndividual(data) {
    return axiosAjaxCall("get_association_individual", data);
}

function addAssociationIndividual(data) {
    return axiosAjaxCall("add_association_individual", data);
}

function getAssociationCompany(data) {
    return axiosAjaxCall("get_association_company", data);
}

function addAssociationCompany(data) {
    return axiosAjaxCall("add_association_company", data);
}

function getGroupType(data) {
    return axiosAjaxCall("get_group_type", data);
}

function addGroupType(data) {
    return axiosAjaxCall("add_group_type", data);
}

function deleteGroupType(data) {
    return axiosAjaxCall("delete_group_type", data);
}
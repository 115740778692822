import React, { Component } from "react";
import {
  Button,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  ReputeIDCompanyInput,
  ReputeIDIndividualInput,
} from "../../../../components/Forms/Inputs";
import { connect } from "react-redux";
import { businessAccountsActions } from "../../../actions";
import { businessAccountsConstants } from './../../../constants/businessAccounts.constants';

class UpdateReputeID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: { repute_id: "" },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.clear = this.clear.bind(this);
    this.verify = this.verify.bind(this);

  }

  toggle(e) {
    if (e) {
      e.stopPropagation();
    }
    const { isEdit, current_data } = this.props;
    let { data } = this.state;

    data = { ...data, ...current_data };
    console.log(current_data);
    let isOpen = !this.state.isOpen;
    if (isOpen === true) {
      this.clear();
    }
    this.setState({ isOpen, data });
  }

  verify(){
    
    const { data } = this.state;
    const { dispatch, type, id } = this.props;

    let dataToSend = {
      id,
      type,
      repute_id: data.repute_id.replace(/-/g, ''),
    };
    dispatch(businessAccountsActions.getDataFromReputeId(dataToSend, {
        success : () => {
          
        }})
    );
 
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;
    const { dispatch } = this.props;

    data[name] = value;

    dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })
    this.setState(data);
  }

  save() {
    const { data } = this.state;
    const { dispatch, type, id, getData } = this.props;

    let dataToSend = {
      id,
      type,
      repute_id: data.repute_id.replace(/-/g, ''),
    };
    dispatch(businessAccountsActions.saveReputeId(dataToSend, {
        success : () => {
            this.toggle();
            getData()
    dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })

        }})
    );
  }

  
  clear() {
    let { data } = this.state;
    let { dispatch } = this.props;

    data.repute_id = "";
    dispatch({ type: businessAccountsConstants.GET_DATA_FROM_RI_FAILURE })

    this.setState(data);
  }

  render() {
    const { data, error } = this.state;
    const { Heading, type, repute_id, reputeid_data } = this.props;

    console.log(reputeid_data);
    
    return (
      <React.Fragment>
        &nbsp;
        {repute_id ? 
        <small>{repute_id}</small> :
        <React.Fragment>&nbsp;
        <button
          className="btn helpLink"
          style={{ fontSize: 12 }}
          onClick={(e) => this.toggle(e)}
        >
          Update Repute ID
        </button>

        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>{Heading}</ModalHeader>
          <ModalBody>
            <InputGroup>
              {type === "company" ? (
                <ReputeIDCompanyInput
                  type="text"
                  name="repute_id"
                  placeholder={`Company Repute ID`}
                  value={data.repute_id}
                  handleChange={this.handleChange}
                />
              ) : (
                <ReputeIDIndividualInput
                  type="text"
                  name="repute_id"
                  value={data.repute_id}
                  placeholder={`Individual Repute ID`}
                  handleChange={this.handleChange}
                />
              )}
            </InputGroup>

            {reputeid_data && (type === "company" ? <React.Fragment>
            
            <span style={{margin:5, }}> Company found :  <b>{reputeid_data.company_name}</b>  </span> 
            <div  style={{margin:5, }}>
              <b>Individuals</b>
            </div>
            {reputeid_data.individuals.map((each)=>{
              return  <div  style={{margin:5, }}>   {each.individual_name}({each.repute_id}) {each.is_owner && <span className="badge badge-success" > Owner</span>} </div>
            })}

            </React.Fragment> : <React.Fragment>
              <span style={{margin:5, }}> Individual found :  <b>{reputeid_data.aadhaar_name}</b>  </span> 
              </React.Fragment>)}
          </ModalBody>
          <ModalFooter>
            {reputeid_data ? 
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button> : 
            <Button color="primary" onClick={() => this.verify()}>
              verify
            </Button>}
            <Button color="danger" onClick={() => this.toggle()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        </React.Fragment>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getDataFromReputeId;
    return {
        loading, 
        reputeid_data : data
    }
};

const connected = connect(mapStateToProps)(UpdateReputeID);
export { connected as UpdateReputeID };
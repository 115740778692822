import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { accountAction } from "../../actions";
import { TableLoader } from "../../../components/Loaders";
import swal from "sweetalert";
import { DataTable } from "../../../components/Tables/DataTable";
import filterFactory from "react-bootstrap-table2-filter";
import { show, hide } from "redux-modal";
import { UpdateChequeDetail } from "./UpdateChequeDetail";
class ChequeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.dropdowntoggle = this.dropdowntoggle.bind(this);
    this.getChequeDetail = this.getChequeDetail.bind(this);

    this.renderAction = this.renderAction.bind(this);
  }

  dropdowntoggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  componentDidMount() {
    this.getChequeDetail();
  }
  getChequeDetail() {
    const { dispatch } = this.props;
    dispatch(accountAction.getChequeDetail());
  }
  updateChequeDatail(row) {
    const { dispatch } = this.props;
    dispatch(
      show("bootstrap", {
        ModelSize: "md",
        ModelHeaderText: "Cheque Detail Verification",
        ModelBodyText: (
          <UpdateChequeDetail
            data={row}
            getChequeDetail={this.getChequeDetail}
          />
        ),
        isFooter: false,
      })
    );
  }

  afterAction() {
    this.getChequeDetail();
  }
  renderAction(cell, row, rowIndex, formateExtraData) {
    return (
      <Button
        size="sm"
        color="primary"
        onClick={() => this.updateChequeDatail(row)}
      >
        {" "}
        Update{" "}
      </Button>
    );
  }
  renderActive(cell, row, rowIndex, formateExtraData) {
    return parseInt(row.is_active, 6) === 1 ? "Yes" : "No";
  }

  renderRI(cell, row, rowIndex, formateExtraData) {
    return (
      <React.Fragment>
        {cell} &nbsp;{" "}
        <i
          className="fa fa-info-circle text-info cursor-pointer"
          id={"tooltip_userinfo_" + row.id}
        ></i>
        <UncontrolledTooltip
          placement="top"
          target={"tooltip_userinfo_" + row.id}
        >
          <div className="row text-left">
            <div className="col-md-12 font-weight-bold "> Company Name </div>
            <div className="col-md-12 mb-2">{row.company_name}</div>
           
                <div className="col-md-12 font-weight-bold "> Individual Name </div>
                <div className="col-md-12 mb-2">{row.individual_name}</div>
                
                <div className="col-md-12 font-weight-bold "> Mobile Number </div>
                <div className="col-md-12 mb-2">{row.individual_mobile}</div>
              
          </div>
        </UncontrolledTooltip>
      </React.Fragment>
    );
  }
  renderNote(cell, row, rowIndex, formateExtraData) {
    return (
      <React.Fragment>
        {row.cheque_status ? (
          <React.Fragment>
            <i
              className={"fas fa-info-circle cursor-pointer  text-danger"}
              id={"tooltip_permission" + row.id}
            ></i>
            <UncontrolledTooltip
              placement="top"
              target={"tooltip_permission" + row.id}
            >
              {
                <div className="row text-left">
                  <div className="col-md-12 font-weight-bold "> Status </div>
                  <div className="col-md-12 mb-2">{row.cheque_status}</div>
                  {row.notes ? (
                    <React.Fragment>
                      <div className="col-md-12 font-weight-bold "> Note </div>
                      <div className="col-md-12 mb-2">{row.notes}</div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              }
            </UncontrolledTooltip>
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
  render() {
    const { loading, data } = this.props;
    const columns = [
      {
        text: "Reute ID",
        dataField: "formated_repute_id",
        sort: true,
        formatter: this.renderRI,
      },
      {
        text: "Date",
        dataField: "date",
        sort: true,
      },
      {
        text: "Amount",
        dataField: "amount",
      },
      {
        text: "Bank Name",
        dataField: "bank_name",
      },
      {
        text: "Account Holder Name",
        dataField: "account_holder_name",
      },
      {
        text: "Account Number",
        dataField: "account_number",
      },
      {
        text: "Cheque Number",
        dataField: "cheque_number",
      },
      {
        text: "Cheque Amount",
        dataField: "cheque_amount",
      },
      {
        text: "",
        dataField: "",
        formatter: this.renderNote,
      },
      {
        text: "Action",
        dataField: "action",
        formatter: this.renderAction,
      },
    ];
    return (
      <div>
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                {" "}
                <i className="icon-directions"></i> <b>Cheque Details</b>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <TableLoader />
                ) : (
                  <div>
                    <DataTable
                      keyField="id"
                      data={data || []}
                      columns={columns}
                      filter={filterFactory()}
                      noDataIndication={"No data found"}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getChequeDetail;
  return {
    loading,
    data,
  };
}

const connectedChequeDetail = connect(mapStateToProps)(ChequeDetail);
export { connectedChequeDetail as ChequeDetail };

import { notify } from 'reapop'
import { notificationConstants } from '../constants'
import { notificationService } from '../services'

export const notificationActions = {
    addNotification,
    getNotifications,
    editNotification,
    deleteNotification,
    publishNotification
}

function addNotification(data) {
    return dispatch => {
        dispatch(request({ data }));

        notificationService.addNotification(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: notificationConstants.ADD_NOTIFICATION_REQUEST } }
    function success(result) { return { type: notificationConstants.ADD_NOTIFICATION_SUCCESS, result } }
    function failure(error) { return { type: notificationConstants.ADD_NOTIFICATION_FAILURE, error } }
}

function getNotifications(data) {
    return dispatch => {
        dispatch(request())

        notificationService.getNotifications(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: notificationConstants.GET_NOTIFICATIONS_REQUEST } }
    function success(result) { return { type: notificationConstants.GET_NOTIFICATIONS_SUCCESS, result } }
    function failure(error) { return { type: notificationConstants.GET_NOTIFICATIONS_FAILURE, error } }
}

function editNotification(data,ref) {
    return dispatch => {
        dispatch(request())

        notificationService.editNotification(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Updated Successfully', status: "success" }))
                    ref.afterSave()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: notificationConstants.EDIT_NOTIFICATION_REQUEST } }
    function success(result) { return { type: notificationConstants.EDIT_NOTIFICATION_SUCCESS, result } }
    function failure(error) { return { type: notificationConstants.EDIT_NOTIFICATION_FAILURE, error } }
}

function deleteNotification() {
    return dispatch => {
        dispatch(request())

        notificationService.deleteNotification()
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: notificationConstants.DELETE_NOTIFICATION_REQUEST } }
    function success(result) { return { type: notificationConstants.DELETE_NOTIFICATION_SUCCESS, result } }
    function failure(error) { return { type: notificationConstants.DELETE_NOTIFICATION_FAILURE, error } }
}

function publishNotification(data,ref) {
    return dispatch => {
        dispatch(request())
        notificationService.publishNotification(data)
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Published Successfully', status: "success" }))
                    ref.getNotifications()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: notificationConstants.PUBLISH_NOTIFICATION_REQUEST } }
    function success(result) { return { type: notificationConstants.PUBLISH_NOTIFICATION_SUCCESS, result } }
    function failure(error) { return { type: notificationConstants.PUBLISH_NOTIFICATION_FAILURE, error } }
}
import { notify } from 'reapop'
import { InquiriesConstants } from '../constants'
import { InquiriesService } from '../services'

export const InquiriesAction = {
    getInquiries,
}


function getInquiries(data) {
    return dispatch => {
        dispatch(request())

        InquiriesService.getInquiries(data)
            .then(
                verified =>{
                    console.log(`verified`);
                    console.log(verified);
                     dispatch(success(verified))
                    },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type:  InquiriesConstants.GET_INQUIRIES_REQUEST } }
    function success(result) { return { type:  InquiriesConstants.GET_INQUIRIES_SUCCESS, result } }
    function failure(error) { return { type:  InquiriesConstants.GET_INQUIRIES_FAIL, error } }
}

import { showLoader } from "../../helpers";
import { axiosAjaxCall } from './common.service';

export const whatsappLogService = {
    getWhatsappReceivedLogs,
    getWhatsappSendLogs,
    getWhatsappScheduleLogs,
    getWhatsappSendLogsRi,

}



function getWhatsappSendLogsRi(data) {
    showLoader();
    return axiosAjaxCall("get_whatsapp_sent_log_reputeinfo", data);
}

function getWhatsappReceivedLogs(data) {
    showLoader();
    return axiosAjaxCall("get_whatsapp_receive_logs", data);
}
function getWhatsappSendLogs(data) {
    showLoader();
    return axiosAjaxCall("get_whatsapp_sent_log", data);
}
function getWhatsappScheduleLogs(data) {
    showLoader();
    return axiosAjaxCall("get_whatsapp_schedules", data);
}
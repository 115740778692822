import { notify } from 'reapop'
import { communicationMastersTemplatesService } from '../services'
import { communicationMastersTemplatesConstants } from '../constants'

export const communicationMastersTemplatesActions = {
    saveCommunicationMastersTemplates,
    getCommunicationMastersTemplates
}



function getCommunicationMastersTemplates(data) {
    return dispatch => {
        dispatch(request())

        communicationMastersTemplatesService.getCommunicationMastersTemplates(data)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_REQUEST } }
    function success(result) { return { type: communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_SUCCESS, result } }
    function failure(error) { return { type: communicationMastersTemplatesConstants.GET_COMM_MASTERS_TEMP_DATA_FAILURE, error } }
}

function saveCommunicationMastersTemplates(data, callback) {
    return dispatch => {
        dispatch(request())

        communicationMastersTemplatesService.saveCommunicationMastersTemplates(data)
            .then(
                data => {
                    dispatch(success(data))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: communicationMastersTemplatesConstants.SAVE_COMM_MASTERS_TEMP_DATA_FAILURE } }
    function success(result) { return { type: communicationMastersTemplatesConstants.SAVE_COMM_MASTERS_TEMP_DATA_SUCCESS, result } }
    function failure(error) { return { type: communicationMastersTemplatesConstants.SAVE_COMM_MASTERS_TEMP_DATA_FAILURE, error } }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Form,  FormGroup } from 'reactstrap';
import { withRouter } from 'react-router';
import { customerDetailAction } from "../../../actions";
import swal from 'sweetalert';
import { show, hide } from 'redux-modal';
import { Mobile } from './mobile';
import { handleMobileValidation } from './MobileValidation';

class UpdateMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                mobile: []
            },
            errors: {},
        }
        this.handleChildValue = this.handleChildValue.bind(this)
        this.handelUpdateMobile = this.handelUpdateMobile.bind(this)
        this.afterAction = this.afterAction.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handelCansel = this.handelCansel.bind(this)
    }
    toggle() {
        const { onClose } = this.props
        onClose()
    }
    handleChildValue(data) {
        const { formData } = this.state
        const { name, value } = data;
        formData[name] = value;
        this.setState({ formData })
    }

    handelCansel() {
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"))
    }

    afterAction() {
        const { dispatch, getCustomerList } = this.props;
        dispatch(hide("bootstrap"))
        getCustomerList()
    }

    handelUpdateMobile(e) {
        e.preventDefault();
        const { formData } = this.state;
        const { dispatch } = this.props;
        const result = handleMobileValidation(formData)
        this.setState({ errors: result.errors })
            if (result.formIsValid) {
                swal({
                    title: "Are you sure ?",
                    text: "Do you want to update mobile?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((confirm) => {
                    if (confirm) {
                        formData.type = 'mobile'
                        dispatch(customerDetailAction.updateCustomerDetilEmail(formData, this));
                    }
                })
            }

        
        // else {
        //     dispatch(notify({ position: "tc", dismissible: false, message: '', status: "error" }))

        // }

    }
    componentDidMount() {
        const { customerDetail } = this.props;
        let { formData } = this.state;
        if (customerDetail) {
            formData.id = customerDetail.id
            formData.mobile = customerDetail.mobile ? customerDetail.mobile.split(',') : []
            this.setState({ formData })
        }

    }
    render() {
        const { errors, formData } = this.state
        return (

            <React.Fragment>
                <Form autocomplete="off">
                    <Row>
                        <Col md="12">
                            <FormGroup >
                            <Mobile handleChildValue={this.handleChildValue} errors={errors["mobile"]} mobileData={formData.mobile} />
                            </FormGroup>
                        </Col>
                    </Row><hr />
                    <button className="btn btn-secondary btn-sm pull-right" onClick={this.handelCansel} style={{'marginLeft':'10px'}} > Cancel </button>
                    <button  onClick={this.handelUpdateMobile} className="btn btn-primary btn-sm pull-right"  > Update </button>
                </Form>
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    const { loading, data, customerDetail } = state.getCustomerStatusData
    return {
        loading,
        data,
        customerDetail
    }
}

const connectedUpdateMobile = withRouter(connect(mapStateToProps)(UpdateMobile))
export { connectedUpdateMobile as UpdateMobile }
import React, { Component } from "react";
import { accountAction } from "../../actions/account.action";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import { currencyFormat } from '../../../helpers';
export default class SubscriptionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
      errors: {}
    };
    this.showModal = this.showModal.bind(this);

  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan },() => {
      
    });
  }





  render() {
    const { modalOpan,  errors } = this.state;
    const { element } = this.props;

    let _this = this;
    return (
      <div>
        <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Subscription History">

          <Button onClick={_this.showModal} color="primary">
            <i class="fa fa-history"></i>
          </Button>
        </Tooltip>
        <Modal size="lg" className="modal-primary" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Subscription History<br />{" "}
            {/* <small>{ element.current_plan === "Premium" ? "Renew Premium plan" : "Upgrade from Basic to Premium plan" }</small> */}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="4">
                <Row>
                  <Col md="5" className="text-right mb-1">
                    Name of company :
                </Col>
                  <Col md="7" className="mb-1">{element.company_name}</Col>

                  <Col md="5" className="text-right mb-1">
                    Repute ID :
                </Col>
                  <Col md="7" className="mb-1">{element.formated_repute_id}</Col>
                  <Col md="5" className="text-right mb-1">
                  Registered Date :
                </Col>
                  <Col md="7" className="mb-1">{element.created_at}</Col>
                  
                </Row>
              </Col>
              <Col md="4">
                <Row>
                <Col md="5" className="text-right  mb-1">
                    Subscription Plan :
                </Col>
                  <Col md="7" className="mb-1">{element.current_plan}</Col>
                  <Col md="5" className="text-right">
                  Remaining SMS  :
                </Col>
                  <Col md="7" >{element.total_sms}</Col>
                </Row>
              </Col>

              <Col md="4">
                <Row>
                  <Col md="6" className="text-right mb-1">
                    Plan Start Date :
                </Col>
                  <Col md="6">{element.formated_plan_start_date}</Col>



                  <Col md="6" className="text-right  mb-4">
                     Expiry Date :
                </Col>
                  <Col md="6" className="mb-1">{element.formated_expiry_date}</Col>

                </Row>

              </Col>
            </Row>
            <br />
            <Label><strong>Subscription Details</strong></Label>
            <div className="table-responsive-sm  animated fadeIn">
            <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
              <thead class="thead-light">
                <tr>
                  <th>OrderID </th>
                  <th>Plan Title</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>SMS Given</th>
                  <th>Amount  <br/><small>(exc. GST) </small></th>
                  <th>Total Amount <br/><small>(inc. GST) </small></th>
                  <th>Created at</th>
                </tr>
                {
                    element.subscription_data && element.subscription_data.length ?
                    element.subscription_data.map((each) => {
                        return (
                            <tr>
                                <td>{each.order_id}</td>
                                <td>{element.current_plan}</td>
                                <td>{each.start_date}</td>
                                <td>{each.end_date}</td>
                                <td>{each.total_sms}</td>
                                <td>{each.amount}</td>
                                <td>{each.total_amount}</td>
                                <td>{each.invoice_date}</td>
                            </tr>
                        )
                    })
                    : (
                        <tr>
                            <td colSpan="4" className="text-center">No Data Found</td>
                        </tr>
                    )
                }
              </thead>
            </table>
            </div>


            <br />
            <Label><strong>SMS Recharges</strong></Label>
            <div className="table-responsive-sm  animated fadeIn">
            <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
              <thead class="thead-light">
                <tr>
                  <th>OrderID </th>
                  <th>SMS Given </th>
                  <th>Amount  <br/><small>(exc. GST) </small></th>
                  <th>Total Amount <br/><small>(inc. GST) </small></th>
                  <th>Created at</th>
                </tr>
                {
                    element.sms_data && element.sms_data.length ?
                    element.sms_data.map((each) => {
                        return (
                            <tr>
                                <td>{each.order_id}</td>
                                <td>{each.total_sms}</td>
                                <td>{each.amount}</td>
                                <td>{each.total_amount}</td>
                                <td>{each.invoice_date}</td>
                            </tr>
                        )
                    })
                    : (
                        <tr>
                            <td colSpan="4" className="text-center">No Data Found</td>
                        </tr>
                    )
                }
              </thead>
            </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
              Proceed
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import config from 'react-global-configuration';
import { handleResponse, axiosAjaxCall } from './common.service'
import axios from 'axios';
import {  showLoader } from '../../helpers';

export const authService = {
    login,
    logout,
    register,
    changePassword,
    forgetpassword,
    saveNewPassword
};



/*
*  user login 
*/

function login(username, password) {
    var form_data = new FormData();

    form_data.append("login_id", username);
    form_data.append("password", password);
    

    return axios.post(`${config.get('apiUrl')}/login`, form_data)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', btoa(JSON.stringify(user)));
            }

            return user;
        });
}


/*
*  user logout 
*/

function logout() {
    
     // remove user from local storage to log user out
      
     axiosAjaxCall("logout",[]);
     localStorage.removeItem('user');
    
    // var form_data = new FormData();

    // return axios.post(`${config.get('apiUrl')}/logout`, form_data).then(handleResponse);

}


/*
*  Individual registration
*/
function register(user) {

    var form_data = new FormData();

    for (var key in user) {
        form_data.append(key, user[key]);
    }

    return axios.post(`${config.get('apiUrl')}/register/user`, form_data).then(handleResponse);
}


/*
*  Forget password
*/

function forgetpassword(data) {
    return axiosAjaxCall("forgot-password", data);
}


/*
*  Change password
*/

function changePassword(data) {
    return axiosAjaxCall("change-password", data);
}

/*
*  Forget password
*/

function saveNewPassword(data) {
    return axiosAjaxCall("reset-password", data);
}






export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    // if (!fields[`title`]) {
    //     formIsValid = false
    //     errors[`title`] = 'Can Not be empty'
    // }
    // if (!fields[`email_subject`]) {
    //     formIsValid = false
    //     errors[`email_subject`] = 'Can Not be empty'
    // }
    // if (!fields[`sms_text`]) {
    //     formIsValid = false
    //     errors[`sms_text`] = 'Can Not be empty'
    // }
    // if (!fields[`sms_flow_id`]) {
    //     formIsValid = false
    //     errors[`sms_flow_id`] = 'Can Not be empty'
    // }
    // if (!fields[`notification_title`]) {
    //     formIsValid = false
    //     errors[`notification_title`] = 'Can Not be empty'
    // }
    // if (!fields[`notification_text`]) {
    //     formIsValid = false
    //     errors[`notification_text`] = 'Can Not be empty'
    // }
    return { errors, formIsValid }
}
import { validPhone, validReputeIdIndividual, validEmail } from "../../../../helpers";
export const handleMobileValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (fields[`mobile`]) {
        errors[`mobile`] = [];
        fields[`mobile`].map( function( eachMobile,  index ) {
            if (!eachMobile) {
                formIsValid = false
                errors[`mobile`][index] = "This field is required."
            } else if (!validPhone(eachMobile)) {
                formIsValid = false
                errors[`mobile`][index]  =  "Enter valid mobile number ."
            }
            return false;
        })
    }
    return { errors, formIsValid }
}


export const InputTypes = {
    Text: "text",
    TextArea: "textarea",
    Checkbox: "checkbox",
    CheckList: "checklist",
    Radio: "radio",
    Dropdown: "dropdown",
    Heading: "heading",
    SubItem: "subitem",
};

export const InputTypeOptions = [
    { title: "Text", value: InputTypes.Text },
    { title: "Text Area", value: InputTypes.TextArea },
    { title: "Checkbox", value: InputTypes.Checkbox },
    { title: "Radio", value: InputTypes.Radio },
    // { title: "CheckList", value: InputTypes.CheckList },
    // { title: "Radio", value: InputTypes.Radio },
    // { title: "Dropdown", value: InputTypes.Dropdown },
    { title: "Heading", value: InputTypes.Heading },
    { title: "Sub Item", value: InputTypes.SubItem },
];

export const DataTypes = {
    TEXT : "text",
    NUMBER : "number",                          
    MOBILE : "mobile",
    EMAIL : "email",
    NAME : "name",
    NEGOTIATION_MONTHS : "negotiation_months",
    NEGOTIATION_SMS : "negotiation_sms",
    NEGOTIATION_AMOUNT : "negotiation_amount",
};


export const DataTypesOptions = [
    { title: "Text", value: DataTypes.Text },
    { title: "Mobile", value: DataTypes.MOBILE },
    { title: "Email", value: DataTypes.EMAIL },
    { title: "Name", value: DataTypes.NAME },

    { title: "Negotiation Months", value: DataTypes.NEGOTIATION_MONTHS },
    { title: "Negotiation SMS", value: DataTypes.NEGOTIATION_SMS },
    { title: "Negotiation Amount", value: DataTypes.NEGOTIATION_AMOUNT },
    
];

export const suggestions = [
    { id: 'name', name: "Individual Name" },
    { id: 'business_name', name: "Business Name" },
    { id: 'mobile', name: "Mobile" },
    { id: 'schedule_date_time', name: "Schedule Date Time" },
    { id: 'email', name: "Email" }
  ]

  
export const suggestionsPreview = [
    { id: '{name}', name: "John" },
    { id: '{business_name}', name: "ABC Company" },
    { id: '{mobile}', name: "9876543210" },
    { id: '{schedule_date_time}', name: "19-02-2020 10:00 AM" },
    { id: '{email}', name: "abc@xyz.in" }
  ]
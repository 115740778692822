import { notify } from 'reapop'
import { adminContentConfigConstants } from '../constants'
import { adminContentConfigService } from '../services'

export const adminContentConfigAction = {
    getAdminContentConfigtData,
    updateAdminContentConfigData
}

function getAdminContentConfigtData(data) {
    return dispatch => {
        dispatch(request({ data }));

        adminContentConfigService.getAdminContentConfigtData(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_REQUEST } }
    function success(result) { return { type: adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_SUCCESS, result } }
    function failure(error) { return { type: adminContentConfigConstants.GET_ADMIN_CONTENT_CONFIG_FAILURE, error } }
}

function updateAdminContentConfigData(data,ref) {
    return dispatch => {
        dispatch(request({ data }));

        adminContentConfigService.updateAdminContentConfigData(data)
            .then(
                response => {
                    dispatch(success(response));
                   // ref.afterSave()
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Update Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_REQUEST } }
    function success(result) { return { type: adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_SUCCESS, result } }
    function failure(error) { return { type: adminContentConfigConstants.UPDATE_ADMIN_CONTENT_CONFIG_FAILURE, error } }
}
export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`sector`]) {
        formIsValid = false
        errors[`sector`] = 'Please enter sector name'
    }

    return { errors, formIsValid }
}
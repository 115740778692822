export const emailConstants = {
    ADD_EMAIL_REQUEST: 'ADD_EMAIL_REQUEST',
    ADD_EMAIL_SUCCESS: 'ADD_EMAIL_SUCCESS',
    ADD_EMAIL_FAILURE: 'ADD_EMAIL_FAILURE',

    GET_EMAILS_REQUEST: 'GET_EMAIL_REQUEST',
    GET_EMAILS_SUCCESS: 'GET_EMAIL_SUCCESS',
    GET_EMAILS_FAILURE: 'GET_EMAIL_FAILURE',

    EDIT_EMAIL_REQUEST: 'EDIT_EMAIL_REQUEST',
    EDIT_EMAIL_SUCCESS: 'EDIT_EMAIL_SUCCESS',
    EDIT_EMAIL_FAILURE: 'EDIT_EMAIL_FAILURE',

    DELETE_EMAIL_REQUEST: 'DELETE_EMAIL_REQUEST',
    DELETE_EMAIL_SUCCESS: 'DELETE_EMAIL_SUCCESS',
    DELETE_EMAIL_FAILURE: 'DELETE_EMAIL_FAILURE',

    PUBLISH_EMAIL_REQUEST: 'PUBLISH_EMAIL_REQUEST',
    PUBLISH_EMAIL_SUCCESS: 'PUBLISH_EMAIL_SUCCESS',
    PUBLISH_EMAIL_FAILURE: 'PUBLISH_EMAIL_FAILURE',

    SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',
}
import { notify } from 'reapop'
import { associationConstants } from '../constants'
import { associationService } from '../services'

export const associationAction = {
    getAssociation,
    getIndiviualType,
    addIndiviualType,
    deleteIndiviualType,
    addAssociation,
    deleteAssociation,
    deleteAssociationLink,
    changeStatusAssociation,
    getAssociationIndividual,
    addAssociationIndividual,
    getAssociationCompany,
    addAssociationCompany,
    getGroupType,
    addGroupType,
    deleteGroupType
}

function getAssociation(data) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.getAssociation(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_ASSOCIATION_REQUEST } }
    function success(result) { return { type: associationConstants.GET_ASSOCIATION_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_ASSOCIATION_FAILURE, error } }
}

function getIndiviualType(data) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.getIndiviualType(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_INIVIUAL_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.GET_INIVIUAL_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_INIVIUAL_TYPE_FAILURE, error } }
}

function deleteAssociationLink(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.deleteAssociationLink(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_INIVIUAL_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.GET_INIVIUAL_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_INIVIUAL_TYPE_FAILURE, error } }
}

function addIndiviualType(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.addIndiviualType(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response);
                    dispatch(notify({ position: "tc", dismissible: false, message: data && data.id ? 'Upated Successfully' : 'Added Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.ADD_INIVIUAL_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.ADD_INIVIUAL_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.ADD_INIVIUAL_TYPE_FAILURE, error } }
}

function deleteIndiviualType(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.deleteIndiviualType(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response);
                    dispatch(notify({ position: "tc", dismissible: false, message:  'Deleted Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.DELETE_INIVIUAL_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.DELETE_INIVIUAL_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.DELETE_INIVIUAL_TYPE_FAILURE, error } }
}


function addAssociation(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.addAssociation(data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(notify({ position: "tc", dismissible: false, message: data && data.id ? 'Upated Successfully' : 'Added Successfully', status: "success" }))

                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.ADD_ASSOCIATION_REQUEST } }
    function success(result) { return { type: associationConstants.ADD_ASSOCIATION_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.ADD_ASSOCIATION_FAILURE, error } }
}

function deleteAssociation(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.deleteAssociation(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.DELETE_ASSOCIATION_REQUEST } }
    function success(result) { return { type: associationConstants.DELETE_ASSOCIATION_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.DELETE_ASSOCIATION_FAILURE, error } }
}

function changeStatusAssociation(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.changeStatusAssociation(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.CHANGE_STATUS_ASSOCIATION_REQUEST } }
    function success(result) { return { type: associationConstants.CHANGE_STATUS_ASSOCIATION_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.CHANGE_STATUS_ASSOCIATION_FAILURE, error } }
}




function getAssociationIndividual(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.getAssociationIndividual(data)
            .then(
                response => {
                    dispatch(success(response));
         //           callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_ASSOCIATION_INDIVIUAL_REQUEST } }
    function success(result) { return { type: associationConstants.GET_ASSOCIATION_INDIVIUAL_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_ASSOCIATION_INDIVIUAL_FAILURE, error } }
}

function addAssociationIndividual(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.addAssociationIndividual(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.ADD_ASSOCIATION_INDIVIUAL_REQUEST } }
    function success(result) { return { type: associationConstants.ADD_ASSOCIATION_INDIVIUAL_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.ADD_ASSOCIATION_INDIVIUAL_FAILURE, error } }
}

function getAssociationCompany(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.getAssociationCompany(data)
            .then(
                response => {
                    dispatch(success(response));
                   // callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_ASSOCIATION_COMPANY_REQUEST } }
    function success(result) { return { type: associationConstants.GET_ASSOCIATION_COMPANY_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_ASSOCIATION_COMPANY_FAILURE, error } }
}

function addAssociationCompany(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.addAssociationCompany(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.ADD_ASSOCIATION_COMPANY_REQUEST } }
    function success(result) { return { type: associationConstants.ADD_ASSOCIATION_COMPANY_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.ADD_ASSOCIATION_COMPANY_FAILURE, error } }
}


function getGroupType(data) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.getGroupType(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.GET_GROUP_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.GET_GROUP_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.GET_GROUP_TYPE_FAILURE, error } }
}

function addGroupType(data, callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.addGroupType(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.ADD_GROUP_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.ADD_GROUP_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.ADD_GROUP_TYPE_FAILURE, error } }
}

function deleteGroupType(data,callback) {
    return dispatch => {
        dispatch(request({ data }));

        associationService.deleteGroupType(data)
            .then(
                response => {
                    dispatch(success(response));
                    callback.success(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
    function request() { return { type: associationConstants.DELETE_GROUP_TYPE_REQUEST } }
    function success(result) { return { type: associationConstants.DELETE_GROUP_TYPE_SUCCESS, result } }
    function failure(error) { return { type: associationConstants.DELETE_GROUP_TYPE_FAILURE, error } }
}

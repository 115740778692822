import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, NavItem } from 'reactstrap'

class TermsOfuse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      TermsOfuseModel: false
    }

    this.TermsOfuseToggle = this.TermsOfuseToggle.bind(this)
  }

  TermsOfuseToggle(event) {
    event.preventDefault()
    const { TermsOfuseModel } = this.state
    this.setState({
      ...this.state,
      TermsOfuseModel: !TermsOfuseModel
    })
  }

  render() {
    return (
      <span>

        <NavItem className="d-md-down-none">
          <a href="/#" className="nav-link" onClick={this.TermsOfuseToggle}>  Terms of use </a>
        </NavItem>

        <Modal isOpen={this.state.TermsOfuseModel}  size={"lg"} toggle={this.TermsOfuseToggle} className={this.props.className}>
          <ModalHeader toggle={this.TermsOfuseToggle}>Terms of use</ModalHeader>
          <ModalBody>
            <p>Lorem ix`psum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p> <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p> <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.TermsOfuseToggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </span>
    )
  }
}

export default TermsOfuse

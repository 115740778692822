import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Process} from '../StageSetting/Stages/Process'

 class SupportTemplate extends Component {


    render() {
        return (
            <Process page_title="Support Process" onlyOne={true} type={"support"}/>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const connected = connect(mapStateToProps)(SupportTemplate)

export { connected as SupportTemplate }
import { notify } from 'reapop';
import { requirementLogsConstants } from '../constants';
import { requirementLogService } from '../services';

export const requirementLogsAction = {
    getRequirementLogs,
    addBuyerRequirementInvite,
    getBuyerRequirementInvites,
    getSellerDetail,
    getUnregisteredSellerDetail,
    updateRequirementLocation
}
function getBuyerRequirementInvites(data) {
    return dispatch => {
        dispatch(request());

        requirementLogService.getBuyerRequirementInvites(data)
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request() { return { type: requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.GET_BUYER_REQUIREMENT_INVITE_FAILURE, error } }
}

function getRequirementLogs(data) {
    return dispatch => {
        dispatch(request())

        requirementLogService.getRequirementLogs(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: requirementLogsConstants.GET_REQUIREMENT_LOG_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.GET_REQUIREMENT_LOG_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.GET_REQUIREMENT_LOG_FAILURE, error } }
}

function getSellerDetail(data) {
    return dispatch => {
        dispatch(request())

        requirementLogService.getSellerDetail(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: requirementLogsConstants.GET_SELLER_DETAIL_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.GET_SELLER_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.GET_SELLER_DETAIL_FAILURE, error } }
}
function addBuyerRequirementInvite(data, callback) {
    return dispatch => {
        dispatch(request());

        requirementLogService.addBuyerRequirementInvite(data)
            .then(
                response => {
                    dispatch(success(response))
                    callback.success()
                    //  dispatch(notify({ position: "tc", dismissible: false, message: 'Invitation added successfully', status: "success" }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }));
                }
            );
    };

    function request() { return { type: requirementLogsConstants.ADD_BUYER_REQUIREMENT_INVITE_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.ADD_BUYER_REQUIREMENT_INVITE_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.ADD_BUYER_REQUIREMENT_INVITE_FAILURE, error } }
}

function getUnregisteredSellerDetail(data) {
    return dispatch => {
        dispatch(request())

        requirementLogService.getUnregisteredSellerDetail(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.GET_UNREGISTERED_SELLER_DETAIL_FAILURE, error } }
}

function updateRequirementLocation(data,callback) {
    return dispatch => {
        dispatch(request())

        requirementLogService.updateRequirementLocation(data)
            .then(
                verified => {
                    dispatch(success(verified))
                    callback.success(verified)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_REQUEST } }
    function success(result) { return { type: requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_SUCCESS, result } }
    function failure(error) { return { type: requirementLogsConstants.UPDATE_REQUIREMMENT_LOCATION_FAILURE, error } }
}
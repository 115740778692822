import { findFirst } from "obj-traverse/lib/obj-traverse";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItemHeading,
  ListGroupItem,
  Button,
  CardBody,
  FormGroup,
  Input,
  Col,
  Row,
  Alert,
} from "reactstrap";
import { RenderForm } from "./RenderForm";
import { businessAccountsActions } from "../../../actions";
import { DataTypes } from "../../../../individual/constants/stages.constants";
import { validateItem } from "../../../../helpers/common";
import { InputDropDown } from "../../Settings/StageSetting/Components/FormElements";

const defaultData = {
  individual: "",
  json: "",
  notes: "",
}
class ProcessStage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: defaultData,
      errors: {},
    };

    this.save = this.save.bind(this);
    this.saveStageJSON = this.saveStageJSON.bind(this)
    this.validation = this.validation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  saveStageJSON(json) {
    const {data} = this.state;
    data.json = json;
    console.log('json ---- > ',json )
    this.setState({data});
  }


  save(save_type) {
    const { dispatch, getData, business_details_id } = this.props;
    const { data } = this.state;

    if (this.validation() === true) {
      return false;
    }

    let dataToSend = {
      save_type: save_type,
      notes: data.notes ? data.notes : "" ,
      json: typeof data.json === "string" ? data.json :  JSON.stringify(data.json),
      communicated_individual_ids: data.individual ?  data.individual:"",
      business_account_id: business_details_id,
    };

    let nagotiation_data = this.findNegotiationData(JSON.parse(dataToSend.json));
    if(nagotiation_data.found){
      dataToSend["negotiation_amount"] = nagotiation_data.negotiation_amount;
      dataToSend["negotiation_sms"] = nagotiation_data.negotiation_sms;
      dataToSend["negotiation_months"] = nagotiation_data.negotiation_months;
    }

    console.log(nagotiation_data);
// return false;

    dispatch(
      businessAccountsActions.saveProcessStage(dataToSend, {
        success: () => {
          this.setState({data:defaultData }, getData() )
        },
      })
    );
  }

  findNegotiationData(json){
   
    let result = {
      found : false,
      negotiation_amount: false,
      negotiation_months: false,
      negotiation_sms: false
    }

    let foundItemMonth = findFirst({"children":json}, "children", {
      dataType: DataTypes.NEGOTIATION_MONTHS,
    });
    
    if(foundItemMonth) {
      result.negotiation_months =  foundItemMonth.value;
    }

    let foundItemSms = findFirst({"children":json}, "children", {
      dataType: DataTypes.NEGOTIATION_SMS,
    });

    if(foundItemSms) {
      result.negotiation_sms =  foundItemSms.value;
    }
    
    let foundItemAmount = findFirst({"children":json}, "children", {
      dataType: DataTypes.NEGOTIATION_AMOUNT,
      
    });

    if(foundItemAmount) {
      result.negotiation_amount =  foundItemAmount.value;
    }

  
    result.found =  result.negotiation_sms !== false && result.negotiation_months !== false && result.negotiation_amount !== false;

    return result

  }

  validation() {
    const { data } = this.state;

    let errorsList = [];

    let Items = typeof data.json === "string" ? JSON.parse(data.json) : data.json ;

    Items.map(( eachItem) =>{
       validateItem(eachItem, errorsList)
    })

    this.setState({
      errors : errorsList
    });

    return errorsList.length > 0 ? true : false;;
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState(data);
  }

   componentWillReceiveProps(props) {
     const {currentStageJson} = props
     if(currentStageJson ){
      const { data } = this.state;

      data.individual =  currentStageJson.communicated_individual_ids;
      data.notes =  currentStageJson.notes ? currentStageJson.notes:"" ;
      data.json =  currentStageJson.json ? currentStageJson.json: "";

      this.setState({ data })
      
    }  else {
      this.setState({ data:defaultData })

     }
   }

   
   componentDidMount() {
    const {currentStageJson} = this.props
    if(currentStageJson){
     const { data } = this.state;

     data.individual =  currentStageJson.communicated_individual_ids;
     data.notes =  currentStageJson.notes ? currentStageJson.notes:"" ;
     data.json =  currentStageJson.json ? currentStageJson.json: "";

     this.setState({ data })
      
    } else {
      this.setState({ data:defaultData })

     }
  }

  render() {
    const { currentStageJson, current_stage } = this.props;

    const { individuals } = this.props;
    const { data, errors } = this.state;

    let individualOptions = [{ title: "Select Individual", value: "" }];

    if (individuals) {
      individuals.map((individual) => {
        let individual_id = individual.individual_id;
        let foundItem = findFirst(individual, "children", {
          dataType: "name",
        });
        let name = "name not specified";
        if (foundItem) {
          name = foundItem.value ? foundItem.value : name;
        }
        individualOptions.push({ value: individual_id, title: name });
      });
    }
    let _this = this;

    return (
      <Card>
        <CardHeader>
          <Row>
            <Col md="6">
              <h5>Stages</h5>
            </Col>
            <Col md="6">
            {currentStageJson != false && currentStageJson && currentStageJson.type === 1 && 
              <InputDropDown
                noMargin={true}
                title={false}
                name={"individual"}
                // placeholder={data.title}
                error={errors.individual}
                value={data.individual}
                onChange={this.handleChange}
                options={individualOptions}
                maxLength="250"
            /> }
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {currentStageJson != false && currentStageJson && currentStageJson.type === 1? (
            <React.Fragment>
              <ListGroup>
                {currentStageJson && (
                  <ListGroupItemHeading>
                    {currentStageJson.process_name} &nbsp;{" "}
                    <i className="fa fa-angle-right"></i> &nbsp;{" "}
                    {currentStageJson.stage_name}
                  </ListGroupItemHeading>
                )}

                {current_stage && current_stage.length > 0 ? (
                  <ListGroupItem>
                    <RenderForm
                      data={data.json}
                      saveStageJSON={_this.saveStageJSON}
                    />
                  </ListGroupItem>
                ) : (
                  "No Stages Available"
                )}

                <FormGroup>
                  <label>
                    <b>Notes</b>
                  </label>
                  <Input
                    onChange={this.handleChange}
                    name="notes"
                    placeholdeer="notes"
                    value={data.notes}
                    type="textarea"
                  />
                </FormGroup>
              </ListGroup>
              
              {errors && errors.length
              ? errors.map((eachText) => {
                  return <Alert color="danger">{eachText}</Alert>;
                })
              : false}

              <Button
                style={{ marginTop: "30px" }}
                color="primary"
                onClick={() => this.save("save")}
              >
                Save
              </Button>
              &nbsp;
              <Button
                style={{ marginTop: "30px" }}
                color="primary"
                onClick={() => this.save("repeat")}
              >
                Complete and Repeat
              </Button>
              &nbsp;
              <div 
                className="pull-right">
                
              <Button
                style={{ marginTop: "30px" }}
                color="success"
                onClick={() => this.save("complete")}
              >
                Complete and Next
              </Button>
              
              &nbsp;
              
              <Button
                className="pull-right"
                style={{ marginTop: "30px" }}
                color="danger"
                onClick={() => this.save("close")}
              >
                Complete and Close
              </Button>
              </div>
            </React.Fragment>
          ) : (
            "No Stage Available"
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

const connected = connect(mapStateToProps)(ProcessStage);
export { connected as ProcessStage };

import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from 'react-global-configuration';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { expenceStatementActions } from '../../actions';
import { TableLoader } from '../../../components/Loaders';
import { AddDepositDetail } from './AddDepositDetail'
import { Tooltip } from 'react-tippy';
import { currencyFormat } from '../../../helpers';
class PayoutDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                tag_name: '',
            },
            selectedTags: [],
            isEdit: false,
            errors: {}
        }
        this.handelToggle = this.handelToggle.bind(this)
        this.getPayoutDetails = this.getPayoutDetails.bind(this)
        this.getTotalAmount = this.getTotalAmount.bind(this)
    }
    handelToggle() {
        const { isEdit } = this.state
        const { getExpenceStatement } = this.props
        this.setState({
            isEdit: !isEdit
        }, () => {
            if (!isEdit == false) {
                getExpenceStatement()
            } else {
                this.getPayoutDetails();
            }
        })
    }
    getPayoutDetails() {
        const { dispatch, commissionData } = this.props
        let dataToSend = {
            commisssion_calculation_id: commissionData.id
        }
        dispatch(expenceStatementActions.getPayoutDetails(dataToSend));
    }
    getTotalAmount() {
        const { loading, data } = this.props

        let total_commission_amount = 0;


        data && data.map((eachData) => {
            total_commission_amount = total_commission_amount + parseFloat(eachData.commission_amount, 10);
        })

        return currencyFormat(total_commission_amount)
    }
    render() {
        const { loading, data, commissionData } = this.props
        const { isEdit } = this.state
        let _this = this

        return (<React.Fragment>

            <button className="btn btn-danger btn-sm " onClick={this.handelToggle}> Payout Details</button>
 
            <Modal isOpen={isEdit} size={"lg"} className="modal-danger" toggle={this.handelToggle}    >
                <ModalHeader toggle={this.handelToggle} >
                    {"Payout Details"}
                    <br />
                    <small>{commissionData.calculate_from_date}	-  {commissionData.calculate_till_date} </small>

                </ModalHeader>
                <ModalBody style={{ minHeight: '500px' }}>
                    <div className="row">
                        {
                            loading ? <TableLoader /> :

                                <div className="col-12">
                                    <a className='btn btn-danger pull-right mb-2' target='_blank' href={`${config.get('apiUrl')}/commission-payout-export/${commissionData.id}`}> Export</a>


                                    <table className="table table-sm table-condensed table-outline">
                                        <thead>
                                            <tr>
                                                {/* <th>User Name</th> */}
                                                <th>User Name</th>
                                                <th>Commisssion Amount</th>
                                                <th>Deposited</th>
                                                <th>Deposit Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data && data.length ? (
                                                data.map((eachData) => {
                                                    return (
                                                        <tr>
                                                            {/* <td>{eachData.user_name}</td> */}
                                                            <td>{eachData.user_name}
                                                            {eachData.is_bank_linked ? false :
                                                            <React.Fragment>
                                                                &nbsp; &nbsp;
                                                                  <i className='fa fa-mobile text-primary'></i> {eachData.mobile}
                                                                <Tooltip
                                                                    className="btn m-0 p-0 ml-1 mr-1"
                                                                    interactive
                                                                    arrow={true}
                                                                    title="Bank details not added"  ><i className='fa fa-bank text-danger'></i> </Tooltip>
                                                                    
                                                            </React.Fragment> }
                                                            </td>
                                                            <td>₹ {eachData.commission_amount}</td>
                                                            <td>{eachData.is_deposited ?
                                                                <React.Fragment>
                                                                    Yes
                                                                    <Tooltip
                                                                        className="btn m-0 p-0 ml-1 mr-1"
                                                                        interactive
                                                                        arrow={true} html={
                                                                            <React.Fragment>
                                                                                <table style={{ width: "300px", 'textAlign': "left", 'fontSize': '13px' }}>
                                                                                    <tr>
                                                                                        <th width="50%">Payment Receipt</th>
                                                                                        <td>{eachData.receipt_number}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th width="50%">Payment Type</th>
                                                                                        <td>{eachData.payment_type}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th width="50%">Reference Number</th>
                                                                                        <td>{eachData.reference_number}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th width="50%">Remarks</th>
                                                                                        <td>{eachData.remarks ? eachData.remarks : "-"}</td>
                                                                                    </tr>

                                                                                </table>
                                                                            </React.Fragment>}>
                                                                        <i className='fa fa-info-circle text-primary'></i>
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                                : 'No'}</td>
                                                            <td>{eachData.deposited_date ? eachData.deposited_date : '-'}</td>
                                                            <td><AddDepositDetail PayoutDetails={eachData} getData={_this.getPayoutDetails} is_disable={eachData.is_deposited ? true : false} bank_account_details={eachData.user_bank_account_detail} /></td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                {/* <th></th> */}
                                                <th>Total</th>
                                                <th>₹  {this.getTotalAmount()}</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                        }
                    </div>
                </ModalBody>
            </Modal>



        </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getPayoutDetails
    return {
        loading,
        data
    }
}

const PayoutDetailsconnected = connect(mapStateToProps)(PayoutDetails);
export { PayoutDetailsconnected as PayoutDetails }
import {  axiosAjaxCall } from './common.service'
import { showLoader } from '../../helpers';

export const supportService = {
    searchCustomer,
    verifySupportPIN,
    createTicket,
    getTicketDetails,
    saveTicketMessage,
    getTicketList,
    changeTicketStatus
}


function searchCustomer(data) {
    showLoader();
    return axiosAjaxCall("search_customer", data);
}

function verifySupportPIN(data) {
    return axiosAjaxCall("verify_support_pin", data);
}

function createTicket(data) {
    showLoader();
    
    if(data.ticket_id) {
        return axiosAjaxCall("update_ticket_detail", data);
    }
    return axiosAjaxCall("create_ticket", data);
}

function getTicketDetails(data) {
    showLoader();
    return axiosAjaxCall("get_ticket", data);
}

function saveTicketMessage(data) {
    showLoader();
    return axiosAjaxCall("add_ticket_comment", data);
}

function changeTicketStatus(data) {
    showLoader();
    return axiosAjaxCall("update_ticket_status", data);
}

function getTicketList(data) {
    showLoader();
    return axiosAjaxCall("get_ticket_by_status", data);
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, Button, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import {TabContentData} from './TabContentData'
import classnames from 'classnames'
import {expenceStatementActions} from '../../actions';
const TABS = {
    CommissionPayout: "CommissionPayout",
    Tab2: "Tab2",
  }
class ExpenceStatement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: TABS.CommissionPayout,
        }
        this.handleChangeTab = this.handleChangeTab.bind(this);

    }
    handleChangeTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          }, function () {
           // this.getExpenceStatement();
          })
        }
    }
    
    render() {
//      const { loading, data } = this.props
        return (
            <div>
              <Card>
                <CardHeader>
                  <b><i className='fa fa-money'></i> &nbsp;  Expense Statement</b>
                </CardHeader>
                <CardBody>
      
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.CommissionPayout })}
                        onClick={() => { this.handleChangeTab(TABS.CommissionPayout) }}
                      >
                        <b>Commission Payout</b>
      
                      </NavLink>
                    </NavItem> 
                    <NavItem>
      
                      {/* <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.Tab2 })}
                        onClick={() => { this.handleChangeTab(TABS.Tab2) }}
                      >
                        <b>Tab2</b>
      
                      </NavLink> */}
                    </NavItem>
      
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={TABS.CommissionPayout}>
                      <TabContentData activeTab={this.state.activeTab}   />
                    </TabPane>
                    <TabPane tabId={TABS.Tab2}>
                      {/* <TabContentData  activeTab={this.state.activeTab}  /> */}
                      <h1>Tab2</h1>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          );
    }
}

function mapStateToProps(state) {
  return {}
}

const ExpenceStatementConnected = connect(mapStateToProps)(ExpenceStatement);
export { ExpenceStatementConnected as ExpenceStatement }
import moment from 'moment';
import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tippy';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { currencyFormat } from '../../../helpers';
import Address from '../../../helpers/Address';
import { CriticalDue } from '../../actions';

import DocumentPreview from '../Verification/Company/Comman/DocumentsPreview';

const VisibilityStatus = ({
  visibility,
  type,
  title,
  icon
}) => {
  return visibility === type ? <React.Fragment>

    <Tooltip
      title={title}
    >
      <i className={icon + " mx-2"} style={{ fontSize: "18px" }}></i>
    </Tooltip>
  </React.Fragment> : false
}

class TabContentData extends Component {
  constructor(props) {
    super(props)
    let days = new Date().getDate() - 1;

    this.state = {
      startDate: moment().subtract(days, 'days'),
      endDate: moment(),
      serachField: {
        dateRange: ''
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      keywords: '',

      tooltipOpen: false,
      collapse: [],
      page: {
        activePage: 1,
        perPage: 20,
        totalItemsCount: 0
      },
      activeTab: "",
      isModalShow: false,
      modalData: [],
      isDateChanged: false

    }

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }
  getData() {
    const { dispatch } = this.props;
    const { page, keywords, startDate, endDate, isDateChanged } = this.state;

    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
      keywords: keywords,

    }
    if (isDateChanged) {
      dataToSend[`start_date`] = startDate.format('YYYY-MM-DD');
      dataToSend[`end_date`] = endDate.format('YYYY-MM-DD')
    }


    dispatch(CriticalDue.getDirectCriticalDue(dataToSend));
  }
  toggle(index) {
    this.setState({
      collapse: {
        [index]: !this.state.collapse[index]
      }
    });
  }
  componentWillReceiveProps(props) {
    let { page, activeTab } = this.state
    page.totalItemsCount = 0;
    if (props.data) {
      page.totalItemsCount = props.data.count

    }
    if (props.activeTab) {
      activeTab = props.activeTab
    }
    this.setState({
      page,
      activeTab
    })
  }
  handlePageChange(pageNumber) {
    const { page } = this.state;
    page.activePage = pageNumber;
    this.setState({ page }, () => this.getData());

  }
  handleToggle = (data, isOpened) => {
    const { isModalShow, modalData } = this.state

    let stateData = {}
    if (isOpened) {
      stateData[`modalData`] = [data]
    }
    stateData[`isModalShow`] = !isModalShow


    this.setState({
      ...stateData
    })

  }

  handleEvent(event, picker) {
    var start = picker.startDate.format("DD MMM Y");
    var end = picker.endDate.format("DD MMM Y");
    let label = start + ' to ' + end;
    if (start === end) {
      label = start;
    }
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      serachField: {
        dateRange: label
      },
      errors: {},
      isDateChanged: true
    })
  }

  handleFetch() {

    this.setState({
      active_page: 1,
    }, () => this.getData())
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({ ...this.state, [name]: value })
  }

  reset() {
    let days = new Date().getDate() - 1;

    this.setState({
      ...this.state,
      startDate: moment().subtract(days, 'days'),
      endDate: moment(),
      serachField: {
        dateRange: ''
      },
      keywords: '',
      isDateChanged: false
    }, () => this.getData())
  }

  componentDidMount() {

    const { page } = this.state
    let dataToSend = {
      active_page: page.activePage,
      per_page: page.perPage,
    }

    this.getData(dataToSend);
  }

  render() {
    const { data, loading, isSuperAdmin } = this.props
    const { collapse, page, isModalShow, modalData, keywords } = this.state
    let _this = this;
    console.log(data);
    let _beddebtdata = data && data.baddebt_data


    return (loading ? <TableLoader /> :
      <React.Fragment>

        <div className='d-flex mb-2' >
          <span>
            <Label>
              <b>   Search</b>
            </Label>
            <Input className='' name='keywords' value={keywords} onChange={this.handleChange} placeholder='Search...' />
          </span>

          <span className='mt-auto mx-3' >

            <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              //onApply={this.handleApply}
              onEvent={this.handleEvent}
              ranges={this.state.ranges}
            >
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Select Date Range" value={this.state.serachField.dateRange} id="date_picker" style={{ 'width': '220px' }} />
                <span className="input-group-btn">
                  <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                    <i className="fa fa-calendar" />
                  </Button>
                </span>
              </div>
            </DateRangePicker>


          </span>
          <span className='mt-auto'>
            <Button className='' color='primary' onClick={this.handleFetch} >
              Fetch
            </Button>
          </span>
          <span className='mx-3 mt-auto '>
            <Button className='' onClick={this.reset} >
              Reset
            </Button>
          </span>
          <h3 className='h4 text-nowrap mt-auto ml-auto'><b>Total Critical Due : <b className='text-danger'> {page ? page.totalItemsCount : 0}  </b> </b> </h3>
        </div>



        <div className="table-responsive-sm  animated fadeIn">
          <table className="table table-sm table-condensed table-striped table-bordered table-outline table-responsive-sm">
            <thead className="thead-light">
              <tr>
                <th style={{ width: "5%" }}>#ID</th>
                <th style={{ width: "15%" }}>Company Name </th>
                <th style={{ width: "5%" }}>Bill No</th>
                <th style={{ width: "10%", textAlign: "center" }}>
                  {" "}
                  Bill Date{" "}
                </th>
                <th style={{ width: "15%", textAlign: "Center" }}>
                  Bill Cross Days
                </th>
                <th style={{ width: "10%", textAlign: "center" }}>
                  Status
                </th>
                <th style={{ width: "10%", textAlign: "right" }}>
                  Bill Amount
                </th>
                <th style={{ width: "15%", textAlign: "right" }}>
                  {" "}
                  Pending Amount{" "}
                </th>
                <th style={{ width: "15%", textAlign: "Center" }}>
                  {" "}
                  Declared By
                </th>
                <th style={{ width: "3%", textAlign: "Center" }}>
                </th>
                <th style={{ width: "15%", textAlign: "Center" }}>
                  {" "}
                  Created At
                </th>
                <th style={{ width: "5%" }}> Action </th>
              </tr>
            </thead>
            <tbody>
              {_beddebtdata && _beddebtdata.length ? (
                _beddebtdata.map((d, index) => {
                  let isDocExist =
                    d.bill_copy_url ||
                    d.account_ledger_copy_url ||
                    d.po_copy_url;
                  return (
                    <React.Fragment>
                      <tr style={{ borderTop: "2px solid #c2c2c2" }}>
                        <td>{d.id}</td>
                        <td className="text-dark" title="Company Name">
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              _this.handleToggle(d, true);
                            }}
                          >
                            {d.company_name ? d.company_name : "-"}
                          </a>
                        </td>
                        <td
                          title="Bill Number"
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                          {d.bill_number ? d.bill_number : "-"}{" "}
                        </td>
                        <td
                          title="Bill Date"
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                          {d.bill_date
                            ?
                            moment(d.bill_date).format("DD/MM/YYYY")
                            : "-"}
                        </td>{" "}
                        <td title="Overdue Days" className="text-center">
                          {" "}
                          {d.overdue_days ? d.overdue_days + " Days" : "-"}
                        </td>
                        <td
                          title="Status"
                          className="text-center align-middle"
                        >
                          <span
                            style={{ fontSize: 12 }}
                            className={`badge badge-${d.status === "overdue" ? "warning" : "danger"
                              }`}
                          >
                            {d.status === "overdue"
                              ? "Overdue"
                              : "Critical Due"}{" "}
                          </span>
                        </td>
                        <td title="Credit Amount" className="text-right">
                          {currencyFormat(d.credit_amount, true)}
                        </td>
                        <td
                          title="Pending Amount"
                          className="text-bolder text-danger text-right"
                        >
                          <b>{currencyFormat(d.critical_due_amount, true)}</b>
                        </td>
                        <td title="Declared By" className="text-center">
                          <Link to={'/verification/company/' + d.creditor_formatted_company_repute_id}>{d.creditor_compnay_name} </Link>
                        </td>
                        <td
                          title="Visiblity"
                          className="text-center align-middle"
                        >

                          <VisibilityStatus
                            title={"Visible to Public on Reputeinfo"}
                            type={"reputeinfo"}
                            icon="fa fa-globe cursor-pointer text-success"
                            visibility={d.visibility}
                          />



                          <VisibilityStatus
                            title={"Visible to Only Me"}
                            type={"myself"}
                            icon={"fa fa-user cursor-pointer text-info"}
                            visibility={d.visibility}
                          />


                          <VisibilityStatus
                            title={"Visible to Groups"}
                            type={"groups"}
                            icon={"fa fa-users cursor-pointer text-info"}
                            visibility={d.visibility}
                          />


                        </td>
                        <td title="Created At" className="text-center">
                          {d.created_at}
                        </td>
                        <td title="Action">
                          <Button
                            className="bg-primary"
                            onClick={() => _this.handleToggle(d, true)}
                          >
                            View More
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <div className="col-md-12"> No data found </div>
              )}
            </tbody>
            {/* <thead>
              <tr className="h5">
                <th colSpan={7} className="text-right">
                  Total Account
                </th>
                <th className="text-danger text-right">
                </th>
              </tr>
            </thead> */}
          </table>
        </div>
        {page.totalItemsCount <= page.perPage ? "" :
          <Pagination
            activePage={page.activePage}
            itemsCountPerPage={page.perPage}
            totalItemsCount={page.totalItemsCount}
            pageRangeDisplayed={5}
            linkClass="page-link"
            itemClass="page-item"
            onChange={this.handlePageChange}
            hideNavigation={false}
            hideFirstLastPages={true}
          />}
        <Modal isOpen={isModalShow} lg toggle={() => this.handleToggle()} size={'lg'}  >
          <ModalHeader className="bg-primary text-white" toggle={() => this.handleToggle()}>Critical Due Bill Details</ModalHeader>
          <ModalBody>
            {modalData.length && modalData.map((d) => <React.Fragment>
              <table
                className="table table-sm table-condensed table-striped  table-bordered table-outline "
                style={{ width: "100%" }}
              >
                <thead className="thead-light ">
                  <tr>
                    <th colSpan={5} className="text-center">
                      Customer Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Company Name & GST</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Address</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Directors</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Mobile</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center w-25 align-text-top">
                      <span>
                        {d.company_name ? d.company_name : "Not Available"},
                      </span>
                      <br />
                      <span>{d.gst ? d.gst : "Not Available"}</span>
                    </td>
                    <td className="text-center w-25 align-text-top">
                      <span>{d.address ? <Address address={d.address} /> : "-"}</span>
                    </td>
                    <td className="text-center w-25 align-text-top">
                      <span>{d.person_name}</span>
                    </td>
                    <td className="text-center w-25 align-text-top">
                      <span>{d.mobile ? d.mobile : "-"}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-sm table-condensed  table-bordered table-outline table-striped "
                style={{ width: "100%" }}
              >
                <thead className="thead-light ">
                  <tr>
                    <th colSpan={5} className="text-center">
                      Transaction Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Bill Date</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Bill No</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Bill Credit Days</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Bill Cross Days</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center w-25">
                      <span>
                        {d.bill_date ? moment(d.bill_date).format("DD/MM/YYYY") : "-"}
                      </span>
                    </td>
                    <td className="text-center w-25">
                      <span>{d.bill_number ? d.bill_number : "-"}</span>
                    </td>
                    <td className="text-center w-25">
                      <span> {d.credit_days ? d.credit_days + " Days" : "-"}</span>
                    </td>
                    <td className="text-center w-25">
                      <span>{d.overdue_days ? d.overdue_days + " Days" : "-"}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Bill Amount</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Recceived Amount</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Pending Amount</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Interest</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center w-25">
                      <span> {currencyFormat(d.credit_amount, true)} </span>
                    </td>
                    <td className="text-center w-25">
                      <span>{currencyFormat(d.received_amount, true)}</span>
                    </td>
                    <td className="text-center w-25">
                      <span>{currencyFormat(d.critical_due_amount, true)}</span>
                    </td>
                    <td className="text-center w-25">
                      <span>{d.interest_rate ? d.interest_rate + "%" : "-"} </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Status</span>
                    </td>
                    <td className="text-center w-25">
                      <span style={{ fontWeight: "bold" }}>Any Legal Process</span>
                    </td>
                    <td className="text-center w-25"></td>
                    <td className="text-center w-25"></td>
                  </tr>
                  <tr>
                    <td className="text-center w-25">
                      <span>
                        {d.status === "overdue" ? "Overdue" : "Critical Due"}{" "}
                      </span>
                    </td>
                    <td className="text-center w-25">
                      {d.any_legel ? "YES" : "NO"}{" "}
                    </td>
                    <td className="text-center w-25"></td>
                    <td className="text-center w-25"></td>
                  </tr>
                </tbody>
              </table>
              <table className=" table table-sm table-condensed table-striped  table-bordered table-outline  ">
                <thead className="thead-light">
                  <tr>
                    <th colSpan={5} className="text-center">
                      Documents
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center w-25">PO Copy</td>
                    <td className="text-center w-25">Bill Copy</td>
                    <td className="text-center w-25">Account Ledger Copy</td>
                    <td />
                  </tr>
                  <tr>
                    <td className="text-center w-25">
                      {d.po_copy_url && (
                        <DocumentPreview
                          DocumentURL={d.po_copy_url} title='PO Copy' isFullWidth
                        />
                      )}
                    </td>
                    <td className="text-center w-25">
                      {d.bill_copy_url && (
                        <DocumentPreview
                          DocumentURL={d.bill_copy_url} title='Bill Copy' isFullWidth
                        />
                      )}
                    </td>
                    <td className="text-center w-25">
                      {d.account_ledger_copy_url && (
                        <DocumentPreview
                          DocumentURL={d.account_ledger_copy_url} title='Account Ledger Copy	' isFullWidth
                        />
                      )}
                    </td>
                    <td className="text-center w-25" />
                  </tr>
                </tbody>
              </table>
              {
                <table
                  className="table table-sm table-condensed table-striped  table-bordered table-outline "
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light ">
                    <tr>
                      <th colSpan={5} className="text-center">
                        Declared By
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center w-25">
                        <span style={{ fontWeight: "bold" }}>Company Name & GST</span>
                      </td>
                      <td className="text-center w-25">
                        <span style={{ fontWeight: "bold" }}>Address</span>
                      </td>
                      <td className="text-center w-25">
                        <span style={{ fontWeight: "bold" }}>Owner Name</span>
                      </td>
                      <td className="text-center w-25">
                        <span style={{ fontWeight: "bold" }}>Mobile</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center w-25 align-text-top">
                        <span>
                          {d.creditor_compnay_name ? d.creditor_compnay_name : "-"}
                          ,
                          <br />
                          {d.creditor_gst ? d.creditor_gst : "Not Available"}
                        </span>
                      </td>
                      <td className="text-center w-25 align-text-top">
                        <span>
                          {d.address ? <Address address={d.creditor_address} /> : "-"}
                        </span>
                      </td>
                      <td className="text-center w-25 align-text-top">
                        <span>
                          {d.creditor_individual_name
                            ? d.creditor_individual_name
                            : "-"}{" "}
                        </span>
                      </td>
                      <td className="text-center w-25 align-text-top">
                        <span>{d.creditor_mobile ? d.creditor_mobile : "-"} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </React.Fragment>)}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.handleToggle()} >Cancel</Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getDirectCriticalDue;
  return {
    loading,
    data
  }
}
const connectedTabContentData = connect(mapStateToProps)(TabContentData)
export { connectedTabContentData as TabContentData };


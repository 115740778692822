import { whatsappLogConstants } from "../constants"

export function getWhatsappLogs(state = {}, action) {
    switch (action.type) {
      case whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_REQUEST:
        return { loading: true }
      case whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_SUCCESS:
        return { data: action.result }
      case whatsappLogConstants.GET_WHATSAPP_RECEIVE_LOGS_FAILURE:
        return {}
      case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_REQUEST:
        return { loading: true }
      case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_SUCCESS:
        return { data: action.result }
      case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_FAILURE:
        return {}
      case whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_REQUEST:
        return { loading: true }
      case whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_SUCCESS:
        return { data: action.result }
      case whatsappLogConstants.GET_WHATSAPP_SCHEDULE_LOGS_FAILURE:
        return {}
      default:
        return state
    }
  }




  export function getWhatsappLogsRi(state = {}, action) {
    switch (action.type) {
        case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_REQUEST:
          return { loading: true }
        case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_SUCCESS:
          return { data: action.result }
        case whatsappLogConstants.GET_WHATSAPP_SEND_LOGS_RI_FAILURE:
          return {}
      default:
        return state
    }
  }
  
  
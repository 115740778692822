import classnames from 'classnames'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders'
import { communicationTemplateActions } from '../../actions'
import { Template } from './Template'

const TABS = {
  REQUEST: "REQUEST",
  ALERT: "ALERT",
  CRITICAL: "CRITICAL"
}

class CommunicationTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: TABS.REQUEST
    }

    this.toggle = this.toggle.bind(this)
    this.getCommunicationTemplate = this.getCommunicationTemplate.bind(this)
  }
  componentDidMount() {
    var qstr = queryString.parse(this.props.location.search)
    var atab = qstr.tab;
    this.setState({ activeTab: atab ? atab : this.state.activeTab }, this.getCommunicationTemplate)
  }
  getCommunicationTemplate() {
    const { dispatch } = this.props;
    let dataToSend = {
      category: this.state.activeTab
    }
    dispatch(communicationTemplateActions.getCommunicationTemplate(dataToSend))

  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {

      this.props.history.push({
        pathname: '/communication_template',
        search: '?tab=' + tab
      })
      this.setState({
        activeTab: tab
      }, () => {
        this.getCommunicationTemplate()
      })
    }
  }

  render() {
    const { data, loading, requests_count, alerts_count, criticals_count } = this.props

    return (
      <div>
        <h5 className="mb-4 mt-4">
          <i className="fa fa-bell"></i> &nbsp; Communication template
        </h5>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.REQUEST })}
              onClick={() => { this.toggle(TABS.REQUEST) }}
            >
              <i class="fa fa-bell text-success" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>REQUEST</span> <span className="badge badge-primary">{requests_count ? requests_count : 0} </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.ALERT })}
              onClick={() => { this.toggle(TABS.ALERT) }}
            >
              <i className="fa fa-bell text-warning" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>ALERT</span> <span className="badge badge-primary">{alerts_count ? alerts_count : 0} </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.CRITICAL })}
              onClick={() => { this.toggle(TABS.CRITICAL) }}
            >
              <i className="fa fa-bell text-danger" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Critical</span> <span className="badge badge-primary">{criticals_count ? criticals_count : 0} </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={TABS.REQUEST}>
            {
              loading ? <TableLoader /> :
                <Template getCommunicationTemplate={this.getCommunicationTemplate} templatedata={data} />
            }

          </TabPane>
          <TabPane tabId={TABS.ALERT}>
            {
              loading ? <TableLoader /> :
                <Template getCommunicationTemplate={this.getCommunicationTemplate} templatedata={data} />
            }

          </TabPane>
          <TabPane tabId={TABS.CRITICAL}>
            {
              loading ? <TableLoader /> :
                <Template getCommunicationTemplate={this.getCommunicationTemplate} templatedata={data} />
            }


          </TabPane>
        </TabContent>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getCommunicationTemplate
  const { user } = state.authentication
  return {
    loading,
    data: data ? data.data : [],
    requests_count: data ? data.requests_count : 0,
    alerts_count: data ? data.alerts_count : 0,
    criticals_count: data ? data.criticals_count : 0,
    authUser: user
  }

}

const connectedCommunicationTemplate = connect(mapStateToProps)(CommunicationTemplate)
export { connectedCommunicationTemplate as CommunicationTemplate }


import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card, CardHeader,Button,} from 'reactstrap';
import { TableLoader } from "../../../components/Loaders";
import {AddInformation} from './AddInformation'
import { informationActions } from '../../actions';
class Information extends Component {
    constructor(props) {
        super(props)
        this.getinformations = this.getinformations.bind(this)
    }

    getinformations() {
        const { dispatch } = this.props;
        dispatch(informationActions.getinformations())
    }
    componentDidMount() {
        this.getinformations()
    }
    render() {
        const {loading, data} = this.props
        return (
			<Card>
				<CardHeader>
					<i className="fas fa-boxes" />
					<strong>Information</strong>
                    {
                        data && data.length <= 0 ?
					<AddInformation getData={this.getinformations}  /> 
                        : ''
                    }
				</CardHeader>
				<CardBody>
					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
							<div className="table-responsive-sm  animated fadeIn">
								<table className="table table-sm table-condensed table-outline">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Subject</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>    
                                    </thead>
									<tbody>
                                        
										{data && data.length ? (
											data.map((eachData) => {
												return (
														<tr className="bg-light">
															<td>{eachData.subject}</td>
                                                            <td>{eachData.type}</td>
                                                            <td>{eachData.status == 1 ? "Active" : "Inactive" }</td>
                                                            <td><AddInformation getData={this.getinformations} editData={eachData} isEdit={true} /></td>
														</tr>
												);
											})
										) : (
											<tr>
												<td colSpan="3" className="text-center">
													No Data Found
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

						</React.Fragment>
					)}

				</CardBody>
			</Card>
		);
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getinformations
    return {loading, data}
}

const connectedInformation = connect(mapStateToProps)(Information)
export { connectedInformation as Information }
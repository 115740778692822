import { notify } from 'reapop'
import { informationConstants } from '../constants'
import { informationService } from '../services'

export const informationActions = {
    getinformations,
    addEditinformations,
}

function getinformations(data) {
    return dispatch => {
        dispatch(request())

        informationService.getinformations(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: informationConstants.GET_INFORMATION_REQUEST } }
    function success(result) { return { type: informationConstants.GET_INFORMATION_SUCCESS, result } }
    function failure(error) { return { type: informationConstants.GET_INFORMATION_FAILURE, error } }
}

function addEditinformations(data,callback) {
    return dispatch => {
        dispatch(request())

        informationService.addEditinformations(data)
            .then(
                result => {
                    dispatch(success(result))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: informationConstants.ADD__EDIT_INFORMATION_REQUEST } }
    function success(result) { return { type: informationConstants.ADD__EDIT_INFORMATION_SUCCESS, result } }
    function failure(error) { return { type: informationConstants.ADD__EDIT_INFORMATION_FAILURE, error } }
}

import React, { Component } from "react";
import { accountAction } from "./../../actions/account.action";
import swal from "sweetalert";

import { Tooltip } from 'react-tippy';

import moment  from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback
} from "reactstrap";

export default class ExtendPlan extends Component {
  constructor(props) {
    super(props);
    let days = new Date().getDate() - 1;
    this.state = {
      modalOpan: false,
      data: {
        isInitialBalance: true,
        balance: "",
      },
      errors : {}
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.proceed = this.proceed.bind(this);
    this.isValidData = this.isValidData.bind(this);
  }

  showModal() {
    const { modalOpan } = this.state;
    this.setState({ modalOpan: !modalOpan });
  }

  handleChange(event) {
    const { name, value, type } = event.target;
    const { default_balance } = this.props

    let { data } = this.state;
    let val = value;

    let regex_allowed = /^[0-9]+$/;
    if (name == 'balance' && value.length && regex_allowed.test(value) === false) {
      return false;
    }

    if (type === "checkbox") {
      if (data[name] === false) {
        val = true;
        data['balance'] = default_balance
      } else if (data[name] === true) {
        val = false;
        data['balance'] = ''
      } else {
        val = false;
        data['balance'] = ''
      }
    }
    data[name] = val;
    this.setState({ data });
  }

  isValidData() {
    const { data, errors } = this.state
    let error = false
    if(!data.balance) {
        error = true
        errors.balance ='this field is required'
    }
    this.setState({ errors })
    return error
  }
  proceed() {
    const { data } = this.state;
    const { dispatch, element, getData } = this.props;

    if(!this.isValidData()) {
      swal({
        title: "Are you sure ?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        buttons: ["Cancel", "Yes, I am sure!"],
      }).then((confirm) => {
        if (confirm) {
          dispatch(
            accountAction.proceedExtend({
              repute_id: element.repute_id,
              isInitialBalance: data.isInitialBalance,
              balance: data.balance,
              type: 'extend',
            }, {
                success : () => {
                    this.showModal()
                    getData()
                }
            })
          );
  
        }
      });
    }
  }
  componentDidMount() {
    const { data } = this.state
    const { default_balance } = this.props
    data.balance = default_balance ? default_balance : ''
    this.setState({ data })
  }
  render() {
    const { modalOpan, data, errors } = this.state;
    const { element } = this.props;

    let _this = this;
    return (
      <div>
         <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Add Initial Balance">
                    
        <Button color="success" onClick={_this.showModal} disabled={ element.is_initial_balance_added }>
          <i class="fas fa-handshake"></i>
        </Button>
        </Tooltip>
        <Modal className="modal-success" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>Add Initial Balance<br />{" "}
            {/* <small>Add remaining negotiated offer</small> */}
            </ModalHeader>
          <ModalBody>

              <Row>
                <Col md="5" className="text-right mb-1">
                  Name of company :
                </Col>
                <Col md="7" className="mb-1">{element.company_name}</Col>

                <Col md="5" className="text-right mb-1">
                  Repute ID :
                </Col>
                <Col md="7" className="mb-1">{element.formated_repute_id}</Col>

                {/* <Col md="5" className="text-right  mb-1">
                  Subscription Plan :
                </Col>
                <Col md="7" className="mb-1">{element.current_plan}</Col>


                <Col md="5" className="text-right mb-4">
                  Current Expiry Date :
                </Col>
                <Col md="7">{element.formated_expiry_date}</Col> 

                 <Col md="5" className="text-right mb-1">
                  Plan Start Date : :
                </Col>
                <Col md="7">{element.formated_plan_start_date}</Col> 


                <Col md="5" className="text-right mb-1 pt-2">
                 Add Days :
                </Col>
                <Col md="7">
                  <Input
                    maxlength={3}
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Days"
                    name={"days"}
                    value={data.days}
                  />
                </Col>


                <Col md="5" className="text-right  mb-4">
                  New Expiry Date :
                </Col>
                <Col md="7" className="mb-1">{moment(element.expiry_date).add(data.days, 'days').format('DD MMM YYYY hh:mm A')}</Col>
                */}


                <Col md="5" className="text-right mb-1">
                  Current Balance :
                </Col>
                <Col md="7" className="mb-1">{element.balance}</Col>
                <Col md="5" className="text-right mb-1 pt-2">
                  Initial Balance :
                </Col>
                <Col md="7" className="mb-1">
                <div style={{paddingTop:'10px'}}>
                  <input type="checkbox"
                        id="isInitialBalance"
                        class=""
                        checked={data.isInitialBalance}
                        name="isInitialBalance"
                        onChange={this.handleChange}
                         />
                        </div>
                </Col>
                <Col md="5" className="text-right mb-1 pt-2">
                  Add Balance :
                </Col>
                <Col md="7" className="mb-1">
                  <Input
                    maxlength={4}
                    type="text"
                    onChange={this.handleChange}
                    name={"balance"}
                    value={data.balance}
                    placeholder="Balance"
                    disabled = { data.isInitialBalance }
                  />
                  {errors.balance && (
                    <FormFeedback>
                      {errors.balance}
                    </FormFeedback>
                  )}
                </Col>

                <Col md="5" className="text-right">
                  Total Balance  :
                </Col>
                <Col md="7" >{data.balance? parseInt(element.balance)   + parseInt(data.balance) : element.balance  }</Col>

              </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
              Proceed
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import DataSection from "./DataSection/DataSection";
import Stages from "./Stages/Stages";
import { Process } from "./Stages/Process";
import { businessAccountTemplateActions } from "../../../actions";

let  default_template = {
  business_details : {
    children: []
  },
  individual_details : {
    individual_template:{
      children:[]
    },
    individuals : []
},
  stages:{
    children: []
  }
}

class DataTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: "",
      template: default_template,
    };
    this.updateMainJSON = this.updateMainJSON.bind(this);
    this.updateStagesJSON = this.updateStagesJSON.bind(this);
  }

  updateMainJSON(template) {
    
    this.setState({ template });

    const { dispatch } = this.props;
    let dataToSend = {
      business_account_template_json : JSON.stringify(template)
    };
    dispatch(businessAccountTemplateActions.saveBusinessAccountTemplate(dataToSend));
  }

  updateStagesJSON( data ) {

    const { template } = this.state; 
  
    template.stages = data;

    this.updateMainJSON(template);
  
  }

componentDidMount() {
  const { dispatch } = this.props;
  dispatch(businessAccountTemplateActions.getBusinessAccountTemplate());
}

componentWillReceiveProps(props) {
  let {businessAccountTemplate} = props
  console.log(businessAccountTemplate);
  if(businessAccountTemplate  ){
    // let template_data = JSON.parse(businessAccountTemplate);
    if(typeof businessAccountTemplate === "object" && businessAccountTemplate.hasOwnProperty("individual_details")){
      this.setState({template: businessAccountTemplate})
    }
  }
}

  render() {
    const { expand, template } = this.state;
    let _this = this;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <DataSection template={template} updateMainJSON={this.updateMainJSON}/>
          </div>

          <div className="col-md-6">
            <Process template={template} updateStagesJSON={this.updateStagesJSON}/>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {businessAccountTemplate} = state
  return {
    businessAccountTemplate
  }
}

const connectedDataTemplate = connect(mapStateToProps)(DataTemplate);
export { connectedDataTemplate as DataTemplate };


import indexRoutes from '../routes';
import { store } from '.';
import { PERMISSIONS } from '../individual/constants';

export function getRedirectToAuthenticatedPage() {
  var newState = store.getState();
  const { user } = newState.authentication;

  let permissions = user ? (user.permission ? user.permission.split(',') : []) : [];
  // let redirectTo = "/setting"; // default
  let redirectTo = "/dashboard"; // default
  let each_permission = permissions[0];

  if (each_permission === PERMISSIONS.ALL) return redirectTo;

  for (var i = 0; i < indexRoutes.length; i++) {
    let val_route = indexRoutes[i];
    if (val_route.hasOwnProperty("main")) {
      if (val_route.permission === each_permission) {
        redirectTo = val_route.path;
        break;
      }
    }
  }
  return redirectTo;
}

store.subscribe(getRedirectToAuthenticatedPage);




export function authHeader() {

  let user = localStorage.getItem('user')

  if (user) {
    user = JSON.parse(atob(localStorage.getItem('user')))
    if (user && user.access_token) {
      return { 'Authorization': user.access_token }
    } else {
      return {}
    }
  }
  return {}
}





import config from 'react-global-configuration'
import { handleResponse } from './common.service'
import axios from 'axios'
import {authHeader} from "../../helpers";

export const subscriptionLogService = {
    getSubscriptionLogs
}

function getSubscriptionLogs(dataToSend) {
    var form_data = dataToSend
    let setting = {
        headers: authHeader()
    }
    return axios.post(`${config.get('apiUrl')}/getSubscriptionLogs`,form_data, setting).then(handleResponse)
}
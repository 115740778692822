import { VideosConstants } from "../constants"

export function getVideoData(state = {}, action) {
    switch (action.type) {
        case VideosConstants.GET_VIDEOS_REQUEST:
        return { loading: true }
        case VideosConstants.GET_VIDEOS_SUCCESS:
        return { 
            data: action.result
            }
        case VideosConstants.GET_VIDEOS_FAILURE:
        return {}
        default:
        return state
    }
}

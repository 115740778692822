import moment from "moment";
import React, { Component } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import EachStageDataRender from "./EachStageDataRender";

export default class ProcessStageTimeLineListFormat extends Component {
  renderEachStage() {
    const { processStages } = this.props;

    return processStages.map((each_stage) => {
      let iconStyle = {
        background: "#0194C8",
        color: "#fff",
      };
      return (
        <React.Fragment>
          <ListGroup style={{ marginBottom: "10px" }}>
            <ListGroupItem
              style={{ fontSize: "12px", backgroundColor: "#f0f3f5" }}
            >
              <Row>
                <Col md="6">
                  <p>
                    <b>Stage Name</b> <br />
                    {each_stage.stage_name}
                  </p>
                </Col>

                <Col md="6">
                  <p>
                    <b>Communicated with Person </b> <br />
                    {each_stage.individual_name}
                  </p>
                </Col>

                <Col md="6">
                  <p>
                    <b>Completed on </b> <br />
                    {each_stage.is_active
                      ? "-"
                      : moment(each_stage.updated_at).format("D-M-Y hh:mm A")}
                  </p>
                </Col>

                <Col md="6">
                  
                  <p>
                    <b>Status</b> <br />
                    {each_stage.is_repeat ? (
                      <span className="badge badge-primary">Repeat</span>
                    ) : (
                      ""
                    )}
                    {each_stage.is_active ? (
                      <span className="badge badge-primary">Active</span>
                    ) : (
                      <span className="badge badge-success">Complete</span>
                    )}
                  </p>
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <EachStageDataRender processStage={each_stage} />
            </ListGroupItem>
            
            <ListGroupItem   style={{ fontSize: "12px", backgroundColor: "#f0f3f5" }}>
<Row>
            <Col md="6">
                  <p>
                    <b>Notes </b> <br />
                    {each_stage.notes}
                  </p>
                </Col></Row>
            </ListGroupItem>
          </ListGroup>
        </React.Fragment>
      );
    });
  }

  render() {
    return <div>{this.renderEachStage()}</div>;
  }
}

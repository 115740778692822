import {  axiosAjaxCall } from './common.service'
import {  showLoader } from "../../helpers";

export const UserLoginLogService = {
    getUserLoginLogs
}

function getUserLoginLogs(data) {
    
    showLoader();    
    return axiosAjaxCall("get_login_logs", data);
}
import { InquiriesConstants } from "../constants"

export function getInquiries(state = {}, action) {

    switch (action.type) {
      case InquiriesConstants.GET_INQUIRIES_REQUEST:
          return { loading: true }
      case InquiriesConstants.GET_INQUIRIES_SUCCESS:
        return { data: action.result }
      case InquiriesConstants.GET_INQUIRIES_FAIL:
        return {}
      default:
        return state
    }
  }
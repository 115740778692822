import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardBody, Card, CardHeader, Button, FormFeedback, Form, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { accountAction } from '../../actions'
import { TableLoader } from '../../../components/Loaders';
import UpdateDepositStatus from './UpdateDepositStatus'
import classnames from 'classnames';
import { currencyFormat } from '../../../helpers';
import { DepositReceiptsDetail } from './DepositReceiptsDetail';
import swal from 'sweetalert';

const TABS = {
    PENDING: "pending",
    VERIFIED: "verified"
}
class DepositReceipts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: TABS.PENDING
        }
        this.getDepositReceipt = this.getDepositReceipt.bind(this)
        this.closeCredit = this.closeCredit.bind(this)
    }

    getDepositReceipt() {
        const { dispatch } = this.props;
        dispatch(accountAction.getDepositReceipt());
    }

    componentDidMount() {
        this.getDepositReceipt()
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    closeCredit(user_id) {
        const { data } = this.state;
        const { dispatch, element, getData } = this.props;
        swal({
            title: "",
            text: 'Coming Soon...',
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // buttons: ["Cancel", "Yes, I am sure!"],
            buttons: ["Close"],
        }).then((confirm) => {
            if (confirm) {
                // let dataTosend = {
                //     user_id: user_id,
                // }
                // dispatch(
                //     accountAction.closeCredit(dataTosend, {
                //         success: () => {
                //             this.getDepositReceipt()
                //         }
                //     })
                // );

            }
        });
    }

    render() {
        const { loading, pendingData, approovedData } = this.props
        let _this = this;
        return (
            <React.Fragment>

                <Card>
                    <CardHeader> <i className="fas fa-receipt"></i> <b>Deposit Ledgers/Balance sheet</b></CardHeader>
                    <CardBody>

                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === TABS.PENDING,
                                    })}
                                    onClick={() => {
                                        this.toggle(TABS.PENDING);
                                    }}
                                >
                                    <span style={{ fontWeight: "bold" }}>Pending</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === TABS.VERIFIED,
                                    })}
                                    onClick={() => {
                                        this.toggle(TABS.VERIFIED);
                                    }}
                                >
                                    <span style={{ fontWeight: "bold" }}>Received</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={TABS.PENDING}>
                                {
                                    loading ? <TableLoader /> :
                                        (pendingData ? <div className="table-responsive">

                                            <div className="table-responsive-sm  animated fadeIn">
                                                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th>User Name</th>
                                                            <th>Plan Deposit Amount</th>
                                                            <th>Deposited Amount</th>
                                                            <th>Pending Amount</th>
                                                            <th style={{ "width": "25%" }}>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pendingData.length ?
                                                            pendingData.map(function (element, index) {
                                                                return (element.pending_amount > 0 ?
                                                                    <tr>
                                                                        <td>{element.user.name}</td>
                                                                        <td> ₹ {currencyFormat(element.debit_amount)}</td>
                                                                        <td>₹ {currencyFormat(element.received_amount)}</td>
                                                                        <td>₹ {currencyFormat(element.pending_amount)}</td>
                                                                        {/* <td>{element.cheque_status_text ? ' ( Cheque ' + element.cheque_status_text + ')' : ''} </td> */}
                                                                        <td>
                                                                            <UpdateDepositStatus {..._this.props} element={element} getData={_this.getDepositReceipt} />
                                                                            <DepositReceiptsDetail element={element} data={element.all_data} />

                                                                        </td>
                                                                    </tr> : false)
                                                            })

                                                            : <tr>
                                                                <th colSpan="4" className="text-center">No Data Found</th>
                                                            </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : "")
                                }
                            </TabPane>
                            <TabPane tabId={TABS.VERIFIED}>
                                {
                                    loading ? <TableLoader /> :
                                        (approovedData ? <div className="table-responsive">

                                            <div className="table-responsive-sm  animated fadeIn">
                                                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th>User Name</th>
                                                            <th>Plan Deposit Amount</th>
                                                            <th>Received</th>
                                                            <th>Deposit Lock-in </th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {approovedData.length ?
                                                            approovedData.map(function (element, index) {
                                                                return (element.total_pending_amount == 0 ?
                                                                    <tr>
                                                                        <td>{element.user.name}</td>
                                                                        <td> ₹ {currencyFormat(element.debit_amount)}</td>
                                                                        <td> ₹ {currencyFormat(element.total_deposited_amount)}</td>
                                                                        <td> {element && element.deposit_lockin ? element.deposit_lockin + ' days. (' + element.deposit_lockin_date + ')' : '-'}</td>
                                                                        <td>
                                                                            <DepositReceiptsDetail element={element} data={element.all_data} />
                                                                            {/* {element && element.deposit_lockin <= 0 ?
                                                                                <Button color="danger">Close Credit</Button> : false} */}

                                                                            <Button color="danger" onClick={() => _this.closeCredit(element.user_id)}>Close Credit</Button>
                                                                        </td>
                                                                        {/* <td>{element.user.deposit_amount_approved_at}</td> */}
                                                                    </tr> : false)
                                                            })

                                                            : <tr>
                                                                <th colSpan="4" className="text-center">No Data Found</th>
                                                            </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : "")
                                }
                            </TabPane>
                        </TabContent>


                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getDepositReceipt
    return {
        loading,
        pendingData: data && data.pending ? data.pending : [],
        approovedData: data && data.approved ? data.approved : [],
    }
}
const connectDepositReceipts = connect(mapStateToProps)(DepositReceipts)
export { connectDepositReceipts as DepositReceipts }
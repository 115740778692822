import { autoReminderCronLogsConstants } from "../constants"

export function getAutoReminderCronLogs(state = {}, action) {
    switch (action.type) {
      case autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_REQUEST:
        return { loading: true }
      case autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_SUCCESS:
        return { 
          data: action.result,
         }
      case autoReminderCronLogsConstants.GET_AUTO_REMINDER_CRON_LOGS_FAILURE:
        return {}
      default:
        return state
    }
  }
export const userManagementConstants = {
    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
    
    CHANGE_USER_STATUS_REQUEST: 'CHANGE_USER_STATUS_REQUEST',
    CHANGE_USER_STATUS_SUCCESS: 'CHANGE_USER_STATUS_SUCCESS',
    CHANGE_USER_STATUS_FAILURE: 'CHANGE_USER_STATUS_FAILURE',


    ADD_USER_TYPE_REQUEST: 'ADD_USER_TYPE_REQUEST',
    ADD_USER_TYPE_SUCCESS: 'ADD_USER_TYPE_SUCCESS',
    ADD_USER_TYPE_FAILURE: 'ADD_USER_TYPE_FAILURE',

    GET_USER_TYPES_REQUEST: 'GET_USER_TYPES_REQUEST',
    GET_USER_TYPES_SUCCESS: 'GET_USER_TYPES_SUCCESS',
    GET_USER_TYPES_FAILURE: 'GET_USER_TYPES_FAILURE',

    DELETE_USER_TYPE_REQUEST: 'DELETE_USER_TYPE_REQUEST',
    DELETE_USER_TYPE_SUCCESS: 'DELETE_USER_TYPE_SUCCESS',
    DELETE_USER_TYPE_FAILURE: 'DELETE_USER_TYPE_FAILURE',

    GET_COMISSION_SLAB_REQUEST: 'GET_COMISSION_SLAB_REQUEST',
    GET_COMISSION_SLAB_SUCCESS: 'GET_COMISSION_SLAB_SUCCESS',
    GET_COMISSION_SLAB_FAILURE: 'GET_COMISSION_SLAB_FAILURE',

    GET_COMISSION_AMOUNT_REQUEST: 'GET_COMISSION_AMOUNT_REQUEST',
    GET_COMISSION_AMOUNT_SUCCESS: 'GET_COMISSION_AMOUNT_SUCCESS',
    GET_COMISSION_AMOUNT_FAILURE: 'GET_COMISSION_AMOUNT_FAILURE',

    UPDATE_COMISSION_SLAB_REQUEST: 'UPDATE_COMISSION_SLAB_REQUEST',
    UPDATE_COMISSION_SLAB_SUCCESS: 'UPDATE_COMISSION_SLAB_SUCCESS',
    UPDATE_COMISSION_SLAB_FAILURE: 'UPDATE_COMISSION_SLAB_FAILURE',

    GET_JOB_TITLES_REQUEST: 'GET_JOB_TITLES_REQUEST',
    GET_JOB_TITLES_SUCCESS: 'GET_JOB_TITLES_SUCCESS',
    GET_JOB_TITLES_FAILURE: 'GET_JOB_TITLES_FAILURE',

    SAVE_JOB_TITLES_REQUEST: 'SAVE_JOB_TITLES_REQUEST',
    SAVE_JOB_TITLES_SUCCESS: 'SAVE_JOB_TITLES_SUCCESS',
    SAVE_JOB_TITLES_FAILURE: 'SAVE_JOB_TITLES_FAILURE',
    
    GET_DEPARTMENTS_REQUEST: 'GET_DEPARTMENTS_REQUEST',
    GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
    GET_DEPARTMENTS_FAILURE: 'GET_DEPARTMENTS_FAILURE',
    
    SAVE_DEPARTMENTS_REQUEST: 'SAVE_DEPARTMENTS_REQUEST',
    SAVE_DEPARTMENTS_SUCCESS: 'SAVE_DEPARTMENTS_SUCCESS',
    SAVE_DEPARTMENTS_FAILURE: 'SAVE_DEPARTMENTS_FAILURE',
}
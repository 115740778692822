import { notify } from 'reapop'
import { verificationConstants } from '../constants'
import { varificationService } from '../services'

export const verificationActions = {
    getIndividuals,
    getIndividualDetail,
    updateIndividual,
    verifyIndividualCompany,
    addToJunkUser,
    fetchAndVerify,
    aadharReject,
    saveGstUpdate,

    getCompanies,
    getCompanyDetail,
    updateCompany,
    setUserVerify,
    setCompanyVerify,
}


function saveGstUpdate(data, ref) {
    return dispatch => {
        dispatch(request())

        varificationService.saveGstUpdate(data)
            .then(
                result => { ref.getData(); dispatch(success(result)) },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.PUT_GST_UPDATE_REQUEST } }
    function success(result) { return { type: verificationConstants.PUT_GST_UPDATE_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.PUT_GST_UPDATE_FAILURE, error } }
}

function getIndividuals(data) {
    return dispatch => {
        dispatch(request())

        varificationService.getIndividuals(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.GET_INDIVIDUALS_REQUEST } }
    function success(result) { return { type: verificationConstants.GET_INDIVIDUALS_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.GET_INDIVIDUALS_FAILURE, error } }
}

function getIndividualDetail(data) {
    return dispatch => {
        dispatch(request())

        varificationService.getIndividualDetail(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.GET_INDIVIDUAL_DETAIL_REQUEST } }
    function success(result) { return { type: verificationConstants.GET_INDIVIDUAL_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.GET_INDIVIDUAL_DETAIL_FAILURE, error } }
}
function fetchAndVerify(data, callback) {
    return dispatch => {
        dispatch(request())

        varificationService.fetchAndVerify(data)
            .then(
                result => {
                    dispatch(success(result))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.FETCH_AND_VERIFY_REQUEST } }
    function success(result) { return { type: verificationConstants.FETCH_AND_VERIFY_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.FETCH_AND_VERIFY_FAIL, error } }
}
function setUserVerify(data, callback) {
    return dispatch => {
        dispatch(request())

        varificationService.setUserVerify(data)
            .then(
                result => {
                    dispatch(success(result))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.SET_USER_VERIFY_REQUEST } }
    function success(result) { return { type: verificationConstants.SET_USER_VERIFY_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.SET_USER_VERIFY_FAIL, error } }
}
function setCompanyVerify(data, callback) {
    return dispatch => {
        dispatch(request())

        varificationService.setCompanyVerify(data)
            .then(
                result => {
                    dispatch(success(result))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.SET_COMPANY_VERIFY_REQUEST } }
    function success(result) { return { type: verificationConstants.SET_COMPANY_VERIFY_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.SET_COMPANY_VERIFY_FAIL, error } }
}

function aadharReject(data, callback) {
    return dispatch => {
        dispatch(request())

        varificationService.aadharReject(data)
            .then(
                result => {
                    dispatch(success(result))
                    callback.success()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.AADHAR_REJECT_REQUEST } }
    function success(result) { return { type: verificationConstants.AADHAR_REJECT_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.AADHAR_REJECT_FAIL, error } }
}


function addToJunkUser(data) {
    return dispatch => {
        dispatch(request())

        varificationService.addToJunkUser(data)
            .then(
                result => dispatch(success(result)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.ADD_TO_JUNK_USER } }
    function success(result) { return { type: verificationConstants.ADD_TO_JUNK_USER_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.ADD_TO_JUNK_USER_FAIL, error } }
}

function verifyIndividualCompany(data, ref) {
    return dispatch => {
        dispatch(request())

        varificationService.verifyIndividualCompany(data)
            .then(
                res => {
                    dispatch(success(res));
                    ref.afterAction();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.VERIFY_INDIVIDUAL_REQUEST } }
    function success(result) { return { type: verificationConstants.VERIFY_INDIVIDUAL_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.VERIFY_INDIVIDUAL_FAILURE, error } }
}


function getCompanies(data) {
    return dispatch => {
        dispatch(request())

        varificationService.getCompanies(data)
            .then(
                notVerified => dispatch(success(notVerified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.GET_COMPANY_LIST_REQUEST } }
    function success(result) { return { type: verificationConstants.GET_COMPANY_LIST_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.GET_COMPANY_LIST_FAILURE, error } }
}

function getCompanyDetail(data) {
    return dispatch => {
        dispatch(request())

        varificationService.getCompanyDetail(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.GET_COMPANY_DETAIL_REQUEST } }
    function success(result) { return { type: verificationConstants.GET_COMPANY_DETAIL_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.GET_COMPANY_DETAIL_FAILURE, error } }
}

function updateCompany(data, ref) {
    return dispatch => {
        dispatch(request())

        varificationService.updateCompany(data)
            .then(
                res => {
                    dispatch(success(res));
                    ref.afterAction();
                }, error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.VERIFY_COMPANY_REQUEST } }
    function success(result) { return { type: verificationConstants.VERIFY_COMPANY_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.VERIFY_COMPANY_FAILURE, error } }
}

function updateIndividual(data, ref) {
    return dispatch => {
        dispatch(request())

        varificationService.updateIndividual(data)
            .then(
                res => {
                    dispatch(success(res));
                    ref.afterAction();
                }, error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: verificationConstants.VERIFY_COMPANY_REQUEST } }
    function success(result) { return { type: verificationConstants.VERIFY_COMPANY_SUCCESS, result } }
    function failure(error) { return { type: verificationConstants.VERIFY_COMPANY_FAILURE, error } }
}
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { dashboardReportsActions } from "../../actions";
import { InputTypes } from "../../constants/stages.constants";
import EachStageDataRender from "./Components/EachStageDataRender";
import { ProcessStageTimeLineModal } from "./Components/ProcessStageTimeLineModal";

class ProcessStagesListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      errors: {},
      business_details: [],
      individual_details: [],
      collapse: "",
    };
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  renderValue(data) {
    let renderedValue = false;

    switch (data.inputType) {
      case InputTypes.Text:
      case InputTypes.TextArea:
        renderedValue = data.value ? data.value : "-";
        break;

      case InputTypes.Checkbox:
        renderedValue = data.value ? "Yes" : "No";
        break;
    }
    return renderedValue;
  }

  toggle() {
    const { show } = this.state;
    this.setState({ show: !show }, () => !show === true && this.getData());
  }

  showDetails(idx) {
    this.setState({ collapse: idx });
  }

  getData() {
    const { dispatch, business_account } = this.props;

    dispatch(
      dashboardReportsActions.getProcessStages({
        business_account_id: business_account.id,
      })
    );
  }

  componentWillReceiveProps(props) {
    let { individual_details } = this.state;
    let { businessAccountTemplate, businessDetails, individualDetails } = props;

    if (businessDetails) {
      let businessDetails_parse = businessDetails
        ? JSON.parse(businessDetails)
        : [];
      let individual_details_parse = individualDetails
        ? JSON.parse(individualDetails)
        : [];
      individual_details = individual_details_parse;

      this.setState({
        template: businessAccountTemplate,
        business_details: businessDetails_parse,
        individual_details,
        // stages:businessAccountTemplate.stages.Items
      });
    }
  }

  render() {
    let { processStages } = this.props;
    let { business_details, individual_details } = this.state;
    let _this = this;
    return (
      <div>
        <Button size="sm" color="primary" onClick={() => this.toggle()}>
          View Stage Details
        </Button>

        <Modal className="modal-primary" size="lg" isOpen={this.state.show}>
          <ModalHeader toggle={this.toggle}>
            Business Details
            <div style={{ position: "absolute", top: 15, right: 50 }}>
              <ProcessStageTimeLineModal processStages={processStages || []} />
            </div>
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardHeader>
                <h5>Business Details</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {business_details &&
                    business_details.map((each) => {
                      return (
                        <Col md="3">
                          <b>{each.title}</b> <br />
                          {this.renderValue(each)}
                        </Col>
                      );
                    })}
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {individual_details &&
                  individual_details.map((eachIndividual, index) => {
                    return (
                      <React.Fragment>
                        <h5>Individual Details</h5>
                        <Row>
                          {eachIndividual.children.map((each) => {
                            return (
                              <Col md="3">
                                <b>{each.title}</b> <br />
                                {this.renderValue(each)}
                              </Col>
                            );
                          })}
                        </Row>
                        <br />
                        <br />
                      </React.Fragment>
                    );
                  })}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5>Process Stages</h5>
              </CardHeader>
              <CardBody>
                <Table
                  responsive
                  size="sm"
                  hover
                  bordered
                  className="table-outline"
                >
                  <tr>
                    <td>Process Name</td>
                    <td>Stage Name</td>
                    <td>Communicated with Person</td>
                    <td>Updated By</td>
                    <td>Created on</td>
                    <td>Completed On</td>
                    <td></td>
                    <td>Status</td>
                    <td>Actions</td>
                  </tr>
                  {processStages &&
                    processStages.map((each_stage, idx) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td> {each_stage.process_name} </td>
                            <td> {each_stage.stage_name} </td>
                            <td> {each_stage.individual_name} </td>
                            <td>
                              {" "}
                              {each_stage.assign_to
                                ? each_stage.assign_to.name
                                : "-"}{" "}
                            </td>
                            <td>
                              {" "}
                              {moment(each_stage.created_at).format(
                                "D-M-Y hh:mm A"
                              )}{" "}
                            </td>

                            <td>
                              {" "}
                              {each_stage.is_active
                                ? "-"
                                : moment(each_stage.updated_at).format(
                                    "D-M-Y hh:mm A"
                                  )}{" "}
                            </td>

                            <td>
                              {" "}
                              {each_stage.is_repeat ? (
                                <i className="fa fa-repeat" title="Repeat"></i>
                              ) : (
                                "-"
                              )}{" "}
                            </td>

                            <td>
                              {" "}
                              {each_stage.is_active ? (
                                <span className="badge badge-primary">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  Complete
                                </span>
                              )}{" "}
                            </td>
                            <td>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => this.showDetails(idx)}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="9"
                              style={{
                                padding:
                                  this.state.collapse === idx ? "0.3em" : 0,
                              }}
                            >
                              <Collapse isOpen={this.state.collapse === idx}>
                                <EachStageDataRender
                                  processStage={each_stage}
                                />
                              </Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </Table>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    processStages,
    individualDetails,
    businessDetails,
  } = state.baPrpcessStages;
  return {
    processStages,
    individualDetails,
    businessDetails,
  };
};

let connected = connect(mapStateToProps)(ProcessStagesListModal);

export { connected as ProcessStagesListModal };


import { websocketConstants } from '../constants';

export function websocket(state = {}, action) {
    switch (action.type) {
      case websocketConstants.WS_CONNECT:
        return action.socket;
      case websocketConstants.WS_DISCONNECT:
        return {};
      default:
        return state
    }
  }
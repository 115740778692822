export const whatsappLogConstants = {
    GET_WHATSAPP_RECEIVE_LOGS_REQUEST: 'GET_WHATSAPP_RECEIVE_LOGS_REQUEST',
    GET_WHATSAPP_RECEIVE_LOGS_SUCCESS: 'GET_WHATSAPP_RECEIVE_LOGS_SUCCESS',
    GET_WHATSAPP_RECEIVE_LOGS_FAILURE: 'GET_WHATSAPP_RECEIVE_LOGS_FAILURE',

    GET_WHATSAPP_SEND_LOGS_REQUEST: 'GET_WHATSAPP_SEND_LOGS_REQUEST',
    GET_WHATSAPP_SEND_LOGS_SUCCESS: 'GET_WHATSAPP_SEND_LOGS_SUCCESS',
    GET_WHATSAPP_SEND_LOGS_FAILURE: 'GET_WHATSAPP_SEND_LOGS_FAILURE',

    GET_WHATSAPP_SCHEDULE_LOGS_REQUEST: 'GET_WHATSAPP_SCHEDULE_LOGS_REQUEST',
    GET_WHATSAPP_SCHEDULE_LOGS_SUCCESS: 'GET_WHATSAPP_SCHEDULE_LOGS_SUCCESS',
    GET_WHATSAPP_SCHEDULE_LOGS_FAILURE: 'GET_WHATSAPP_SCHEDULE_LOGS_FAILURE',

GET_WHATSAPP_SEND_LOGS_RI_REQUEST : 'GET_WHATSAPP_SEND_LOGS_RI_REQUEST' , 
GET_WHATSAPP_SEND_LOGS_RI_SUCCESS : 'GET_WHATSAPP_SEND_LOGS_RI_SUCCESS' , 
    GET_WHATSAPP_SEND_LOGS_RI_FAILURE : 'GET_WHATSAPP_SEND_LOGS_RI_FAILURE' , 
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, Card, CardHeader } from 'reactstrap';
import { salesAction } from "../../actions"
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable';
import { Translate } from "react-localize-redux"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { RenderPrioritesText } from '../CustomerDetail';
import { RenderSalesStatus } from './CommonComponents';

class CustomerSalesStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
            customerData: [],
            userData: []
        }

        this.getCustomerList = this.getCustomerList.bind(this)
        this.editData = this.editData.bind(this)
        this.renderAction = this.renderAction.bind(this)
        this.afterAction = this.afterAction.bind(this)
    }

    componentDidMount() {
        this.getCustomerList()
    }


    getCustomerList() {
        const { dispatch } = this.props;
        dispatch(salesAction.getCustomerList());
    }

    editData(d) {
        this.props.history.push('/customer-sales-status/' + d.id)
    }

    afterAction() {
        this.getCustomerList()
    }

    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <Button size="sm" color="primary" onClick={() => this.editData(row)} > Update  </Button>  {" "}
        </React.Fragment>
    }

    renderCustomerName(cell, row, rowIndex, formateExtraData) {
        return row.first_name + " " + row.last_name
    }
    
    renderPriority(cell, row, rowIndex, formateExtraData) {
        return <RenderPrioritesText priority={row.priority} />
    }

    renderSalesStatus(cell, row, rowIndex, formateExtraData) {
        return <RenderSalesStatus status={row.status}/>;
    }

    render() {
        const { loading, data } = this.props
        const columns = [{
            text: <Translate id='Customer Name' />,
            dataField: 'first_name',
            sort: true,
            filter: textFilter({
                placeholder: "Customer Name",
            }),
            formatter: this.renderCustomerName
        }, {
            text: 'Company Name',
            dataField: 'company_name',
            sort: true,
            filter: textFilter({
                placeholder: "Company Name",
            })
        }, {
            text: 'City',
            dataField: 'address.city',
            sort: true,
            filter: textFilter({
                placeholder: "City",
            })
        },{
            text: 'District',
            dataField: 'address.district',
            sort: true,
            filter: textFilter({
                placeholder: "District",
            })
        },{
            text: 'State',
            dataField: 'address.state',
            sort: true,
            filter: textFilter({
                placeholder: "State",
            })
        }, {
            text: <Translate id='Assign To Sales Person' />,
            dataField: 'assign_to_user.name',
            sort: true,
            filter: textFilter({
                placeholder: "User Name",
            })
        }, {
            text: <Translate id='Status' />,
            dataField: 'test',
            sort: false,
            formatter: this.renderSalesStatus
        }, {
            text: "Last status updated At",
            dataField: 'status.last_date',
            sort: false,
            // filter: dateFilter({
            //     placeholder: "User Name",
            // })
        }, {
            text: "Priority",
            dataField: 'priority',
            sort: false,
            formatter: this.renderPriority
        }, {
            text: <Translate id='Actions' />,
            formatter: this.renderAction,
            dataField: 'action',
        }]
        return (
            <Card>
                <CardHeader>
                    <i className="fa fa-user-circle-o"></i><strong>Customer Sales Status</strong>
                </CardHeader>
                <CardBody>
                    {loading ? <TableLoader /> :
                        <React.Fragment>
                            <DataTable keyField="id" key="id"
                                data={data || []} columns={columns}
                                filter={filterFactory()}
                                noDataIndication={"No data found"} />
                        </React.Fragment>
                    }

                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getCustomerList
    return {
        loading,
        data
    }
}

const connectedCustomerSalesStatus = connect(mapStateToProps)(CustomerSalesStatus)
export { connectedCustomerSalesStatus as CustomerSalesStatus }
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Card, CardBody, CardGroup, Col, Input, InputGroup, FormFeedback, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from 'react-router'

import { authActions } from '../../actions';
import SubmitButton from '../../../components/SubmitButton';

import {
  isLoggedIn, getRedirectToAuthenticatedPage
} from '../../../helpers';


class Login extends Component {


  componentDidMount() {
    window.scrollTo(0, 0)
    if (isLoggedIn()) {
      this.props.history.push( getRedirectToAuthenticatedPage());
    } else {
      document.body.classList.remove('sidebar-lg-show')
      document.body.classList.remove('sidebar-fixed')
    }
  }




  constructor(props) {
    super(props);

    this.state = {
      loginData: {
        username: "",
        password: ""
      },
      errors: {
        usernameError: false,
        usernameErrorMessage: false,
        passwordError: false,
        passwordErrorMessage: false,
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { loginData } = this.state;
    const { dispatch } = this.props;
    if (this.isValidData()) {
      dispatch(authActions.login(loginData.username, loginData.password, this.props));
    }
  }

  isValidData() {
    const { loginData } = this.state;
    let error = false, usernameError, usernameErrorMessage, passwordError, passwordErrorMessage;

    if (!loginData.username) {
      error = true;
      usernameError = true;
      usernameErrorMessage = "Please enter email / mobile number ";
    }

    if (!loginData.password) {
      error = true;
      passwordError = true;
      passwordErrorMessage = "Please enter password ";
    }

    this.setState({
      ...this.state,
      errors: {
        usernameError: usernameError,
        usernameErrorMessage: usernameErrorMessage,
        passwordError: passwordError,
        passwordErrorMessage: passwordErrorMessage,
      },
      submitted: true
    });

    return !error;

  }

  handleChange(event) {
    const { name, value } = event.target;
    const { loginData } = this.state;

    this.setState({
      loginData: {
        ...loginData,
        [name]: value
      }
    });
  }


  render() {

    const {
      loginData,
      errors
    } = this.state;

    const { loggingIn, alert } = this.props;

    return (
      <div className="app-login flex-row align-items-center">
        <Row className="justify justify-content-center w-100">
          <Col md="7" lg="4">
            <Translate>
              {({ translate }) =>
                <CardGroup>
                  <Card className="p-4 "  >
                    <CardBody>
                      <h1 className=" mb-3">  <Translate id="title.login"> Login </Translate> </h1>
                      {alert && alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                      }
                      <form  autocomplete="off" onSubmit={this.handleSubmit}>
                        <div className="mb-4">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text"
                              placeholder={translate("title.username")}
                              value={loginData.username}
                              name={"username"}
                              onChange={this.handleChange} />
                          </InputGroup>

                          {errors.usernameError &&
                            <FormFeedback >
                              {errors.usernameErrorMessage}
                            </FormFeedback>
                          }
                        </div>
                        <div className="mb-4">
                          <InputGroup >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="password"
                              value={loginData.password}
                              name={"password"}
                              onChange={this.handleChange}
                              placeholder={translate("title.password")} />
                          </InputGroup>

                          {errors.passwordError &&
                            <FormFeedback >
                              {errors.passwordErrorMessage}
                            </FormFeedback>
                          }
                        </div>

                        <Row>
                          <Col xs="6">
                            <SubmitButton currentState={loggingIn} name="Login" />
                          </Col>
                          <Col xs="6" className="text-right">
                            <Link to="/forget-password" color="link" className="px-0 btn btn-link"> {translate("title.forget_password_link")} </Link>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                  
                </CardGroup>
              }
            </Translate>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  const { alert } = state;
  return {
    user,
    loggingIn,
    alert,
  };
}

const connectedLogin = withRouter(connect(mapStateToProps)(Login));
export { connectedLogin as Login }

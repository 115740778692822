import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { validReputeIdCorporate } from '../../../../helpers';
import { ReputeIDCompanyInput } from '../../../../components/Forms/Inputs';
class CompanyReputeIDs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            shareholders: [{
                name: ""
            }],
            errors: []
        };

        this.isSet = false
        this.setParentValue = this.setParentValue.bind(this);
    }

    handleShareholderNameChange = idx => evt => {
        const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: "RC-"+ evt.target.value };
        });

        this.setState({ shareholders: newShareholders }, () => {
            this.checkValidaion();
            this.setParentValue(newShareholders);
        });

    };

    setParentValue(value) {
        const { handleChildValue } = this.props
        let data = value.map(a => { return a.name })
        handleChildValue({ name: "company", value: data })
    }

    checkValidaion() {
        const { shareholders } = this.state;

        let errors = shareholders.map(a => {
            if (!a.name) {
                return "This field is required."
            } else if (!validReputeIdCorporate(a.name)) {
                return "Enter valid company repute id."
            }
            return false
        })

        this.setState({ errors });
    }

    handleAddShareholder = () => {
        const { shareholders } = this.state;

        let newShareholders = shareholders.concat([{ name: "" }])

        this.setParentValue(newShareholders);
        this.setState({
            shareholders: newShareholders
        });

    };

    handleRemoveShareholder = idx => () => {
        const { shareholders } = this.state;

        // if(shareholders.length <= 1){
        //     alert("can't remove last one")
        //     return false;
        // }

        let newShareholders = shareholders.filter((s, sidx) => idx !== sidx)

        this.setParentValue(newShareholders);
        this.setState({
            shareholders: newShareholders
        });
    };
    componentDidMount(){
        const { company, errors } = this.props;

        if (company && this.isSet === false) {
            var dataArray = [];
            company.map(d => {
                var md = {
                    name: d
                }
                dataArray.push(md);
                return false;
            })

            this.isSet = true;
            this.setState({
                shareholders: dataArray
            });
        }

        if (errors) {
            this.setState({
                errors
            });
        }

    }

    componentWillReceiveProps(props) {
        const { company, errors } = props;

        if (company && this.isSet === false) {
            var dataArray = [];
            company.map(d => {
                var md = {
                    name: d
                }
                dataArray.push(md);
                return false;
            })

            this.isSet = true;
            this.setState({
                shareholders: dataArray
            });
        }

        if (errors) {
            this.setState({
                errors
            });
        }

    }

    render() {
        const { errors, shareholders } = this.state
        const { disabled } = this.props

        return (
            <div className="row">
                {shareholders.map((shareholder, idx) => (
                    <div key={idx} className="shareholder col-md-4 mb-2" >

                        <InputGroup>
                            <ReputeIDCompanyInput
                                appendAddon={idx >= 0 && !disabled  && <InputGroupAddon addonType="append">
                                    <InputGroupText className="text-danger cursor-pointer" onClick={this.handleRemoveShareholder(idx)}>
                                        <i className="fa fa-remove"></i>
                                    </InputGroupText>
                                </InputGroupAddon>}
                                disabled={disabled}
                                type="text" name="name" placeholder={`Company Repute ID ${idx + 1}`}
                                value={shareholder.name.replace("RC-", "")} handleChange={this.handleShareholderNameChange(idx)} />


                            {errors[idx] && <FormFeedback >
                                {errors[idx]}
                            </FormFeedback>
                            }
                        </InputGroup>

                    </div>
                ))}
                {!disabled && 
                <div className="shareholder col-md-4 mb-2" >
                    <button
                        type="button"
                        onClick={this.handleAddShareholder}
                        className="btn btn-primary" > {shareholders.length === 0 ? "Add Company Repute ID" : "Add another"} </button>
                </div> }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedCompanyReputeIDs = connect(mapStateToProps)(CompanyReputeIDs)
export { connectedCompanyReputeIDs as CompanyReputeIDs }
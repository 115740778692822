import React, { Component } from 'react'
import { Translate } from "react-localize-redux"
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { IndividualTabContentData } from './TabContentData'
import { verificationActions } from '../../../actions';
import { PERMISSIONS } from '../../../constants'
import { userHasPermission } from '../../../../helpers'

const TABS = {
    VERIFIED: "2",
    NOT_VERIFIED: "3",
    ALL: "1",
    JUNK: '4',
    AADHAR_ADDED: '5'
}

class Individual extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: localStorage.getItem("tab_individual") || TABS.NOT_VERIFIED,
            page: {
                activePage: Number(localStorage.getItem("Individual_Verification_page_no")) || 1,
                perPage: 15,
                totalItemsCount: 0
            }
        }

        this.toggle = this.toggle.bind(this)
        this.getData = this.getData.bind(this)

    }

    componentDidMount() {

        if (userHasPermission(PERMISSIONS.INDIVIDUAL_NOT_VERIFIED)) {
            this.setState({ activeTab: TABS.NOT_VERIFIED }, () => this.getData());
        } else if (userHasPermission(PERMISSIONS.INDIVIDUAL_VERIFIED)) {
            this.setState({ activeTab: TABS.VERIFIED }, () => this.getData());
        } else if (userHasPermission(PERMISSIONS.INDIVIDUAL_ALL)) {
            this.setState({ activeTab: TABS.ALL }, () => this.getData());
        } else {
            this.setState({ activeTab: false }, () => this.getData());
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab || localStorage.getItem("tab_individual") !== tab) {
            localStorage.setItem("tab_individual", tab)
            localStorage.setItem('Individual_Verification_page_no', 1)
            this.setState({
                activeTab: tab
            }, function () {
                this.getData();
            })
        }
    }

    getData() {
        const { dispatch } = this.props;

        // let dataToSend = {
        //     type : this.state.activeTab 
        // }
        const { page, activeTab } = this.state;

        if (activeTab === TABS.NOT_VERIFIED && userHasPermission(PERMISSIONS.INDIVIDUAL_NOT_VERIFIED) === false) {
            return false
        }
        if (activeTab === TABS.VERIFIED && userHasPermission(PERMISSIONS.INDIVIDUAL_VERIFIED) === false) {
            return false
        }
        if (activeTab === TABS.ALL && userHasPermission(PERMISSIONS.INDIVIDUAL_ALL) === false) {
            return false
        }

        let dataToSend = {
            active_page: page.activePage,
            per_page: page.perPage,
            type: localStorage.getItem("tab_individual") ? localStorage.getItem("tab_individual") : activeTab
        }
        dispatch(verificationActions.getIndividuals(dataToSend));
    }

    render() {
        return (
            <div>

                <h5 className="mb-4 mt-4 ">
                    <i className="fa fa-users"></i> &nbsp; Individual Verification
                </h5>
                <Nav tabs>

                    {userHasPermission(PERMISSIONS.INDIVIDUAL_NOT_VERIFIED) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_individual") ? localStorage.getItem("tab_individual") === TABS.NOT_VERIFIED : '') || (!localStorage.getItem("tab_individual") && this.state.activeTab === TABS.NOT_VERIFIED) })}
                                onClick={() => { this.toggle(TABS.NOT_VERIFIED) }}
                            >
                                <i className="fa fa-users text-danger"></i> &nbsp; &nbsp;
                                <b>
                                    <Translate id='Not Verified / Partially Verified' />
                                </b>
                            </NavLink>
                        </NavItem>}

                    {userHasPermission(PERMISSIONS.INDIVIDUAL_VERIFIED) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_individual") ? localStorage.getItem("tab_individual") === TABS.VERIFIED : '') || (!localStorage.getItem("tab_individual") ? this.state.activeTab === TABS.VERIFIED : '') })}
                                onClick={() => { this.toggle(TABS.VERIFIED) }}
                            >

                                <i className="fa fa-users text-success"></i> &nbsp;&nbsp;
                                <b><Translate id='Verified' /> </b>
                            </NavLink>
                        </NavItem>}

                    {userHasPermission(PERMISSIONS.INDIVIDUAL_ALL) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: (localStorage.getItem("tab_individual") ? localStorage.getItem("tab_individual") === TABS.ALL : '') || (!localStorage.getItem("tab_individual") && this.state.activeTab === TABS.ALL) })}
                                onClick={() => { this.toggle(TABS.ALL) }}
                            >

                                <i className="fa fa-users text-warning"></i> &nbsp;&nbsp;
                                <b>
                                    <Translate id='All' /></b>
                            </NavLink>
                        </NavItem>}
                    {userHasPermission(PERMISSIONS.INDIVIDUAL_JUNK) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.JUNK })}
                                onClick={() => { this.toggle(TABS.JUNK) }}
                            >

                                <i className="fa fa-users text-primary"></i> &nbsp;&nbsp;
                                <b>
                                    <Translate id='Junk' /></b>
                            </NavLink>
                        </NavItem>}
                    {userHasPermission(PERMISSIONS.INDIVIDUAL_ALL) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.AADHAR_ADDED })}
                                onClick={() => { this.toggle(TABS.AADHAR_ADDED) }}
                            >

                                <i className="fa fa-users text-info"></i> &nbsp;&nbsp;
                                <b>
                                    Aadhar Added
                                </b>
                            </NavLink>
                        </NavItem>}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={TABS.NOT_VERIFIED}>
                        <IndividualTabContentData {...this.state} />
                    </TabPane>
                    <TabPane tabId={TABS.VERIFIED}>
                        <IndividualTabContentData {...this.state} />
                    </TabPane>
                    <TabPane tabId={TABS.ALL}>
                        <IndividualTabContentData {...this.state} />
                    </TabPane>
                    <TabPane tabId={TABS.JUNK}>
                        <IndividualTabContentData {...this.state} />
                    </TabPane>
                    <TabPane tabId={TABS.AADHAR_ADDED}>
                        <IndividualTabContentData {...this.state} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedIndividual = connect(mapStateToProps)(Individual)
export { connectedIndividual as Individual }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormFeedback, FormGroup } from 'reactstrap';
import { handleValidation } from './Validation';
import { adminContentConfigAction } from '../../../actions';
import { TableLoader } from '../../../../components/Loaders';
import CKEditor from '../../../../components/Editor/CKeditor';

class AddAdminContentConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editorHtml: '',
			theme: 'snow',
			saving: false,
			saving: false,
			editData: {
				id: '',
				key: '',
				value: ''
			},
			errors: {},
			isChange: false
		};
		this.getAdminContentConfigtData = this.getAdminContentConfigtData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeHTML = this.handleChangeHTML.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		// const { editData } = this.props
		// if (editData) {
		//     this.setState({ editData: editData })
		// }
		this.getAdminContentConfigtData();
    }
    
    

	getAdminContentConfigtData() {
		const { dispatch, content_key } = this.props;
		let dataToSend = {
			key: content_key
		};
		dispatch(adminContentConfigAction.getAdminContentConfigtData(dataToSend));
	}
	componentWillReceiveProps(props) {
		const { data } = props;
		if (data) {
			this.setState({ editData: data });
        }
        // this.getAdminContentConfigtData()
	}
	handleChange(e) {
		e.preventDefault();
		const { editData } = this.state;
		editData[e.target.name] = e.target.value;
		this.setState({ editData });
	}
	handleChangeHTML(event) {	
		this.setState({
			editData: {
				...this.state.editData,
				value: event.editor.getData()
			}
		});
	}
	handleThemeChange(newTheme) {
		if (newTheme === 'core') newTheme = null;
		this.setState({ theme: newTheme });
	}
	onSubmit() {
		const { editData } = this.state;
		const result = handleValidation(editData);
		this.setState({ errors: result.errors });
		if (result.formIsValid) {
			const { dispatch } = this.props;
			this.setState({ saving: true });
			dispatch(adminContentConfigAction.updateAdminContentConfigData(editData, this));
			this.setState({ saving: false });
		}
	}
	afterSave() {
		this.props.history.push('/admin-content-config');
	}

	render() {
		const { errors, editData, saving, isChange } = this.state;
		const { loading } = this.props;
		return (
			<React.Fragment>
				{loading ? (
					<TableLoader />
				) : (
					<Form  autocomplete="off" >
						<FormGroup>
							<CKEditor
								content={editData.value}
								events={{
									blur: this.handleChangeHTML,
									afterPaste: this.handleChangeHTML,
									change: this.handleChangeHTML
								}}
							/>
							<FormFeedback>{errors[`value`] && errors[`value`]}</FormFeedback>
						</FormGroup>
						<FormGroup>
							<Button color="primary" onClick={this.onSubmit} disabled={saving}>
								{' '}
								{saving ? 'Saving...' : 'Save'}
							</Button>
						</FormGroup>
					</Form>
				)}
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const { loading, data } = state.getAdminContentConfigtData;
	const { user } = state.authentication;
	return {
		loading,
		data,
		authUser: user
	};
}

const connectedAddAdminContentConfig = connect(mapStateToProps)(AddAdminContentConfig);
export { connectedAddAdminContentConfig as AddAdminContentConfig };

import {  axiosAjaxCall } from './common.service'

export const criticalDueService = {
    getDirectCriticalDue,

    
}

function getDirectCriticalDue(data) {
    return axiosAjaxCall("get_critical_due_direct", data);
}

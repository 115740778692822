import classnames from 'classnames'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders'
import { notificationActions } from '../../actions'
import { Requests } from './Requests'

const TABS = {
  Requests: "Requests",
  Alerts: "Alerts",
  Criticals: "Criticals"
}

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: TABS.Requests
    }

    this.toggle = this.toggle.bind(this)
    this.getNotifications = this.getNotifications.bind(this)
  }
  componentDidMount() {
    var qstr = queryString.parse(this.props.location.search)
    var atab = qstr.tab;    
    this.setState({activeTab : atab ? atab : this.state.activeTab }, this.getNotifications)
  }
  getNotifications() {
    const { dispatch } = this.props;
    let dataToSend = {
      category: this.state.activeTab
    }
    
    dispatch(notificationActions.getNotifications(dataToSend))
    this.props.history.push('/notification/notifications')
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, () => {
        this.getNotifications()
      })
    }
  }

  render() {
    const { data, loading, requests_count, alerts_count, criticals_count } = this.props

    return (
      <div>
              <h5 className="mb-4 mt-4">
                    <i className="fa fa-bell"></i> &nbsp; Notifications
                </h5>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.Requests })}
              onClick={() => { this.toggle(TABS.Requests) }}
            >
              <i class="fa fa-bell text-success" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Requests</span> <span className="badge badge-primary">{requests_count ? requests_count : 0} </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.Alerts })}
              onClick={() => { this.toggle(TABS.Alerts) }}
            >
              <i className="fa fa-bell text-warning" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Alerts</span> <span className="badge badge-primary">{alerts_count ? alerts_count : 0} </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TABS.Criticals })}
              onClick={() => { this.toggle(TABS.Criticals) }}
            >
              <i className="fa fa-bell text-danger" style={{ marginRight: '5px' }} /><span style={{ fontWeight: 'bold' }}>Critical</span> <span className="badge badge-primary">{criticals_count ? criticals_count : 0} </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={TABS.Requests}>
            {
              loading ? <TableLoader /> :
                <Requests getNotifications={this.getNotifications} notificationdata={data} />
            }
            
          </TabPane>
          <TabPane tabId={TABS.Alerts}>
            {
              loading ? <TableLoader /> :
                <Requests getNotifications={this.getNotifications} notificationdata={data} />
             }

          </TabPane>
          <TabPane tabId={TABS.Criticals}>
            {
              loading ? <TableLoader /> :
                <Requests getNotifications={this.getNotifications} notificationdata={data} />
              }
            

          </TabPane>
        </TabContent>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getNotifications
  const { user } = state.authentication
  return {
    loading,
    data : data ? data.notification_data : [],
    requests_count : data ? data.requests_count : 0,
    alerts_count : data ? data.alerts_count : 0,
    criticals_count : data ? data.criticals_count : 0,
    authUser: user
  }

}

const connectedNotifications = connect(mapStateToProps)(Notifications)
export { connectedNotifications as Notifications }


import { notify } from 'reapop'
import { cronLogsConstants } from '../constants'
import { cronLogsService } from '../services'

export const cronLogsAction = {
    getCronLogsData
}

function getCronLogsData(data) {
    return dispatch => {
        dispatch(request())

        cronLogsService.getCronLogsData(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: cronLogsConstants.GET_CRONLOGS_DATA_REQUEST } }
    function success(result) { return { type: cronLogsConstants.GET_CRONLOGS_DATA_SUCCESS, result } }
    function failure(error) { return { type: cronLogsConstants.GET_CRONLOGS_DATA_FAILURE, error } }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SMSPackageActions } from "../../actions";
import {  CardBody, Card, CardHeader, Button } from 'reactstrap'
import { TableLoader } from '../../../components/Loaders';
import { DataTable } from '../../../components/Tables/DataTable'
import filterFactory from "react-bootstrap-table2-filter";


class Smspackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getSMSPackage = this.getSMSPackage.bind(this);
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {
        this.getSMSPackage()
    }

    getSMSPackage() {
        const { dispatch } = this.props;
        dispatch(SMSPackageActions.getSMSPackage());
    }
    editData(d) {
        this.props.history.push('/sms-packages/edit/' + d.id)
    }
    renderAction(cell, row, rowIndex, formateExtraData) {
        return <React.Fragment>
            <Button size="sm" color="primary" onClick={() => this.editData(row)} > Edit </Button>  {" "}
        </React.Fragment>
    }
    render() {
        const { data, loading } = this.props
        const columns = [
            {
                text: 'Package Title',
                dataField: 'title',
                sort: true
            },
            {
                text: 'Action',
                dataField:'action',
                formatter: this.renderAction

            }]
        return (
            <Card>
                <CardHeader>
                    <i className="fas fa-boxes"></i><strong>SMS packages</strong>
                </CardHeader>
                <CardBody>
                    {loading ? <TableLoader /> :
                        <div className="">
                            <DataTable keyField="id"
                                data={data || []} columns={columns}
                                filter={filterFactory()}
                                noDataIndication={"No data found"} />
                        </div>
                    }
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getSMSPackage
    const { user } = state.authentication

    return {
        loading,
        data,
        authUser: user

    }
}

const connectedSmspackage = connect(mapStateToProps)(Smspackage)
export { connectedSmspackage as Smspackage }

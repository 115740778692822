import React, { Component } from "react"
import { Route, Switch } from "react-router"
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import { Container } from 'reactstrap'
import { Header } from "../../components"
import Footer from "../../components/Footer.jsx"
import { NotAllowAccess } from "../../components/ErrorPages"
import PrivateRoute from '../../components/PrivateRoute'
import {
    AppFooter,
    AppHeader,
} from '@coreui/react'
import indexRoutes from "../../routes/index.jsx"
import { navigations } from "../../routes/navigations"
import {
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react'
import { getNavigation } from "./Navigations";
import { PERMISSIONS } from "../../individual/constants";
import BootstrapModal from "../../components/Models/BootstrapModal";
import { InformationAlert } from "./InformationAlert"
class UserLayout extends Component {
    render() {
        const { authentication } = this.props
        let navigation = navigations.guest
       
        if (authentication.loggedIn) {
            navigation = getNavigation({ authentication, navigations: navigations.user })
        }
        

        return (
            <div className="app">
                <AppHeader fixed>
                    <Header />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarForm />
                        <AppSidebarNav navConfig={navigation} {...this.props} />
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <div className="mb-4"></div>
                        {/* <AppBreadcrumb appRoutes={indexRoutes} /> */}
                        <Container fluid>
                            <NotificationsSystem theme={theme} />
                            <InformationAlert/>
                            <Switch>
                                {indexRoutes.map((prop, key) => {

                                    // check user has permission or not
                                    if (authentication.loggedIn) {
                                        const { user } = authentication
                                        let permissions = user ? (user.permission ? user.permission.split(',') : []) : [];

                                        // not for super admin
                                        if(permissions[0] !== PERMISSIONS.ALL && ['PROFILE'].indexOf(prop.permission) === -1) {
                                            if (prop.permission && permissions.indexOf(prop.permission) === -1) {
                                                return <Route path={prop.path} key={key} exact component={NotAllowAccess} />;
                                            }
                                        }
                                    }

                                    // view private routes
                                    if (prop.private) {
                                        return <PrivateRoute path={prop.path} key={key} exact component={prop.component} />
                                    }

                                    // \all users can access 
                                    return <Route path={prop.path} key={key} exact component={prop.component} />
                                })}
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <Footer />
                </AppFooter>
                <BootstrapModal/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication
    }
}

const connectedUserLayout = withRouter(connect(mapStateToProps)(UserLayout))
export { connectedUserLayout as UserLayout }
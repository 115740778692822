import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { DocumentPreview } from "./DocumentsPreview";
import { NoDocumentsAvailable } from "../../../../../components/ErrorPages/NoDocumentsAvailable";

const TABS = {
    DOCUMENTS: "documents",
    OTHER_DOCUMENTS: "other_documents",
}

class Documents extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: TABS.DOCUMENTS
        }

        this.toggle = this.toggle.bind(this)

    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }


    render() {

        const { profile } = this.props

        let documents = {};
        if (profile) {
            documents = profile.documents;
        }

        return (
            <div className="">


                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.DOCUMENTS })}
                                onClick={() => { this.toggle(TABS.DOCUMENTS) }}
                            >
                                 <b> <i className="fa fa-files-o"> </i> {" "}  Individual Verification Documents </b>

                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === TABS.OTHER_DOCUMENTS })}
                                onClick={() => { this.toggle(TABS.OTHER_DOCUMENTS) }}
                            >
                                <Translate id='Documents' />
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={TABS.DOCUMENTS}>
                            <div className="row">

                                {documents.hasOwnProperty("PHOTO") || documents.hasOwnProperty("PAN_CARD") || documents.hasOwnProperty("AADHAR_CARD")  ? <React.Fragment>
                                    {documents.hasOwnProperty("PHOTO") ? <div className="col-md-4">
                                        <Card>
                                            <CardHeader>  <b>Photo </b> </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={documents.PHOTO.url}
                                                    style={{ maxHeight: "100%", maxWidth: "100%", height: "300px", margin: "auto" }}
                                                    title={"Document Preview"} alt={"Profile"}/>
                                            </CardBody>
                                        </Card>
                                    </div> : ""}


                                    {documents.hasOwnProperty("PAN_CARD") ? DocumentPreview({
                                        title: "Pan Card",
                                        url: documents.PAN_CARD.url
                                    }) : ""}



                                    {/* {documents.hasOwnProperty("AADHAR_CARD") ? DocumentPreview({
                                        title: "Aadhar Card",
                                        url: documents.AADHAR_CARD.url
                                    }) : ""} */}
                                </React.Fragment>
                                    : <React.Fragment> <NoDocumentsAvailable/> </React.Fragment>}
                            </div>


                        </TabPane>
                        <TabPane tabId={TABS.OTHER_DOCUMENTS}>
                            {/* <div className="row">
                                {other_documents && other_documents.length ? other_documents.map(function (eachDoc) {
                                    return DocumentPreview({
                                        title: eachDoc.value,
                                        url: eachDoc.url
                                    })
                                }) : <React.Fragment> <NoDocumentsAvailable/> </React.Fragment>}

                            </div> */}
                        </TabPane>
                    </TabContent>
                </div>


            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

const connectedDocuments = connect(mapStateToProps)(Documents)
export { connectedDocuments as Documents }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { customerDetailAction } from '../../actions';

import Pagination from "react-js-pagination";
import { Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { hide, show } from 'redux-modal';
import swal from 'sweetalert';
import FormHelpText from '../../../components/Messages/FormHelpText';
import { userHasPermission } from '../../../helpers';
import { PERMISSIONS } from '../../constants';
import Dropdown from './comman';
class CustomerDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			customerData: [],
			userData: [],
			upload: '',
			selectedFile: null,
			page: {
                activePage: 1,
                perPage: 20,
                totalItemsCount: 0
			},
			searchForm: {
				customer_name: '',
				company_name: '',
				mobile:'',
				email:''
			},
			data :{
				sector : ''
			},
			errors: {}
		};
		this.dropdowntoggle = this.dropdowntoggle.bind(this);
		this.getCustomerDetail = this.getCustomerDetail.bind(this);
		this.editData = this.editData.bind(this);
		this.renderAction = this.renderAction.bind(this);
		this.afterAction = this.afterAction.bind(this);
		this.renderAssignedCustomer = this.renderAssignedCustomer.bind(this);
		this.uploadCSV = this.uploadCSV.bind(this);
		this.handelSubmit = this.handelSubmit.bind(this);
		this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
		this.validate = this.validate.bind(this);
		this.uploadFormData = this.uploadFormData.bind(this);
		this.afterUploadCSV = this.afterUploadCSV.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCSV = this.handleChangeCSV.bind(this);
		this.handelSearch = this.handelSearch.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
	}

	componentDidMount() {
		this.getCustomerDetail();
	}

	dropdowntoggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	getCustomerDetail() {
		const { page } = this.state;
		const { searchForm } = this.state
		let dataToSend = {
            active_page: page.activePage,
			per_page: page.perPage,
			customer_name: searchForm.customer_name,
			company_name: searchForm.company_name,
			mobile: searchForm.mobile,
			email: searchForm.email
        }
		const { dispatch } = this.props;
		dispatch(customerDetailAction.getCustomerDetail(dataToSend));
	}
	handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getCustomerDetail());
        
    }
	editData(d) {
		this.props.history.push('/customer-detail/edit/' + d.id);
	}

	deleteData(d) {
		const { dispatch } = this.props;
		var dataToSend = {
			id: d.id
		};
		swal({
			title: 'Are you sure you want to delete ?',
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				// Here delete email id and call action here
				dispatch(customerDetailAction.delteCustomerDetail(dataToSend, this));
			}
		});
	}

	afterAction() {
		this.getCustomerDetail();
	}

	renderAction(row) {
		return (
			<React.Fragment>
				{userHasPermission(PERMISSIONS.EDIT_CUSTOMER_DETAIL) ? (
					<React.Fragment>
						<Button size="sm" color="primary" onClick={() => this.editData(row)}>
							{' '}
							Edit{' '}
						</Button>{' '}
						{' '}
						<Button size="sm" color="danger" onClick={() => this.deleteData(row)}>
							{' '}
							Delete{' '}
						</Button>
					</React.Fragment>
				) : (
					'-'
				)}
			</React.Fragment>
		);
	}

	renderMobile(row) {
		let mobile = row.mobile ? row.mobile +"," : ''
		let m =  mobile.split(',');
		return mobile && m.length > 0 ? (
			<React.Fragment>
				{m.map(function(m) {
					return (
						<span className="" key={m}>
							{' '}
							{m} &nbsp;
						</span>
					);
				})}
			</React.Fragment>
		) : (
			'-'
		);
	}

	renderEmail(row) {
		let email = row.email ? row.email + "," : '' 
		let m = email.split(',');

		return email && m.length > 0 ? (
			<React.Fragment>
				{m.map(function(val) {
					return (
						<span className="" key={val}>
							{' '}
							{val} &nbsp;
						</span>
					);
				})}
			</React.Fragment>
		) : (
			'-'
		);
	}

	renderCustomerName( row) {

		return row.first_name + ' ' + row.last_name;
	}

	renderStatus(row) {
		if (parseInt(row.status, 10) === 1) {
			return <span className="badge badge-success">Registered </span>;
		}
		if (parseInt(row.status, 10) === 2) {
			return <span className="badge badge-danger">Not Interested </span>;
		}
		return '-';
	}
	rensderSector(row) {
		return row &&  row.sector ? row.sector : '-'
	}

	renderAssignedCustomer(row) {
		if (parseInt(row.status, 10) !== 0) {
			return '-';
		}
		return (
			<React.Fragment>
				<Dropdown
					user={this.props.userData}
					current={row}
					key={row.id}
					{...this.props}
					afterAction={this.afterAction}
				/>
			</React.Fragment>
		);
	}
	componentWillReceiveProps(props) {
		const   {customerData,total_records}  = props
		let { page } = this.state		
        page.totalItemsCount = 0;
        if (customerData) {
            page.totalItemsCount = total_records
        }
	}

	fileSelectedHandler(event) {
		if (event.target && event.target.files[0]) {
			this.setState({
				message: event.target.files[0].name,
				selectedFile: event.target.files[0],
				FileSize: event.target.files[0].size
			});
		}
	}
	handelSubmit(e) {
		e.preventDefault();
		const { dispatch } = this.props;
		const { selectedFile, data } = this.state;
		if (!this.validate()) {
			let dataToSend = {
				sector: data.sector,
				csv_file: selectedFile
			};

			// return false;
			
			dispatch(customerDetailAction.uploadCsvData(dataToSend, this));
		}
	}
	resetSearch() {
		const { searchForm } = this.state
		let { page } = this.state
		searchForm.customer_name = "";
		searchForm.company_name = "";
		searchForm.mobile = "";
		searchForm.email = "";
		//page.totalItemsCount = 0;
		this.setState(searchForm, () => this.getCustomerDetail());
	}
	afterUploadCSV(){
		const { dispatch } = this.props;
		let { selectedFile } = this.state;
		dispatch(
			hide('bootstrap')
		);
		selectedFile = null;
		this.setState({ selectedFile });
		this.getCustomerDetail()
	}

	validate() {
		const { selectedFile, errors, data } = this.state;
        let error = false;
        errors.selectedFile="";
		// enter sector
		if(!data.sector)	{
            errors.sector = "Please enter sector";
            error = true;
            alert(errors.sector);

		} else if (!selectedFile) {
            errors.selectedFile = "Please Select CSV file";
            error = true;
            alert(errors.selectedFile);
        }   else if( !selectedFile.name.toLowerCase().endsWith('.csv')) {
            errors.selectedFile = "Please Select CSV file";
            error = true;
            alert(errors.selectedFile);
        }
        this.setState({errors});
		return error;
	}
	uploadFormData() {
		const { data, errors } = this.state;
		return (
			<React.Fragment>
				<Form autocomplete="off" onSubmit={this.handelSubmit}>
					<Row>
						<Col md="12">
							<FormGroup>
								<Input name="sector" placeholder="sector"  onChange={this.handleChangeCSV}
							 />
								{errors[`sector`] && <FormFeedback key="sector"> { errors[`sector`] }</FormFeedback>}

							</FormGroup>
							<FormGroup>
								<Input
									placeholder="Uplod File"
									className="form-control"
									type="file"
									name="selectedFile"
									onChange={this.fileSelectedHandler}
								/>
								{errors[`selectedFile`] && <FormFeedback key="selectedFile"> { errors[`selectedFile`] }</FormFeedback>}
							</FormGroup>
							<FormHelpText> CSV file must be in spedifed format. Download Format </FormHelpText>
						</Col>
					</Row>
					<button className="btn btn-primary btn-sm pull-right"> Upload </button>
				</Form>
			</React.Fragment>
		);
	}
	uploadCSV() {
		const { dispatch } = this.props;
		dispatch(
			show('bootstrap', {
				ModelSize: 'md',
				ModelHeaderText: 'Upload CSV',
				ModelBodyText: this.uploadFormData(),
				isFooter: false
			})
		);
	}

	handleChange(e) {
        const { searchForm } = this.state
        const { name, value } = e.target;
        searchForm[name] = value;

        this.setState({ searchForm })
	}

	handleChangeCSV(e) {
        const { data } = this.state
        const { name, value } = e.target;
        data[name] = value;

        this.setState({ data })
	}
	handelSearch(e) {
		e.preventDefault()
		this.getCustomerDetail()
	}
	render() {
		const { loading, customerData } = this.props;
		const {  page } = this.state
		
		return (
			<Card>
				<CardHeader>
					<i className="fa fa-user-circle-o" />
					<strong>RAW Lead</strong>
				</CardHeader>
				<CardBody>
				<Row>
					<Col sm="10">
						<Form inline autoComplete="off"  onSubmit={this.handelSearch}>
							<FormGroup className="mb-3">
								<Input placeholder="Customer Name" name="customer_name"  onChange={this.handleChange} onBlur={this.handleChange} /> &nbsp;&nbsp;
								<Input placeholder="Company Name" name="company_name"  onChange={this.handleChange} /> &nbsp;&nbsp;
								<Input placeholder="Mobile" name="mobile"  onChange={this.handleChange} /> &nbsp;&nbsp;
								<Input placeholder="Email" name="email"  onChange={this.handleChange} /> &nbsp;&nbsp;
								<Button type="submit" color="primary"> Search</Button>  &nbsp;
								<Button color="secondary" type="reset" onClick={this.resetSearch}> Reset</Button>
							</FormGroup>
						</Form>
					</Col>
					<Col sm="2">
						{userHasPermission(PERMISSIONS.NEW_CUSTOMER) ? (
							<React.Fragment>
								<button
									className="btn btn-primary btn-sm pull-right"
									style={{ 'marginLeft': '5px' }}
									onClick={(e) => this.uploadCSV()}
								>
									{' '}
									Upload CSV{' '}
								</button>
								<Link className="btn btn-primary btn-sm pull-right" to={'/customer-detail/add'}>
									{' '}
									Add New{' '}
								</Link>
								<br />
								<br />
							</React.Fragment>
						) : (
							''
						)}
					</Col>
				</Row>	
				<React.Fragment>
				</React.Fragment>

					{loading ? (
						<TableLoader />
					) : (
						<React.Fragment>
					
							<div class="react-bootstrap-table table-responsive-sm  animated fadeIn">
								<table className="table table-striped table-outline table-striped table-bordered table-sm">
									<thead>
									<tr class="thead-light">
										<th>Customer Name</th>
										<th>Company Name</th>
										<th>Mobile Number</th>
										<th>Email</th>
										<th>Sector</th>
										<th>Assign To Sales Person</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
									</thead>
									<tbody>
										{
											customerData && customerData.length > 0  ? 
												customerData.map((customer) => {
													return (
														<React.Fragment>
															<tr>
																<td>{ this.renderCustomerName(customer) }</td>
																<td>{ customer.company_name }</td>
																<td>{ this.renderMobile(customer) }</td>
																<td>{ this.renderEmail(customer) }</td>
																<td>{ this.rensderSector(customer) }</td>
																<td>{ this.renderAssignedCustomer(customer) }</td>
																<td>{ this.renderStatus(customer) }</td>
																<td>{ this.renderAction(customer) }</td>
															</tr>
														</React.Fragment>
													)
												})
											:<tr><td colSpan="8" className="text-center"> No data found </td></tr>
										}
									</tbody>
								</table>
							</div>
							{page.totalItemsCount <= page.perPage ? "" :
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}
									// hideNavigation={false}
									// hideFirstLastPages={true}
								/>}
     
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { loading, customerData, userData,total_records } = state.getCustomerDetail;
	return {
		loading,
		customerData,
		userData,
		total_records
	};
}

const connectedCustomerDetail = connect(mapStateToProps)(CustomerDetails);
export { connectedCustomerDetail as CustomerDetails };


import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Card, CardBody, CardHeader } from "reactstrap";
import { GenerateTicketForm } from "./GenerateTicketForm";
import { TableLoader } from "../../../../components/Loaders";
import Interweave from "interweave";
import moment from "moment";
import TimeAgo from "react-timeago";
import { TicketStatusActions } from "./TicketStatusActions";
import { TICKET_STATUS } from "../../../constants";
import { IconStatus } from "./components";
import { show, hide } from "redux-modal";
import { SupportStage } from "../../BusinessAccounts/Components/SupportStage";
import { supportActions } from "../../../actions";
import  ProcessStageTimeLineListFormat from "../../DashboardBusinessAccountReports/Components/ProcessStageTimeLineListFormat";
import {DisplayBusinessDetails} from './DisplayBusinessDetails';
import PerfectScrollbar from 'react-perfect-scrollbar'

class ViewTicketDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };

    this.openModal = this.openModal.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentWillReceiveProps(props) {
    if (this.state.isLoaded === false) {
      this.setState({ isLoaded: true });
    }
  }

  openModal() {
    const { dispatch } = this.props;
    dispatch(hide("bootstrap"));
    setTimeout(function () {
      dispatch(
        show("bootstrap", {
          ModelSize: "md",
          ModelHeaderText: "Edit Ticket",
          ModelBodyText: <GenerateTicketForm {...this.props} isEdit={true} />,
          isFooter: false,
        })
      );
    }, 100);
  }

  componentWillReceiveProps(props) {
    const { ticketsDetails } = props;
    if (ticketsDetails && ticketsDetails.currentStageJson) {
      if (ticketsDetails.currentStageJson) {
        this.setState({
          current_stage: JSON.parse(ticketsDetails.currentStageJson.json),
        });
      }

      if (ticketsDetails.business_account) {
        let business_details = ticketsDetails.business_account.business_details_json ? JSON.parse(ticketsDetails.business_account.business_details_json) : []
        let individual_details = ticketsDetails.business_account_individuals;
        this.setState({
          business_details,
          individual_details
        });
      }
    }
  }

  getData() {
    const { dispatch, isEdit, inputParams, ticketsDetails } = this.props;
    dispatch(supportActions.getTicketDetails({ id: ticketsDetails.id }));
  }


  render() {
    const { loading, ticketsDetails, business_details_id } = this.props;
    const { individual_details, business_details } = this.state;
    return loading && this.state.isLoaded === false ? (
      <TableLoader />
    ) : (
      <React.Fragment>
      
        <Card>
          <CardHeader>
            <Row>
              <Col md="4">
                <p>
                  <strong>Subject</strong>
                  <br /> {ticketsDetails.title}
                </p>
                <p>
                  <strong>Description</strong>
                  <br />
                  <div className=" ql-snow">
                    <div className="ql-editor">
                      <Interweave content={ticketsDetails.description} />
                    </div>
                  </div>
                </p>
              </Col>
              <Col md="4">
                <p>
                  <strong>Company</strong>
                  <br /> {ticketsDetails.company_name} (
                  {ticketsDetails.company_formated_repute_id}){" "}
                </p>
                <p>
                  <strong>Individual</strong>
                  <br /> {ticketsDetails.individual_name} (
                  {ticketsDetails.individual_formated_repute_id}){" "}
                </p>
                <p>
                  <strong>Status</strong>:<br />
                  <span className="h6">
                    <IconStatus
                      ticket_status_id={ticketsDetails.ticket_status_id}
                      status_name={ticketsDetails.ticket_status}
                    />
                  </span>
                </p>
              </Col>
              <Col md="4">
                <p>
                  <strong>Created By</strong>
                  <br />
                  {ticketsDetails.created_by_user_name}
                </p>
                <p>
                  <strong>Created At</strong>
                  <br />
                  {moment(new Date(ticketsDetails.created_at)).format(
                    "DD-MM-YYYY hh:mm A"
                  )}{" "}
                  ( <TimeAgo date={ticketsDetails.created_at} /> )
                </p>
                <p>
                  <strong>Last Update</strong>
                  <br />
                  {moment(ticketsDetails.updated_at).format(
                    "DD-MM-YYYY hh:mm A"
                  )}{" "}
                  ( <TimeAgo date={ticketsDetails.updated_at} /> )
                </p>
              </Col>
            </Row>

            <Row>
          <Col md="4"></Col>

          <Col md="8">
            <div class="progress" style={{ height: "22px"}}>
              <div class="progress-bar bg-success" style={{ width: "25%" }}>
                ✓ Open
              </div>
              <div class="progress-bar bg-success  progress-bar-striped progress-bar-animated" style={{ width: "25%" }}>
                ✓ Work in progress
              </div>
              <div class="progress-bar bg-secondary" style={{ width: "25%" }}>
                Complete
              </div>

              <div class="progress-bar bg-secondary" style={{ width: "25%" }}>
                Close
              </div>
            </div>
          </Col>
        </Row>
          </CardHeader>
        </Card>
        
        
        <DisplayBusinessDetails
        
          business_details={business_details}
          individual_details={individual_details}
          business_details_id={business_details_id}
        />
        

        <Row>
          <Col md="6">
            <Card>
              <CardHeader>Tickets History</CardHeader>
              <CardBody style={{height:"500px"}}>
                    <PerfectScrollbar>
                  <ProcessStageTimeLineListFormat processStages={ticketsDetails.ticket_stages}/>
                    </PerfectScrollbar>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <SupportStage
              {...this.props}
              {...this.state}
              ticket_id={ticketsDetails.id}
              individual_id={ticketsDetails.individual_id}
              currentStageJson={ticketsDetails.currentStageJson}
              // individuals={individual_details.individuals}
              getData={this.getData}
              business_details_id={
                ticketsDetails.currentStageJson
                  ? ticketsDetails.currentStageJson.business_account_id
                  : "-"
              }
            />
          </Col>
        </Row>

        
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { ticketsDetails, loading } = state.getTicketDetails;

  return {
    loading,
    ticketsDetails,
  };
}

const connectedViewTicketDetails = connect(mapStateToProps)(ViewTicketDetails);
export { connectedViewTicketDetails as ViewTicketDetails };

import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'react-quill/dist/quill.snow.css'
import { Row, Col, Button, Input, Form, FormFeedback, Card, CardBody, FormGroup, FormText, Collapse } from 'reactstrap'
import { handleValidation } from './Validation'
import { emailActions } from "../../actions";
import { TableLoader } from '../../../components/Loaders';
import { Link } from "react-router-dom";
import CKEditor from "../../../components/Editor/CKeditor";

class AddEmail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorHtml: '',
      theme: 'snow',
      saving: false,
      addEmail: {
        id: '',
        subject: '',
        email_text: '',
      },
      isChange: false,
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this)

    this.getEmails = this.getEmails.bind(this)
    this.handleChangeHTML = this.handleChangeHTML.bind(this)
    this.onSubmitEmail = this.onSubmitEmail.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
  }


  handleChange(e) {
    e.preventDefault()
    const { addEmail } = this.state
    addEmail[e.target.name] = e.target.value
    this.setState({ addEmail })
  }


  componentDidMount() {
    this.getEmails()
  }
  getEmails() {
    const { dispatch } = this.props;
    const values = this.props.match.params.id
    let dataToSend = {
      id: values
    }
    dispatch(emailActions.getEmails(dataToSend))
  }
  componentWillReceiveProps(props) {
    const { data } = props
    if (data) {
      this.setState({
        addEmail: data
      })
    }
  }
  handleChangeHTML(event) {
    
    this.setState({
      addEmail: {
        ...this.state.addEmail,
        email_text: event.editor.getData()
      }
    })
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }

  onSubmitEmail() {
    const { addEmail } = this.state
    const result = handleValidation(addEmail)
    this.setState({ errors: result.errors })
    if (result.formIsValid) {
      this.setState({ saving: true })
      const { dispatch } = this.props;
      dispatch(emailActions.editEmail(addEmail, this));
    }
  }

  afterSave() {
    this.props.history.push('/emails')
  }
  toggleChange(e) {
    e.preventDefault();
    this.setState({
      isChange: true
    });

  }
  render() {

    const { loading } = this.props
    const { errors, addEmail, saving, isChange } = this.state
    return (<div className="">
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <h3 className="text-center mb-4 mt-4"> Update Email </h3>

              {loading ? <TableLoader /> :
                <Form  autocomplete="off" className="col-md-8 offset-md-2">


                  <FormGroup>
                    <label className="font-weight-bold">Subject</label>

                    <Input placeholder="Subject" type="text" rows="5" name="subject" value={addEmail.subject} onChange={this.handleChange} />
                    <FormFeedback>
                      {errors[`subject`] && errors[`subject`]}
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <label className="font-weight-bold">Email Text</label>
                    <CKEditor
                     

                      content={addEmail.email_text}
                      events={{
                        "blur": this.handleChangeHTML,
                        "afterPaste": this.handleChangeHTML,
                        "change": this.handleChangeHTML
                      }}
                    />
                    <FormText style={isChange ? { display: "none" } : {}}> <i> <b className="text-info"> {addEmail.description}  </b> </i>   <a className="text-danger pull-right font-weight-bold cursor-pointer" onClick={this.toggleChange} > Change </a></FormText>

                    <FormFeedback>
                      {errors[`email_text`] && errors[`email_text`]}
                    </FormFeedback>
                  </FormGroup>

                  <Collapse isOpen={isChange}>
                    <FormGroup>
                      <label className="font-weight-bold">Help Text</label>
                      <Input placeholder="Description" type="text" rows="5" name="description" value={addEmail.description} onChange={this.handleChange} />
                      <FormFeedback>
                        {errors[`description`] && errors[`description`]}
                      </FormFeedback>
                    </FormGroup>
                  </Collapse>


                  <FormGroup>
                    <Button color="primary" style={{ 'marginTop': '20px' }} onClick={this.onSubmitEmail} disabled={saving}> {saving ? "Saving..." : "Save Draft"}</Button> {" "}
                    <Link className="btn btn-secondary" style={{ 'marginTop': '20px' }} to={"/emails"}> Cancel </Link>
                  </FormGroup>

                </Form>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    )
  }
}

function mapStateToProps(state) {
  const { loading, data } = state.getEmails
  return {
    loading,
    data : data && data.email_data
  }
}

const connectedAddEmail = connect(mapStateToProps)(AddEmail)
export { connectedAddEmail as AddEmail }

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  Button,  Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { TableLoader } from "../../../components/Loaders";
import { requirementLogsAction } from '../../actions'
import Pagination from "react-js-pagination";
import { AddSuperadminInteraction } from './AddSuperadminInteraction'
import DropdownList from 'react-widgets/lib/DropdownList';
import BuyerDetails from './BuyerDetails';
import Label from 'reactstrap/lib/Label';
class UnregisteredSellers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thisModel: false,
            page : {
                perPage: 20,
                totalItemsCount: 0
            },
            serachField : {
                status : '1'
            }

        }
        this.getData = this.getData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }



    handleChange(e) {
        const { serachField } = this.state
        const { name, value } = e.target;
        serachField[name] = value;

        this.setState({ serachField })
	}

    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getData());
    }

    handleApply(e) {
        e.preventDefault();
        this.getData();
    }

    handleReset() {
        this.setState({
            serachField : {
                status : '1'
            }
        },() => this.getData());
    }

    getData() {
        const { buyer_requirement_id, dispatch } = this.props
        const { page, serachField } = this.state;
        let dataToSend = {
            buyer_requirement_id,
            active_page: page.activePage,
			per_page: page.perPage,
            status : serachField.status
        }
        
        dispatch(requirementLogsAction.getUnregisteredSellerDetail(dataToSend))
    }

    componentWillReceiveProps(props) {
		let { page } = this.state;
		page.totalItemsCount = 0;
		if (props.data) {
			page.totalItemsCount = props.data.total_records;
		}
	
		this.setState({
			page,
		});
	}
    componentDidMount() {
        this.getData()
    }
    render() {
        const { thisModel, page, serachField } = this.state
        const { loading, data, buyer_requirement_id , seller_count, requirement_details} = this.props
        return (
            <React.Fragment>
                        {loading ? <TableLoader /> :
                            (
                                <div className="table-responsive-sm animated fadeIn mt-4">
                                    <h6>Unregistered Sellers</h6>
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        
                                        <thead className="thead-light">
                                            {/* <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th>
                                                    <select className="form-control" name="status" value={serachField.status} onChange={this.handleChange}>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Not Interested</option>
                                                        <option value="3">Done</option>
                                                    </select>
                                                </th>
                                                <th>
                                                <Button color="primary" onClick={(e) => this.handleApply(e)} style={{ 'margin-left': '10px' }}>Fetch</Button>
                                                <Button  color="secondary"  onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>
                                                </th>
                                            </tr> */}
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Owner Name</th>
                                                <th>Owner Mobile</th>
                                                <th>Product Tags</th>
                                                {/* <th>Quote Status</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                            {
                                                data && data.unregirster_sellers_detail.length ?
                                                    data.unregirster_sellers_detail.map(element => {
                                                        return (
                                                            <tr>

                                                                <td>{element.company_name}</td>
                                                                <td>{element.owner_name}</td>
                                                                <td>{element.mobile}</td>
                                                                <td>
                                                                {
                                                                       element.unregistered_seller_product_tag &&  element.unregistered_seller_product_tag.length ?
                                                                       element.unregistered_seller_product_tag.map((eachProduct) => {
                                                                           return eachProduct.product_name
                                                                       }).join(",")
                                                                       :''
                                                                    }
                                                                </td>
                                                                {/* <td>{element.qouteExistStatus}</td> */}
                                                                <td> <AddSuperadminInteraction 
                                                                        buyer_requirement_id={buyer_requirement_id} 
                                                                        invitee_id={element.id} 
                                                                        seller_call_detail={element.seller_call_detail}
                                                                        getData={this.getData}
                                                                    /> 
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="5" className="text-center"> No seller </td></tr>
                                            }

                                        </tbody>
                                    </table>
                                    {page.totalItemsCount <= page.perPage ? (
								''
							) : (
								<Pagination
									activePage={page.activePage}
									itemsCountPerPage={page.perPage}
									totalItemsCount={page.totalItemsCount}
									pageRangeDisplayed={5}
									linkClass="page-link"
									itemClass="page-item"
									onChange={this.handlePageChange}
									
								/>
							)}
                                </div>
                            )}



                                <div className="table-responsive-sm animated fadeIn mt-4">
                                    <h6>Unregistered Sellers By Location matching</h6>
                                    <table className="table table-sm table-condensed table-striped table-bordered table-outline">
                                        
                                        <thead className="thead-light">
                                          
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Owner Name</th>
                                                <th>Owner Mobile</th>
                                                <th>Product Tags</th>
                                                {/* <th>Quote Status</th> */}
                                                <th>Seller Location</th>
                                                <th>Deliver up to (KM)</th>
                                                <th>Distance (Approx)</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                            {
                                                data && data.seller_by_location.length ?
                                                    data.seller_by_location.map(element => {
                                                        return (
                                                            <tr>

                                                                <td>{element.company_name}</td>
                                                                <td>{element.owner_name}</td>
                                                                <td>{element.mobile}</td>
                                                                <td>
                                                                {
                                                                       element.unregistered_seller_product_tag &&  element.unregistered_seller_product_tag.length ?
                                                                       element.unregistered_seller_product_tag.map((eachProduct) => {
                                                                           return eachProduct.product_name
                                                                       }).join(",")
                                                                       :''
                                                                    }
                                                                </td>
                                                                
                                                                <td>{element.location_data} </td>
                                                                <td>{element.radius} KM</td>
                                                                <td>{element.distance} KM</td>
                                                                {/* <td>{element.status}</td> */}
                                                                <td> <AddSuperadminInteraction 
                                                                        buyer_requirement_id={buyer_requirement_id} 
                                                                        invitee_id={element.id} 
                                                                        seller_call_detail={element.seller_call_detail}
                                                                        getData={this.getData}
                                                                    /> 
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan="8" className="text-center"> No seller </td></tr>
                                            }

                                        </tbody>
                                    </table>
                                    </div>

                                   
               
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { data, loading } = state.getUnregisteredSellerDetail
    return { data , loading }
}
const UnregisteredSellersConnected = connect(mapStateToProps)(UnregisteredSellers);
export { UnregisteredSellersConnected as UnregisteredSellers }
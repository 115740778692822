import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { communicationMastersTemplatesActions } from "../../../../actions";
import { InputDropDown, InputText } from "../../StageSetting/Components/FormElements";

let optionWiseitems = {
  CheckList: [
    { title: "", value: "" },
  ]
}

let defaultData = {
  sms_text: "",

  // whatsapp
  media_type: 'text',
  media_url: '',
  whatsapp_title: '',
  whatsapp_text: '',
  active_template_title: '',
  status: '',


}


class CommunicationChannelTemplateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      data: defaultData,
      preview: [],
      previewInputs: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.addField = this.addField.bind(this)
    this.smsPreview = this.smsPreview.bind(this)
    this.whatsappPreview = this.whatsappPreview.bind(this)
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this)
    this.reset = this.reset.bind(this)
    this.setPreview = this.setPreview.bind(this)
    this.handleChangePreview = this.handleChangePreview.bind(this)
  }

  setData(data) {
    if (data) {
      this.setState({ currentData: data });
    }
  }

  handleChange(event) {

    let _dt = event.target
    if (typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { data } = this.state;
    data[name] = value;
    this.setState({ data });
  }

  handleChangePreview(event) {

    let _dt = event.target
    if (typeof event.target === "undefined") {
      _dt = event;
    }
    const { name, value } = _dt;
    const { preview } = this.state;
    preview[name] = value;
    this.setState({ preview });
  }

  save() {
    const { data } = this.state
    const { channelData, getData, dispatch } = this.props;

    let dataToSend = {
      communication_template_master_id: channelData.id,
      template_text: data.sms_text,
      channel_name: "sms",


      media_type: data.media_type,
      media_url: data.media_url,
      whatsapp_title: data.whatsapp_title,
      whatsapp_text: data.whatsapp_text,
      active_template_title: data.active_template_title,
      status: data.status,
    }


    dispatch(communicationMastersTemplatesActions.saveCommunicationMastersTemplates(dataToSend, {
      success: () => {
        getData();
        this.toggle(false);
      }
    }))




  }

  reset() {
    let data = { ...defaultData };
    this.setState({ data });
  }

  toggle(value) {
    const { isEdit, ItemData } = this.props;
    let { data } = this.state;

    if (value === true) {

      const { channelData } = this.props;
      if (channelData.schedule_sms_template) {
        //sms
        data.sms_text = channelData.schedule_sms_template.template_text;

        // whatsapp
        data.whatsapp_text = channelData.schedule_whatsapp_template.template_text;
        data.whatsapp_title = channelData.schedule_whatsapp_template.title;
        data.media_type = channelData.schedule_whatsapp_template.media_type;
        data.media_url = channelData.schedule_whatsapp_template.media_url;
        data.status = channelData.schedule_whatsapp_template.status;
        data.active_template_title = channelData.schedule_whatsapp_template.active_template_title;
      }
      this.setPreview();
    }

    this.setState({ show: value, data }, () => {
    });
  }

  addField(str) {
    let { data } = this.state

    if (str.indexOf("#") !== -1) {
      let sub_str = str.substr(0, str.indexOf("#"));
      data.sms_text = (data.sms_text ? data.sms_text : "") + " {" + sub_str + "} ";
    } else {
      data.sms_text = (data.sms_text ? data.sms_text : "") + " {" + str + "} ";
    }
    this.setState({ data })

  }

  smsPreview() {
    let { preview, data } = this.state
    let _string = data.sms_text;

    if (typeof _string === "string") {
      console.log(preview);

      for (var key in preview) {
        console.log(key);
        console.log(preview[key]);

        var re = new RegExp("{" + key + "}", "gi");

        _string = _string.replace(re, preview[key] ? preview[key] : "{" + key + "}");

      }
    }
    return _string;
  }

  whatsappPreview() {
    let { preview, data } = this.state
    let _string = data.whatsapp_text;

    if (typeof _string === "string") {

      let i = 1;
      for (var key in preview) {
        // console.log(key);
        // console.log(preview[key]);

        // var re = new RegExp("{{" + i + "}}", "gi");
        // console.log(re);
        // console.log(preview[key] );
        // console.log("=---------");
        _string = _string.replace("{{" + i + "}}", preview[key] ? preview[key] : "{{" + i + "}}");

        i++;
      }
    }
    return _string;
  }

  setPreview() {

    const { preview } = this.state;
    const { channelData } = this.props;


    let _tags = []

    if (channelData.tags) {
      _tags = channelData.tags.split(',');
    }

    _tags = _tags.map((tg) => {
      let t = tg.trim()

      if (t.indexOf("#") !== -1) {
        t = t.substr(0, t.indexOf("#"));
      }
      return t
    })

    let _preview = [];
    _tags.map((each) => {
      _preview[each] = "";
    });

    this.setState({
      preview: _preview,
      previewInputs: _tags,
    });

  }

  render() {
    const { show, data, preview, previewInputs } = this.state;
    const { channelData } = this.props;

    let _this = this;

    let _tags = []

    if (channelData.tags) {
      _tags = channelData.tags.split(',');

      _tags = _tags.map((tg) => tg.trim())
    }


    return (
      <React.Fragment>
        <Tooltip title="Manage SMS/Whatsapp Template">
          <button className="btn btn-primary btn-sm " onClick={() => this.toggle(true)}>
            <i className={"fa fa-list"}></i>
          </button>
        </Tooltip>
        <Modal
          isOpen={show}
          size={"lg"}
          toggle={() => this.toggle(false)}
          className="modal-primary" >

          <ModalHeader> Templates For : {channelData.name} </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="6">
                <Card>
                  <CardHeader>SMS</CardHeader>
                  <CardBody>
                    <InputText
                      title={"Sms Text"}
                      placeholder="Sms Text"
                      value={data.sms_text}
                      type="textarea"
                      name="sms_text"
                      onChange={this.handleChange}
                      maxLength="1000"
                      row={8}
                    />
                    <div>
                      Available field: &nbsp;

                      {_tags.map((item) => {
                        return <React.Fragment>
                          <span className={"badge badge-primary cursor-pointer"} onClick={() => _this.addField(item)}>{item}</span> &nbsp;
                        </React.Fragment>
                      })}
                    </div>

                  </CardBody>
                </Card>

              </Col>



              <Col md="6">

                <Card>
                  <CardHeader>WhatsApp</CardHeader>
                  <CardBody>
                    <label>Media type</label>
                    <div className="form-group">

                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="media_type_1"
                          name="media_type"
                          value="text"
                          class="custom-control-input"
                          checked={data.media_type === "text"}
                          onChange={this.handleChange}
                        />
                        <label
                          class="custom-control-label"
                          for="media_type_1"
                        >
                          Text
                        </label>
                      </div>


                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="media_type_2"
                          name="media_type"
                          value="image"
                          class="custom-control-input"
                          checked={data.media_type === "image"}
                          onChange={this.handleChange}
                        />
                        <label
                          class="custom-control-label"
                          for="media_type_2"
                        >
                          Image
                        </label>
                      </div>

                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="media_type_3"
                          name="media_type"
                          value="video"
                          class="custom-control-input"
                          checked={data.media_type === "video"}
                          onChange={this.handleChange}
                        />
                        <label
                          class="custom-control-label"
                          for="media_type_3"
                        >
                          Video
                        </label>
                      </div>

                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="media_type_4"
                          name="media_type"
                          value="document"
                          class="custom-control-input"
                          checked={data.media_type === "document"}
                          onChange={this.handleChange}
                        />
                        <label
                          class="custom-control-label"
                          for="media_type_4"
                        >
                          Document
                        </label>
                      </div>
                    </div>

                    {data.media_type !== "text" ?
                      <InputText
                        title={"Media Url"}
                        placeholder="Media Url"
                        value={data.media_url}
                        name="media_url"
                        onChange={this.handleChange}
                        maxLength="400"
                      // helptext="It should be same as on facebook whatsapp template"
                      /> : false}



                    <InputText
                      title={"WhatsApp template title"}
                      placeholder="WhatsApp template title"
                      value={data.whatsapp_title}
                      name="whatsapp_title"
                      onChange={this.handleChange}
                      maxLength="250"
                      helptext="It should be same as on facebook whatsapp template"
                    />

                    <InputText
                      title={"Alternative active template title (Optinal) "}
                      placeholder="Alternative active template title"
                      value={data.active_template_title}
                      name="active_template_title"
                      onChange={this.handleChange}
                      maxLength="250"
                      helptext="It should be same as on facebook whatsapp template [incase main WhatsApp template title will be disabled use as alternative template name]"
                    />


                    <InputText
                      title={"Template Text"}
                      placeholder="Template Text"
                      value={data.whatsapp_text}
                      type="textarea"
                      name="whatsapp_text"
                      onChange={this.handleChange}
                      maxLength="1000"
                      row={8}
                      helptext="It will show the what content sent in whatsapp while communication"
                    />



                    <InputDropDown
                      title={"Template Status"}
                      placeholder="Template Status"
                      value={data.status}
                      name="status"
                      options={[
                        { title: 'Active', value: 'active' },
                        { title: 'Disabled', value: 'disabled' },
                        { title: 'Paused', value: 'paused' }
                      ]}
                      onChange={this.handleChange}
                      maxLength="250"
                      helptext="It should be same as on facebook whatsapp template status"
                    />

                  </CardBody>
                </Card>

              </Col>
            </Row>

            <Card>
              <CardHeader>Preview</CardHeader>
              <CardBody>
                <label>Preview Values</label>

                {previewInputs.map((item, key) => {
                  return <React.Fragment>
                    <FormGroup>
                      <Input
                        name={item}
                        placeholder={item}
                        value={preview[key]}
                        onChange={_this.handleChangePreview} />
                    </FormGroup>
                  </React.Fragment>
                })}


                <Row>
                  <Col md="6">

                    <label> SMS Preview</label>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {_this.smsPreview()}
                    </span>
                  </Col>

                  <Col md="6">
                    <h5> Whatsapp Preview</h5>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {_this.whatsappPreview()}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="alert alert-success">
              You can use following default variables in template for auto replacement. it will apply while sending sms/whatsapp message<br />
              Default variables <b>business_name</b> , <b>individual_name</b>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            {/* <Button color="warning" onClick={() => this.reset()}>
              Reset
            </Button>{" "} */}
            <Button color="secondary" onClick={() => this.toggle(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedCommunicationChannelTemplateModal = connect(mapStateToProps)(CommunicationChannelTemplateModal);
export { connectedCommunicationChannelTemplateModal as CommunicationChannelTemplateModal };


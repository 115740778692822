import { axiosAjaxCall } from './common.service';

export const MarketingService = {
    fetchResult,
    scheduleMarketing,
    resetSchedule,
    getWhatsappMarketingStatus,
    setWhatsappMarketingStatus,
    WhatsappStopCount, 
    marketingWhatsappAnalysis, 
    getSendedNotifications , 
    SendNotifications ,
    reportValuesModal
}


function getWhatsappMarketingStatus() {
    return axiosAjaxCall("get_whatsapp_marketing_status");
}

function marketingWhatsappAnalysis(data) {
    return axiosAjaxCall("get_leads_count_report" , data );
}

function reportValuesModal(data) {
    return axiosAjaxCall("save-reports-values" , data );
}

function SendNotifications(data) {
    return axiosAjaxCall("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" , data );
}
function getSendedNotifications(data) {
    return axiosAjaxCall("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" , data );
}
function setWhatsappMarketingStatus(data) {
    return axiosAjaxCall("set_whatsapp_marketing_status  ", data);
}
   



function WhatsappStopCount() {
    return axiosAjaxCall("get_whatsapp_activity");
}

function resetSchedule(data) {
    return axiosAjaxCall("reset_schedules", data);
}
function fetchResult(data) {
    return axiosAjaxCall("getScheduleMarketing", data);
}
function scheduleMarketing(data) {
    return axiosAjaxCall("scheduleMarketing", data);
}

 


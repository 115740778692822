import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { connectModal } from 'redux-modal'

class BootstrapModal extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired,
        handleHide: PropTypes.func.isRequired,
        ModelHeaderText: PropTypes.string.isRequired,
        ModelBodyText: PropTypes.string.isRequired,
        ModelSize: PropTypes.string.isRequired,
        ActionFunction: PropTypes.func.isRequired,
        ActionLoading: PropTypes.bool.isRequired,
        ActionButtonText: PropTypes.string.isRequired,
        ActionButtonWaitText: PropTypes.string.isRequired,
        ActionButtonColor: PropTypes.string.isRequired
    };

    render() {
        const { 
            show,
            handleHide,
            ModelHeaderText,
            ModelBodyText,
            ModelSize,
            ActionFunction,
            ActionLoading,
            ActionButtonText,
            ActionButtonWaitText,
            ActionButtonColor,
            isFooter
        } = this.props;

        return (
            <Modal isOpen={show} size={ModelSize || "sm"} >
                <ModalHeader toggle={handleHide} >
                    {ModelHeaderText || "Are you sure ? "}
                </ModalHeader>

                { ModelBodyText &&
                    <ModalBody>
                        {ModelBodyText}
                    </ModalBody>
                }

                {isFooter !== false &&
                <ModalFooter>
                    <Button
                        color={ActionButtonColor || "primary"}
                        onClick={ActionFunction}
                        disabled={ActionLoading}>
                        {ActionLoading ? ( ActionButtonWaitText || "loading") : (ActionButtonText || "Confirm")}
                    </Button>

                    <Button color="secondary" onClick={handleHide}>Cancel</Button>
                </ModalFooter> }
            </Modal>
        );
    }
}

export default connectModal({ name: 'bootstrap' })(BootstrapModal)
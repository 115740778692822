import { validPhone, validReputeIdIndividual, validEmail } from "../../../../helpers";
export const handleCompanyNameValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`company_name`]) {
        formIsValid = false
        errors[`company_name`] = 'Please enter company name.'
    }
    return { errors, formIsValid }
}
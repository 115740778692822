import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button} from 'reactstrap';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { currencyFormat } from '../../../helpers';

class SmsPackageCharges extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                total_sms: '',
                total_amount: '',
                gst_percentage: '',
                igst: '',
                cgst: '',
                utgst: '',
                amount: ''
            },
            errors: {}
        }
        this.isLoad = false;
        this.handleChange = this.handleChange.bind(this)
        this.calculateGST = this.calculateGST.bind(this)
    }

    handleChange(e) {
        e.preventDefault()
        const { formData, errors } = this.state
        const { name, value } = e.target;
        formData[name] = value
        errors[name] = ""
        this.setState({ formData }, () => this.calculateGST())
    }

    componentDidMount() {
        const { detail } = this.props;
        let { formData } = this.state;
        formData = detail;
        this.setState({ formData }, () => this.calculateGST())
    }

    calculateGST() {
        let { formData } = this.state;

        if(formData.amount !="" ) {
            let igst = currencyFormat( parseFloat(formData.amount) * 0.18  ) ;
            let total_amount =currencyFormat(parseFloat(formData.amount) + parseFloat(formData.igst)) ;
            let cgst = currencyFormat(formData.igst / 2);
            let utgst = cgst;
    
            // formData.total_amount = currencyFormat(formData.total_amount);
            formData.igst = igst.replace(/\,/g, "");
            formData.total_amount = total_amount.replace(/\,/g, "");
            formData.cgst = cgst.replace(/\,/g, "");
            formData.utgst =  utgst.replace(/\,/g, "");
        }   else {
            formData.igst = currencyFormat(0);
            formData.total_amount = currencyFormat(0);
            formData.cgst = currencyFormat(0);
            formData.utgst = currencyFormat(0);
        }

        this.setState({ formData })
    }



    render() {

        const { formData } = this.state;
        const { currentIndex, removePaln } = this.props;
        return (
            <tr>                
                <td>
                    <MaskedInput
                        className={"form-control"}
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            minValue: 1
                        })}
                        type="text"
                        name={"total_sms"}
                        value={formData.total_sms}
                        onChange={this.handleChange}
                        onKeyUp={this.handleChange}
                        required="required"
                        placeholder={"Total SMS"} />

                    </td> 
                <td>
                    <MaskedInput
                        className={"form-control"}
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            minValue: 1
                        })}
                        type="text"
                        name={"amount"}
                        value={formData.amount}
                        onChange={this.handleChange}
                        onKeyUp={this.handleChange}
                        required="required"
                        placeholder={"Amount"} />
                </td>
                <td><input type="text" name="igst" className="form-control" readOnly value={ "₹ " + formData.igst} /></td>
                <td><input type="text" name="cgst" className="form-control" readOnly value={"₹ " + formData.cgst} /></td>
                <td><input type="text" name="utgst" className="form-control" readOnly value={"₹ " + formData.utgst} /></td>
                <td><input type="text" name="amount" className="form-control" readOnly value={"₹ " + formData.total_amount} /></td>
                <td><Button color="danger" onClick={() => removePaln(currentIndex)}> Delete </Button></td>
            </tr>
        )
    }
}
function mapStateToProps(state) {
}

const connectedSmsPackageCharges = connect(mapStateToProps)(SmsPackageCharges)
export { connectedSmsPackageCharges as SmsPackageCharges }
import React, { Component } from "react";
import RadioItems from "./RadioItems";
import { Button, Row, Col } from "reactstrap";

export default class Radio extends Component {
  constructor(props) {
    super(props);
    this.addNewOption = this.addNewOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
  }

  addNewOption() {
    const { data, handleChange } = this.props;
    let newitems = [...data.items];
    newitems.push({ title: "", value: false });
    handleChange({ name: "items", value: newitems });
  }

  removeOption(idx) {
    const { data, handleChange } = this.props;
    let olditems = [...data.items];
    if (olditems.length <= 1){
      return false
    }

    // newitems.splice(idx, 1);
    let newitems = [];
    for (let i = 0; i < olditems.length; i++) {
      if (i !== idx) newitems.push(olditems[i]);
    }

    handleChange({ name: "items", value: newitems });
  }

  handleChangeItem(event, index) {
    const { data, handleChange } = this.props;
    let olditems = [...data.items];
    let  _dt = event.target
    if(typeof event.target === "undefined") {
      _dt = event;
    }
    
    const { name, value } = _dt;

    if(name === "value") {
      olditems.value = value;
      handleChange({ name: "items", value: olditems });
    } else{
      olditems[index][name] = value;
      handleChange({ name: "items", value: olditems });
    }
  }

  render() {
    const { data } = this.props;
    let _this = this;
    return (
      <div>
        <h4>Options</h4>
        <Row>
          <Col md="1">
            <label> # </label>
          </Col>
          <Col md="5"> Name </Col>
          <Col md="2">Default Value</Col>
          <Col md="4"> Actions</Col>
        </Row>
        {typeof data.items === "object" &&
          data.items.map(function (item, idx) {
            return (
              <RadioItems
                key={idx}
                idx={idx}
                item={item}
                data={data}
                isShowDelete={(data.items.length > 1)}
                handleChangeItem={_this.handleChangeItem}
                handleChange={_this.props.handleChange}
                removeOption={() => _this.removeOption(idx)}
              />
            );
          })}
        <Button className="btn-sm" color="danger" onClick={this.addNewOption}>
          <i className={"fa fa-plus"}></i> Add Another Item
        </Button>
      </div>
    );
  }
}

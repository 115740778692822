import { notify } from 'reapop'
import { dashboardConstants } from '../constants'
import { dashboardService } from '../services'

export const dashboardActions = {
    getDashboardData,
    getSalseStatusOverviewData,
    getDashboardDataLeadVsRegister,

}



function getDashboardData() {
    return dispatch => {
        dispatch(request())

        dashboardService.getDashboardData()
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_DATA_REQUEST } }
    function success(result) { return { type: dashboardConstants.GET_DASHBOARD_DATA_SUCCESS, result } }
    function failure(error) { return { type: dashboardConstants.GET_DASHBOARD_DATA_FAILURE, error } }
}
function getDashboardDataLeadVsRegister() {
    return dispatch => {
        dispatch(request())

        dashboardService.getDashboardDataLeadVsRegister()
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_REQUEST } }
    function success(result) { return { type: dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_SUCCESS, result } }
    function failure(error) { return { type: dashboardConstants.GET_DASHBOARD_DATA_LEAD_VS_REGISTER_FAILURE, error } }
}
function getSalseStatusOverviewData(data) {
    return dispatch => {
        dispatch(request())

        dashboardService.getSalseStatusOverviewData(data)
            .then(
                verified => dispatch(success(verified)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_SALSE_STATUS_OVERVIEW_REQUEST } }
    function success(result) { return { type: dashboardConstants.GET_SALSE_STATUS_OVERVIEW_SUCCESS, result } }
    function failure(error) { return { type: dashboardConstants.GET_SALSE_STATUS_OVERVIEW_FAILURE, error } }
}

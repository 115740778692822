import {  axiosAjaxCall } from './common.service'

export const FAQService = {
    // addFAQ,
    getFAQ,
    saveFAQ,
    deleteFAQ
}

function getFAQ(data) {
    return axiosAjaxCall("get_faq_data", data );
}

function saveFAQ(data) {
    return axiosAjaxCall("save_faq_data", data);
}

function deleteFAQ(data) {
    return axiosAjaxCall("delete_faq_data", data);
}
import { axiosAjaxCall } from "./common.service";

export const sendBillTransactionService = {
    getSendBillTransaction,
}


function  getSendBillTransaction(data) {    
    return axiosAjaxCall("get-send-bill-transaction", data);
}


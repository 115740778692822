import React, { Component } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import swal from 'sweetalert'
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { withRouter } from 'react-router';
import { documentStatus } from '../../../../constants';
import { verificationActions } from '../../../../actions';
import { AddReason } from './AddReason'
import { Tooltip } from 'react-tippy';

class VerifyDoucment extends Component {
    constructor(props) {
        super(props)
        this.verifyDocument = this.verifyDocument.bind(this)
        this.openModal = this.openModal.bind(this);
    }

    openModal(status) {
        const { dispatch, repute_id, document_type } = this.props;
        let dataToSend = {
            document_type: document_type,
            document_status: status,
            repute_id: repute_id,
            request_from: "individual",
        }
        dispatch(show("bootstrap", {
            ModelSize: "md",
            ModelHeaderText: "Reason ",
            ModelBodyText: <AddReason data={dataToSend} />,
            isFooter: false
        }));
    }

    verifyDocument(status) {
        const { dispatch, repute_id, document_type } = this.props;

        let helptext = "Do you want to Reject ?"

        if (status === documentStatus.ACCEPTED) {
            helptext = "Do you want to Accept ?"
        }

        swal({
            title: "Are you sure ?",
            text: helptext,
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        }).then((confirm) => {
            if (confirm) {
                let dataToSend = {
                    document_type: document_type,
                    document_status: status,
                    repute_id: repute_id,
                    request_from: "individual",
                }
                if (status === documentStatus.REJECTED) {
                    this.openModal(dataToSend)
                }
                else {
                    dispatch(verificationActions.verifyIndividualCompany(dataToSend, this))
                }
            }
        })

    }
    afterAction() {
        const { dispatch, repute_id } = this.props;
        dispatch(verificationActions.getIndividualDetail({ reputeId: repute_id }))
    }

    render() {

        const { status, is_cheque_verified } = this.props;

		let id = Math.random().toString(36).substr(2, 9);
        return (<React.Fragment>
            {status === documentStatus.INREVIEW ?
                <React.Fragment>
                    
                    { !is_cheque_verified ?
                <div className="pull-right">
                    <Tooltip className="btn m-0 p-0" interactive arrow={true} title="Cheque payment is not verified.">
                        <Button className={"btn-success"} size="xs" disabled={true} > ACCEPT </Button>
                        <Button className={"btn-danger"} size="xs" disabled={true} > REJECT </Button>
                    </Tooltip>  
                </div> : <div className="pull-right">
                    <Button className={"btn-success"} size="xs"  onClick={(e) => this.verifyDocument(documentStatus.ACCEPTED)} > ACCEPT </Button>
                    <Button className={"btn-danger"} size="xs" onClick={(e) => this.openModal(documentStatus.REJECTED)} > REJECT </Button>
                </div>}
                 </React.Fragment> : ""}

            
        </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {}
}


const connectedVerifyDoucment = withRouter(connect(mapStateToProps)(VerifyDoucment));

export { connectedVerifyDoucment as VerifyDoucment }
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Form, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router';
import { customerDetailAction } from "../../../actions";
import swal from 'sweetalert';
import { show, hide } from 'redux-modal';
import { handleAddressValidation } from './AddressValidation'
import AddressForm from '../../../../components/Forms/AddressForm';
import { utilityService } from '../../../services'
import { Translate } from "react-localize-redux";
class UpdateAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                id: '',
                address_id: '',
                line1: '',
                line2: '',
                city: '',
                taluka: '',
                pinCode: '',
                district: '',
                state: '',
            },
            errors: {},
        }
        this.handleChange = this.handleChange.bind(this)
        this.getPincodeDetails = this.getPincodeDetails.bind(this)
        this.handelUpdateAddress = this.handelUpdateAddress.bind(this)
        this.afterAction = this.afterAction.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handelCansel = this.handelCansel.bind(this)
    }
    toggle() {
        const { onClose } = this.props
        onClose()
    }


    handleChange(e) {
        e.preventDefault()
        const { formData } = this.state
        const { name, value } = e.target;

        if ( name === "line1" || name === "line2" || name === "city" || name === "state" || name === "taluka") {
            let regex_allowed = /^[a-zA-Z0-9 .,]+$/
            if (value.length && regex_allowed.test(value) === false) {
                return false;
            }
        }


        formData[name] = value;

        this.setState({ formData })
        console.log(formData)
        // const result = handleAddressValidation()
        // this.setState({ errors: result.errors })
    }

    handelCansel() {
        const { dispatch } = this.props;
        dispatch(hide("bootstrap"))
    }

    afterAction() {
        const { dispatch, getCustomerList } = this.props;
        dispatch(hide("bootstrap"))
        getCustomerList()
    }
/*
    *  Function : getPincodeDetails
    *  check pincode is valid or not and set data of pincode 
    */

   getPincodeDetails() {
    const { formData } = this.state;

    let self = this;

    let pincodeRegex = /^[1-9][0-9]{5}$/
    if (!formData.pinCode || pincodeRegex.test(formData.pinCode) === false) {
        self.setState({
            ...self.state,
            errors: {
                ...self.state.errors,
                pinCode: "please enter valid pin code",
            },
        });

        return false;
    }

    utilityService.pincodeDetail(formData.pinCode).then(function (response) {
        if (response.result) {
            self.setState({
                ...self.state,
                errors: {
                    ...self.state.errors,
                    pinCode: "",
                },
                formData: {
                    ...formData,
                    state: response.data.state,
                    district: response.data.district,
                }
            });

        } else {

            self.setState({
                ...self.state,
                errors: {
                    ...self.state.errors,
                    pinCode: response.message,
                }
            });

        }
    });

}
    handelUpdateAddress(e) {
        e.preventDefault();
        const { formData } = this.state;
        const { dispatch } = this.props;

        const result = handleAddressValidation(formData)
        this.setState({ errors: result.errors })
            if (result.formIsValid) {
                swal({
                    title: "Are you sure ?",
                    text: "Do you want to update Address ?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((confirm) => {
                    if (confirm) {
                        formData.type = 'address'
                        dispatch(customerDetailAction.updateCustomerDetilEmail(formData, this));
                    }
                })
            }

        // else {
        //     dispatch(notify({ position: "tc", dismissible: false, message: '', status: "error" }))

        // }

    }
    componentDidMount() {
        const { customerDetail } = this.props;
        let { formData } = this.state;
        if (customerDetail) {
            formData.id = customerDetail.id
            formData.address_id = customerDetail.address_id ? customerDetail.address_id : ''
            formData.line1 = customerDetail.address.line1 ? customerDetail.address.line1 : ''
            formData.line2 = customerDetail.address.line2 ? customerDetail.address.line2 : ''
            formData.city = customerDetail.address.city ? customerDetail.address.city : ''
            formData.taluka = customerDetail.address.taluka ? customerDetail.address.taluka : ''
            formData.pinCode = customerDetail.address.pincode ? customerDetail.address.pincode : ''
            formData.district = customerDetail.address.district ? customerDetail.address.district : ''
            formData.state = customerDetail.address.state ? customerDetail.address.state : ''
            this.setState({ formData })
        }

    }
    render() {
        const { errors, formData } = this.state
        return (
            <Translate>
            {({ translate }) =>
            <React.Fragment>
                <Form autocomplete="off">
                    <Row>
                        <Col md="12">
                            <FormGroup >
                            <AddressForm data={formData} errors={errors} translate={translate}   handleChange={this.handleChange} getPincodeDetails={this.getPincodeDetails} />

                            </FormGroup>
                        </Col>
                    </Row><hr />
                    <button className="btn btn-secondary btn-sm pull-right" onClick={this.handelCansel} style={{'marginLeft':'10px'}} > Cancel </button>
                    <button onClick={this.handelUpdateAddress} className="btn btn-primary btn-sm pull-right"  > Update </button>
                </Form>
            </React.Fragment>
            }
            </Translate>

        )
    }
}

function mapStateToProps(state) {
    const { loading, data, customerDetail } = state.getCustomerStatusData
    return {
        loading,
        data,
        customerDetail
    }
}

const connectedUpdateAddress = withRouter(connect(mapStateToProps)(UpdateAddress))
export { connectedUpdateAddress as UpdateAddress }
import React, { Component } from "react";
import { profileActions, userManagementActions } from "../../actions";
import swal from "sweetalert";
import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    FormFeedback, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";
import { currencyFormat } from "../../../helpers";
import InlineError from "../../../components/Messages/InlineError";
import { connect } from "react-redux";
import { CommissionForm } from "./CommissionForm";


let defaultState = {
    type: "",
    deposit_type: "",
    type_name: '',

    first_recharge: '',
    trail_recharge: '',
    service_recharge: '',
    flat_recharge: '',
    deposit_amount: '',
    associate_deposit: [
        { type: 'Associate', amount: 0 }
    ],
    employee_deposit: [
        { type: 'Employee', amount: 0 }
    ],
    reseller_deposit: [
        { type: 'Reseller S1', amount: 5000 },
        { type: 'Reseller S2', amount: 15000 },
    ],
    partner_deposit: [
        { type: 'Partner P1', amount: 30000 },
        { type: 'Partner P2', amount: 40000 },
        { type: 'Partner P3', amount: 50000 },
    ],
};

class UpgradeUserAccount extends Component {
    constructor(props) {
        super(props);
        let days = new Date().getDate() - 1;
        this.state = {
            modalOpan: false,
            data: {...defaultState},
            errors: {}
        };
        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.proceed = this.proceed.bind(this);
        this.isValidData = this.isValidData.bind(this);
        this.reset = this.reset.bind(this);
    }

    showModal() {
        const { modalOpan, data } = this.state;
        const { userData } = this.props;
        this.setState({ modalOpan: !modalOpan });

        // if (modalOpan == true) {
            const { dispatch } = this.props;
            dispatch(userManagementActions.getUserTypes());
            
            this.reset();

            data.first_recharge = userData.first_recharge
            data.trail_recharge = userData.trail_recharge
            data.service_recharge = userData.service_recharge
            data.flat_recharge = userData.flat_recharge;
    
                this.setState({data});
           
        // }
    }
    componentWillMount(){
     
    }

    reset() {
        this.setState({
            data: {...defaultState},
            errors:{}
        })
    }

    handleChange(event) {
        const { name, value, type } = event.target;

        let { data } = this.state;
        let { UserTypes, authUser } = this.props;
        let val = value;

        if (name === 'type' && UserTypes) {
            UserTypes.map(function (eachType) {
                if (parseInt(value, 10) === parseInt(eachType.id, 10)) {
                    data['type_name'] = eachType.name;
                }
            });
        }

        if (name == 'deposit_type') {
            if (data.type_name == 'Partner') {
                let selected_deposit_type = data['partner_deposit'].find(f => f.type == value);
                data['deposit_amount'] = selected_deposit_type.amount
            }
            if (data.type_name == 'Reseller') {
                let selected_deposit_type = data['reseller_deposit'].find(f => f.type == value);
                data['deposit_amount'] = selected_deposit_type.amount
            }
            if (data.type_name == 'Employee') {
                let selected_deposit_type = data['employee_deposit'].find(f => f.type == value);
                data['deposit_amount'] = selected_deposit_type.amount
            }
            if (data.type_name == 'Associate') {
                let selected_deposit_type = data['associate_deposit'].find(f => f.type == value);
                data['deposit_amount'] = selected_deposit_type.amount
            }
        }


        if (data.type_name == 'Partner' || data.type_name == 'Reseller' || data.type_name == 'Employee' || data.type_name == 'Associate') {
            if (name == 'first_recharge' && (parseFloat(value) > parseFloat(authUser.first_recharge))) {
                return false;
            }
            if (name == 'trail_recharge' && (parseFloat(value) > parseFloat(authUser.trail_recharge))) {
                return false;
            }
            if (name == 'service_recharge' && (parseFloat(value) > parseFloat(authUser.service_recharge))) {
                return false;
            }
            if (name == 'flat_recharge' && (parseFloat(value) > parseFloat(authUser.flat_recharge))) {
                return false;
            }
        }


        data[name] = val;
        this.setState({ data, errors : {} });
    }

    isValidData() {
        const { data, errors } = this.state
        const { authUser } = this.props
        let error = false

        if (data.first_recharge === '') {
            errors['first_recharge'] = 'Please enter first recharge';
        }
        else if (parseFloat(data.first_recharge) > parseFloat(authUser.first_recharge)) {
            errors['first_recharge'] = 'First recharge should be less than ' + authUser.first_recharge + '%';
        }
        if (data.trail_recharge === '') {
            errors['trail_recharge'] = 'Please enter trail recharge';
        }
        else if (parseFloat(data.trail_recharge) > parseFloat(authUser.trail_recharge)) {
            errors['trail_recharge'] = 'Trail recharge should be less than ' + authUser.trail_recharge + '%';
        }
        if (data.service_recharge === '') {
            errors['service_recharge'] = 'Please enter service recharge';
        }
        else if (parseFloat(data.service_recharge) > parseFloat(authUser.service_recharge)) {
            errors['service_recharge'] = 'Service recharge should be less than ' + authUser.service_recharge + '%';
        }
        if (data.flat_recharge === '') {
            errors['flat_recharge'] = 'Please enter flat recharge';
        }
        else if (parseFloat(data.flat_recharge) > parseFloat(authUser.flat_recharge)) {
            errors['flat_recharge'] = 'Flat recharge should be less than ' + authUser.flat_recharge;
        }

        if (data.deposit_type === '') {
            errors['deposit_type'] = 'Please select type';
        }
        if (data.user_type === '') {
            errors['user_type'] = 'Please select type';
        }


        this.setState({ errors })
        
		return Object.keys(errors).length === 0 ? false : true;
    }
    proceed() {
        const { data } = this.state;
        const { dispatch, userData, getData } = this.props;

        if (!this.isValidData()) {
            swal({
                title: "Are you sure ?",
                text: "Do you want to upgrade user account ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancel", "Yes, I am sure!"],
            }).then((confirm) => {
                if (confirm) {
                    let dataTosend = {
                        user_id: userData.id,
                        type: data.type,
                        deposit_type: data.deposit_type,
                        type_name: data.type_name,
                        deposit_amount: data.deposit_amount,

                        flat_recharge: data.flat_recharge,
                        first_recharge: data.first_recharge,
                        trail_recharge: data.trail_recharge,
                        service_recharge: data.service_recharge,
                    }

                    dispatch(
                        profileActions.upgradeUserAccount(dataTosend, {
                            success: () => {
                                this.showModal();

                                dispatch(userManagementActions.getUsers());
                            }
                        })
                    );

                }
            });
        }
    }

    render() {
        const { modalOpan, data, errors } = this.state;
        const { UserTypes, userData, fetching, commission_data, authUser } = this.props;
        const mendetoryFields = {
            color: "red",
            fontSize: "14px",
            marginLeft: "2px",
        };

        let _this = this;
        let currentPlanAmount = userData.deposit_plan ? parseInt(userData.deposit_plan.debit_amount, 10) : 0;
    
        return (
            <React.Fragment>


                <Button className="ml-1" color="danger" size="sm" onClick={_this.showModal} >
                    Upgrade
                </Button>
                <Modal className="modal-primary" isOpen={modalOpan}>
                    <ModalHeader toggle={_this.showModal}> Upgrade User Account </ModalHeader>
                    <ModalBody>

                        <p><b>Current User Type : </b>  <span>{userData.user_type.name}</span></p>
                        <p>
                            <b>Active Deposit Type : </b>  <span> {userData.deposit_plan ? userData.deposit_plan.deposit_type + " ( ₹ " + currencyFormat(userData.deposit_plan.debit_amount) + "}" : "You have no deposit plan"}</span> <br />
                        </p>
                        {userData.deposit_plan ? <React.Fragment>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                                            <i className="fas fa-user-tag" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        type="select"
                                        name="type"
                                        value={data.type}
                                        onChange={this.handleChange}
                                        style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                                    >
                                        <option value="0">Select user type</option>
                                        {UserTypes ? (
                                            UserTypes.map(function (eachType) {
                                                return (
                                                    userData.user_type.name === "Associate" && (eachType.name == "Reseller" || eachType.name == "Partner") ||
                                                        userData.user_type.name === "Employee" && (eachType.name == "Reseller" || eachType.name == "Partner") ||
                                                        userData.user_type.name === "Reseller" && (eachType.name == "Reseller" || eachType.name == "Partner") ||
                                                        userData.user_type.name === "Partner" && (eachType.name == "Partner") ?
                                                        <option key={eachType.id} value={eachType.id}>
                                                            {eachType.name}
                                                        </option> : false
                                                );
                                            })
                                        ) : (
                                            ''
                                        )}
                                    </Input>
                                </InputGroup>
                                {errors.type && <InlineError message={errors.type} />}
                            </FormGroup>
                            {(data.type_name == 'Partner' || data.type_name == 'Reseller' || data.type_name == 'Employee' || data.type_name == 'Associate') ? <React.Fragment>

                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                                                <i className="fa fa-star" />
                                            </InputGroupText>
                                        </InputGroupAddon>


                                        <Input
                                            type="select"
                                            name="deposit_type"
                                            value={data.deposit_type}
                                            onChange={this.handleChange}
                                            style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                                        >
                                            <option value="0">Select deposit type</option>

                                            <React.Fragment>
                                                {data.type_name == 'Partner' ? (
                                                    data.partner_deposit && data.partner_deposit.length && data.partner_deposit.map(function (eachType, index) {
                                                        return (currentPlanAmount < eachType.amount ?
                                                            <option key={index} value={eachType.type}>
                                                                {eachType.type} - {'₹' + currencyFormat(eachType.amount)}
                                                            </option> : false
                                                        );
                                                    })
                                                ) : (
                                                    data.type_name == 'Reseller' ? (
                                                        data.reseller_deposit.map(function (eachType, index) {
                                                            return (currentPlanAmount < eachType.amount ?
                                                                <option key={index} value={eachType.type}>
                                                                    {eachType.type} - {'₹' + currencyFormat(eachType.amount)}
                                                                </option> : false
                                                            );
                                                        })
                                                    )
                                                        : data.type_name == 'Employee' ? (
                                                            data.employee_deposit.map(function (eachType, index) {
                                                                return (
                                                                    <option key={index} value={eachType.type}>
                                                                        {eachType.type} - {'₹' + currencyFormat(eachType.amount)}
                                                                    </option>
                                                                );
                                                            })
                                                        ) :
                                                            data.type_name == 'Associate' ? (
                                                                data.associate_deposit.map(function (eachType, index) {
                                                                    return (
                                                                        <option key={index} value={eachType.type}>
                                                                            {eachType.type} - {'₹' + currencyFormat(eachType.amount)}
                                                                        </option>
                                                                    );
                                                                })
                                                            ) : ''
                                                )}</React.Fragment>
                                        </Input>
                                    </InputGroup>
                                    {errors.deposit_type && <InlineError message={errors.deposit_type} />}
                                </FormGroup>
                                
                        <CommissionForm authUser={authUser} errors={errors} addUser={data} handleChange={this.handleChange} />

                            </React.Fragment> : false}
                        </React.Fragment> : false}



                    </ModalBody>
                    <ModalFooter>
                        {userData.deposit_plan ?
                            <Button color="primary" onClick={this.proceed}>
                                Submit
                            </Button> : false}
                        <Button onClick={_this.showModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {

    const { fetching, commission_data } = state.getCommissionAmounts;
    const { user } = state.authentication

    return {
        authUser: user,
        UserTypes: state.getUserTypes.data,

    }
}

const connectedUpgradeUserAccount = connect(mapStateToProps)(UpgradeUserAccount)
export { connectedUpgradeUserAccount as UpgradeUserAccount }
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card } from 'reactstrap'
import { CustomerForm } from './CustomerForm'
import { customerDetailAction } from "../../actions"
import { TableLoader } from '../../../components/Loaders';
class EditCustomer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        const values = this.props.match.params.id
        let dataToSend = {
            id: values
        }
        const { dispatch } = this.props;
        dispatch(customerDetailAction.getCustomerDetail(dataToSend));
        
    }
    render() {
        const { loading, data } = this.props
       
        return (
            <React.Fragment>

<div className={"row"}>
                    <div className={"col-md-8"}>
            <Card>
                <CardBody>
                    <h3 className="text-center mb-4 mt-4"> Edit Customer Details </h3>
                    {loading ? <TableLoader /> :
                            <CustomerForm detail={data}></CustomerForm> }
                </CardBody>
            </Card>
                            </div>
                            <div className={"col-md-4"}>
                            <Card>
                                <CardBody>
                                    <h3 className="text-center mb-4 mt-4"> What you get ? </h3>
                                
                                </CardBody>
                            </Card>
                    <Card>
                <CardBody>
                    <h3 className="text-center mb-4 mt-4"> What to do ? </h3>
                   
                </CardBody>
            </Card>
                            </div>
                            </div>
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.getCustomerDetail
    return {
        loading,
        data
    }
}

const connectedEditCustomer = connect(mapStateToProps)(EditCustomer)
export { connectedEditCustomer as EditCustomer }

import React from 'react'
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import InlineError from '../../../components/Messages/InlineError';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { DEFAULT_USER_TYPE } from '../../constants/utility.constants'

const renderExampleText = (parrentAssign, assignTo) => { 

	return (
		<React.Fragment>
			{assignTo ? (parseFloat(parrentAssign) - parseFloat(assignTo)) : parrentAssign}
		</React.Fragment>
	)
}

const renderExampleText1 = (parrentAssign, assignTo) => { 

	return (
		<React.Fragment>
			{assignTo ? parseFloat(parrentAssign) : parrentAssign}
		</React.Fragment>
	)
}
export const CommissionForm = ({ errors, addUser, index, handleChange, getCommissionAmounts, fetching,authUser }) => {
	
    return <React.Fragment>
        <table className="table">
				<tr>
					<th>Commission Type</th>
					<th>What you get</th>
					<th>What you give </th>
					<th>NET Profit</th>
				</tr>
				<tr>
					<td>First Recharge</td>
					<td className="text-center">
						{  renderExampleText1( authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.first_recharge ,addUser.first_recharge) }	%	
					</td>

					<td>
						{/* <Input placeholder='First Recharge' name="first_recharge" value={addUser.first_recharge} onChange={handleChange} /> */}
						<InputGroup>
							<InputGroupAddon addonType="prepend" >
								<InputGroupText > % </InputGroupText>
							</InputGroupAddon>
							<MaskedInput
								className="form-control"
								type="text"
								mask={createNumberMask({
									prefix: '',
									postfix: '',
									includeThousandsSeparator: false,
									allowDecimal: true,
									integerLimit: 2,
									minValue: 1
								})}
								placeholder="First Recharge"
								name={"first_recharge"}
								value={addUser.first_recharge}
								guide={false}
								onChange={(e) => handleChange(e, index)} />
						</InputGroup>
						{errors.first_recharge && <InlineError message={errors.first_recharge} />}
					</td>

					<td className="text-center">
						{  renderExampleText( authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.first_recharge ,addUser.first_recharge) }	%	
					</td>
					
				</tr>
				<tr>
					<td>Trail Recharge</td>
					<td className="text-center">
						{   renderExampleText1(authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.trail_recharge ,addUser.trail_recharge) }	%	
					</td>
					<td>
						{/* <Input placeholder='Trail Recharge' name="trail_recharge" value={addUser.trail_recharge} onChange={handleChange} /> */}
						<InputGroup>
							<InputGroupAddon addonType="prepend" >
								<InputGroupText > % </InputGroupText>
							</InputGroupAddon>
							<MaskedInput
								className="form-control"
								type="text"
								mask={createNumberMask({
									prefix: '',
									postfix: '',
									includeThousandsSeparator: false,
									allowDecimal: true,
									integerLimit: 2,
									minValue: 1
								})}
								placeholder="Trail Recharge"
								name={"trail_recharge"}
								value={addUser.trail_recharge}
								guide={false}
								onChange={(e) => handleChange(e, index)} />
						</InputGroup>
						{errors.trail_recharge && <InlineError message={errors.trail_recharge} />}
					</td>

					<td className="text-center">
						{   renderExampleText(authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.trail_recharge ,addUser.trail_recharge) }	%	
					</td>
				</tr>
				<tr>
					<td>Service Recharge</td>
					<td className="text-center">
						{  renderExampleText1(authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.service_recharge ,addUser.service_recharge)  }	%	
					</td>
					<td>
						{/* <Input placeholder='Service Recharge' name="service_recharge" value={addUser.service_recharge} onChange={handleChange} /> */}
						<InputGroup>
							<InputGroupAddon addonType="prepend" >
								<InputGroupText > % </InputGroupText>
							</InputGroupAddon>
							<MaskedInput
								className="form-control"
								type="text"
								mask={createNumberMask({
									prefix: '',
									postfix: '',
									includeThousandsSeparator: false,
									allowDecimal: true,
									integerLimit: 2,
									minValue: 1
								})}
								placeholder="Service Recharge"
								name={"service_recharge"}
								value={addUser.service_recharge}
								guide={false}
								onChange={(e) => handleChange(e, index)} />
						</InputGroup>
						{errors.service_recharge && <InlineError message={errors.service_recharge} />}
					</td>

					<td className="text-center">
						{  renderExampleText(authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? '100' :  authUser.service_recharge ,addUser.service_recharge)  }	%	
					</td>

				</tr>
				<tr>
					<td>Flat Intro Amount</td>
					<td className="text-center">
					₹ {   authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? 0 : renderExampleText1(authUser.flat_recharge ,addUser.flat_recharge) }		
					</td>

					<td>
						{/* <Input placeholder='Flat Intro Amount' name="flat_recharge" value={addUser.flat_recharge} onChange={handleChange} /> */}
						<InputGroup>
							<InputGroupAddon addonType="prepend" >
								<InputGroupText >₹ </InputGroupText>
							</InputGroupAddon>
							<MaskedInput
								className="form-control"
								type="text"
								mask={createNumberMask({
									prefix: '',
									postfix: '',
									includeThousandsSeparator: false,
									allowDecimal: true,
									integerLimit: 4,
									minValue: 1
								})}
								placeholder="Flat Intro Amount"
								name={"flat_recharge"}
								value={addUser.flat_recharge}
								guide={false}
								onChange={(e) => handleChange(e, index)} />
						</InputGroup>
						{errors.flat_recharge && <InlineError message={errors.flat_recharge} />}
					</td>
					<td className="text-center">
					₹ {   authUser && authUser.user_type_name == DEFAULT_USER_TYPE.SUPER_ADMIN ? renderExampleText(0 ,addUser.flat_recharge) : renderExampleText(authUser.flat_recharge ,addUser.flat_recharge) }		
					</td>
				</tr>
			</table>
    </React.Fragment>

}



import { productTagConstants } from "../constants"

export function getProductTags(state = {}, action) {
  switch (action.type) {
    case productTagConstants.GET_PRODUCT_TAG_REQUEST:
      let loading = true;
      if (action.params && action.params.tag_name) {
        loading = false;
      }
      return { loading }
    case productTagConstants.GET_PRODUCT_TAG_SUCCESS:
      return { data: action.result }
    case productTagConstants.GET_PRODUCT_TAG_FAILURE:
      return {}
    default:
      return state
  }
}

export function addEditProductTags(state = {}, action) {
    switch (action.type) {
      case productTagConstants.ADD__EDIT_PRODUCT_TAG_REQUEST:
        return { loading: true }
      case productTagConstants.ADD__EDIT_PRODUCT_TAG_SUCCESS:
        return { data: action.result }
      case productTagConstants.ADD__EDIT_PRODUCT_TAG_FAILURE:
        return {}
      default:
        return state
    }
}

export function deleteProductTags(state = {}, action) {
    switch (action.type) {
      case productTagConstants.DELETE_PRODUCT_TAG_REQUEST:
        return { loading: true }
      case productTagConstants.DELETE_PRODUCT_TAG_SUCCESS:
        return { data: action.result }
      case productTagConstants.DELETE_PRODUCT_TAG_FAILURE:
        return {}
      default:
        return state
    }
}

export function addSuperadminInteraction(state = {}, action) {
  switch (action.type) {
    case productTagConstants.ADD_SUPERADMIN_INTERACTION_REQUEST:
      return { loading: true }
    case productTagConstants.ADD_SUPERADMIN_INTERACTION_SUCCESS:
      return { data: action.result }
    case productTagConstants.ADD_SUPERADMIN_INTERACTION_FAILURE:
      return {}
    default:
      return state
  }
}

export function getUnregisteredSeller(state = {}, action) {
  switch (action.type) {
    case productTagConstants.GET_UNREGISTERED_SELLER_REQUEST:
      let loading = true;
      if (action.params && action.params.tag_name) {
        loading = false;
      }
      return { loading }
    case productTagConstants.GET_UNREGISTERED_SELLER_SUCCESS:
      return { data: action.result }
    case productTagConstants.GET_UNREGISTERED_SELLER_FAILURE:
      return {}
    default:
      return state
  }
}

export function addUnregisteredSeller(state = {}, action) {
  switch (action.type) {
    case productTagConstants.ADD_UNREGISTERED_SELLER_REQUEST:
      let loading = true;
      if (action.params && action.params.tag_name) {
        loading = false;
      }
      return { loading }
    case productTagConstants.ADD_UNREGISTERED_SELLER_SUCCESS:
      return { data: action.result }
    case productTagConstants.ADD_UNREGISTERED_SELLER_FAILUREd:
      return {}
    default:
      return state
  }
}

export function checkDuplicateUnregisteredSeller(state = {}, action) {
  switch (action.type) {
    case productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_REQUEST:
      let loading = true;
      if (action.params && action.params.tag_name) {
        loading = false;
      }
      return { loading }
    case productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_SUCCESS:
      return { data: action.result }
    case productTagConstants.CHECK_DUPLICATE_UNREGISTERED_SELLER_FAILUREd:
      return {}
    default:
      return state
  }
}
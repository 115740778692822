import { findFirst } from "obj-traverse/lib/obj-traverse";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Button,
} from "reactstrap";
import { dashboardReportsActions } from "../../actions";
import { InputDropDown } from "../Settings/StageSetting/Components/FormElements";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { uniqueID } from "../../../helpers";
import TreeDropdown from "./TreeDropdown";
import {ProcessStagesListModal} from "./ProcessStagesListModal";

import moment from 'moment';
import { TableLoader } from "../../../components/Loaders";


class PersonWiseStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        process_name: "",
        start_date: "",
        end_date: "",
        individual: "",
        individual_name: "",
      },
      errors: {},
    };

    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.setIndividual = this.setIndividual.bind(this);
  }

  componentDidMount(){
    this.getData();
  }
  getData() {
    const { data } = this.state;
    const { dispatch } = this.props;

    dispatch(
      dashboardReportsActions.getPersonWiseReportsData({
        // process_name : data.process_name,
        individual: data.individual,
        individual_name: data.individual_name,
        // start_date : data.startDate ? data.startDate.format('YYYY-MM-DD') : "",
        // end_date : data.startDate ?  data.endDate.format('YYYY-MM-DD') : "",
      })
    );
  }


  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }

    const { name, value } = _dt;
    const { data } = this.state;

    data[name] = value;
    this.setState(data);
  }

  handleEvent(event, picker) {
    var start = picker.startDate.format("DD-MMM-Y");
    var end = picker.endDate.format("DD-MMM-Y");
    let label = start + " to " + end;
    if (start === end) {
      label = start;
    }

    const { data } = this.state;

    data.startDate = picker.startDate;
    data.endDate = picker.endDate;

    this.setState({
      data,
      serachFieldLabal: label,
    });
  }

  setIndividual(individual) {
    const { data } = this.state;
    // data.individual = individual;
    
    let _data = JSON.parse(individual);

    data.individual = _data.id;
    data.individual_name = _data.name;

    this.setState({ data });
  }

  componentWillReceiveProps(props) {
    const { processes, users_tree } = this.props;
    const { data } = this.state;

    if(users_tree && !data.individual_name){
      data.individual = users_tree.id;
      data.individual_name = users_tree.name;
      
      this.setState({ data });
      
    }
    

    if (processes && processes.length === 1) {
      data.process_name = processes[0].process_name;
    }

    this.setState({ data });

  }

  render() {
    const { businessAccounts, users_tree, params, loading } = this.props;
    const { data, errors, serachFieldLabal } = this.state;

    return (
      <div>
        <Row>
          <Col md="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md="12">Business Account Process Stage Status</Col>
                      <Col md="6"></Col>
                    </Row>
                  </CardHeader>

                  {users_tree &&
              users_tree.hasOwnProperty("all_report_by_persons") &&
              users_tree.all_report_by_persons.length > 0 && (
                  <CardHeader>
                    <TreeDropdown
                      users={users_tree.all_report_by_persons || []}
                      parentUserID={users_tree}
                      setIndividual={this.setIndividual}
                    />
                    &nbsp; &nbsp;{" "}
                    <Button color="primary" onClick={() => this.getData()}>
                      Get Data
                    </Button>
                  </CardHeader>
                  
              )}
                  {loading ? <TableLoader /> :businessAccounts && (
                    <CardBody>
                      <p>
                      Result For Person {params.individual_name ? params.individual_name : users_tree.name  }
                      </p>
                    <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                      <tr>
                        <td>Business Name</td>
                        <td>Cureent Process Stage</td>
                        <td>Created on </td>
                        <td> Days </td>
                        <td>Actions</td>
                      </tr>
                      {businessAccounts.length > 0 ? businessAccounts.map((each_business) => {

                        return <tr>
                        <td> {each_business.name} </td>
                        <td> {each_business.process_stage ? <span> 

                          {each_business.process_stage.process_name} &nbsp; <i className="fa fa-caret-right"></i> &nbsp; { each_business.process_stage.stage_name}
                           
                        </span>
                          : "Not Available" } </td>
                          
                        <td> {each_business.process_stage ? <span> {moment(each_business.process_stage.created_at).format("D-M-Y hh:mm A") } </span> : "-" } 
                          </td>
                          
                        <td> {each_business.process_stage ? <span> {moment.duration(moment().startOf('day').diff(moment(each_business.process_stage.created_at,"YYYY-MM-DD"))).asDays() } </span> : "-" } 
                          </td>
                        <td>
                          <ProcessStagesListModal business_account={each_business}/>
                        </td>
                      </tr>
                      }) : 
                      <tr>  
                      <td colSpan="5"> No data found
                      </td>
                    </tr>}
                    </table>
                    </CardBody>
                  )}
                </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dashboardReports } = state;
  const { personWiseReports } = state;
  return {
    loading : personWiseReports.hasOwnProperty("loading") ? personWiseReports.loading : false,
    params : personWiseReports.hasOwnProperty("params")
    ? personWiseReports.params
    : {},
    businessAccounts : personWiseReports.hasOwnProperty("businessAccounts")
    ? personWiseReports.businessAccounts
    : [],
    users_tree: dashboardReports.hasOwnProperty("users_tree")
      ? dashboardReports.users_tree
      : false,
  };
};

const connected = connect(mapStateToProps)(PersonWiseStatus);
export { connected as PersonWiseStatus };

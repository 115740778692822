import { validPhone, validReputeIdIndividual, validEmail } from "../../../helpers";

export const handleValidation = (data) => {
    const fields = data
    const errors = {}
    let formIsValid = true

    if (!fields[`first_name`]) {
        formIsValid = false
        errors[`first_name`] = 'Please enter first name.'
    }

    if (!fields[`last_name`]) {
        formIsValid = false
        errors[`last_name`] = 'Please enter last name.'
    }

    if (!fields[`company_name`]) {
        formIsValid = false
        errors[`company_name`] = 'Please enter company name.'
    }
    
    if (!fields[`pinCode`]) {
        formIsValid = false
        errors[`pinCode`] = 'Please enter valid pincode'
    }
   


    if (fields[`mobile`]) {
        errors[`mobile`] = [];
        fields[`mobile`].map( function( eachMobile,  index ) {
            if (!eachMobile) {
                formIsValid = false
                errors[`mobile`][index] = "This field is required."
            } else if (!validPhone(eachMobile)) {
                formIsValid = false
                errors[`mobile`][index]  =  "Enter valid mobile number ."
            }
            return false;
        })
    }

    if (fields[`email`]) {
        errors[`email`] = [];
        fields[`email`].map( function( eachMobile,  index ) {
            if (!eachMobile) {
                formIsValid = false
                errors[`email`][index] = "This field is required."
            } else if (!validEmail(eachMobile)) {
                formIsValid = false
                errors[`email`][index]  = "Enter valid email address."
            }
            return false;
        })
    }

    if (!fields[`preferred_language`] || fields[`preferred_language`] === "0" || fields[`preferred_language`] === 0) {
        formIsValid = false
        errors[`preferred_language`] = 'Please select prefered language'
    } else if (fields[`preferred_language`] === "Other") {
        if (!fields["other_language"]) {
            formIsValid = false
            errors[`other_language`] = 'Please enter preferred language'
        }
    }

    

    if (fields[`referrer_repute_id`]) {
        if (!validReputeIdIndividual("RI-" + fields["referrer_repute_id"])) {
            formIsValid = false
            errors[`referrer_repute_id`] = 'Please enter valid repute id'
        }
    }

    if (fields[`referrer_mobile_number`]) {
        if (!validPhone(fields["referrer_mobile_number"])) {
            formIsValid = false
            errors[`referrer_mobile_number`] = 'Please enter valid phone number'
        }
    }

    return { errors, formIsValid }
}
import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import swal from 'sweetalert';
import { customerDetailAction } from '../../actions';

export default class Dropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			isAssign: ''
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { user, current } = this.props;
		if (user) {
			user.forEach((u) => {
				if (current.assign_userId === u.id) {
					this.setState({
						id: user.id,
						isAssign: current.assign_userId
					});
				}
			});
		}
	}

	handleChange(e) {
		e.preventDefault();
		const { dispatch, current } = this.props;
		const { id, value } = e.target;
		swal({
			title: 'Are you sure you want to assign this customer?',
			// text: "Once deleted, you will not be able to recover this user!",
			icon: 'warning',
			buttons: true,
			dangerMode: true
		}).then((willDelete) => {
			if (willDelete) {
				var dataTosend = {
					user_id: value,
					customer_id: current.id
				};
				dispatch(customerDetailAction.assignCustomerDetail(dataTosend, this));
				this.setState({ id: id, isAssign: value });
			}
		});
	}

	render() {
		const { id, isAssign } = this.state;
		const { user } = this.props;
		return (
			<FormGroup style={{ maxWidth: '150px', marginBottom: '0px' }}>
				<Input
					type="select"
					name="isAssign"
					onChange={this.handleChange}
					size="sm"
					id={id}
					value={isAssign}
					style={{ width: '130px' }}
				>
					<option value="">Not Assign</option>
					{user ? (
						user.map((u) => {
							return (
								<option key={u.id} value={u.id}>
									{u.name}
								</option>
							);
						})
					) : (
						''
					)}
				</Input>
			</FormGroup>
		);
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Button, Input } from 'reactstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { currencyFormat } from '../../../helpers';

class ReputeinfoBalanceCharge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                mrp: '',
                offer_label: '',
                percentage: '',
                total_sms: '',
                total_amount: '',
                gst_percentage: '',
                igst: '',
                cgst: '',
                utgst: '',
                amount: '',
                balance: '',
                is_visible_sa_only : false,
                type: '',
                years: '',
            },
            errors: {}
        }
        this.isLoad = false;
        this.handleChange = this.handleChange.bind(this)
        this.calculateGST = this.calculateGST.bind(this)
    }

    handleChange(e) {
        e.preventDefault()
        const { formData, errors } = this.state
        const { name, value, type} = e.target;
        let val = value

        if(name  == 'discount' && value) {
            var x = parseFloat(value);
            if (isNaN(x) || x < 0 || x > 100) {
                return false
            }
        }

        if (type === "checkbox") {
            if (formData[name] === false) {
              val = true;
            } else if (formData[name] === true) {
              val = false;
            } else {
              val = false;
            }
        }
        formData[name] = val
        errors[name] = ""
        this.setState({ formData }, () => this.calculateGST())
    }

    componentDidMount() {
        const { detail } = this.props;
        let { formData } = this.state;
        formData = detail;
        this.setState({ formData }, () => this.calculateGST())
    }

    calculateGST() {
        let { formData } = this.state;

        if(formData.mrp !=""  ) {
            
             let discount = formData.discount == '' ? 0 : formData.discount;
            let discount_amount = currencyFormat(parseFloat(formData.mrp) *  (parseFloat(discount) / 100)).replace(/\,/g, "");
            let total_amount = currencyFormat(parseFloat(formData.mrp ) - discount_amount).replace(/\,/g, "");
            let igst = currencyFormat(total_amount - (total_amount * (100 / (100+18)))).replace(/\,/g, "");
            let amount = currencyFormat(total_amount -  igst).replace(/\,/g, "");
            let cgst = currencyFormat(igst / 2).replace(/\,/g, "");
            let utgst = cgst;
    
            // ecluding
            // let amount = currencyFormat(parseFloat(formData.mrp) -  discount_amount).replace(/\,/g, "");
            // let igst = currencyFormat( parseFloat(amount) * 0.18  ).replace(/\,/g, "") ;
            // let total_amount = currencyFormat(parseFloat(formData.amount) + parseFloat(formData.igst)).replace(/\,/g, "") ;
            // let cgst = currencyFormat(formData.igst / 2).replace(/\,/g, "");
            // let utgst = cgst;
    
            // formData.total_amount = currencyFormat(formData.total_amount);
            formData.amount = amount;
            formData.igst = igst;
            formData.total_amount = total_amount;
            formData.cgst = cgst;
            formData.utgst =  utgst;
        }   else {
            formData.amount = currencyFormat(0);
            formData.igst = currencyFormat(0);
            formData.total_amount = currencyFormat(0);
            formData.cgst = currencyFormat(0);
            formData.utgst = currencyFormat(0);
        }
            
        this.setState({ formData })
    }



    render() {

        const { formData } = this.state;
        const { currentIndex, removePaln } = this.props;
        return (
            <tr>                
                <td>
                    <Input type="text" className="form-control" name="offer_label" 
                    onChange={this.handleChange } value={formData.offer_label} />
                    </td>
                <td>
                    <MaskedInput
                        className={"form-control form-control-sm"}
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            minValue: 1
                        })}
                        type="text"
                        name={"balance"}
                        value={formData.balance}
                        onChange={this.handleChange}
                        onKeyUp={this.handleChange}
                        required="required"
                        placeholder={"Balance"} />
                </td>                
                <td>
                    <MaskedInput 
                        className={"form-control form-control-sm"}
                        mask={createNumberMask({
                            prefix: '',
                            postfix: '',
                            includeThousandsSeparator: false,
                            allowDecimal: true,
                            minValue: 1
                        })}
                        type="text"
                        name={"mrp"}
                        value={formData.mrp}
                        onChange={this.handleChange}
                        onKeyUp={this.handleChange}
                        required="required"
                        placeholder={"Amount"} />
                </td>
                <td>
                <input type="text" name="discount" placeholder={"%"} className="form-control form-control-sm" onChange={this.handleChange} value={formData.discount} />
                
                </td>
                <td><input type="text" name="amount" placeholder={"Amount"} className="form-control form-control-sm" readOnly value={ "₹ " + currencyFormat(formData.amount)} /></td>
                <td><input type="text" name="igst" className="form-control form-control-sm" readOnly value={ "₹ " + currencyFormat(formData.igst)} /></td>
                <td><input type="text" name="cgst" className="form-control form-control-sm" readOnly value={"₹ " +  currencyFormat(formData.cgst)} /></td>
                <td><input type="text" name="utgst" className="form-control form-control-sm" readOnly value={"₹ " +  currencyFormat(formData.utgst)} /></td>
                <td><input type="text" name="amount" className="form-control form-control-sm" readOnly value={"₹ " +  currencyFormat(formData.total_amount)} /></td>
                <td style={{verticalAlign:'middle', textAlign: 'center'}}>
                    <input type="checkbox" name="is_visible_sa_only" checked={formData.is_visible_sa_only} onChange={this.handleChange} />
                    </td>
                <td>
                <Input type='select' className='form-control form-control-sm' name="type" value={formData.type}  onChange={this.handleChange} >
                    <option value={0}>Balance</option>
                    <option value={1}>Registration/Renew</option>
                </Input>

                </td>
                <td style={{width:'60px'}}>
                        <Input type='select' className='form-control form-control-sm' name="subscription_year" value={formData.subscription_year} onChange={this.handleChange} >
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </Input>
                </td>
                <td><Button className="btn-sm" color="danger" onClick={() => removePaln(currentIndex)}> <i className='fa fa-trash'></i> </Button></td>
            </tr>
        )
    }
}
function mapStateToProps(state) {
}

const connectedReputeinfoBalanceCharge = connect(mapStateToProps)(ReputeinfoBalanceCharge)
export { connectedReputeinfoBalanceCharge as ReputeinfoBalanceCharge };

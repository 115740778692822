import { leadsConstants } from "../constants"

export function getLeads(state = {}, action) {
  console.log(action,"chexck");
    switch (action.type) {
      case leadsConstants.GET_LEADS_REQUEST:
          return { loading: true }
      case leadsConstants.GET_LEADS_SUCCESS:
        return { data: action.result }
      case leadsConstants.GET_LEADS_FAIL:
        return {}
      default:
        return state
    }
  }
import React from 'react';
import { Translate } from "react-localize-redux";
import { PERMISSIONS } from '../individual/constants';

export const navigations = {
    guest: {
        items: [
            {
                name: <Translate id='Login' />,
                url: '/login',
                icon: 'icon-login',
            }
        ]
    },
    user: {
        items: [
            {
                menu_id: PERMISSIONS.DASHBOARD,
                name: <Translate id='Dashboard' />,
                url: '/dashboard',
                icon: 'icon-speedometer',
            },
            {
                menu_id: PERMISSIONS.DAILY_MIS,
                name: 'Daily MIS',
                url: '/daily-mis',
                icon: 'far fa-chart-bar',
            },
            {
                menu_id: PERMISSIONS.EXPENSES,
                name: 'Expenses',
                url: '/expenses',
                icon: 'fas fa-coins',
            },

            {
                menu_id: PERMISSIONS.RECENT_REGISTRATIONS,
                name: 'Recent Registrations',
                url: '/recent-registrations',
                icon: 'fa fa-user-o',

            },
            {
                menu_id: PERMISSIONS.USER_ACTIVITY,
                name: 'User Activity',
                url: '/user_activity',
                icon: 'fas fa-chalkboard-teacher',

            },
            // {
            //     menu_id: PERMISSIONS.FINANCE_STATUS,
            //     name: 'Finance Status',
            //     url: '/finance_status',
            //     icon: 'fas fa-chart-line',

            // },
            {
                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                name: 'Business Accounts',
                url: '/business-account',
                icon: 'fa fa-list',
            },
            {
                menu_id: PERMISSIONS.SEND_BILL_TRANSACTION,
                name: 'Send Bill Trasaction',
                url: '/send-bill-trasanction',
                icon: 'nav-icon fas fa-file-contract',
            },
            {
                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                name: 'Marketing',
                url: '/marketing',
                icon: 'fa fa-tags',
            },
            {
                menu_id: PERMISSIONS.PROMOCODE,
                name: 'Promo Code',
                url: '/promo-code',
                icon: 'fa fa-gift',
            },
            {
                menu_id: PERMISSIONS.LANDING_PAGE,
                name: 'Landing Page',
                url: '/landing-page',
                icon: 'fas fa-ad',
            },


            {
                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                name: 'Tasks',
                url: '/tasks',
                icon: 'fa fa-list',

            },
            {
                menu_id: PERMISSIONS.VERIFICATION,
                name: <Translate id='Verification' />,
                url: '/verification',
                icon: 'fa fa-check-circle',
                children: [
                    {
                        menu_id: PERMISSIONS.INDIVIDUAL,
                        name: <Translate id='Individual' />,
                        url: '/verification/individual',
                        icon: 'fa fa-user',
                    },
                    {
                        menu_id: PERMISSIONS.COMPANY,
                        name: <Translate id='Company' />,
                        url: '/verification/company',
                        icon: 'fa fa-building',
                    },
                ]
            },




            {
                menu_id: PERMISSIONS.ACCOUNTING,
                name: <Translate id='Accounting' />,
                url: '/income-statement',
                icon: 'fa fa-book',
                children: [{
                    menu_id: PERMISSIONS.ACCOUNT_LIST,
                    name: 'Income Statement',
                    url: '/income-statement',
                    icon: 'fas fa-money-check-alt',
                }, {
                    menu_id: PERMISSIONS.USERS_SALARY,
                    name: "Users Commission Report",
                    url: '/users-commission-report',
                    icon: 'fas fa-money-check-alt',
                },
                {
                    menu_id: PERMISSIONS.ExpenceStatement,
                    name: "Expense Statement",
                    url: '/expense-type',
                    icon: 'fas fa-money',
                },
                {
                    menu_id: PERMISSIONS.COMPANY_LIST,
                    name: 'Company List',
                    url: '/company-list',
                    icon: 'fas fa-money-check-alt',
                }
                ]
            },

            {
                menu_id: PERMISSIONS.USER_MANAGEMENT,
                name: <Translate id='User Management' />,
                url: '/user-management',
                icon: 'fa fa-users',
                children: [
                    {
                        menu_id: PERMISSIONS.ADD_USER,
                        name: 'Add User',
                        url: '/user-management/add-user',
                        icon: 'fa fa-user-plus',
                    },
                    {
                        menu_id: PERMISSIONS.LIST_USER,
                        name: 'Users',
                        url: '/user-management/users',
                        icon: 'fa fa-users',
                    },
                    {
                        menu_id: PERMISSIONS.USER_TYPES,
                        name: 'User Types',
                        url: '/user-management/users-type',
                        icon: 'fa fa-circle-o',
                    },
                    {
                        menu_id: PERMISSIONS.USER_TYPES,
                        name: 'Departments',
                        url: '/user-management/departments',
                        icon: 'fa fa-circle-o',
                    },
                    {
                        menu_id: PERMISSIONS.USER_TYPES,
                        name: 'Job Titles',
                        url: '/user-management/job-titles',
                        icon: 'fa fa-circle-o',
                    }
                ]
            },
            {
                menu_id: PERMISSIONS.RI_COMMUNICATION,
                name: 'RI Sub Admin',
                url: '/sub-admin',
                icon: 'fa fa-envelope',
                children: [
                // {
                //     menu_id: PERMISSIONS.EMAIL,
                //     name: <Translate id='Email' />,
                //     url: '/emails',
                //     icon: 'fa fa-envelope'
                // },
                // {
                //     menu_id: PERMISSIONS.SMS,
                //     name: 'SMS/Voice',
                //     url: '/sms',
                //     icon: 'fa fa-comment',
                // },                
                // {
                //     name: <Translate id='Notifications' />,
                //     url: '/notification/notifications',
                //     icon: 'fa fa-bell',
                //     menu_id: PERMISSIONS.LIST_NOTIFICATION,
                // },
                {
                    menu_id: PERMISSIONS.LEADS,
                    name: 'Leads',
                    url: '/leads',
                    icon: 'fa fa-comment',
                },
                {
                    menu_id: PERMISSIONS.PRODUCTS,
                    name: 'Products',
                    url: '/products',
                    icon: 'nav-icon fa  fa-shopping-cart',
                },
               ]
            },
            {
                menu_id: PERMISSIONS.RI_COMMUNICATION,
                name: 'RI Communications',
                url: '/comunictions',
                icon: 'fa fa-envelope',
                children: [
                // {
                //     menu_id: PERMISSIONS.EMAIL,
                //     name: <Translate id='Email' />,
                //     url: '/emails',
                //     icon: 'fa fa-envelope'
                // },
                // {
                //     menu_id: PERMISSIONS.SMS,
                //     name: 'SMS/Voice',
                //     url: '/sms',
                //     icon: 'fa fa-comment',
                // },                
                // {
                //     name: <Translate id='Notifications' />,
                //     url: '/notification/notifications',
                //     icon: 'fa fa-bell',
                //     menu_id: PERMISSIONS.LIST_NOTIFICATION,
                // },
                {
                    menu_id: PERMISSIONS.COMMUNICATION_TEMPLATE,
                    name: 'Communication template',
                    url: '/communication_template',
                    icon: 'fa fa-comment',
                },]
            },




            {
                menu_id: PERMISSIONS.SUPER_ADMIN_SETTING,
                name: "RI Super Admin",
                // url: '/setting',
                icon: 'fa fa-cog',
                children: [{
                    menu_id: PERMISSIONS.SUPER_ADMIN_SETTING,
                    name: 'Templates',
                    icon: 'fa fa-circle',
                    children: [{
                        menu_id: PERMISSIONS.SUPER_ADMIN_SETTING,
                        name: 'Business Account',
                        url: '/business-account-template',
                        icon: 'fa  fa-circle-o',
                    }, {
                        menu_id: PERMISSIONS.SUPER_ADMIN_SETTING,
                        name: 'Communication',
                        url: '/communication-template',
                        icon: 'fa fa-circle-o',

                    }, {
                        menu_id: PERMISSIONS.SUPER_ADMIN_SETTING,
                        name: 'Support',
                        url: '/support-template',
                        icon: 'fa fa-support',

                    }]
                }, {
                    menu_id: PERMISSIONS.INFORMATION,
                    name: 'Information',
                    url: '/information',
                    icon: 'fa fa-circle-o',

                },
                {
                    menu_id: PERMISSIONS.FIXED_EXPENSES_SETTING,
                    name: 'Settings',
                    url: '/settings',
                    icon: 'fa fa-circle-o',
                },
                ],

            },
            {
                menu_id: PERMISSIONS.REPUTEINFO,
                name: <Translate id='Repute Info' />,
                url: '/subscriptions',
                icon: 'icon-list',
                children: [
                    {
                        menu_id: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE,
                        name: 'Reputeinfo Balance',
                        url: '/reputeinfo-balance',
                        icon: 'fa fa-money',
                    },
                    {
                        menu_id: PERMISSIONS.LEGAL,
                        name: <Translate id='Legal' />,
                        url: '/legal',
                        icon: 'fa fa-legal'
                    },
                    {
                        menu_id: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE,
                        name: 'Balance Setting',
                        url: '/reputeinfo-balance-setting',
                        icon: 'fa fa-cog',
                    },
                    {
                        menu_id: PERMISSIONS.REPUTEINFO_BALANCE_PACKAGE,
                        name: 'Profile Setting',
                        url: '/reputeinfo-profile-weightage-setting',
                        icon: 'fa fa-cog',
                    },
                    {
                        menu_id: PERMISSIONS.COMPANY_SECTOR_LIST,
                        name: <Translate id='Company Sectors' />,
                        url: '/company-sector',
                        icon: 'icon-directions',
                    },
                    {
                        menu_id: PERMISSIONS.FAQ,
                        name: <Translate id='FAQ' />,
                        url: '/FAQ',
                        icon: 'fa fa-question-circle',
                        children: [
                            {
                                menu_id: PERMISSIONS.ADD_FAQ,
                                name: 'Add FAQ',
                                url: '/FAQ/AddFAQ',
                                icon: 'icon-star',
                            },
                            {
                                menu_id: PERMISSIONS.LIST_FAQ,
                                name: 'FAQ',
                                url: '/FAQ',
                                icon: 'icon-star',
                            }
                        ]
                    }]
            },
            {
                menu_id: PERMISSIONS.EXCEPTION_LOGS,
                name: "Logs",
                icon: 'fas fa-clipboard-list',
                children: [
                    {
                        menu_id: PERMISSIONS.EXCEPTION_LOGS,
                        name: <Translate id='Exception Logs' />,
                        url: '/exception-logs',
                        icon: 'fas fa-bug    ',
                    },
                    {
                        menu_id: PERMISSIONS.LOGIN_LOGS,
                        name: "Login Details",
                        url: '/user-login-logs',
                        icon: 'fas fa-user-lock',
                    },
                    {
                        menu_id: PERMISSIONS.CRON_LOGS,
                        name: "Cron Logs",
                        url: '/cron-logs',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.SEARCH_LOGS,
                        name: 'Search Log',
                        url: '/search-logs',
                        icon: 'fas fa-search    ',
                    },
                    {
                        menu_id: PERMISSIONS.SMS_LOGS,
                        name: 'SMS log',
                        url: '/sms-logs',
                        icon: 'fas fa-envelope    ',
                    },
                    {
                        menu_id: PERMISSIONS.VOICE_LOGS,
                        name: 'Voice log',
                        url: '/voice-logs',
                        icon: 'fa fa-phone',
                    },
                    {
                        menu_id: PERMISSIONS.BADDEBT_SEARCH_LOGS,
                        name: 'Critical Due log',
                        url: '/critical-due-logs',
                        icon: 'fas fa-rupee',
                    },
                    {
                        menu_id: PERMISSIONS.AUTO_REMINDER_CRON_LOGS,
                        name: "Auto Reminder Cron Logs",
                        url: '/auto-reminder-cron-logs',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.SUBSCRIPTION_LOGS,
                        name: "Subscription Logs",
                        url: '/subscription-logs',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.CONTACT_US_DETAIL,
                        name: "Contactus Detail",
                        url: '/contactus-detail',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.CONTACT_US_DETAIL,
                        name: "FeedBack Detail",
                        url: '/feedback-detail',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.CONTACT_US_DETAIL,
                        name: "Videos Detail",
                        url: '/video-detail',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.MILESTONE_LOGS,
                        name: "Milestone Logs",
                        url: '/milestone-logs',
                        icon: 'fas fa-clone',
                    },
                    {
                        menu_id: PERMISSIONS.EMAIL_LOGS,
                        name: "Email Logs",
                        url: '/email-logs',
                        icon: 'fas fa-clone',
                    },
                    {
                        // menu_id: PERMISSIONS.WHATSAPP,
                        name: 'Whatsapp Log',
                        icon: 'fab fa-whatsapp',
                        children: [
                            {
                                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                                name: 'Received Logs',
                                url: '/whatsapp/receive',
                                icon: 'fa fa-send-o',
                            },
                            {
                                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                                name: 'Send Logs',
                                url: '/whatsapp/send',
                                icon: 'fa fa-send-o',
                            },
                            {
                                menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                                name: 'Schedule Logs',
                                url: '/whatsapp/schedule',
                                icon: 'far fa-hourglass',
                            }
                        ]
                    },
                ]
            },
            {
                menu_id: PERMISSIONS.DEPRECATED,
                name: "Deprecated",
                icon: 'fas fa-times',
                children: [

                    {
                        menu_id: PERMISSIONS.DASHBOARD_REPORTS,
                        name: 'Dashboard Reports',
                        url: '/dashboard-reports',
                        icon: 'fa fa-list',

                    },
                    {
                        name: <Translate id='Customer Detail' />,
                        icon: 'fa fa-user',
                        children: [{
                            menu_id: PERMISSIONS.NEW_CUSTOMER,
                            name: <Translate id='Add Customer Details' />,
                            url: '/customer-detail/add',
                            icon: 'fa fa-user-circle-o',
                        },
                        {
                            menu_id: PERMISSIONS.CUSTOMERDETAIL,
                            name: <Translate id='Customer Detail' />,
                            url: '/customer-detail',
                            icon: 'fa fa-user-circle-o',
                        }]
                    },

                    {
                        // menu_id: PERMISSIONS.SUPPORT,
                        // name: <Translate id='Support' />,
                        // url: '/dashboard',
                        // icon: 'fa fa-life-ring',
                        // children: [
                        //     {
                        menu_id: PERMISSIONS.TICKETS,
                        name: 'Tickets',
                        url: '/tickets',
                        icon: 'fa fa-ticket',
                        //     }
                        // ]
                    },

                    {
                        menu_id: PERMISSIONS.CHEQUE_DETAIL,
                        name: "Cheque Detail",
                        url: '/cheque-detail',
                        icon: 'fas fa-money-check-alt',
                    }, {
                        menu_id: PERMISSIONS.SALES,
                        name: <Translate id='Sales' />,
                        icon: 'fa  fa-shopping-cart',
                        children: [
                            {
                                menu_id: PERMISSIONS.CUSTOMER_SALES_STATUS,
                                name: 'Customer Sales Status',
                                url: '/customer-sales-status',
                                icon: 'fa fa-user-plus',
                            }
                        ]
                    },

                    {
                        menu_id: PERMISSIONS.PRODUCTS,
                        name: 'Reqs / Quotes',
                        url: '/product-tags',
                        icon: 'fa fa-book',
                        children: [

                            {
                                menu_id: PERMISSIONS.REQUIREMENTS,
                                name: "Requirements",
                                url: '/requirements',
                                icon: 'fas fa-clone',
                            }, {
                                menu_id: PERMISSIONS.PRODUCT_TAGS,
                                name: 'Product Tags',
                                url: '/product-tags',
                                icon: 'fas fa-money-check',
                            }, {
                                menu_id: PERMISSIONS.UNREGISTERED_SELLER,
                                name: 'Unregistered Seller',
                                url: '/unregistered-seller',
                                icon: 'fas fa-money-check',
                            }
                        ]
                    },


                    {
                        menu_id: PERMISSIONS.BUSINESS_ACCOUNT,
                        name: 'Payments',
                        url: '/payments',
                        icon: 'fa fa-list',

                    },


                    {
                        menu_id: PERMISSIONS.GROUP,
                        name: "Groups",
                        url: '/groups',
                        icon: 'fa fa-users',
                        children: [
                            {
                                menu_id: PERMISSIONS.GROUP,
                                name: "Groups",
                                url: '/groups',
                                icon: 'fa fa-user',
                            },
                            {
                                menu_id: PERMISSIONS.ASSOCIATION_INDIVIUAL_TYPE,
                                name: "Individual Type",
                                url: '/association/indviual-type',
                                icon: 'fa fa-building',
                            },
                            {
                                menu_id: PERMISSIONS.GROUP_TYPE,
                                name: "GroupType",
                                url: '/group-type',
                                icon: 'fa fa-user',
                            },
                        ]
                    },

                    {
                        menu_id: PERMISSIONS.SUBSCRIPTION_DETAILS,
                        name: "Subscriptions Details",
                        url: '/subscriptions',
                        icon: 'fas fa-boxes'
                    },
                    {
                        menu_id: PERMISSIONS.SMS_PACKAGE,
                        name: <Translate id='SMS Package' />,
                        url: '/sms-packages',
                        icon: 'fa fa-comment',
                    },

                ]
            },


        ]
    }
}

import React, { Component } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Modal,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import {
  InputText,
  InputDropDown,
  InputCheckBox,
} from "../../Settings/StageSetting/Components/FormElements";
import { v4 as uuidv4 } from "uuid";
import { InputTypes } from "../../../constants/stages.constants";
import CheckLiistItem from "./CheckLiistItem";

export default class UpdateEachStageContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { title: "", value: "" },
    };

    this.handleChange = this.handleChange.bind(this);

    this.renderInputTypeWiseInputElements = this.renderInputTypeWiseInputElements.bind(
      this
    );
  }

  componentDidMount() {
    const { current_data } = this.props;
    let { data } = this.state;

    data = { ...data, ...current_data };

    this.setState({ data });
  }

  handleChange(event) {
    let _dt = event.target;
    if (typeof event.target === "undefined") {
      _dt = event;
    }
    const { save, index } = this.props;

    const { name, value } = _dt;
    const { data } = this.state;

    
    console.log("name", name)
    console.log("value",value)
    console.log(data)

    if(data.inputType === InputTypes.Radio){
      data['value'] = value;
    } else if (data.inputType === InputTypes.Checkbox) {
      data[name] = event.target.checked;
    } else {
      data[name] = value;
    }


    this.setState({ data }, () => save({ ...data }, index));
  }

  renderInputTypeWiseInputElements(data) {
    let renderedItem = false;
    let _this = this;
    switch (data.inputType) {
      case InputTypes.Heading:
        renderedItem = (
          <React.Fragment>
            
            <labal style={{ fontSize: "15", fontWeight: "bold" }}>
              {data.title}
            </labal>
          </React.Fragment>
        );
        break;
      case InputTypes.Text:
        renderedItem = (
          <React.Fragment>
            <InputText
              title={data.title}
              placeholder={"Enter " + data.title}
              name={"value"}
              value={data.value}
              onChange={this.handleChange}
              maxLength="250"
            />
          </React.Fragment>
        );
        break;
      case InputTypes.TextArea:
        renderedItem = (
          <React.Fragment>
            <InputText
              title={data.title}
              placeholder={"Enter " + data.title}
              name={"value"}
              type={"textarea"}
              value={data.value}
              onChange={this.handleChange}
            />
          </React.Fragment>
        );
        break;
      case InputTypes.Checkbox:
        renderedItem = (
          <React.Fragment>
            <InputCheckBox
              title={data.title}
              placeholder={data.title}
              name="value"
              checked={data.value}
              onChange={this.handleChange}
              maxLength="250"
            />
          </React.Fragment>
        );
        break;
      case InputTypes.Radio:
        renderedItem = (
          <React.Fragment>
            <FormGroup>
            <Label>
                <b>{data.title}</b>
              </Label> <br/>
            {data.items &&
              data.items.map(function (eachItem, index) {
                return (
                  <FormGroup check inline={data.hasOwnProperty("attribute") && data.attribute.hasOwnProperty("inline") ? data.attribute.inline : false }>
                    <Label check  inline={data.hasOwnProperty("attribute") && data.attribute.hasOwnProperty("inline") ? data.attribute.inline : false }>
                      <Input
                        type="radio"
                        name={"radio" + data.id}
                        value={eachItem.title}
                        checked={data.value == eachItem.title}
                        onChange={_this.handleChange}
                      />{" "}
                      {eachItem.title}
                    </Label>
                  </FormGroup>
                );
              })}
              </FormGroup>
          </React.Fragment>
        );
        break;
      case InputTypes.CheckList:
        renderedItem = <CheckLiistItem {...this.props} current_data={data} />;
        break;
      case InputTypes.SubItem:
        renderedItem = <React.Fragment>
          <FormGroup>
          <Label><b>{ data.title  }</b></Label>
          {Array.isArray(data.children) &&
          data.children.map((each) => {
            return <UpdateEachStageContent {..._this.props} current_data={each} />
          })}
          </FormGroup>
        </React.Fragment>

        break;
    }
    return (
      <React.Fragment>
        <div style={{ marginLeft: "10px" }}>
          {/* <label>
            <b> {data.heading}</b>
          </label> */}
          {renderedItem}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { current_data } = this.props;

    return (
      <React.Fragment>
        {this.renderInputTypeWiseInputElements(current_data)}
      </React.Fragment>
    );
  }
}

import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button,Card, CardBody,Collapse, Modal, ModalBody,Table, ModalHeader } from "reactstrap";

import ProcessStageTimeLine from "./ProcessStageTimeLine";

class ProcessStageTimeLineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  render() {
    let { processStages } = this.props;
    let { business_details, individual_details } = this.state;
    let _this = this;
    return (
      <div>
        <Button size="sm" color="danger" onClick={() => this.toggle()}>
          View Process Stage Timeline
        </Button>

        <Modal className="modal-primary" size="lg" isOpen={this.state.show}>
          <ModalHeader toggle={this.toggle}>
          Process Stage Timeline


          </ModalHeader>
          <ModalBody  style={{background : "#e4e5e6"}}>
            <div>
              <ProcessStageTimeLine {...this.props}/>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    processStages,
    individualDetails,
    businessDetails,
  } = state.baPrpcessStages;
  return {};
};

let connected = connect(mapStateToProps)(ProcessStageTimeLineModal);

export { connected as ProcessStageTimeLineModal };

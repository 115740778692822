import { notify } from 'reapop'
import { SalesConstants } from '../constants'
import { saleservice } from '../services'

export const salesAction = {
    getCustomerList,
    updateCustomerSalesStatus,
    getCustomerSalesStatusData,
    addDemoAppData
}

function getCustomerList(data) {
    return dispatch => {
        dispatch(request({ data }));

        saleservice.getCustomerList(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SalesConstants.GET_CUSTOMER_LIST_REQUEST } }
    function success(result) { return { type: SalesConstants.GET_CUSTOMER_LIST_SUCCESS, result } }
    function failure(error) { return { type: SalesConstants.GET_CUSTOMER_LIST_FAILURE, error } }
}


function getCustomerSalesStatusData(data) {
    return dispatch => {
        dispatch(request({ data }));

        saleservice.getCustomerSalesStatusData(data)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SalesConstants.GET_CUSTOMER_SALES_STATUS_REQUEST } }
    function success(result) { return { type: SalesConstants.GET_CUSTOMER_SALES_STATUS_SUCCESS, result } }
    function failure(error) { return { type: SalesConstants.GET_CUSTOMER_SALES_STATUS_FAILURE, error } }
}


function updateCustomerSalesStatus(data, ref) {
    return dispatch => {
        dispatch(request({ data }));

        saleservice.updateCustomerSalesStatus(data)
            .then(
                result => {
                    dispatch(success(result));
                    ref.afterAction();
                    dispatch(notify({ position: "tc", dismissible: true, message: "Updated Successfully", status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }

    function request() { return { type: SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_REQUEST } }
    function success(result) { return { type: SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_SUCCESS, result } }
    function failure(error) { return { type: SalesConstants.UPDATE_CUSTOMER_SALES_STATUS_FAILURE, error } }
}

function addDemoAppData(ref) {
    return dispatch => {
        dispatch(request({}));
 
        saleservice.addDemoAppData()
            .then(
                result => {
                    dispatch(success(result));
                    // if(ref) {
                    //     ref.afterStartDemo();
                    // }
                    dispatch(notify({ position: "tc", dismissible: false, message: 'Demo Created Successfully', status: "success" }))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notify({ position: "tc", dismissible: false, message: error.toString(), status: "error" }))
                }
            )
    }
 
    function request() { return { type: SalesConstants.ADD_DEMO_APP_DATA_REQUEST } }
    function success(result) { return { type: SalesConstants.ADD_DEMO_APP_DATA_SUCCESS, result } }
    function failure(error) { return { type: SalesConstants.ADD_DEMO_APP_DATA_FAILURE, error } }
}
 


import React, { Component } from "react";
import { associationAction } from '../../actions';
import { CompanySectorAction } from '../../actions/companySector.action'
import swal from "sweetalert";
import {connect } from 'react-redux'
import { Tooltip } from 'react-tippy';

import moment  from 'moment';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
  Form
} from "reactstrap";
import AddressForm from '../../../components/Forms/AddressForm';
import { Translate } from "react-localize-redux";
import { utilityService } from '../../services'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { InputDropDown } from '../Settings/StageSetting/Components/FormElements';
class AddAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpan: false,
      data: {
        name :'',
        description : '',
        sector_id : '',
        group_type_id : '',
        member_company_limit : '',
        minimum_balance : '',
        line1: "",
        line2: "",
        city: "",
        taluka: "",
        pinCode: "",
        district: "",
        state: "",
      },
      errors : {}
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.proceed = this.proceed.bind(this);
    this.isValidData = this.isValidData.bind(this);
    this.getCompanySectorData = this.getCompanySectorData.bind(this)
    this.getPincodeDetails = this.getPincodeDetails.bind(this);
    this.setEditData = this.setEditData.bind(this)
    this.getGroupType = this.getGroupType.bind(this)
  }

  showModal() {
    const { modalOpan } = this.state;
    let _modalOpan = !modalOpan;
    this.setState({ modalOpan: _modalOpan }, () => {
      if(_modalOpan){
        this.getCompanySectorData()
        this.setEditData()
        this.getGroupType()
      }
    });
  }

  getGroupType() { 
    const { dispatch } = this.props;
    let dataToSend = {}
    dispatch(associationAction.getGroupType(dataToSend));
  }
  getPincodeDetails() {
    const { data } = this.state;

    let self = this;

    let pincodeRegex = /^[1-9][0-9]{5}$/

    if (!data.pinCode || pincodeRegex.test(data.pinCode) === false) {
        self.setState({
            ...self.state,
            errors: {
                ...self.state.errors,
                pinCode: "please enter valid pin code",
            },
        });

        return false;
    }

    utilityService.pincodeDetail(data.pinCode).then(function (response) {
        if (response.result) {
            self.setState({
                ...self.state,
                errors: {
                    ...self.state.errors,
                    pinCode: "",
                },
                data: {
                    ...data,
                    state: response.data.state,
                    district: response.data.district,
                }
            });

        } else {

            self.setState({
                ...self.state,
                errors: {
                    ...self.state.errors,
                    pinCode: response.message,
                }
            });

        }
    });

}


  handleChange(event) {
    const { name, value, type } = event.target;
    const { default_balance } = this.props

    let { data } = this.state;
    let val = value;
    
    let regex_allowed = /^[0-9]+$/;
    if (name == 'member_company_limit' && value.length && regex_allowed.test(value) === false) {
      return false;
    }

    if (type === "checkbox") {
      if (data[name] === false) {
        val = true;
        data['balance'] = default_balance
      } else if (data[name] === true) {
        val = false;
        data['balance'] = ''
      } else {
        val = false;
        data['balance'] = ''
      }
    }
    data[name] = val;
    this.setState({ data });
  }

  isValidData() {
    const { data, errors } = this.state
    let error = false
    if(!data.name) {
        error = true
        errors.name ='this field is required'
    }
    if(!data.sector_id) {
        error = true
        errors.sector_id ='this field is required'
    }
    if(!data.group_type_id) {
      error = true
      errors.group_type_id ='this field is required'
  }
    if(!data.member_company_limit) {
        error = true
        errors.member_company_limit ='this field is required'
    }
    if(!data.minimum_balance) {
      error = true
      errors.minimum_balance ='this field is required'
    }
    if(!data.pinCode) {
        error = true
        errors.pinCode ='this field is required'
    }

    this.setState({ errors })
    return error
  }
  proceed() {
    const { data } = this.state;
    const { dispatch, element, getData,is_edit } = this.props;

    if(!this.isValidData()) {
        
        let dataToSend = {
            name : data.name,
            description : data.description,
            sector_id : data.sector_id,
            group_type_id : data.group_type_id,
            member_company_limit : data.member_company_limit,
            line1 : data.line1,
            line2 : data.line2,
            city : data.city,
            taluka : data.taluka,
            pincode : data.pinCode,
            district : data.district,
            state : data.state,
            minimum_balance : data.minimum_balance
        }
        if(is_edit) {
          dataToSend.id = data.id
          dataToSend.address_id = data.address_id
        }

        dispatch(associationAction.addAssociation(dataToSend,{
          success : () => {
            this.setState({ 
              modalOpan:false,
          });
            getData()
          }
        }))

    }
  }

  resetForm() {
    const { data } = this.state;
    data.name ='';
    data.description = '';
    data.sector_id = '';
    data.group_type_id = '';
    data.member_company_limit = '';
    data.minimum_balance = '';
    data.line1 = '';
    data.line2 = '';
    data.city = '';
    data.taluka = '';
    data.pinCode = '';
    data.district = '';
    data.state = '';

    this.setState({ data, errors : {}})
  }
  getCompanySectorData() {
    const { dispatch } = this.props;
    dispatch(CompanySectorAction.getCompanySectorData());
}
  componentDidMount() {
    const { data } = this.state
    const { default_balance, } = this.props
    data.balance = default_balance ? default_balance : ''
    this.setState({ data })
  }
  setEditData() {
    const { eachData, is_edit } = this.props
    const { data } = this.state
    if(is_edit) {
      data.name =eachData.name;
      data.description = eachData.description;
      data.sector_id = eachData.sector_id;
      data.group_type_id = eachData.group_type_id;
      data.member_company_limit = eachData.member_company_limit;
      data.minimum_balance = eachData.minimum_member_company_balance;
      data.line1 = eachData.address.line1 ? eachData.address.line1 : "";
      data.line2 = eachData.address.line2 ? eachData.address.line2 : "";
      data.city = eachData.address.city ? eachData.address.city : "";
      data.taluka = eachData.address.taluka ? eachData.address.taluka : "";
      data.pinCode = eachData.address.pincode;
      data.district = eachData.address.district;
      data.state = eachData.address.state;
      data.id = eachData.id;
      data.address_id = eachData.address_id
      this.setState({ data, errors : {}})
    }
  }
  render() {
    const { modalOpan, data, errors } = this.state;
    const { sectorList , is_edit, groupTypesList } = this.props;
    let _this = this;
    const mendetoryFields = {
      color: "red",
      fontSize: "14px",
      marginLeft: "2px",
  };
  
  let sectorOptions = [{ title : "Select Sector", value : false}] 
  if(sectorList) {
    sectorList.map((item)=>{
      sectorOptions.push({ title : item.sector, value : item.id})
      });
}
    let groupTypeOptions = [{ title : "Select Group Type", value : false}] 
    if(groupTypesList) {
      groupTypesList.map((item)=>{
        groupTypeOptions.push({ title : item.type, value : item.id})
        });
    }
    return (
      <React.Fragment>
        <Button color="info" className={is_edit ?'' : "pull-right"} size="sm"  onClick={_this.showModal}>
           { is_edit ? 'Edit' : 'Add New' } 
        </Button>&nbsp;
        <Modal className="modal-success" isOpen={modalOpan}>
          <ModalHeader toggle={_this.showModal}>{ is_edit ? 'Edit Group' : 'Add New Group' } 
            </ModalHeader>
          <ModalBody>
            <Translate>
                {({ translate }) =>
                    <Form  autocomplete="off"  >

<FormGroup>
                            <label><b>Select Group Type</b>  <i style={mendetoryFields}>*</i></label>
                            <InputDropDown
                             // title={"Sector"}
                              value={data.group_type_id	}
                              name={"group_type_id"}
                              onChange={this.handleChange}
                              options={groupTypeOptions}
                              />
                            {errors.group_type_id && ( <FormFeedback> {errors.group_type_id} </FormFeedback>  )}
                        </FormGroup>
                        <FormGroup>
                            <label><b>Name</b> <i style={mendetoryFields}>*</i></label>
                            <Input
                                type="text"
                                onChange={this.handleChange}
                                name={"name"}
                                value={data.name}
                                placeholder="Name"
                                />
                                {errors.name && ( <FormFeedback> {errors.name} </FormFeedback>)}
                        </FormGroup>
                        <FormGroup>
                            <label><b>Description</b>  <i style={mendetoryFields}>*</i></label>
                            <Input
                                type="textarea"
                                onChange={this.handleChange}
                                name={"description"}
                                value={data.description}
                                placeholder="Description"
                                />
                                {errors.description && ( <FormFeedback> {errors.description} </FormFeedback>  )}
                        </FormGroup>
                        <FormGroup>
                            <label><b>Select Sector</b>  <i style={mendetoryFields}>*</i> </label>
                            <InputDropDown
                             // title={"Sector"}
                              value={data.sector_id	}
                              name={"sector_id"}
                              onChange={this.handleChange}
                              options={sectorOptions}
                              />
                            {errors.sector_id && ( <FormFeedback> {errors.sector_id} </FormFeedback>  )}
                        </FormGroup>
                    
                        <FormGroup>
                            <label><b>Address</b></label>

                            <AddressForm data={data} errors={errors} translate={translate} handleChange={this.handleChange} getPincodeDetails={this.getPincodeDetails} />
                        </FormGroup>
                        <FormGroup>
                            <label><b>Minimum Member Limit</b> <i style={mendetoryFields}>*</i></label>
                            <Input
                                type="text"
                                onChange={this.handleChange}
                                name={"member_company_limit"}
                                value={data.member_company_limit}
                                placeholder="Member Company Limit"
                                />
                                {errors.member_company_limit && ( <FormFeedback> {errors.member_company_limit} </FormFeedback>)}
                        </FormGroup>
                        <FormGroup>
                            <label><b>Minimum Company Balance</b> <i style={mendetoryFields}>*</i></label>
                            <MaskedInput
                              className={"form-control"}
                              mask={createNumberMask({
                                  prefix: '',
                                  postfix: '',
                                  includeThousandsSeparator: false,
                                  allowDecimal: true,
                                  minValue: 1
                              })}
                              type="text"
                              name={"minimum_balance"}
                              value={data.minimum_balance}
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              placeholder={"Minimum Company Balance"} />
                                {errors.minimum_balance && ( <FormFeedback> {errors.minimum_balance} </FormFeedback>)}
                        </FormGroup>
                    </Form>
                }
            </Translate>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.proceed}>
              Proceed
            </Button>
            <Button onClick={_this.showModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
    const { data } = state.getCompanySectorDetail
    return {
        sectorList : data,
        groupTypesList : state.getGroupType.data
    }
}

const connectedAddAssociation = connect(mapStateToProps)(AddAssociation)
export { connectedAddAssociation as AddAssociation }
import React from "react";
import Chart from "react-apexcharts";

function ChartView({ data, count, activeTab, label }) {
  if (!data || data.length === 0) {
    return null;
  }

  const categories = data.map((eachData) => eachData.date).reverse();
  const seriesData = data.map((eachData) => eachData.count).reverse();
  const totalCount = count;
  const funnelStage = label;

  const concatenatedString = `Total ${funnelStage} : ${totalCount}`;
  return (
    <div className="row p-3">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className=" justify-content-center mb-2 order-3 mr-3 order-md-2 ml-lg-auto d-lg-flex">
          <span style={{ fontSize: 14 }}>
            <b>
             Total {funnelStage}:{" "}
              <span className="bg-primary mx-2 px-2 rounded">{totalCount}</span>
            </b>
          </span>
        </div>

        <Chart
          options={{
            chart: {
              id: "area-datetime",
              zoom: {
                autoScaleYaxis: true,
              },
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
              },
            },
            stroke: {
              width: 1
            },
            xaxis: {
              type: "datetime",
              categories,
              title: { text: "Month" },
            },
            tooltip: {
              x: {
                format: "dd MMM yyyy",
              },
            },
          }}
          series={[{ name: "Count", data: seriesData }]}
          type="area"
          width="100%"
          height={300}
        />
      </div>
    </div>
  );
}

export default ChartView;

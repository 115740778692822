import React, { Component } from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { FaqActions } from '../../actions';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { arrayMove } from '../../../helpers';
import { userHasPermission } from '../../../helpers';
import { PERMISSIONS } from '../../constants';


const DragHandle = sortableHandle(() => <span> &nbsp; <i className={"fa fa-navicon"} style={{cursor:"move"}}></i> </span>);

const onDelete = (id, props) => {
  const {dispatch} = props
  swal({
    title: "Are you sure you want to delete?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      dispatch(FaqActions.deleteFAQ({
        id : id
      }))
    }
  });
}

const Actions = ({value, props}) => {
  return <React.Fragment>
    {userHasPermission(PERMISSIONS.EDIT_FAQ) ?  <React.Fragment>
    <Link className="btn btn-primary btn-sm"  to={"/FAQ/Edit/"+value.id} > Edit </Link> {" "}
    <Button size="sm" color="danger" onClick={() => onDelete(value.id, props)} > Delete </Button>
    </React.Fragment> : "-" }
  </React.Fragment>
}


const SortableItem = SortableElement(({ value, index, props }) =>
  <tr>
    <td style={{ width: "600px" }} >
      <DragHandle /> &nbsp;&nbsp; <b> {value.question} </b>  </td>
    <td style={{ width: "50px" }} className={"text-center"} > {index + 1} </td>
    <td style={{ width: "150px" }} className={"text-center"}  > <Actions value={value} props={props} /> </td>
  </tr>);

const SortableList = SortableContainer(({ items, props }) => {
  return (
    <div className="table-responsive-sm  animated fadeIn">
    <table className="table table-sm table-condensed table-striped table-bordered table-outline" style={{ width: "800px" }} >

      <thead className="thead-light">
        <tr>
          <th >  &nbsp; &nbsp; &nbsp; &nbsp;  Questions</th>
          <th >  Order </th>
          <th className={"text-center"}>Actions</th>
        </tr>
      </thead>

      {Array.isArray(items) ? items.map((value, index) => (
        <SortableItem props={props} key={`item-${index}`} index={index } value={value} />
      )): ""}
    </table>
        </div>
  );
});

class FAQlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.data || []
    }
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }), () => {
      const {items} = this.state 
      const { dispatch } = this.props
    
      let data = [];      
      items.map(function( item, index){
        data.push({
          id : item.id,
          order : index + 1 ,
        }) 
        return false;
      })

      let dataToSend = {
        order : JSON.stringify(data)
      } 

      dispatch(FaqActions.saveFAQ(dataToSend));
      
    });
  };
  render() {
    return <SortableList  props={this.props} items={this.state.items} onSortEnd={this.onSortEnd} useDragHandle />;
  }
}

function mapStateToProps(state) {
  const {loading} = state.saveFAQ
  return {
    loading
  }
  
}

const connectedFAQ = connect(mapStateToProps)(FAQlist)
export { connectedFAQ as FAQlist }

let current_config;

let config = {
  localhost: {
    backendHost: 'http://localhost:8001/api',
    // backendHost: 'https://api.sa.reputeinfo.com/api',
    app_api_url: 'http://localhost:8000/api',
    // backendHost: 'https://api.sa.demo.reputeinfo.com/api',


    // websocketHost: 'ws://localhost:3001',
    websocketHost: 'wss://sa.reputeinfo.com:3001',
    google_captcha_site_hey: '6LclJIkUAAAAACtIuwq7L_vXUth_Mc5kOVnNYPYX',
    // google_map_api : 'AIzaSyDm0DsHutzj_LplEpDkdOiuQ7UZdM15tzU',
    google_map_api: 'AIzaSyDymeiztLfpmaDG_ZLU8zRA1PyShRGbhfM',
    // backendHost: 'https://api.sa.reputeinfo.com/api',
    // websocketHost: 'wss://sa.reputeinfo.com:3001',
    app_url: 'http://localhost:3000'
  },
  production: {
    backendHost: 'https://api.sa.reputeinfo.com/api',
    websocketHost: 'wss://sa.reputeinfo.com:3001',
    google_captcha_site_hey: '6LfB05sUAAAAAERSTRM7C8Oh-PszxZEa5vwnWetD',
    google_map_api: 'AIzaSyDm0DsHutzj_LplEpDkdOiuQ7UZdM15tzU',
    app_url: 'https://reputeinfo.com',
    app_api_url: 'https://api.reputeinfo.com/api',

  },
  development: {
    backendHost: 'https://api.sa.development.reputeinfo.com/api',
    websocketHost: 'wss://sa.development.reputeinfo.com:3001',
    google_captcha_site_hey: '6LclJIkUAAAAACtIuwq7L_vXUth_Mc5kOVnNYPYX',
    google_map_api: 'AIzaSyDm0DsHutzj_LplEpDkdOiuQ7UZdM15tzU',
    app_api_url: 'https://api.development.reputeinfo.com/api',
    app_url: 'https://development.reputeinfo.com',
  },
  demo: {
    backendHost: 'https://api.sa.demo.reputeinfo.com/api',
    websocketHost: 'wss://sa.demo.reputeinfo.com:3001',
    google_captcha_site_hey: '6LfB05sUAAAAAERSTRM7C8Oh-PszxZEa5vwnWetD',
    google_map_api: 'AIzaSyDm0DsHutzj_LplEpDkdOiuQ7UZdM15tzU',
    app_url: 'https://demo.reputeinfo.com',
    app_api_url: 'https://api.demo.reputeinfo.com/api',
  }
}


const hostname = window && window.location && window.location.hostname;

if (hostname === 'sa.development.reputeinfo.com') {
  current_config = config.development;
} else if (hostname === 'sa.demo.reputeinfo.com') {
  current_config = config.demo;
} else if (hostname === 'sa.reputeinfo.com') {
  current_config = config.production;
} else {
  // current_config = config.demo;
  current_config = config.localhost;
  // current_config = config.production;
}

export const API_ROOT = `${current_config.backendHost}`;
export const WEB_SOCKET_URL = `${current_config.websocketHost}`;
export const GOOGLE_reCAPTCHA_SITE_KEY = `${current_config.google_captcha_site_hey}`;
export const GOOGLE_MAP_API_KEY = `${current_config.google_map_api}`;
export const APP_URL = `${current_config.app_url}`;
export const APP_API_URL = `${current_config.app_api_url}`;
import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { Button, Form, FormFeedback, Input } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { reputeinfoActions } from "../../actions";
import { reputeinfoConstants } from '../../constants';
import { handleValidation } from './Validation';
class RecentRegistered extends Component {
    constructor(props) {
        super(props)

        let days = new Date().getDate();
        this.state = {
            // startDate: moment().subtract(days, 'days'),
            startDate: moment(),
            endDate: moment(),
            serachField: {
                dateRange: moment().format("DD MMM Y")
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            SearchKey: '',
            errors: {},
            columns: '',
            csv_data: [],
            modalOpan: false,
            is_disable: true,
            page: {
                activePage: 1,
                perPage: 50,
                totalItemsCount: 0
            },
        }
        this.componentRef = '';
        this.getData = this.getData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.changeSearchFunc = this.changeSearchFunc.bind(this)
    }
    componentDidMount() {
        this.getData()
    }
    handleEvent(event, picker) {
        var start = picker.startDate.format("DD MMM Y");
        var end = picker.endDate.format("DD MMM Y");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            serachField: {
                dateRange: label
            },
            errors: {}
        })
    }



    getData() {

        const result = handleValidation(this.state.serachField)

        this.setState({ errors: result.errors })

        if (result.formIsValid) {
            const { dispatch } = this.props;
            const { page, startDate, endDate } = this.state;
            let dataToSend = {
                active_page: page.activePage,
                per_page: page.perPage,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                keyword: this.state.SearchKey,
            }
            dispatch(reputeinfoActions.getRecentRegistrations(dataToSend));
        }
    }
    handlePageChange(pageNumber) {
        const { page } = this.state;
        page.activePage = pageNumber;
        this.setState({ page }, () => this.getData());

    }
    componentWillReceiveProps(props) {
        let { page } = this.state
        page.totalItemsCount = 0;
        if (props.data) {
            page.totalItemsCount = props.data.total_records
        }
        this.setState({
            page
        })
    }
    changeSearchFunc(e) {
        this.setState({
            ...this.state, SearchKey: e.target.value
        })
    }

    handleReset() {
        const { dispatch } = this.props;
        this.setState({
            startDate: moment(),
            endDate: moment(),
            serachField: {
                dateRange: moment().format("DD MMM Y")
            },
            errors: {},
            SearchKey: '',
        });

        dispatch({ type: reputeinfoConstants.GET_RECENT_REGISTRATIONS_FAILURE });
    }


    render() {
        let { loading, data } = this.props
        const { page, serachField, errors } = this.state;
        console.log(this.props);
        console.log("data---", data);

        return (
            <div>

                <Form autocomplete="off">
                    <div className="form-group">
                        <DateRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            //onApply={this.handleApply}
                            onEvent={this.handleEvent}
                            ranges={this.state.ranges}
                        >
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Select Date Range" value={serachField.dateRange} id="date_picker" style={{ 'width': '220px' }} />
                                <span className="input-group-btn">
                                    <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                        <i className="fa fa-calendar" />
                                    </Button>
                                </span>
                            </div>
                        </DateRangePicker>
                        <span>
                            <Input className='mx-2 d-inline' placeholder='Search ' onChange={this.changeSearchFunc} value={this.state.SearchKey} style={{ 'width': '220px' }} ></Input>
                        </span>
                        <Button color="primary" onClick={(e) => {
                            this.setState({
                                page: {
                                    activePage: 1,
                                    perPage: 50,
                                    totalItemsCount: 0
                                }
                            })
                            this.getData()
                        }} style={{ 'margin-left': '10px' }}>Fetch</Button>
                        <Button color="secondary" onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>
                        <span style={{ fontSize: 20 }} className='mx-4'>
                            <b>
                                Total Registration  : <span className='bg-success mx-2 px-2 rounded  '> {data && data.total_records}
                                </span>
                            </b>
                            <b>
                                Total WM  : <span className='bg-success mx-2 px-2 rounded  '> {data && data.total_wm}
                                </span>
                            </b>
                        </span>

                        <ReactToPrint
                            trigger={() => {
                                return <Button color='primary' className='pull-right btn-primary'>Print</Button>

                            }}
                            content={() => this.componentRef}
                        />
                        <FormFeedback>
                            {errors[`dateRange`] && errors[`dateRange`]}
                        </FormFeedback>

                    </div>

                </Form>

                {
                    loading ? <TableLoader /> :
                        <div ref={el => (this.componentRef = el)} className="table-responsive-sm  animated fadeIn">
                            <table className="table table-sm table-condensed  table-bordered  table-outline ">
                                <thead className="thead-light">
                                    <tr>
                                        <th>SR.NO.</th>
                                        <th>Person Name</th>
                                        <th>Mobile</th>
                                        <th>Registered on</th>
                                        <th>Company Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.recentregistered && data.recentregistered.length > 0 ?
                                            data.recentregistered.map((d, i) => {
                                                return (<tr>
                                                    <td>{i + 1} </td>
                                                    <td>
                                                        <Link to={`/verification/Individual/${d.repute_id}`}   >
                                                            {d.name} ({d.individual_reputeid})
                                                        </Link>
                                                    </td>
                                                    <td>{d.mobile} &nbsp;
                                                        {d.is_wm ?
                                                            <span className='badge badge-success'>WM</span>
                                                            : false}
                                                    </td>
                                                    <td>{d.created_at}</td>
                                                    <td>
                                                        {(d.companies && d.companies.length) ? d.companies.map((item) => {
                                                        return  <React.Fragment>
                                                             <Link to={`/verification/company/${item.repute_id
                                                        }`}   >  {item.company_name} ({ item.company_reputeid} ) 
                                                        </Link> [User Role :  {item.role}] 
                                                             <br/>  [Company Created on :{ item.created_at}]  
                                                             
                                                           

                                                        </React.Fragment> 
                                                    }) : "--"}</td>
                                                  
                                                </tr>)
                                            })
                                            : <tr><td colSpan="4" className="text-center" style={{ "background-color": "rgba(0, 0, 0, 0.05)" }}>No Data Found</td></tr>

                                    }
                                </tbody>

                                {/* <tbody>
                                    {
                                        data && data.recentregistered && data.recentregistered.length > 0 ?
                                            data.recentregistered.map((d, i) => {
                                                return (<tr>
                                                    <td>{i + 1} </td>
                                                    <td>{d.aadhaar_name} ({d.repute_id}) </td>
                                                    <td>{d.mobile}</td>
                                                    <td>{d.created_at}</td>
                                                    <td style={{ 'width': '50%' }}>
                                                        {d.companies && d.companies.length ?
                                                            <table className="table table-sm table-condensed  table-bordered  table-outline m-0">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th>company name</th>
                                                                        <th>Registered on</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {d.companies.map((eachCompany) => {
                                                                        return <tr>
                                                                            <td>{eachCompany.company_name} ({eachCompany.repute_id})</td>
                                                                            <td>{eachCompany.created_at}</td>
                                                                        </tr>
                                                                    })}

                                                                </tbody>
                                                            </table> :
                                                            <span>Not registered company</span>
                                                        }


                                                    </td>
                                                </tr>)
                                            })
                                            : <tr><td colSpan="4" className="text-center" style={{ "background-color": "rgba(0, 0, 0, 0.05)" }}>No Data Found</td></tr>

                                    }
                                </tbody> */}
                            </table>

                            {
                                page.totalItemsCount <= page.perPage ? "" :
                                    <Pagination
                                        activePage={page.activePage}
                                        itemsCountPerPage={page.perPage}
                                        totalItemsCount={page.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        linkClass="page-link"
                                        itemClass="page-item"
                                        onChange={this.handlePageChange}
                                    // hideNavigation={false}
                                    // hideFirstLastPages={true}
                                    />
                            }
                        </div>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data } = state.recentRegistrations

    return {
        loading,
        data,
    }
}
const connected = connect(mapStateToProps)(RecentRegistered)
export { connected as RecentRegistered };

